/* eslint-disable max-lines-per-function */
import {
  GL_SET_FUNDS_FOR_FILTER,
  GL_SELECTED_FUND,
  GL_SELECTED_PERIOD,
  GL_SET_ACCOUNTS_FOR_FILTER,
  GL_SELECTED_ACCOUNTS,
  GL_SET_STATUSES_FOR_FILTER,
  GL_SELECTED_STATUSES,
  GL_DOCUMENT_EXPORTS,
  GL_SELECTED_TRANSACTION_IDS,
  GL_SET_SELECTED_BATCHES,
  GL_SELECT_ALL,
  GL_TRANSACTIONS_TOTALS,
  GL_PENDING_TOTALS,
  GL_SET_ACTIVE_TAB,
  GL_SET_FETCHING,
  GL_SET_TRANSACTION_FETCHING,
  GL_SET_TABLE_FETCHING,
  GL_RESET_STATE,
  GL_MANAGE_ARCHIVE_MODAL,
  GL_MANAGE_MOVE_TO_PENDING_MODAL,
  GL_MANAGE_APPROVE_MODAL,
} from 'actions/actionsConstants';

import update from 'immutability-helper';

import initialState from './initialState';

export default (state = initialState, action) => {
  switch (action.type) {
    case GL_SET_FUNDS_FOR_FILTER:
      return update(state, {
        funds: { $set: action.payload },
      });
    case GL_SELECTED_FUND:
      return update(state, {
        selected_fund: { $set: action.payload },
        gl_pending_totals: { $set: 0 },
        gl_transactions_totals: { $set: 0 },
      });
    case GL_SELECTED_PERIOD:
      return update(state, {
        selected_period: { $set: action.payload },
      });
    case GL_SET_ACTIVE_TAB:
      return update(state, {
        active_tab: { $set: action.payload },
        selected_transaction_ids: { $set: [] },
        selected_batches: { $set: [] },
      });
    case GL_SET_ACCOUNTS_FOR_FILTER:
      return update(state, {
        accounts: { $set: action.payload },
      });
    case GL_SELECTED_ACCOUNTS:
      return update(state, {
        selected_accounts: { $set: action.payload },
      });
    case GL_SET_STATUSES_FOR_FILTER:
      return update(state, {
        statuses: { $set: action.payload },
      });
    case GL_SELECTED_STATUSES:
      return update(state, {
        selected_statuses: { $set: action.payload },
      });
    case GL_DOCUMENT_EXPORTS:
      return update(state, {
        document_exports: { $set: action.payload },
      });
    case GL_SELECTED_TRANSACTION_IDS:
      return update(state, {
        selected_transaction_ids: { $set: action.payload },
      });
    case GL_SET_SELECTED_BATCHES:
      return update(state, {
        selected_batches: { $set: action.payload },
      });
    case GL_SELECT_ALL:
      return update(state, {
        select_all: { $set: action.payload },
      });
    case GL_PENDING_TOTALS:
      return update(state, {
        gl_pending_totals: { $set: action.payload },
      });
    case GL_TRANSACTIONS_TOTALS:
      return update(state, {
        gl_transactions_totals: { $set: action.payload },
      });
    case GL_SET_FETCHING:
      return update(state, {
        is_fetching: { $set: action.payload },
      });
    case GL_SET_TRANSACTION_FETCHING:
      return update(state, {
        is_transactions_fetching: { $set: action.payload },
      });
    case GL_SET_TABLE_FETCHING:
      return update(state, {
        is_table_fetching: { $set: action.payload },
      });
    case GL_RESET_STATE:
      return update(state, {
        $set: initialState,
      });
    case GL_MANAGE_ARCHIVE_MODAL:
      return update(state, {
        is_archive_modal_open: { $set: action.payload },
      });
    case GL_MANAGE_MOVE_TO_PENDING_MODAL:
      return update(state, {
        is_move_to_pending_modal_open: { $set: action.payload },
      });
    case GL_MANAGE_APPROVE_MODAL:
      return update(state, {
        is_approve_modal_open: { $set: action.payload },
      });
    default:
      return state;
  }
};
