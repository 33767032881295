/**
 *
 * @param {*} obj
 * This function will do a push into the Google Tag Manager (GTM) 'dataLayer' object
 * One can use this to pass an object with any props to GTM as required
 */
export default function gtmPush(obj) {
  if (window.dataLayer) {
    window.dataLayer.push(obj);
  }
}
