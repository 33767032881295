import { routeCodes as landingPageRoutes } from 'modules/landing_page/router';
import { CLIENT_MANAGEMENT_PAGE_ENTRY } from 'containers/client_management/constants';

export const publicPath = '/icn_react/static/';

export const routeCodes = {
  COMPLIANCE: `${publicPath}compliance`,
  DOC_CENTER: `${publicPath}doc_center`,
  DOC_CENTER_ADMIN: `${publicPath}document_center_admin`,
  RAKE_TASKS_DASHBOARD: `${publicPath}rake_tasks_dashboard`,
  INV_PROF: `${publicPath}investor_profile`,
  NEW_INVESTOR_PROFILE: `${publicPath}new_investor_profile`,
  FUND_ADMIN_STATEMENTS: `${publicPath}fund_admin/statements`,
  FUND_ADMIN_RECONCILIATION: `${publicPath}fund_admin/reconciliation`,
  FUND_ADMIN_CAPITAL_EVENTS: `${publicPath}fund_admin/capital_events/:id?`,
  FUND_ADMIN_UNDERLYING_CAPITAL_EVENTS: `${publicPath}fund_admin/underlying_capital_events/:id?`,
  FUND_ADMIN_OPERATIONS: `${publicPath}fund_admin/operations/:tab?`,
  FUND_ADMIN_FUND_FEES: `${publicPath}fund_admin/fund_fees`,
  FUND_ADMIN_GENERAL_LEDGERS: `${publicPath}fund_admin/general_ledgers`,
  FUND_ADMIN_TRADE_EXECUTIONS: `${publicPath}fund_admin/trade_executions`,
  FUND_ADMIN_UPLOADER: `${publicPath}fund_admin/uploader/:id?`,
  FUND_ADMIN_HFAR_DETAIL: `${publicPath}fund_admin/uploader/:id?/report/:reportId?`,
  EDIT_REPORT_GENERATOR: `${publicPath}report_generator/:id?`,
  REPORT_GENERATOR: `${publicPath}report_generator`,
  REPORT_GENERATOR_GLOSSARY: `${publicPath}report_generator_glossary`,
  REPORTING_HUB: `${publicPath}reporting_hub`,
  REPORTING_HUB_CTX: `${publicPath}reporting_hub/:tabId?_:categoryId?`,
  REPORTING_HUB_VIEW: `${publicPath}reporting_hub/embed/:reportId`,
  REPORTING_HUB_VIEW_CTX: `${publicPath}reporting_hub/embed/:reportId/:tabId?_:categoryId?`,
  REPORT_SCHEDULE_REQUESTS: `${publicPath}report_schedule_requests`,
  VIEW_REPORT_SCHEDULE_REQUESTS: `${publicPath}report_schedule_requests/:id?`,
  REPORT_SCHEDULE_LOGS: `${publicPath}report_schedule_logs`,
  DOC_MANAGER: `${publicPath}fund_document_manager`,
  SUBSCRIPTION_WORKFLOW_STATUS: `${publicPath}subscription_workflow_status`,
  FUND_DASH: `${publicPath}fund_dashboard/:fundId?`,
  SALES_DETAIL: `${publicPath}sales_detail/:fundId?`,
  INVITE_HISTORY: `${publicPath}invite_history`,
  REPORTING: `${publicPath}reporting`,
  USER_SETTINGS: `${publicPath}settings`,
  DELEGATION: `${publicPath}delegation`,
  NOTIFICATION_EVENTS: `${publicPath}notification_events`,
  AUTHORIZATION_GROUPS: `${publicPath}authorization_groups`,
  INVESTMENT_DASHBOARD: `${publicPath}investment_dashboard`,
  EXPORT_HISTORY: `${publicPath}investment_dashboard/export_history`,
  CM_EXPORT_HISTORY: `${publicPath}export_history`,
  SUBSCRIPTION_REVIEWER: `${publicPath}investor_relations/subscription_reviewer`,
  SUBSCRIPTION_DOCUMENTS: `${publicPath}document_definitions`,
  DATA_DASHBOARD: `${publicPath}data_dashboard`,
  SUBSCRIPTIONS_DASHBOARD: `${publicPath}subscriptions_dashboard`,
  SUBSCRIPTION_REVIEW: `${publicPath}aml_approval_document_status_dashboard`,
  VIEW_DOCUMENTS: `${publicPath}view_documents/:investmentId`,
  CLOSE_SCHEDULE: `${publicPath}close_schedule/:pafId`,
  MANAGE_CLOSE: `${publicPath}manage_close/:pafId`,
  MANAGE_FUNDING_STATUS: `${publicPath}manage_funding_status/:id?`,
  API_LOG_FINDER: `${publicPath}api_log_finder`,
  SUBDOC_EXCLUSIONS: `${publicPath}subdoc_exclusions`,
  INTEGRATION_PARTNERS_DASHBOARD: `${publicPath}integration_partners_dashboard`,
  SUPPLEMENTAL_FORM: `${publicPath}investment/:id/paf_supplemental_questions`,
  SUPPLEMENTAL_FORM_PRE: `${publicPath}paf_supplemental_questions_pre_investment`,
  REDEMPTION_SUPPLEMENTAL_FORM: `${publicPath}redemption/:id/redemption_supplemental_questions`,
  PRE_APPROVAL_QUESTIONNAIRE: `${publicPath}pre_approval_questionnaire`,
  LOGIN: `${publicPath}login`,
  RELEASE_NOTES: `${publicPath}release_notes`,
  CLIENT_MANAGEMENT: `${publicPath}:pageEntry(${CLIENT_MANAGEMENT_PAGE_ENTRY})/:pafId?/:advisorId?`,
  FORM_BUILDER: `${publicPath}form_builder`,
  INDEXMASTERS: `${publicPath}fund_finances/index_masters`,
  INDEXRETURNS: `${publicPath}fund_finances`,
  SHARECLASSPPERFORMANCES: `${publicPath}fund_finances/share_class_performances`,
  LEVENSHTEIN_MATCHER: `${publicPath}levenshtein_matcher`,
  AML_SUITABILITY_REPS: `${publicPath}aml_suitability_representations`,
  SECURE_DOCUMENT_TRANSFER: `${publicPath}secure_document_transfer`,
  DOCUMENT_VIEW_INFO: `${publicPath}document_view_info`,
  FORM_TEMPLATES: `${publicPath}form_templates`,
  INVESTOR_REPRESENTATIVE: `${publicPath}investor_representatives`,
  INVESTOR_QUESTIONNAIRE: `${publicPath}investor_questionnaire`,
  TEAM_MANAGEMENT: `${publicPath}team_management`,
  ADMIN_SEARCH: `${publicPath}admin_search/:searchText?/:selectedTab?/:selectedResultId?`,
  DATA_RECONCILIATION: `${publicPath}data_reconciliations`,
  REQUIRED_DOCUMENTS: `${publicPath}required_documents`,
  INVESTMENT_BLOCKS_MANAGER: `${publicPath}investment_blocks_manager`,
  REVERTED_DOCUMENTS: `${publicPath}aml_approval_document_status_dashboard/reversion/reverted_documents/:investmentId?`,
  SET_PASSWORD: `${publicPath}set_password`,
  SSO_REGISTRATION_COMPLETION: `${publicPath}sso_registration_completion`,
  EDUCATION_DASHBOARD: `${publicPath}education_dashboard`,
  INVESTMENT_PRODUCT_SUMMARY: `${publicPath}investment_product/:id/summary`,
  INVESTMENT_PRODUCT_SUMMARY_RESEARCH: `${publicPath}investment_product/:id/summary_research`,
  COURSE_SUMMARY: `${publicPath}course/:id/summary`,
  EDUCATION_SETTINGS: `${publicPath}education_settings`,
  EDUCATION_SETTINGS_COURSES_CUSTOM_LIST: `${publicPath}education_settings/courses/:id/customs`,
  EDUCATION_SETTINGS_COURSES_FORM: `${publicPath}education_settings/:referrer(customs)?/:cta(view)?/courses/:id/:action(create|edit)`,
  REGISTRATION_ACCOUNT_TYPE: `${publicPath}register/account_types`,
  PRIVACY_POLICY: `${publicPath}privacy_policy`,
  TERMS_OF_USE: `${publicPath}custom_terms_of_use`,
  DISCLOSURES: `${publicPath}disclosures`,
  THIRD_PARTY_VENDORS: `${publicPath}third_party_vendors`,
  REGISTRATION_PROCESS: `${publicPath}register`,
  REGISTRATION_REVIEW_ACCOUNT: `${publicPath}register/review_account`,
  DATA_QUALITY_REPORT_DOWNLOAD: `${publicPath}download_data_quality_report/:data_quality_report_id`,
  DEFINITION_CREATE: `${publicPath}document_definitions/create`,
  DEFINITION_EDIT: `${publicPath}document_definitions/edit/:id`,
  AGREEMENT_SIGNING: `${publicPath}agreement_signing`,
  FUND_ADMIN_FUNDING_STATUS: `${publicPath}fund_admin/capital_events/:id?/funding_status`,
  MULTIFACTOR_AUTHENTICATION_REGISTER: `${publicPath}mfa_register`,
  MULTIFACTOR_AUTHENTICATION_VERIFY: `${publicPath}mfa_verify`,
  FUND_PROFILE: `${publicPath}funds/:id?`,
  RESEARCH_FUND_PROFILE: `${publicPath}research_funds/:id?`,
  FUND_DOC_EMAILS: `${publicPath}fund_document_emails`,
  WLP_ADMIN: `${publicPath}wlp_admin`,
  PDF_VIEWER: `${publicPath}pdf_viewer`,
  PUBLISHED_DOCUMENT_EXPORT: `${publicPath}published_document_export`,
  LIBRARY_TEMPLATES: `${publicPath}library_templates`,
  NOMINEE_REVIEW: `${publicPath}aml_approval_document_status_dashboard/:pafId?/nominee_review/:investmentId?`,
  ACTIVITY_LOG: `${publicPath}activity_log`,
  SEND_GP_CERTIFICATE: `${publicPath}aml_approval_document_status_dashboard/:pafId?/send_gp_certificate`,
  ARCHITECT: `${publicPath}architect/:tab?`,
  DLT: `${publicPath}dlt`,
  INVESTOR_PROFILE_CONFIGURATION: `${publicPath}investor_profile_configuration`,
  MASS_MAILER_DOCUMENTS: `${publicPath}mass_mailer`,
  ORGANIZATION_MANAGEMENT: `${publicPath}organization_management`,
  SALES_ENABLEMENT: `${publicPath}sales_enablement`,
  CONSENT_FORM: `${publicPath}consent_form`,
  ESIGN_HUB: `${publicPath}esign_hub`,
  ESIGN_HUB_AUTH: `${publicPath}esign_hub/auth`,
  LOGIN_MFE: '/login',
  SI_MFE: `${publicPath}si-annuities`,
  ALTERNATIVES_RESEARCH: `${publicPath}alternatives_research`,
  ...landingPageRoutes,
};
