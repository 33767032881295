import { getRequest } from 'actions/utils';

import {
  getAggregationLevel,
  getDataSetsLoaded,
  getSelectedCurrencyCode,
  getInvestmentIds,
  getExternalCommitmentsIds,
} from 'reducers/investment_dashboard_reducers/selectors';

import { idSetInvestmentData, idUpdateDataSetsLoaded } from './helpers/constants';

const idGetCapitalStatementsOnSuccess = (payload) => (
  (dispatch) => {
    if (payload) {
      dispatch(idSetInvestmentData({
        investmentInSelectedCurrency: payload.selected_currency_data,
        investmentInFundCurrency: payload.fund_currency_data,
      }));
    }
    dispatch(idUpdateDataSetsLoaded({ investmentsDataSet: true }));
  }
);

const idGetCapitalStatements = () => (
  async (dispatch, getState) => {
    const state = getState();
    const selectedCurrency = getSelectedCurrencyCode(state);
    const aggregationLevel = getAggregationLevel(getState());
    const dataSetsLoaded = getDataSetsLoaded(state);
    const investmentIds = getInvestmentIds(state);
    const externalCommitmentIds = getExternalCommitmentsIds(state);

    if (dataSetsLoaded.investmentsDataSet) return;

    if (!investmentIds && !externalCommitmentIds) return;

    await dispatch(getRequest({
      url: 'investment_dashboard/capital_statements',
      params: {
        investment_ids: investmentIds,
        external_commitment_ids: externalCommitmentIds,
        selected_currency: selectedCurrency,
        aggregation_level: aggregationLevel,
      },
      onSuccess: idGetCapitalStatementsOnSuccess,
    }));
  }
);

export {
  idGetCapitalStatements,
};
