import Pusher from 'pusher-js';
import axios from 'axios';

import configuration from 'configuration';

let pusherKey = configuration.PUSHER_API_KEY;
const cluster = configuration.PUSHER_CLUSTER;
const pusherAuthEndpoint = 'pusher/auth';

if (configuration.RAILS_ENV === 'development' && configuration.DISABLE_PUSHER === true) {
  pusherKey = null;
}

function setPusherOptions() {
  const devOptions = {};

  if (configuration.RAILS_ENV === 'development' || configuration.RAILS_ENV === 'test') {
    const wsHost = configuration.PUSHER_WS_HOST;
    const wsPort = parseInt(configuration.PUSHER_WS_PORT, 10);
    const httpHost = configuration.PUSHER_HOST;
    const httpPort = parseInt(configuration.PUSHER_PORT, 10);

    Object.assign(devOptions, {
      wsHost, wsPort, httpHost, httpPort, disableStats: true, forceTLS: false,
    });
  }

  return devOptions;
}

function createSocket() {
  return new Pusher(
    pusherKey,
    {
      cluster,
      forceTLS: true,
      authorizer: (channel) => ({
        authorize: (socketId, callback) => {
          const params = {
            channel_name: channel.name,
            socket_id: socketId,
          };

          axios.post(pusherAuthEndpoint, params, {
            withCredentials: true,
          }).then((response) => {
            callback(false, response.data);
          });
        },
      }),
      ...setPusherOptions(),
    }
  );
}

const handlers = {};
export const manager = {
  addEventHandler(event, handler) {
    if (!handlers[event]) handlers[event] = [];
    handlers[event].push(handler);
  },
  clearEventHandlers() {
    Object.getOwnPropertyNames(handlers).forEach((event) => {
      delete handlers[event];
    });
  },
};

function bindEvents(userId, socket) {
  const userChannelName = `private-${userId}`;
  const userChannel = socket.subscribe(userChannelName);

  userChannel.bind_global((eventName, data) => {
    handlers[eventName]?.forEach((handler) => {
      setTimeout(() => handler(data), 0);
    });
  });
}

export const bindUser = (userId) => {
  if (!pusherKey) {
    console.info('PUSHER DISABLED');
    return;
  }

  const socket = createSocket();
  bindEvents(userId, socket);
};
