import PropTypes from 'prop-types';

export default PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  private_access_fund_id: PropTypes.number,
  white_label_partner_id: PropTypes.number,
  firm_id: PropTypes.number,
  hide_on_investment_creation: PropTypes.bool,
  limit_code: PropTypes.string,
  subscription_type: PropTypes.number,
});
