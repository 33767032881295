import { getRequest, patchRequest } from 'actions/utils';
import { actionFactory } from 'services/actions';

import {
  WLP_INVESTOR_PROFILE_DATA,
  WLP_EXTERNAL_REVIEW_DATA,
  WLP_CONTACT_CARD_DATA,
  WLP_NEW_ISSUES_PROFILE_DATA,
  WLP_CURRENT_DATA,
} from './WlpAdmin.actionsContants';

const wlpGetInvestorProfileData = actionFactory(WLP_INVESTOR_PROFILE_DATA);
const wlpGetExternalReviewData = actionFactory(WLP_EXTERNAL_REVIEW_DATA);
const wlpGetContactCardData = actionFactory(WLP_CONTACT_CARD_DATA);
const wlpGetNewIssuesData = actionFactory(WLP_NEW_ISSUES_PROFILE_DATA);
const wlpGetCurrentData = actionFactory(WLP_CURRENT_DATA);

const wlpFetchInvestorProfileData = () => (dispatch) => {
  const endpointParams = { url: 'investor_profiles/fields' };

  return dispatch(getRequest({
    ...endpointParams,
    onSuccess: (payload) => {
      dispatch(wlpGetInvestorProfileData(payload));
    },
  }));
};

const wlpFetchExternalReviewData = () => (dispatch) => {
  const endpointParams = { url: 'investments/fields' };

  return dispatch(getRequest({
    ...endpointParams,
    onSuccess: (payload) => {
      dispatch(wlpGetExternalReviewData(payload));
    },
  }));
};

const wlpFetchContactCardData = () => (dispatch) => {
  const endpointParams = { url: 'contact_cards/overwrite_attributes' };

  return dispatch(getRequest({
    ...endpointParams,
    onSuccess: (payload) => {
      dispatch(wlpGetContactCardData(payload));
    },
  }));
};

const wlpFetchNewIssuesProfileData = () => (dispatch) => {
  const endpointParams = { url: 'new_issues_profiles/question_groups' };

  return dispatch(getRequest({
    ...endpointParams,
    onSuccess: (payload) => {
      dispatch(wlpGetNewIssuesData(payload));
    },
  }));
};

const wlpFetchCurrentData = () => (dispatch) => {
  const params = {
    approval: 0,
  };

  const endpointParams = { url: 'white_label_partners/current', params };

  return dispatch(getRequest({
    ...endpointParams,
    onSuccess: (payload) => {
      dispatch(wlpGetCurrentData(payload));
    },
  }));
};

const wlpUpdateField = ({ wlpId, data }) => (dispatch) => dispatch(
  patchRequest({
    url: `white_label_partners/${wlpId}`,
    data,
    onSuccess: () => dispatch(wlpFetchCurrentData),
  })
);

export default {
  wlpFetchInvestorProfileData,
  wlpFetchExternalReviewData,
  wlpFetchContactCardData,
  wlpFetchNewIssuesProfileData,
  wlpFetchCurrentData,
  wlpUpdateField,
};
