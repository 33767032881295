import { createAction } from 'redux-actions';
import {
  CASH_FUNDING_APPROVE_MODAL,
  CASH_FUNDING_APPROVE_WARNING_MODAL,
  CASH_FUNDING_DETAILS_MODAL,
  CASH_FUNDING_EXPORT_MODAL,
  CASH_FUNDING_REJECT_MODAL,
  CASH_FUNDING_UPLOAD_MODAL,
  CASH_FUNDING_IN_PROGRESS_MODAL,
  CASH_FUNDING_COMMENT_MODAL,
} from 'components/fund_admin/capital_events_cash_funding/modals/CashFundingModalsTypes';

import {
  CE_CASH_FUNDING_MODAL_OPEN,
  CE_CASH_FUNDING_MODAL_CLOSE,
  CE_CASH_FUNDING_SET_SELECTED_ITEMS_TO_MODAL,
  CE_CASH_FUNDING_SET_MODAL_SELECTED_CUSTODIANS,
  CE_CASH_FUNDING_SET_MODAL_SELECTED_FIRMS,
  CE_CASH_FUNDING_UPLOAD_RESULTS,
} from '../../../actionsConstants';

const ceCashFundingUploadModalPayload = () => ({ value: CASH_FUNDING_UPLOAD_MODAL });
const ceCashFundingAproveModalPayload = (context, selectAll) => ({ value: CASH_FUNDING_APPROVE_MODAL, context, selectAll });
const ceCashFundingRejectModalPayload = (context) => ({ value: CASH_FUNDING_REJECT_MODAL, context });
const ceCashFundingInProgressModalPayload = (context) => ({ value: CASH_FUNDING_IN_PROGRESS_MODAL, context });
const ceCashFundingDetailsModalPayload = () => ({ value: CASH_FUNDING_DETAILS_MODAL });
const ceCashFundingExportModalPayload = () => ({ value: CASH_FUNDING_EXPORT_MODAL });
const ceCashFundingAprvWrnModalPayload = (context) => ({ value: CASH_FUNDING_APPROVE_WARNING_MODAL, context });
const ceCashFundingCommentModalPayload = (context) => ({ value: CASH_FUNDING_COMMENT_MODAL, context });


const ceCashFundingUploadModalOpen = createAction(CE_CASH_FUNDING_MODAL_OPEN, ceCashFundingUploadModalPayload);
const ceCashFundingApproveModalOpen = createAction(CE_CASH_FUNDING_MODAL_OPEN, ceCashFundingAproveModalPayload);
const ceCashFundingRejectModalOpen = createAction(CE_CASH_FUNDING_MODAL_OPEN, ceCashFundingRejectModalPayload);
const ceCashFundingInProgressModalOpen = createAction(CE_CASH_FUNDING_MODAL_OPEN, ceCashFundingInProgressModalPayload);
const ceCashFundingDetailsModalOpen = createAction(CE_CASH_FUNDING_MODAL_OPEN, ceCashFundingDetailsModalPayload);
const ceCashFundingExportModalOpen = createAction(CE_CASH_FUNDING_MODAL_OPEN, ceCashFundingExportModalPayload);
const ceCashFundingAproveWarningModalOpen = createAction(CE_CASH_FUNDING_MODAL_OPEN, ceCashFundingAprvWrnModalPayload);
const ceCashFundingCommentModalOpen = createAction(CE_CASH_FUNDING_MODAL_OPEN, ceCashFundingCommentModalPayload);

const ceCashFundingModalClose = createAction(CE_CASH_FUNDING_MODAL_CLOSE);
const ceCashFundingCopySelectedItemsToModal = createAction(CE_CASH_FUNDING_SET_SELECTED_ITEMS_TO_MODAL);
const ceCashFundingSetModalSelectedCustodians = createAction(CE_CASH_FUNDING_SET_MODAL_SELECTED_CUSTODIANS);
const ceCashFundingSetModalSelectedFirms = createAction(CE_CASH_FUNDING_SET_MODAL_SELECTED_FIRMS);
const ceCashFundingUploadResults = createAction(CE_CASH_FUNDING_UPLOAD_RESULTS);


export default {
  ceCashFundingUploadModalOpen,
  ceCashFundingApproveModalOpen,
  ceCashFundingRejectModalOpen,
  ceCashFundingInProgressModalOpen,
  ceCashFundingDetailsModalOpen,
  ceCashFundingExportModalOpen,
  ceCashFundingAproveWarningModalOpen,
  ceCashFundingModalClose,
  ceCashFundingCopySelectedItemsToModal,
  ceCashFundingSetModalSelectedCustodians,
  ceCashFundingSetModalSelectedFirms,
  ceCashFundingUploadResults,
  ceCashFundingCommentModalOpen,
};
