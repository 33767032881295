import update from 'immutability-helper';
import { INVESTMENT_STATUS, INVESTMENT_STATUS_LIST } from './actions';

export const initialState = {
  massMailer: {
    isLoading: true,
    list: [],
  },
};

const label = (fundName) => {
  return fundName === null ? `` : ` (${fundName})`;
}

const massMailerInvestmentStatus = (state = initialState, { type, payload }) => {
  switch (type) {
    case INVESTMENT_STATUS.LIST.REQUEST:
      return update(state, { massMailer: { isLoading: { $set: true } } });
    case INVESTMENT_STATUS.LIST.SUCCESS:
      return update(state, {
        massMailer: {
          isLoading: { $set: false },
          list: {
            $set: Object.values(payload.response).map((item) => ({
              label: `${item.label}${label(item.fund_name)}`,
              value: item,
            })),
          },
        },
      });
    case INVESTMENT_STATUS.LIST.FAILURE:
      return update(state, { massMailer: { isLoading: { $set: true } } });
    case INVESTMENT_STATUS_LIST.UNMOUNT:
      return initialState;
    default:
      return state;
  }
};

export default massMailerInvestmentStatus;
