import { createAction } from 'redux-actions';
import { getRequest, postRequest } from 'actions/utils';

import {
  STATEMENTS_SET_DOCUMENT_EXPORTS,
  STATEMENTS_SET_RESULTS_PER_PAGE,
  STATEMENTS_SET_SELECTED,
  STATEMENTS_SET_INVESTORS,
} from '../actionsConstants';

const setDocumentExports = createAction(STATEMENTS_SET_DOCUMENT_EXPORTS);
const setStatementsSetInvestors = createAction(STATEMENTS_SET_INVESTORS);

function fetchDocumentExports(pafId, currentPage = 1, resultsPerPage = 25) {
  return getRequest({
    showSpinner: false,
    url: '/document_exports',
    params: {
      document_types: [
        'InvestmentReportDocumentZip',
        'ExportAllocationsCsv',
        'ExportAllocationsZip',
      ],
      paf_id: pafId,
      page: currentPage,
      per_page: resultsPerPage,
    },
    onSuccess: setDocumentExports,
  });
}

function statementsSetResultsPerPage(fundId, perPage) {
  return (dispatch) => {
    dispatch({
      type: STATEMENTS_SET_RESULTS_PER_PAGE,
      fund_id: fundId,
      per_page: perPage,
    });
  };
}

function exportAllocations(pafId, reportId) {
  return postRequest({
    showSpinner: false,
    url: '/investment_report_document_sets/export_allocations',
    data: {
      private_access_fund_id: pafId,
      id: reportId,
    },
  });
}

function statementsSetSelected(investmentIds) {
  return (dispatch) => {
    dispatch({ type: STATEMENTS_SET_SELECTED, payload: investmentIds });
  };
}

function statementsFetchInvestors(pafId, reportId) {
  return getRequest({
    showSpinner: false,
    url: `/investment_report_document_sets/${reportId}/investors`,
    params: { private_access_fund_id: pafId },
    onSuccess: setStatementsSetInvestors,
  });
}

export default {
  fetchDocumentExports,
  statementsSetResultsPerPage,
  exportAllocations,
  statementsSetSelected,
  statementsFetchInvestors,
};
