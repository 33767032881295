import axios from 'axios';
import qs from 'query-string';

const integrationPartnersTemplateJobsPath = 'flexible_feeds/dashboard/jobs';

export const getIntegrationPartnerTemplateJobs = async ({ pageSize, pageIndex, filter }) => {
  const url = integrationPartnersTemplateJobsPath;
  const params = { ...filter, per_page: pageSize, page: pageIndex };

  return axios.get(url, {
    params,
    paramsSerializer: (params) => qs.stringify(params),
  }, {
    withCredentials: true,
  }).then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error));
};

export const getJobStatuses = async () => {
  const url = `${integrationPartnersTemplateJobsPath}/get_job_statuses`;

  return axios.get(url, {}, {
    withCredentials: true,
  }).then((response) => Promise.resolve(response.data.status.map((item) => ({ text: item.text, value: item.value }) )))
    .catch((error) => Promise.reject(error));
}

export const cancelIntegrationPartnerTemplateJob = (jobId) => {
  const url = `${integrationPartnersTemplateJobsPath}/${jobId}/cancel`;

  return axios.post(url, {
    withCredentials: true,
  }).then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error));
};

export const getOutputFileS3UrlFromIntegrationPartnerTemplateJob = (jobId) => {
  const url = `${integrationPartnersTemplateJobsPath}/${jobId}/get_s3_file_link`;

  return axios.get(url, {}, {
    withCredentials: true,
  }).then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error));
};

export const handleS3FileFetch = (job, setSnackbarData, getOutputFileS3Url) => {
  const onSuccess = (data) => {
    setSnackbarData({ message: 'File fetched', severity: 'success' });
    window.open(data.url, '_blank').focus();
  };

  const onError = (error) => {
    setSnackbarData({ message: error.data.message, severity: 'error' });
  };

  getOutputFileS3Url(job.id, onSuccess, onError);
};
