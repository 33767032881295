export default {
  capitalEvents: [],
  filteredCapitalEvents: {
    pastDueEvents: [],
    attentionRequiredEvents: [],
    outForApprovalEvents: [],
    approvedEvents: [],
    distributionEvents: [],
    executedTodayCapitalCalls: [],
    executedTodayDistributions: [],
    allEvents: [],
  },
  activeStat: 'allEvents',
  capitalEventOutstandingInvestments: [],
  allCapitalEventsExports: [],
  allOutstandingInvestments: [],
  filters: {
    fundAdministrators: [],
    fundDistributors: [],
    fundFinanceRepresentatives: [],
    underlyingFundManagers: [],
  },
  activeFilters: {
    fundAdministrators: [],
    fundDistributors: [],
    fundFinanceRepresentatives: [],
    underlyingFundManagers: [],
  },
  callDateFilter: 'last45Days',
  isFetching: true,
  lastExportedFile: null,
  hasNewExportedFile: false,
};
