import {
    CHANGE_DATE_PICKER_DATE_TYPE
} from 'actions/actionsConstants'

import update from 'immutability-helper';

const initialState = {
    dateType: 'publish_date'
};

export default (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_DATE_PICKER_DATE_TYPE:
            return update(state, {
                dateType: {
                    $set: action.payload
                }
            });
        default:
            return state;
    }
};
