import * as reducers from './reducers';
import initialState from './initialState';

const fn = [
  reducers.fundsReducer,
];

export const reducer = (state = initialState, action) => (
  fn.reduce((stateAccumulator, fnc) => fnc(stateAccumulator, action), state)
);

export default reducer;
