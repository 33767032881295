/* eslint-disable max-lines-per-function, max-lines */
import icnIdentity from 'services/icn_identity/icnIdentity';
import { INVESTMENT_APPROVAL_STATUS_OVERRIDES } from 'constants/index';
import {
  isPreApprovalStatus,
  isRevertedStatus,
  isPendingDocumentStatus,
  isTransferStatus,
  isRedemptionsStatus,
} from '../investmentStatus';
import * as columnValidations from './columnValidations';

const {
  REVERTED,
  PRE_APPROVAL,
  PRE_APPROVAL_SECONDARY,
  PENDING_REVIEW,
  APPROVED,
  ACCEPTED,
} = INVESTMENT_APPROVAL_STATUS_OVERRIDES;

/*
  @function Pass a column name and associated data to determine if a column should be shown.
  Return false if column should not be shown based on permissions or other.
  @name isValidColumn
  @param user {object}
  @param columnName {string}
  @param investmentStatus {string}
  @param selectedPaf {object}
  @param whiteLabelPartner {object} or null
  @returns Boolean
*/

const isValidColumn = ({
  user,
  columnName,
  selectedPaf,
  investmentStatus,
  whiteLabelPartner,
  showCheckbox,
  showColumns,
}) => {
  switch (columnName) {
    case 'id':
      if (whiteLabelPartner === null) {
        return icnIdentity.isPafAdmin(user);
      }
      return icnIdentity.isPafAdmin(user) || icnIdentity.canShowInvestmentIds(whiteLabelPartner);

    case 'fund_name':
      return columnValidations.showFundName(investmentStatus, selectedPaf);

    case 'type':
      return !isPreApprovalStatus(investmentStatus);

    case 'investor_name':
      return columnValidations.showInvestorName(user);

    case 'submission_date':
      return columnValidations.showSubmissionDate(investmentStatus, whiteLabelPartner);

    case 'flagged_fields':
      return columnValidations.showFlaggedFields(user);

    case 'contact_card_name':
      return columnValidations.showFirstSigner(investmentStatus, user);

    case 'account_number':
      return columnValidations.showAccountNum(user);

    case 'status_date':
      return columnValidations.showStatusDate(investmentStatus);

    case 'assigned_lead_approver':
      return columnValidations.showAssignedLeadApprover(user, investmentStatus);

    case 'branch_code':
      return columnValidations.showBranchCode(whiteLabelPartner);

    case 'ria_firm':
      return !icnIdentity.isHomeOffice(user);

    case 'ria_user':
      return !icnIdentity.isHomeOffice(user);

    case 'advisor_name':
      return !isRevertedStatus(investmentStatus);

    case 'delegate_name':
      return true;

    case 'amount':
      return true;

    case 'documents_reverted':
      return isRevertedStatus(investmentStatus);

    case 'placement_fee':
      return columnValidations.showPlacementFee(investmentStatus);

    case 'close_date':
      return columnValidations.showCloseDate(investmentStatus);

    case 'funded':
      return columnValidations.showFunded(investmentStatus, selectedPaf);

    case 'sub_doc_type_name':
      return !isRevertedStatus(investmentStatus);

    case 'custodian_account_name':
      return ![REVERTED, PRE_APPROVAL, PRE_APPROVAL_SECONDARY].includes(investmentStatus);

    case 'subdoc_signed':
      return isPendingDocumentStatus(investmentStatus);

    case 'transfer_doc_signed':
      return isPendingDocumentStatus(investmentStatus);

    case 'aml_uploaded':
      return isPendingDocumentStatus(investmentStatus);

    case 'admin_documents_sent_at':
      return columnValidations.showAdminSignOff(investmentStatus, user);

    case 'sign_method':
      return ![
        REVERTED, PRE_APPROVAL, PRE_APPROVAL_SECONDARY, PENDING_REVIEW,
      ].includes(investmentStatus);

    case 'recent_subdoc_upload':
      return columnValidations.showUploadTimes(investmentStatus, user);

    case 'recent_aml_upload':
      return columnValidations.showUploadTimes(investmentStatus, user);

    case 'erisa':
      return ![REVERTED, PRE_APPROVAL, PRE_APPROVAL_SECONDARY].includes(investmentStatus);

    case 'custodian_forms_received':
      return columnValidations.showCustodianForms(investmentStatus, user);

    case 'general_partner_signature':
      return [ACCEPTED, APPROVED].includes(investmentStatus);

    case 'review_status':
      return ![
        REVERTED,
        PRE_APPROVAL,
        PENDING_REVIEW,
        APPROVED,
        ACCEPTED,
      ].includes(investmentStatus);

    case 'reverted_status':
      return isRevertedStatus(investmentStatus);

    case 'attention_required':
      return columnValidations.showUploadTimes(investmentStatus, user);

    case 'new_issues_participant':
      return ![
        REVERTED,
        PRE_APPROVAL,
      ].includes(investmentStatus);

    case 'administrator_sign_off_status':
      return columnValidations.showAdminSignOff(investmentStatus, user);

    case 'status':
      return isPreApprovalStatus(investmentStatus) || isRedemptionsStatus(investmentStatus);

    case 'share_class':
      return ![
        REVERTED,
        PRE_APPROVAL,
      ].includes(investmentStatus);

    case 'profile_matched':
      return columnValidations.showProfileMatched(investmentStatus, user);

    case 'preapproval_status':
      return [
        PRE_APPROVAL,
        PRE_APPROVAL_SECONDARY,
      ].includes(investmentStatus);

    case 'ready_to_fund':
      return columnValidations.showReadyToFund(investmentStatus, selectedPaf);

    case 'transfer_investment_id':
      return isTransferStatus(investmentStatus);

    case 'transfer_amount':
      return isTransferStatus(investmentStatus);

    case 'investment_id':
      return isTransferStatus(investmentStatus) ||
        (isRedemptionsStatus(investmentStatus) &&
          !icnIdentity.isPafAdmin(user) && icnIdentity.canShowInvestmentIds(whiteLabelPartner));

    case 'investment.investor_profile.name':
      return isTransferStatus(investmentStatus) && (
        icnIdentity.isSalesManagerWithUnredactedSubReview(user) ||
        !icnIdentity.isSalesManager(user) ||
        icnIdentity.isHomeOffice(user)
      );

    case 'investment.investor_profile.account_num':
      return isTransferStatus(investmentStatus) && (
        icnIdentity.isSalesManagerWithUnredactedSubReview(user) ||
        !icnIdentity.isSalesManager(user) ||
        icnIdentity.isHomeOffice(user)
      );

    case 'investment.investor_profile_completed':
      return isTransferStatus(investmentStatus);

    case 'effective_date':
      return isTransferStatus(investmentStatus);

    case 'investment.investment_supplemental_responses_completed':
      return isTransferStatus(investmentStatus) &&
        Boolean(
          selectedPaf.private_access_fund.paf_supplemental_questions_form ||
          selectedPaf.private_access_fund.has_investment_supplemental_form
        );

    case 'percentage':
      return isTransferStatus(investmentStatus);

    case 'units':
      return (isTransferStatus(investmentStatus) || isRedemptionsStatus(investmentStatus)) &&
        !!(
          selectedPaf.private_access_fund.open_ended &&
          selectedPaf.private_access_fund.unitized
        );

    case 'investment.aml_document_status':
      return isTransferStatus(investmentStatus);

    // Status column is being used on Sub Review still. After the Agreement Status Dependency is removed then
    // only the Sub Doc Status Column will be taken into account
    case 'investment.status':
    case 'investment.subscription_document.status':
      return isTransferStatus(investmentStatus);

    case 'investment.transfer_document.status':
      return isTransferStatus(investmentStatus);

    case 'available':
      return isTransferStatus(investmentStatus) || isRedemptionsStatus(investmentStatus);

    case 'actions':
      return columnValidations.showActionsMenu(user) || (
        isTransferStatus(investmentStatus) && user.canManageTransfersDirectsOnly
      );

    case 'sub_advisory_fee':
      return columnValidations.showSubAdvisoryFee(investmentStatus);

    case 'available_units':
      return columnValidations.showUnits(selectedPaf);

    case 'doc_delivery_eligibility':
      return true;
    case 'doc_delivery_status':
      return true;
    case 'order_delivery_status':
      return true;

    case 'individual_nav':
    case 'nav_as_of_date':
      return columnValidations.isHedgeFund(selectedPaf) && whiteLabelPartner && !whiteLabelPartner.hide_nav_columns;

    case 'investor_profile_completed':
      return isRedemptionsStatus(investmentStatus) && !icnIdentity.canEditFrozenProfiles(user, selectedPaf);

    case 'nav':
      return isRedemptionsStatus(investmentStatus) && whiteLabelPartner && !whiteLabelPartner.hide_nav_columns;

    case 'created_at': {
      return isRedemptionsStatus(investmentStatus) && icnIdentity.isPafAdmin(user);
    }

    case 'supplemental_form_completed': {
      return isRedemptionsStatus(investmentStatus) && showColumns.showSupplementalForm;
    }

    case 'account_num': {
      return isRedemptionsStatus(investmentStatus) && showColumns.showAccountNumber;
    }

    case 'raw_hold_back_wire_date':
    case 'raw_wire_date': {
      return isRedemptionsStatus(investmentStatus) && showColumns?.showWireDates;
    }

    case 'raw_value_date':
    case 'full':
    case 'investment.id':
    case 'total_distribution':
    case 'amount_payable':
    case 'hold_back_amount':
    case 'hold_back':
    case 'redemption_id':
    case 'lock_up_penalty':
      return isRedemptionsStatus(investmentStatus);
    case 'investment.wlp_attestation_completed':
      return true;
    case 'checkbox':
      return showCheckbox;
    case 'external_id':
      return user.canSeeOneInvestmentExternalId;
    case 'external_ids':
      return user.canSeeAllInvestmentExternalIds;
    default:
      return false;
  }
};

export default isValidColumn;
