import _ from 'lodash';
import { createSelector } from 'reselect';

export const accountProfiles = (state) => state.teamManagement.accounts.accountProfiles;
export const apAccountProfilesFilter = (state) => state.teamManagement.accounts.apAccountProfilesFilter;
export const apReferralCodesFilter = (state) => state.teamManagement.accounts.apReferralCodesFilter;
export const apBranchCodesFilter = (state) => state.teamManagement.accounts.branchCodesFilter;
export const apSelectedAccountsFilter = (state) => state.teamManagement.accounts.apSelectedAccountsFilter;
export const apSelectedRefCodesFilter = (state) => state.teamManagement.accounts.apSelectedRefCodesFilter;
export const apSelectedBranchCodesFilter = (state) => state.teamManagement.accounts.selectedBranchCodesFilter;
export const apSelectedAccountsOptionsFilter = (state) => state.teamManagement.accounts.apSelectedAccountsOptionsFilter;
export const apSelectedRefCodesOptionsFilter = (state) => state.teamManagement.accounts.apSelectedRefCodesOptionsFilter;
export const apSelectedBranchCodesOptionsFilter = (state) => (
  state.teamManagement.accounts.selectedBranchCodesOptionsFilter
);
export const rcAssignableReferralCodes = (state) => (
  state.teamManagement.teams.rcAssignableReferralCodes
);
export const rcAssignableReferralCodesCount = (state) => (
  state.teamManagement.teams.rcAssignableReferralCodesCount
);
export const hasRcAssignableReferralCodes = createSelector(
  rcAssignableReferralCodesCount,
  (count) => count > 0
);
export const isTMEditModalOpen = (state) => state.teamManagement.modals.editTeamModal.isOpen;
export const isTMDeleteModalOpen = (state) => state.teamManagement.modals.deleteTeamModal.isOpen;
export const tmEditModalRowData = (state) => state.teamManagement.modals.editTeamModal.rowData;
export const tmDeleteModalRowData = (state) => state.teamManagement.modals.deleteTeamModal.rowData;
export const apAssignReferralCodeTooltip = createSelector(
  hasRcAssignableReferralCodes,
  (hasAssignableReferralCodes) => {
    if (hasAssignableReferralCodes) {
      return 'Assign Team Code';
    }
    return 'There are no Team Codes available to assign';
  }
);
export const accountProfilesPerPage = (state) => state.teamManagement.accounts.accountProfiles.per_page;
export const rcAssignableUsers = (state) => state.teamManagement.teams.rcAssignableUsers;
export const rcAssignableUsersUsers = (state) => state.teamManagement.teams.rcAssignableUsers?.users;
export const rcReferralCodeUsers = (state) => state.teamManagement.teams.rcReferralCodeUsers;
export const rcUserReferralCodes = (state) => state.teamManagement.teams.rcUserReferralCodes;

// Teams
export const teamsReferralCodes = (state) => state.teamManagement.teams.referralCodes;
export const referralCodes = (state) => state.teamManagement.teams.referralCodes.referral_codes;
export const teamsReferralCodesFilter = (state) => state.teamManagement.teams.referralCodesFilter;
export const teamsUsersFilter = (state) => state.teamManagement.teams.usersFilter;
export const teamsBranchCodesFilter = (state) => state.teamManagement.teams.branchCodesFilter;
export const teamsSelectedReferralCodesFilter =
  (state) => state.teamManagement.teams.selectedReferralCodesFilter;
export const teamsSelectedUsersFilter =
  (state) => state.teamManagement.teams.selectedUsersFilter;
export const teamsSelectedBranchCodesFilter =
  (state) => state.teamManagement.teams.selectedBranchCodesFilter;
export const teamsSelectedReferralCodesOptionsFilter =
  (state) => state.teamManagement.teams.selectedReferralCodesOptionsFilter;
export const teamsSelectedUsersOptionsFilter =
  (state) => state.teamManagement.teams.selectedUsersOptionsFilter;
export const teamsSelectedBranchCodesOptionsFilter =
  (state) => state.teamManagement.teams.selectedBranchCodesOptionsFilter;

const formatReferralCodeFilterOptions = (referralCode) => ({
  id: referralCode.id,
  content: referralCode.code,
  value: `${referralCode.id}`,
});

const formatUsersFilterOptions = (user) => ({
  id: user.id,
  content: user.name,
  details: user.email,
  value: `${user.id}`,
});

const formatBranchCodeFilterOptions = (branchCode) => ({
  id: branchCode.id,
  content: branchCode.code,
  value: `${branchCode.id}`,
});

export const teamsReferralCodesFilterOptions = createSelector(
  teamsReferralCodesFilter,
  (referralCodesFilter) => (
    _.map(referralCodesFilter.referral_codes, formatReferralCodeFilterOptions)
  )
);

export const teamsSelectedReferralCodesFilterOptions = createSelector(
  teamsReferralCodesFilterOptions,
  teamsSelectedReferralCodesFilter,
  (refCodes, selectedReferralCodes) => (
    _.filter(refCodes, (rc) => selectedReferralCodes.includes(rc.id))
  )
);

export const teamsUsersFilterOptions = createSelector(
  teamsUsersFilter,
  (usersFilter) => _.map(usersFilter.users, formatUsersFilterOptions)
);

export const teamsBranchCodesFilterOptions = createSelector(
  teamsBranchCodesFilter,
  (branchCodesFilter) => _.map(branchCodesFilter.branch_codes, formatBranchCodeFilterOptions)
);

export const teamsSelectedUsersFilterOptions = createSelector(
  teamsUsersFilterOptions,
  teamsSelectedUsersFilter,
  (users, selectedUsers) => _.filter(users, (user) => selectedUsers.includes(user.id))
);

export const formattedTeamsReferralCodes = createSelector(
  teamsReferralCodes,
  (rcs) => {
    const array = [];

    _.each(rcs.referral_codes, (referralCode) => {
      array.push({
        id: referralCode.id,
        referralCodeId: referralCode.id,
        referralCode: referralCode.code,
        branchCodeId: referralCode.branch_code.id,
        branchCode: referralCode.branch_code.code,
        accountProfilesCount: referralCode.account_profiles_count,
        lastModifiedBy: referralCode.last_modified_by,
        usersCount: referralCode.users_count,
        timestamp: rcs.timestamp,
      });
    });

    return array;
  }
);

// Branch Codes
export const bcAssignableBranchCodes = (state) => state.teamManagement.branches.assignableBranchCodes;
export const bcBranchReferralCodes = (state) => state.teamManagement.branches.branchReferralCodes;

// Team Invites
export const teamInvites = (state) => state.teamManagement.teamInvites.invites;
export const teamInvitesFilter = (state) => state.teamManagement.teamInvites.invitesFilter;
export const teamSelectedInvitesFilter = (state) => state.teamManagement.teamInvites.selectedInvitesFilter;
