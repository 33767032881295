import {
  RGA_LOAD_SCHEDULE,
  RGA_CHANGE_SCHEDULE_TYPES,
} from 'actions/actionsConstants';
import update from 'immutability-helper';
import initialState from 'reducers/report_generator_reducers/initialState';

export default (state = initialState, action) => {
  switch (action.type) {
    case RGA_LOAD_SCHEDULE: {
      const {
        attachment_expiry_days,
        config_id,
        email_body,
        email_subject,
        enabled,
        for_internal_reporting: forInternalReporting,
        recipients,
        schedule_meta_data,
        approval_status,
        rejection_reason,
        approval_status_text,
        sftp_account_id: sftpAccountId,
        sftp_upload_path: sftpUploadPath,
        snowflake_client_id: snowflakeClientId,
        snowflake_table_name: snowflakeTableName,
      } = action.payload || {};

      const schedulingTypes = {
        email: recipients?.length > 0,
        sftp: !!sftpAccountId,
        snowflake: !!snowflakeClientId,
      };

      const schedulePayload = {
        config_id,
        enabled,
        type: schedule_meta_data?.type || initialState.schedule.type,
        time: schedule_meta_data?.time || initialState.schedule.time,
        interval:
          schedule_meta_data?.interval || initialState.schedule.interval,
        days_of_month:
          schedule_meta_data?.days_of_month || initialState.schedule.days_of_month,
        dates_of_year: schedule_meta_data?.dates_of_year || initialState.schedule.dates_of_year,
        days: schedule_meta_data?.week_days || initialState.schedule.days,
        email_recipients: recipients || initialState.schedule.email_recipients,
        email_subject,
        email_content: email_body,
        forInternalReporting,
        approval_status,
        rejection_reason,
        approval_status_text:
          approval_status_text || initialState.schedule.approval_status_text,
        expiry_duration:
          attachment_expiry_days || initialState.schedule.expiry_duration,
        sftpAccountId: sftpAccountId || initialState.schedule.sftpAccountId,
        sftpUploadPath: sftpUploadPath || initialState.schedule.sftpUploadPath,
        snowflakeClientId:
          snowflakeClientId ?? initialState.schedule.snowflakeClientId,
        snowflakeTableName:
          snowflakeTableName ?? initialState.schedule.snowflakeTableName,
      };

      return update(state, {
        schedule: { $set: schedulePayload },
        schedulingTypes: { $set: schedulingTypes },
      });
    }
    case RGA_CHANGE_SCHEDULE_TYPES: {
      const { schedulingTypes } = action.payload;

      return update(state, { schedulingTypes: { $set: schedulingTypes } });
    }
    default:
      return state;
  }
};
