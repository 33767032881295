import partition from 'lodash/partition';
import {
  SR_LOAD_PRIVATE_ACCESS_FUNDS_SUCCESS,
  SR_ADD_ASSIGNABLE_FUND_SUCCESS,
  SR_DELETE_ASSIGNABLE_FUND_SUCCESS,
} from 'actions/actionsConstants';

import update from 'immutability-helper';

import initialState from '../initialState';

export default (state = initialState, action) => {
  switch (action.type) {
    case SR_LOAD_PRIVATE_ACCESS_FUNDS_SUCCESS: {
      const [assignable, nonAssignable] = partition(action.payload, 'assignable');

      return update(state, {
        funds: { $set: nonAssignable },
        assignable_funds: { $set: assignable },
      });
    }
    case SR_ADD_ASSIGNABLE_FUND_SUCCESS: {
      const index = state.funds.findIndex((e) => e.id === action.payload);
      if (index >= 0) {
        return update(state, {
          assignable_funds: { $push: [{ ...state.funds[index], assignable: true }] },
          funds: { $splice: [[index, 1]] },
        });
      }
      return state;
    }
    case SR_DELETE_ASSIGNABLE_FUND_SUCCESS: {
      const index = state.assignable_funds.findIndex((e) => e.id === action.payload);

      if (index >= 0) {
        return update(state, {
          funds: { $push: [{ ...state.assignable_funds[index], assignable: false }] },
          assignable_funds: { $splice: [[index, 1]] },
        });
      }
      return state;
    }
    default:
      return state;
  }
};
