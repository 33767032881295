// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("images/delete_icon.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("images/delete_hover_icon.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DeleteEditButtonCellRenderer-module__edit_button___GI_B1{width:15px;height:15px;display:inline-block}.DeleteEditButtonCellRenderer-module__edit_button___GI_B1::after{content:"";font-family:FontAwesome;color:#2ca8e2;position:relative;font-size:15px;top:-2px}.DeleteEditButtonCellRenderer-module__spacer___SIH3a{display:inline-block;width:15px;height:15px;content:" "}.DeleteEditButtonCellRenderer-module__delete_button___IjQKG{background:url(${___CSS_LOADER_URL_REPLACEMENT_0___});background-size:contain;width:15px;height:15px;display:inline-block}.DeleteEditButtonCellRenderer-module__delete_button___IjQKG:hover{background:url(${___CSS_LOADER_URL_REPLACEMENT_1___});background-size:contain}`, "",{"version":3,"sources":["webpack://./icn_react/src/components/shared/cell_renderers/DeleteEditButtonCellRenderer/DeleteEditButtonCellRenderer.module.scss"],"names":[],"mappings":"AAAA,0DAAA,UACE,CAAA,WACA,CAAA,oBACA,CAAA,iEAEA,WACE,CAAA,uBACA,CAAA,aACA,CAAA,iBACA,CAAA,cACA,CAAA,QACA,CAAA,qDAIJ,oBACE,CAAA,UACA,CAAA,WACA,CAAA,WACA,CAAA,4DAGF,kDACE,CAAA,uBACA,CAAA,UACA,CAAA,WACA,CAAA,oBACA,CAAA,kEACA,kDACE,CAAA,uBACA","sourcesContent":[".edit_button {\n  width: 15px;\n  height: 15px;\n  display: inline-block;\n\n  &::after {\n    content: \"\\f040\";\n    font-family: FontAwesome;\n    color: #2CA8E2;\n    position: relative;\n    font-size: 15px;\n    top: -2px;\n  }\n}\n\n.spacer {\n  display: inline-block;\n  width: 15px;\n  height: 15px;\n  content: ' ';\n}\n\n.delete_button {\n  background: url(~images/delete_icon.svg);\n  background-size: contain;\n  width: 15px;\n  height: 15px;\n  display: inline-block;\n  &:hover {\n    background: url(~images/delete_hover_icon.svg);\n    background-size: contain;\n  }\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"edit_button": `DeleteEditButtonCellRenderer-module__edit_button___GI_B1`,
	"spacer": `DeleteEditButtonCellRenderer-module__spacer___SIH3a`,
	"delete_button": `DeleteEditButtonCellRenderer-module__delete_button___IjQKG`
};
export default ___CSS_LOADER_EXPORT___;
