import fieldsReducer from './fieldsReducer';
import filesReducer from './filesReducer';
import filterReducer from './filterReducer';
import optionsReducer from './optionsReducer';
import numericFilterReducer from './numericFilterReducer';
import transformFilterReducer from './transformFilterReducer';
import dateFilterReducer from './dateFilterReducer';
import stringFilterReducer from './stringFilterReducer';
import booleanFilterReducer from './booleanFilterReducer';

export default {
  fieldsReducer,
  filesReducer,
  filterReducer,
  optionsReducer,
  numericFilterReducer,
  transformFilterReducer,
  dateFilterReducer,
  stringFilterReducer,
  booleanFilterReducer,
};
