import _ from 'lodash';
import { patchRequest } from 'actions/utils';
import i18n from 'i18n.js';
import { setNotificationContext } from 'services/system_notifier';
import {
  SET_ICN_BOOTSTRAP, ADD_REFERRAL_CODE, UPDATE_CURRENT_LANGUAGE, UPDATE_USER_ATTRIBUTE,
} from './actionsConstants';

const isWhiteLabel = (icnReactBootstrap) => {
  if (!_.isEmpty(icnReactBootstrap.white_label_partner) && icnReactBootstrap.white_label_partner !== '') {
    return icnReactBootstrap.white_label_partner.id !== 0;
  } else {
    return false;
  }
};

function setIcnBootstrap(
  {
    icnBootstrap,
    mainAppUrl,
    favicon,
    header_logo,
    public_logo,
    csrf_token,
    wlpStyles,
  }
) {
  icnBootstrap['main_app_url'] = mainAppUrl;
  icnBootstrap['favicon'] = favicon;
  icnBootstrap['header_logo'] = header_logo;
  icnBootstrap['public_logo'] = public_logo;
  icnBootstrap['csrf_token'] = csrf_token;
  icnBootstrap['is_wlp'] = isWhiteLabel(icnBootstrap);
  icnBootstrap['wlpStyles'] = wlpStyles;

  const { language_code, id: user_id } = icnBootstrap?.user || {};
  setNotificationContext({ user_id });

  if (i18n.resolvedLanguage !== language_code) {
    setCurrentLanguage({ language_code });
  }

  return {
    type: SET_ICN_BOOTSTRAP,
    payload: icnBootstrap,
  };
}

const addReferralCode = (rawCode) => ({
  type: ADD_REFERRAL_CODE,
  payload: rawCode,
});

const setCurrentLanguage = (language) => {
  i18n.changeLanguage(language.language_code);

  return {
    type: UPDATE_CURRENT_LANGUAGE,
    payload: language,
  };
};

const updateCurrentLanguage = (languageCode) => patchRequest({
  url: 'users/update_language',
  params: { language_code: languageCode },
  onSuccess: (payload) => setCurrentLanguage(payload),
});

const updateUserAttributes = (payload) => ({
  type: UPDATE_USER_ATTRIBUTE,
  payload,
});

export default {
  setIcnBootstrap,
  addReferralCode,
  updateCurrentLanguage,
  updateUserAttributes,
};
