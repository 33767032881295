import {
  getRequest, postRequest, patchRequest, deleteRequest,
} from 'actions/utils';

export default function oRequest(endpoint, params, successAction, extra) {
  return getRequest({
    url: endpoint,
    params,
    onSuccess: successAction,
    ...extra,
  });
}

export function oPost(endpoint, params, successAction, extra) {
  return postRequest({
    url: endpoint,
    params,
    onSuccess: successAction,
    successMessage: { title: '', message: 'The underlying capital event has been successfully updated.' },
    errorMessage: { title: '', message: 'The underlying capital event has failed to update.' },
    ...extra,
  });
}

export function oPatch(endpoint, params, successAction, extra, successMessage) {
  return patchRequest({
    url: endpoint,
    params,
    onSuccess: successAction,
    successMessage: successMessage,
    ...extra,
  });
}

export function oDelete(endpoint, params, successAction, extra) {
  return deleteRequest({
    url: endpoint,
    params,
    onSuccess: successAction,
    successMessage: { title: '', message: 'The Underlying Capital Event has been deleted.' },
    errorMessage: { title: '', message: 'This Underlying Capital Event does not exist anymore.' },
    ...extra,
  });
}
