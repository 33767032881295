import {
  FLEXIBLE_FEED_DASHBOARD_SET_FILTER_OPTIONS,
  FLEXIBLE_FEED_DASHBOARD_CLEAR_FILTERS,
} from 'actions/actionsConstants';

import { updateState } from '../helpers';
import initialState from '../initialState';

export default (state = initialState, action) => {
  switch (action.type) {
    case FLEXIBLE_FEED_DASHBOARD_SET_FILTER_OPTIONS:
      return updateState(state, action.payload, (data) => ({
        filterOptions: { ...state.filterOptions, ...data },
      }));
    case FLEXIBLE_FEED_DASHBOARD_CLEAR_FILTERS:
      return updateState(state, action.payload, (data) => ({
        filterOptions: {},
      }));
    default:
      return state;
  }
};
