import { combineReducers } from 'redux';
import formTemplatesShowReducer from './show/reducer';
import formTemplatesListReducer from './list/reducer';
import formTemplateVersionsReducer from './versions/reducer';

export default combineReducers({
  show: formTemplatesShowReducer,
  list: formTemplatesListReducer,
  versions: formTemplateVersionsReducer,
});
