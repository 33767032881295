import {
  RGA_SET_ACTIVE_MODAL,
  RGA_SET_SELECTED_ROW,
  RGA_LOAD_CONFIG_SHARED_USERS,
  RGA_CONFIG_SHARED_USERS_COUNT,
} from 'actions/actionsConstants';

import update from 'immutability-helper';

import initialState from 'reducers/report_generator_reducers/initialState';

export default (state = initialState, action) => {
  switch (action.type) {
    case RGA_SET_ACTIVE_MODAL:
      return update(state, { active_modal: { $set: action.payload } });
    case RGA_SET_SELECTED_ROW:
      return update(state, { selected_row: { $set: action.payload } });
    case RGA_LOAD_CONFIG_SHARED_USERS:
      return update(state, { config_shared_users: { $set: action.payload } });
    case RGA_CONFIG_SHARED_USERS_COUNT:
      return update(state, {
        config_shared_users_count: { $set: action.payload },
      });
    default:
      return state;
  }
};
