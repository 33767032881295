/* eslint-disable max-lines */
/* eslint-disable max-statements */
/* eslint-disable max-lines-per-function */
import axios from 'axios';
import store from 'icnStore';
import { formatDate } from 'services/DateUtils';

import {
  DC_SET_RESULTS,
  DC_FAILED_API_REQUEST,
  DC_SET_IMPERSONATE_USER_EMAIL,
  DC_SET_SORT,
  DC_SET_PAGE,
  DC_SET_VIEW_TABLE_OPERATION_DOCS,
  DC_SET_ALL_FILTERS,
  DC_SET_NOTE_RESULT,
  DC_SET_IS_FETCHING,
  DC_SET_IMPERSONATE_USER_ID,
  DC_FETCH_LOGIN_AUTO_SUGGEST,
  DC_SET_LOGIN_FETCHING,
  DC_SET_IS_TABLE_FETCHING,
} from 'actions/actionsConstants';

import dcDownloadActions from '../doc_center_download_actions/dcDownloadActions';
import dcFilterListActions from '../doc_center_filter_actions/dcFilterListActions';

const {
  toggleSelectAll,
  toggleDownloadError,
} = dcDownloadActions;

const {
  dcSetFilterList,
  dcGetSelectedFilters,
} = dcFilterListActions;

// state.isFetching = false (hide loader)
function failedApiRequest(error) {
  console.log('An Error Occured', error);
  return {
    type: DC_FAILED_API_REQUEST,
    error,
  };
}

// transform JSON to whatever reducer expects
function dcSetResults(json) {
  return {
    type: DC_SET_RESULTS,
    data: json,
  };
}

const applyHotButtons = (filters, listExternalDocTypes) => {
  const listNames = listExternalDocTypes.map((e) => e.name);
  const filtered = filters.filter((e) => !(e.filter_type === 'external_document_type' &&
    listNames.includes(e.typed_filter_id)));
  return filtered.concat(listExternalDocTypes.map((externalDoc) => ({
    filter_type: 'external_document_type',
    typed_filter_id: externalDoc.name,
    content: '',
    doc_type: externalDoc.doc_type,
  })));
};

const dcSetNoteResult = (docId) => (dispatch) => {
  dispatch({ type: DC_SET_NOTE_RESULT, payload: docId });
};

function dcSetSort(sort) {
  const dcDownloads = store.getState().dcDownloads;
  if (!dcDownloads.allSelected) {
    store.dispatch(toggleSelectAll({ showBanner: false }));
  }

  return {
    type: DC_SET_SORT,
    data: sort,
  };
}

function dcSetViewOperationDocs(viewOperationDocs) {
  return {
    type: DC_SET_VIEW_TABLE_OPERATION_DOCS,
    data: viewOperationDocs,
  };
}

function dcSetPage(page) {
  const dcDownloads = store.getState().dcDownloads;
  if (!dcDownloads.allSelected) {
    store.dispatch(toggleSelectAll({ showBanner: false }));
  }

  return {
    type: DC_SET_PAGE,
    data: page,
  };
}

function getPerPage(state) {
  return { per_page: state.dcResultsPerPage.results_per_page };
}

function getSort(state) {
  return state.dcResults.sort;
}

function getPage(state) {
  return { page: state.dcResults.page };
}

function getImpersonateUserEmail(state) {
  const impersonateUserEmail = state.dcResults.impersonate_user_email;

  if (impersonateUserEmail === null || impersonateUserEmail === '') {
    return {};
  }

  return {
    impersonate_user_email: state.dcResults.impersonate_user_email,
  };
}

// Created to update the previous filters that would have its content empty
function updateFilterApplied(newMetaFilters, dcSelectedFilters) {
  const allFilters = Object.keys(newMetaFilters).map(
    (e) => newMetaFilters[e].filter_options
  ).filter((e) => e).reduceRight((prev, current) => prev.concat(current), []);

  const newSelectedFilters = {};
  const keys = Object.keys(dcSelectedFilters);

  for (let index = 0; index < keys.length; index++) {
    const key = keys[index];
    const filter = dcSelectedFilters[key];
    if (key === 'sequence') {
      newSelectedFilters[key] = filter;
    } else {
      newSelectedFilters[key] = dcSelectedFilters[key].map((selectedFilter) => {
        if (!selectedFilter.content) {
          const newFilter = { ...selectedFilter };
          const filterValues = allFilters.find((e) => (
            selectedFilter.filter_type === e.filter_type &&
            selectedFilter.typed_filter_id === e.typed_filter_id
          ));
          if (filterValues && filterValues.content) {
            newFilter.content = filterValues.content;
          }
          return newFilter;
        }
        return selectedFilter;
      });
    }
  }

  return newSelectedFilters;
}

const setDocCenterIsFetching = (bool) => ({
  type: DC_SET_IS_FETCHING,
  payload: bool,
});

const setDocCEnterIsTableFetching = (bool) => ({
  type: DC_SET_IS_TABLE_FETCHING,
  payload: bool,
});

const getImpersonateLogin = (state) => {
  const impersonateUserId = state.dcResults.impersonate_user_id;

  if (impersonateUserId === null || impersonateUserId === '') {
    return {};
  }

  return {
    impersonate_user_id: impersonateUserId.id,
  };
};

// called from component, resolves/reject promise
function dcGetResults(isTableAction = false) {
  return (dispatch, getState) => {
    const filters = dcGetSelectedFilters(getState());
    const perPage = getPerPage(getState());
    const sort = getSort(getState());
    const page = getPage(getState());
    const dcSelectedFilters = getState().dcFilters;
    const impersonateUserEmail = getImpersonateUserEmail(getState());
    const impersonateLogin = getImpersonateLogin(getState());
    const selectedHotButton = getState().dcHotButtons.selectedHotButton;
    const typeHotButton = getState().dcHotButtons.typeHotButton;
    const isOperationDocs = getState().dcResults.view_operation_docs;

    // Apply Hot Buttons Logic
    if (!isOperationDocs && selectedHotButton) {
      const filtersWithHotButtonApplied = applyHotButtons(filters.filters, selectedHotButton.external_document_types);
      filters.filters = filtersWithHotButtonApplied;
      if (selectedHotButton.timeframe_start || selectedHotButton.timeframe_end) {
        filters.filters.push({
          filter_type: 'effective_date',
          typed_filter_id: `${selectedHotButton.timeframe_start || '01/01/00T00:00:00'} - ${selectedHotButton.timeframe_end || '12/31/99T23:59:59'}`,
          content: '',
          type: 'defaultFilter',
        });
      } else if (typeHotButton === 'ieq') {
        // Needs the last 2 digits of the Full Year.
        const lastYear = (new Date().getFullYear() - 1).toString().slice(-2);
        filters.filters.push({
          filter_type: 'effective_date',
          typed_filter_id: `01/01/${lastYear}T00:00:00 - 12/31/${lastYear}T23:59:59`,
          content: '',
          type: 'defaultFilter',
        });
      }
    }

    const params = {
      ...filters,
      ...perPage,
      ...sort,
      ...page,
      ...impersonateUserEmail,
      ...impersonateLogin,
      view_operation_docs: isOperationDocs,
    };

    if (isTableAction) {
      dispatch(setDocCEnterIsTableFetching(true));
    } else {
      dispatch(setDocCenterIsFetching(true));
    }

    return axios({
      url: 'doc_center',
      method: 'post',
      withCredentials: true,
      data: params,
    }).then((response) => response.data)
      .then((json) => {
        json.docs.forEach((doc) => {
          doc.publish_date = formatDate(doc.publish_date, 'dd-MMM-yyyy');
        });

        let docFilters = null;
        let advisoryFirmFilters = null;
        let advisoryFirmFiltersCount = null;
        let fundFilters = null;
        let fundFiltersCount = null;
        let fundAdminGroupFilters = null;
        let investorsFilters = null;
        let investorsFiltersCount = null;
        let accountNameFiltersCount = null;
        let accountNumberFiltersCount = null;
        let accountNameFilters = null;
        let accountNumberFilters = null;
        let advisorFilters = null;
        let advisorFiltersCount = null;
        let displayNameFilters = null;
        let displayNameFiltersCount = null;
        let docFiltersCount = null;
        let fundAdminGroupFiltersCount = null;
        let whiteLabelPartnerFilters = null;
        let whiteLabelPartnerFiltersCount = null;

        if (json.meta.filters.external_document_type) {
          docFilters = json.meta.filters.external_document_type.filter_options;
          docFiltersCount = json.meta.filters.external_document_type.total_count;
        }
        if (json.meta.filters.advisory_firm) {
          advisoryFirmFilters = json.meta.filters.advisory_firm.filter_options;
          advisoryFirmFiltersCount = json.meta.filters.advisory_firm.total_count;
        }
        if (json.meta.filters.fund_id) {
          fundFilters = json.meta.filters.fund_id.filter_options;
          fundFiltersCount = json.meta.filters.fund_id.total_count;
        }
        if (json.meta.filters.fund_admin_group_id) {
          fundAdminGroupFilters = json.meta.filters.fund_admin_group_id.filter_options;
          fundAdminGroupFiltersCount = json.meta.filters.fund_admin_group_id.total_count;
        }
        if (json.meta.filters.investors) {
          investorsFilters = json.meta.filters.investors.filter_options;
          investorsFiltersCount = json.meta.filters.investors.total_count;
        }
        if (json.meta.filters.original_investor_profile_id) {
          accountNameFilters = json.meta.filters.original_investor_profile_id.filter_options;
          accountNameFiltersCount = json.meta.filters.original_investor_profile_id.total_count;
        }
        if (json.meta.filters.account_number) {
          accountNumberFilters = json.meta.filters.account_number.filter_options;
          accountNumberFiltersCount = json.meta.filters.account_number.total_count;
        }
        if (json.meta.filters.advisor_contact_cards) {
          advisorFilters = json.meta.filters.advisor_contact_cards.filter_options;
          advisorFiltersCount = json.meta.filters.advisor_contact_cards.total_count;
        }
        if (json.meta.filters.display_name) {
          displayNameFilters = json.meta.filters.display_name.filter_options;
          displayNameFiltersCount = json.meta.filters.display_name.total_count;
        }
        if (json.meta.filters.white_label_partner) {
          whiteLabelPartnerFilters = json.meta.filters.white_label_partner.filter_options;
          whiteLabelPartnerFiltersCount = json.meta.filters.white_label_partner.total_count;
        }

        const updatedDCSelectedFilters = updateFilterApplied(json.meta.filters, dcSelectedFilters);

        dispatch({
          type: DC_SET_ALL_FILTERS,
          payload: updatedDCSelectedFilters,
        });
        dispatch(
          dcSetFilterList('advisor', advisorFilters, dcSelectedFilters.advisor, advisorFiltersCount)
        );
        dispatch(
          dcSetFilterList('external_document_type', docFilters, dcSelectedFilters.external_document_type, docFiltersCount)
        );
        dispatch(
          dcSetFilterList(
            'advisory_firm',
            advisoryFirmFilters,
            dcSelectedFilters.advisory_firm,
            advisoryFirmFiltersCount
          )
        );
        dispatch(
          dcSetFilterList('fund', fundFilters, dcSelectedFilters.fund, fundFiltersCount)
        );
        dispatch(
          dcSetFilterList('fund_admin_group', fundAdminGroupFilters, dcSelectedFilters.fund_admin_group, fundAdminGroupFiltersCount)
        );
        dispatch(
          dcSetFilterList(
            'investors',
            investorsFilters,
            dcSelectedFilters.investors,
            investorsFiltersCount
          )
        );
        dispatch(
          dcSetFilterList(
            'investor_profile_id',
            accountNameFilters,
            dcSelectedFilters.investor_profile_id,
            accountNameFiltersCount
          )
        );
        dispatch(
          dcSetFilterList(
            'account_number',
            accountNumberFilters,
            dcSelectedFilters.account_number,
            accountNumberFiltersCount
          )
        );

        dispatch(
          dcSetFilterList(
            'display_name',
            displayNameFilters,
            dcSelectedFilters.display_name,
            displayNameFiltersCount
          )
        );

        dispatch(
          dcSetFilterList(
            'white_label_partner',
            whiteLabelPartnerFilters,
            dcSelectedFilters.white_label_partner,
            whiteLabelPartnerFiltersCount
          )
        );
        return dispatch(dcSetResults(json));
      })
      .catch((error) => {
        dispatch(setDocCenterIsFetching(false));
        dispatch(failedApiRequest(error));
        dispatch(toggleDownloadError({
          downloadError: {
            title: 'Document Center',
            text: 'Something went wrong. Please, try again after some time.',
          },
        }));
      });
  };
}

function dcSetImpersonateUserEmail(value) {
  return {
    type: DC_SET_IMPERSONATE_USER_EMAIL,
    data: value,
  };
}

function dcSetImpersonateUserId(value) {
  return {
    type: DC_SET_IMPERSONATE_USER_ID,
    data: value,
  };
}

const CancelToken = axios.CancelToken;
let cancel;
function dcLoginAutoSuggest(value) {
  if (cancel !== undefined) {
    cancel();
    cancel = undefined;
  }

  store.dispatch({
    type: DC_SET_LOGIN_FETCHING,
    payload: true,
  });
  return axios({
    url: 'doc_center/login_auto_suggest',
    method: 'post',
    withCredentials: true,
    data: { input: value },
    cancelToken: new CancelToken(((c) => {
      cancel = c;
    })),
  }).then((response) => response.data)
    .then((json) => {
      store.dispatch({
        type: DC_SET_LOGIN_FETCHING,
        payload: false,
      });

      return {
        type: DC_FETCH_LOGIN_AUTO_SUGGEST,
        payload: json,
      };
    });
}

export default {
  dcGetSort: getSort,
  dcGetResults,
  setDocCEnterIsTableFetching,
  dcSetResults,
  dcSetSort,
  dcSetViewOperationDocs,
  dcSetPage,
  dcSetImpersonateUserEmail,
  dcGetPage: getPage,
  dcGetPerPage: getPerPage,
  dcSetNoteResult,
  dcSetImpersonateUserId,
  dcLoginAutoSuggest,
};
