import update from 'immutability-helper';
import {
  WLP_INVESTOR_PROFILE_DATA,
  WLP_EXTERNAL_REVIEW_DATA,
  WLP_CONTACT_CARD_DATA,
  WLP_NEW_ISSUES_PROFILE_DATA,
  WLP_CURRENT_DATA,
} from './WlpAdmin.actionsContants';

export const initialState = {
  currentData: null,
  investorProfileData: [],
  externalReviewData: [],
  contactCardData: [],
  newIssuesProfileData: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case WLP_INVESTOR_PROFILE_DATA: {
      return update(state, { investorProfileData: { $set: action.payload.fields } });
    }
    case WLP_EXTERNAL_REVIEW_DATA: {
      return update(state, { externalReviewData: { $set: action.payload.fields } });
    }
    case WLP_CONTACT_CARD_DATA: {
      return update(state, { contactCardData: { $set: action.payload.attributes } });
    }
    case WLP_NEW_ISSUES_PROFILE_DATA: {
      return update(state, { newIssuesProfileData: { $set: action.payload.question_groups } });
    }
    case WLP_CURRENT_DATA: {
      return update(state, { currentData: { $set: action.payload.white_label_partner } });
    }
    default:
      return state;
  }
};
