import update from 'immutability-helper';
import { flatten } from 'lodash';

import {
  CE_CASH_FUNDING_SET_FILTER_OPTIONS,
  CE_CASH_FUNDING_CUSTODIAN_LIST,
  CE_CASH_FUNDING_FIRM_LIST,
  CE_CASH_FUNDING_MODAL_OPEN,
  CE_CASH_FUNDING_MODAL_CLOSE,
  CE_CASH_FUNDING_SET_SELECTED_CUSTODIANS,
  CE_CASH_FUNDING_SET_SELECTED_FIRMS,
  CE_CASH_FUNDING_SET_SELECTED_ITEMS_TO_MODAL,
  CE_CASH_FUNDING_SET_MODAL_SELECTED_CUSTODIANS,
  CE_CASH_FUNDING_SET_MODAL_SELECTED_FIRMS,
  CE_CASH_FUNDING_SET_DETAILS,
  CE_CASH_FUNDING_UPDATE_DETAILS,
  CE_CASH_FUNDING_EXPORT_DOCS_LIST,
  CE_CASH_FUNDING_EVENTS_LIST,
  CE_CASH_FUNDING_REFRESH_GRID_INFO,
  CE_CASH_FUNDING_UPLOAD_RESULTS,
  CE_CASH_FUNDING_BUCKETS_COUNT,
  CE_CASH_FUNDING_RESET_WIRE_AMOUNT_INPUT,
  CE_CASH_FUNDING_CAPITAL_EVENT_DETAILS,
  CE_CASH_FUNDING_CAPITAL_EVENT_SELECT_ALL,
  CE_CASH_FUNDING_CAPITAL_EVENT_BATCH_SELECT,
  CE_CASH_FUNDING_CAPITAL_EVENT_BATCH_SELECT_REMOVE,
  CE_CASH_FUNDING_CAPITAL_EVENT_CURRENT_TABLE_SELECT,
} from 'actions/actionsConstants';

export const initialState = {
  ceCashFundingCapEventDetails: {},
  ceCashFundingModalOpen: undefined,
  ceCashFundingModalContext: {},
  ceCashFundingModalApproveBatch: undefined,
  custodiansList: [],
  firmsList: [],
  selectedCustodians: [],
  selectedFirms: [],
  modalSelectedCustodians: [],
  modalSelectedFirms: [],
  cashFundingDetails: {},
  ceCashFundingExportDocsList: [],
  ceCashFundingExportDocsTotal: 0,
  ceCashFundingEventsList: [],
  ceCashFundingEventsListTotal: 0,
  ceCashFundingRefreshGridInfo: false,
  ceCashFundingUploadResults: [],
  bucketTotals: {},
  resetWireAmountInput: undefined,
  ceCashFundingSelectAll: {
    attentionRequired: false,
    rejected: false,
  },
  ceCashFundingBatchSelect: [],
  ceCashFundingCurrentTableSelect: undefined,
  ceCashFundingFilterOptions: [],
};

export default function ceCashFundingReducer(state = initialState, action) {
  switch (action.type) {
    case CE_CASH_FUNDING_SET_FILTER_OPTIONS:
      return update(state, {
        ceCashFundingFilterOptions: { $set: action.payload },
      });
    case CE_CASH_FUNDING_MODAL_OPEN:
      return update(state, {
        ceCashFundingModalOpen: { $set: action.payload.value },
        ceCashFundingModalContext: { $set: action.payload.context },
        ceCashFundingModalApproveBatch: { $set: action.payload.selectAll },
      });
    case CE_CASH_FUNDING_MODAL_CLOSE:
      return update(state, {
        ceCashFundingModalOpen: { $set: undefined },
        ceCashFundingModalContext: { $set: {} },
      });
    case CE_CASH_FUNDING_CUSTODIAN_LIST:
      return update(state, {
        custodiansList: { $set: action.payload },
      });
    case CE_CASH_FUNDING_FIRM_LIST:
      return update(state, {
        firmsList: { $set: action.payload },
      });
    case CE_CASH_FUNDING_SET_SELECTED_CUSTODIANS:
      return update(state, {
        selectedCustodians: { $set: action.payload },
      });
    case CE_CASH_FUNDING_SET_SELECTED_FIRMS:
      return update(state, {
        selectedFirms: { $set: action.payload },
      });
    case CE_CASH_FUNDING_SET_SELECTED_ITEMS_TO_MODAL:
      return update(state, {
        modalSelectedCustodians: { $set: state.selectedCustodians },
        modalSelectedFirms: { $set: state.selectedFirms },
      });
    case CE_CASH_FUNDING_SET_MODAL_SELECTED_CUSTODIANS:
      return update(state, {
        modalSelectedCustodians: { $set: action.payload },
      });
    case CE_CASH_FUNDING_SET_MODAL_SELECTED_FIRMS:
      return update(state, {
        modalSelectedFirms: { $set: action.payload },
      });
    case CE_CASH_FUNDING_SET_DETAILS:
      return update(state, {
        cashFundingDetails: { $set: action.payload },
      });
    case CE_CASH_FUNDING_UPDATE_DETAILS:
      return update(state, {
        cashFundingDetails: { $merge: action.payload },
      });
    case CE_CASH_FUNDING_EXPORT_DOCS_LIST:
      return update(state, {
        ceCashFundingExportDocsList: { $set: action.payload.exports },
        ceCashFundingExportDocsTotal: { $set: action.payload.total }
      });
    case CE_CASH_FUNDING_EVENTS_LIST:
      return update(state, {
        ceCashFundingEventsList: { $set: action.payload.events },
        ceCashFundingEventsListTotal: { $set: action.payload.events_total },
      });
    case CE_CASH_FUNDING_REFRESH_GRID_INFO:
      return update(state, {
        ceCashFundingRefreshGridInfo: { $set: action.payload },
      });
    case CE_CASH_FUNDING_UPLOAD_RESULTS:
      return update(state, {
        ceCashFundingUploadResults: { $set: action.payload },
      });
    case CE_CASH_FUNDING_BUCKETS_COUNT:
      return update(state, {
        bucketTotals: { $set: action.payload },
      });
    case CE_CASH_FUNDING_RESET_WIRE_AMOUNT_INPUT:
      return update(state, {
        resetWireAmountInput: { $set: action.payload },
      });
    case CE_CASH_FUNDING_CAPITAL_EVENT_DETAILS:
      return update(state, {
        ceCashFundingCapEventDetails: { $set: action.payload },
      });
    case CE_CASH_FUNDING_CAPITAL_EVENT_SELECT_ALL:
      return update(state, {
        ceCashFundingSelectAll: { $merge: { [action.payload.type]: action.payload.value }},
      });
    case CE_CASH_FUNDING_CAPITAL_EVENT_BATCH_SELECT:
      return update(state, {
        ceCashFundingBatchSelect: { $set: Array.from(new Set([...state.ceCashFundingBatchSelect, ...flatten([action.payload])])) },
      });
    case CE_CASH_FUNDING_CAPITAL_EVENT_BATCH_SELECT_REMOVE:
      const payload = flatten([action.payload]);
      const elems = state.ceCashFundingBatchSelect.filter((it) => !payload.includes(it))
      return update(state, {
        ceCashFundingBatchSelect: { $set: elems },
      });
    case CE_CASH_FUNDING_CAPITAL_EVENT_CURRENT_TABLE_SELECT:
      return update(state, {
        ceCashFundingCurrentTableSelect: { $set: action.payload },
      });
    default:
      return state;
  }
}
