import React from 'react';
import {
  func, shape,
} from 'prop-types';
import {
  Box, Checkbox, FormControlLabel, FormGroup, Typography,
} from '@icapitalnetwork/supernova-core';
import {
  capableEvaluatingInvestments, exerciseIndependentJudgment, responsibleVerificationClientEligibility,
} from './attestationData';

const AdditionalRiaAttestations = ({ updateStore, profileData }) => (
  <Box sx={{ marginTop: '12px' }}>
    <RiaAttestation
      dataPoint={capableEvaluatingInvestments}
      updateStore={updateStore}
      profileData={profileData}
    />
    <RiaAttestation
      dataPoint={exerciseIndependentJudgment}
      updateStore={updateStore}
      profileData={profileData}
    />
    <RiaAttestation
      dataPoint={responsibleVerificationClientEligibility}
      updateStore={updateStore}
      profileData={profileData}
    />
  </Box>
);

AdditionalRiaAttestations.propTypes = {
  updateStore: func.isRequired,
  profileData: shape({}).isRequired,
};

function RiaAttestation({ dataPoint, updateStore, profileData }) {
  const { questionKey, text } = dataPoint;
  return (
    <FormGroup>
      <FormControlLabel
        tietoid={questionKey}
        label={(
          <Typography sx={{ marginTop: '10px' }}>
            {text}
          </Typography>
        )}
        control={(
          <Checkbox
            data-testid={`${questionKey}-checkbox`}
            checked={profileData[questionKey] || false}
          />
        )}
        onChange={({ target: { checked } }) => updateStore({ [questionKey]: checked })}
      />
    </FormGroup>
  );
}

RiaAttestation.propTypes = {
  dataPoint: shape({}).isRequired,
  profileData: shape({}).isRequired,
  updateStore: func.isRequired,
};

export default AdditionalRiaAttestations;
