import { setEmptyValues, isIndividual, isEntity } from 'services/investor_profile';
import { DISREGARDED_ENTITY_FIELDS } from './investorDetails';

const resetFields = [
  'section_a_entity_type',
  'section_a_entity_type_other',
  'section_a_entity_phone',
  'section_a_entity_address',
  'section_a_entity_address_2',
  'section_a_entity_city',
  'section_a_entity_state',
  'section_a_entity_zip',
  'section_a_entity_state_country',
  'entity_domicile_state',
  'entity_domicile_country',
  'entity_jurisdiction_of_organization_country',
  'entity_jurisdiction_of_organization_state',
  'section_a_entity_tin',
  'section_a_entity_formation_date',
  'section_a_entity_tax_classification',
  'section_a_entity_other_tax_classification',
  'section_a_entity_tax_year_end',
  'section_a_entity_us_person',
  'section_a_entity_foia_partner',
  'section_a_individual_us_person',
  'section_a_individual_type',
  'section_a_individual_pone',
  'section_a_individual_address',
  'section_a_individual_address_2',
  'section_a_individual_city',
  'section_a_individual_state',
  'section_a_individual_zip',
  'section_a_individual_state_country',
  'section_a_mailing_address',
  'section_a_mailing_address_2',
  'section_a_mailing_city',
  'section_a_mailing_state',
  'section_a_mailing_zip',
  'section_a_mailing_state_country',
  'has_accredited_investor_certification',
  'disregarded_entity',
  ...DISREGARDED_ENTITY_FIELDS,
];

// 'section_a_individual_pone' above is not a typo.  will be fixed with a rake task

const defaultValues = (updateObj) => {
  const defaults = {};
  if (isEntity(updateObj)) {
    defaults.section_a_entity_state_country = 'United States of America';
    defaults.entity_domicile_country = 'United States of America';
    defaults.entity_jurisdiction_of_organization_country = 'United States of America';
  } else if (isIndividual(updateObj)) {
    defaults.section_a_individual_state_country = 'United States of America';
  }

  return defaults;
};

const sideEffects = (updateObj, profile) => {
  let modifiedObj = {};

  if (updateObj.signer_type) {
    if (profile.number_of_investments === 0) {
      modifiedObj = {
        ...setEmptyValues(resetFields),
        ...defaultValues(updateObj, profile),
      };
      if (isIndividual(updateObj) && profile.signers) {
        modifiedObj = {
          ...modifiedObj,
          signers: profile.signers.slice(0, 3),
        };
      }
    } else {
      delete updateObj.signer_type;
    }
  }

  return { ...updateObj, ...modifiedObj };
};

export default sideEffects;
