import { getRequest } from 'actions/utils';
import { createCRUD, createRequestTypes } from 'actions/helpers';

const BASE_NAME = 'massMailerFunds';

export const MASS_MAILER_ALL_FUNDS = createCRUD(BASE_NAME, {
  LIST: createRequestTypes(`${BASE_NAME}_LIST`),
});

export const MASS_MAILER_ALL_FUNDS_LIST = {
  UNMOUNT: `${BASE_NAME}_UNMOUNT`,
};

export const getAllFundsAutocomplete = (query) => getRequest({
  showSpinner: false,
  url: 'funds/autocomplete',
  actions: MASS_MAILER_ALL_FUNDS.LIST,
  params: { query },
});
