const REQUEST = 'REQUEST';
const SUCCESS = 'SUCCESS';
const FAILURE = 'FAILURE';

/**
 * Create actions types for api requests
 * Example:
 * call createRequestTypes('TEST')
 * return {
 *   REQUEST: 'TEST_REQUEST',
 *   SUCCESS: 'TEST_SUCCESS',
 *   FAILURE: 'TEST_FAILURE',
 * }
 * @param {string} base
 * @param {Object} object to extend
 * @return {{REQUEST: string, SUCCESS: string, FAILURE: string}}
 */
export const createRequestTypes = (base, extend = {}) => {
  const res = extend;
  [REQUEST, SUCCESS, FAILURE].forEach((type) => {
    res[type] = `${base}_${type}`;
  });
  return res;
};

const CREATE = 'CREATE';
const READ = 'READ';
const UPDATE = 'UPDATE';
const DELETE = 'DELETE';

/**
 * Create CRUD actions types
 * for example:
 * call createCRUD('TEST', { OTHER_ACTION_TYPE:'OTHER_ACTION_TYPE' })
 * return {
 *   CREATE: 'TEST_CREATE',
 *   READ: 'TEST_READ',
 *   UPDATE: 'TEST_UPDATE',
 *   DELETE: 'TEST_DELETE'
 *   OTHER_ACTION_TYPE: 'TEST_OTHER_ACTION_TYPE'
 * }
 * @param {string} base base action name
 * @param {Object} extend object will be extended with CRUD actions
 * @return {{CREATE: string, READ: string, UPDATE: string, DELETE: string}}
 */
export function createCRUD(base, extend = {}) {
  const result = extend;
  [CREATE, READ, UPDATE, DELETE].forEach((method) => {
    result[method] = createRequestTypes(`${base}_${method}`);
  });
  return result;
}

export default {
  createRequestTypes,
  createCRUD,
};
