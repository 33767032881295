/* eslint-disable max-lines-per-function */
import React from 'react';
import { bool, shape, string } from 'prop-types';
import { connect } from 'react-redux';
import parse from 'html-react-parser';
import * as ioSelectors from 'modules/investment_opportunity/selectors';

import { isWhiteLabelPartner } from 'selectors/icnBootstrapSelectors';
import { Typography } from '@icapitalnetwork/supernova-core';

export const OpportunitiesDisclaimer = ({
  isWLP,
  whiteLabelPartner,
  showDiligenceFilter,
  showIsDistributedByIcapitalFilter,
  customDisclaimer,
  templateDisclaimer,
}) => {
  const getDisclaimerSmallParagraphElement = (text) => <Typography variant="smallParagraph" paragraph>{text}</Typography>;
  const getDisclaimerColumnHeaderElement = (text) => <Typography variant="columnHeader" paragraph>{text}</Typography>;

  const diligenceDisclaimer = () => getDisclaimerSmallParagraphElement(
    'iCapital diligence does not constitute a personal recommendation or take into account the particular investment objectives, financial situations, or needs of the Receiving Party. Additionally, a fund that has received iCapital diligence does not constitute a recommendation over a fund that has not received diligence. iCapital, in providing diligence, is not providing investment advice and does not recommend or endorse any private capital fund, private capital manager, private capital segment, or any investment opportunity.'
  );

  const distributionDisclaimer = () => getDisclaimerSmallParagraphElement(
    'Securities may be offered through iCapital Markets, LLC, a registered broker dealer, member of FINRA and SIPC and subsidiary of Institutional Capital Network, Inc. (d/b/a iCapital). iCapital Advisors, LLC, a subsidiary of Institutional Capital Network, Inc (d/b/a iCapital), is an investment adviser registered with the U.S. Securities and Exchange Commission ("SEC"). These registrations and memberships in no way imply that the SEC, FINRA or SIPC have endorsed the entities, products or services discussed herein.'
  );

  const safeParse = (text) => (typeof text === 'string' ? parse(text) : text);

  const defaultDisclaimer = () => (
    <>
      {customDisclaimer && getDisclaimerSmallParagraphElement(safeParse(customDisclaimer))}
      {templateDisclaimer && getDisclaimerSmallParagraphElement(safeParse(templateDisclaimer))}
      {getDisclaimerColumnHeaderElement('Please read the important disclosures below.')}
      {showDiligenceFilter && diligenceDisclaimer()}
      {showIsDistributedByIcapitalFilter && distributionDisclaimer()}

      {getDisclaimerSmallParagraphElement(safeParse(
        'Securities products and services are offered through iCapital Markets LLC ("iCapital Markets"), a broker-dealer registered with the US Securities and Exchange Commission (the "SEC") and a member of the <a href="http://www.finra.org">Financial Industry Regulatory Authority (FINRA)</a> and the <a href="http://sipc.org"> Securities Investor Protection Corporation (SIPC)</a>.'
      ))}
      {getDisclaimerSmallParagraphElement(safeParse(
        'As described in more detail below, iCapital Advisors, LLC ("iCapital Advisors"), an SEC registered investment adviser, is the sponsor or investment adviser to certain private investment funds on the <a href="http://www.icapitalnetwork.com">www.icapitalnetwork.com</a> website (the "iCapital Platform"). iCapital Registered Fund Adviser, LLC ("iCapital Registered Adviser"), an SEC registered investment adviser, is the adviser to iDirect Private Markets Fund ("iDPE"). The iCapital Platform is operated by iCapital Markets, iCapital Advisors, iCapital Registered Adviser, and its and their affiliates including Institutional Capital Network, Inc. (collectively, "iCapital").'
      ))}
      {getDisclaimerSmallParagraphElement(
        'By accessing this website, you agree to be bound by the iCapital Privacy Policy and Terms of Service.'
      )}
      {getDisclaimerSmallParagraphElement(
        "Investing in alternative investment products, like the products included on the iCapital Platform, entails a high degree of risk (including the possible loss of a substantial part, or even the entire amount, of an investment) and no assurance can be given that any alternative investment product's investment objectives will be achieved or that investors will receive a return of their capital. Furthermore, you should be aware that certain financial products (including iCapital Access Funds) on the iCapital Platform may be exempt from registration with the SEC and certain financial products may be registered with the SEC but not listed on any securities exchange. That means that there may be significantly less information available about such financial products and those products may be subject to less regulatory scrutiny as compared to securities that are registered with the SEC and/or listed on a securities exchange. Prospective investors should also note that investments in the products described typically involve long lock-ups, meaning that an investor may not be able to sell, in whole or in part, an investment in a financial product for an extended period of time."
      )}
      {getDisclaimerSmallParagraphElement(
        'Past performance does not guarantee future results. Certain financial products on the iCapital Platform may use leverage or engage in speculative investment practices that could increase the risk of investment loss; may be highly illiquid; may not be required to provide periodic pricing or valuation information to investors; may involve complex tax structures and delays in distributing important tax information; and often charge high fees.'
      )}
      {getDisclaimerSmallParagraphElement(
        'Certain data on this site has been calculated and/or provided by third party data providers or by the general partner, sponsor, or investment adviser to the relevant financial product (and, in the case of an iCapital Access Fund, the sponsor or adviser of the Underlying Fund). In either case, data provided by third parties has not been independently verified or confirmed by iCapital or any other party, and iCapital does not assume any liability that may arise from the inaccuracy of such data. iCapital makes no representation or warranty as to the completeness, adequacy or accuracy of any information provided by a third-party data provider, issuer, sponsor, or investment adviser.'
      )}
      {getDisclaimerSmallParagraphElement(safeParse(
        'Be aware that the information in a fund profile for an iCapital Access Fund generally is provided by the third-party sponsor or adviser to the Underlying Fund and relates to the Underlying Fund, not the iCapital Access Fund. <b>This includes performance information and, unless otherwise specified, such information does not take into account fees and expenses charged by the iCapital Access Fund (accordingly, performance of any iCapital Access Fund would be lower than the performance of the corresponding Underlying Fund).</b> You should carefully review whether any performance information relates to the iCapital Access Fund, the Underlying Fund, or performance of a "related" fund or financial product (a fund or financial product that uses a similar strategy and is managed by the same sponsor). You should carefully review the documents provided in the "Documents" tab including the final offering documents.'
      ))}
    </>
  );

  const getWlpDisclaimer = () => (
    <>
      {customDisclaimer && getDisclaimerSmallParagraphElement(safeParse(customDisclaimer))}
      {templateDisclaimer && getDisclaimerSmallParagraphElement(safeParse(templateDisclaimer))}
      {showDiligenceFilter && diligenceDisclaimer()}
      {showIsDistributedByIcapitalFilter && distributionDisclaimer()}
      {getDisclaimerSmallParagraphElement(parse(whiteLabelPartner?.disclaimer))}
    </>
  );

  const currentDisclaimer = () => (isWLP && whiteLabelPartner?.custom_disclaimer
    ? getWlpDisclaimer()
    : defaultDisclaimer());

  return currentDisclaimer();
};

const mapStateToProps = (state) => ({
  isWLP: isWhiteLabelPartner(state),
  whiteLabelPartner: state.icnReactBootstrap.icn_react_bootstrap.white_label_partner,
  showDiligenceFilter: ioSelectors.showDiligenceFilter(state),
  showIsDistributedByIcapitalFilter: ioSelectors.showIsDistributedByIcapitalFilter(state),
});

OpportunitiesDisclaimer.defaultProps = {
  dataTestId: undefined,
  isWLP: false,
  showDiligenceFilter: false,
  showIsDistributedByIcapitalFilter: false,
  whiteLabelPartner: { disclaimer: '' },
  customDisclaimer: null,
  templateDisclaimer: null,
};

OpportunitiesDisclaimer.propTypes = {
  isWLP: bool,
  whiteLabelPartner: shape({
    disclaimer: string,
  }),
  showDiligenceFilter: bool,
  showIsDistributedByIcapitalFilter: bool,
  dataTestId: string,
  customDisclaimer: string,
  templateDisclaimer: string,
};

export default connect(mapStateToProps)(OpportunitiesDisclaimer);
