import { actionFactory } from 'services/actions';

import {
  RGA_LOADING_OPTIONS_FAILURE,
  RGA_LOADING_OPTIONS_REQUEST,
  RGA_LOADING_OPTIONS_SUCCESS,
  RGA_SET_NUMERIC_FILTER_OPTIONS,
  RGA_REMOVE_NUMERIC_FILTERED_OPTIONS,
  RGA_SET_NUMERIC_FILTER_TYPE,
  RGA_SET_NUMERIC_FILTER_RANGE,
  RGA_SET_NUMERIC_NULL_OPTION,
  RGA_SET_STRING_FILTER_OPTIONS,
  RGA_REMOVE_STRING_FILTERED_OPTIONS,
} from 'actions/actionsConstants';

import { getFilteredOptionsForFilter } from 'reducers/report_generator_reducers/selectors';

export const loadingOptionsRequest = actionFactory(RGA_LOADING_OPTIONS_REQUEST);
export const loadingOptionsSuccess = actionFactory(RGA_LOADING_OPTIONS_SUCCESS);
export const loadingOptionsFailure = actionFactory(RGA_LOADING_OPTIONS_FAILURE);

export const reportGeneratorConfigsSetNumericFilterOptions = actionFactory(
  RGA_SET_NUMERIC_FILTER_OPTIONS,
);
export const reportGeneratorConfigsRemoveNumericFilteredOption = actionFactory(
  RGA_REMOVE_NUMERIC_FILTERED_OPTIONS,
);
export const reportGeneratorConfigsSetNumericFilterType = actionFactory(
  RGA_SET_NUMERIC_FILTER_TYPE,
);
export const reportGeneratorConfigsSetNumericFilterRange = actionFactory(
  RGA_SET_NUMERIC_FILTER_RANGE,
);
export const reportGeneratorConfigsSetNullOption = actionFactory(
  RGA_SET_NUMERIC_NULL_OPTION,
);

export const reportGeneratorConfigsSetStringFilterOptions = actionFactory(
  RGA_SET_STRING_FILTER_OPTIONS,
);
export const reportGeneratorConfigsRemoveStringFilteredOption = actionFactory(
  RGA_REMOVE_STRING_FILTERED_OPTIONS,
);

export const resetStringFilter = (payload) => (dispatch, getState) => {
  const { filter } = payload;
  const filteredOptions = getFilteredOptionsForFilter(getState(), filter) || [];
  dispatch(
    reportGeneratorConfigsRemoveStringFilteredOption({
      filter,
      options: filteredOptions,
    }),
  );
  dispatch(reportGeneratorConfigsSetNullOption({ filter, nullOption: false }));
};

export const resetNumericFilter = (payload) => (dispatch, getState) => {
  const { filter } = payload;
  const filteredOptions = getFilteredOptionsForFilter(getState(), filter) || [];
  dispatch(
    reportGeneratorConfigsRemoveNumericFilteredOption({
      filter,
      options: filteredOptions,
    }),
  );
  dispatch(
    reportGeneratorConfigsSetNumericFilterType({
      filter,
      filter_type: 'value',
    }),
  );
  dispatch(reportGeneratorConfigsSetNullOption({ filter, nullOption: false }));
};
