export const publicPath = '/icn_react/static/';
export const baseRoute = `${publicPath}passport/`;

export const routeCodes = {
  BASE: baseRoute,
  ONBOARDING: `${baseRoute}onboarding`,
  SELECT_ACCOUNTS: `${baseRoute}select_accounts`,
  ACCOUNT_LINK: `${baseRoute}account_link`,
  MANAGEMENT: `${baseRoute}management`,
};
