import {
  BEGIN_API_REQUEST,
  SET_SPINNER_TEXT,
  END_API_REQUEST,
  FAILED_API_REQUEST,
  SET_SPINNER_DISPLAY,
} from 'actions/actionsConstants';

export function beginApiRequest() {
  return {
    type: BEGIN_API_REQUEST,
  };
}

export function endApiRequest() {
  return {
    type: END_API_REQUEST,
  };
}

export function setSpinnerText(payload) {
  return {
    type: SET_SPINNER_TEXT,
    payload,
  };
}

export function failedApiRequest(error) {
  console.log('An Error Occured', error);
  /** /
  return {
    type: FAILED_API_REQUEST,
    error: error,
  };
  /* */
  return {
    type: END_API_REQUEST,
  };
}

export function setSpinnerDisplay(payload) {
  return {
    type: SET_SPINNER_DISPLAY,
    payload,
  };
}

export default {
  beginApiRequest,
  setSpinnerText,
  endApiRequest,
  failedApiRequest,
  setSpinnerDisplay,
};
