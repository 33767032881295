import update from 'immutability-helper';
import {
  LP_SET_IS_LOADING_INSIGHTS_AND_RESEARCH,
  LP_ADD_MORE_INSIGHTS,
  LP_SET_INSIGHTS,
  LP_SET_INSIGHTS_CATEGORIES_SELECTED,
  LP_SET_INSIGHTS_FILTER,
  LP_SET_INSIGHTS_LOADING,
} from 'modules/landing_page/actions/constants';
import initialState from '../initialState';

export function addMoreInsights(state, { icapital_insights: nextInsights, total_count: insightsSize }) {
  return update(
    state, { insights: { list: { $push: nextInsights }, size: { $set: insightsSize } }, loading: { $set: false } }
  );
}

export function setInsights(state, { icapital_insights: insights, total_count: insightsSize, categories }) {
  return update(state, {
    insights: { list: { $set: insights }, size: { $set: insightsSize }, categories: { $set: categories } },
    loading: { $set: false },
  });
}

export function setInsightsFilter(state, insightsFilter) {
  return update(state, { insights: { filter: { $set: insightsFilter } } });
}

export function setInsightsCategoriesSelected(state, insightsCategories) {
  return update(state, { insights: { categoriesSelected: { $set: insightsCategories } } });
}

export function setInsightsLoading(state, loading) {
  return update(state, { loading: { $set: loading } });
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case LP_SET_IS_LOADING_INSIGHTS_AND_RESEARCH: {
      return update(state, {
        insights: { isLoading: { $set: payload } },
      });
    }
    case LP_ADD_MORE_INSIGHTS:
      return addMoreInsights(state, payload);
    case LP_SET_INSIGHTS:
      return setInsights(state, payload);
    case LP_SET_INSIGHTS_FILTER:
      return setInsightsFilter(state, payload);
    case LP_SET_INSIGHTS_CATEGORIES_SELECTED:
      return setInsightsCategoriesSelected(state, payload);
    case LP_SET_INSIGHTS_LOADING:
      return setInsightsLoading(state, payload);
    default:
      return state;
  }
};
