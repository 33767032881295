import { FALLBACK_ERROR_MESSAGE } from 'containers/client_management/constants';
import {
  patchRequest,
} from 'actions/utils';

/**
 * @param {number} privateAccessFundId
 * @param {number} investmentId
 * @param {Object} investment
 * @param {number} investment.commitment
 * @param {import("actions/utils").GenericActionsApiRequestConfig} requestArgs
 */
export const patchCommitmentAmount = (
  privateAccessFundId,
  investmentId,
  investment,
  requestArgs = {}
) => patchRequest({
  url: `private_access_funds/${privateAccessFundId}/investments/${investmentId}/set_commitment_amount`,
  data: {
    privateAccessFundId,
    investmentId,
    investment,
  },
  errorMessage: FALLBACK_ERROR_MESSAGE,
  ...requestArgs,
});
