import { FALLBACK_ERROR_MESSAGE } from 'containers/client_management/constants';
import { patchRequest } from 'services/apiRequest';

/**
 * @param {number} investmentId
 * @param {import("actions/utils").GenericActionsApiRequestConfig} requestArgs
 */
export const cancelInvestment = (investmentId, requestArgs = {}) => patchRequest({
  errorMessage: FALLBACK_ERROR_MESSAGE,
  url: `/investments/${investmentId}/cancel`,
  ...requestArgs,
});
