const profileValidations = {
  section_h_option_2_text: {
    min: {
      validMin: 2,
    },
  },
  section_h_option_3_text: {
    min: {
      validMin: 2,
    },
  },
  section_a_name_of_investor: {
    allowedCharacters: {},
  },
  section_a_entity_tax_year_end: {
    allowedCharacters: {},
  },
  section_a_individual_pone: {
    phone: {
      limitations: {
        profileField: {
          section_a_individual_state_country: ['United States of America', 'Canada'],
        },
      },
    },
  },
  section_a_entity_phone: {
    phone: {
      limitations: {
        profileField: {
          section_a_entity_state_country: ['United States of America', 'Canada'],
        },
      },
    },
  },
  section_a_individual_address: {
    allowedCharacters: {},
  },
  section_a_individual_address_2: {
    allowedCharacters: {},
  },
  section_a_individual_city: {
    allowedCharacters: {},
  },
  section_a_individual_zip: {
    allowedCharacters: {},
  },
  section_a_entity_address: {
    allowedCharacters: {},
  },
  section_a_entity_address_2: {
    allowedCharacters: {},
  },
  section_a_entity_city: {
    allowedCharacters: {},
  },
  section_a_entity_zip: {
    allowedCharacters: {},
  },
  section_a_mailing_address: {
    allowedCharacters: {},
  },
  section_a_mailing_address_2: {
    allowedCharacters: {},
  },
  section_a_mailing_city: {
    allowedCharacters: {},
  },
  section_a_mailing_zip: {
    allowedCharacters: {},
  },
  section_b_ria_email: {
    email: {},
  },
  section_b_recipient_bank_name: {
    allowedCharacters: {},
  },
  section_b_recipient_bank_location: {
    allowedCharacters: {},
  },
  section_b_recipient_bank_aba: {
    length: {
      validLength: 9,
    },
    allowedCharacters: {},
  },
  section_b_recipient_bank_swift_code: {
    allowedCharacters: {},
  },
  section_b_recipient_account_name: {
    allowedCharacters: {},
  },
  section_b_recipient_account_number: {
    allowedCharacters: {},
  },
  section_b_recipient_ffc: {
    allowedCharacters: {},
  },
  section_b_recipient_reference: {
    allowedCharacters: {},
  },
  wire_instructions_us_correspondent_bank_name: {
    allowedCharacters: {},
  },
  wire_instructions_us_correspondent_swift_code: {
    allowedCharacters: {},
  },
  wire_instructions_us_correspondent_aba_number: {
    length: {
      validLength: 9,
    },
    allowedCharacters: {},
  },
  wire_instructions_international_bank_account: {
    allowedCharacters: {},
  },
  // section_a_entity_tin: {
  //   length: {
  //     validLength: 9
  //   }
  // },
  section_a_entity_formation_date: {
    date: {
      format: 'MM/DD/YYYY',
    },
  },
  section_b_ria_firm: {
    allowedCharacters: {},
  },
  section_b_ria_name: {
    allowedCharacters: {},
  },
  beneficial_owners_1_owner_name: {
    allowedCharacters: {},
  },
  beneficial_owners_1_owner_address_line1: {
    allowedCharacters: {},
  },
  beneficial_owners_1_owner_address_line2: {
    allowedCharacters: {},
  },
  beneficial_owners_1_owner_address_city: {
    allowedCharacters: {},
  },
  beneficial_owners_1_owner_address_zipcode: {
    allowedCharacters: {},
  },
  beneficial_owners_2_owner_name: {
    allowedCharacters: {},
  },
  beneficial_owners_2_owner_address_line1: {
    allowedCharacters: {},
  },
  beneficial_owners_2_owner_address_line2: {
    allowedCharacters: {},
  },
  beneficial_owners_2_owner_address_city: {
    allowedCharacters: {},
  },
  beneficial_owners_2_owner_address_zipcode: {
    allowedCharacters: {},
  },
  beneficial_owners_3_owner_name: {
    allowedCharacters: {},
  },
  beneficial_owners_3_owner_address_line1: {
    allowedCharacters: {},
  },
  beneficial_owners_3_owner_address_line2: {
    allowedCharacters: {},
  },
  beneficial_owners_3_owner_address_city: {
    allowedCharacters: {},
  },
  beneficial_owners_3_owner_address_zipcode: {
    allowedCharacters: {},
  },
  beneficial_owners_4_owner_name: {
    allowedCharacters: {},
  },
  beneficial_owners_4_owner_address_line1: {
    allowedCharacters: {},
  },
  beneficial_owners_4_owner_address_line2: {
    allowedCharacters: {},
  },
  beneficial_owners_4_owner_address_city: {
    allowedCharacters: {},
  },
  beneficial_owners_4_owner_address_zipcode: {
    allowedCharacters: {},
  },
  beneficial_owners_5_owner_name: {
    allowedCharacters: {},
  },
  beneficial_owners_5_owner_address_line1: {
    allowedCharacters: {},
  },
  beneficial_owners_5_owner_address_line2: {
    allowedCharacters: {},
  },
  beneficial_owners_5_owner_address_city: {
    allowedCharacters: {},
  },
  beneficial_owners_5_owner_address_zipcode: {
    allowedCharacters: {},
  },
  control_person_name: {
    allowedCharacters: {},
  },
  control_person_address_line_1: {
    allowedCharacters: {},
  },
  control_person_address_line_2: {
    allowedCharacters: {},
  },
  control_person_address_city: {
    allowedCharacters: {},
  },
  control_person_address_zipcode: {
    allowedCharacters: {},
  },
};

export default profileValidations;
