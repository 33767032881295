import axios from 'axios';
import { SIMON, GET, BASE_URLS } from 'modules/passport/constants';

const axiosInstances = {};

const getAxiosInstance = (platform) => {
  if (!axiosInstances[platform]) {
    axiosInstances[platform] = axios.create({
      baseURL: BASE_URLS[platform],
      timeout: 10000,
    });
  }

  const AUTH_TOKEN = JSON.parse(sessionStorage.getItem('okta-token-storage'))?.accessToken?.accessToken;

  if (platform === SIMON) axiosInstances[platform].defaults.headers.common.Authorization = `Bearer ${AUTH_TOKEN}`;

  return axiosInstances[platform];
};

const sendApiRequest = async (platform, endpoint, method = GET, data = {}) => {
  try {
    const axiosInstance = getAxiosInstance(platform);

    const response = await axiosInstance({
      url: endpoint,
      method,
      data,
    });

    return response.data;
  } catch (error) {
    console.error(`Error while making request to ${platform}:`, error);
    throw error;
  }
};

export default sendApiRequest;
