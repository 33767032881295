import i18n from 'i18n.js';

export const NDADisclosureText = () =>
  i18n.t(
    'disclaimer:nda_text',
    `<div>
    <span>
      <p>By accessing this site (this “Website”), you understand that Institutional Capital Network, Inc., a Delaware
      corporation (“iCapital” and, together with its affiliates, the “iCapital Parties”), is prepared to make available
      to you Information (as defined below) concerning {{currentDisclaimer.fundName}} (collectively, the “Access Fund”)
      and one or more investment funds into which the Access Fund may invest from time to time (collectively, the
      “Underlying Fund”), and you agree to the following terms as of the date on which you first access this Website (the
      “Effective Date”).</p>
      <p>1. You hereby acknowledge and agree (i) that the information made available through the use of this Website,
      including any posted materials (collectively, the “Materials”) regarding the Access Fund and the Underlying
      Fund (such information, collectively with the Materials, the “Information”), is being furnished to you for
      the sole purpose of providing information about, and documents with respect to, the Access Fund in connection
      with your (or in the case of a Representative (as defined below), an investor’s) evaluation of a possible
      investment in the Access Fund and may not be used for any other purpose, (ii) that, by accessing this Website,
      you will be in possession of confidential information (including as it relates to the Materials, which may
      contain material nonpublic information); (iii) to use the Information for the sole purpose of evaluating a
      potential investment in the Access Fund (and, in the case of a Representative, to assist an investor in
      connection with his, her or its evaluation of a potential investment in the Access Fund), and for no other
      purpose; and (iv) to keep all Information strictly confidential (unless such Information is or becomes readily
      ascertainable from public information or trade sources or is already in your possession on a non-confidential
      basis prior to your receipt thereof through the use of this Website) and not to disclose, reproduce,
      redistribute, forward or otherwise pass, directly or indirectly, any such Information to any other person, (x)
      other than to your affiliates, officers, employees, attorneys, accountants, consultants and other advisors
      (each, a “Representative”) who (A) have a bona fide need to know such Information for purposes of monitoring
      or managing your (or in the case of a Representative, an investor’s) potential investment in the Access Fund,
      or for financial, legal or accounting purposes, and (B) have agreed to, or are otherwise subject to, a duty to
      keep such Information confidential and to not otherwise use such Information in a manner inconsistent with this
      Non-Disclosure Agreement (this “Agreement”), it being understood that you shall be responsible and liable for
      any disclosure or use by any such Representative, (y) only in the case of a Representative, other than to an
      investor who has agreed to, or is otherwise subject to, a duty to keep such Information confidential and to not
      otherwise use such Information in a manner inconsistent with this Agreement, it being understood that you shall
      be responsible and liable for any disclosure or use by any such investor, or (z) unless such disclosure is
      required by applicable law, governmental rule or regulation, or by any regulatory or judicial authority having
      jurisdiction over you, in which case you shall provide as much prior written notice to the iCapital Parties as
      is practicable under the circumstances.</p>
      <p>2. You acknowledge and agree that the iCapital Parties may be irreparably harmed if their obligations
      hereunder are not specifically enforced and that they would not have an adequate remedy at law in the event
      of an actual or threatened violation by you or your Representative of your or its obligations hereunder.
      Therefore, you agree and consent that the iCapital Parties shall be entitled to seek an injunction or any
      appropriate decree of specific performance for any actual or threatened violation or breach by you or your
      Representative of this Agreement, without the posting of any bond, and such other relief as may be available
      at law or equity, including the right to recover all losses or damages suffered by the iCapital Parties
      resulting from any such breach or threatened breach.</p>
      <p>3. This Agreement will be governed by and interpreted in accordance with the laws of the State of New York,
      without regard to principles of conflicts of law.  Any action or proceeding brought by any party relating in
      any way to this Agreement shall be brought and enforced in the courts of the Southern District of New York,
      and you irrevocably submit to the jurisdiction for such federal courts in respect of any such action or
      proceeding.</p>
      <p>4. This Agreement is effective as of the Effective Date and shall continue in full force and effect for so
      long as this Agreement is not terminated by the iCapital Parties.</p>
      <p>5. If any provision or provisions of this Agreement shall be held to be invalid, illegal or unenforceable,
      the validity, legality and enforceability of the remaining provisions shall not in any way be affected or
      impaired thereby. This Agreement may be executed in counterparts, each of which shall be deemed an original,
      but all of which, taken together, shall constitute one agreement.</p>
    </span>
  </div>`
  );

export default NDADisclosureText;
