export const publicPath = '/icn_react/static/';
export const baseRoute = `${publicPath}product_access`;

export const routeCodes = {
  PA_INDEX: baseRoute,
  PA_DEFAULT_SETTINGS: `${baseRoute}?tab=settings`,
  PA_PRODUCTS: `${baseRoute}?tab=products`,
  PA_LISTING: `${baseRoute}?tab=listings`,
  PA_GROUP: `${baseRoute}?tab=groups`,
  PA_PRODUCT_EDIT: `${baseRoute}/product/:id/edit`,
  PA_LISTING_EDIT: `${baseRoute}/white_label_partner_fund/:id/edit`,
  PA_GROUP_EDIT: `${baseRoute}/group/:id/edit`,
  PA_GROUP_EDIT_MEMBERS: `${baseRoute}/group/:id/edit?tab=members`,
};

export const hideFooterRoutes = {
  EDIT_MEMBERS_TAB: `${baseRoute}/group/\\d+/edit(\\?tab=members)?$`,
};
