import update from 'immutability-helper';
import API_DASHBOARD_ACTIONS from '../../actionsConstants';
import { initialDashboardState } from '../../reducer';

const orderStatusDashboardReducer = (
  state = initialDashboardState,
  { type, payload },
) => {
  switch (type) {
    case API_DASHBOARD_ACTIONS.SET_SELECTED_ORDER_STATUS:
      return update(state, {
        selectedItem: {
          $set: payload,
        },
      });
    case API_DASHBOARD_ACTIONS.GET_ORDER_STATUS_USER:
      return update(state, {
        selectedUser: {
          $set: payload,
        },
      });
    case API_DASHBOARD_ACTIONS.GET_ORDER_STATUS_LOGS:
      return update(state, {
        selectedItemLogs: {
          $set: payload,
        },
      });
    case API_DASHBOARD_ACTIONS.GET_OLD_API_PROCESSES_LOGS:
      return update(state, {
        selectedOldApiProcessesLogs: {
          $set: payload,
        },
      });
    case API_DASHBOARD_ACTIONS.SET_CURRENT_VIEW_FILTERS:
      return update(state, {
        currentViewFilters: {
          $set: payload,
        },
      });
    case API_DASHBOARD_ACTIONS.SET_DEFAULT_VIEW_FILTERS:
      return update(state, {
        defaultViewFilters: {
          $set: payload,
        },
      });
    case API_DASHBOARD_ACTIONS.SET_CURRENT_PAGE:
      return update(state, {
        currentPage: {
          $set: payload,
        },
      });
    case API_DASHBOARD_ACTIONS.SET_CURRENT_PAGE_ROWS:
      return update(state, {
        currentPageRows: {
          $set: payload,
        },
      });
    case API_DASHBOARD_ACTIONS.SET_ORDER_STATUS_ANALYTICS:
      return update(state, {
        analytics: {
          $set: payload,
        },
      });
    case API_DASHBOARD_ACTIONS.SET_ORDER_STATUS_ANALYTICS_DAYS_BEHIND:
      return update(state, {
        analyticsDaysBehind: {
          $set: payload,
        },
      });
    default:
      return state;
  }
};

export default orderStatusDashboardReducer;
