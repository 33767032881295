import assignmentsReducer from './assignmentsReducer';
import filterReducer from './filterReducer';
import firmsReducer from './firmsReducer';
import fundsReducer from './fundsReducer';
import modalReducer from './modalReducer';
import reviewersReducer from './reviewersReducer';

export default {
  assignmentsReducer,
  filterReducer,
  firmsReducer,
  fundsReducer,
  modalReducer,
  reviewersReducer,
};
