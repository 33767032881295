import update from 'immutability-helper';

import {
  NR_FETCH_NOMINEE_REVIEW_LIST,
  NR_FETCH_NOMINEE_REVIEW_DETAILS,
  NR_NOMINEE_REVIEW_MODAL,
  NR_NOMINEE_REVIEW_MODAL_WITH_CONTEXT,
  NR_NOMINEE_REVIEW_EXPORT_HISTORY,
  NR_FETCH_SR_NOMINEE_REVIEW_DETAILS,
  NR_CLEAR_SR_NOMINEE_REVIEW_DETAILS,
  NR_NOMINEE_REVIEW_PAGE_TABLE,
  NR_NOMINEE_REVIEW_PER_PAGE_TABLE,
  NR_NOMINEE_REVIEW_ACCOUNT_ID_FILTER,
  NR_NOMINEE_FIELD_OPTIONS,
  NR_UPDATE_NOMINEE_REVIEW_ALLOCATION,
} from 'actions/actionsConstants';

export const initialState = {
  nomineeReviewList: [],
  nomineeReviewDetails: {
    nominee_total_amount: 0,
    nominee_parent_amount: 0,
    nominee_total: 0,
    parent_nominee_acc_num: undefined,
    parent_nominee_investor_name: undefined,
    fund_name: undefined,
    currency_code: 'USD',
    share_class_id: 0,
    has_share_classes: false,
    investment_accepted: false,
    close_date: undefined,
    available: false,
  },
  nomineeReviewActiveModal: undefined,
  nomineeReviewActiveModalContext: undefined,
  nomineeReviewExportHistoryList: [],
  nomineeReviewExportHistoryTotal: 0,
  SRNomineeDetails: {},
  nomineeReviewPage: 1,
  nomineeReviewPerPage: 25,
  searchFilter: undefined,
  nomineeFieldOptions: {},
  discretionaryInvestorFieldOptions: [],
  discretionaryAccountProfileFieldOptions: [],
};

// eslint-disable-next-line default-param-last
export default function nomineeReviewReducer(state = initialState, action) {
  switch (action.type) {
    case NR_FETCH_NOMINEE_REVIEW_LIST:
      return update(state, {
        nomineeReviewList: { $set: action.payload },
      });
    case NR_UPDATE_NOMINEE_REVIEW_ALLOCATION:
      return update(state, {
        nomineeReviewList: {
          $apply: (allocations) => allocations.map((allocation) => {
            if (allocation.id === action.payload.id) {
              return {
                ...allocation,
                ...action.payload,
              };
            }
            return allocation;
          }),
        },
      });
    case NR_FETCH_NOMINEE_REVIEW_DETAILS:
      return update(state, {
        nomineeReviewDetails: { $set: action.payload },
      });
    case NR_NOMINEE_REVIEW_MODAL:
      return update(state, {
        nomineeReviewActiveModal: { $set: action.payload },
        nomineeReviewActiveModalContext: { $set: undefined },
      });
    case NR_NOMINEE_REVIEW_MODAL_WITH_CONTEXT:
      return update(state, {
        nomineeReviewActiveModal: { $set: action.payload.type },
        nomineeReviewActiveModalContext: { $set: action.payload.context },
      });
    case NR_NOMINEE_REVIEW_EXPORT_HISTORY:
      return update(state, {
        nomineeReviewExportHistoryList: { $set: action.payload.exports },
        nomineeReviewExportHistoryTotal: { $set: action.payload.total },
      });
    case NR_FETCH_SR_NOMINEE_REVIEW_DETAILS:
      return update(state, {
        SRNomineeDetails: {
          $merge: {
            [action.payload.investmentId]: action.payload.data,
          },
        },
      });
    case NR_CLEAR_SR_NOMINEE_REVIEW_DETAILS:
      return update(state, {
        SRNomineeDetails: { $merge: { [parseInt(action.payload, 10)]: {} } },
      });
    case NR_NOMINEE_REVIEW_PAGE_TABLE:
      return update(state, {
        nomineeReviewPage: { $set: action.payload },
      });
    case NR_NOMINEE_REVIEW_PER_PAGE_TABLE:
      return update(state, {
        nomineeReviewPerPage: { $set: action.payload },
      });
    case NR_NOMINEE_REVIEW_ACCOUNT_ID_FILTER:
      return update(state, {
        searchFilter: { $set: action.payload },
        nomineeReviewPage: { $set: 1 },
        nomineeReviewPerPage: { $set: 25 },
      });
    case NR_NOMINEE_FIELD_OPTIONS:
      return update(state, {
        nomineeFieldOptions: { $set: action.payload },
      });
    default:
      return state;
  }
}
