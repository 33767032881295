import { getRequest } from 'actions/utils';

import { createCRUD, createRequestTypes } from 'actions/helpers';

const BASE_NAME = 'massMailerPartners';

export const MASS_MAILER_EMAIL_PARTNERS = createCRUD(BASE_NAME, {
  LIST: createRequestTypes(`${BASE_NAME}_LIST`),
});

export const MASS_MAILER_ALL_PARTNERS = createCRUD(BASE_NAME, {
  LIST: createRequestTypes(`${BASE_NAME}_LIST_ALL`),
});

export const MASS_MAILER_EMAIL_PARTNERS_LIST = {
  UNMOUNT: `${BASE_NAME}_UNMOUNT`,
};

export const fetchAllPartners = () => getRequest({
  showSpinner: false,
  url: 'white_label_partners/names',
  actions: MASS_MAILER_ALL_PARTNERS.LIST,
});

export const fetchMassMailerPartners = (massMailerDocumentId) => getRequest({
  showSpinner: false,
  url: 'mass_mailer/mass_mailer_emails/partners',
  params: { mass_mailer_document_id: massMailerDocumentId },
  actions: MASS_MAILER_EMAIL_PARTNERS.LIST,
});
