import update from 'immutability-helper';

import {
  RGA_SCHEDULED_REQUEST_SET_RESULTS_PER_PAGE,
  RGA_SCHEDULED_REQUEST_SET_CURRENT_PAGE,
  RGA_SCHEDULED_REQUEST_SET_SORTING,
  RGA_SCHEDULED_REQUEST_SET_ACTIVE_TAB,
  RGA_SCHEDULED_REQUEST_APPLY_FILTERS,
} from 'actions/actionsConstants';
import { normalizeCurrentPage } from 'services/paginationUtils';

import initialState from '../initialState';

export default (state = initialState, action) => {
  switch (action.type) {
    case RGA_SCHEDULED_REQUEST_SET_ACTIVE_TAB: {
      const {
        currentPage,
        sortingColumn,
        sortingOrder,
        reportNameFilter,
        creatorFilter,
      } = initialState;
      return {
        ...state,
        activeTab: action.payload,
        loading: true,
        scheduledConfigs: [],
        scheduledConfigsCount: 0,
        currentPage,
        sortingOrder,
        sortingColumn,
        reportNameFilter,
        creatorFilter,
      };
    }
    case RGA_SCHEDULED_REQUEST_SET_RESULTS_PER_PAGE:
      return update(state, {
        resultsPerPage: { $set: action.payload },
        currentPage: { $set: initialState.currentPage },
      });
    case RGA_SCHEDULED_REQUEST_SET_CURRENT_PAGE: {
      const { scheduledConfigsCount, resultsPerPage } = state;

      return update(state, {
        currentPage: {
          $set: normalizeCurrentPage(
            action.payload,
            scheduledConfigsCount,
            resultsPerPage,
          ),
        },
      });
    }
    case RGA_SCHEDULED_REQUEST_SET_SORTING: {
      const { sortingColumn, sortingOrder } = action.payload;

      return update(state, {
        sortingColumn: { $set: sortingColumn },
        sortingOrder: { $set: sortingOrder },
      });
    }
    case RGA_SCHEDULED_REQUEST_APPLY_FILTERS: {
      const {
        creatorFilter,
        reportNameFilter,
        freshServiceStatusFilter,
        approvalStatusFilter,
        jobStatusFilter,
        lockedFilter,
        deliveryFilter,
      } = action.payload;
      const { currentPage } = initialState;

      return update(state, {
        currentPage: { $set: currentPage },
        creatorFilter: { $set: creatorFilter },
        reportNameFilter: { $set: reportNameFilter },
        freshServiceStatusFilter: { $set: freshServiceStatusFilter },
        approvalStatusFilter: { $set: approvalStatusFilter },
        jobStatusFilter: { $set: jobStatusFilter },
        lockedFilter: { $set: lockedFilter },
        deliveryFilter: { $set: deliveryFilter },
      });
    }
    default:
      return state;
  }
};
