import { FALLBACK_ERROR_MESSAGE } from 'containers/client_management/constants';
import { getRequest, postRequest } from 'services/apiRequest';
import { useQuery } from '@tanstack/react-query';

export const getRedemptions = (pafId, available, search, searchType) =>
  postRequest({
    url: `private_access_funds/${pafId}/redemptions/search`,
    errorMessage: FALLBACK_ERROR_MESSAGE,
    params: {
      redemptions_status: available,
      source: 'client_management',
      search,
      search_type: searchType,
    },
  });

export const usePostTotalsRedemptions = ({
  pafId,
  enabled = true,
  searchType,
  searchTerm,
  source,
  filterOptions = {},
}) => {
  const url = `/private_access_funds/${pafId}/redemptions/totals`;
  const params = {
    search_type: searchType,
    search: searchTerm,
    source,
  };
  const data = { filterOptions };

  return useQuery({
    queryKey: [url, params, data],
    queryFn: () => postRequest({ url, params, data }),
    enabled: Boolean(enabled && pafId),
    select: (totals) => ({
      ...totals,
      count: Object.values(totals).reduce((acc, cur) => acc + cur, 0),
      activesCount: totals.active + totals.completed + totals.holdback,
    }),
    placeholderData: {
      active: 0,
      completed: 0,
      holdback: 0,
      canceled: 0,
      count: 0,
      activesCount: 0,
    },
    cacheTime: 30000, // 30 seconds
    staleTime: 30000, // 30 seconds
  });
};

export const getInitiateRedemptionData = (pafId, investmentId) =>
  getRequest({
    url: `private_access_funds/${pafId}/redemptions/initiate_redemption_data`,
    errorMessage: FALLBACK_ERROR_MESSAGE,
    params: { investment_id: investmentId },
  });

export const getForClientManagementEmailVerification = (investment) =>
  getRequest({
    url: `private_access_funds/${investment.private_access_fund_id}/investments/for_client_management_email_verification`,
    errorMessage: FALLBACK_ERROR_MESSAGE,
    params: {
      investment_id: investment.id,
      investor_profile_id: investment.investor_profile_id,
      wlp_id: investment.white_label_partner_id,
    },
  });

export const cancelRedemption = (pafId, redemptionId) =>
  postRequest({
    errorMessage: FALLBACK_ERROR_MESSAGE,
    url: `/private_access_funds/${pafId}/redemptions/${redemptionId}/update_status`,
    data: { status_action: 'cancel', redemption: { cancellation_reason: 'advisor_requested' } },
  });

export const uploadRedemptionDoc = (pafId, redemptionId, file, reupload = false) => {
  const formData = new FormData();
  formData.append('file', file);
  return postRequest({
    url: `/private_access_funds/${pafId}/redemptions/${redemptionId}/upload_document`,
    params: { reupload: reupload ? 'true' : undefined },
    data: formData,
  });
};

export const getRedemptionEvents = (pafId, redemptionId) =>
  getRequest({
    errorMessage: FALLBACK_ERROR_MESSAGE,
    url: `/private_access_funds/${pafId}/redemptions/${redemptionId}/events`,
  });

export const sendRedemptionAgreement = (pafId, redemptionId, signerData, signMethod, faOptions, isResendAgreement) =>
  postRequest({
    url: `private_access_funds/${pafId}/redemptions/${redemptionId}/send_agreement`,
    data: {
      sign_method: signMethod,
      signer_data: signerData,
      fa_options: {
        fa_selection: faOptions.faSelection,
        selected_signatory_id: faOptions.selectedSignatory?.id,
        discretionary_phone: faOptions.discretionaryPhone,
      },
      is_resend_agreement: isResendAgreement,
    },
  });
