import {
  shape, string, number, bool, arrayOf,
} from 'prop-types';

export default shape({
  id: number,
  investment: shape({
    id: number,
    custodian_account: shape({ custodian_id: number }),
    first_recipient: shape({ email: string, display_name: string }),
    investor_profile_id: number,
    available_signers: arrayOf(shape({})),
  }),
  status: string,
  sign_method: string,
  investor: shape({ name: string }),
  subscription_document_definition: shape({ id: number, enable_discretionary_signer: bool }),
  has_supplemental_form: bool,
  supplemental_form_completed: bool,
  display_status: string,
  redemption_document: shape({}),
  signature_vendor: string,
});
