import axios from 'axios';
import { actionFactory } from 'services/actions';
import { searchTextFormatter } from 'containers/admin_search/services/textFormatter';
import _ from 'lodash';
import notificationService from 'services/notifications';
import { getRequest, postRequest } from 'actions/utils';
import { beginApiRequest, endApiRequest } from '../spinner_actions/spinnerActions';

import {
  ADMS_SET_SEARCH_RESULTS_LOADED,
  ADMS_SET_SEARCH_KEYWORD,
  ADMS_SET_CURRENT_RESULTS,
  ADMS_SET_IS_ADMIN_SEARCH_OPEN,
  ADMS_SET_IS_PREVIEW_RESULTS_OPEN,
  ADMS_SET_IS_SEARCH_BUTTON_ENABLE,
  ADMS_SET_IS_SEARCH_INPUT_DISABLE,
  ADMS_SET_TABS,
  ADMS_SET_SELECTED_TAB,
  ADMS_SET_SELECTED_RESULT,
  ADMS_SET_SELECTED_RESULT_POSITION,
  ADMS_SET_RESULTS_PER_PAGE,
  ADMS_SET_CURRENT_PAGE,
  ADMS_SET_RELATED_INFO_DATA,
  ADMS_SET_IS_EDIT_CONTACT_CARD_EMAIL_MODAL_OPEN,
  ADMS_SET_EDIT_CONTACT_CARD_EMAIL_ERRORS,
  ADMS_SET_RELATED_TABLES_PAGES,
  ADMS_SET_CONTACT_CARD_TO_EDIT,
  ADMS_SET_EDIT_TYPE,
  ADMS_SET_CLONED_USER_PASSWORD,
  ADMS_SET_CLONED_USER_DATA,
  ADMS_SET_PREVIOUS_PAGE_URL,
} from '../actionsConstants';

const BASE_URL = 'admin_search';
const CC_RELATED_DATA_URL = `${BASE_URL}/contact/`;

const admsSetSearchResultsLoaded = actionFactory(ADMS_SET_SEARCH_RESULTS_LOADED);
const admsSetSearchKeyword = actionFactory(ADMS_SET_SEARCH_KEYWORD);
const admsSetCurrentResults = actionFactory(ADMS_SET_CURRENT_RESULTS);
const admsSetIsAdminSearchOpen = actionFactory(ADMS_SET_IS_ADMIN_SEARCH_OPEN);
const admsSetIsPreviewResultsOpen = actionFactory(ADMS_SET_IS_PREVIEW_RESULTS_OPEN);
const admsSetIsSearchButtonEnable = actionFactory(ADMS_SET_IS_SEARCH_BUTTON_ENABLE);
const admsSetIsSearchInputDisable = actionFactory(ADMS_SET_IS_SEARCH_INPUT_DISABLE);
const admsSetTabs = actionFactory(ADMS_SET_TABS);
const admsSetSelectedTab = actionFactory(ADMS_SET_SELECTED_TAB);
const admsSetSelectedResult = actionFactory(ADMS_SET_SELECTED_RESULT);
const admsSetSelectedResultPosition = actionFactory(ADMS_SET_SELECTED_RESULT_POSITION);
const admsSetResultsPerPage = actionFactory(ADMS_SET_RESULTS_PER_PAGE);
const admsSetCurrentPage = actionFactory(ADMS_SET_CURRENT_PAGE);
const admsSetRelatedInfoData = actionFactory(ADMS_SET_RELATED_INFO_DATA);
const admsSetEditContactCardEmailErrors = actionFactory(ADMS_SET_EDIT_CONTACT_CARD_EMAIL_ERRORS);
const admsSetIsEditContactCardEmailModalOpen = actionFactory(
  ADMS_SET_IS_EDIT_CONTACT_CARD_EMAIL_MODAL_OPEN
);
const admsSetRelatedTablesPages = actionFactory(ADMS_SET_RELATED_TABLES_PAGES);
const admsSetContactCardToEdit = actionFactory(ADMS_SET_CONTACT_CARD_TO_EDIT);
const admsSetEditType = actionFactory(ADMS_SET_EDIT_TYPE);
const admsSetClonedUserPassword = actionFactory(ADMS_SET_CLONED_USER_PASSWORD);
const admsSetClonedUserData = actionFactory(ADMS_SET_CLONED_USER_DATA);
const admsSetPreviousPageUrl = actionFactory(ADMS_SET_PREVIOUS_PAGE_URL);

function admsFetchRelatedInfo() {
  return (dispatch, getState) => {
    const url = CC_RELATED_DATA_URL + getState().adminSearch.selected_result?.id;
    dispatch(beginApiRequest());

    const params = {
      ...getState().adminSearch.related_tables_pages,
      searchText: decodeURIComponent(getState().adminSearch.search_keyword),
    };

    return axios.get(url, { withCredentials: true, params })
      .then((response) => {
        dispatch(admsSetRelatedInfoData(response.data));
        return dispatch(endApiRequest());
      });
  };
}

function admsFetchRelatedData(tableName) {
  return (dispatch, getState) => dispatch(getRequest({
    url: CC_RELATED_DATA_URL + getState().adminSearch.selected_result?.id,
    params: {
      related_tables_pages: getState().adminSearch.related_tables_pages,
      table: tableName,
      searchText: decodeURIComponent(getState().adminSearch.search_keyword),
    },
    showSpinner: true,
    onSuccess: (data) => {
      const relatedInfoDataUpdated = {
        ...getState().adminSearch.related_info_data,
        [`related_${tableName}`]: data,
      };

      dispatch(admsSetRelatedInfoData(relatedInfoDataUpdated));
    },
  }));
}

function buildTabs(data) {
  return (dispatch) => {
    const tabs = [];
    if (data.length > 1) {
      tabs.push({
        id: -1,
        label: 'All',
        number_of_results: data.reduce(
          (count, result) => count + result.number_of_results
        ),
      });
    }

    data.map((category, index) => (
      tabs.push({
        id: index,
        label: category.title.toLowerCase(),
        number_of_results: category.number_of_results,
      })
    ));

    dispatch(admsSetTabs(tabs));
    dispatch(admsSetSelectedTab(tabs[0].id));
  };
}

function admsGetResults(url, params, selectedResultId = null, selectedTab = null) {
  return (dispatch) => {
    dispatch(beginApiRequest());
    dispatch(admsSetSearchResultsLoaded(false));
    return axios.get(url, { withCredentials: true, params }).then((response) => {
      dispatch(admsSetCurrentResults(response.data));
      if (!_.isEmpty(response.data)) {
        dispatch(buildTabs(response.data));
      }

      if (!_.isEmpty(selectedResultId) && !_.isEmpty(selectedTab)) {
        const queryResults = response.data.filter((obj) => obj.title.toLowerCase() == selectedTab);
        const selectedResult = queryResults[0]?.results.filter(
          (obj) => obj.id == selectedResultId
        )[0];

        dispatch(admsSetSelectedResult(selectedResult));
        dispatch(admsFetchRelatedInfo());
      }
    }).finally(() => {
      dispatch(admsSetSearchResultsLoaded(true));
      dispatch(admsSetIsSearchInputDisable(false));
      dispatch(endApiRequest());
    });
  };
}

function admsFetchAdminSearchResults(searchText) {
  const params = { searchText };

  return (dispatch) => {
    dispatch(admsSetSearchKeyword(searchTextFormatter(searchText)));
    return dispatch(admsGetResults(BASE_URL, params));
  };
}

function admsFetchFullSearchResults(selectedResultId = null, selectedTab = null) {
  // For when we have more categories than just contact cards
  // const url = `${BASE_URL}/full_results`;

  return (dispatch, getState) => {
    const params = {
      searchText: getState().adminSearch.search_keyword,
      page: getState().adminSearch.current_page,
      per_page: getState().adminSearch.results_per_page,
    };

    return dispatch(admsGetResults(BASE_URL, params, selectedResultId, selectedTab));
  };
}

function admsOnSelectedResultChange(selectedResult) {
  return (dispatch) => {
    dispatch(admsSetSelectedResult(selectedResult));
    return !_.isEmpty(selectedResult) ? dispatch(admsFetchRelatedInfo()) : null;
  };
}

function onCloneUserSuccess(payload) {
  return (dispatch) => {
    dispatch(admsSetClonedUserPassword(payload?.password));
    dispatch(admsSetIsEditContactCardEmailModalOpen(false));
  };
}

function admsCloneUser(userId) {
  return (dispatch, getState) => dispatch(postRequest({
    url: 'users/clone_user',
    data: {
      user_id: userId,
      first_name: getState().adminSearch.cloned_user_first_name,
      last_name: getState().adminSearch.cloned_user_last_name,
      email: getState().adminSearch.cloned_user_email,
      ticket_id: getState().adminSearch.cloned_user_ticket_id
    },
    onFailure: (payload) => notificationService.notifyError('', payload?.data?.errors),
    onSuccess: onCloneUserSuccess,
  }));
}

function admsUpdateContactCardEmail(id, email) {
  return (dispatch) => {
    const url = `contact_cards/${id}/update_email`;
    dispatch(admsSetEditContactCardEmailErrors(''));
    dispatch(beginApiRequest());

    return axios.patch(url, {}, {
      withCredentials: true,
      params: {
        email,
      },
    }).then(() => {
      dispatch(admsSetSelectedResult({}));
      dispatch(admsSetSelectedResultPosition(0));
      dispatch(admsFetchFullSearchResults());
      notificationService.notifyInfo('', 'Contact card email successfully updated.');
      dispatch(admsSetIsEditContactCardEmailModalOpen(false));
    }).catch((e) => {
      if (e.data?.email[0] === 'has already been taken') {
        dispatch(admsSetEditContactCardEmailErrors('The email has already been taken.'));
      } else {
        dispatch(admsSetEditContactCardEmailErrors('Please provide a valid email address.'));
      }
      dispatch(admsSetIsEditContactCardEmailModalOpen(true));
    }).finally(() => {
      dispatch(endApiRequest());
    });
  };
}

export default {
  admsFetchAdminSearchResults,
  admsSetSearchResultsLoaded,
  admsSetCurrentResults,
  admsSetSearchKeyword,
  admsSetIsAdminSearchOpen,
  admsSetIsPreviewResultsOpen,
  admsSetIsSearchButtonEnable,
  admsSetIsSearchInputDisable,
  admsFetchFullSearchResults,
  admsOnSelectedResultChange,
  admsSetSelectedTab,
  admsSetResultsPerPage,
  admsSetCurrentPage,
  admsFetchRelatedInfo,
  admsSetSelectedResultPosition,
  admsSetIsEditContactCardEmailModalOpen,
  admsUpdateContactCardEmail,
  admsSetEditContactCardEmailErrors,
  admsSetRelatedTablesPages,
  admsFetchRelatedData,
  admsCloneUser,
  admsSetContactCardToEdit,
  admsSetEditType,
  admsSetClonedUserPassword,
  admsSetClonedUserData,
  admsSetPreviousPageUrl,
};
