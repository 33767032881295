import React from 'react';
import Stack from '@icapitalnetwork/supernova-core-v2/Stack';
import HomeDisclaimer from 'modules/landing_page/components/home/Disclaimer';

const HomepageMFE = () => {
  return (
    <Stack>
      <div id="single-spa-application:unified-homepage" />
      <HomeDisclaimer />
    </Stack>
  );
};

export default HomepageMFE;
