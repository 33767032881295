import { actionFactory } from 'services/actions';
import { patchRequest, getRequest } from 'actions/utils';
import {
  RGA_SET_REPORT_CONFIGS,
  RGA_SET_REPORT_CONFIGS_FAILED,
  RGA_EMPTY_FILTERS,
  RGA_SET_REPORT_LOADED,
} from 'actions/actionsConstants';
import {
  getCurrentConfigId,
  getConfigurableId,
  getConfigurableType,
} from 'reducers/report_generator_reducers/selectors';
import scheduleActions from './scheduleActions';
import filterActions from './filters';
import configFieldActions from './configFieldActions';

const BASE_URL = 'report_generator/configs';
const rgaSetReportConfigs = actionFactory(RGA_SET_REPORT_CONFIGS);
const rgaResetFilters = actionFactory(RGA_EMPTY_FILTERS);
const rgaSetReportConfigsFailed = actionFactory(RGA_SET_REPORT_CONFIGS_FAILED);
const rgaSetReportLoadedValue = actionFactory(RGA_SET_REPORT_LOADED);
const rgaSetReportConfigsCallback = (payload) => {
  return (dispatch) => {
    dispatch(rgaResetFilters());
    dispatch(rgaSetReportConfigs(payload));
    dispatch(rgaSetReportLoaded(true));
  };
};

const rgaSetReportLoaded = (payload) => {
  return (dispatch) => {
    dispatch(rgaSetReportLoadedValue(payload));
  };
};

const rgaSetReportConfigsAndAttributes = (payload) => {
  return (dispatch) => {
    if (payload.configurable_type === 'ReportGeneratorBaseQuery') {
      dispatch(filterActions.getReportGeneratorConfigsFields(payload.id));
      dispatch(configFieldActions.getConfigFields(payload.id));
    }
    dispatch(scheduleActions.reportScheduleData(payload.id));
    dispatch(rgaSetReportConfigs(payload));
  };
};

function getReportGeneratorConfigs(reportConfigId, superUser = false) {
  return (dispatch) => {
    return dispatch(
      getRequest({
        url: `${BASE_URL}/${reportConfigId}`,
        params: { super_user: superUser || undefined },
        onSuccess: rgaSetReportConfigsCallback,
        onFailure: (err) => {
          var msg = 'An occurred while setting the report config';
          if (err.status === 404) {
            msg =
              'The report does not exist or you do not have permission to access it.';
          }
          dispatch(rgaSetReportConfigsFailed(msg));
        },
      }),
    );
  };
}

function saveReportGeneratorConfigs(
  message = 'Report saved successfully',
  showSpinner = true,
  configId = null,
) {
  return (dispatch, getState) => {
    const configurableId = getConfigurableId(getState());
    const configurableType = getConfigurableType(getState());

    if (!configId) {
      configId = getCurrentConfigId(getState());
    }
    let messageArgs;
    if (message) {
      messageArgs = { message };
    }

    return dispatch(
      patchRequest({
        url: `${BASE_URL}/${configId}`,
        data: {
          report_generator_config: {
            configurable_id: configurableId,
            configurable_type: configurableType,
          },
        },
        onSuccess: rgaSetReportConfigsAndAttributes,
        successMessage: messageArgs,
        errorMessage: { title: 'Error', message: 'Failed to save the report' },
        showSpinner: showSpinner,
      }),
    );
  };
}

export default {
  saveReportGeneratorConfigs,
  getReportGeneratorConfigs,
};
