const custodianValidations = {
  account_number: {
    length: {
      validLength: 9,
      limitations: {
        custodian: {
          name: ['Fidelity RIAs / Family Office', 'NFS Broker-Dealers'],
        },
      },
      errorMsg: 'Please provide account number in XXXXXXXXX format',
    },
  },
};

export default custodianValidations;
