export default {
  formData: {},
  schema: {
    type: 'string',
    const: 'No schema definition available',
  },
  uiSchema: {},
  isLoading: true,
  accountName: '',
  accountNum: '',
};
