/* eslint-disable max-statements */
/* eslint-disable max-lines */
import { rootUrl } from 'services/axios/setBaseUrl';
import { getRequest } from 'actions/utils';
import flow from 'lodash/fp/flow';
import filter from 'lodash/fp/filter';
import map from 'lodash/fp/map';
import _ from 'lodash';
import axios from 'axios';
import {
  DC_CLEAR_ALL_SELECTIONS,
  DC_CLEAR_SELECTED_DOCUMENTS,
  DC_CLEAR_UNSELECTED_DOCUMENTS,
  DC_SET_SELECTED_DOCUMENTS,
  DC_SET_UNSELECTED_DOCUMENTS,
  DC_TOGGLE_DOWNLOAD_ERROR,
  DC_TOGGLE_SELECT_ALL,
  DC_DELETE_INTERVAL,
  DC_NEW_DOWNLOAD_AVAILABLE,
  DC_CREATE_INTERVAL,
  DC_CLEAR_DOWNLOAD_AVAILABLE,
} from 'actions/actionsConstants';

import { beginApiRequest, setSpinnerText, endApiRequest } from 'actions/spinner_actions/spinnerActions';
import dcFilterListActions from '../doc_center_filter_actions/dcFilterListActions';

const {
  dcGetSelectedFilters,
} = dcFilterListActions;

function clearSelectedDocuments() {
  return {
    type: DC_CLEAR_SELECTED_DOCUMENTS,
  };
}

function clearUnselectedDocuments() {
  return {
    type: DC_CLEAR_UNSELECTED_DOCUMENTS,
  };
}

function setSelectedDocuments(payload) {
  return {
    type: DC_SET_SELECTED_DOCUMENTS,
    payload,
  };
}

function setUnselectedDocuments(payload) {
  return {
    type: DC_SET_UNSELECTED_DOCUMENTS,
    payload,
  };
}

function clearAllSelectedDocuments() {
  return {
    type: DC_CLEAR_ALL_SELECTIONS,
  };
}

function toggleSelectAll(payload) {
  return {
    type: DC_TOGGLE_SELECT_ALL,
    payload,
  };
}

function toggleDownloadError(payload) {
  return {
    type: DC_TOGGLE_DOWNLOAD_ERROR,
    payload,
  };
}

function deleteInterval(payload) {
  return {
    type: DC_DELETE_INTERVAL,
    payload,
  };
}

function downloadReady(payload) {
  return {
    type: DC_NEW_DOWNLOAD_AVAILABLE,
    payload,
  };
}

function createInterval(payload) {
  return {
    type: DC_CREATE_INTERVAL,
    payload,
  };
}

function clearDownloadList() {
  return {
    type: DC_CLEAR_DOWNLOAD_AVAILABLE,
  };
}

const clearDownloadAvailable = () => (dispatch = () => { }) => {
  dispatch(clearDownloadList());
};

const toViewExists = () => (dispatch = () => { }) => {
  dispatch(getRequest({
    url: 'document_downloads/to_view',
    showSpinner: false,
    onSuccess: (data) => () => { if (data.to_view) dispatch(downloadReady({ id: -1 })); },
  }));
};

const setIntervalForBulk = (idBulkDownload) => (dispatch) => {
  const intervalForBulk = setInterval(() => {
    dispatch(getRequest({
      url: `document_downloads/bulk_finished/${idBulkDownload}`,
      showSpinner: false,
      onSuccess: (data) => () => {
        if (data.finished) {
          dispatch(downloadReady({ id: idBulkDownload }));
          dispatch(deleteInterval({ idInterval: idBulkDownload }));
        }
      },
      onFailure: () => () => {
        dispatch(deleteInterval({ idInterval: idBulkDownload }))
      },
    }));
  }, 2000);
  dispatch(
    createInterval({
      id: idBulkDownload,
      interval: intervalForBulk,
    })
  );
};

function getDownloadBaseUrl() {
  const apiBaseUrl = `${rootUrl()}document_downloads`;
  return apiBaseUrl;
}

function onDownloadError(dispatch = () => { }, customTitle = '', customText = '') {
  const title = customTitle !== '' ? customTitle : 'Download error';
  const text = customText !== ''
    ? customText
    : 'Your download could not be processed at this time.  Please try again later.';

  dispatch(
    toggleDownloadError({ downloadError: { title, text } })
  );
}

const allDocumentsSelected = (checkboxState) => checkboxState === 1;

function findDocuments(collection = {}, selectionState) {
  // find isSelected = true
  return flow(
    filter((item) => item[selectionState] === true),
    map((item) => _.pick(item, 'doc_source_id', 'doc_source_class_name', 'fund_id', 'external_document_type', 'external_document_id', 'investor_profile_id', 'effective_date'))
  )(collection);
}

function documentsForDownload(state = {}) {
  const dcDownloads = state.dcDownloads;
  const allSelected = allDocumentsSelected(dcDownloads.checkboxState);
  const idParams = allSelected
    ? findDocuments(dcDownloads.unselected, 'isUnselected')
    : findDocuments(dcDownloads.selected, 'isSelected');

  return idParams;
}

function getCsrfToken(icnReactBootstrap = {}) {
  return icnReactBootstrap.icn_react_bootstrap &&
    icnReactBootstrap.icn_react_bootstrap.csrf_token;
}

function assembleRequestParams(state, selectedAccounts = {}) {
  const csrfToken = getCsrfToken(state.icnReactBootstrap);
  const dcDownloads = state.dcDownloads;
  const allSelected = allDocumentsSelected(dcDownloads.checkboxState);
  const docs = documentsForDownload(state);

  const formObj = {
    authenticity_token: csrfToken,
    selected_accounts: selectedAccounts,
  };

  if (allSelected) {
    const filters = dcGetSelectedFilters(state).filters;
    if (docs.length === 0) {
      return { ...formObj, all_selected: true, filters };
    }

    return {
      ...formObj, docs, all_selected_with_exclusions: true, filters,
    };
  }

  return { ...formObj, docs };
}

function isValidDocumentSelection(state, totalDocs, allSelected) {
  const limit = state.dcDownloads.maxFiles;

  const docs = documentsForDownload(state);

  if (allSelected) {
    return !(totalDocs > limit || (totalDocs - docs.length) > limit);
  }

  return !(docs.length > limit);
}

function getAvailableAccountsForSelectedDocs(data) {
  return axios({
    url: 'document_downloads/available_accounts_for_docs',
    method: 'post',
    withCredentials: true,
    data,
  });
}

function bulkDownload(dispatch = () => { }, data, callbackSnackbar) {
  dispatch(beginApiRequest());

  axios({
    url: 'document_downloads',
    method: 'post',
    withCredentials: true,
    data,
    responseType: 'json',
  }).then((response) => {
    dispatch(endApiRequest());
    dispatch(clearAllSelectedDocuments());
    dispatch(toggleSelectAll({ downloadInProgress: false }));
    if (callbackSnackbar) {
      setIntervalForBulk(response.data.identifier)(dispatch);
      callbackSnackbar('The zip file containing your documents is being created. You will be notified when the file is ready. Click the View Downloads button to access your zip files.');
    }
    else {
      onDownloadError(
        dispatch,
        'Bulk Download',
        'The zip file containing your documents is being created. You will be notified when the file is ready. Click the download icon to access your zip files.'
      );
    }
  }).catch((error) => {
    dispatch(endApiRequest());
    onDownloadError(dispatch, null, error?.data?.message);
  });
}

function mergeDocuments(data) {
  return (dispatch, getState) => {
    dispatch(beginApiRequest());

    data.params.filters = dcGetSelectedFilters(getState()).filters;

    axios({
      url: 'document_downloads/merge_documents',
      method: 'post',
      withCredentials: true,
      data,
    }).then((response) => {
      dispatch(endApiRequest());
      onDownloadError(
        dispatch,
        'Merge Files',
        'The pdf file containing your documents is being created. You will be notified when the file is ready. Click the download icon to access your pdf have been sent.'
      );
    }).catch((error) => {
      dispatch(endApiRequest());
      onDownloadError(dispatch);
    });
  };
}

function singleDocument(dispatch, data, type) {
  const params = data;
  if (type !== 'preview') {
    dispatch(beginApiRequest());

    axios.get('document_downloads', {
      withCredentials: true,
      params,
      responseType: 'arraybuffer',
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const disposition = response.headers['content-disposition'];
      const support = document.createElement('a');
      support.href = url;
      support.download = decodeURIComponent(disposition.match(/filename="(.*)"/)[1]);
      support.click();
      support.remove();
      dispatch(endApiRequest());
    }).catch((error) => {
      dispatch(endApiRequest());
      onDownloadError(dispatch, null, error?.data?.message);
      throw error;
    });
  } else {
    params.preview = true;
    const str = [];
    Object.keys(params).forEach((key) => {
      if (params[key]) {
        str.push(`${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`);
      }
    });
    const url = `${getDownloadBaseUrl()}?${str.join('&')}`;
    window.open(url);
  }
}

function downloadSelectedMiddleware(callback) {
  return (dispatch, getState) => {
    const state = getState();
    const params = assembleRequestParams(state);
    const dcDownloads = state.dcDownloads;
    const allSelected = allDocumentsSelected(dcDownloads.checkboxState);

    const isValid = isValidDocumentSelection(
      state,
      state.dcResults.total_count,
      allSelected
    );

    if (!isValid) {
      return onDownloadError(
        dispatch,
        'Download size exceeds limit',
        'The number of documents you are attempting to download exceeds the allowable limit of 500.  Please modify your selections.'
      );
    }

    dispatch(beginApiRequest());
    return new Promise((resolve, reject) => {
      getAvailableAccountsForSelectedDocs(params).then((response) => {
        const userSelectedAccountForFunds = {};
        const dropdownOptionsForUserToSelect = [];

        Object.keys(response.data).forEach((key) => {
          const accountsInfo = response.data[key].accounts_info;
          const fundName = response.data[key].fund_name;
          const externalDocumentType = response.data[key].external_document_type;
          const fundIdDocTypeId = key.split('%#$&');
          const fundId = fundIdDocTypeId[0];
          const docTypeId = fundIdDocTypeId[fundIdDocTypeId.length - 1];
          let effectiveDate = null;
          if (fundIdDocTypeId.length === 3) {
            effectiveDate = fundIdDocTypeId[1];
          }

          dropdownOptionsForUserToSelect.push({
            fund_id: fundId,
            fund_name: fundName,
            external_document_id: docTypeId,
            external_document_type: externalDocumentType,
            effective_date: effectiveDate,
            options: accountsInfo,
          });
        });

        dropdownOptionsForUserToSelect.sort((a, b) => {
          if (a.fund_name === b.fund_name) {
            return 0;
          }
          return a.fund_name < b.fund_name ? -1 : 1;
        });

        callback(userSelectedAccountForFunds);
        resolve(dropdownOptionsForUserToSelect);
      }).catch((error) => {
        onDownloadError(
          dispatch,
          'Bulk Download Error',
          error.data.message
        );
        reject(error);
      }).finally(() => {
        dispatch(endApiRequest());
      });
    });
  };
}

/* @function downloadSingleDocument
   @desc Handles two cases.
   1) User selects a single item to download, and clicks 'Download'.
   2) User clicks inline icon for downloading an item.
   Both cases, no spinner is displayed while waiting for server response.
   For the inline download icon, allow clearSelection=false
   to opt out of clearing selection after download.
   @param {Object} item An object with props for server to query for a document
   @param {Boolean} clearSelection Flag if selection UI should be cleared after download
 */
function downloadSingleDocument(doc, type, clearSelection = true, selectedAccounts = {}) {
  return (dispatch, getState) => {
    const formObj = doc;
    formObj.view_operation_docs = getState().dcResults.view_operation_docs;

    if (clearSelection === true) {
      dispatch(toggleSelectAll({ downloadInProgress: true }));
    }

    const selectedAccountsKeys = Object.keys(selectedAccounts);
    if (selectedAccounts && selectedAccountsKeys.length > 0) {
      for (let i = 0; i < selectedAccounts.length; i++) {
        formObj.fund_watermark_account = selectedAccounts[i].ip_id;
        singleDocument(dispatch, formObj, type);
      }
    } else {
      singleDocument(dispatch, formObj, type);
    }
  };
}

/* @function downloadSelectedDocuments
   @desc This is triggered by the 'Download' button above the list of Document results.
   Query in Redux for what Documents have been persisted. If only 1 document,
   return early and call downloadSingleDocument. Show spinner with text.
   Send server IDs of documents to download.
   Poll for a cookie back from server to check if server responded, which means download has begun.
*/
function downloadSelectedDocuments(fundDocWatermarkingSelected = {}, callbackSnackbar = undefined) {
  const selectedAccounts = fundDocWatermarkingSelected;
  return (dispatch, getState) => {
    const state = getState();
    const dcDownloads = state.dcDownloads;
    const allSelected = allDocumentsSelected(dcDownloads.checkboxState);

    const isValid = isValidDocumentSelection(
      state,
      state.dcResults.total_count,
      allSelected
    );

    if (!isValid) {
      onDownloadError(
        dispatch,
        'Download size exceeds limit',
        'The number of documents you are attempting to download exceeds the allowable limit of 500. Please modify your selections.'
      );
    } else {
      // when only 1 document selected, download without spinner
      if (!allSelected) {
        const documents = findDocuments(dcDownloads.selected, 'isSelected');
        if (documents.length === 1) {
          return dispatch(
            downloadSingleDocument(documents[0], 'download', true, selectedAccounts[Object.keys(selectedAccounts)[0]])
          );
        }
      }

      const formObj = assembleRequestParams(state, selectedAccounts);
      formObj.view_operation_docs = state.dcResults.view_operation_docs;
      dispatch(toggleSelectAll({ downloadInProgress: true }));
      dispatch(setSpinnerText({ spinnerText: 'Please wait while your documents are being prepared' }));
      bulkDownload(dispatch, formObj, callbackSnackbar);
    }
  };
}

function getAvailableAccountsForFunds(fundId, externalDocumentId, effectiveDate, view_operation_docs) {
  const data = {
    docs: [
      {
        fund_id: fundId,
        external_document_id: externalDocumentId,
        effective_date: effectiveDate,
        view_operation_docs,
      },
    ],
  };

  return axios({
    url: 'document_downloads/available_accounts_for_funds',
    method: 'post',
    withCredentials: true,
    data,
  });
}

function previewOrDownloadSingleDocument(fundDocWatermarkingEnabled, positionDateEnabled, event) {
  const docSourceId = event.currentTarget.dataset.docSourceId;
  const docSourceClassName = event.currentTarget.dataset.docSourceClassName;
  const externalDocumentType = event.currentTarget.dataset.externalDocumentType;
  const externalDocumentId = event.currentTarget.dataset.externalDocumentId;
  const fundId = event.currentTarget.dataset.fundId;
  const isFundLevelDocument = !event.currentTarget.dataset.investorProfileId;
  const investorProfileId = event.currentTarget.dataset.investorProfileId;
  const effectiveDate = event.currentTarget.dataset.effectiveDate;

  const docData = {
    doc_source_id: docSourceId,
    doc_source_class_name: docSourceClassName,
    external_document_type: externalDocumentType,
    fund_id: fundId,
    investor_profile_id: investorProfileId,
    effective_date: effectiveDate,
  };
  return (dispatch, getState) => {
    const data = {
      response: [],
      doc_data: docData,
    };
    if (fundDocWatermarkingEnabled && isFundLevelDocument) {
      dispatch(beginApiRequest());
      return new Promise((resolve, reject) => {
        getAvailableAccountsForFunds(
          fundId,
          externalDocumentId,
          effectiveDate,
          getState().dcResults.view_operation_docs,
        ).then((response) => {
          const key = positionDateEnabled ? `${fundId}%#$&${effectiveDate}%#$&${externalDocumentId}` : fundId;
          if (
            response &&
            response.data &&
            response.data[key] &&
            response.data[key].accounts_info
          ) {
            data.response = response.data[key].accounts_info;
          }
          resolve({ data });
        }).catch((error) => {
          onDownloadError(
            dispatch,
            'Preview or Download Error',
            error.data.message
          );
          reject(error);
        }).finally(() => {
          dispatch(endApiRequest());
        });
      });
    }
    return Promise.resolve({ data });
  };
}

function openErrorModal(title, message) {
  return (dispatch) => {
    onDownloadError(
      dispatch,
      title,
      message
    );
  };
}

export default {
  clearSelectedDocuments,
  clearUnselectedDocuments,
  clearAllSelectedDocuments,
  setSelectedDocuments,
  setUnselectedDocuments,
  toggleSelectAll,
  downloadSelectedMiddleware,
  downloadSelectedDocuments,
  downloadSingleDocument,
  previewOrDownloadSingleDocument,
  toggleDownloadError,
  documentsForDownload,
  assembleRequestParams,
  isValidDocumentSelection,
  openErrorModal,
  onDownloadError,
  getAvailableAccountsForSelectedDocs,
  bulkDownload,
  mergeDocuments,
  singleDocument,
  clearDownloadAvailable,
  toViewExists,
  deleteInterval,
  downloadReady,
  createInterval,
  clearDownloadList,
};
