import i18n from 'i18n.js';
import { indexOf, isEmpty } from 'lodash';
import parse from 'html-react-parser';

import { NDADisclosureText, IDDDisclaimerText } from 'modules/investment_opportunity/constants';

export const types = {
  NON_DISCLOSURE_AGREEMENT: 'NON_DISCLOSURE_AGREEMENT',
  USER_AGREEMENT: 'USER_AGREEMENT',
  COUNTRY_DISCLOSURE_AGREEMENT: 'COUNTRY_DISCLOSURE_AGREEMENT',
  INVESTMENT_DILIGENCE: 'INVESTMENT_DILIGENCE',
  ACCESS_AGREEMENT: 'ACCESS_AGREEMENT',
};

const isAgreementNotYetAccepted = (fund, acceptedAgreements, agreementType) => indexOf(acceptedAgreements[fund?.fund_id], agreementType) === -1;

const showNDAAgreement = (fund, acceptedLegacyAgreements) =>
  (fund.gp_firm_name === 'iCapital Network' ||
    fund.gp_firm_name === 'iCapital') &&
  !fund.suppress_nda &&
  (!fund.non_disclosure_agreements[0] ||
    !fund.non_disclosure_agreements[0]?.agreement) &&
  isAgreementNotYetAccepted(
    fund,
    acceptedLegacyAgreements,
    types.NON_DISCLOSURE_AGREEMENT
  );

const showUEAgreement = (fund, acceptedLegacyAgreements) => fund.view_disclaimer &&
  !isEmpty(fund.view_disclaimer) &&
  isAgreementNotYetAccepted(fund, acceptedLegacyAgreements, types.USER_AGREEMENT);

const showCDAgreement = (fund, acceptedLegacyAgreements) => fund.pending_country_disclosures?.length > 0 &&
  isAgreementNotYetAccepted(fund, acceptedLegacyAgreements, types.COUNTRY_DISCLOSURE_AGREEMENT);

const showIDDAgreement = (fund, user, acceptedLegacyAgreements) => fund.diligence &&
  user.seen_investment_diligence_disclaimers.indexOf(fund.fund_id) === -1 &&
  isAgreementNotYetAccepted(fund, acceptedLegacyAgreements, types.INVESTMENT_DILIGENCE);

export const getLegacyAgreements = (fund, user, acceptedLegacyAgreements) => {
  const legacyAgreements = [];
  if (showUEAgreement(fund, acceptedLegacyAgreements)) {
    legacyAgreements.push({
      text: parse(fund.view_disclaimer),
      title: i18n.t('disclaimer:user_agreement', 'User Agreement'),
      checkboxText: i18n.t('disclaimer:acknowledge_user_agreement', 'I acknowledge that I have read and agree to the above terms.'),
      type: types.USER_AGREEMENT,
    });
  }

  if (showCDAgreement(fund, acceptedLegacyAgreements)) {
    const pcdText = fund.pending_country_disclosures.map((pcd) => pcd.disclosure).join('<br><br>');
    legacyAgreements.push({
      text: parse(pcdText),
      title: i18n.t('disclaimer:country_disclosure', 'Country Disclosure'),
      checkboxText: i18n.t('disclaimer:acknowledge_country_disclosure', 'I acknowledge that I have read and agree to the above country disclosure.'),
      ids: fund.pending_country_disclosures.map((pcd) => pcd.id),
      type: types.COUNTRY_DISCLOSURE_AGREEMENT,
    });
  }

  if (showNDAAgreement(fund, acceptedLegacyAgreements)) {
    const ndaText = NDADisclosureText().replace('{{fundName}}', fund.name);
    legacyAgreements.push({
      text: parse(ndaText),
      title: i18n.t('disclaimer:non_disclosure_agreement', 'Non-Disclosure Agreement'),
      checkboxText: i18n.t('disclaimer:acknowledge_non_disclosure_agreement', 'I acknowledge and agree that I have read and agree to the above terms.'),
      type: types.NON_DISCLOSURE_AGREEMENT,
    });
  }

  if (showIDDAgreement(fund, user, acceptedLegacyAgreements)) {
    legacyAgreements.push({
      text: parse(IDDDisclaimerText()),
      title: i18n.t('disclaimer:diligence_disclosure', 'Diligence Disclosure'),
      checkboxText: i18n.t('disclaimer:understand_diligence_disclosure', 'I understand the language set forth above.'),
      type: types.INVESTMENT_DILIGENCE,
    });
  }

  return legacyAgreements;
};
export const getAccessAgreementsStandarized = (agreements) => agreements.map((accessAgreement) => ({
  agreementId: accessAgreement.agreement_id,
  checkboxText: accessAgreement.checkbox_text,
  requireScrollToAccept: accessAgreement.require_full_review_to_accept,
  text: parse(accessAgreement.text),
  title: accessAgreement.title,
  type: types.ACCESS_AGREEMENT,
}));
