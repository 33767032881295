import { getRequest } from 'actions/utils';

import { createCRUD, createRequestTypes } from 'actions/helpers';

const BASE_NAME = 'investmentStatus';

export const INVESTMENT_STATUS = createCRUD(BASE_NAME, {
  LIST: createRequestTypes(`${BASE_NAME}_LIST`),
});

export const INVESTMENT_STATUS_LIST = {
  UNMOUNT: `${BASE_NAME}_UNMOUNT`,
};

export const fetchInvestmentStatus = (fund_ids) => getRequest({
  url: `investment_status_labels`,
  params: { fund_ids },
  actions: INVESTMENT_STATUS.LIST,
});
