import { getPreviousMonth, startOfMonth, endOfMonth, formatDate } from 'services/DateUtils';

export const TABLE_RESULTS_LIST = [
  {
    value: '25',
    text: '25',
  },
  {
    value: '50',
    text: '50',
  },
  {
    value: '100',
    text: '100',
  },
  {
    value: '150',
    text: '150',
  },
  {
    value: '200',
    text: '200',
  },
  {
    value: '250',
    text: '250',
  },
];

export const TABS_LIST = [
  { id: 'index_returns', label: 'Index Returns', url: '/icn_react/static/fund_finances/index/returns' },
  { id: 'index_performances', label: 'Index Performance', url: '/icn_react/static/fund_finances/index/performances' },
  {
    id: 'share_class_performances',
    label: 'Share Class Performance',
    url: '/icn_react/static/fund_finances/share_class_performances/',
  },
  { id: 'pro_forma_returns', label: 'Pro Forma Returns', url: '/icn_react/static/fund_finances/pro_forma/returns' },
];

export const SHARE_CLASS_PERFORMANCE_REPORT_TYPES = [
  {
    id: 1,
    name: 'ShareClassMonthly',
    reportName: 'ShareClassMonthlyReport',
  },
  {
    id: 2,
    name: 'ShareClassReturnsYearly',
    reportName: 'ShareClassReturnsYearlyReport',
  },
  {
    id: 3,
    name: 'ShareClassReturnsMonthly',
    reportName: 'ShareClassReturnsMonthlyReport',
  },
  {
    id: 4,
    name: 'ShareClassHomeOffice',
    reportName: 'ShareClassHomeOfficeReport',
  },
];

export const INDEX_PERFORMANCE_REPORT_TYPES = [
  {
    id: 1,
    name: 'ShareClassIndexReturnsMonthly',
    reportName: 'ShareClassIndexReturnsMonthlyReport',
  },
  {
    id: 2,
    name: 'IndexMonthly',
    reportName: 'IndexMonthlyReport',
  },
  {
    id: 3,
    name: 'ShareClassIndexReturnsYearly',
    reportName: 'ShareClassIndexReturnsYearlyReport',
  },
  {
    id: 4,
    name: 'ShareClassIndexMonthly',
    reportName: 'ShareClassIndexMonthlyReport',
  },
];

export const defaultPeriodFilter = () => {
  const prevMonth = getPreviousMonth();
  const startOfRange = formatDate(startOfMonth(prevMonth), 'dd-MMM-yyyy');
  const endOfRange = formatDate(endOfMonth(prevMonth), 'dd-MMM-yyyy');
  return `${startOfRange}-to-${endOfRange}`;
};
