import update from 'immutability-helper';
import { FORM_TEMPLATES } from '../actions';
import { FORM_TEMPLATES_VERSIONS } from '../versions/actions';
import { FORM_TEMPLATES_SHOW } from './actions';
import { VERSION_STATUSES } from './constants';

export const resolveTemplateVersionState = (templateVersion, latestVersion) => {
  if (!templateVersion.published_at) return VERSION_STATUSES.DRAFT;

  return templateVersion.id === latestVersion.id ? VERSION_STATUSES.LIVE : VERSION_STATUSES.ARCHIVED;
};

export const transformTemplateVersion = (templateVersion, latestVersion) => {
  const version = `${templateVersion.major}.${templateVersion.minor}`;
  const state = resolveTemplateVersionState(templateVersion, latestVersion);
  return {
    ...templateVersion,
    version,
    state,
  };
};

export const transformTemplateVersions = (templateVersions, latestVersion) =>
  templateVersions?.map((version) => transformTemplateVersion(version, latestVersion));

export const initialState = {
  isLoading: false,
  isLoadingVersions: true,
  isSaving: false,
  formTemplate: {},
  templateVersions: [],
};

const formTemplatesShowReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FORM_TEMPLATES.READ.REQUEST:
      return update(state, { isLoading: { $set: true } });
    case FORM_TEMPLATES.READ.FAILURE:
      return update(state, {
        isLoading: { $set: false },
      });
    case FORM_TEMPLATES.READ.SUCCESS:
      return update(state, {
        isLoading: { $set: false },
        formTemplate: { $set: payload.response },
      });
    case FORM_TEMPLATES.UPDATE.REQUEST:
      return update(state, {
        isSaving: { $set: true },
      });
    case FORM_TEMPLATES.UPDATE.FAILURE:
      return update(state, {
        isSaving: { $set: false },
      });
    case FORM_TEMPLATES.UPDATE.SUCCESS:
      return update(state, {
        isSaving: { $set: false },
        formTemplate: { tag_list: { $set: payload.response.tag_list } },
      });
    case FORM_TEMPLATES_VERSIONS.LIST.REQUEST:
      return update(state, { isLoadingVersions: { $set: true } });
    case FORM_TEMPLATES_VERSIONS.LIST.SUCCESS:
      return update(state, {
        isLoadingVersions: { $set: false },
        templateVersions: {
          $set: [...transformTemplateVersions(payload.response, state.formTemplate.latest_version)],
        },
      });
    case FORM_TEMPLATES_VERSIONS.LIST.FAILURE:
      return update(state, { isLoadingVersions: { $set: false } });
    case FORM_TEMPLATES_VERSIONS.PUBLISH.SUCCESS:
      return update(state, {
        templateVersions: {
          $set: state.templateVersions.map((templateVersion) =>
            transformTemplateVersion(
              templateVersion.id === payload.response.id ? payload.response : templateVersion,
              payload.response
            )
          ),
        },
        formTemplate: {
          latest_version: {
            $set: payload.response,
          },
        },
      });
    case FORM_TEMPLATES_VERSIONS.DELETE.SUCCESS:
      return update(state, {
        templateVersions: {
          $set: state.templateVersions.filter((templateVersion) => templateVersion.id !== payload.request.versionId),
        },
      });
    case FORM_TEMPLATES_SHOW.UNMOUNT:
      return initialState;
    default:
      return state;
  }
};

export default formTemplatesShowReducer;
