import PropTypes from 'prop-types';
import { routeCodes } from 'router';

const COLUMN_TYPES = [
  'id',
  'name',
  'email',
  'wlp_name',
];

function contactCardColDefs(searchKeyword, record) {
  const fields = [];

  COLUMN_TYPES.map((type) => {
    let value = record[type];
    const className = type;
    let renderComponent = 'HighlightedSearchText';
    let renderComponentProps = { };

    switch (type) {
      case 'id':
        renderComponent = 'a';
        renderComponentProps = {
          href: routeCodes.ADMIN_SEARCH
            .replace(':searchText?', searchKeyword)
            .replace(':selectedTab?', 'contacts')
            .replace(':selectedResultId?', record.id),
        };
        break;

      case 'name':
        value = `${record.first_name} ${record.last_name}`;
        renderComponentProps = { searchKeyword, value };
        break;

      case 'email':
        renderComponent = 'HighlightedSearchEmail';
        renderComponentProps = { searchKeyword, value, isEmail: true };
        break;

      case 'wlp_name':
        renderComponentProps = { searchKeyword, value };
        break;

      default:
        break;
    }

    return fields.push({
      key: `${type}_${record.id}}`,
      value,
      className,
      renderComponent,
      renderComponentProps,
    });
  });

  return fields;
}

contactCardColDefs.propTypes = {
  searchKeyword: PropTypes.string,
};

export default contactCardColDefs;
