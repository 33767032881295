import { actionFactory } from 'services/actions';
import { patchRequest } from 'actions/utils';
import { RGA_UPDATE_REPORT_CONFIG } from 'actions/actionsConstants';

import notificationService from 'services/notifications';

const reportGeneratorConfigsUpdateSuccess = actionFactory(
  RGA_UPDATE_REPORT_CONFIG,
);

function errorMessage(data) {
  let msg = 'Failed to update report';
  if (data?.batch_processing_enabled) {
    msg = data?.batch_processing_enabled;
  }
  notificationService.notifyError(msg);
}

function updateReportGeneratorConfig(updateConfig, admin = false) {
  return (dispatch, getState) => {
    const storeConfig = getState().reportGeneratorConfigs.current_configs;
    return dispatch(
      patchRequest({
        url: `/report_generator/configs/${storeConfig.id}`,
        data: {
          report_generator_config: updateConfig,
          admin: admin || undefined,
        },
        onSuccess: (response) => reportGeneratorConfigsUpdateSuccess(response),
        successMessage: { message: 'Report updated successfully' },
        onFailure: ({ data }) => errorMessage(data),
      }),
    );
  };
}

export default {
  updateReportGeneratorConfig,
};
