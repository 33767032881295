import update from 'immutability-helper';
import _ from 'lodash';

import {
  SET_AUTHORIZATION_GROUPS,
  DELETE_AUTHORIZATION_GROUP,
  SET_WLPS_AG,
  SET_PERMISSIONS_AG,
  SET_GROUP_FOR_ACTION,
  SET_PERMISSION_FOR_GROUP,
} from 'actions/actionsConstants';

export const initialState = {
  authorization_groups: [],
  wlp_authorization_groups: [],
  wlp_all: [],
  permissions: [],
  group_for_action: {},
  permission_for_group_action: [],
};

const filterWlp = (groups) => {
  let wlp = groups.map((g) => ({ name: g.white_label_partner_name, id: g.white_label_partner_id || 0 }));
  wlp = _.uniqBy(wlp, 'id');
  wlp = _.sortBy(wlp, ['name', 'id']);
  return wlp;
};

const getWlps = (wlps) => {
  const wlpsList = wlps.map((wlp) => wlp.white_label_partner);
  wlpsList.push({ id: 0, name: 'Flagship' });
  return _.sortBy(wlpsList, (w) => w.name);
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_AUTHORIZATION_GROUPS: {
      const wlp = filterWlp(action.payload);
      return update(state, {
        authorization_groups: { $set: action.payload },
        wlp_authorization_groups: { $set: wlp },
      });
    }
    case SET_WLPS_AG: {
      const wlps = getWlps(action.payload);
      return update(state, {
        wlp_all: { $set: wlps },
      });
    }
    case SET_PERMISSIONS_AG:
      return update(state, { permissions: { $set: action.payload } });
    case SET_GROUP_FOR_ACTION:
      return update(state, { group_for_action: { $set: action.payload } });
    case SET_PERMISSION_FOR_GROUP:
      return update(state, { permission_for_group_action: { $set: action.payload } });
    case DELETE_AUTHORIZATION_GROUP:
      return update(state, {
        authorization_groups: { $set: state.authorization_groups.filter((group) => group.id !== action.payload) },
      });
    default:
      return state;
  }
};
