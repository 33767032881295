import i18n from 'i18n.js';

const TRANSLATION_KEYS = {
  individual_investor: () => i18n.t('glossary:individual_investor'),
  aggregated_holdings: () => i18n.t('glossary:aggregated_holdings'),
  aggregated_accounts: () => i18n.t('glossary:aggregated_accounts'),
};

export const CAPITAL_STATEMENTS_PRIVATE_CAPITAL_SOURCE = 'private_capital';
export const CAPITAL_STATEMENTS_PCAP_CALCS_INTERNAL_SOURCE = 'icapital_pcap';

export function translateName(option) {
  if (option?.name && TRANSLATION_KEYS[option.name]) {
    return TRANSLATION_KEYS[option.name]();
  }
  return option?.name;
}

export function assetClassWithHedgeFundBehavior(assetClass) {
  return ['hedge_fund_open_ended', 'private_capital_open_ended'].includes(assetClass);
}

export function assetClassWithPrivateCapitalBehavior(assetClass) {
  return ['hedge_fund_close_ended', 'private_capital_close_ended'].includes(assetClass);
}

export function isAllAssetClass(assetClass) {
  return assetClass === 'all';
}

export function showInternalSpecificValues(sources, isFundLevel) {
  const internalSources = [CAPITAL_STATEMENTS_PRIVATE_CAPITAL_SOURCE, CAPITAL_STATEMENTS_PCAP_CALCS_INTERNAL_SOURCE];

  return isFundLevel || sources?.filter((s) => !internalSources.includes(s)).length === 0;
}
