import investmentProductReducer from './investmentProductReducer';
import foundationalReducer from './foundationalReducer';
import logReducer from './logReducer';
import sharedReducer from './sharedReducer';
import productSummaryReducer from './productSummaryReducer';
import courseReducer from './courseReducer';
import customCourseReducer from './customCourseReducer';
import courseFormReducer from './courseFormReducer';
import wlpSettingsFormReducer from './wlpSettingsFormReducer';
import questionReducer from './questions/questionReducer';
import initialState from './initialState';

const fn = [
  investmentProductReducer,
  foundationalReducer,
  sharedReducer,
  logReducer,
  productSummaryReducer,
  courseReducer,
  customCourseReducer,
  courseFormReducer,
  wlpSettingsFormReducer,
  questionReducer,
];

export const reducer = (state = initialState, action) => (
  fn.reduce((stateAccumulator, fnc) => fnc(stateAccumulator, action), state)
);

export default reducer;
