import { generatePath } from 'react-router-dom';
import { stringify } from 'query-string';
import { BASE_ROUTE } from './constants';

export const generateUrl = ({ queryParams }) => {
  const path = generatePath(BASE_ROUTE);
  return queryParams ? path.concat(`?${stringify(queryParams, { arrayFormat: 'bracket' })}`) : path;
};

export default generateUrl;
