import { actionFactory } from 'services/actions';
import { getRequest } from 'actions/utils';
import {
  RGA_SET_BASE_QUERY_MODELS,
  RGA_SET_CONFIGURABLE,
  RGA_SET_TEMPORARY_CONFIGURABLE,
} from 'actions/actionsConstants';
import {
  getTemporaryConfigurableId,
  getTemporaryConfigurableType,
} from 'reducers/report_generator_reducers/selectors';
import filterActions from './filters';

const BASE_URL = 'report_generator/base_queries';
const fetchAllBaseQueryModelsSuccess = actionFactory(RGA_SET_BASE_QUERY_MODELS);

function fetchAllBaseQueryModels() {
  return (dispatch) =>
    dispatch(
      getRequest({
        url: BASE_URL,
        onSuccess: fetchAllBaseQueryModelsSuccess,
      }),
    );
}

const rgaSetConfigurable = actionFactory(RGA_SET_CONFIGURABLE);
const rgaSetTemporaryConfigurable = actionFactory(
  RGA_SET_TEMPORARY_CONFIGURABLE,
);

const rgaHandleBaseQueryChange = () => (dispatch, getState) => {
  const configurableId = getTemporaryConfigurableId(getState());
  const configurableType = getTemporaryConfigurableType(getState());

  dispatch(setConfigurable(configurableId, configurableType));
  return dispatch(filterActions.reportGeneratorConfigsSetSelectedFields([]));
};

const setConfigurable =
  (configurableId, configurableType, temporary = false) =>
  (dispatch, getState) => {
    if (temporary) {
      dispatch(
        rgaSetTemporaryConfigurable({ configurableId, configurableType }),
      );
    } else {
      dispatch(rgaSetConfigurable({ configurableId, configurableType }));
    }
  };

export default {
  fetchAllBaseQueryModels,
  setConfigurable,
  rgaHandleBaseQueryChange,
};
