import {
  TOGGLE_NOTES_MODAL,
  OPERATION_DOCS_SET_NOTES
} from 'actions/actionsConstants';

import update from 'immutability-helper';

const initialState = {
  is_open: false,
  current_notes: [],
  currentDocId: null,
  currentDocSource: null,
}

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case TOGGLE_NOTES_MODAL:
      return update(state, {
        is_open: { $set: !state.is_open },
        currentDocSource: {$set: action.payload[0]},
        currentDocId: {$set: action.payload[1]},
      });
    case OPERATION_DOCS_SET_NOTES:
      return update(state, {
        current_notes: { $set: action.payload },
      });
    default:
      return state;
  }
}

export default reducer;
