import _ from 'lodash';
import update from 'immutability-helper';
import {
  RGA_OPEN_FIELD_FILTER,
  RGA_CLOSE_FIELD_FILTER,
  RGA_ADD_FIELD_FILTER,
  RGA_REMOVE_ALL_FIELDS_FILTER,
  RGA_EMPTY_FILTERS,
  RGA_SET_SELECTED_FIELDS,
  RGA_SELECT_ALL_FILTERS,
  RGA_SET_FIELD_METADATA,
} from 'actions/actionsConstants';

import {
  getFilterTypeFromFilterConfig,
  retrieveDBKey,
} from 'reducers/report_generator_reducers/utils';

import initialState from 'reducers/report_generator_reducers/initialState';

const dataTypeOptions = {
  Date: {},
  Boolean: { value: [] },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case RGA_OPEN_FIELD_FILTER: {
      return {
        ...state,
        active_filter: action.payload.filter,
      };
    }
    case RGA_SET_SELECTED_FIELDS: {
      const configurableId = state.configurableId;
      return {
        ...state,
        selectedFields: update(state.selectedFields, {
          $set: { [configurableId]: action.payload },
        }),
      };
    }
    case RGA_CLOSE_FIELD_FILTER:
      const { filter } = action.payload;
      const configurableId = state.configurableId;
      let filteredOptions, filteredTotals;
      if (
        !_.isEmpty(state.stored_queries[configurableId][filter]) &&
        !_.isEmpty(state.stored_queries[configurableId][filter].chosen_options)
      ) {
        filteredOptions =
          state.stored_queries[configurableId][filter].chosen_options;
        filteredTotals =
          state.stored_queries[configurableId][filter].chosen_options.length;
      } else {
        filteredOptions = dataTypeOptions[filter] || [];
        filteredTotals = 0;
      }

      let numericFilterStates = {};
      if (
        state.stored_queries[configurableId][filter] &&
        'ranges' in state.stored_queries[configurableId][filter]
      ) {
        numericFilterStates = {
          appliedRanges: update(state.appliedRanges, {
            [configurableId]: {
              [filter]: {
                $set: state.stored_queries[configurableId][filter].ranges,
              },
            },
          }),
          appliedFilterType: update(state.appliedFilterType, {
            [configurableId]: {
              [filter]: {
                $set: getFilterTypeFromFilterConfig(
                  state.stored_queries[configurableId][filter],
                ),
              },
            },
          }),
        };
      }

      return {
        ...state,
        active_filter: null,
        options: update(state.options, {
          [configurableId]: {
            [filter]: { $set: state.initialOptions[configurableId][filter] },
          },
        }),
        filteredOptions: update(state.filteredOptions, {
          [configurableId]: { [filter]: { $set: filteredOptions } },
        }),
        filteredOptionsPicked: update(state.filteredOptionsPicked, {
          [configurableId]: { [filter]: { $set: [] } },
        }),
        filteredTotals: update(state.filteredTotals, {
          [configurableId]: { [filter]: { $set: filteredTotals } },
        }),
        disableSave: update(state.disableSave, {
          [configurableId]: { [filter]: { $set: false } },
        }),
        ...numericFilterStates,
      };
    case RGA_REMOVE_ALL_FIELDS_FILTER: {
      const configurableId = state.configurableId;
      return {
        ...state,
        options: update(state.options, { [configurableId]: { $set: {} } }),
        requests: update(state.requests, { [configurableId]: { $set: {} } }),
        queries: update(state.queries, { [configurableId]: { $set: {} } }),
        stored_queries: update(state.stored_queries, {
          [configurableId]: { $set: {} },
        }),
        toggle_tracker: update(state.toggle_tracker, {
          [configurableId]: { $set: {} },
        }),
        query_totals: update(state.query_totals, {
          [configurableId]: { $set: {} },
        }),
        totals: update(state.totals, { [configurableId]: { $set: {} } }),
        active_filter: null,
        selectedFields: update(state.selectedFields, {
          [configurableId]: { $set: [] },
        }),
        selectedFieldMetadata: update(state.selectedFieldMetadata, {
          [configurableId]: { $set: {} },
        }),
      };
    }
    case RGA_ADD_FIELD_FILTER: {
      const { filter, type } = action.payload;
      const configurableId = state.configurableId;
      const { model, field } = retrieveDBKey(
        filter,
        state.available_fields[configurableId],
      );
      const filterMethod =
        state.available_fields[configurableId][model][field].method;
      const request_state =
        state.requests[configurableId][filter] !== undefined
          ? state.requests[configurableId][filter]
          : false;
      const tracker_state =
        state.toggle_tracker[configurableId][filter] !== undefined
          ? state.toggle_tracker[configurableId][filter]
          : true;
      const options_state = dataTypeOptions[type] || [];
      const queries_state =
        state.queries[configurableId][filter] !== undefined
          ? state.queries[configurableId][filter]
          : [];
      return {
        ...state,
        options: update(state.options, {
          [configurableId]: { [filter]: { $set: options_state } },
        }),
        requests: update(state.requests, {
          [configurableId]: { [filter]: { $set: request_state } },
        }),
        queries: update(state.queries, {
          [configurableId]: { [filter]: { $set: queries_state } },
        }),
        toggle_tracker: update(state.toggle_tracker, {
          [configurableId]: { [filter]: { $set: tracker_state } },
        }),
        filteredRequests: update(state.filteredRequests, {
          [configurableId]: { [filter]: { $set: false } },
        }),
        selectedFields: update(state.selectedFields, {
          [configurableId]: { $push: [filterMethod] },
        }),
      };
    }
    case RGA_SELECT_ALL_FILTERS: {
      const configurableId = state.configurableId;
      const updatedState = {
        requests: {},
        queries: {},
        toggleTracker: {},
        filteredRequests: {},
        options: {},
      };
      const selectedFields = new Set(state.selectedFields[configurableId]);
      const availableFields = state.available_fields[configurableId];

      Object.keys(availableFields).forEach((model) => {
        Object.values(availableFields[model]).forEach(
          ({ name, type, method }) => {
            selectedFields.add(method);
            updatedState.requests[name] =
              state.requests[configurableId][name] || false;
            updatedState.toggleTracker[name] =
              state.toggle_tracker[configurableId][name] !== undefined
                ? state.toggle_tracker[configurableId][name]
                : true;
            updatedState.options[name] =
              state.options[configurableId][name] !== undefined
                ? state.options[configurableId][name]
                : dataTypeOptions[type] || [];
            updatedState.queries[name] =
              state.queries[configurableId][name] || [];
            updatedState.filteredRequests[name] = false;
          },
        );
      });

      return {
        ...state,
        options: update(state.options, {
          [configurableId]: { $set: updatedState.options },
        }),
        requests: update(state.requests, {
          [configurableId]: { $set: updatedState.requests },
        }),
        queries: update(state.queries, {
          [configurableId]: { $set: updatedState.queries },
        }),
        toggle_tracker: update(state.toggle_tracker, {
          [configurableId]: { $set: updatedState.toggleTracker },
        }),
        filteredRequests: update(state.filteredRequests, {
          [configurableId]: { $set: updatedState.filteredRequests },
        }),
        selectedFields: update(state.selectedFields, {
          [configurableId]: { $set: [...selectedFields] },
        }),
      };
    }
    case RGA_SET_FIELD_METADATA: {
      const { filter, field_alias, field_format } = action.payload;
      const configurableId = state.configurableId;
      const data = {
        ...state.selectedFieldMetadata[configurableId][filter],
        field_alias: field_alias,
        field_format,
      };

      return {
        ...state,
        selectedFieldMetadata: update(state.selectedFieldMetadata, {
          [configurableId]: { [filter]: { $set: data } },
        }),
      };
    }
    case RGA_EMPTY_FILTERS: {
      return {
        ...state,
        filteredOptions: {},
        filteredOptionsPicked: {},
        filteredRequests: {},
        appliedRanges: {},
        filteredTotals: {},
        filteredTracker: {},
        storedExcludedQueries: {},
        options: {},
        requests: {},
        queries: {},
        stored_queries: {},
        toggle_tracker: {},
        query_totals: {},
        totals: {},
        selectedFields: {},
        active_filter: null,
        selectedFieldMetadata: {},
      };
    }
    default:
      return state;
  }
};
