import { uniq } from 'lodash';
import { INVESTMENT_APPROVAL_STATUS_OVERRIDES } from 'constants/index';

const STATUSES = [
  INVESTMENT_APPROVAL_STATUS_OVERRIDES.REVERTED,
  INVESTMENT_APPROVAL_STATUS_OVERRIDES.PRE_APPROVAL,
  INVESTMENT_APPROVAL_STATUS_OVERRIDES.PRE_APPROVAL_SECONDARY,
  INVESTMENT_APPROVAL_STATUS_OVERRIDES.PENDING_REVIEW,
  INVESTMENT_APPROVAL_STATUS_OVERRIDES.EXTERNAL_REVIEW,
  INVESTMENT_APPROVAL_STATUS_OVERRIDES.EXTERNAL_SECONDARY_REVIEW,
  INVESTMENT_APPROVAL_STATUS_OVERRIDES.IR_REVIEW,
  INVESTMENT_APPROVAL_STATUS_OVERRIDES.COMPLIANCE_REVIEW,
  INVESTMENT_APPROVAL_STATUS_OVERRIDES.APPROVED,
  INVESTMENT_APPROVAL_STATUS_OVERRIDES.ACCEPTED,
];

function existingCurrencyIndex(arr, code) {
  return arr.findIndex((obj) => obj.fund_currency === code);
}

function sortByCurrency(arr) {
  // sort alphabetically, then move USD to first
  const first = 'USD';
  arr
    .sort((x, y) => (x.fund_currency > y.fund_currency ? 1 : -1))
    .sort((x, y) => (first === x.fund_currency ? -1 : first === y.fund_currency));
}

function updateSummaryByCurrency(array, currency, count) {
  const index = existingCurrencyIndex(array, currency);

  if (index !== -1) {
    array[index].count += count;
  } else {
    array.push({
      count,
      fund_currency: currency,
    });
  }
}

function updateStatusByCurrency(array, currency, count, amount) {
  const index = existingCurrencyIndex(array, currency);

  if (index !== -1) {
    array[index].invs_count += count;
    array[index].total_amount += amount;
  } else {
    array.push({
      invs_count: count,
      total_amount: amount,
      fund_currency: currency,
    });
  }
}

function totalsByStatus(funds) {
  const totals = {};
  STATUSES.forEach((status) => {
    if (!totals[status]) {
      totals[status] = [];
    }

    funds.forEach((fund) => {
      const fundCurrency = fund.fund_currency;
      const investmentCount = fund.investments_totals[`num_investments_${status}`];
      const transferCount = fund.investments_totals[`num_transfers_${status}`];
      const subNomineeCount = fund.investments_totals[`num_sub_nominee_${status}`];
      const count =
        status === 'accepted' ? investmentCount + subNomineeCount : investmentCount + transferCount + subNomineeCount;
      updateStatusByCurrency(totals[status], fundCurrency, count, fund.investments_totals[`${status}_total_amount`]);
    });

    sortByCurrency(totals[status]);
  });

  return totals;
}

function investmentsTotals(funds) {
  const pendingInvestments = [];
  let totalInvestments = 0;
  const pendingSubscriptions = [];
  const totalSubscriptions = [];

  funds.forEach((fund) => {
    const fundCurrency = fund.fund_currency;
    const totals = fund.investments_totals;
    const pendingInvestmentsCount =
      totals.num_investments_reverted +
      totals.num_investments_pre_approval +
      totals.num_investments_pre_approval_secondary +
      totals.num_investments_pending_review +
      totals.num_investments_external_review +
      totals.num_investments_external_secondary_review +
      totals.num_investments_ir_review +
      totals.num_investments_compliance_review +
      totals.num_investments_approved;

    updateSummaryByCurrency(pendingInvestments, fundCurrency, pendingInvestmentsCount);
    totalInvestments += pendingInvestmentsCount + totals.num_investments_accepted;

    const pendingAmount =
      totals.reverted_total_amount +
      totals.pre_approval_total_amount +
      totals.pre_approval_secondary_total_amount +
      totals.pending_review_total_amount +
      totals.ir_review_total_amount +
      totals.compliance_review_total_amount +
      totals.external_review_total_amount +
      totals.external_secondary_review_total_amount +
      totals.approved_total_amount;

    updateSummaryByCurrency(pendingSubscriptions, fundCurrency, pendingAmount);
    updateSummaryByCurrency(totalSubscriptions, fundCurrency, pendingAmount + totals.accepted_total_amount);
  });

  sortByCurrency(pendingInvestments);
  sortByCurrency(pendingSubscriptions);
  sortByCurrency(totalSubscriptions);

  return [pendingInvestments, totalInvestments, pendingSubscriptions, totalSubscriptions];
}

function totalsSummary(funds) {
  const [pendingInvestments, totalInvestments, pendingSubscriptions, totalSubscriptions] = investmentsTotals(funds);

  const totals = {
    total_investments_count: totalInvestments,
    total_subscriptions: totalSubscriptions,
    pending_investments: pendingInvestments,
    pending_subscriptions: pendingSubscriptions,
  };

  return { ...totals, ...totalsByStatus(funds) };
}

export default function fundsSummaryCalculator(funds) {
  const firms = uniq(funds.reduce((advisoryFirms, fund) => advisoryFirms.concat(fund.advisory_firms), []));
  const summary = {
    funds_count: funds.length,
    totals_summary: totalsSummary(funds),
    firms,
  };

  return summary;
}
