import dateFnsFormat from 'date-fns/format';
import dateFnsParse from 'date-fns/parse';
import dateFnsStartOfMonth from 'date-fns/startOfMonth';
import dateFnsEndOfMonth from 'date-fns/endOfMonth';
import isDate from 'date-fns/isDate';
import isValid from 'date-fns/isValid';
import setQuarter from 'date-fns/setQuarter';
import dateFnsStartOfYear from 'date-fns/startOfYear';
import dateFnsEndOfYear from 'date-fns/endOfYear';
import getYearFns from 'date-fns/getYear';
import addDaysFns from 'date-fns/addDays';
import differenceBetweenDatesFns from 'date-fns/differenceInCalendarDays';
import dateFnsDifferenceInQuarters from 'date-fns/differenceInQuarters';
import dateFnsGetDaysInYear from 'date-fns/getDaysInYear';
import subMonths from 'date-fns/subMonths';
import dateFnsBetween from 'date-fns/isWithinInterval';
import isAfterFns from 'date-fns/isAfter';
import endOfQuarterFns from 'date-fns/endOfQuarter';
import startOfQuarterFns from 'date-fns/startOfQuarter';

import moment from 'moment';

export * from './formatLocale';

export function parseDate(str, format, locale) {
  if (isDate(str)) {
    // dateFnsParse only accepts string.
    return str; // if it is already a date return same.
  }

  if (!str) {
    return null;
  }

  const parsed = dateFnsParse(str, format, new Date(), { locale });

  if (isValid(parsed)) {
    return parsed;
  }
  return undefined;
}

/**
 * Checks if a string is in the ISO 8601 in the format YYYY-MM-DD
 * @param {string} dateString The date string to be checked
 * @returns {boolean} true if the string is in the iso format false otherwise
 */
function isIso8601Date(dateString) {
  const ISO_8601_DATE_FORMAT = /^\d{4}-\d{2}-\d{2}$/;
  return typeof dateString === 'string' && ISO_8601_DATE_FORMAT.test(dateString);
}

/**
 * Converts a string to a date without knowing the input format
 * @param {string} dateString The date string to be converted
 * @returns {Date|null} the converted date or null if the conversion fails
 */
function deprecatedConvertToDate(dateString) {
  let date;

  if (isIso8601Date(dateString)) {
    // Adding T00:00:00 without timezone sets the date in the browser local timezone
    // avoiding dates appearing in a different day for browsers in negative timezones
    date = new Date(`${dateString}T00:00:00`);
  } else {
    date = new Date(dateString);
  }

  if (!isValid(date)) {
    return null;
  }

  return date;
}

/**
 * Return the formatted date in the given format
 * It is highly recommended to pass a Date object as the first parameter.
 * If a string is passed it will be converted to a date using the deprecatedConvertToDate function
 * where the string format may or may not be correctly guessed.
 * @param {Date|string} date the original date, if a string is passed converts it to a date
 * @param {string} format the string of tokens
 * @param {Locale} [locale] A locale object from date-fns (optional, default=defaultLocale)
 * @returns {string|null} the formatted date string or null if the date is invalid.
 */
export function formatDate(date, format, locale) {
  if (isValid(date)) {
    return dateFnsFormat(date, format, { locale });
  }

  const convertedDate = deprecatedConvertToDate(date);

  if (convertedDate) {
    return dateFnsFormat(convertedDate, format, { locale });
  }
  return null;
}

export function isPastDate(date) {
  return date && moment(date, 'DD-MMM-YYYY', true).isBefore();
}

export function getQuarterDate(today = moment().toDate()) {
  return formatDate(today, 'Q-yyyy');
}

export function getBeginMonthQuarter(quarterDate) {
  const beginQuarterDate = startOfQuarterFns(quarterDate);
  return formatDate(beginQuarterDate, 'MMM-yyyy');
}

export function getEndingMonthQuarter(quarterDate) {
  const endingQuarterDate = endOfQuarterFns(quarterDate);
  return formatDate(endingQuarterDate, 'MMM-yyyy');
}

export function lastYear(currYear = moment().toDate().getFullYear(), amount = 1) {
  const date = moment(currYear, 'YYYY');
  return date.subtract(amount, 'year').year().toString();
}

export function nextYear(currYear = moment().toDate().getFullYear(), amount = 1) {
  const date = moment(currYear, 'YYYY');
  return date.add(amount, 'year').year().toString();
}

export function isDateBetween(currDateString, startDate, endDate, currDateformat = 'MMM-yyyy') {
  const date = parseDate(currDateString, currDateformat);

  if (isAfterFns(startDate, endDate)) {
    return dateFnsBetween(date, { start: endDate, end: startDate });
  }
  return dateFnsBetween(date, { start: startDate, end: endDate });
}

export function getCurrentYear() {
  return moment().toDate().getFullYear().toString();
}

export function isAfterToday(dateString, format) {
  const today = new Date();
  const date = parseDate(dateString, format);
  return isAfterFns(date, today);
}

export function isValidDate(input, format) {
  const date = parseDate(input, format);
  return isValid(date);
}

export function endOfMonth(date) {
  return dateFnsEndOfMonth(date);
}

export function startOfMonth(date) {
  return dateFnsStartOfMonth(date);
}

export function parseToQuarterDate(string) {
  const dateParts = string.split('-');
  if (dateParts.length !== 2) return null;
  const year = dateParts[1];
  const quarter = dateParts[0];
  const date = dateFnsParse(year, 'yyyy', new Date());

  return isDate(date) && setQuarter(date, quarter);
}

export function startOfYear(date) {
  return dateFnsStartOfYear(date);
}

export function endOfYear(date) {
  return dateFnsEndOfYear(date);
}

export function getYearDate(date) {
  return getYearFns(date);
}

export function addDays(date, amount) {
  return addDaysFns(date, amount);
}

export function getPreviousMonth(date = new Date()) {
  return subMonths(date, 1);
}

export function differenceBetweenDates(fromDate, toDate) {
  return differenceBetweenDatesFns(fromDate, toDate);
}

export function differenceInQuarters(fromDate, toDate) {
  return dateFnsDifferenceInQuarters(toDate, fromDate);
}

export function getDaysInYear(date) {
  return dateFnsGetDaysInYear(date);
}

export function buildPeriodRange(startDate, endDate = new Date(), delimiter = '-to-') {
  if (!startDate) return '';

  const startLabel = formatDate(startDate, 'dd-MMM-yyyy');
  const endLabel = formatDate(endDate, 'dd-MMM-yyyy');
  return `${startLabel}${delimiter}${endLabel}`;
}
