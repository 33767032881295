import { shape, string } from 'prop-types';

export default shape({
  reverted: string,
  pending_review: string,
  external_review: string,
  external_secondary_review: string,
  ir_review: string,
  compliance_review: string,
  approved: string,
  accepted: string,
  pre_approval: string,
  pre_approval_secondary: string,
  transfers: string,
});
