import { IO_UPDATE_SHOW_FILTERS } from 'modules/investment_opportunity/actions/constants';

import update from 'immutability-helper';

import initialState from './initialState';

export default (state = initialState, action) => {
  switch (action.type) {
    case IO_UPDATE_SHOW_FILTERS: {
      return update(state, { showOpportunitiesFilters: { $set: action.payload } });
    }
    default:
      return state;
  }
};
