import {
  DR_LOAD_DATA_RECONS,
  DR_LOAD_DATA_RECON_FILES,
  DR_LOAD_DATA_RECONS_STATISTICS,
  DR_CREATE_RECONCILIATION,
  DR_CREATE_BULK_RECONCILIATION,
  DR_RETRY_RECONCILIATION,
  DR_RETRY_BULK_RECONCILIATION,
  DR_UPLOAD_RECONCILIATION,
  DR_UPLOAD_BULK_RECONCILIATION,
  DR_APPROVE_RECONCILIATION,
  DR_APPROVE_BULK_RECONCILIATION,
  DR_STOP_RECONCILIATION,
  DR_STOP_BULK_RECONCILIATION,
  DR_LOAD_PRIVATE_ACCESS_FUNDS,
  DR_LOAD_FUND_ADMIN_GROUPS,
  DR_LOAD_RECONCILIATION_TYPES,
  DR_LOAD_RECONCILIATION_PERIODS,
  DR_LOAD_FUND_ADMINS,
  DR_GET_SETTINGS,
  DR_SAVE_SETTINGS,
} from 'actions/actionsConstants';

import { actionFactory } from 'services/actions';

export const drLoadDataRecons = actionFactory(DR_LOAD_DATA_RECONS);

export const drLoadDataReconFiles = actionFactory(DR_LOAD_DATA_RECON_FILES);

export const drLoadDataReconsStatistics = actionFactory(DR_LOAD_DATA_RECONS_STATISTICS);

export const drCreateReconciliation = actionFactory(DR_CREATE_RECONCILIATION);

export const drCreateBulkReconciliation = actionFactory(DR_CREATE_BULK_RECONCILIATION);

export const drRetryReconciliation = actionFactory(DR_RETRY_RECONCILIATION);

export const drRetryBulkReconciliation = actionFactory(DR_RETRY_BULK_RECONCILIATION);

export const drUploadReconciliation = actionFactory(DR_UPLOAD_RECONCILIATION);

export const drUploadBulkReconciliation = actionFactory(DR_UPLOAD_BULK_RECONCILIATION);

export const drApproveReconciliation = actionFactory(DR_APPROVE_RECONCILIATION);

export const drApproveBulkReconciliation = actionFactory(DR_APPROVE_BULK_RECONCILIATION);

export const drStopReconciliation = actionFactory(DR_STOP_RECONCILIATION);

export const drStopBulkReconciliation = actionFactory(DR_STOP_BULK_RECONCILIATION);

export const drLoadPrivateAccessFunds = actionFactory(DR_LOAD_PRIVATE_ACCESS_FUNDS);

export const drLoadFundAdminGroups = actionFactory(DR_LOAD_FUND_ADMIN_GROUPS);

export const drLoadReconciliationTypes = actionFactory(DR_LOAD_RECONCILIATION_TYPES);

export const drLoadReconciliationPeriods = actionFactory(DR_LOAD_RECONCILIATION_PERIODS);

export const drLoadFundAdmins = actionFactory(DR_LOAD_FUND_ADMINS);

export const drGetSettings = actionFactory(DR_GET_SETTINGS);

export const drSaveSettings = actionFactory(DR_SAVE_SETTINGS);
