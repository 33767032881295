import update from 'immutability-helper';

import {
  MANAGE_FUNDING_STATUS_SET_SELECTABLE_STATUSES,
  MANAGE_FUNDING_STATUS_SET_SELECTED_STATUSES,
  MANAGE_FUNDING_STATUS_SET_PRIVATE_ACCESS_FUND,
  MANAGE_FUNDING_STATUS_SET_READY_TO_FUND_INVESTMENTS,
  MANAGE_FUNDING_STATUS_SET_NOT_READY_TO_FUND_INVESTMENTS,
  MANAGE_FUNDING_STATUS_SET_NEXT_FUNDING_WINDOW,
} from 'actions/actionsConstants';

import { UPDATE_OVERRIDE_STATUS } from '../../actions/manage_funding_status_actions/manageFundingStatusActions';

export const initialState = {
  selectable_statuses: [],
  selected_statuses: [],
  private_access_fund: {
    name: '',
    fund_currency: {
      iso_code: 'USD',
    },
  },
  ready_to_fund_investments: [],
  not_ready_to_fund_investments: [],
  next_funding_window: '',
};

export default function manageFundingStatusReducer(state = initialState, action) {
  switch (action.type) {
    case MANAGE_FUNDING_STATUS_SET_SELECTABLE_STATUSES:
      return update(state, {
        selectable_statuses: { $set: action.payload },
      });
    case MANAGE_FUNDING_STATUS_SET_SELECTED_STATUSES:
      return update(state, {
        selected_statuses: { $set: action.payload },
      });
    case MANAGE_FUNDING_STATUS_SET_PRIVATE_ACCESS_FUND:
      return update(state, {
        private_access_fund: { $set: action.payload },
      });

    case UPDATE_OVERRIDE_STATUS.SUCCESS: {
      const index = state.ready_to_fund_investments.findIndex(
        (inv) => inv.id === action.payload.request.investment_id
      );
      return update(state, {
        ready_to_fund_investments: {
          [index]: {
            [action.payload.request.override_field_name]: {
              $set: action.payload.request.override_value,
            },
          },
        },
      });
    }
    case MANAGE_FUNDING_STATUS_SET_READY_TO_FUND_INVESTMENTS:
      return update(state, {
        ready_to_fund_investments: { $set: action.payload },
      });
    case MANAGE_FUNDING_STATUS_SET_NOT_READY_TO_FUND_INVESTMENTS:
      return update(state, {
        not_ready_to_fund_investments: { $set: action.payload },
      });
    case MANAGE_FUNDING_STATUS_SET_NEXT_FUNDING_WINDOW:
      return update(state, {
        next_funding_window: { $set: action.payload },
      });
    default:
      return state;
  }
}
