import { actionFactory } from 'services/actions';
import { getRequest, postRequest } from 'actions/utils';

import {
  SET_INVESTOR_QUALIFICATION_STANDARD_QUESTIONS,
  UPDATE_INVESTOR_QUALIFICATION_STANDARDS_QUESTION,
} from 'actions/actionsConstants';

const setInvestorQualificationStandardQuestions = actionFactory(SET_INVESTOR_QUALIFICATION_STANDARD_QUESTIONS);
const updateInvestorQualificationStandardQuestion = actionFactory(UPDATE_INVESTOR_QUALIFICATION_STANDARDS_QUESTION);


function getInvestorQualificationStandardQuestions() {
  return getRequest({
    url: `user_investor_qualification_standard_questions`,
    onSuccess: setInvestorQualificationStandardQuestions,
  });
}

function checkInvestorQualificationStandardQuestions(id, checked) {
  return (dispatch) => {
    dispatch(updateInvestorQualificationStandardQuestion({ id, checked: !checked }));
  };
}

function createUserAcknowledgement(question_ids) {
  return postRequest({
    url: 'user_acknowledgements',
    data: {
      acknowledgement_type: "investor",
      iqs_question_ids: question_ids,
    },
  })
}

export default {
  getInvestorQualificationStandardQuestions,
  checkInvestorQualificationStandardQuestions,
  createUserAcknowledgement,
};
