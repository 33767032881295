import React, {
  useEffect,
  useState,
} from 'react';
import {
  string, bool, shape,
} from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';
import {
  getMainAppUrl,
  isWhiteLabelPartner,
  hasPrivacyPolicy,
  getTermsOfUse, hideThirdPartyVendors
} from 'selectors/icnBootstrapSelectors';
import _ from 'lodash';

import { DEFAULT_TERMS_OF_USE_URL,
  DEFAULT_PRIVACY_POLICY_URL,
  DEFAULT_GLBA_NOTICE_URL } from 'components/terms_of_use_popup/constants';
import styles from './icnFooter.module.scss';

const cx = classNames.bind(styles);

const IcnFooter = ({
  isWhiteLabel,
  mainAppUrl,
  hasPrivacyPolicy,
  termsOfUse,
  marginEnabled = true,
  hideThirdPartyVendors,
}) => {
  const [privacyPolicyUrl, setPrivacyPolicyUrl] = useState(DEFAULT_PRIVACY_POLICY_URL);
  const [termsOfUseUrl, setTermsOfUseUrl] = useState(DEFAULT_TERMS_OF_USE_URL);
  const { t } = useTranslation('common');
  useEffect(() => {
    if (isWhiteLabel && hasPrivacyPolicy) {
      setPrivacyPolicyUrl(`${mainAppUrl}/icn_react/static/privacy_policy`);
    }
  }, [isWhiteLabel, hasPrivacyPolicy]);

  useEffect(() => {
    if (isWhiteLabel && !_.isEmpty(termsOfUse) && termsOfUse.enable_custom_wlp_page_and_popup) {
      setTermsOfUseUrl(`${mainAppUrl}/icn_react/static/custom_terms_of_use`);
    }
  }, [isWhiteLabel, termsOfUse]);

  const linksForMainSite = () => {
    return !isWhiteLabel && (
      <>
        <li key="0" className="list-inline-item">
          <a href="http://www.icapitalnetwork.com/about-us" target="_blank" rel="noopener noreferrer">{t('common:footer.about_us', 'ABOUT US')}</a>
        </li>
        <li key="1" className="list-inline-item">
          <a href="http://www.icapitalnetwork.com/education" target="_blank" rel="noopener noreferrer">{t('common:footer.blog', 'BLOG')}</a>
        </li>
        <li key="2" className="list-inline-item">
          <a href="http://www.icapitalnetwork.com/about-us/careers" target="_blank" rel="noopener noreferrer">{t('common:footer.careers', 'CAREERS')}</a>
        </li>
        <li key="3" className="list-inline-item">
          <a href="http://www.icapitalnetwork.com/contact-us" target="_blank" rel="noopener noreferrer">{t('common:footer.contact_us', 'CONTACT US')}</a>
        </li>
      </>
    );
  }

  const copyrightForMainSite = () => {
    return !isWhiteLabel && (
      <div className="muted">
        {`© ${(new Date()).getFullYear()} ${t('common:footer.all_rights_reserved', 'INSTITUTIONAL CAPITAL NETWORK, INC. ALL RIGHTS RESERVED')}.`}
      </div>
    );
  }

  return (
    <div
      className={cx('footer_container', {
        container: true,
        icn_footer_margin: marginEnabled,
        icn_footer_padding: true,
      })}
      role="contentinfo"
    >
      <div className={styles.icn_footer_divider} />
      <div className={styles.footer}>
        <div className={styles.footer__links}>
          <ul className="list-inline">
            <li className="list-inline-item">
              <a target="" href={privacyPolicyUrl}>{t('common:footer.privacy_policy', 'PRIVACY POLICY')}</a>
            </li>
            <li className="list-inline-item">
              <a target="" href={termsOfUseUrl}>{t('common:footer.terms_of_service', 'TERMS OF SERVICE')}</a>
            </li>
            <li className="list-inline-item">
              <a target="" href={DEFAULT_GLBA_NOTICE_URL}>{t('common:footer.glba_notice', 'GLBA NOTICE')}</a>
            </li>
            <li className="list-inline-item">
              <a target="" href={`${mainAppUrl}/icn_react/static/disclosures`}>{t('common:footer.disclosures', 'DISCLOSURES')}</a>
            </li>
            {!hideThirdPartyVendors &&
              <li className="list-inline-item">
                <a target=""
                   href={`${mainAppUrl}/icn_react/static/third_party_vendors`}>{t('common:footer.third_party_vendors', 'THIRD PARTY VENDORS')}</a>
              </li>
            }
            {linksForMainSite()}
          </ul>
          {copyrightForMainSite()}
        </div>
      </div>
    </div>
  );
}

IcnFooter.defaultProps = {
  isWhiteLabel: false,
  hasPrivacyPolicy: false,
  termsOfUse: {},
  marginEnabled: true,
  hideThirdPartyVendors: false,
};

IcnFooter.propTypes = {
  mainAppUrl: string.isRequired,
  isWhiteLabel: bool,
  hasPrivacyPolicy: bool,
  termsOfUse: shape(),
  marginEnabled: bool,
  hideThirdPartyVendors: bool,
};

function mapStateToProps(state) {
  return {
    mainAppUrl: getMainAppUrl(state),
    isWhiteLabel: isWhiteLabelPartner(state),
    hasPrivacyPolicy: hasPrivacyPolicy(state),
    termsOfUse: getTermsOfUse(state),
    hideThirdPartyVendors: hideThirdPartyVendors(state),
  };
}

export default connect(mapStateToProps, null)(IcnFooter);
