import { createRequestTypes } from 'actions/helpers';
import { getRequest } from '../utils';

const BASE_NAME = 'PRE_APPROVAL_QUESTIONNAIRE';

export const PRE_APPROVAL_QUESTIONNAIRE = {
  LOAD: createRequestTypes(`${BASE_NAME}/LOAD`),
};

const setQuestionnaireForm = (pafId, wlpId, accountType, investorProfileId) =>
  getRequest({
    url: 'pre_approval_questionnaire_forms',
    params: {
      private_access_fund_id: pafId,
      white_label_partner_id: wlpId,
      investor_type: accountType,
      investor_profile_id: investorProfileId,
    },
    actions: PRE_APPROVAL_QUESTIONNAIRE.LOAD,
  });

export default {
  setQuestionnaireForm,
};
