export default (selection, selectionCollection, idAlias = 'typed_filter_id') => {
  const newSelectedOptions = [];

  if (Array.isArray(selection)) {
    selection.forEach((id) => {
      selectionCollection.forEach((selectedObj) => {
        if (id === selectedObj[idAlias]) {
          newSelectedOptions.push(selectedObj);
        }
      });
    });
    return newSelectedOptions;
  }
  return selection;
};
