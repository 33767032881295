import startCase from 'lodash/startCase';

export const createFilterOption = (content, filterType, typedFilterId, typeFilterLabel = 'typed_filter_id') => ({
  content,
  filter_type: filterType,
  [typeFilterLabel]: typedFilterId,
});

export const createOption = (optionName, groupSlug, optionId) => {
  const id = optionId || `no_${groupSlug.toLowerCase()}`;
  const name = optionName || `No ${startCase(groupSlug)}`;

  return {
    name,
    groupSlug,
    id,
  };
};

export const extractFilterOption = (data) => {
  const {
    record, valueAttr, slugName, idAttr,
  } = data;

  const {
    id,
    name,
    groupSlug,
  } = createOption(record[valueAttr], slugName, record[idAttr]);

  return createFilterOption(name, groupSlug, id);
};
