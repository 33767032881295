import {
  ED_SET_INV_PROD_DATA,
  ED_SET_INV_PROD_CURRENT_PAGE,
  ED_SET_INV_PROD_PAGE_ACTIONS,
  ED_SET_INV_PROD_IS_LOADING_FIRST_PAGE,
  ED_SET_INV_PROD_REQUEST_MADE,
  ED_SET_INV_PROD_SEARCH_TERMS,
  ED_SET_INV_PROD_SORT,
} from '../actions/constants';

import update from 'immutability-helper';

import initialState from './initialState';

export default (state = initialState, action) => {
  switch (action.type) {
    case ED_SET_INV_PROD_DATA: {
      return update(state, {
        investmentProductData: {
          data: { $set: action.payload.data },
          details: { $set: action.payload.details },
          filterOptions: { $set: action.payload.wlp_filter_options },
          filterValues: { $set: action.payload.filters },
          sortOptions: { $set: action.payload.wlp_sort_options },
          currentPage: { $set: action.payload.current_page },
          resultsPerPage: { $set: action.payload.results_per_page },
          infoMessage: { $set: action.payload.info_message },
          filterSelectedValues: { $set: action.payload.filters_selected_values },
          hasNextPage: { $set: action.payload.has_next_page },
          isLoadingFirstPage: { $set: false },
          requestMade: { $set: false },
        },
      });
    }

    case ED_SET_INV_PROD_PAGE_ACTIONS: {
      return update(state, {
        investmentProductData: {
          pageActions: { $merge: action.payload },
        },
      });
    }

    case ED_SET_INV_PROD_CURRENT_PAGE: {
      return update(state, { investmentProductData: { currentPage: { $set: action.payload } } });
    }
    case ED_SET_INV_PROD_REQUEST_MADE: {
      return update(state, { investmentProductData: { requestMade: { $set: action.payload } } });
    }
    case ED_SET_INV_PROD_IS_LOADING_FIRST_PAGE: {
      return update(state, { investmentProductData: { isLoadingFirstPage: { $set: action.payload } } });
    }

    case ED_SET_INV_PROD_SEARCH_TERMS: {
      return update(state, {
        investmentProductData: {
          pageActions: { searchTerms: { $set: action.payload } },
        },
      });
    }

    case ED_SET_INV_PROD_SORT: {
      return update(state, {
        investmentProductData: {
          pageActions: { sort: { $set: action.payload } },
        },
      });
    }

    default:
      return state;
  }
};
