import update from 'immutability-helper';
import {
  EDUCATION_QUESTIONS_SET_SORT,
  EDUCATION_QUESTIONS_SET_APPLIED_FILTERS,
} from '../../actions/constants';
import initialState from '../initialState';

export default (state = initialState, action) => {
  switch (action.type) {
    case EDUCATION_QUESTIONS_SET_APPLIED_FILTERS: {
      return update(state, { questionData: { $merge: action.payload } });
    }

    case EDUCATION_QUESTIONS_SET_SORT: {
      return update(state, { questionData: { sort: { $set: action.payload } } });
    }

    default:
      return state;
  }
};
