import update from 'immutability-helper';
import {
  ED_SET_FUND_SUMMARY_DATA,
  ED_SET_FUND_SUMMARY_INVALID_DATA,
  ED_SET_ACTIVITY_DATA,
  ED_SET_ACTIVITY_INVALID_DATA,
} from '../actions/constants';
import initialState from './initialState';

export default (state = initialState, action) => {
  switch (action.type) {
    case ED_SET_FUND_SUMMARY_DATA: {
      return update(state, {
        productSummaryData: {
          data: {
            course: { $set: action.payload.data.course },
            activity: { $merge: action.payload.data.activity },
          },
          isLoadingFirstPage: { $set: false },
        },
      });
    }
    case ED_SET_FUND_SUMMARY_INVALID_DATA: {
      return update(state, {
        productSummaryData: {
          data: {
            course: { $set: { valid: false } },
            activity: { $merge: {} },
          },
          isLoadingFirstPage: { $set: false },
        },
      });
    }

    case ED_SET_ACTIVITY_DATA: {
      return update(state, {
        productSummaryData: {
          data: {
            activity: { $set: action.payload },
          },
        },
      });
    }

    case ED_SET_ACTIVITY_INVALID_DATA: {
      return state;
    }

    default:
      return state;
  }
};
