import { postRequest } from 'actions/utils';

const multifactorAuthenticationUrl = 'mfa/';

const requestTokenSms = (showSpinner = true) => postRequest({
  url: `${multifactorAuthenticationUrl}request_sms.json`,
  showSpinner,
});

const verifyMfaToken = (token, showSpinner = true) => postRequest({
  url: `${multifactorAuthenticationUrl}verify_token.json`,
  showSpinner,
  data: {
    token,
  },
});

const mfaRegister = (countryCode, cellphone, showSpinner = true) => postRequest({
  showSpinner,
  url: `${multifactorAuthenticationUrl}enable_user.json`,
  data: {
    cellphone,
    country_code: countryCode,
  },
});

export default {
  requestTokenSms,
  verifyMfaToken,
  mfaRegister,
};
