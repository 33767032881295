import { getRequest } from 'actions/utils';
import { createCRUD, createRequestTypes } from 'actions/helpers';

const BASE_NAME = 'massMailerFunds';

export const MASS_MAILER_FUNDS_INUSE = createCRUD(BASE_NAME, {
  LIST: createRequestTypes(`${BASE_NAME}_LIST`),
});

export const MASS_MAILER_FUNDS_INUSE_LIST = {
  UNMOUNT: `${BASE_NAME}_UNMOUNT`,
};

export const getMassMailerFunds = () => getRequest({
  showSpinner: false,
  url: 'mass_mailer/funds',
  actions: MASS_MAILER_FUNDS_INUSE.LIST,
});
