import _, { isEqual, isNull, isFunction } from 'lodash';
import initialState from './initialState';

const initialPayload = {
  response: {},
  status: 0,
  statusText: '',
};

const expectedHttpStatus = [200, 201, 202, 302];

export const updateState = (state = initialState, payload = initialPayload, actionResult = () => { }) => {
  const { response, status, statusText } = payload;
  const nextState = actionResult(response);
  const serverError = !expectedHttpStatus.includes(status);
  return {
    ...Object.assign({}, state, nextState),
    error: nextState.error || serverError,
    request_total: state.request_total + 1,
    message: serverError ? statusText : (nextState.message || 'No message applied...'),
  };
};

export const convertXmlToJson = (xml) => {
  let object = {};
  const xmlObject = isEqual(typeof xml, 'string') ? new DOMParser().parseFromString(xml, 'text/xml') : xml;

  if (!isNull(xmlObject) && isEqual(typeof xmlObject, 'object')) {
    if (isEqual(xmlObject.nodeType, 1)) {
      if (!isEqual(xmlObject.attributes.length, 0)) {
        object['@attributes'] = {};

        xmlObject.attributes.forEach((item) => {
          object['@attributes'][item.nodeName] = item.nodeValue;
        });
      }
    } else if (isEqual(xmlObject.nodeType, 3)) {
      object = xmlObject.nodeValue;
    }

    if (isFunction(xmlObject.hasChildNodes) && xmlObject.hasChildNodes()) {
      if (isEqual(xmlObject.childNodes.length, 1) && isEqual(xmlObject.childNodes[0].nodeType, 3)) {
        object = xmlObject.childNodes[0].nodeValue;
      } else {
        xmlObject.childNodes.forEach((item) => {
          if (isEqual(typeof object[item.nodeName], 'undefined')) {
            object[item.nodeName] = convertXmlToJson(item);
          } else {
            if (isEqual(typeof object[item.nodeName].push, 'undefined')) {
              const oldItem = object[item.nodeName];

              object[item.nodeName] = [];
              object[item.nodeName].push(oldItem);
            }

            object[item.nodeName].push(convertXmlToJson(item));
          }
        });
      }
    }
  }

  return object;
};
