/* eslint-disable max-statements */
import update from 'immutability-helper';
import {
  PPB_SET_LOADING,
  PPB_SET_FUND_TEMPLATE,
  PPB_SET_FUND_TEMPLATES,
  PPB_SET_FUND_PROFILE,
  PPB_SET_FUND_PROFILE_FUND,
  PPB_SET_IS_INVESTOR_PROFILE_COMPLETED,
  PPB_SET_USER_HAS_INVESTOR_PROFILE,
  PPB_SET_IS_CONTACT_INFO_OPEN,
  PPB_SET_FUND_PROFILE_VIEW,
  PPB_SET_FUND_TEMPLATE_DETAILS,
} from '../actions/Constants';

export const initialState = {
  loading: false,
  fundTemplates: {},
  fundProfile: null,
  currentUserHasProfile: false,
  isInvestorProfileCompleted: false,
  investorProfileId: null,
};

function setCurrentUserHasProfile(state, payload) {
  return update(state, {
    currentUserHasProfile: { $set: payload.has_profile },
  });
}

function setInvestorProfileCompleted(state, payload) {
  return update(state, {
    isInvestorProfileCompleted: { $set: payload.is_completed },
    investorProfileId: { $set: payload.investor_profile_id },
  });
}
const nestByID = (profiles) => (
  profiles.reduce((acc, profile) => {
    acc[profile.id] = profile;
    return acc;
  }, {})
);

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case PPB_SET_LOADING: {
      return { ...state, loading: payload };
    }
    case PPB_SET_FUND_TEMPLATE: {
      return { ...state, fundTemplates: { ...state.fundTemplates, [payload.id]: payload } };
    }
    case PPB_SET_FUND_TEMPLATE_DETAILS: {
      const templatesCopy = { ...state.fundTemplates };
      payload.forEach((i) => {
        const matchingTemplate = state.fundTemplates[i.id];
        if (matchingTemplate) {
          templatesCopy[i.id] = { ...matchingTemplate, ...i };
        }
      });
      return { ...state, fundTemplates: templatesCopy };
    }
    case PPB_SET_FUND_TEMPLATES: {
      return { ...state, fundTemplates: nestByID(payload.templates), count: payload.total_count };
    }
    case PPB_SET_FUND_PROFILE_FUND: {
      return { ...state, fundProfile: payload, loading: false };
    }
    case PPB_SET_FUND_PROFILE: {
      return { ...state, fundProfile: payload, loading: false };
    }
    case PPB_SET_FUND_PROFILE_VIEW: {
      return { ...state, fundProfile: payload, loading: false };
    }
    case PPB_SET_IS_CONTACT_INFO_OPEN: {
      return { ...state, isContactInfoOpen: payload, loading: false };
    }
    case PPB_SET_USER_HAS_INVESTOR_PROFILE:
      return setCurrentUserHasProfile(state, payload);
    case PPB_SET_IS_INVESTOR_PROFILE_COMPLETED: {
      return setInvestorProfileCompleted(state, payload);
    }
    default: {
      return state;
    }
  }
};
