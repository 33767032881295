import { getQuarterDate } from 'services/DateUtils';

export default {
  is_fetching: true,
  // CONSIDER: Rename confusing state property. is_transactions_fetching indicates if any of the cascading filters at
  // the top of the page (Fund Name, Entered At Range, GL Account, Status) are loading.
  is_transactions_fetching: true,
  is_table_fetching: true,
  funds: [],
  selected_fund: undefined,
  selected_period: undefined,
  active_tab: 'pending',
  accounts: [],
  selected_accounts: [],
  document_exports: {
    exports: [],
    total: null,
  },
  selected_transaction_ids: [],
  selected_batches: [],
  statuses: [],
  selected_statuses: [],
  select_all: false,
  is_archive_modal_open: false,
  is_move_to_pending_modal_open: false,
  is_approve_modal_open: false,
};
