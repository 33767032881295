import { useQuery } from '@tanstack/react-query';
import { getRequest } from 'services/apiRequest';

export const useQueryGeoCountriesForPrivateAccessFund = ({ privateAccessFundId, forIndividual }) => {
  const url = 'icn_geo_countries/for_private_access_fund';
  const params = { private_access_fund_id: privateAccessFundId, for_individual: forIndividual };

  return useQuery({
    queryKey: [url, params],
    queryFn: () => getRequest({ url, params }),
    cacheTime: Infinity,
    staleTime: Infinity,
    select: (data) => data.icn_geo_countries,
  });
};
