import { setEmptyValues } from 'services/investor_profile';

const resetCustodianAccount = () => {
  const resetFields = [
    'custodian_id',
    'custodian_account_type_id',
    'account_name',
    'account_number',
    'group_number',
    'addendum_on_file',
  ];

  return setEmptyValues(resetFields);
};

const generalResets = () => {
  const resetFields = [
    'section_b_recipient_bank_name',
    'section_b_recipient_bank_swift_code',
    'section_b_recipient_bank_aba',
    'section_b_recipient_account_name',
    'section_a_name_of_investor',
    'section_b_recipient_account_number',
    'section_b_recipient_reference',
  ];

  return setEmptyValues(resetFields);
};

const withCustodianResets = () => {
  const resetFields = [
    'wire_instructions_us_correspondent_bank_name',
    'section_b_recipient_bank_location',
    'wire_instructions_us_correspondent_aba_number',
    'wire_instructions_us_correspondent_swift_code',
    'wire_instructions_international_bank_account',
  ];

  return setEmptyValues(resetFields);
};

const withoutCustodianResets = () => {
  const resetFields = [
    'custodian_account_id',
    'section_b_recipient_ffc',
    'section_b_recipient_client_account_name',
    'section_b_recipient_client_account_number',
  ];

  return setEmptyValues(resetFields);
};

const hasCustodianResets = (hasCustodian) => {
  const conditionalResets = hasCustodian ? withCustodianResets : withoutCustodianResets;

  return {
    ...generalResets(),
    ...resetCustodianAccount(),
    ...conditionalResets(),
  };
};

export default hasCustodianResets;
