import update from 'immutability-helper';
import {
  ACTIVITY_LOG_SALES_COVERAGE_SET_USER_SALES_COVERAGE,
  ACTIVITY_LOG_SALES_COVERAGE_SET_COVERAGE_DIALOG,
  ACTIVITY_LOG_SALES_COVERAGE_UPDATE_SALES_COVERAGE,
} from 'modules/activity_log/sales_coverage/actions/constants';
import initialState from 'modules/activity_log/reducers/initialState';

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIVITY_LOG_SALES_COVERAGE_SET_COVERAGE_DIALOG: {
      return update(state, {
        salesCoverageData: {
          is_user_sales_coverage_dialog_open: { $set: action.payload },
        },
      });
    }

    case ACTIVITY_LOG_SALES_COVERAGE_SET_USER_SALES_COVERAGE: {
      return update(state, {
        salesCoverageData: {
          user_sales_coverage: { $set: action.payload.user_sales_coverage },
        },
      });
    }

    case ACTIVITY_LOG_SALES_COVERAGE_UPDATE_SALES_COVERAGE: {
      return update(state, {
        salesCoverageData: {
          user_sales_coverage: { $set: action.payload.user_sales_coverage },
        },
      });
    }

    default:
      return state;
  }
};
