import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { wlpShape } from 'prop_types/shared';
import { whiteLabelPartner as getWhiteLabelPartner } from 'selectors/icnBootstrapSelectors';

export const CurrentWhiteLabelPartnerContext = createContext();
const DEFAULT_WLP = {};

function CurrentWhiteLabelPartnerProvider({ whiteLabelPartner, children }) {
  return (
    <CurrentWhiteLabelPartnerContext.Provider value={whiteLabelPartner || DEFAULT_WLP}>
      {children}
    </CurrentWhiteLabelPartnerContext.Provider>
  );
}

function mapStateToProps(state) {
  const whiteLabelPartner = getWhiteLabelPartner(state);
  return {
    whiteLabelPartner,
  };
}

CurrentWhiteLabelPartnerProvider.propTypes = {
  whiteLabelPartner: PropTypes.oneOfType([
    wlpShape,
    PropTypes.bool, // false is the default return of the selector when the wlp is not available
  ]),
  children: PropTypes.node.isRequired,
};

CurrentWhiteLabelPartnerProvider.defaultProps = {
  whiteLabelPartner: null,
};

export default connect(mapStateToProps, null)(CurrentWhiteLabelPartnerProvider);
