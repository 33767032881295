export default {
  is_loading_default_view: false,
  is_loading_rep_data_for_filter: false,
  is_loading_fund_data_for_filter: false,
  is_loading_funds_for_filter: false,
  active_saved_view: null,
  funds: [],
  selected_funds: [],
  selected_single_fund: '',
  current_period_type: 'accruals',
  selected_period: '',
  dashboard_totals: {
    total_corporate_payment: 0,
    total_investor_fee: 0,
    total_revenue_share_fee: 0,
    total_trail_fee: 0,
  },
  current_page: 1,
  results_per_page: 25,
  selected_period_picker_type: 'accrual',
  representatives: [],
  options_for_filter: [],
  fund_fee_types: [],
};
