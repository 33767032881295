/* eslint-disable max-len, max-statements, max-lines */
import { isNumber } from 'lodash';
import icnIdentity from 'services/icn_identity/icnIdentity';

export const isFaFeatureEnabled = (enableDiscretionarySigner, investment, user) => Boolean(
  enableDiscretionarySigner &&
  !investment.custodian?.disable_discretionary_fa &&
  !icnIdentity.isIndividualInvestor(user)
);

export const checkFaAdvisoryOnly = (investment, preferredSigner) => {
  if (preferredSigner !== 'Financial Advisor Advisory Only') {
    return true;
  }
  return investment.sub_doc_type_name === 'advisory_individual';
};

export const signerSelected = (faFeature, signer) => (
  faFeature.faSelection === 'fa' && faFeature.preferredSigner === signer
);

const validManualFinancialAdvisor = (faFeature) => {
  if (signerSelected(faFeature, 'Financial Advisor')) {
    return !!faFeature.faEmail;
  }
  return true;
};

const validManualCentralSignatory = (faFeature) => {
  if (signerSelected(faFeature, 'Central Signatory')) {
    return !!faFeature.selectedSignatory;
  }
  return true;
};

const faShowOfferingDocs = (faFeature) => (
  faFeature.faSelection === 'investor' || faFeature.sendOfferingDocs
);

export const stripIcnExtension = (email) => {
  if (!email) return email;
  return email.replace(/\b(\.icn[0-9]+)$\b/, '');
};

export const setSigners = (
  defaults,
  investment
) => {
  const arr = [];

  if (investment.first_recipient) {
    arr.push({
      ...defaults.firstSigner,
      email: stripIcnExtension(investment.first_recipient.email),
      displayName: investment.first_recipient.display_name,
    });
  }
  if (investment.extra_recipient) {
    arr.push({
      ...defaults.secondSigner,
      email: stripIcnExtension(investment.extra_recipient.email),
      displayName: investment.extra_recipient.display_name,
    });
  }
  if (investment.extra_third_recipient) {
    arr.push({
      ...defaults.thirdSigner,
      email: stripIcnExtension(investment.extra_third_recipient.email),
      displayName: investment.extra_third_recipient.display_name,
    });
  }

  return arr;
};

export const manualSignatureOnly = (investment) => Boolean(
  investment.paf_subscription_sign_method === 'manual_only' ||
  investment.disable_electronic_signature ||
  (
    investment.custodian &&
    investment.custodian.manual_signature &&
    !investment.paf_strict_signature_method
  )
);

export const allowManualSignature = (investment) => manualSignatureOnly(investment) || investment.paf_subscription_sign_method === 'manual_and_electronic';

export const hideSigners = (faFeature, faFeatureEnabled) => (
  faFeatureEnabled && faFeature.faSelection !== 'investor'
);

export const hideOfferingDocumentInfo = (investment, faFeature, resetSubdoc = false) => (
  investment.requires_pre_approval ||
  investment.requires_pre_approval_secondary ||
  resetSubdoc ||
  !faShowOfferingDocs(faFeature)
);

export const hideManualSignature = (availableSignMethod, faFeature, faFeatureEnabled) => (
  availableSignMethod === 'electronic_only' ||
  (faFeatureEnabled && !validManualFinancialAdvisor(faFeature)) ||
  (faFeatureEnabled && !validManualCentralSignatory(faFeature))
);

export const getModifiedInvestment = (res, investment) => ({
  ...investment,
  agreement_id: res.investment.agreement_id,
  amount: Number(res.investment.commitment),
  commitment: Number(res.investment.commitment),
  subscription_document: res.investment.subscription_document,
  subscription_signature_process_id: res.investment.signature_process_id,
  subscription_temp_file_url: res.investment.temp_file_url,
  signature_vendor: res.investment.signature_vendor,
  approval_status: 'Pending Review',
});

export const showMissingPhoneNumbersError = (
  investment,
  faFeature,
  faFeatureEnabled,
  phoneNumberSvc
) => (
  faFeature.require2Fa &&
  investment.sign_method === 'electronic' &&
  phoneNumberSvc.signerPhoneNumbersMissing() &&
  !(faFeatureEnabled && faFeature.faSelection === 'fa')
);

export const formatCurrency = (amount) => {
  if (isNumber(amount)) {
    return amount;
  }
  const amt = amount.replace(/\$|,/g, '');
  return amt === '' ? 0 : parseFloat(amt);
};

export const existCustodianAccount = (investment) => Boolean(investment.custodian_account?.custodian_id);

export const custodianManualSignatureOnly = (investment) => Boolean(investment.custodian &&
    investment.custodian.manual_signature &&
    !investment.paf_strict_signature_method);
