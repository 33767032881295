export const publicPath = '/icn_react/static/';

export const routeCodes = {
  EDUCATION_DASHBOARD: `${publicPath}education_dashboard`,
  INVESTMENT_PRODUCT_SUMMARY: `${publicPath}investment_product/:id/summary`,
  INVESTMENT_PRODUCT_SUMMARY_RESEARCH: `${publicPath}investment_product/:id/summary_research`,
  COURSE_SUMMARY: `${publicPath}course/:id/summary`,
  EDUCATION_SETTINGS: `${publicPath}education_settings`,
  EDUCATION_SETTINGS_COURSES_FORM: `${publicPath}education_settings/:referrer(customs)?/:cta(view)?/courses/:id/:action(create|edit)`,
  EDUCATION_SETTINGS_COURSES_CUSTOM_LIST: `${publicPath}education_settings/courses/:id/customs`,
  EDUCATION_SETTINGS_QUESTION_LIST: `${publicPath}education_settings/question_bank`,
  EDUCATION_SETTINGS_QUESTION_FORM_CREATE: `${publicPath}education_settings/question_bank/create`,
  EDUCATION_SETTINGS_QUESTION_FORM_UPDATE: `${publicPath}education_settings/question_bank/:id/update`,
  EDUCATION_SETTINGS_CUSTOM_QUESTION_FORM_CREATE: `${publicPath}education_settings/course/:course_id/question/create`,
  EDUCATION_SETTINGS_CUSTOM_QUESTION_FORM_UPDATE: `${publicPath}education_settings/course/:course_id/question/:id/update`,
  INTEGRATED_LEARN_SAML: `${publicPath}integrated_learn/:page?/:subpage?`,
  EDUCATION_MFE: `${publicPath}app/education`,
};
