/* eslint-disable react/no-danger */
import React, { useState, useEffect } from 'react';
import {
  arrayOf, shape, number, string, bool, func,
} from 'prop-types';
import {
  Checkbox, FormControlLabel, FormGroup, Skeleton, Typography,
} from '@icapitalnetwork/supernova-core';
import FieldConfiguration from 'containers/investor_profile/field_configuration/FieldConfiguration';
import sanitizeHtml from 'sanitize-html';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

export const QualificationQuestions = ({
  role,
  questions,
  updateStore,
  isInvitedClient,
  isRegistrationLoading,
}) => {
  const { t } = useTranslation(['registration']);
  const [qualQuestions, setQualQuestions] = useState(questions);

  useEffect(() => {
    if (!isEmpty(questions)) {
      setQualQuestions(questions);
    }
  }, [questions]);

  if (isRegistrationLoading) {
    return (
      <Skeleton width={300} height={240} />
    );
  }

  return (
    <FieldConfiguration
      fieldName={[
        'investorInformation',
      ]}
    >
      {() => (
        <>
          {role === 'ria' && (
            <Typography>
              {t('registration:qualification_questions.ria', 'Please select all qualification types that the Firm advises.')}
            </Typography>
          )}
          {(role !== 'ria' || isInvitedClient) && (
            <Typography>
              {t('registration:qualification_questions.invited_client', 'Please select all qualification types that apply.')}
            </Typography>
          )}
          {qualQuestions.map((question) => (
            <FormGroup key={question.id} row>
              <FormControlLabel
                label={(
                  <>
                    {
                        !isEmpty(question.title) && (
                          <div>
                            {question.title}
                          </div>
                        )
                      }
                    <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(question.description) }} />
                  </>
                  )}
                control={(
                  <Checkbox
                    data-testid={`question-${question.id}`}
                    checked={question.checked}
                    onChange={() => updateStore(question.id, question.checked)}
                  />
                )}
              />
            </FormGroup>
          ))}
        </>
      )}
    </FieldConfiguration>
  );
};

QualificationQuestions.defaultProps = {
  role: '',
  questions: [],
  isInvitedClient: false,
  isRegistrationLoading: false,
};

QualificationQuestions.propTypes = {
  role: string,
  isInvitedClient: bool,
  questions: arrayOf(shape({
    id: number.isRequired,
    title: string,
    description: string.isRequired,
    checked: bool.isRequired,
  })),
  isRegistrationLoading: bool,
  updateStore: func.isRequired,
};

export default QualificationQuestions;
