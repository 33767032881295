import React from 'react';
import Loading from 'components/shared/loading/Loading';
import PrivateRoute from 'containers/private_route/PrivateRoute';
import Loadable from 'react-loadable';
import { routeCodes as orderStatusRouteCodes } from './modules/dashboard/router';

const LoadableOrderStatusApp = Loadable({
  loader: () => import(
    /* webpackChunkName: "IntegrationHubOrderStatus" */ './modules/dashboard'
  ),
  loading: Loading,
});

export function App() {
  return (
    <PrivateRoute
      path={Object.values(orderStatusRouteCodes)}
      component={LoadableOrderStatusApp}
    />
  );
}
