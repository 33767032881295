import {
  DC_SET_RESULTS,
  DC_SET_PAGE,
  DC_SET_IMPERSONATE_USER_EMAIL,
  DC_FAILED_API_REQUEST,
  DC_SET_VIEW_TABLE_OPERATION_DOCS,
  DC_SET_SORT,
  DC_SET_NOTE_RESULT,
  DC_SET_IS_FETCHING,
  DC_SET_IMPERSONATE_USER_ID,
  DC_FETCH_LOGIN_AUTO_SUGGEST,
  DC_SET_LOGIN_FETCHING,
  DC_SET_IS_TABLE_FETCHING,
} from 'actions/actionsConstants';

import update from 'immutability-helper';
import _ from 'lodash';

const initialState = {
  total_count: 0,
  total_pages: 0,
  results: [],
  page: 1,
  prev_page: null,
  next_page: 2,
  view_operation_docs: false,
  sort: {
    sort_direction: 'desc',
    sort_field: 'publish_date',
  },
  error: null,
  impersonate_user_email: null,
  impersonate_user_id: '',
  loginAutoSuggestList: [],
  isFetching: false,
  loginAutoSuggestFetching: false,
  isTableFetching: false,
  impersonated_user: {},
};

export { initialState };

export default (state = initialState, action) => {
  switch (action.type) {
    case DC_SET_NOTE_RESULT:
      const index = state.results.findIndex((i) => i.doc_source_id === action.payload);

      return update(state, {
        results: {
          [index]: {
            $apply(item) {
              return {
                ...item,
                has_notes: true,
              };
            },
          },
        },
      });
    case DC_SET_VIEW_TABLE_OPERATION_DOCS:
      return update(state, {
        view_operation_docs: {
          $set: action.data,
        },
      });
    case DC_SET_RESULTS:
      return update(state, {
        total_count: {
          $set: action.data.meta.total_count,
        },
        results: {
          $set: action.data.docs,
        },
        page: {
          $set: action.data.meta.current_page,
        },
        prev_page: {
          $set: action.data.meta.prev_page,
        },
        next_page: {
          $set: action.data.meta.next_page,
        },
        total_pages: {
          $set: action.data.meta.total_pages,
        },
        isFetching: {
          $set: false,
        },
        isTableFetching: {
          $set: false,
        },
        impersonated_user: {
          $set: action.data.meta.impersonated_user,
        },
      });
    case DC_SET_PAGE:
      return update(state, {
        page: {
          $set: action.data,
        },
      });

    case DC_SET_SORT:
      let nextSort = null;
      if (!_.isEmpty(action.data.sort_direction)) {
        nextSort = {
          sort_direction: action.data.sort_direction,
          sort_field: action.data.sort_field,
        };
      }

      return update(state, {
        sort: {
          $set: nextSort,
        },
      });

    case DC_FAILED_API_REQUEST:
      return update(state, {
        error: {
          $set: action.error,
        },
      });

    case DC_SET_IMPERSONATE_USER_EMAIL:
      return update(state, {
        impersonate_user_email: {
          $set: action.data,
        },
      });

    case DC_SET_IMPERSONATE_USER_ID:
      return update(state, {
        impersonate_user_id: {
          $set: action.data,
        },
      });

    case DC_SET_IS_FETCHING:
      return update(state, {
        isFetching: {
          $set: action.payload,
        },
      });

    case DC_SET_IS_TABLE_FETCHING:
      return update(state, {
        isTableFetching: {
          $set: action.payload,
        },
      });

    case DC_FETCH_LOGIN_AUTO_SUGGEST:
      return update(state, {
        loginAutoSuggestList: {
          $set: action.payload,
        },
      });

    case DC_SET_LOGIN_FETCHING:
      return update(state, {
        loginAutoSuggestFetching: {
          $set: action.payload,
        },
      });

    default:
      return state;
  }
};
