export default {
  search_results_loaded: false,
  previous_search_results: {},
  current_search_results: [],
  search_keyword: '',
  is_admin_search_open: false,
  is_preview_results_open: true,
  is_search_button_enable: true,
  is_search_input_disable: false,
  tabs: [],
  selected_tab: undefined,
  current_page: 1,
  results_per_page: 25,
  selected_result: {},
  selected_result_position: 0,
  related_info_data: {},
  is_edit_contact_card_email_modal_open: false,
  edit_contact_card_email_errors: '',
  related_tables_pages: {
    teams_current_page: 1,
    accounts_current_page: 1,
    investments_current_page: 1,
  },
  contact_card_to_edit: {},
  edit_type: '',
  cloned_user_password: '',
  cloned_user_email: '',
  cloned_user_first_name: '',
  cloned_user_last_name: '',
  cloned_user_ticket_id: '',
  previous_page_url: '',
};
