import update from 'immutability-helper';

import {
  RGA_SET_CREATE_CUSTOM_QUERY,
  RGA_SET_CUSTOM_QUERIES,
  RGA_SET_CUSTOM_QUERY,
} from 'actions/actionsConstants';
import initialState from '../report_generator_reducers/initialState';

export default (state = initialState, action) => {
  switch (action.type) {
    case RGA_SET_CUSTOM_QUERIES:
      return update(state, {
        reportGeneratorCustomQueries: {
          $set: action.payload.report_generator_custom_queries,
        },
      });
    case RGA_SET_CREATE_CUSTOM_QUERY:
      return update(state, {
        createCustomQuery: { $set: action.payload },
        customQuery: { $set: undefined },
      });
    case RGA_SET_CUSTOM_QUERY:
      return update(state, { customQuery: { $set: action.payload } });
    default:
      return state;
  }
};
