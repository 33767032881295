import { LM_SET_SVC_STATE, LM_SET_POLL_DATA } from 'actions/actionsConstants';
import initialState from './initialState';

export default (state = initialState, action) => {
  switch (action.type) {
    case LM_SET_SVC_STATE:
      return {
        ...state,
        serviceState: action.payload,
      };
    case LM_SET_POLL_DATA:
      return {
        ...state,
        id: action.payload.id,
        setIntervalId: action.payload.setIntervalId,
      };
    default:
      return state;
  }
};
