import { createAction } from 'redux-actions';
import { createRequestTypes } from 'actions/helpers';
import { apiShowFormTemplateVersion, BASE_NAME as ENTRY_BASE_NAME } from '../actions';

export const BASE_NAME = `${ENTRY_BASE_NAME}_versions_update`;

export const FORM_TEMPLATES_VERSIONS_UPDATE = {
  UNMOUNT: `${BASE_NAME}_UNMOUNT`,
  READ: createRequestTypes(`${BASE_NAME}_READ`),
};

export const onUnmount = createAction(FORM_TEMPLATES_VERSIONS_UPDATE.UNMOUNT);

export const showFormTemplateVersion = apiShowFormTemplateVersion(FORM_TEMPLATES_VERSIONS_UPDATE.READ);

export default {
  onUnmount,
  showFormTemplateVersion,
};
