import {
  RGA_SET_REPORT_DATA,
  RGA_RESET_REPORT_DATA,
  RGA_SET_DATA_FETCH_IN_PROGRESS,
} from 'actions/actionsConstants';
import initialState from './initialState';

const reportDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case RGA_SET_REPORT_DATA: {
      const reportData = action.payload.data;

      return {
        ...state,
        reportData,
        reportDataTableColumnMetadata: action.payload.column_metadata,
        reportDataFetchInProgress: false,
        reportExecutedQuery: action.payload.sql,
      };
    }
    case RGA_RESET_REPORT_DATA: {
      return {
        ...state,
        reportData: initialState.reportData,
        reportDataTableColumnMetadata:
          initialState.reportDataTableColumnMetadata,
        reportDataFetchInProgress: false,
      };
    }
    case RGA_SET_DATA_FETCH_IN_PROGRESS: {
      return {
        ...state,
        reportDataFetchInProgress: action.payload,
      };
    }
    default:
      return state;
  }
};

export default { reportDataReducer };
