import React from 'react';
import {
  string, shape, func,
} from 'prop-types';
import FieldConfiguration from 'containers/investor_profile/field_configuration/FieldConfiguration';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@icapitalnetwork/supernova-core';

const options = [
  { text: 'Yes', value: true },
  { text: 'No', value: false },
];

const YesNoQuestion = ({
  information, saveInformation, informationKey, questionText,
}) => {
  const onChange = ({ target: { value } }) => {
    const booleanValue = value === 'true';
    saveInformation({ [informationKey]: booleanValue });
  };
  const radioValue = typeof information?.[informationKey] === 'boolean' ? information[informationKey] : '';
  return (
    <>
      {!!questionText && (
      <Typography variant="h5">
        {questionText}
      </Typography>
      )}
      <FieldConfiguration
        fieldName={informationKey}
      >
        {(config) => (
          <FormControl>
            <RadioGroup
              row
              aria-labelledby={informationKey}
              onChange={onChange}
              value={radioValue}
            >
              {options.map(({ text, value: optionValue }) => (
                <FormControlLabel
                  key={text}
                  value={optionValue}
                  control={<Radio />}
                  label={text}
                  disabled={config.readOnly}
                />
              ))}
            </RadioGroup>
          </FormControl>
        )}
      </FieldConfiguration>
    </>
  );
};

YesNoQuestion.propTypes = {
  saveInformation: func.isRequired,
  information: shape({}).isRequired,
  informationKey: string.isRequired,
  questionText: string,
};

YesNoQuestion.defaultProps = {
  questionText: '',
};

export default YesNoQuestion;
