import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import * as icnBootstrapSelectors from 'selectors/icnBootstrapSelectors';
import styles from './LayoutContainer.module.scss';

const cx = classNames.bind(styles);

export const HEADER_SIZE = 75;
export const IMPERSONATE_HEADER_SIZE = 50;

/**
 * Calculate container style height without header and impersonate bar
 * @param {bool} isImposter is impersonating
 */
const getLayoutHeight = (isImposter, isMinimum) => {
  let sum = HEADER_SIZE;
  if (isImposter) sum += IMPERSONATE_HEADER_SIZE;
  const heightKey = isMinimum ? 'minHeight' : 'height';

  return { [heightKey]: `calc(100% - ${sum}px)` };
};

export const LayoutContainer = ({
  isImposter,
  children,
  fullHeight,
  isMinHeight,
  fullWidth,
  className,
}) => {
  const style = useMemo(() => (
    fullHeight ? getLayoutHeight(isImposter, isMinHeight) : {}
  ), [isImposter, fullHeight, isMinHeight]);

  const containerClass = useMemo(() => cx('LayoutContainer', {
    [styles['LayoutContainer--fullHeight']]: fullHeight,
    [styles['LayoutContainer--fullWidth']]: fullWidth,
    [className]: className,
  }), [fullHeight, fullWidth, className]);

  return (
    <div
      className={containerClass}
      style={style}
      role="main"
      id="main_content"
    >
      {children}
    </div>
  );
};

LayoutContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  isImposter: PropTypes.bool,
  fullHeight: PropTypes.bool,
  isMinHeight: PropTypes.bool,
  fullWidth: PropTypes.bool,
  className: PropTypes.string,
};

LayoutContainer.defaultProps = {
  children: null,
  isImposter: false,
  fullHeight: false,
  isMinHeight: false,
  fullWidth: false,
  className: null,
};

function mapStateToProps(state) {
  const isImposter = icnBootstrapSelectors.isImposter(state);
  return {
    isImposter,
  };
}

export default connect(mapStateToProps, null)(LayoutContainer);
