import { RGA_SET_BASE_QUERY_MODELS } from 'actions/actionsConstants';
import update from 'immutability-helper';
import initialState from '../report_generator_reducers/initialState';

export default (state = initialState, action) => {
  switch (action.type) {
    case RGA_SET_BASE_QUERY_MODELS:
      return update(state, {
        report_generator_base_queries: {
          $set: action.payload.report_generator_base_queries,
        },
      });
    default:
      return state;
  }
};
