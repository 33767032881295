// Education Dashboard -- Shared
export const ED_UNMOUNT = 'ED_UNMOUNT';
export const ED_SET_IS_RENDERING_TABLE = 'ED_SET_IS_RENDERING_TABLE';
// Education Settings - Courses
export const ED_SET_COURSES_CURRENT_PAGE = 'ED_SET_COURSES_CURRENT_PAGE';
export const ED_SET_COURSES_DATA = 'ED_SET_COURSES_DATA';
export const ED_SET_COURSES_IS_LOADING_FIRST_PAGE = 'ED_SET_COURSES_IS_LOADING_FIRST_PAGE';
export const ED_SET_COURSES_REQUEST_MADE = 'ED_SET_COURSES_REQUEST_MADE';
export const ED_SET_COURSES_PAGE_ACTIONS = 'ED_SET_COURSES_PAGE_ACTIONS';
export const ED_SET_COURSES_SEARCH_TERMS = 'ED_SET_COURSES_SEARCH_TERMS';
export const ED_SET_COURSES_SORT = 'ED_SET_COURSES_SORT';
export const ED_SET_COURSES_SUGGESTIONS_DATA = 'ED_SET_COURSES_SUGGESTIONS_DATA';
export const ED_SET_COURSE = 'ED_SET_COURSE';
export const ED_REMOVE_COURSE = 'ED_REMOVE_COURSE';

// Education Settings - Custom courses listing
export const ED_SET_CUSTOM_COURSES_CURRENT_PAGE = 'ED_SET_CUSTOM_COURSES_CURRENT_PAGE';
export const ED_SET_CUSTOM_COURSES_DATA = 'ED_SET_CUSTOM_COURSES_DATA';
export const ED_SET_CUSTOM_COURSES_IS_LOADING_FIRST_PAGE = 'ED_SET_CUSTOM_COURSES_IS_LOADING_FIRST_PAGE';
export const ED_SET_CUSTOM_COURSES_REQUEST_MADE = 'ED_SET_CUSTOM_COURSES_REQUEST_MADE';
export const ED_SET_CUSTOM_COURSES_SORT = 'ED_SET_CUSTOM_COURSES_SORT';
export const ED_SET_CUSTOM_COURSE = 'ED_SET_CUSTOM_COURSE';
export const ED_REMOVE_CUSTOM_COURSE = 'ED_REMOVE_CUSTOM_COURSE';

// Education Settings - Course Form
export const ED_SET_COURSE_FORM_DATA = 'ED_SET_COURSE_FORM_DATA';
export const ED_SET_WLP_SETTINGS_FORM_DATA = 'ED_SET_WLP_SETTINGS_FORM_DATA';
export const ED_SET_FIRMS_FILTER_VALUES = 'ED_SET_FIRMS_FILTER_VALUES';
// Education Dashboard - Investment Product
export const ED_SET_INV_PROD_CURRENT_PAGE = 'ED_SET_INV_PROD_CURRENT_PAGE';
export const ED_SET_INV_PROD_DATA = 'ED_SET_INV_PROD_DATA';
export const ED_SET_INV_PROD_PAGE_ACTIONS = 'ED_SET_INV_PROD_PAGE_ACTIONS';
export const ED_SET_INV_PROD_IS_LOADING_FIRST_PAGE = 'ED_SET_INV_PROD_IS_LOADING_FIRST_PAGE';
export const ED_SET_INV_PROD_SEARCH_TERMS = 'ED_SET_INV_PROD_SEARCH_TERMS';
export const ED_SET_INV_PROD_SORT = 'ED_SET_INV_PROD_SORT';
export const ED_SET_INV_PROD_REQUEST_MADE = 'ED_SET_INV_PROD_REQUEST_MADE';
// Education Dashboard - Foundational
export const ED_SET_FOUNDATIONAL_CURRENT_PAGE = 'ED_SET_FOUNDATIONAL_CURRENT_PAGE';
export const ED_SET_FOUNDATIONAL_DATA = 'ED_SET_FOUNDATIONAL_DATA';
export const ED_SET_FOUNDATIONAL_IS_LOADING_FIRST_PAGE = 'ED_SET_FOUNDATIONAL_IS_LOADING_FIRST_PAGE';
export const ED_SET_FOUNDATIONAL_REQUEST_MADE = 'ED_SET_FOUNDATIONAL_REQUEST_MADE';
export const ED_SET_FOUNDATIONAL_PAGE_ACTIONS = 'ED_SET_FOUNDATIONAL_PAGE_ACTIONS';
export const ED_SET_FOUNDATIONAL_SEARCH_TERMS = 'ED_SET_FOUNDATIONAL_SEARCH_TERMS';
export const ED_SET_FOUNDATIONAL_SORT = 'ED_SET_FOUNDATIONAL_SORT';
// Education Log
export const ED_SET_LOG_DATA = 'ED_SET_LOG_DATA';
export const ED_SET_LOG_CURRENT_PAGE = 'ED_SET_LOG_CURRENT_PAGE';
export const ED_SET_LOG_IS_LOADING_FIRST_PAGE = 'ED_SET_LOG_IS_LOADING_FIRST_PAGE';
export const ED_SET_LOG_REQUEST_MADE = 'ED_SET_LOG_REQUEST_MADE';
export const ED_SET_LOG_SORT = 'ED_SET_LOG_SORT';
export const ED_SET_LOG_SEARCH_TERMS = 'ED_SET_LOG_SEARCH_TERMS';
export const ED_SET_LOG_PAGE_ACTIONS = 'ED_SET_LOG_PAGE_ACTIONS';
export const ED_SET_LOG_SELECTED_IDS = 'ED_SET_LOG_SELECTED_IDS';
// Education Product Summary
export const ED_SET_FUND_SUMMARY_DATA = 'ED_SET_FUND_SUMMARY_DATA';
export const ED_SET_FUND_SUMMARY_INVALID_DATA = 'ED_SET_FUND_SUMMARY_INVALID_DATA';
export const ED_SET_ACTIVITY_DATA = 'ED_SET_ACTIVITY_DATA';
export const ED_SET_ACTIVITY_INVALID_DATA = 'ED_SET_ACTIVITY_INVALID_DATA';
// Education Settings
export const ED_SET_SETTINGS_SEARCH_TERMS = 'ED_SET_SETTINGS_SEARCH_TERMS';
export const ED_SET_SETTINGS_SEARCH_DATA = 'ED_SET_SETTINGS_SEARCH_DATA';

// Education questions
export const EDUCATION_QUESTIONS_SET_CURRENT_PAGE = 'EDUCATION_QUESTIONS_SET_CURRENT_PAGE';
export const EDUCATION_QUESTIONS_SET_RESULTS_PER_PAGE = 'EDUCATION_QUESTIONS_SET_RESULTS_PER_PAGE';
export const EDUCATION_QUESTIONS_SET_SORT = 'EDUCATION_QUESTIONS_SET_SORT';
export const EDUCATION_QUESTIONS_SET_APPLIED_FILTERS = 'EDUCATION_QUESTIONS_SET_APPLIED_FILTERS';
