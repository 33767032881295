export default function labelFormatter(params) {
  if (params.value == null) { return ''; }
  return params.value.replace(/_/g, ' ');
}

export const labelFormatterCapitalize = (params) => labelFormatter(params)
  .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());

export const labelWithDefaultValue = ({ value }, defaultValue = '—') => {
  if (!value) return defaultValue;

  return value;
};
