import { beginApiRequest, endApiRequest } from 'actions/spinner_actions/spinnerActions';
import genericApiRequest from 'services/apiRequest';

const buildPayload = ({ response, requestParams }) => (
  requestParams ? { response, request: requestParams } : { response }
);

/**
 * Wrapper for "apiRequest" helper that calls "beginApiRequest" before and "endApiRequest" after
 * the request is called. It requires a "dispatch" function that comes from "redux" or "useReducer"
 * @typedef {Object} RequestTypes
 * @property {string} REQUEST
 * @property {string} SUCCESS
 * @property {string} FAILURE
 * @typedef {Object} ActionApiRequestOptions
 *
 * @property {boolean} [showSpinner] whether or not we want to show the global spinner
 * @property {function} [onSuccess] action callback for successful request (can be a thunk)
 * @property {function} [onFailure] action callback for failed request (can be a thunk)
 * @property {RequestTypes} [actions] actions that will be called before and after the request
 * @property {Object} [requestParams] params that will be sent in the actions after the request finished
 *
 * @typedef {import("services/apiRequest").GenericApiRequestConfig} GenericApiRequestConfig
 * @typedef {ActionApiRequestOptions & GenericApiRequestConfig} GenericActionsApiRequestConfig
 *
 * @param {GenericActionsApiRequestConfig} config
 */
export const apiRequest = ({
  showSpinner = true,
  onSuccess = null,
  onFailure = null,
  actions = null,
  requestParams = null,
  ...rest
}) => (dispatch) => {
  if (showSpinner) {
    dispatch(beginApiRequest());
  }
  if (actions && actions.REQUEST) {
    dispatch({
      type: actions.REQUEST,
      ...(requestParams && { payload: { request: requestParams } }),
    });
  }

  return genericApiRequest({
    ...rest,
    onSuccess: (data) => {
      if (onSuccess) dispatch(onSuccess(data));

      if (actions?.SUCCESS) {
        const payload = buildPayload({ response: data, requestParams });
        dispatch({ type: actions.SUCCESS, payload });
      }
    },
    onFailure: (err) => {
      if (onFailure) dispatch(onFailure(err));

      if (actions?.FAILURE) {
        const payload = buildPayload({ response: err.response, requestParams });
        dispatch({ type: actions.FAILURE, payload });
      }
    },
  }).finally(() => {
    if (showSpinner) {
      dispatch(endApiRequest());
    }
  });
};

// Aliases for different request methods
/** @param {GenericActionsApiRequestConfig} config */
export const getRequest = (config) => apiRequest({ ...config, method: 'get' });

/** @param {GenericActionsApiRequestConfig} config */
export const postRequest = (config) => apiRequest({ ...config, method: 'post' });

/** @param {GenericActionsApiRequestConfig} config */
export const putRequest = (config) => apiRequest({ ...config, method: 'put' });

/** @param {GenericActionsApiRequestConfig} config */
export const patchRequest = (config) => apiRequest({ ...config, method: 'patch' });

/** @param {GenericActionsApiRequestConfig} config */
export const deleteRequest = (config) => apiRequest({ ...config, method: 'delete' });
