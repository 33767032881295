import React from 'react';
import {
  bool, shape, string, func,
} from 'prop-types';
import FieldConfiguration from 'containers/investor_profile/field_configuration/FieldConfiguration';
import FieldWrap from 'components/shared/icn_form/field_wrap/FieldWrap';
import { useTheme } from '@mui/material';
import {
  Box,
  FormControl,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
} from '@icapitalnetwork/supernova-core';
import {
  otherTypeInstitutionalInvestor, totalAssets, isOtherTypeInstitution,
} from '../profileData';
import TotalAssets from './TotalAssets';

const OtherTypeInstitutionalInvestor = ({
  isRegistrationLoading, profileData, updateStore,
}) => {
  const options = otherTypeInstitutionalInvestor.institution_options;

  const theme = useTheme();

  const selectTypeInstitutionalInvestor = ({ target: { value } }) => {
    updateStore({ [otherTypeInstitutionalInvestor.question_key]: value });
  };

  return (
    profileData[isOtherTypeInstitution.question_key] ? (
      <>
        <FieldWrap size="full_fluid">
          <FieldConfiguration
            fieldName={otherTypeInstitutionalInvestor.question_key}
          >
            {(config) => (
              isRegistrationLoading ? <Skeleton width={theme.spacing(4)} /> : (
                <FormControl disabled={config.readOnly} fullWidth size="medium">
                  <InputLabel id="institution-type-select">Type</InputLabel>
                  <Select
                    label="Type"
                    value={profileData[otherTypeInstitutionalInvestor.question_key] || ''}
                    onChange={selectTypeInstitutionalInvestor}
                  >
                    {options.map(({ text, value }) => (
                      <MenuItem key={value} value={value}>
                        {text}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )
            )}
          </FieldConfiguration>
        </FieldWrap>

        {profileData[otherTypeInstitutionalInvestor.question_key] === 'any_other_entity' && (
          <FieldWrap>
            <FieldConfiguration
              fieldName={totalAssets.question_key}
            >
              {(config) => (
                isRegistrationLoading ? <Skeleton height={theme.spacing(8)} /> : (
                  <Box display="flex" flexDirection="column">
                    <FormLabel id="firm-assets" focused={false}>{totalAssets.text}</FormLabel>
                    <TotalAssets
                      config={config}
                      profileData={profileData}
                      updateStore={updateStore}
                    />
                  </Box>
                ))}
            </FieldConfiguration>
          </FieldWrap>
        )}
      </>
    ) : null
  );
};

OtherTypeInstitutionalInvestor.propTypes = {
  isRegistrationLoading: bool,
  profileData: shape({
    registered_with_sec: bool,
    firm_name: string,
    crd_number: string,
    advisor_crd_number: string,
    firm_confirm_us_location: bool,
    country: string,
    state: string,
    firm_has_qp_clients: bool,
    firm_assets: string,
    ria_record_id: string,
  }).isRequired,
  updateStore: func.isRequired,
};

OtherTypeInstitutionalInvestor.defaultProps = {
  isRegistrationLoading: false,
};

export default OtherTypeInstitutionalInvestor;
