const pageActions = (filtersData) => ({
  filters: filtersData,
  searchTerms: '',
  sort: [],
  education_only: false,
});

const commonData = (filter) => ({
  data: [],
  details: [],
  filterOptions: {},
  filterValues: {},
  sortOptions: {},
  infoMessage: 'loading',
  isRenderingTable: true,
  filterSelectedValues: {},
  isLoadingFirstPage: false,
  requestMade: false,
  currentPage: null,
  resultsPerPage: null,
  totalCount: null,
  hasNextPage: false,
  pageActions: pageActions(filter),
});

const courseFilters = {
  categories: [],
  customizations: [],
  statuses: [],
  roles: [],
  firms: [],
  wlps: [],
};

const courseForm = {
  data: {
    id: null,
    name: null,
    course_id: null,
    threshold: null,
    failing_option: null,
    timing: null,
    timing_option: null,
    ai_insight_create_course_questions_link: null,
    roles: [],
    firm_ids: [],
    wlp_id: null,
    category: null,
  },
  filterValues: {
    firms: [],
    roles: [],
    wlps: [],
    current_wlp_id: null,
  },
};

const wlpSettingsForm = {
  data: {
    foundational_courses_threshold: 0,
    investment_courses_threshold: 0,
    default_option: null,
    default_question: null,
    roles: [],
  },
};

const invProdFilters = {
  category: [],
  strategy: [],
  fundManager: [],
  courseStatus: null,
};

const foundationalFilters = {
  topics: [],
  vendor: null,
  ceCredits: null,
  courseStatus: null,
  requirement: null,
};

const logFilters = {
  vendor: [],
  courseType: null,
  category: [],
  activityType: [],
  strategy: [],
  fundManager: [],
  topics: [],
  adminView: true,
  dateRange: '',
  ids: [],
};

const productSummary = {
  isLoadingFirstPage: true,
  data: {
    course: {
      id: null,
      course_type: null,
      name: null,
      ai_insight_fund_id: null,
      link: null,
      valid: null,
      user_course_status: null,
      course_test_link: null,
    },
    activity: {
      activity_type: 'product_summary',
      status: 'unknown',
      result: 'unknown',
      raw_log: {
        cmi: {
          location: null,
          entry: 'ab_initio',
          mode: 'normal',
          progress_measure: 0,
          scaled_passing_score: 1,
          completion_status: 'unknown',
          completion_threshold: 1,
          success_status: 'unknown',
          learner_id: null,
          learner_name: null,
          session_time: null,
          suspend_data: '',
        },
      },
    },
  },
};

const questionData = {
  filters: {
    question_types: [],
    search_terms: '',
    requirement: [],
  },
  sort: {},
};

export default {
  courseData: { ...commonData(courseFilters), suggestions: [] },
  customCoursesData: commonData(undefined),
  courseFormData: courseForm,
  wlpSettingsFormData: wlpSettingsForm,
  investmentProductData: commonData(invProdFilters),
  foundationalData: commonData(foundationalFilters),
  logData: commonData(logFilters),
  productSummaryData: productSummary,
  questionData,
};
