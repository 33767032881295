import update from 'immutability-helper';
import _ from 'lodash';
import {
  RGA_CHANGE_DATE_FILTER_RANGE,
  RGA_SAVE_FILTER_DATE,
} from 'actions/actionsConstants';

import initialState from 'reducers/report_generator_reducers/initialState';
import {
  retrieveDBKey,
  getDateFilterLabel,
} from 'reducers/report_generator_reducers/utils';
import { DEFAULT_RELATIVE_RANGE_DURATION } from 'reducers/report_generator_reducers/constants';

export default (state = initialState, action) => {
  switch (action.type) {
    case RGA_CHANGE_DATE_FILTER_RANGE: {
      const {
        filter,
        from,
        to,
        relative_date = false,
        fromDuration,
        toDuration,
        fromSign = 'minus',
        toSign = 'minus',
        disableSave = false,
        toMonthSpan = null,
        fromMonthSpan = null,
      } = action.payload;

      return {
        ...state,
        options: update(state.options, {
          [state.configurableId]: {
            [filter]: {
              $set: {
                from,
                to,
                relative_date,
                fromDuration: fromDuration || DEFAULT_RELATIVE_RANGE_DURATION,
                toDuration: toDuration || DEFAULT_RELATIVE_RANGE_DURATION,
                fromSign,
                toSign,
                toMonthSpan,
                fromMonthSpan,
              },
            },
          },
        }),
        disableSave: update(state.disableSave, {
          [state.configurableId]: { [filter]: { $set: disableSave } },
        }),
      };
    }

    case RGA_SAVE_FILTER_DATE: {
      const { filter } = action.payload;
      const configurableId = state.configurableId;
      const {
        from,
        to,
        relative_date,
        fromDuration: from_duration,
        toDuration: to_duration,
        fromSign: from_sign,
        toSign: to_sign,
        toMonthSpan: to_month_span,
        fromMonthSpan: from_month_span,
      } = state.options[configurableId][filter];
      let saveObject = {};
      if (to !== undefined) {
        saveObject = {
          data_type: 'DATE',
          range: {
            from: from || (relative_date ? from : new Date()),
            to,
            relative_date,
            from_duration,
            to_duration,
            from_sign,
            to_sign,
            from_month_span,
            to_month_span,
          },
        };
      }

      const dbMethod = retrieveDBKey(
        filter,
        state.available_fields[configurableId],
      );

      let allFilterLabels = {};
      if (saveObject?.range?.from && saveObject?.range?.to) {
        let label = getDateFilterLabel(
          saveObject.range.from,
          saveObject.range.to,
          relative_date,
          from_duration,
          to_duration,
          from_sign,
          to_sign,
          from_month_span,
          to_month_span,
        );
        allFilterLabels = update(state.totals, {
          [configurableId]: { [filter]: { $set: label } },
        });
      } else {
        allFilterLabels = update(state.totals, {
          [configurableId]: {
            $set: _.omit(state.totals[configurableId], filter),
          },
        });
      }

      return {
        ...state,
        initialOptions: update(state.initialOptions, {
          [configurableId]: {
            [filter]: { $set: { ...state.options[configurableId][filter] } },
          },
        }),
        stored_queries: update(state.stored_queries, {
          [configurableId]: {
            [filter]: { $set: { ...saveObject, ...dbMethod } },
          },
        }),
        totals: update(state.totals, { $set: allFilterLabels }),
      };
    }

    default:
      return state;
  }
};
