// Landing Page
export const LP_SET_LANDING_PAGE = 'LP_SET_LANDING_PAGE';
export const LP_SET_HERO_CONFIGURATION = 'LP_SET_HERO_CONFIGURATION';
export const LP_SET_ADVISOR_MARKETING_FIELDS = 'LP_SET_ADVISOR_MARKETING_FIELDS';
export const LP_ADD_MORE_ANNOUNCEMENTS = 'LP_ADD_MORE_ANNOUNCEMENTS';
export const LP_SET_INVESTMENT_OPPORTUNITY_COLLECTIONS = 'LP_SET_INVESTMENT_OPPORTUNITY_COLLECTIONS';
export const LP_SET_ANNOUNCEMENTS = 'LP_SET_ANNOUNCEMENTS';
export const LP_SET_ANNOUNCEMENTS_FILTER = 'LP_SET_ANNOUNCEMENTS_FILTER';
export const LP_SET_ANNOUNCEMENTS_LOADING = 'LP_SET_ANNOUNCEMENTS_LOADING';
export const LP_SET_IS_LOADING_INSIGHTS_AND_RESEARCH = 'LP_SET_IS_LOADING_INSIGHTS_AND_RESEARCH';
export const LP_SET_ANNOUNCEMENT = 'LP_SET_ANNOUNCEMENT';
export const LP_SET_ANNOUNCEMENT_FUNDS = 'LP_SET_ANNOUNCEMENT_FUNDS';
export const LP_SET_ANNOUNCEMENT_EDIT = 'LP_SET_ANNOUNCEMENT_EDIT';
export const LP_SET_ANNOUNCEMENT_NEW = 'LP_SET_ANNOUNCEMENT_NEW';
export const LP_SET_ANNOUNCEMENT_EDIT_CHECK_FOR_ERRORS = 'LP_SET_ANNOUNCEMENT_EDIT_CHECK_FOR_ERRORS';
export const LP_SET_ANNOUNCEMENT_EDIT_DIALOG_IS_OPEN = 'LP_SET_ANNOUNCEMENT_EDIT_DIALOG_IS_OPEN';
export const LP_SET_ANNOUNCEMENT_EDIT_UPDATED_ANNOUCEMENT = 'LP_SET_ANNOUNCEMENT_EDIT_UPDATED_ANNOUCEMENT';
export const LP_SET_ANNOUNCEMENT_EDIT_MISSING_CATEGORIES_DATA = 'LP_SET_ANNOUNCEMENT_EDIT_MISSING_CATEGORIES_DATA';
export const LP_SET_ANNOUNCEMENT_EDIT_ID = 'LP_SET_ANNOUNCEMENT_EDIT_ID';
export const LP_SET_ANNOUNCEMENT_EDIT_MODE = 'LP_SET_ANNOUNCEMENT_EDIT_MODE';
export const LP_RESET = 'LP_RESET';
export const LP_SET_INSIGHT = 'LP_SET_INSIGHT';
export const LP_SET_INSIGHT_EDIT = 'LP_SET_INSIGHT_EDIT';
export const LP_SET_INSIGHT_UPDATE = 'LP_SET_INSIGHT_UPDATE';
export const LP_SET_INSIGHT_EDIT_DIALOG_IS_OPEN = 'LP_SET_INSIGHT_EDIT_DIALOG_IS_OPEN';
export const LP_SET_INSIGHT_EDIT_UPDATED = 'LP_SET_INSIGHT_EDIT_UPDATED';
export const LP_SET_INSIGHT_EDIT_CHECK_FOR_ERRORS = 'LP_SET_INSIGHT_EDIT_CHECK_FOR_ERRORS';
export const LP_SET_INSIGHT_EDIT_ID = 'LP_SET_INSIGHT_EDIT_ID';
export const LP_SET_INSIGHT_EDIT_MODE = 'LP_SET_INSIGHT_EDIT_MODE';
export const LP_SET_INSIGHT_EDIT_NEW = 'LP_SET_INSIGHT_EDIT_NEW';
export const LP_ADD_MORE_INSIGHTS = 'LP_ADD_MORE_INSIGHTS';
export const LP_SET_INSIGHTS = 'LP_SET_INSIGHTS';
export const LP_SET_INSIGHTS_FILTER = 'LP_SET_INSIGHTS_FILTER';
export const LP_SET_INSIGHTS_CATEGORIES_SELECTED = 'LP_SET_INSIGHTS_CATEGORIES_SELECTED';
export const LP_SET_INSIGHTS_LOADING = 'LP_SET_INSIGHTS_LOADING';
export const LP_SET_ANNOUNCEMENT_TRANSLATION_DIALOG_IS_OPEN = 'LP_SET_ANNOUNCEMENT_TRANSLATION_DIALOG_IS_OPEN';
export const LP_SET_ANNOUNCEMENT_TRANSLATION_MODE = 'LP_SET_ANNOUNCEMENT_TRANSLATION_MODE';
export const LP_SET_ANNOUNCEMENT_TRANSLATION = 'LP_SET_ANNOUNCEMENT_TRANSLATION';
export const LP_SET_ANNOUNCEMENT_LIST_TRANSLATION = 'LP_SET_ANNOUNCEMENT_LIST_TRANSLATION';
export const LP_SET_ANNOUNCEMENT_ORIGINAL_TRANSLATION = 'LP_SET_ANNOUNCEMENT_ORIGINAL_TRANSLATION';
export const LP_SET_ANNOUNCEMENT_TRANSLATE = 'LP_SET_ANNOUNCEMENT_TRANSLATE';
