import _ from 'lodash';
import { getPrivateAccessFundsNameList } from 'services/funds/private_access_funds';
import {
  getIntegrationPartnerBaseTemplates,
  createIntegrationPartnerTemplate,
  getIntegrationPartnerBaseTemplate,
  getIntegrationPartnerTemplates,
  getIntegrationPartnerTemplate,
  updateIntegrationPartnerTemplate,
  deleteIntegrationPartnerTemplate,
  executeIntegrationPartnerTemplate,
} from 'services/flexible_feeds_dashboard/templates';
import onItemsSelection from 'services/DropDownItemSelectionService';
import { getAllSelectedOptions } from 'services/integration_partner_templates';
import { actionFactory } from 'services/actions';
import { getIntegrationPartners } from 'services/integration_partners';
import { beginApiRequest, endApiRequest } from '../spinner_actions/spinnerActions';
import {
  INTEGRATION_PARTNER_TEMPLATES_SET_INT_PARTNERS,
  INTEGRATION_PARTNER_TEMPLATES_SET_FUNDS,
  INTEGRATION_PARTNER_TEMPLATES_SET_BASE_TEMPLATES,
  INTEGRATION_PARTNER_TEMPLATES_SET_BASE_TEMPLATE,
  INTEGRATION_PARTNER_TEMPLATES_SET_TEMPLATES,
  INTEGRATION_PARTNER_TEMPLATES_SET_TEMPLATE,
  INTEGRATION_PARTNER_TEMPLATES_UPDATE_PAGINATION,
  INTEGRATION_PARTNER_TEMPLATES_UPDATE_FILTER,
} from '../actionsConstants';

const integrationPartnerTemplatesIntPartners = actionFactory(INTEGRATION_PARTNER_TEMPLATES_SET_INT_PARTNERS);
const integrationPartnerTemplatesFunds = actionFactory(INTEGRATION_PARTNER_TEMPLATES_SET_FUNDS);
const integrationPartnerTemplatesBaseTemplates = actionFactory(INTEGRATION_PARTNER_TEMPLATES_SET_BASE_TEMPLATES);
const integrationPartnerTemplatesBaseTemplate = actionFactory(INTEGRATION_PARTNER_TEMPLATES_SET_BASE_TEMPLATE);
const integrationPartnerTemplatesTemplates = actionFactory(INTEGRATION_PARTNER_TEMPLATES_SET_TEMPLATES);
const integrationPartnerTemplatesTemplate = actionFactory(INTEGRATION_PARTNER_TEMPLATES_SET_TEMPLATE);
const integrationPartnerTemplatesUpdatePaginationSort = actionFactory(INTEGRATION_PARTNER_TEMPLATES_UPDATE_PAGINATION);
const integrationPartnerTemplatesUpdateFilters = actionFactory(INTEGRATION_PARTNER_TEMPLATES_UPDATE_FILTER);

function loadIntegrationPartnersList() {
  return (dispatch) => {
    dispatch(beginApiRequest());
    getIntegrationPartners().then((response) => {
      dispatch(
        integrationPartnerTemplatesIntPartners(
          _.map(response.integration_partners, (item) => ({ text: item.name, value: item.id }))
        )
      );
      dispatch(endApiRequest());
    });
  };
}

function loadFundsList() {
  return (dispatch) => {
    dispatch(beginApiRequest());
    getPrivateAccessFundsNameList().then((response) => {
      const items = _.sortBy(_.map(response, (item) => {
        const paf = item.private_access_fund;
        return {
          name: paf.name,
          id: paf.id,
        };
      }), ['text']);
      dispatch(integrationPartnerTemplatesFunds(items));
      dispatch(endApiRequest());
    });
  };
}

function integrationPartnerTemplatesLoadBaseTemplates() {
  return (dispatch) => {
    dispatch(beginApiRequest());
    getIntegrationPartnerBaseTemplates().then((response) => {
      dispatch(
        integrationPartnerTemplatesBaseTemplates(
          _.map(response.integration_partner_base_templates, (item) => ({ text: item.name, value: item.name }))
        )
      );
      dispatch(endApiRequest());
    });
  };
}

// To be discontinued once new Dashboard stepper is implemented
function integrationPartnerTemplatesLoadFormOptions() {
  return (dispatch) => {
    dispatch(loadIntegrationPartnersList());
    dispatch(integrationPartnerTemplatesLoadBaseTemplates());
    dispatch(loadFundsList());
  };
}

function integrationPartnerTemplatesLoadIntegrationPartners() {
  return (dispatch) => {
    dispatch(loadIntegrationPartnersList());
  };
}

const formatError = (error) => {
  const formattedError = { ...error };
  formattedError.message = error.message;
  return formattedError;
};

function integrationPartnerTemplatesLoadTemplates() {
  return (dispatch, getState) => {
    dispatch(beginApiRequest());
    const state = getState();
    const integrationPartnerTemplates = state.integrationPartnerTemplates;

    const updatedMeta = { ...integrationPartnerTemplates.pagination };
    updatedMeta.filter_options = getAllSelectedOptions(state);

    getIntegrationPartnerTemplates(updatedMeta).then((response) => {
      dispatch(integrationPartnerTemplatesTemplates(response));
      dispatch(endApiRequest());
    }).catch(() => dispatch(endApiRequest()));
  };
}

function integrationPartnerTemplatesLoadTemplate(templateId) {
  return (dispatch) => {
    if(!templateId){
      dispatch(integrationPartnerTemplatesTemplate(undefined));
    }else{
      dispatch(beginApiRequest());
      getIntegrationPartnerTemplate(templateId).then((response) => {
        dispatch(integrationPartnerTemplatesTemplate(response));
      }).finally(() => dispatch(endApiRequest()));
    }
  };
}

function integrationPartnerTemplatesSetPaginationSort(pagination) {
  return (dispatch) => {
    dispatch(integrationPartnerTemplatesUpdatePaginationSort(pagination));
    dispatch(integrationPartnerTemplatesLoadTemplates());
  };
}

function integrationPartnerTemplatesCreateTemplate(template, onSuccess, onFail) {
  return (dispatch) => {
    dispatch(beginApiRequest());
    createIntegrationPartnerTemplate(template).then(() => {
      const pagination = {
        sort_field: 'integration_partner_templates.name',
        sort_direction: 'desc',
        page: 1,
      };

      dispatch(integrationPartnerTemplatesSetPaginationSort(pagination));
      dispatch(endApiRequest());
      onSuccess();
    }).catch((error) => {
      dispatch(endApiRequest());
      onFail(formatError(error.data));
    });
  };
}

function integrationPartnerTemplatesLoadBaseTemplate(baseTemplateName) {
  return (dispatch) => {
    dispatch(beginApiRequest());
    getIntegrationPartnerBaseTemplate(baseTemplateName).then((response) => {
      dispatch(integrationPartnerTemplatesBaseTemplate(response));
      dispatch(endApiRequest());
    }).catch(() => {
      dispatch(endApiRequest());
    });
  };
}

function integrationPartnerTemplatesUnloadBaseTemplate() {
  return (dispatch) => {
    dispatch(integrationPartnerTemplatesBaseTemplate(null));
  };
}

function integrationPartnerTemplatesUpdateTemplate(template, onSuccess, onFail) {
  return (dispatch) => {
    dispatch(beginApiRequest());
    updateIntegrationPartnerTemplate(template).then(() => {
      dispatch(integrationPartnerTemplatesLoadTemplates());
      dispatch(endApiRequest());
      onSuccess();
    }).catch((error) => {
      dispatch(endApiRequest());
      onFail(formatError(error.data));
    });
  };
}

function integrationPartnerTemplatesDeleteTemplate(templateId) {
  return (dispatch) => {
    dispatch(beginApiRequest());
    deleteIntegrationPartnerTemplate(templateId).then(() => {
      dispatch(integrationPartnerTemplatesLoadTemplates());
      dispatch(endApiRequest());
    }).catch(() => {
      dispatch(endApiRequest());
    });
  };
}

function integrationPartnerTemplatesExecuteTemplate(templateId, onSuccess) {
  return (dispatch) => {
    dispatch(beginApiRequest());
    executeIntegrationPartnerTemplate(templateId).then(() => {
      dispatch(integrationPartnerTemplatesLoadTemplates());
      dispatch(endApiRequest());
      onSuccess();
    }).catch(() => {
      dispatch(endApiRequest());
    });
  };
}

function integrationPartnerTemplatesSetSelectedFilters(itemIds, reduxKey, itemCollection) {
  return (dispatch) => {
    const items = onItemsSelection(itemIds, itemCollection);
    dispatch(integrationPartnerTemplatesUpdateFilters({ [reduxKey]: items }));
    dispatch(integrationPartnerTemplatesLoadTemplates());
  };
}

export default {
  integrationPartnerTemplatesLoadTemplates,
  integrationPartnerTemplatesLoadTemplate,
  integrationPartnerTemplatesLoadBaseTemplates,
  integrationPartnerTemplatesSetPaginationSort,
  integrationPartnerTemplatesLoadFormOptions,
  integrationPartnerTemplatesLoadIntegrationPartners,
  integrationPartnerTemplatesCreateTemplate,
  integrationPartnerTemplatesLoadBaseTemplate,
  integrationPartnerTemplatesUnloadBaseTemplate,
  integrationPartnerTemplatesUpdateTemplate,
  integrationPartnerTemplatesDeleteTemplate,
  integrationPartnerTemplatesExecuteTemplate,
  integrationPartnerTemplatesSetSelectedFilters,
  loadFundsList,
};
