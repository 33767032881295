import { createSlice } from '@reduxjs/toolkit'
import { getRequest, patchRequest } from 'actions/utils';

const initialState = {
  glossaryFields: [],
}

const glossarySlice = createSlice({
  name: 'posts',
  initialState,
  reducers: {
    loadFields(state, action) {
      state.glossaryFields = action.payload.report_generator_fields
    },
    updateField(state, action) {
      const { id, description } = action.payload.report_generator_field
      const existingField = state.glossaryFields.find((field) => field.id === id)

      if (existingField) {
        existingField.description = description
      }
    },
  },
})

const getCallback = (payload) => {
  return (dispatch) => {
    dispatch(glossarySlice.actions.loadFields(payload))
  };
};

const updateCallback = (payload) => {
  return (dispatch) => {
    dispatch(glossarySlice.actions.updateField(payload))
  };
};

export function fetchFields(reportConfigId) {
  return (dispatch) => {
    return dispatch(
      getRequest({
        url: "report_generator/glossary",
        onSuccess: getCallback,
      }),
    );
  }
}

export function updateField({id, description}) {
  return (dispatch) => {
    return dispatch(
      patchRequest({
        url: `report_generator/glossary/${id}`,
        data: {
          report_generator_field: {
            description: description,
          },
        },
        onSuccess: updateCallback,
      }),
    );
  }
}

export default glossarySlice.reducer

export const selectAllPosts = (state) => state.posts.posts

export const selectPostById = (state, postId) =>
  state.posts.posts.find((post) => post.id === postId)