import { actionFactory } from 'services/actions';
import { getRequest, patchRequest, postRequest } from 'actions/utils';
import {
  RGA_LOAD_SCHEDULE,
  RGA_CHANGE_SCHEDULE_TYPES,
} from 'actions/actionsConstants';
import notificationService from 'services/notifications';

const BASE_URL = 'report_generator/schedule_reports';
const ADMIN_URL = 'report_generator/admin/manage_schedules';
const reportGeneratorConfigScheduleData = actionFactory(RGA_LOAD_SCHEDULE);

function rgaUpdateScheduleData(data) {
  return (dispatch) => {
    dispatch(reportGeneratorConfigScheduleData(data));
  };
}

function reportScheduleData(reportConfigId, superUser = false) {
  return (dispatch) =>
    dispatch(
      getRequest({
        url: `${superUser ? ADMIN_URL : BASE_URL}/${reportConfigId}`,
        onSuccess: rgaUpdateScheduleData,
      }),
    );
}

function seekApproval(config_id) {
  return (dispatch) =>
    dispatch(
      postRequest({
        url: `/${BASE_URL}/seek_approval`,
        params: { config_id },
        onSuccess: () => reportScheduleData(config_id),
        onFailure: ({ data: { message } }) =>
          notificationService.notifyError(message),
      }),
    );
}

function trackSchedulerEvent(config_id) {
  return (dispatch) =>
    dispatch(
      postRequest({
        url: `/${BASE_URL}/track_event`,
        params: { config_id, event_type: 'report_config_scheduler_viewed' },
      }),
    );
}

function updateScheduleData(payload, superUser = false) {
  const {
    daysOfMonth,
    datesOfYear,
    days,
    emailContent,
    emailRecipients,
    emailSubject,
    enabled,
    expiryDuration,
    interval,
    monthlyType,
    months,
    time,
    type,
    configId,
    sftpAccountId: sftp_account_id,
    sftpUploadPath: sftp_upload_path,
    snowflakeClientId: snowflake_client_id,
    snowflakeTableName: snowflake_table_name,
  } = payload;

  return patchRequest({
    url: `${superUser ? ADMIN_URL : BASE_URL}/${configId}`,
    data: {
      config_id: configId,
      schedule_meta_data: {
        time,
        type,
        days_of_month: daysOfMonth,
        dates_of_year: datesOfYear,
        monthly_type: monthlyType,
        months,
        week_days: days,
        interval,
      },
      enabled,
      recipients: emailRecipients,
      email_subject: emailSubject,
      email_body: emailContent,
      attachment_expiry_days: expiryDuration,
      sftp_account_id,
      sftp_upload_path,
      snowflake_client_id,
      snowflake_table_name,
    },
    onSuccess: rgaUpdateScheduleData,
    onFailure: ({ data: { message } }) =>
      notificationService.notifyError(message),
  });
}

const rgaChangeSchedulingTypes = actionFactory(RGA_CHANGE_SCHEDULE_TYPES);

export default {
  reportScheduleData,
  updateScheduleData,
  seekApproval,
  trackSchedulerEvent,
  rgaChangeSchedulingTypes,
};
