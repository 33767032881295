import axios from 'axios';
import snakeCase from 'lodash/snakeCase';

import notificationService from 'services/notifications';
import spinnerActions from 'actions/spinner_actions/spinnerActions';
import { filterCombinations, parseFilters } from 'services/subscription_workflow_status';

import {
  SWS_SET_COMBINATIONS,
  SWS_SET_TRIGGER_OPTIONS,
  SWS_DELETE_FROM_GRID,
  SWS_SET_FILTER_OPTIONS,
  SWS_SET_SELECTED_FILTERS_FOR_GROUP,
  SWS_SET_FILTERED_COMBINATIONS,
  SWS_CLEAR_SELECTED_FILTERS,
} from 'actions/actionsConstants';

import { actionFactory } from 'services/actions';

const swsSetCombinations = actionFactory(SWS_SET_COMBINATIONS);

const swsSetOfferingMaterialsTriggers = actionFactory(SWS_SET_TRIGGER_OPTIONS);

const swsDeleteFromGrid = actionFactory(SWS_DELETE_FROM_GRID);

const swsSetFilteredCombinations = actionFactory(SWS_SET_FILTERED_COMBINATIONS);

const swsSetFilterOptions = actionFactory(SWS_SET_FILTER_OPTIONS);

const swsClearSelectedFilters = actionFactory(SWS_CLEAR_SELECTED_FILTERS);

const swsUpdatetSelectedFiltersForGroup = actionFactory(SWS_SET_SELECTED_FILTERS_FOR_GROUP);

function swsFetchAllCombinations() {
  const url = 'subscription_workflow_statuses';
  return (dispatch) => {
    dispatch(spinnerActions.beginApiRequest());
    return axios
      .get(url, { withCredentials: true })
      .then((response) => {
        dispatch(swsSetCombinations(response.data));

        // extract the filtering options from the current data set
        dispatch(swsSetFilterOptions(parseFilters(response.data)));

        dispatch(swsClearSelectedFilters());
        return Promise.resolve();
      })
      .catch(() => Promise.reject())
      .finally(() => dispatch(spinnerActions.endApiRequest()));
  };
}

function swsFetchOfferingMaterialsTriggerOptions() {
  const url = 'subscription_workflow_statuses/offering_materials_triggers';
  return (dispatch) => {
    dispatch(spinnerActions.beginApiRequest());
    return axios
      .get(url, { withCredentials: true })
      .then((response) => {
        dispatch(swsSetOfferingMaterialsTriggers(response.data));
        return Promise.resolve();
      })
      .catch(() => Promise.reject())
      .finally(() => dispatch(spinnerActions.endApiRequest()));
  };
}

function swsDeleteRecord(entry) {
  const url = `subscription_workflow_statuses/${entry.id}`;
  const params = {
    white_label_partner_id: entry.white_label_partner_id,
    firm_id: entry.firm_id,
    private_access_fund_id: entry.private_access_fund_id,
  };
  return (dispatch) => {
    dispatch(spinnerActions.beginApiRequest());
    return axios
      .delete(url, { withCredentials: true, params })
      .then(() => {
        dispatch(swsDeleteFromGrid(entry.id));
        dispatch(swsFetchAllCombinations());
        notificationService.notifySuccess('', 'Row deleted successfully');
        return Promise.resolve();
      })
      .catch((error) => {
        notificationService.notifyError('', error.data.message);
        return Promise.reject();
      })
      .finally(() => dispatch(spinnerActions.endApiRequest()));
  };
}

function swsAddNewRecord(entry) {
  const url = 'subscription_workflow_statuses';
  const statuses = [];
  Object.keys(entry.statuses).forEach((key) => {
    if (entry.statuses[key]) {
      statuses.push(snakeCase(key));
    }
  });
  const params = {
    white_label_partner_id: entry.selectedWlp ? entry.selectedWlp.value : null,
    firm_id: entry.selectedFirm ? entry.selectedFirm.value : null,
    private_access_fund_id: entry.selectedPaf ? entry.selectedPaf.value : null,
    statuses,
    offering_materials_trigger: entry.selectedOfferingMaterials.value,
    note: entry.note,
    send_sub_doc_delay: entry.sendSubDocDelay,
    addons_for_preapproval: entry.addonsForPreApproval,
    skip_pre_approval: entry.skipPreApproval,
    auto_resubmit_remediated_investments: entry.autoResubmitRemediatedInvestments,
    send_remediated_investments_to_reverted_bucket: entry.sendRemediatedInvestmentsToRevertedBucket,
    skip_workflow_statuses_on_remediation: entry.skipWorkflowStatusesOnRemediation,
    skip_workflow_status: entry.skipWorkflowStatusesOnRemediation ? entry.skipWorkflowStatus.value : null,
    allow_advisor_choice: entry.allowAdvisorChoice,
    allow_advisor_attestation: entry.allowAdvisorChoice ? entry.allowAdvisorAttestation : null,
  };
  return (dispatch) => {
    dispatch(spinnerActions.beginApiRequest());
    return axios
      .post(url, params, { withCredentials: true })
      .then(() => {
        dispatch(swsFetchAllCombinations());
        dispatch(spinnerActions.endApiRequest());
        notificationService.notifySuccess('', 'New row created successfully');
      })
      .catch((error) => {
        dispatch(spinnerActions.endApiRequest());
        notificationService.notifyError('', error.data.message);
      });
  };
}

function swsEditRecord(entry) {
  const url = `subscription_workflow_statuses/${entry.id}`;
  const statuses = [];
  Object.keys(entry.statuses).forEach((key) => {
    if (entry.statuses[key]) {
      statuses.push(snakeCase(key));
    }
  });
  const params = {
    id: entry.id,
    white_label_partner_id: entry.selectedWlp ? entry.selectedWlp.value : null,
    firm_id: entry.selectedFirm ? entry.selectedFirm.value : null,
    private_access_fund_id: entry.selectedPaf ? entry.selectedPaf.value : null,
    statuses,
    offering_materials_trigger: entry.selectedOfferingMaterials.value,
    send_sub_doc_delay: entry.sendSubDocDelay,
    addons_for_preapproval: entry.addonsForPreApproval,
    skip_pre_approval: entry.skipPreApproval,
    auto_resubmit_remediated_investments: entry.autoResubmitRemediatedInvestments,
    send_remediated_investments_to_reverted_bucket: entry.sendRemediatedInvestmentsToRevertedBucket,
    note: entry.note,
    skip_workflow_statuses_on_remediation: entry.skipWorkflowStatusesOnRemediation,
    skip_workflow_status: entry.skipWorkflowStatusesOnRemediation ? entry.skipWorkflowStatus.value : null,
    allow_advisor_choice: entry.allowAdvisorChoice,
    allow_advisor_attestation: entry.allowAdvisorChoice ? entry.allowAdvisorAttestation : null,
  };
  return (dispatch) => {
    dispatch(spinnerActions.beginApiRequest());
    return axios
      .patch(url, params, { withCredentials: true })
      .then(() => {
        dispatch(swsFetchAllCombinations());
        dispatch(spinnerActions.endApiRequest());
        notificationService.notifySuccess('', 'Row updated successfully');
      })
      .catch((error) => {
        dispatch(spinnerActions.endApiRequest());
        notificationService.notifyError('', error.data.message);
      });
  };
}

function swsSetSelectedFiltersForGroup(options, filterType, originalList) {
  const data = originalList.filter((item) => options.includes(item.typed_filter_id));

  return (dispatch, getState) => {
    dispatch(
      swsUpdatetSelectedFiltersForGroup({
        filterType,
        options: data,
      })
    );

    const filtered = filterCombinations(getState());
    dispatch(swsSetFilteredCombinations(filtered));

    dispatch(swsSetFilterOptions(parseFilters(filtered)));
  };
}

export default {
  swsFetchAllCombinations,
  swsFetchOfferingMaterialsTriggerOptions,
  swsSetCombinations,
  swsDeleteRecord,
  swsDeleteFromGrid,
  swsEditRecord,
  swsAddNewRecord,
  swsSetSelectedFiltersForGroup,
  swsSetFilterOptions,
  swsSetFilteredCombinations,
};
