import { LP_SET_LANDING_PAGE, LP_SET_HERO_CONFIGURATION, LP_RESET } from 'modules/landing_page/actions/constants';
import update from 'immutability-helper';
import initialState from './initialState';

function setConfigurations(state, {
  configurations = {},
  has_advisor_marketing_fields: hasAdvisorMarketingFields,
  announcements,
  announcements_size: announcementsSize,
  icapital_insights: insights,
}) {
  return update(state, {
    configurations: {
      id: { $set: configurations.id },
      customContent: { $set: configurations.custom_content },
      customDisclaimer: { $set: configurations.custom_disclaimer },
      showAnnouncements: { $set: configurations.show_announcements },
      showIcapitalInsights: { $set: configurations.show_icapital_insights_and_research },
      showInvestmentOpportunitiesCollections: { $set: configurations.show_investment_opportunities_collections },
    },
    hasAdvisorMarketingFields: { $set: hasAdvisorMarketingFields },
    announcements: { list: { $set: announcements }, size: { $set: announcementsSize } },
    insights: { list: { $set: insights } },
    loading: { $set: false },
  });
}

function setHeroConfiguration(state, configurations = {}) {
  return update(state, {
    heroConfiguration: {
      image: { $set: configurations.image },
      heroColor: { $set: configurations.hero_color },
      showSalutation: { $set: configurations.show_salutation },
      title: { $set: configurations.title },
      description: { $set: configurations.description },
      resourceCenterPage: { $set: configurations.resource_center_page },
      showHelpContactInformation: { $set: configurations.show_help_contact_information },
      applyGradientToImage: { $set: configurations.apply_gradient_to_image_upload },
      textTreatment: { $set: configurations.text_treatment },
    },
  });
}

export default (state = initialState, { type = '', payload = '' } = {}) => {
  switch (type) {
    case LP_SET_LANDING_PAGE:
      return setConfigurations(state, payload);
    case LP_SET_HERO_CONFIGURATION:
      return setHeroConfiguration(state, payload);
    case LP_RESET:
      return initialState;
    default:
      return state;
  }
};
