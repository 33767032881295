import { actionFactory } from 'services/actions';
import { postRequest, getRequest } from 'actions/utils';
import { RG_SET_FAILED_SCHEDULE_NOTIFICATION_RECIPIENTS } from 'actions/actionsConstants';

const BASE_URL = 'report_generator/failed_schedule_notification_recipients';
const failedScheduleNotificationRecipientsSuccess = actionFactory(
  RG_SET_FAILED_SCHEDULE_NOTIFICATION_RECIPIENTS,
);

function updateSchedulerNotificationRecipients(recipients, admin) {
  return (dispatch, getState) => {
    const storeConfig = getState().reportGeneratorConfigs.current_configs;
    return dispatch(
      postRequest({
        url: BASE_URL,
        data: {
          config_id: storeConfig.id,
          recipients: recipients,
          admin: admin,
        },
        onSuccess: (response) =>
          failedScheduleNotificationRecipientsSuccess(response),
        successMessage: {
          message: 'Schedule notification recipients saved successfully',
        },
        errorMessage: {
          title: 'Error',
          message: 'Unable to save schedule notification recipients',
        },
      }),
    );
  };
}

function getSchedulerNotificationRecipients(admin) {
  return (dispatch, getState) => {
    const storeConfig = getState().reportGeneratorConfigs.current_configs;
    return dispatch(
      getRequest({
        url: BASE_URL,
        params: { config_id: storeConfig.id, admin: admin },
        onSuccess: (response) =>
          failedScheduleNotificationRecipientsSuccess(response),
        errorMessage: {
          title: 'Error',
          message: 'Unable to fetch schedule notification recipients',
        },
      }),
    );
  };
}

export default {
  updateSchedulerNotificationRecipients,
  getSchedulerNotificationRecipients,
};
