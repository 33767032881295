/* eslint-disable max-lines */
import _ from 'lodash';

import {
  SET_INVESTOR_PROFILE,
  SET_PROFILE_FIELDS,
  SET_PROFILE_ERRORS,
  RESET_CUSTODIAN_ACCOUNT_ERRORS,
  SET_INTERESTED_PARTIES_ERRORS,
  SET_CUSTODIAN_ACCOUNT_ERRORS,
  SET_SIGNERS_ERRORS,
  ADD_SIGNER_ERROR,
  REMOVE_SIGNER_ERROR,
  RESET_SIGNER_ERRORS,
  IP_SET_INITIAL_VALUES,
  SET_CHOOSE_TO_PARTICIPATE,
  SET_NEW_ISSUES_QUESTIONS,
  SET_NEW_ISSUES_NONE_APPLY,
  SET_NEW_ISSUES_DEFAULT,
  ADD_INTERESTED_PARTY,
  REMOVE_INTERESTED_PARTY,
  UPDATE_INTERESTED_PARTY,
  RESET_INTERESTED_PARTY_ERRORS,
  ADD_SIGNER,
  REMOVE_SIGNER,
  UPDATE_SIGNER,
  REMOVE_ALL_INTERESTED_PARTIES,
  RESET_INTERESTED_PARTIES_ERRORS,
  SET_CUSTODIAN_ACCOUNT_FIELD,
  RESET_CUSTODIAN_ACCOUNT,
  SET_HAS_CUSTODIAN,
  SET_CURRENT_CUSTODIAN,
  SET_BATCH_UPDATE_OPTIONS,
  SET_PROFILE_COUNTRIES,
  SET_CONDITIONAL_COUNTRIES,
  SET_PAF_EXCEPTION_APPLIES,
  SET_INVESTOR_PROFILE_COMPLETENESS,
  SET_INVESTOR_PROFILE_SIGNER_TYPES,
  SET_INVESTOR_PROFILE_VISIBILITY,
  SET_INBOUND_ANGULAR_QUERY_PARAMS,
  SET_PROFILE_WLP_CONFIG,
  SET_SIGNERS_BACKUP,
  SET_INTERESTED_PARTIES_BACKUP,
  SET_PROFILE_COUNTRY_BACKUP,
  INVESTOR_PROFILE_UNMOUNT,
  SET_CUSTOM_ANALYZERS,
} from 'actions/actionsConstants';

import { profileCountriesState, conditionalCountriesState } from 'services/investor_profile/countriesProcessor';
import update from 'immutability-helper';
import { arrToObj } from 'services/investor_profile';

const newIssuesDefault = () => ({
  choose_to_participate: '',
  data: {
    question1: {
      has_broker_dealer_personnel_family_broker_dealer_member: null,
      has_broker_dealer_personnel_family_can_allocate_new_issues: null,
      has_broker_dealer_personnel_family_financial_support: null,
      is_broker_dealer_member: null,
      is_broker_dealer_personnel: null,
      is_executive_officer_of_select_companies: null,
      is_finder_or_fiduciary: null,
      is_portfolio_investment_manager: null,
      none_applies: null,
      owns_broker_dealer: null,
      owns_broker_dealer_family_financial_support: null,
      owns_broker_dealer_listed: null,
      owns_broker_dealer_owns_ten_percent_or_more: null,
      owns_broker_dealer_owns_twenty_five_percent_or_more: null,
    },
    question2: {
      is_a_church_plan: null,
      is_a_common_trust_fund: null,
      is_a_foreign_investment_company: null,
      is_a_government_benefits_plan: null,
      is_a_publicly_traded_entity: null,
      is_a_tax_exempt_charitable_org: null,
      is_an_erisa_benefits_plan: null,
      is_company_registered_under_1940_act: null,
      is_insurance_company: null,
      is_non_restricted_account: null,
      none_applies: null,
    },
  },
  id: null,
  complete: false,
  investor_profile_id: null,
});

export const initialState = {
  profile: {},
  current_custodian: {},
  errors: {
    profile_errors: {},
    interested_parties_errors: {},
    custodian_account_errors: {},
    signers_errors: {},
  },
  inboundAngularQueryParams: {},
  batch_update_options: {
    live: null,
    frozen: null,
    profile_type: 'current',
  },
  countries: {
    all: [],
    viewable: [],
    paf_whitelisted: [],
    domicile: [],
    entity_permanent_address: [],
  },
  completeness: {},
  visibility: {},
  white_label_partner: {},
  signers_backup: [],
  interested_parties_backup: [],
  country_backup: {},
  individual_signers_types: [],
  entity_signer_types: [],
  custom_analyzers: [],
  entity_tax_classification: [],
  individual_disregarded_entity_types: [],
  entity_disregarded_entity_types: [],
  entity_disregarded_entity_tax_classification: [],
  has_investor_profile_template: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_PROFILE_FIELDS:
      return update(state, {
        profile: profile => update(profile || {}, {
          $merge: action.payload,
        }),
      });

    case SET_INBOUND_ANGULAR_QUERY_PARAMS:
      return update(state, {
        inboundAngularQueryParams: {
          $set: action.payload,
        },
      });

    case SET_CUSTOM_ANALYZERS:
      return update(state, {
        custom_analyzers: {
          $set: action.payload,
        },
      });

    case SET_PROFILE_ERRORS:
      return update(state, {
        errors: errors => update(errors || {}, {
          profile_errors: p_errors => update(p_errors || {}, {
            $merge: action.errors,
            $unset: action.errorless,
          }),
        }),
      });

    case RESET_CUSTODIAN_ACCOUNT_ERRORS:
      return update(state, {
        errors: errors => update(errors || {}, {
          custodian_account_errors: ca_errors => update(ca_errors || {}, {
            $set: {},
          }),
        }),
      });

    case SET_INTERESTED_PARTIES_ERRORS:
      return update(state, {
        errors: errors => update(errors || {}, {
          interested_parties_errors: ip_errors => update(ip_errors || {}, {
            [action.idx]: idx => update(idx || {}, {
              $merge: action.errors,
              $unset: action.errorless,
            }),
          }),
        }),
      });

    case SET_SIGNERS_ERRORS:
      return update(state, {
        errors: errors => update(errors || {}, {
          signers_errors: s_errors => update(s_errors || {}, {
            [action.idx]: idx => update(idx || {}, {
              $merge: action.errors,
              $unset: action.errorless,
            }),
          }),
        }),
      });

    case ADD_SIGNER_ERROR:
      return update(state, {
        errors: errors => update(errors || {}, {
          signers_errors: s_errors => update(s_errors || {}, {
            [action.idx]: idx => update(idx || {}, {
              [action.field]: field => update(field || [], {
                $apply: (arr) => {
                  const { errorMsg } = action;

                  return _.includes(arr, errorMsg) ? arr : [...arr, errorMsg];
                },
              }),
            }),
          }),
        }),
      });

    case REMOVE_SIGNER_ERROR:
      return update(state, {
        errors: errors => update(errors || {}, {
          signers_errors: s_errors => update(s_errors || {}, {
            [action.idx]: idx => update(idx || {}, {
              [action.field]: field => update(field || [], {
                $set: _.pull(field, action.errorMsg),
              }),
            }),
          }),
        }),
      });

    case RESET_SIGNER_ERRORS:
      return update(state, {
        errors: errors => update(errors || {}, {
          signers_errors: s_errors => update(s_errors || {}, {
            [action.idx]: idx => update(idx || {}, {
              $set: {},
            }),
          }),
        }),
      });

    case SET_CUSTODIAN_ACCOUNT_ERRORS:
      return update(state, {
        errors: errors => update(errors || {}, {
          custodian_account_errors: ca_errors => update(ca_errors || {}, {
            $merge: action.errors,
            $unset: action.errorless,
          }),
        }),
      });

    case SET_INVESTOR_PROFILE:
      return update(state, {
        profile: { $set: action.payload },
      });

    case IP_SET_INITIAL_VALUES:
      return update(state, {
        profile: profile => update(profile || {}, {
          $merge: action.payload,
        }),
      });

    case SET_CHOOSE_TO_PARTICIPATE:
      return update(state, {
        profile: profile => update(profile || {}, {
          new_issues_profile: new_issues_profile => update(new_issues_profile || {}, {
            choose_to_participate: ctp => update(ctp, {
              $set: action.payload,
            }),
            data: data => update(data || {}, {
              question1: fields => arrToObj(Object.keys(fields), null),
              question2: fields => arrToObj(Object.keys(fields), null),
            }),
          }),
        }),
      });

    case SET_NEW_ISSUES_QUESTIONS:
      return update(state, {
        profile: profile => update(profile || {}, {
          new_issues_profile: new_issues_profile => update(new_issues_profile || {}, {
            data: data => update(data || {}, {
              [action.question]: question => update(question || {}, {
                $merge: { none_applies: false, ...action.payload },
              }),
            }),
          }),
        }),
      });

    case SET_NEW_ISSUES_NONE_APPLY:
      return update(state, {
        profile: profile => update(profile || {}, {
          new_issues_profile: new_issues_profile => update(new_issues_profile || {}, {
            data: data => update(data || {}, {
              [action.question]: question => update(question || {}, {
                $set: { [action.field]: action.payload },
              }),
            }),
          }),
        }),
      });

    case SET_NEW_ISSUES_DEFAULT:
      return update(state, {
        profile: profile => update(profile || {}, {
          new_issues_profile: {
            $set: newIssuesDefault()
          }
        }),
      });

    case ADD_INTERESTED_PARTY:
      return update(state, {
        profile: profile => update(profile || {}, {
          interested_parties: interested_parties => update(interested_parties || [], {
            $push: [{
              address: '',
              name: '',
              phone: '',
              relationship_to_investor: '',
            }],
          }),
        }),
      });

    case REMOVE_INTERESTED_PARTY:
      if (action.idx === 0) { return null; }

      return update(state, {
        profile: profile => update(profile || {}, {
          interested_parties: interested_parties => update(interested_parties || [], {
            $splice: [[action.idx, 1]],
          }),
        }),
      });

    case RESET_INTERESTED_PARTY_ERRORS:
      return update(state, {
        errors: errors => update(errors || {}, {
          interested_parties_errors: ip_errors => update(ip_errors || {}, {
            [action.idx]: idx => update(idx || {}, {
              $set: {},
            }),
          }),
        }),
      });

    case REMOVE_ALL_INTERESTED_PARTIES:
      return update(state, {
        profile: profile => update(profile || {}, {
          interested_parties: { $set: [] },
        }),
      });

    case RESET_INTERESTED_PARTIES_ERRORS:
      return update(state, {
        errors: errors => update(errors || {}, {
          interested_parties_errors: ip_errors => update(ip_errors || {}, {
            $set: {},
          }),
        }),
      });

    case UPDATE_INTERESTED_PARTY:
      return update(state, {
        profile: profile => update(profile || {}, {
          interested_parties: interested_parties => update(interested_parties || [], {
            [action.idx]: party => update(party || {}, {
              [action.key]: field => update(field, {
                $set: action.value,
              }),
            }),
          }),
        }),
      });

    case ADD_SIGNER:
      return update(state, {
        profile: profile => update(profile || {}, {
          signers: signers => update(signers || [], {
            $push: [{
              first_name: '',
              last_name: '',
              ssn: '',
              relationship_to_investor: '',
              birthdate: '',
              email: '',
              full_name: '',
              section_a_individual_employment_status: '',
              section_a_individual_employer: '',
              section_a_individual_occupation: '',
              primary_relationship: '',
              signer_title_of_signatory: '',
            }],
          }),
        }),
      });

    case REMOVE_SIGNER:
      if (action.idx === 0) { return null; }

      return update(state, {
        profile: profile => update(profile || {}, {
          signers: signers => update(signers || [], {
            $splice: [[action.idx, 1]],
          }),
        }),
      });

    case UPDATE_SIGNER:
      return update(state, {
        profile: profile => update(profile || {}, {
          signers: signers => update(signers || [], {
            [action.idx]: signer => update(signer || {}, {
              [action.key]: field => update(field, {
                $set: action.value,
              }),
            }),
          }),
        }),
      });

    case SET_CUSTODIAN_ACCOUNT_FIELD:
      return update(state, {
        profile: profile => update(profile || {}, {
          custodian_account: custodian_account => update(custodian_account || {}, {
            $merge: action.payload,
          }),
        }),
      });

    case RESET_CUSTODIAN_ACCOUNT:
      return update(state, {
        profile: profile => update(profile || {}, {
          custodian_account: custodian_account => update(custodian_account || {}, {
            $merge: {
              custodian_id: action.custodian_id,
              custodian_account_type_id: '',
              account_name: '',
              account_number: '',
              addendum_on_file: '',
              group_number: '',
            },
          }),
        }),
      });

    case SET_HAS_CUSTODIAN:
      return update(state, {
        has_custodian: { $set: action.value },
      });

    case SET_CURRENT_CUSTODIAN:
      return update(state, {
        current_custodian: { $set: action.payload },
      });

    case SET_BATCH_UPDATE_OPTIONS:
      return update(state, {
        batch_update_options: options => update(options || {}, {
          $merge: action.payload,
        }),
      });

    case SET_PAF_EXCEPTION_APPLIES:
      return update(state, {
        countries: {
          $merge: {
            paf_exception_applied: action.payload,
          },
        },
      });

    case SET_PROFILE_COUNTRIES:
      return update(state, {
        countries: {
          $merge: profileCountriesState(
            action.payload.all,
            action.payload.viewable,
            action.payload.paf_whitelisted,
            action.payload.is_ria,
            state.countries.paf_exception_applied
          ),
        },
      });

    case SET_CONDITIONAL_COUNTRIES:
      return update(state, {
        countries: {
          $merge: conditionalCountriesState(
            state.profile,
            state.countries.viewable,
            state.countries.paf_whitelisted,
            state.countries.lock_country_fields
          ),
        },
      });

    case SET_INVESTOR_PROFILE_COMPLETENESS:
      return update(state, {
        completeness: { $set: action.payload },
      });

    case SET_INVESTOR_PROFILE_SIGNER_TYPES:
      return update(state, {
        individual_signers_types: { $set: action.payload.individual },
        entity_signer_types: { $set: action.payload.entity },
        entity_tax_classification: { $set: action.payload.entity_tax_classification },
        individual_disregarded_entity_types: { $set: action.payload.individual_disregarded_entity },
        entity_disregarded_entity_types: { $set: action.payload.entity_disregarded_entity },
        entity_disregarded_entity_tax_classification: { $set: action.payload.entity_disregarded_entity_tax_classification },
      });

    case SET_INVESTOR_PROFILE_VISIBILITY:
      return update(state, {
        visibility: { $set: action.payload },
      });

    case SET_PROFILE_WLP_CONFIG:
      return update(state, {
        white_label_partner: { $set: action.payload },
      });

    case SET_SIGNERS_BACKUP:
      return update(state, {
        signers_backup: { $set: action.payload },
      });

    case SET_INTERESTED_PARTIES_BACKUP:
      return update(state, {
        interested_parties_backup: { $set: action.payload },
      });

    case SET_PROFILE_COUNTRY_BACKUP:
      return update(state, {
        country_backup: { $set: action.payload },
      });

    case INVESTOR_PROFILE_UNMOUNT:
      return initialState;

    default:
      return state;
  }
};
