import update from 'immutability-helper';
import { ACTIVITY_LOG_GP_FUND_FOLLOWINGS_SET_CURRENT_PAGE, ACTIVITY_LOG_GP_FUND_FOLLOWINGS_SET_DATA, ACTIVITY_LOG_GP_FUND_FOLLOWINGS_SET_RESULTS_PER_PAGE, ACTIVITY_LOG_GP_FUND_FOLLOWINGS_SET_SORT } from 'modules/activity_log/actions/constants';
import initialState from '../initialState';

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIVITY_LOG_GP_FUND_FOLLOWINGS_SET_DATA: {
      return update(state, {
        gpFundFollowingsData: {
          data: { $set: action.payload.data },
          engagementFilters: { $set: action.payload.engagement_filters },
          currentPage: { $set: action.payload.current_page },
          resultsPerPage: { $set: action.payload.results_per_page },
          hasNextPage: { $set: action.payload.has_next_page },
          totalCount: { $set: action.payload.total_count },
        },
      });
    }

    case ACTIVITY_LOG_GP_FUND_FOLLOWINGS_SET_CURRENT_PAGE: {
      return update(state, { gpFundFollowingsData: { currentPage: { $set: action.payload } } });
    }

    case ACTIVITY_LOG_GP_FUND_FOLLOWINGS_SET_RESULTS_PER_PAGE: {
      return update(state, {
        gpFundFollowingsData: {
          resultsPerPage: { $set: action.payload },
          currentPage: { $set: 1 },
        },
      });
    }

    case ACTIVITY_LOG_GP_FUND_FOLLOWINGS_SET_SORT: {
      return update(state, { gpFundFollowingsData: { sort: { $set: action.payload } } });
    }

    default:
      return state;
  }
};
