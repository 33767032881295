export default {
  scheduledConfigs: [],
  scheduledConfigsCount: 0,
  activeTab: null,
  loading: true,
  resultsPerPage: 25,
  currentPage: 1,
  sortingColumn: '',
  sortingOrder: '',
  reportNameFilter: '',
  deliveryFilter: '',
  freshServiceStatusFilter: '',
  approvalStatusFilter: '',
  jobStatusFilter: '',
  lockedFilter: '',
  creatorFilter: '',
  showDeliveryScheduleModal: false,
  scheduleIdToDisable: null,
  deliverySchedules: { pastSchedules: [], upcomingSchedules: [] },
};
