export var InvestmentProduct;
(function (InvestmentProduct) {
    InvestmentProduct["ALTS"] = "Alts";
    InvestmentProduct["PLATFORM"] = "Platform";
})(InvestmentProduct || (InvestmentProduct = {}));
export var Feature;
(function (Feature) {
    Feature["CLIENT_MANAGEMENT"] = "Client Management";
    Feature["DOCUMENT_CENTER"] = "Document Center";
    Feature["EDUCATION_LOG"] = "Education Log";
    Feature["FUND_DASHBOARD"] = "Fund Dashboard";
    Feature["INVESTMENT_DASHBOARD"] = "Investment Dashboard";
    Feature["SALES_REPORTING_DASHBOARD"] = "Sales Reporting Dashboard";
    Feature["SUBSCRIPTION_DASHBOARD"] = "Subscription Dashboard";
    Feature["TEAM_MANAGEMENT"] = "Team Management";
    Feature["ACTIVITY_LOG"] = "Activity Log";
    Feature["ADVISOR_DASHBOARD"] = "Advisor Dashboard";
    Feature["INVESTMENT_OPPORTUNITY"] = "Investment Opportunity";
    Feature["FOUNDATIONAL_EDUCATION"] = "Foundational Education";
    Feature["PRODUCT_TRAINING"] = "Product Training";
    Feature["FUND_DETAIL"] = "Fund Detail";
    Feature["INVESTMENT_OPPORTUNITIES"] = "Investment Opportunities";
    Feature["INSIGHTS"] = "Insights";
})(Feature || (Feature = {}));
export var SuperFeatureSet;
(function (SuperFeatureSet) {
    SuperFeatureSet["MANAGE"] = "Manage";
    SuperFeatureSet["ADMIN"] = "Admin";
    SuperFeatureSet["LEARN"] = "Learn";
    SuperFeatureSet["INVEST"] = "Invest";
    SuperFeatureSet["ACCESS"] = "Access";
})(SuperFeatureSet || (SuperFeatureSet = {}));
export var Phase;
(function (Phase) {
    Phase["TRADE"] = "Trade";
    Phase["POST_TRADE"] = "Post-Trade";
    Phase["PRE_TRADE"] = "Pre-Trade";
    Phase["NA"] = "NA";
})(Phase || (Phase = {}));
export var Activity;
(function (Activity) {
    Activity["FEATURE_USAGE"] = "Feature Usage";
    Activity["VIEW"] = "View";
    Activity["CALL_TO_ACTION"] = "Call to Action";
    Activity["VIDEO"] = "Video";
})(Activity || (Activity = {}));
export var Action;
(function (Action) {
    Action["FILTER"] = "Filter";
    Action["CLICK"] = "Click";
    Action["SHARE"] = "Share";
    Action["DOWNLOAD"] = "Download";
    Action["START"] = "Start";
})(Action || (Action = {}));
