/* eslint-disable max-lines */
import { createAction } from 'redux-actions';
import { getRequest, postRequest, patchRequest } from 'actions/utils';

import {
  CE_CASH_FUNDING_CUSTODIAN_LIST,
  CE_CASH_FUNDING_FIRM_LIST,
  CE_CASH_FUNDING_SET_SELECTED_CUSTODIANS,
  CE_CASH_FUNDING_SET_SELECTED_FIRMS,
  CE_CASH_FUNDING_SET_DETAILS,
  CE_CASH_FUNDING_EXPORT_DOCS_LIST,
  CE_CASH_FUNDING_EVENTS_LIST,
  CE_CASH_FUNDING_REFRESH_GRID_INFO,
  CE_CASH_FUNDING_UPLOAD_RESULTS,
  CE_CASH_FUNDING_BUCKETS_COUNT,
  CE_CASH_FUNDING_RESET_WIRE_AMOUNT_INPUT,
  CE_CASH_FUNDING_CAPITAL_EVENT_DETAILS,
  CE_CASH_FUNDING_CAPITAL_EVENT_SELECT_ALL,
  CE_CASH_FUNDING_CAPITAL_EVENT_BATCH_SELECT,
  CE_CASH_FUNDING_CAPITAL_EVENT_BATCH_SELECT_REMOVE,
  CE_CASH_FUNDING_CAPITAL_EVENT_CURRENT_TABLE_SELECT,
  CE_CASH_FUNDING_SET_FILTER_OPTIONS,
} from '../../../actionsConstants';

const ceCashFundingSelectAllPayload = (tableName, value) => ({
  type: tableName, value
});

const ceCashFundingSetCustodianList = createAction(CE_CASH_FUNDING_CUSTODIAN_LIST);
const ceCashFundingSetFirmList = createAction(CE_CASH_FUNDING_FIRM_LIST);
const ceCashFundingSetSelectedCustodians = createAction(CE_CASH_FUNDING_SET_SELECTED_CUSTODIANS);
const ceCashFundingSetSelectedFirms = createAction(CE_CASH_FUNDING_SET_SELECTED_FIRMS);
const ceCashFundingSetDetails = createAction(CE_CASH_FUNDING_SET_DETAILS);
const ceCashFundingSetExportDocsList = createAction(CE_CASH_FUNDING_EXPORT_DOCS_LIST);
const ceCashFundingSetEventsList = createAction(CE_CASH_FUNDING_EVENTS_LIST);
const ceCashFundingRefreshGridInfo = createAction(CE_CASH_FUNDING_REFRESH_GRID_INFO);
const ceCashFundingUploadResults = createAction(CE_CASH_FUNDING_UPLOAD_RESULTS);
const ceCashFundingBucketsCount = createAction(CE_CASH_FUNDING_BUCKETS_COUNT);
const ceCashFundingResetWireAmountInput = createAction(CE_CASH_FUNDING_RESET_WIRE_AMOUNT_INPUT);
const ceCashFundingSetCapEventDetails = createAction(CE_CASH_FUNDING_CAPITAL_EVENT_DETAILS);
const ceCashFundingSetSelectAll = createAction(CE_CASH_FUNDING_CAPITAL_EVENT_SELECT_ALL, ceCashFundingSelectAllPayload);
const ceCashFundingSetItemBatchSelect = createAction(CE_CASH_FUNDING_CAPITAL_EVENT_BATCH_SELECT);
const ceCashFundingRemoveItemBatchSelect = createAction(CE_CASH_FUNDING_CAPITAL_EVENT_BATCH_SELECT_REMOVE);
const ceCashFundingCurrentTableSelect = createAction(CE_CASH_FUNDING_CAPITAL_EVENT_CURRENT_TABLE_SELECT);
const ceCashFundingSetFilterOptions = createAction(CE_CASH_FUNDING_SET_FILTER_OPTIONS);

const ceCashFundingFetchFilterOptions = (capitalEventId) => (dispatch) => (
  dispatch(getRequest({
    url: `/capital_events/${capitalEventId}/cash_funding_data/filter_options`,
    onSuccess: ceCashFundingSetFilterOptions,
  }))
);

const ceUploadCashFundingFile = (capitalEventId, file) => (dispatch) => (
  dispatch(postRequest({
    url: `/capital_events/${capitalEventId}/cash_funding_data/upload`,
    data: file,
  }))
);

const ceExportCashFunding = (capitalEventId, selectedCustodians, selectedFirms) => (dispatch) => (
  dispatch(getRequest({
    url: `/capital_events/${capitalEventId}/cash_funding_data/export`,
    params: { selected_custodians: selectedCustodians, selected_firms: selectedFirms },
  }))
);

const ceCashFundingFetchCustodianList = (capitalEventId) => (dispatch) => (
  dispatch(getRequest({
    url: `/capital_events/${capitalEventId}/custodians`,
    onSuccess: ceCashFundingSetCustodianList,
    showSpinner: false,
  }))
);

const ceCashFundingFetchFirmList = (capitalEventId) => (dispatch) => (
  dispatch(getRequest({
    url: `/capital_events/${capitalEventId}/firms`,
    showSpinner: false,
    onSuccess: ceCashFundingSetFirmList,
  }))
);

const ceCashFundingDetails = (capitalEventId) => (dispatch) => (
  dispatch(getRequest({
    url: `/capital_events/${capitalEventId}/cash_funding_details/overview`,
    showSpinner: false,
    onSuccess: ceCashFundingSetDetails,
  }))
);

const ceCashFundingUpdateDetails = (capitalEventId, details) => (dispatch) => {
  const reqParams = {
    data: { cash_funding_details: details },
  };

  return details.cash_funding_detail_id ? dispatch(patchRequest({
    url: `/capital_events/${capitalEventId}/cash_funding_details/${details.cash_funding_detail_id}`,
    ...reqParams,
  })) : dispatch(postRequest({
    url: `/capital_events/${capitalEventId}/cash_funding_details`,
    ...reqParams,
  }));
};

const ceCashFundingApprove = (capitalEventId, event) => (dispatch) => (
  dispatch(postRequest({
    url: `/capital_events/${capitalEventId}/cash_funding_data/approve_funding`,
    data: { cash_funding_event: event },
  }))
);

const ceCashFundingReject = (capitalEventId, event) => (dispatch) => (
  dispatch(postRequest({
    url: `/capital_events/${capitalEventId}/cash_funding_data/reject_funding`,
    data: { cash_funding_event: event },
  }))
);

const ceCashFundingInProgress = (capitalEventId, event) => (dispatch) => (
  dispatch(postRequest({
    url: `/capital_events/${capitalEventId}/cash_funding_data/in_progress`,
    data: { cash_funding_event: event },
  }))
);

const ceCashFundingUpdateCallValues = (capitalEventId, capitalEventLineItemId, newValues) => (dispatch) => (
  dispatch(patchRequest({
    url: `/capital_events/${capitalEventId}/cash_funding_data`,
    data: { new_funded_data: { capital_event_line_item_id: capitalEventLineItemId, ...newValues } },
    showSpinner: false,
  }))
);

const ceCashFundingFetchExportHistory = (capitalEventId, page, perPage) => (dispatch) => (
  dispatch(getRequest({
    url: '/document_exports',
    params: {
      document_types: ['CashFundingReport'],
      capital_event_id: capitalEventId,
      page,
      per_page: perPage,
    },
    showSpinner: false,
    onSuccess: ceCashFundingSetExportDocsList,
  }))
);

const ceCashFundingFetchEventHistory = (capitalEventId, page, perPage) => (dispatch) => (
  dispatch(getRequest({
    url: `/capital_events/${capitalEventId}/cash_funding_events`,
    params: {
      page,
      per_page: perPage,
    },
    showSpinner: false,
    onSuccess: ceCashFundingSetEventsList,
  }))
);

const ceCashFundingFetchUploadResults = (capitalEventId) => (dispatch) => (
  dispatch(getRequest({
    url: '/document_exports',
    params: {
      document_types: ['CashFundingUploadResultCsv'],
      capital_event_id: capitalEventId,
    },
    showSpinner: false,
    onSuccess: ceCashFundingUploadResults,
  }))
);

const ceCashFundingFetchBucketTotals = (capitalEventId) => (dispatch, getState) => (
  dispatch(getRequest({
    url: `/capital_events/${capitalEventId}/cash_funding_data/bucket_totals`,
    params: {
      selected_firms: getState().ceCashFunding.selectedFirms.join(),
      selected_custodians: getState().ceCashFunding.selectedCustodians.join(),
    },
    showSpinner: false,
    onSuccess: ceCashFundingBucketsCount,
  }))
);

const ceCashFundingCapEventDetails = (capitalEventId) => (dispatch) => (
  dispatch(getRequest({
    url: `/capital_events/${capitalEventId}/cash_funding_details/capital_event_details`,
    onSuccess: ceCashFundingSetCapEventDetails,
    showSpinner: false,
  }))
);

const ceCashFundingCapEventBatchApprove = (capitalEventId, event, approveAll, filterOption, filterCriteria) => (dispatch, getState) => {
  const data = {
    cash_funding_event: event,
    selected_firms: getState().ceCashFunding.selectedFirms.join(),
    selected_custodians: getState().ceCashFunding.selectedCustodians.join(),
    approve_all: approveAll,
  };
  if (filterOption) {
    data[filterOption.option] = filterCriteria;
  }
  return dispatch(postRequest({
    url: `/capital_events/${capitalEventId}/cash_funding_data/batch_approve`,
    data
  }));
};

const ceLockCapitalEvent = (capitalEventId) => (dispatch) =>(
  dispatch(patchRequest({
    url: `/capital_events/${capitalEventId}/lock`,
    infoMessage: {
      title: 'Capital Event Locked',
    },
  }))
);

export default {
  ceUploadCashFundingFile,
  ceExportCashFunding,
  ceCashFundingFetchCustodianList,
  ceCashFundingFetchFirmList,
  ceCashFundingSetSelectedCustodians,
  ceCashFundingSetSelectedFirms,
  ceCashFundingDetails,
  ceCashFundingUpdateDetails,
  ceCashFundingApprove,
  ceCashFundingReject,
  ceCashFundingInProgress,
  ceCashFundingUpdateCallValues,
  ceCashFundingFetchExportHistory,
  ceCashFundingFetchEventHistory,
  ceCashFundingRefreshGridInfo,
  ceCashFundingFetchUploadResults,
  ceCashFundingFetchBucketTotals,
  ceCashFundingResetWireAmountInput,
  ceCashFundingCapEventDetails,
  ceCashFundingSetSelectAll,
  ceCashFundingSetItemBatchSelect,
  ceCashFundingRemoveItemBatchSelect,
  ceCashFundingCapEventBatchApprove,
  ceCashFundingCurrentTableSelect,
  ceLockCapitalEvent,
  ceCashFundingFetchFilterOptions,
};
