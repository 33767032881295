export default {
  scheduleExecutionLogs: [],
  scheduleExecutionLogsCount: 0,
  loading: true,
  resultsPerPage: 25,
  currentPage: 1,
  sortingColumn: '',
  sortingOrder: '',
  filters: {
    lockedConfigId: '',
    openConfigId: '',
    status: [],
    scheduledAt: null,
  },
};
