import React from 'react';
import {
  Box,
  Button,
  DialogActions,
} from '@icapitalnetwork/supernova-core';
import { useTranslation } from 'react-i18next';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  func, bool, string,
} from 'prop-types';

function ForwardBackButtons({
  onNext,
  onBack,
  canContinue,
  continueText,
  isSubmitting,
}) {
  const { t } = useTranslation(['common']);

  return (
    <DialogActions>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
        <Button
          variant="text"
          id="show_previous_page_button"
          onClick={onBack}
          startIcon={<ArrowBackIcon />}
          disabled={isSubmitting}
        >
          {t('common:back', 'Back')}
        </Button>
        <Button
          id="show_next_page_button"
          onClick={onNext}
          disabled={!canContinue || isSubmitting}
        >
          {continueText || t('common:continue', 'Continue')}
        </Button>
      </Box>
    </DialogActions>
  );
}

ForwardBackButtons.propTypes = {
  onNext: func.isRequired,
  onBack: func.isRequired,
  canContinue: bool,
  continueText: string,
  isSubmitting: bool,
};

ForwardBackButtons.defaultProps = {
  continueText: '',
  canContinue: false,
  isSubmitting: false,
};

export default ForwardBackButtons;
