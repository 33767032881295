import {
  SE_SET_SELECTED_EXCLUSIONS,
  SE_SET_RESULTS_PER_PAGE,
  SE_SET_CURRENT_PAGE,
  SE_SET_SORTING,
} from 'actions/actionsConstants';

import update from 'immutability-helper';

import { normalizeCurrentPage } from '../utils';
import initialState from '../initialState';

export default (state = initialState, action) => {
  let newState = null;

  switch (action.type) {
    case SE_SET_SELECTED_EXCLUSIONS:
      newState = update(state, {
        selected_rias_firms: { $set: action.payload },
        current_page: { $set: normalizeCurrentPage(1, state) },
      });
      return newState;
    case SE_SET_RESULTS_PER_PAGE:
      newState = update(state, {
        results_per_page: { $set: action.payload },
        current_page: { $set: initialState.current_page },
      });
      return newState;
    case SE_SET_CURRENT_PAGE:
      newState = update(state, {
        current_page: { $set: normalizeCurrentPage(action.payload, state) },
      });
      return newState;
    case SE_SET_SORTING:
      newState = update(state, {
        sorting_column: { $set: action.payload.sortingColumn },
        sorting_order: { $set: action.payload.sortingOrder },
      });
      return newState;
    default:
      return state;
  }
};
