import {
  DC_CLEAR_SELECTED_DOCUMENTS,
  DC_CLEAR_UNSELECTED_DOCUMENTS,

  DC_SET_SELECTED_DOCUMENTS,
  DC_SET_UNSELECTED_DOCUMENTS,

  DC_CLEAR_ALL_SELECTIONS,
  DC_TOGGLE_SELECT_ALL,

  DC_TOGGLE_DOWNLOAD_ERROR,
  DC_SET_MAX_FILES_LIMIT,

  DC_DELETE_INTERVAL,
  DC_NEW_DOWNLOAD_AVAILABLE,
  DC_CREATE_INTERVAL,
  DC_CLEAR_DOWNLOAD_AVAILABLE,
} from 'actions/actionsConstants';

import update from 'immutability-helper';

const initialState = {
  selected: {},
  unselected: {},
  showBanner: false,
  downloadInProgress: false,
  selectedResultCount: 0,
  downloadError: null,
  maxFiles: 500,
  checkboxState: 0,
  toViewList: [],
  currentIntervals: {},
};

export { initialState };

// TODO, consider moving these state props into
// dcResultsActions to cause fewer updates/re-renders
export default (state = initialState, action) => {
  const payload = action.payload;
  const nextState = {};

  switch (action.type) {
    case DC_TOGGLE_DOWNLOAD_ERROR:
      const error = (payload && payload.downloadError) ? payload.downloadError : null;
      nextState.downloadError = { $set: error };
      return update(state, nextState);

    case DC_SET_MAX_FILES_LIMIT:
      nextState.maxFiles = { $set: payload.maxFiles };
      return update(state, nextState);

    case DC_CLEAR_SELECTED_DOCUMENTS:
      return update(state, { selected: { $set: {} } });

    case DC_CLEAR_UNSELECTED_DOCUMENTS:
      return update(state, { unselected: { $set: {} } });

    case DC_SET_SELECTED_DOCUMENTS:
      nextState.selected = { $merge: payload.selected };
      nextState.unselected = { $set: {} };

      return update(state, nextState);

    case DC_SET_UNSELECTED_DOCUMENTS:
      nextState.unselected = { $merge: payload.unselected };
      nextState.selected = { $set: {} };


      return update(state, nextState);

    case DC_CLEAR_ALL_SELECTIONS:
      return update(state, {
        unselected: { $set: {} },
        selected: { $set: {} },
      });

    case DC_TOGGLE_SELECT_ALL:
      const props = [
        'showBanner',
        'downloadInProgress',
        'selectedResultCount',
        'checkboxState',
      ];

      props.forEach((item, index) => {
        if (payload.hasOwnProperty(item)) {
          nextState[item] = { $set: payload[item] };

          // Clean up
          if (item === 'downloadInProgress' && payload[item] === false) {
            nextState['selectedResultCount'] = { $set: 0 };
            nextState['showBanner'] = { $set: false };
            nextState['checkboxState'] = { $set: 0 };
          }
        }
      });

      return update(state, nextState);

    case DC_DELETE_INTERVAL: {
      const {
        [payload.idInterval]: value,
        ...withoutInterval
      } = state.currentIntervals;
      clearInterval(value);
      nextState.currentIntervals = {
        $set: {
          ...withoutInterval,
        },
      };
      return update(state, nextState);
    }

    case DC_NEW_DOWNLOAD_AVAILABLE:
      return update(state, {
        toViewList: { $set: state.toViewList.concat(payload.id) },
      });

    case DC_CLEAR_DOWNLOAD_AVAILABLE:
      return update(state, {
        toViewList: { $set: [] },
      });

    case DC_CREATE_INTERVAL:
      nextState.currentIntervals = {
        $set: {
          ...state.currentIntervals,
          [payload.id]: payload.interval,
        },
      };
      return update(state, nextState);
    default:
      return state;
  }
};
