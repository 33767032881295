import _ from 'lodash';
import update from 'immutability-helper';

import {
  RGA_LOADING_OPTIONS_REQUEST,
  RGA_LOADING_OPTIONS_FAILURE,
  RGA_LOADING_OPTIONS_SUCCESS,
} from 'actions/actionsConstants';
import { deepAreEqual } from 'services/customComparators';
import initialState from 'reducers/report_generator_reducers/initialState';
import { mapOptions } from 'reducers/report_generator_reducers/utils';

function changeRequestStatus(state, filter, value) {
  return {
    ...state,
    requests: update(state.requests, {
      [state.configurableId]: { [filter]: { $set: value } },
    }),
  };
}

function changeFilteredRequestStatus(state, filter, value) {
  return {
    ...state,
    filteredRequests: update(state.filteredRequests, {
      [state.configurableId]: { [filter]: { $set: value } },
    }),
  };
}

export default (state = initialState, action) => {
  switch (action.type) {
    case RGA_LOADING_OPTIONS_REQUEST:
      return changeRequestStatus(state, action.payload.filter, true);

    case RGA_LOADING_OPTIONS_FAILURE:
      return changeRequestStatus(state, action.payload.filter, false);

    case RGA_LOADING_OPTIONS_SUCCESS: {
      const { options, filter, query } = action.payload;
      const configurableId = state.configurableId;
      const newOptions = { ...state.options[configurableId] };

      if (
        newOptions[filter] === undefined ||
        !deepAreEqual(state.queries[configurableId][filter], query)
      ) {
        const formattedOptions = options.data.map((item) =>
          mapOptions(item[0], true),
        );
        newOptions[filter] = _.uniqBy(formattedOptions, 'id');
      } else {
        const formattedOptions = options.data.map((item) =>
          mapOptions(item[0], state.toggle_tracker[configurableId][filter]),
        );
        newOptions[filter] = _.uniqBy(
          [...newOptions[filter], ...formattedOptions],
          'id',
        );
      }

      if (
        state.filteredOptions[configurableId][filter] &&
        state.filteredOptions[configurableId][filter].length > 0
      ) {
        newOptions[filter] = _.differenceBy(
          newOptions[filter],
          state.filteredOptions[configurableId][filter],
          'id',
        );
      }

      return {
        ...state,
        options: update(state.options, {
          [configurableId]: { [filter]: { $set: newOptions[filter] } },
        }),
        initialOptions: update(state.initialOptions, {
          [configurableId]: { [filter]: { $set: newOptions[filter] } },
        }),
        requests: update(state.requests, {
          [configurableId]: { [filter]: { $set: false } },
        }),
        queries: update(state.queries, {
          [configurableId]: { [filter]: { $set: query } },
        }),
        query_totals: update(state.query_totals, {
          [configurableId]: { [filter]: { $set: options.total } },
        }),
        toggle_tracker: update(state.toggle_tracker, {
          [configurableId]: {
            [filter]: {
              $set:
                state.toggle_tracker[configurableId][filter] === undefined ||
                state.toggle_tracker[configurableId][filter],
            },
          },
        }),
      };
    }

    default:
      return state;
  }
};
