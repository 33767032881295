import _ from 'lodash';
import { isEntity } from 'services/investor_profile';

const DEFAULT_COUNTRY = 'United States of America';

const isInvalidCountryChoice = (value, options) => options.find(o => o.name === value);

const countryResetValue = (options) => {
  if (options.find(o => o.name === DEFAULT_COUNTRY)) {
    return DEFAULT_COUNTRY;
  }
  if (options.length > 0) {
    return options[0].name;
  }
  return '';
};

const countryFieldsResets = (profile, countries, originalCountryValues = {}) => {
  const changes = {};

  if (countries.all.length === 0 || _.isEmpty(profile)) {
    return changes;
  }

  const toCheck = {
    section_a_individual_state_country: countries.paf_whitelisted,
    section_a_entity_state_country: countries.entity_permanent_address,
    section_a_mailing_state_country: countries.viewable,
  };

  if (isEntity(profile)) {
    Object.assign(
      toCheck,
      {
        entity_jurisdiction_of_organization_country: countries.viewable,
        entity_domicile_country: countries.domicile,
      }
    );
  }

  Object.keys(toCheck).forEach((key) => {
    const currentValue = profile[key];
    const options = toCheck[key];

    if (
      currentValue !== originalCountryValues[key] &&
      !isInvalidCountryChoice(currentValue, options)
    ) {
      const resetVal = countryResetValue(options);
      changes[key] = resetVal;
    }
  });

  return changes;
};

export default countryFieldsResets;
