export default {
  documents: [],
  documents_count: 0,
  results_per_page: 25,
  current_page: 1,
  sorting_column: 'id',
  sorting_order: 'desc',
  filter_options: {
    white_label_partners: [
      {
        value: '',
      },
    ],
    private_access_funds: [
      {
        value: '',
      },
    ],
    sub_doc_types: [
      {
        value: '',
      },
    ],
    firms: [
      {
        value: '',
      },
    ],
    limit_codes: [''],
  },
  selected_paf_filter: null,
  selected_firm_filter: null,
  selected_wlp_filter: null,
  selected_document_type_filter: null,
  selected_limit_code_filter: null,
  selected_sub_doc_type_filter: null,
  documentTypes: [],
  funds: [],
  firms: [],
  whiteLabelPartners: [],
  requiredDocumentTypes: [],
  requiredDocumentTypesIsLoading: true,
  subDocTypes: [],
  signerTypes: {
    entity: [],
    individual: [],
  },
  accountTypes: {
    account_types: [],
    specific_account_types: [],
  },
  libraryTemplates: [],
};
