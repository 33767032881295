import {
  FLEXIBLE_FEED_DASHBOARD_SET_SNACKBAR,
  FLEXIBLE_FEED_DASHBOARD_CLEAR_SNACKBAR,
} from 'actions/actionsConstants';

import { updateState } from '../helpers';
import initialState from '../initialState';

export default (state = initialState, action) => {
  switch (action.type) {
    case FLEXIBLE_FEED_DASHBOARD_SET_SNACKBAR:
      return updateState(state, action.payload, ({ message, severity }) => ({
        mainSnackbar: { open: true, message, severity },
      }));
    case FLEXIBLE_FEED_DASHBOARD_CLEAR_SNACKBAR:
      return updateState(state, action.payload, () => ({
        mainSnackbar: { open: false, message: '', severity: undefined },
      }));
    default:
      return state;
  }
};
