import React from 'react';
import {
  Box,
  LinearProgress,
} from '@icapitalnetwork/supernova-core';
import { number } from 'prop-types';

const ProgressBar = ({ progress }) => (
  <Box sx={{ marginBottom: '20px' }}>
    <LinearProgress variant="determinate" value={progress} aria-label="progress bar" />
  </Box>
);

ProgressBar.propTypes = {
  progress: number.isRequired,
};

export default ProgressBar;
