import { getRequest } from 'actions/utils';

import { createCRUD, createRequestTypes } from 'actions/helpers';

const BASE_NAME = 'massMailerFirms';

export const MASS_MAILER_FIRMS = createCRUD(BASE_NAME, {
  LIST: createRequestTypes(`${BASE_NAME}_LIST`),
  ALL: createRequestTypes(`${BASE_NAME}_ALL`),
});

export const MASS_MAILER_FIRMS_LIST = {
  UNMOUNT: `${BASE_NAME}_UNMOUNT`,
};

export const getAllFirmsAutocomplete = (query) => getRequest({
  showSpinner: false,
  url: 'firms/autocomplete',
  actions: MASS_MAILER_FIRMS.ALL,
  params: { query },
});

export const fetchMassMailerFirms = (massMailerDocumentId) => getRequest({
  showSpinner: false,
  url: 'mass_mailer/mass_mailer_emails/firms',
  params: { mass_mailer_document_id: massMailerDocumentId },
  actions: MASS_MAILER_FIRMS.LIST,
});
