import axios from 'axios';

const url = 'investment_custodian_forms';
const config = { withCredentials: true };
const updateInvestmentCustodianForm = (id, flag) => (
  axios.patch(`${url}/${id}`, {
    investment_custodian_form: {
      id,
      custodian_forms_received: flag,
    },
  }, config)
    .then((response) => Promise.resolve(response.data.investment_aml_approval))
    .catch((error) => Promise.reject(error))
);

export default {
  updateInvestmentCustodianForm,
};
