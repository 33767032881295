function normalizeCurrentPage(newValue, state) {
  const lastPage = Math.ceil(state.documents_count / state.results_per_page);
  let page = newValue;
  if (page > lastPage) {
    page = lastPage;
  }

  return Math.max(page, 1);
}

export default normalizeCurrentPage;
