import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import IcnModal from 'components/shared/modals/IcnModal';
import actions from 'actions';
import { findActivePopup } from 'services/notifications/oneTimePopup';
import moment from 'moment';
import parse from 'html-react-parser';

import styles from './OneTimePopup.module.scss';

class OneTimePopup extends Component {
  static propTypes = {
    popups: PropTypes.array,
    activePopup: PropTypes.object,
    markOneTimePopupAsRead: PropTypes.func,
  };

  static defaultProps = {
    popups: [],
    activePopup: null,
    markOneTimePopupAsRead: undefined,
  };

  render() {
    if (!this.props.activePopup) {
      return null;
    }
    return (
      <IcnModal
        modalOpen
        header={parse(this.props.activePopup.title)}
        subHeader={parse(moment(this.props.activePopup.publish_date).format(
          'MMMM D, YYYY'
        ))}
        body={parse(this.props.activePopup.body)}
        buttons={[
          {
            text: 'Ok',
            styleType: 'primary',
            callback: this.props.markOneTimePopupAsRead,
          },
        ]}
        onOverlayClick={this.props.markOneTimePopupAsRead}
        modalClassName={styles.one_time_popup_content}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    popups: state.oneTimePopup.popups,
    activePopup: findActivePopup(state),
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    markOneTimePopupAsRead: () => {
      dispatch(actions.markOneTimePopupAsRead());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OneTimePopup);
