import update from 'immutability-helper';
import { RG_SET_SNOWFLAKE_CLIENTS } from 'actions/actionsConstants';
import initialState from 'reducers/report_generator_reducers/initialState';

export default (state = initialState, action) => {
  switch (action.type) {
    case RG_SET_SNOWFLAKE_CLIENTS:
      return update(state, {
        snowflakeClients: { $set: action.payload.snowflake_clients },
      });
    default:
      return state;
  }
};
