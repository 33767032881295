import { noValue } from 'services/investor_profile';

/**
 *  Everytime the contact card email is changed, the associated user login
 * is regenerated. In order to disallow someone to edit the contact card email
 * as an empty string (messing up the users login), check if the email was
 * already set previously.
 *
 * Note: Contact cards can be created with an empty string for interested parties
 */
export default (previousEmail, value) => (noValue(value) && !noValue(previousEmail) ? 'This field is required.' : undefined);
