/* global globalThis */

import React, { useState, useEffect, useCallback } from 'react';
import { string, arrayOf, shape } from 'prop-types';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faExclamationTriangle, faTimes } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';

import styles from './IncidentStatusAlertBar.module.scss';

let localStorage = {};
try {
  ({ localStorage } = globalThis);
} catch {
  // no space left
}

export default function IncidentStatusAlertsBar({ status = 'active', alerts, className }) {
  const [openAlerts, setOpenAlerts] = useState();

  const cancel = useCallback(() => {
    setOpenAlerts();

    try {
      const alertsData = JSON.parse(localStorage.icnIncidentStatusAlert || '{}');
      alerts.forEach(({ id, title }) => {
        alertsData[`${title}-${id}`] = `${status} seen`;
      });
      localStorage.icnIncidentStatusAlert = JSON.stringify(alertsData);
    } catch (error) {
      // either our localStorage was deleted somehow, or we exceeded our quota
    }
  }, [alerts, status]);

  useEffect(() => {
    let newOpenAlerts = alerts;

    const alertsData = JSON.parse(localStorage?.icnIncidentStatusAlert || '{}');
    newOpenAlerts = alerts.filter(({ id, title }) => {
      const alertData = alertsData[`${title}-${id}`];
      return alertData !== `${status} seen`;
    });

    setOpenAlerts(newOpenAlerts);
  }, [alerts, status]);

  let icon = faExclamationTriangle;
  let headerText = 'Alert';
  if (status === 'resolved') {
    icon = faCheck;
    headerText = 'Resolved';
  }

  return !!openAlerts?.length && (
    <div
      className={classNames(className, styles.bar, {
        [styles.barActive]: status !== 'resolved',
        [styles.barResolved]: status === 'resolved',
      })}
    >
      <FAIcon icon={faTimes} className={classNames(styles.closeButton, styles.closeButtonSpacer)} />

      <div className={styles.barInner}>
        <div className={styles.header}>
          <FAIcon
            icon={icon}
            data-test-id="isa-icon"
            className={classNames(styles.alertIcon, { [styles.alertIconResolved]: status === 'resolved' })}
          />
          <div className={styles.headerTxt}>{headerText}</div>
        </div>

        <span className={styles.alertsText}>
          {openAlerts.map(({ id, title, description }) => (
            <div className={styles.alertText} key={`${title}-${id}`}>
              <span className={styles.title}>{`${title}: `}</span>
              <span className={styles.description}>{description}</span>
            </div>
          ))}
        </span>
      </div>

      <FAIcon icon={faTimes} className={styles.closeButton} data-test-id="isa-close" onClick={cancel} />
    </div>
  );
}
IncidentStatusAlertsBar.propTypes = {
  alerts: arrayOf(shape({ title: string, description: string })),
  status: string,
  className: string,
};
IncidentStatusAlertsBar.defaultProps = { alerts: [], status: undefined, className: '' };
