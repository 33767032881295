/* eslint-disable max-statements */
import {
  isUndefined, isEmpty,
} from 'lodash';
import {
  isTextFilled,
} from 'containers/registration/complete_profile/CompleteProfileValidations';
import {
  registeredRepresentativeSeries,
  isRegisteredRepresentative,
  isOtherTypeInstitution,
  otherTypeInstitutionalInvestor,
  firmRoleIfOther,
  registeredAsBDOrIA,
  firmAssets,
  advisorCrdNumber,
  totalAssets,
} from './complete_profile/profileData';
import { getIsOtherTypeInstInvNeeded } from './complete_profile/TwoPageCompleteProfileValidations';

const REGISTRATION_SESSION_KEY = 'registration';

const saveSessionStorage = (initialState, registrationStep, newUser) => {
  const registrationState = initialState;
  registrationState.newUser = newUser;
  registrationState.currentStep = registrationStep + 1;

  window.sessionStorage.setItem(REGISTRATION_SESSION_KEY, JSON.stringify(registrationState));
};

const setSessionStorage = (registrationState) => {
  window.sessionStorage.setItem(REGISTRATION_SESSION_KEY, JSON.stringify(registrationState));
};

const getSessionStorage = () => window.sessionStorage.getItem(REGISTRATION_SESSION_KEY);

const removeSessionStorage = () => window.sessionStorage.removeItem(REGISTRATION_SESSION_KEY);

const isEnterKey = ({ key, keyCode }) => (key === 'Enter' || keyCode === 13);

// User manually enters a firm
const showFirmType = (profile) => 
  (
    (!isEmpty(profile.firm_name) && !isUndefined(profile.added_firm) && profile.added_firm) ||
    isEmpty(profile.firm_name)
  );

const formatRegisteredRepSeries = (profile) => {
  let profileToSubmit = { ...profile };

  if (profile[isRegisteredRepresentative.question_key]) {
    const seriesResponses = registeredRepresentativeSeries.series_options.reduce((acc, option) => {
      const boolValue = profile[registeredRepresentativeSeries.question_key].includes(option.value);
      return { ...acc, [option.value]: boolValue };
    }, {});
    profileToSubmit = { ...profileToSubmit, ...seriesResponses };
  } else {
    const seriesResponses = registeredRepresentativeSeries.series_options.reduce((acc, option) => (
      { ...acc, [option.value]: false }
    ), {});
    profileToSubmit = { ...profileToSubmit, ...seriesResponses };
  }
  delete profileToSubmit[registeredRepresentativeSeries.question_key];

  return profileToSubmit;
};

const formatOtherInstType = (profile) => {
  let profileToSubmit = { ...profile };

  const roleRadiosAreAllFalse = getIsOtherTypeInstInvNeeded(profileToSubmit);
  const key = otherTypeInstitutionalInvestor.question_key;
  const options = otherTypeInstitutionalInvestor.institution_options;
  if (roleRadiosAreAllFalse) {
    if (profile[isOtherTypeInstitution.question_key]) {
      const responses = options.reduce((acc, option) => {
        const boolValue = profile[key] === option.value;
        return { ...acc, [option.value]: boolValue };
      }, {});
      profileToSubmit = { ...profileToSubmit, ...responses };
      if (profileToSubmit[otherTypeInstitutionalInvestor.question_key] !== 'any_other_entity') {
        delete profileToSubmit[totalAssets.question_key];
      }
    } else {
      delete profileToSubmit[otherTypeInstitutionalInvestor.question_key];
      delete profileToSubmit[totalAssets.question_key];
    }
  } else {
    delete profileToSubmit[isOtherTypeInstitution.question_key];
    delete profileToSubmit[otherTypeInstitutionalInvestor.question_key];
    delete profileToSubmit[totalAssets.question_key];
  }

  return profileToSubmit;
};

const generateTwoPageProfile = (profile) => {
  let profileToSubmit = { ...profile };

  if (profile.role !== 'Other') {
    delete profileToSubmit[firmRoleIfOther.question_key];
  }
  if (!profile.firm_confirm_us_location) {
    delete profileToSubmit[registeredAsBDOrIA.question_key];
    delete profileToSubmit.state;
  }
  if (!isTextFilled(profile[firmAssets.question_key])) {
    delete profileToSubmit[firmAssets.question_key];
  }
  if (!isTextFilled(profile[advisorCrdNumber.question_key])) {
    delete profileToSubmit[advisorCrdNumber.question_key];
  }

  // Registered Representative Series 1, 6, 7 Responses
  profileToSubmit = formatRegisteredRepSeries(profileToSubmit);

  // Other Institutional Type
  profileToSubmit = formatOtherInstType(profileToSubmit);

  delete profileToSubmit[isRegisteredRepresentative.question_key];

  return profileToSubmit;
};

const generateUserIdentificationInformation = (information) => {
  let informationToSubmit = { ...information };

  // Page Two
  if (informationToSubmit.role !== 'Other') {
    delete informationToSubmit[firmRoleIfOther.question_key];
  }

  // Page Three
  informationToSubmit = formatRegisteredRepSeries(informationToSubmit);
  if (!isTextFilled(informationToSubmit[advisorCrdNumber.question_key])) {
    delete informationToSubmit[advisorCrdNumber.question_key];
  }

  // Page Four
  informationToSubmit = formatOtherInstType(informationToSubmit);

  delete informationToSubmit[isRegisteredRepresentative.question_key];

  return informationToSubmit;
};

export {
  saveSessionStorage,
  setSessionStorage,
  getSessionStorage,
  removeSessionStorage,
  isEnterKey,
  generateTwoPageProfile,
  showFirmType,
  generateUserIdentificationInformation,
};
