import { createSelector } from 'reselect';
import IcnIdentity from 'services/icn_identity/icnIdentity';
import { getUser, getWhiteLabelPartner } from 'selectors/icnBootstrapSelectors';

export { getUser as user, whiteLabelPartner } from 'selectors/icnBootstrapSelectors';
export const pafList = (state) => state.clientManagement.pafList;
export const currentPaf = (state) => state.clientManagement.currentPaf;
export const selectedAdvisorId = (state) => state.clientManagement.selectedAdvisorId;
export const showCanceledInvestments = (state) => state.clientManagement.showCanceledInvestments;
export const hasCanceledInvestments = (state) => state.clientManagement.hasCanceledInvestments;
export const canCreateProspectInvestments = (state) => state.clientManagement.canCreateProspectInvestments;
export const createInvestmentFlow = (state) => state.clientManagement.createInvestmentFlow;
export const transferInvestmentsList = (state) => state.clientManagement.transferInvestmentsList;
export const searchTerm = (state) => state.clientManagement.searchTerm;
export const searchType = (state) => state.clientManagement.searchType;
export const investmentsByState = (state) => state.clientManagement.investmentsByState;
export const redemptionsTotals = (state) => state.clientManagement.redemptionsTotals;

export const whiteLabelPartnerId = createSelector(
  getWhiteLabelPartner,
  (wlp) => wlp.id
);

export const currentPafId = createSelector(
  currentPaf,
  (paf) => paf?.id
);

export const exportDocumentFlags = createSelector(
  currentPaf,
  (paf) => ({ hasPending: paf.has_subscription_document_signed })
);

export const hasExports = createSelector(
  currentPaf,
  (paf) => paf?.has_exports
);

export const getSendSubDocDelayMap = createSelector(
  currentPaf,
  (paf) => paf?.send_sub_doc_delay_map
);

export const canExport = createSelector(getUser, (user) => (
  IcnIdentity.isPafAdmin(user) ||
  IcnIdentity.isRiaOrBranchManager(user) ||
  (IcnIdentity.isHomeOffice(user) && IcnIdentity.canExportSubscriptionFiles(user))
));
