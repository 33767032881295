import { createAction } from 'redux-actions';
import {
  getRequest,
  patchRequest,
} from 'actions/utils';
import {
  getRequest as genericGetRequest,
  postRequest as genericPostRequest,
  patchRequest as genericPatchRequest,
  deleteRequest as genericDeleteRequest,
} from 'services/apiRequest';
import {
  NR_FETCH_NOMINEE_REVIEW_LIST,
  NR_FETCH_NOMINEE_REVIEW_DETAILS,
  NR_NOMINEE_REVIEW_MODAL,
  NR_NOMINEE_REVIEW_MODAL_WITH_CONTEXT,
  NR_NOMINEE_REVIEW_EXPORT_HISTORY,
  NR_FETCH_SR_NOMINEE_REVIEW_DETAILS,
  NR_CLEAR_SR_NOMINEE_REVIEW_DETAILS,
  NR_NOMINEE_REVIEW_PAGE_TABLE,
  NR_NOMINEE_REVIEW_PER_PAGE_TABLE,
  NR_NOMINEE_REVIEW_ACCOUNT_ID_FILTER,
  NR_NOMINEE_FIELD_OPTIONS,
  NR_UPDATE_NOMINEE_REVIEW_ALLOCATION,
} from '../actionsConstants';

const nomineeReviewCloseModalPayload = () => (undefined);
const nomineeReviewCloseModalWithCtxPayload = () => ({ type: undefined, context: undefined })

const setNomineeReviewList = createAction(NR_FETCH_NOMINEE_REVIEW_LIST);
const setNomineeReviewDetails = createAction(NR_FETCH_NOMINEE_REVIEW_DETAILS);
const setNomineeReviewModal = createAction(NR_NOMINEE_REVIEW_MODAL);
const setNomineeReviewModalWithContext = createAction(NR_NOMINEE_REVIEW_MODAL_WITH_CONTEXT);
const closeNomineeReviewModal = createAction(NR_NOMINEE_REVIEW_MODAL, nomineeReviewCloseModalPayload);
const closeNomineeReviewModalWithCtx = createAction(
  NR_NOMINEE_REVIEW_MODAL_WITH_CONTEXT,
  nomineeReviewCloseModalWithCtxPayload
);
const nomineeReviewSetExportDocsList = createAction(NR_NOMINEE_REVIEW_EXPORT_HISTORY);
const setSRNomineeReviewDetails = createAction(NR_FETCH_SR_NOMINEE_REVIEW_DETAILS);
const clearCMNomineeReviewDetail = createAction(NR_CLEAR_SR_NOMINEE_REVIEW_DETAILS);
const setNomineeReviewPage = createAction(NR_NOMINEE_REVIEW_PAGE_TABLE);
const setNomineeReviewPerPage = createAction(NR_NOMINEE_REVIEW_PER_PAGE_TABLE);
const setAccountIdFilter = createAction(NR_NOMINEE_REVIEW_ACCOUNT_ID_FILTER);
const setNomineeFieldOptions = createAction(NR_NOMINEE_FIELD_OPTIONS);
const updateNomineeReviewAllocation = createAction(NR_UPDATE_NOMINEE_REVIEW_ALLOCATION);

const fetchNomineeReviewList = (investmentId, page, perPage, search) => (dispatch) => (
  dispatch(getRequest({
    url: `investments/${investmentId}/nominee_allocations`,
    params: { page, per_page: perPage, search },
    onSuccess: setNomineeReviewList,
    showSpinner: false,
  }))
);

const fetchNomineeDetails = (investmentId, showSpinner = true) => (dispatch) => (
  dispatch(getRequest({
    url: `investments/${investmentId}/nominee_allocations/details`,
    showSpinner,
    onSuccess: setNomineeReviewDetails,
  }))
);

const editNomineeReview = (investmentId, nomineeReviewId, data, showSpinner = true) => (dispatch) => (
  dispatch(patchRequest({
    url: `investments/${investmentId}/nominee_allocations/${nomineeReviewId}`,
    data,
    showSpinner,
  }))
);

const nomineeReviewFetchExportHistory = (investmentId, page, perPage) => (dispatch) => (
  dispatch(getRequest({
    url: '/document_exports',
    params: {
      document_types: ['NomineeAllocationExportResultCSV'],
      investment_id: investmentId,
      page,
      per_page: perPage,
    },
    onSuccess: nomineeReviewSetExportDocsList,
  }))
);

const fetchSRNomineeDetails = (investmentId) => (dispatch) => (
  dispatch(getRequest({
    url: `investments/${investmentId}/nominee_allocations/nominee_tooltip_info`,
    onSuccess: (data) => setSRNomineeReviewDetails({ data, investmentId }),
    showSpinner: false,
  }))
);

const fetchNomineeFieldOptions = (investmentId) => (dispatch) => (
  dispatch(getRequest({
    url: `investments/${investmentId}/nominee_allocations/nominee_field_options`,
    onSuccess: setNomineeFieldOptions,
  }))
);

const createNomineeReview = (investmentId, data) => (
  genericPostRequest({
    url: `investments/${investmentId}/nominee_allocations`,
    data,
  })
);

const uploadNomineeReview = (investmentId, data) => genericPostRequest({
  url: `investments/${investmentId}/nominee_allocations/upload`,
  data,
});

const exportNomineeReview = (investmentId) => genericGetRequest({
  url: `investments/${investmentId}/nominee_allocations/export`,
});

const submitNomineeReview = (investmentId) => genericPatchRequest({
  url: `investments/${investmentId}/nominee_allocations/submit_allocation`,
  data: {
    reload_url: window.location.href,
  },
});

const unsubmitNomineeReview = (investmentId) => genericPatchRequest({
  url: `investments/${investmentId}/nominee_allocations/unsubmit_allocation`,
});

const lockNomineeReview = (investmentId) => genericPatchRequest({
  url: `investments/${investmentId}/nominee_allocations/lock_allocation`,
  data: {
    reload_url: window.location.href,
  },
});

const deleteNomineeReview = (investmentId, nomineeReviewId) => genericDeleteRequest({
  url: `investments/${investmentId}/nominee_allocations/${nomineeReviewId}`,
});

const downloadNomineeTemplate = () => genericGetRequest({
  url: '/icn_documents/file_path',
  params: { doctype: 'nominee_upload_template' },
});

const downloadDiscretionaryTemplate = (investmentId) => genericGetRequest({
  url: `investments/${investmentId}/nominee_allocations/discretionary_upload_template`,
});

const fetchContactCards = (investmentId) => genericGetRequest({
  url: `investments/${investmentId}/nominee_allocations/contact_cards`,
});

const fetchInvestorProfiles = (investmentId, contactCardId) => genericGetRequest({
  url: `investments/${investmentId}/nominee_allocations/investor_profiles`,
  params: { contact_card_id: contactCardId },
});

export default {
  fetchNomineeReviewList,
  fetchNomineeDetails,
  uploadNomineeReview,
  exportNomineeReview,
  submitNomineeReview,
  unsubmitNomineeReview,
  lockNomineeReview,
  setNomineeReviewModal,
  closeNomineeReviewModal,
  closeNomineeReviewModalWithCtx,
  deleteNomineeReview,
  createNomineeReview,
  editNomineeReview,
  setNomineeReviewModalWithContext,
  nomineeReviewFetchExportHistory,
  fetchSRNomineeDetails,
  setNomineeReviewPage,
  setNomineeReviewPerPage,
  setAccountIdFilter,
  fetchNomineeFieldOptions,
  clearCMNomineeReviewDetail,
  downloadNomineeTemplate,
  updateNomineeReviewAllocation,
  downloadDiscretionaryTemplate,
  fetchContactCards,
  fetchInvestorProfiles,
};
