import DeleteEditButtonCellRenderer
  from 'components/shared/cell_renderers/DeleteEditButtonCellRenderer/DeleteEditButtonCellRenderer';
import _ from 'lodash';
import MultiItemRenderer from 'components/shared/cell_renderers/MultiItemRenderer/MultiItemRenderer';
import { createFilterOption } from 'services/dropdown_filter';
import investmentStatuses from 'constants/investmentStatuses';
import styles from 'containers/integration_partners_dashboard/table/IPTable.module.scss';

const getState = (state) => state.integrationPartnersDashboard;

export const integrationPartnerRulesSelector = (state) => getState(state).rules;
export const integrationPartnerFundsSelector = (state) => getState(state).funds;
export const integrationPartnerWlpsSelector = (state) => getState(state).wlps;
export const integrationPartnerTriggersSelector = (state) => getState(state).triggers;
export const integrationPartnerDocTypesSelector = (state) => getState(state).document_types;
export const integrationPartnerIntPartnerSelector = (state) => getState(state).integration_partners;
export const integrationPartnerPaginationSelector = (state) => getState(state).pagination;
export const integrationPartnerAvailableFilterSelector = (state) => {
  const availableFilters = getState(state).available_filters;
  return availableFilters || null;
};

export const integrationPartnerFundsFilterSelector = (state) => {
  const availableFilters = integrationPartnerAvailableFilterSelector(state);
  if (availableFilters == null) {
    return [];
  }

  return _.map(availableFilters.private_access_funds,
    (paf) => createFilterOption(paf.fund_name, 'private_access_fund_id', paf.id));
};

export const integrationPartnerIntegrationPartnerFilterSelector = (state) => {
  const availableFilters = integrationPartnerAvailableFilterSelector(state);
  if (availableFilters == null) {
    return [];
  }

  return _.map(availableFilters.integration_partners,
    (item) => createFilterOption(item.name, 'integration_partners.id', item.id));
};

export const integrationPartnerStatusTriggersFilterSelector = (state) => {
  const availableFilters = integrationPartnerAvailableFilterSelector(state);
  if (availableFilters == null) {
    return [];
  }

  return _.map(availableFilters.trigger_status,
    (item) => createFilterOption(investmentStatuses[item], 'trigger_status', item));
};

export const integrationPartnerDocTypesFilterSelector = (state) => {
  const availableFilters = integrationPartnerAvailableFilterSelector(state);
  if (availableFilters == null) {
    return [];
  }

  return _.map(availableFilters.doc_types,
    (item) => createFilterOption(item.name, 'required_document_types.id', item.id));
};


export const integrationPartnerWlpFilterSelector = (state) => {
  const availableFilters = integrationPartnerAvailableFilterSelector(state);
  if (availableFilters == null) {
    return [];
  }
  return _.map(availableFilters.white_label_partners,
    (item) => createFilterOption(item.name, 'white_label_partners.id', item.id));
};


export const integrationPartnerSelectedFundsSelector = (state) => {
  const selectedFunds = getState(state).selectedFunds;
  return selectedFunds || [];
};
export const integrationPartnerSelectedIntegrationPartnerSelector = (state) => {
  const selectedIntegrationPartners = getState(state).selectedIntegrationPartners;
  return selectedIntegrationPartners || [];
};
export const integrationPartnerSelectedStatusTriggersSelector = (state) => {
  const selectedStatusTriggers = getState(state).selectedStatusTriggers;
  return selectedStatusTriggers || [];
};
export const integrationPartnerSelectedDocTypesSelector = (state) => {
  const selectedDocTypes = getState(state).selectedDocTypes;
  return selectedDocTypes || [];
};

export const integrationPartnerSelectedWlpsSelector = (state) => {
  const selectedWlps = getState(state).selectedWlps;
  return selectedWlps || [];
};

export const getAllSelectedOptions = (state) => {
  const selectedOptions = [];
  selectedOptions.push(...integrationPartnerSelectedDocTypesSelector(state));
  selectedOptions.push(...integrationPartnerSelectedStatusTriggersSelector(state));
  selectedOptions.push(...integrationPartnerSelectedIntegrationPartnerSelector(state));
  selectedOptions.push(...integrationPartnerSelectedFundsSelector(state));
  selectedOptions.push(...integrationPartnerSelectedWlpsSelector(state));
  return selectedOptions;
};

export const configStateSelector = (state, config) => getState(state)[config.redux_selector];

export const sharedGridProps = {
  rowClassRules: {
    [styles.even_row]: entry => entry.node.rowIndex % 2 === 0,
    [styles.odd_row]: entry => entry.node.rowIndex % 2 !== 0,
  },
  defaultColDef: {
    suppressMovable: true,
    suppressMenu: true,
    headerClass: styles.grid_header,
    cellClass: styles.grid_cell,
  },
  suppressContextMenu: true,
  frameworkComponents: { DeleteEditButtonCellRenderer, MultiItemRenderer },
  headerHeight: 50,
  rowHeight: 40,
  domLayout: 'autoHeight',
};
