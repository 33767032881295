/* eslint-disable max-statements */
/* eslint-disable max-lines-per-function */
import {
  IR_ADD_INVESTOR,
  IR_SET_INVESTOR,
  IR_SET_INVESTORS,
  IR_SET_MANAGERS,
  IR_SET_QUESTIONNAIRE,
  IR_SET_QUESTIONNAIRE_INITIAL_STATE,
  IR_SET_QUESTIONNAIRE_OPTIONS,
  IR_SET_SUCCESS_TOAST_MESSAGE,
  IR_SET_ERROR_TOAST_MESSAGE,
  IR_CLEAN_TOAST_MESSAGES,
  IR_SET_IS_FETCHING,
} from 'actions/actionsConstants';
import update from 'immutability-helper';

export const initialState = {
  investors: [],
  managers: [],
  newInvestor: {},
  investor: {},
  questionnaire: {},
  questionnaireInitialState: {},
  questionnaireOps: {},
  countries: [],
  successToastMessage: '',
  errorToastMessage: {
    title: '',
    message: '',
  },
  isFetching: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case IR_CLEAN_TOAST_MESSAGES: {
      return update(state, {
        successToastMessage: {
          $set: '',
        },
        errorToastMessage: {
          title: { $set: '' },
          message: { $set: '' },
        },
      });
    }
    case IR_SET_SUCCESS_TOAST_MESSAGE: {
      return update(state, {
        successToastMessage: {
          $set: action.payload,
        },
        investor: { $set: {} },
        questionnaire: { $set: {} },
        questionnaireOps: { $set: {} },
      });
    }
    case IR_SET_ERROR_TOAST_MESSAGE: {
      return update(state, {
        errorToastMessage: {
          title: {
            $set: action.payload.title,
          },
          message: {
            $set: action.payload.message,
          },
        },
        investor: { $set: {} },
        questionnaire: { $set: {} },
        questionnaireOps: { $set: {} },
      });
    }
    case IR_SET_QUESTIONNAIRE: {
      return update(state, {
        questionnaire: (questionnaire) => update(questionnaire || {}, {
          $merge: action.payload,
        }),
      });
    }
    case IR_SET_QUESTIONNAIRE_INITIAL_STATE: {
      return update(state, {
        questionnaireInitialState: (questionnaire) => update(questionnaire || {}, {
          $merge: action.payload,
        }),
      });
    }
    case IR_SET_QUESTIONNAIRE_OPTIONS: {
      return update(state, {
        questionnaireOps: {
          $set: action.payload,
        },
      });
    }
    case IR_ADD_INVESTOR: {
      return update(state, {
        newInvestor: {
          $set: action.payload,
        },
      });
    }
    case IR_SET_INVESTORS: {
      return update(state, {
        investors: {
          $set: action.payload,
        },
        isFetching: {
          $set: state.isFetching - 1,
        },
      });
    }
    case IR_SET_INVESTOR: {
      return update(state, {
        investor: {
          $set: action.payload,
        },
      });
    }
    case IR_SET_MANAGERS: {
      return update(state, {
        managers: {
          $set: action.payload,
        },
        isFetching: {
          $set: state.isFetching - 1,
        },
      });
    }
    case IR_SET_IS_FETCHING: {
      return update(state, {
        isFetching: {
          $set: action.payload,
        },
      });
    }
    default: {
      return state;
    }
  }
};
