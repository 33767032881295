import { getRequest } from 'actions/utils';
import { createAction } from 'redux-actions';

import {
  SD_SELECT_FUNDS,
  SD_SET_FUNDS,
  SD_SET_SELECTED_VIEW_MODE,
  SD_SET_DRILLDOWN,
  SD_CLEAR_DRILLDOWN,
  SD_SET_SUMMARY,
} from '../actionsConstants';

const sdSelectFunds = createAction(SD_SELECT_FUNDS);
const sdSetFunds = createAction(SD_SET_FUNDS);
const sdSetSelectedViewMode = createAction(SD_SET_SELECTED_VIEW_MODE);
const sdSetDrilldown = createAction(SD_SET_DRILLDOWN);
const sdClearDrilldown = createAction(SD_CLEAR_DRILLDOWN);
const sdSetSummary = createAction(SD_SET_SUMMARY);

const sdFetchFunds = () => (
  getRequest({
    showSpinner: true,
    url: 'private_access_funds/for_sales_reporting',
    onSuccess: sdSetFunds,
  })
);

const sdLoadSummary = (params, showSpinner = false) => (
  getRequest({
    showSpinner,
    url: 'fund_sales/summary',
    params,
    onSuccess: sdSetSummary,
  })
);

const sdLoadMetrics = (params, showSpinner = false) => (
  getRequest({
    showSpinner,
    url: 'fund_sales/metrics',
    params,
  })
);

export default {
  sdFetchFunds,
  sdSetFunds,
  sdSelectFunds,
  sdSetSelectedViewMode,
  sdSetDrilldown,
  sdClearDrilldown,
  sdLoadSummary,
  sdLoadMetrics,
};
