import {
  RGA_OPEN_FIELD_FILTER,
  RGA_CLOSE_FIELD_FILTER,
  RGA_ADD_FIELD_FILTER,
} from 'actions/actionsConstants';

import { getCurrentConfigId } from 'reducers/report_generator_reducers/selectors';

import { actionFactory } from 'services/actions';

import crudActions from '../crudActions';
import configFieldActions from '../configFieldActions';

const addFilter = actionFactory(RGA_ADD_FIELD_FILTER);

export const reportGeneratorConfigsOpenFieldFilter = actionFactory(
  RGA_OPEN_FIELD_FILTER,
);
export const reportGeneratorConfigsCloseFieldFilter = actionFactory(
  RGA_CLOSE_FIELD_FILTER,
);

export const reportGeneratorConfigsAddFieldFilter = (filter) => {
  return (dispatch, _getState) => {
    dispatch(addFilter(filter));
    dispatch(crudActions.saveReportGeneratorConfigs(null));
  };
};

export const reportGeneratorConfigsSelectAllFields =
  () => (dispatch, getState) => {
    const configId = getCurrentConfigId(getState());

    dispatch(configFieldActions.selectAllFields(configId));
  };

export const reportGeneratorConfigsRemoveFilterFields =
  () => (dispatch, getState) => {
    const configId = getCurrentConfigId(getState());

    dispatch(configFieldActions.removeAllFields(configId));
  };
