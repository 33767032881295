// Investment Opportunities
export const IO_COMPONENT_UNMOUNT = 'IO_COMPONENT_UNMOUNT';
export const IO_SET_FUNDS = 'IO_SET_FUNDS';
export const IO_SET_PAFS = 'IO_SET_PAFS';
export const IO_SET_CURRENT_PAGE = 'IO_SET_CURRENT_PAGE';

export const IO_SET_SEARCH_TERMS = 'IO_SET_SEARCH_TERMS';
export const IO_UPDATE_SHOW_FILTERS = 'IO_UPDATE_SHOW_FILTERS';
export const IO_SET_PAGE_ACTIONS = 'IO_SET_PAGE_ACTIONS';
export const IO_SET_IS_LOADING = 'IO_SET_IS_LOADING';
export const IO_SET_LOADING_FIRST_PAGE = 'IO_SET_LOADING_FIRST_PAGE';
export const IO_SET_REQUEST_MADE = 'IO_SET_REQUEST_MADE';
export const IO_SET_ROWS_PER_PAGE = 'IO_SET_ROWS_PER_PAGE';
export const SET_FUND_FOLLOW = 'SET_FUND_FOLLOW';

export const IO_SET_RESULTS_PER_PAGE = 'IO_SET_RESULTS_PER_PAGE';

// List Opportunities
export const LO_COMPONENT_UNMOUNT = 'LO_COMPONENT_UNMOUNT';
export const LO_SET_COLLECTIONS = 'LO_SET_COLLECTIONS';
export const LO_SET_USER_HAS_INVESTOR_PROFILE = 'LO_SET_USER_HAS_INVESTOR_PROFILE';
export const LO_SET_SHOW_FLASH_REPORTS = 'LO_SET_SHOW_FLASH_REPORTS';
export const LO_SET_IS_INVESTOR_PROFILE_COMPLETED = 'LO_SET_IS_INVESTOR_PROFILE_COMPLETED';
