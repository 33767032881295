import { actionFactory } from 'services/actions';
import {
  RGA_SAVE_FILTER_QUERY,
  RGA_CLOSE_FIELD_FILTER,
} from 'actions/actionsConstants';

import { postRequest } from 'actions/utils';

import {
  getFilteredOptionsForFilter,
  getQueryTotalsForFilter,
  getOptionsForFilter,
  getRequestForFilter,
  getQueryForFilter,
} from 'reducers/report_generator_reducers/selectors';

import { deepAreEqual } from 'services/customComparators';

import {
  loadingOptionsRequest,
  loadingOptionsSuccess,
  loadingOptionsFailure,
} from './optionActions';
import configFieldActions from '../configFieldActions';

const BASE_URL = 'report_generator/config_fields';
const saveQuery = actionFactory(RGA_SAVE_FILTER_QUERY);
const closeFilter = actionFactory(RGA_CLOSE_FIELD_FILTER);

export const reportGeneratorConfigsSaveFilterQuery =
  (filter, fieldId) => (dispatch) => {
    dispatch(saveQuery(filter));
    dispatch(configFieldActions.handleSaveFilter(filter.filter, fieldId));
    dispatch(closeFilter(filter));
  };

export const reportGeneratorConfigsAvailableOptions =
  (configFieldId, label, query, perPage = 15) =>
  (dispatch, getState) => {
    const totals = getQueryTotalsForFilter(getState(), label);
    const options = getOptionsForFilter(getState(), label);
    const isARequestOutbound = getRequestForFilter(getState(), label);
    const lastKnownQuery = getQueryForFilter(getState(), label);
    const filteredOptions =
      getFilteredOptionsForFilter(getState(), label) || [];

    const url = `${BASE_URL}/${configFieldId}/search`;

    let page = deepAreEqual(lastKnownQuery, query)
      ? Math.floor(options.length / perPage) + 1
      : 1;
    if (options.length === 0 && filteredOptions.length >= 200 && page === 1) {
      page = Math.floor(filteredOptions.length / perPage) + 1;
    }

    if (
      isARequestOutbound ||
      (deepAreEqual(lastKnownQuery, query) &&
        totals > 0 &&
        totals === options.length)
    ) {
      return;
    }

    dispatch(loadingOptionsRequest({ filter: label }));

    return dispatch(
      postRequest({
        showSpinner: false,
        url,
        data: {
          page,
          per_page: perPage,
          query,
        },
        onSuccess: (responseData) =>
          loadingOptionsSuccess({
            options: responseData,
            filter: label,
            query,
          }),
        onFailure: () => loadingOptionsFailure({ filter: label }),
      }),
    );
  };
