import { RG_SET_CONFIG_FILE_ATTRIBUTES } from 'actions/actionsConstants';
import update from 'immutability-helper';

import initialState from 'reducers/report_generator_reducers/initialState';

export default (state = initialState, action) => {
  switch (action.type) {
    case RG_SET_CONFIG_FILE_ATTRIBUTES:
      return update(state, { fileAttributes: { $set: action.payload } });
    default:
      return state;
  }
};
