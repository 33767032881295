import i18n from 'i18n';

export const CLIENT_VIEW = Object.freeze({
  id: 'client',
  label: i18n.t('glossary:client_other'),
});

export const ACCOUNT_VIEW = Object.freeze({
  id: 'account',
  label: i18n.t('glossary:account_other'),
});

export const FUND_VIEW = Object.freeze({
  id: 'fund',
  label: i18n.t('glossary:fund_other'),
});

export const VIEWS = Object.freeze([CLIENT_VIEW, ACCOUNT_VIEW, FUND_VIEW]);
