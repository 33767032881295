/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@icapitalnetwork/supernova-core';

function NumericCellRenderer({ value, valueFormatted }) {
  return (
    <Box
      sx={(theme) => ({
        color: value
          ? value > 0
            ? theme.palette.success.dark
            : theme.palette.error.main
          : null,
      })}
    >
      {valueFormatted}
    </Box>
  );
}

NumericCellRenderer.defaultProps = {
  value: null,
  valueFormatted: '',
};

NumericCellRenderer.propTypes = {
  value: PropTypes.number,
  valueFormatted: PropTypes.string,
};

export default NumericCellRenderer;
