import {
  FLEXIBLE_FEED_DASHBOARD_GET_GENERAL_LEDGER_ACCOUNTS,
} from 'actions/actionsConstants';

import { updateState } from '../helpers';
import initialState from '../initialState';

export default (state = initialState, action) => {
  switch (action.type) {
    case FLEXIBLE_FEED_DASHBOARD_GET_GENERAL_LEDGER_ACCOUNTS:
      return updateState(state, action.payload, (data) => ({
        generalLedgerAccountIds: data,
      }));
    default:
      return state;
  }
};
