import notificationService from 'services/notifications';
import {
  RGA_SET_CREATE_CUSTOM_QUERY,
  RGA_SET_CUSTOM_QUERY,
} from 'actions/actionsConstants';
import { getRequest, patchRequest, postRequest } from 'actions/utils';
import { actionFactory } from 'services/actions';
import { getCurrentConfigId } from 'reducers/report_generator_reducers/selectors';
import actions from 'actions';
import baseQueryModelsActions from './baseQueryModelsActions';
import crudActions from './crudActions';

const BASE_URL = 'report_generator/custom_queries';

const setRgCreateCustomQuery = actionFactory(RGA_SET_CREATE_CUSTOM_QUERY);
const getCustomQuerySuccess = actionFactory(RGA_SET_CUSTOM_QUERY);

const handleCustomQueryCreate = (id) => (dispatch) => {
  dispatch(
    baseQueryModelsActions.setConfigurable(
      id,
      'ReportGeneratorCustomQuery',
      true,
    ),
  );
  dispatch(baseQueryModelsActions.rgaHandleBaseQueryChange());
  dispatch(setRgCreateCustomQuery(false));
};

const handleCustomQueryUpdate = (id) => (dispatch, getState) => {
  const configId = getCurrentConfigId(getState());

  dispatch(crudActions.getReportGeneratorConfigs(configId));
  dispatch(actions.reportScheduleData(configId));
};

const createRgCustomQuery = (formData) => (dispatch) =>
  dispatch(
    postRequest({
      url: BASE_URL,
      data: { report_generator_custom_query: formData },
      onSuccess: ({ id }) => handleCustomQueryCreate(id),
      successMessage: { message: 'Custom query created successfully.' },
      onFailure: ({ data: { message } }) =>
        notificationService.notifyError(message),
    }),
  );
const updateRgCustomQuery = (formData) => (dispatch) =>
  dispatch(
    patchRequest({
      url: `${BASE_URL}/${formData.id}`,
      data: { report_generator_custom_query: formData },
      onSuccess: () => handleCustomQueryUpdate(formData.id),
      successMessage: { message: 'Custom query updated successfully.' },
      onFailure: ({ data: { message } }) =>
        notificationService.notifyError(message),
    }),
  );

const checkRgCustomQuery = (customQueryId) =>
  getRequest({
    url: [BASE_URL, customQueryId, 'validate_query'].join('/'),
    successMessage: { message: 'Query executed successfully.' },
  });

export default {
  createRgCustomQuery,
  updateRgCustomQuery,
  checkRgCustomQuery,
  setRgCreateCustomQuery,
};
