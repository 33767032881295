import update from 'immutability-helper';
import { INVESTMENT_BLOCKS_MANAGER } from './actions';

export const initialState = {
  investmentBlockRulesList: {
    rules: [],
    total_count: 0,
    page: 1,
    per_page: 15,
    sort_by: [],
  },
  investmentBlockFilters: {
    wlps_list: [],
    funds_list: [],
    rias_firms_list: [],
    blocks_list: [],
  },
  blocksList: [],
  selectedBlocks: [],
  selectedConstraintType: null,
  selectedWlps: [],
  selectedFunds: [],
  selectedFirms: [],
};

const investmentBlocksManagerReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case INVESTMENT_BLOCKS_MANAGER.INVESTMENT_BLOCK_RULES_LIST.FAILURE:
      return update(state, {
        investmentBlockRulesList: {
          $set: {
            rules: [],
            total_count: 0,
            page: 1,
            per_page: 15,
            sort_by: [],
          },
        },
      });
    case INVESTMENT_BLOCKS_MANAGER.INVESTMENT_BLOCK_RULES_LIST.SUCCESS:
      return update(state, {
        investmentBlockRulesList: { $set: payload.response },
      });
    case INVESTMENT_BLOCKS_MANAGER.INVESTMENT_BLOCK_FILTERS.FAILURE:
      return update(state, {
        investmentBlockFilters: {
          $set: {
            wlps_list: [],
            funds_list: [],
            rias_firms_list: [],
            blocks_list: [],
          },
        },
      });
    case INVESTMENT_BLOCKS_MANAGER.INVESTMENT_BLOCK_FILTERS.SUCCESS:
      return update(state, {
        investmentBlockFilters: {
          $set: payload.response,
        },
      });
    case INVESTMENT_BLOCKS_MANAGER.BLOCKS_LIST.SUCCESS:
      return update(state, {
        blocksList: { $set: payload.response },
      });
    case INVESTMENT_BLOCKS_MANAGER.BLOCKS_LIST.FAILURE:
      return update(state, {
        blocksList: { $set: [] },
      });
    case INVESTMENT_BLOCKS_MANAGER.SET_SELECTED_BLOCKS:
      return update(state, {
        selectedBlocks: { $set: payload },
      });
    case INVESTMENT_BLOCKS_MANAGER.SET_SELECTED_CONSTRAINT_TYPE:
      return update(state, {
        selectedConstraintType: { $set: payload },
      });
    case INVESTMENT_BLOCKS_MANAGER.SET_SELECTED_WLPS:
      return update(state, {
        selectedWlps: { $set: payload },
      });
    case INVESTMENT_BLOCKS_MANAGER.SET_SELECTED_FUNDS:
      return update(state, {
        selectedFunds: { $set: payload },
      });
    case INVESTMENT_BLOCKS_MANAGER.SET_SELECTED_FIRMS:
      return update(state, {
        selectedFirms: { $set: payload },
      });
    default:
      return state;
  }
};

export default investmentBlocksManagerReducer;
