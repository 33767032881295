import { getRequest } from 'actions/utils';
import { createAction } from 'redux-actions';
import { createCRUD, createRequestTypes } from 'actions/helpers';

export const BASE_URL = 'mass_mailer/mass_mailer_emails';
export const BASE_NAME = 'massMailerDocumentEmail';

export const MASS_MAILER_DOCUMENT_EMAILS = createCRUD(BASE_NAME, {
  LIST: createRequestTypes(`${BASE_NAME}_LIST`),
});

export const getMassMailerDocumentEmails = (query) => getRequest({
  params: query,
  showSpinner: false,
  url: BASE_URL,
  actions: MASS_MAILER_DOCUMENT_EMAILS.LIST,
});

export const MASS_MAILER_DOCUMENT_EMAILS_LIST = {
  UNMOUNT: `${BASE_NAME}_list_UNMOUNT`,
};

export const onUnmount = createAction(MASS_MAILER_DOCUMENT_EMAILS_LIST.UNMOUNT);

export default {
  getMassMailerDocumentEmails,
};
