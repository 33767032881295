import _ from 'lodash';
import { CUSTODIAN_ACCOUNT_TYPE } from 'modules/aml/constants';

const selectedCustodianAccountType = (currentCustodian, custodianAccount) => {
  if (_.isEmpty(custodianAccount) || !currentCustodian) { return; }

  return _.find(currentCustodian.custodian_account_types, { id: custodianAccount.custodian_account_type_id });
};

const suffix = (custodian, accountType) => {
  if (!(custodian && accountType)) { return ''; }
  // in future add suffix here
};

const prefix = (custodian, accountType) => {
  if (!(custodian && accountType)) { return ''; }

  switch (custodian) {
    case 'Pershing':
      if (accountType === CUSTODIAN_ACCOUNT_TYPE.RETIREMENT_IRA || accountType === CUSTODIAN_ACCOUNT_TYPE.RETIREMENT_OTHER) {
        return 'Pershing LLC as Custodian FBO';
      }
      return 'Pershing LLC FBO';

    case 'Fidelity RIAs / Family Office':
    case 'NFS Broker-Dealers':
      if (accountType === CUSTODIAN_ACCOUNT_TYPE.RETIREMENT_IRA) {
        return 'NFS/FMTC FBO';
      }
      return 'NFS LLC FBO';

    case 'Charles Schwab':
      if (accountType === CUSTODIAN_ACCOUNT_TYPE.RETIREMENT_IRA) {
        return 'Charles Schwab & Co., Inc. as Custodian for';
      }
      return 'Charles Schwab & Co., Inc. FBO';

    case 'Inspira Financial Trust, LLC':
      return 'Inspira Financial Trust, LLC Custodian FBO';
    case 'TD Ameritrade':
      if (accountType === CUSTODIAN_ACCOUNT_TYPE.TAXABLE) {
        return 'TD Ameritrade Clearing Inc. FBO';
      }
      return 'TD Ameritrade Clearing Inc. Cust. FBO';
    case 'Community National Bank':
      return 'Community National Bank CUST FBO';
    case 'Goldman Sachs Custody Solutions':
      if (accountType === CUSTODIAN_ACCOUNT_TYPE.RETIREMENT_IRA) {
        return 'GSCS as Custodian For';
      }
      return 'GSCS FBO';

    case 'LPL Financial LLC':
      if (accountType === CUSTODIAN_ACCOUNT_TYPE.RETIREMENT_IRA) {
        return 'PTC CUST IRA FBO';
      }
      return null;
    default:
      return null;
  }
};

// EXPORTS

export const decorated = (custodian, accountType, accountName) => {
  if (accountName) {
    if (prefix(custodian, accountType)) {
      accountName = `${prefix(custodian, accountType)} ${accountName}`;
    }
    if (suffix(custodian, accountType)) {
      accountName = `${accountName} ${suffix(custodian, accountType)}`;
    }
  }

  return accountName;
};

export const adjustCustodianAccountName = () => true;

export const custodianAccountNamePrefix = (currentCustodian, custodianAccount) => {
  const custodianAccountType = selectedCustodianAccountType(currentCustodian, custodianAccount);

  if (currentCustodian && adjustCustodianAccountName() && custodianAccountType) {
    return prefix(currentCustodian.name, custodianAccountType.name);
  }
};

export const custodianAccountNameSuffix = (currentCustodian, custodianAccount) => {
  const custodianAccountType = selectedCustodianAccountType(currentCustodian, custodianAccount);

  if (currentCustodian && adjustCustodianAccountName() && custodianAccountType) {
    return suffix(currentCustodian.name, custodianAccountType.name);
  }
};

export const decoratedCustodianAccountName = (currentCustodian, custodianAccount) => {
  const custodianAccountType = selectedCustodianAccountType(currentCustodian, custodianAccount);

  if (custodianAccount && custodianAccountType) {
    const { account_name } = custodianAccount;

    if (adjustCustodianAccountName()) {
      return decorated(currentCustodian.name, custodianAccountType.name, account_name);
    }
    return account_name;
  }
};

export const ffcForCommunityNationalBank = (currentCustodian, investorProfile) => {
  const decoratedName = decoratedCustodianAccountName(
    currentCustodian,
    investorProfile.custodian_account
  );
  const decoratedNumber = investorProfile.custodian_account.account_number
    .split('').map(() => '*').join('');
  return decoratedName && decoratedNumber ?
    `[${decoratedName || ''}, ${decoratedNumber}]` :
    '';
};


export const formatFFCForCustodian = (currentCustodian, investorProfile) => {
  if (currentCustodian.name === 'Community National Bank') {
    return ffcForCommunityNationalBank(currentCustodian, investorProfile);
  }
  return currentCustodian.overriden_ffc_value || currentCustodian.bank_ffc;
};
