/* eslint-disable max-statements */
/* eslint-disable max-lines-per-function */
import { PUSH_EVENTS } from 'constants/index';
import i18n from 'i18n.js';
import pushCallbacks from 'services/notifications/pushCallbacks';
import { prependPartnerProxyAPIPrefix } from 'services/axios/setBaseUrl';
import useNotificationService from 'hooks/useNotificationService';
import { manager } from './pushNotifier';

const refreshPage = (data) => {
  // Some notifications don't set the url, e.g. synthesis/refresh_share_class_performance_worker.rb
  // in that case refresh the current page
  window.location.href = data?.url || window.location.href;
};

const initilizeGlobalHandlers = (enqueueSnackbar, closeSnackbar) => {
  manager.clearEventHandlers();
  manager.addEventHandler(PUSH_EVENTS.JOB_STARTED, (data) => {
    useNotificationService({
      variant: 'info',
      message: data.message,
      enqueueSnackbar,
      closeSnackbar,
    });
  });
  manager.addEventHandler(PUSH_EVENTS.JOB_COMPLETE, (data) => {
    useNotificationService({
      variant: 'info',
      message: data.message,
      onActionClick: () => refreshPage(data),
      actionButtonLabel: i18n.t('common:reload_small', 'reload'),
      enqueueSnackbar,
      closeSnackbar,
    });
  });

  manager.addEventHandler(PUSH_EVENTS.JOB_SUCCESS, (data) => {
    useNotificationService({
      variant: 'success',
      message: data.message,
      onActionClick: () => refreshPage(data),
      actionButtonLabel: i18n.t('common:reload_small', 'reload'),
      enqueueSnackbar,
      closeSnackbar,
    });
  });

  manager.addEventHandler(PUSH_EVENTS.JOB_FAILED, (data) => {
    useNotificationService({
      variant: 'error',
      message: data.message,
      onActionClick: () => refreshPage(data),
      actionButtonLabel: i18n.t('common:reload_small', 'reload'),
      enqueueSnackbar,
      closeSnackbar,
    });
  });

  manager.addEventHandler(PUSH_EVENTS.EXPORT_FINISHED, (data) => {
    useNotificationService({
      variant: 'success',
      message: `${i18n.t('common:messages.your_export_is_ready_for_download', 'Your export is ready for download.')} `,
      onActionClick: () => window.open(data.url, '_blank'),
      actionButtonLabel: i18n.t('common:download_caps', 'DOWNLOAD'),
      enqueueSnackbar,
      closeSnackbar,
    });
    pushCallbacks.onExportFinished();
  });

  manager.addEventHandler(PUSH_EVENTS.EXPORT_FAILED, (data) => {
    useNotificationService({
      variant: 'error',
      message: data.message,
      enqueueSnackbar,
      closeSnackbar,
    });
  });

  manager.addEventHandler(PUSH_EVENTS.FILE_DOWNLOAD, (data) => {
    useNotificationService({
      variant: 'success',
      message: `${i18n.t('common:messages.your_download_file_is_ready', 'Your download file is ready.')} `,
      onActionClick: () => window.open(data.url, '_blank'),
      actionButtonLabel: i18n.t('common:download', 'Download'),
      enqueueSnackbar,
      closeSnackbar,
      persist: true,
    });
    pushCallbacks.onExportFinished();
  });

  manager.addEventHandler(PUSH_EVENTS.IMPORT_FINISHED, (data) => {
    useNotificationService({
      variant: 'info',
      message: data.message || `${i18n.t('common:messages.your_upload_is_ready_to', 'Your upload is ready to')} `,
      onActionClick: () => window.open(data.url, '_blank'),
      actionButtonLabel: i18n.t('common:download_small', 'download'),
      enqueueSnackbar,
      closeSnackbar,
    });
  });

  manager.addEventHandler(PUSH_EVENTS.IMPORT_FAILED, (data) => {
    useNotificationService({
      variant: 'error',
      message: data.message,
      onActionClick: () => window.open(data.url, '_blank'),
      actionButtonLabel: data.urlText,
      enqueueSnackbar,
      closeSnackbar,
    });
  });

  manager.addEventHandler(PUSH_EVENTS.EXPORT_DOWNLOAD, (data) => {
    useNotificationService({
      variant: 'success',
      message: data.message,
      enqueueSnackbar,
      closeSnackbar,
    });
    document.location.href = data.url;
  });

  manager.addEventHandler(PUSH_EVENTS.EXPORT_STARTED, () => {
    useNotificationService({
      variant: 'info',
      message: `${i18n.t('common:messages.your_export_may_take_a_few_moments', 'Your export may take a few moments.')} ${i18n.t('common:messages.we_will_notify_you', "We will notify you when it's ready.")}`,
      enqueueSnackbar,
      closeSnackbar,
    });
  });

  manager.addEventHandler(PUSH_EVENTS.WATERMARK_DOCUMENT_READY, (data) => {
    useNotificationService({
      variant: 'info',
      message: `${i18n.t('common:messages.your_document_is_ready_to', 'Your document is ready to')} `,
      onActionClick: () => window.open(data.url, '_blank'),
      actionButtonLabel: i18n.t('common:preview_small', 'preview'),
      enqueueSnackbar,
      closeSnackbar,
      autoHideDuration: 7000,
    });
    pushCallbacks.onWatermarkDocumentReady(
      data.documentType,
      data.documentId,
      data.status
    );
  });

  manager.addEventHandler(PUSH_EVENTS.WATERMARK_DOCUMENT_ERROR, (data) => {
    useNotificationService({
      variant: 'error',
      message: data.message,
      enqueueSnackbar,
      closeSnackbar,
      autoHideDuration: 7000,
    });
    pushCallbacks.onWatermarkDocumentReady(
      data.documentType,
      data.documentId,
      data.status
    );
  });

  manager.addEventHandler(PUSH_EVENTS.REFRESH_STARTED, (data) => setTimeout(
    () => {
      useNotificationService({
        variant: 'info',
        message: data.message,
        enqueueSnackbar,
        closeSnackbar,
      });
    }
  ));

  manager.addEventHandler(PUSH_EVENTS.REFRESH_ONGOING, (data) => setTimeout(
    () => {
      useNotificationService({
        variant: 'info',
        message: data.message,
        enqueueSnackbar,
        closeSnackbar,
      });
    }
  ));

  manager.addEventHandler(PUSH_EVENTS.REFRESH_FINISHED, (data) => setTimeout(
    () => {
      useNotificationService({
        variant: 'success',
        message: data.message,
        enqueueSnackbar,
        closeSnackbar,
      });
    }
  ));

  manager.addEventHandler(PUSH_EVENTS.EMAIL_STATUS_PROCESSING, (data) => {
    useNotificationService({
      variant: 'info',
      message: data.message,
      enqueueSnackbar,
      closeSnackbar,
      autoHideDuration: 1000,
    });
  });

  manager.addEventHandler(PUSH_EVENTS.EMAIL_STATUS_FINISHED, (data) => {
    useNotificationService({
      variant: 'info',
      message: data.message,
      enqueueSnackbar,
      closeSnackbar,
    });
    document.location.href = data.url;
  });

  manager.addEventHandler(PUSH_EVENTS.AGREEMENT_PREVIEW_GENERATION_STARTED, (data) => setTimeout(() => {
    useNotificationService({
      variant: 'info',
      message: data.message,
      enqueueSnackbar,
      closeSnackbar,
    });
  }, 500));

  manager.addEventHandler(PUSH_EVENTS.AGREEMENT_PREVIEW_GENERATION_ERROR, (data) => setTimeout(() => {
    useNotificationService({
      variant: 'error',
      message: data.message,
      enqueueSnackbar,
      closeSnackbar,
    });
  }, 500));

  manager.addEventHandler(PUSH_EVENTS.AGREEMENT_PREVIEW_GENERATION_FINISHED, (data) => setTimeout(() => {
    useNotificationService({
      variant: 'success',
      message: i18n.t('glossary:agreement_preview_generated', 'Agreement preview generation finished'),
      onActionClick: () => window.open(data.url, '_blank'),
      actionButtonLabel: i18n.t('glossary:click_to_view', 'Click here to view'),
      enqueueSnackbar,
      closeSnackbar,
    });
  }, 1000));

  manager.addEventHandler(PUSH_EVENTS.INVESTMENT_DASHBOARD_PDF_REPORT_READY, (data) => {
    const reportDowloadUrl = prependPartnerProxyAPIPrefix(
      `/api/v1/investment_dashboard/pdf_report/${data.request_id}/pdf_file`
    );
    useNotificationService({
      variant: 'info',
      message: i18n.t('investment_dashboard:your_report_is_available', 'Your Investment Report is available to'),
      onActionClick: () => window.open(reportDowloadUrl, '_blank'),
      actionButtonLabel: i18n.t('common:download_small', 'download'),
      enqueueSnackbar,
      closeSnackbar,
      persist: true,
    });
  });

  manager.addEventHandler(PUSH_EVENTS.INVESTMENT_DASHBOARD_PDF_REPORT_NO_DATA, () => {
    useNotificationService({
      variant: 'info',
      message: i18n.t('investment_dashboard:no_data_to_generate_report'),
      enqueueSnackbar,
      closeSnackbar,
    });
  });

  manager.addEventHandler(PUSH_EVENTS.INVESTMENT_DASHBOARD_PDF_REPORT_FAILED, () => {
    useNotificationService({
      variant: 'error',
      message: i18n.t('common:errors.problem_generating_report', 'There was a problem generating the report, please try again later'),
      enqueueSnackbar,
      closeSnackbar,
    });
  });

  manager.addEventHandler(PUSH_EVENTS.PUBLISH_REPORT_DOCUMENTS_DONE, (data) => {
    useNotificationService({
      variant: 'success',
      message: data.message,
      enqueueSnackbar,
      closeSnackbar,
      persist: true,
    });
  });

  manager.addEventHandler(PUSH_EVENTS.WRAP_DOCUMENT_STARTED, (data) => {
    useNotificationService({
      variant: 'info',
      message: data.message,
      enqueueSnackbar,
      closeSnackbar,
      persist: true,
    });
  });

  manager.addEventHandler(PUSH_EVENTS.WRAP_DOCUMENT_FINISHED, (data) => {
    useNotificationService({
      variant: 'success',
      message: data.message,
      enqueueSnackbar,
      closeSnackbar,
      persist: true,
    });
  });

  manager.addEventHandler(PUSH_EVENTS.WRAP_DOCUMENT_FAILED, (data) => {
    useNotificationService({
      variant: 'error',
      message: data.message,
      enqueueSnackbar,
      closeSnackbar,
      persist: true,
    });
  });
};

export default initilizeGlobalHandlers;
