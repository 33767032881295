import { useMutation } from '@tanstack/react-query';
import { patchRequest } from 'services/apiRequest';
import { FALLBACK_ERROR_MESSAGE } from 'containers/client_management/constants';

export const useMutationRefreshRelatedInvestorProfiles = (investorProfileId) =>
  useMutation({
    mutationFn: () =>
      patchRequest({
        url: `client_management/external_apis/investor_profiles/${investorProfileId}/refresh_related_investor_profiles`,
        errorMessage: FALLBACK_ERROR_MESSAGE,
      }),
  });
