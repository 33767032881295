/* eslint-disable max-statements */
/* eslint-disable max-len */
import icnIdentity from 'services/icn_identity/icnIdentity';
import { INVESTMENT_APPROVAL_STATUS_OVERRIDES } from 'constants/index';

import {
  isPreApprovalStatus,
  isPreApprovalSecondaryStatus,
  isRevertedStatus,
  isApprovedStatus,
  isAcceptedStatus,
  isComplianceReviewStatus,
} from '../investmentStatus';

const {
  REVERTED,
  IR_REVIEW,
  COMPLIANCE_REVIEW,
  APPROVED,
  ACCEPTED,
  PRE_APPROVAL,
  PRE_APPROVAL_SECONDARY,
  PENDING_REVIEW,
  EXTERNAL_REVIEW,
  EXTERNAL_SECONDARY_REVIEW,
} = INVESTMENT_APPROVAL_STATUS_OVERRIDES;

const showFieldForUser = (user) => (
  icnIdentity.isHomeOffice(user) ||
  icnIdentity.isSalesManagerWithPafAdmin(user) ||
  icnIdentity.isSalesManagerWithUnredactedSubReview(user) ||
  !icnIdentity.isSalesManager(user)
);

const isAllowedToActionsMenu = (status, user) => icnIdentity.isPafAdmin(user) && (
  isApprovedStatus(status) || isAcceptedStatus(status) || isComplianceReviewStatus(status)
);

export const showInvestorName = (user) => (showFieldForUser(user));
export const showFlaggedFields = (user) => (
  icnIdentity.isHomeOffice(user) ||
  icnIdentity.isSalesManagerWithPafAdmin(user) ||
  !icnIdentity.isSalesManager(user)
);

export const showAccountNum = (user) => showFieldForUser(user);

export const showStatusDate = (status) => [IR_REVIEW, COMPLIANCE_REVIEW, EXTERNAL_REVIEW, EXTERNAL_SECONDARY_REVIEW].includes(status);

export const showAssignedLeadApprover = (user, status) => {
  // user must have one of the following global permissions
  if (icnIdentity.canManageLeadApprovers(user) || icnIdentity.canAssignIndividualLeadApprover(user)) {
    // user must have permission to approve an investment in a specific status
    switch (status) {
      case PRE_APPROVAL:
        return icnIdentity.canApprovePreApproval(user);
      case PRE_APPROVAL_SECONDARY:
        return icnIdentity.canApprovePreApprovalSecondary(user);
      case EXTERNAL_REVIEW:
        return icnIdentity.canApproveExternalReview(user);
      case EXTERNAL_SECONDARY_REVIEW:
        return icnIdentity.canApproveExternalSecondaryReview(user);
      case IR_REVIEW:
        return icnIdentity.canApproveDocumentReview(user);
      case COMPLIANCE_REVIEW:
        return icnIdentity.canApproveComplianceReview(user);
      default:
        return false;
    }
  }
  return false;
};

export const showFundName = (status, selectedPaf) => !selectedPaf && !isPreApprovalStatus(status);

export const showFirstSigner = (status, user) => !(
  isRevertedStatus(status) || (
    icnIdentity.isSalesManager(user) &&
    !icnIdentity.isSalesManagerWithPafAdmin(user) &&
    !icnIdentity.isHomeOffice(user) &&
    !icnIdentity.isSalesManagerWithUnredactedSubReview(user)
  )
);

export const showCloseDate = (status) => (
  !isRevertedStatus(status) && !isPreApprovalStatus(status)
);

export const showPlacementFee = (status) => ![REVERTED, PRE_APPROVAL].includes(status);

export const showFunded = (status, paf) => {
  if ([REVERTED, PRE_APPROVAL].includes(status)) {
    return false;
  }
  const fund = paf?.private_access_fund;
  return fund ? fund.funding_for_close : false;
};

export const showUploadTimes = (status) => [
  IR_REVIEW,
  EXTERNAL_REVIEW,
  EXTERNAL_SECONDARY_REVIEW,
].includes(status);

export const showCustodianForms = (status, user) => {
  if (!icnIdentity.isPafAdmin(user)) {
    return false;
  }

  return [
    IR_REVIEW,
    COMPLIANCE_REVIEW,
    APPROVED,
    ACCEPTED,
  ].includes(status);
};

export const showAdminSignOff = (status, user) => {
  if ([REVERTED, PRE_APPROVAL].includes(status)) {
    return false;
  }
  return isAllowedToActionsMenu(status, user);
};

export const profileMatchedStatus = (investment) => investment && investment.profile_matched;

export const electronicSignMethod = (investment) => investment && (investment.sign_method === 'Electronic');

export const showProfileMatched = (status, user) => {
  if (!icnIdentity.isPafAdmin(user)) {
    return false;
  }
  return (isApprovedStatus(status) || isAcceptedStatus(status));
};

export const preApprovalNotAccepted = (investment) => (
  isPreApprovalStatus(investment.approval_status_override) &&
  investment.pre_approval_accepted === false
) || (
    isPreApprovalSecondaryStatus(investment.approval_status_override) &&
    investment.pre_approval_secondary_accepted === false
  );

export const showReadyToFund = (status, paf) => (
  !isRevertedStatus(status) && paf?.private_access_fund?.pre_close_required
);

export const showActionsMenu = (user) => !icnIdentity.isAllowedToReadOnly(user);

export const isHedgeFund = (paf) => paf?.private_access_fund?.open_ended;

export const showUnits = (paf) => isHedgeFund(paf) && paf?.private_access_fund?.unitized;

export const showSubAdvisoryFee = (status) => ![REVERTED, PRE_APPROVAL, PRE_APPROVAL_SECONDARY].includes(status);

export const showSubmissionDate = (status, whiteLabelPartner) => {
  const invalidStatus = [REVERTED, PRE_APPROVAL, PRE_APPROVAL_SECONDARY, PENDING_REVIEW].some((tableStatus) => tableStatus === status);
  const canShowOnWlp = whiteLabelPartner?.show_submission_date_in_subscription_review;
  return !invalidStatus && canShowOnWlp;
};

export const showBranchCode = (whiteLabelPartner) => whiteLabelPartner?.show_branch_codes;
