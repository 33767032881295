import React from 'react';
import { string } from 'prop-types';
import Highlighter from 'react-highlight-words';
import { wordsToHighlight } from 'containers/admin_search/services/textFormatter';
import styles from './HighlightedSearchText.module.scss';

function HighlightedSearchEmail({
  value,
  searchKeyword,
}) {
  const indexToSplitEmail = value.indexOf('@');
  const valueFirstPart = value.substring(0, indexToSplitEmail);
  const valueSecondPart = value.substring(indexToSplitEmail + 1);

  return (
    <div>
      <Highlighter
        highlightClassName={styles.highlight_class}
        searchWords={wordsToHighlight(searchKeyword)}
        autoEscape
        textToHighlight={valueFirstPart}
      />
        @
      <wbr />
      <Highlighter
        highlightClassName={styles.highlight_class}
        searchWords={wordsToHighlight(searchKeyword)}
        autoEscape
        textToHighlight={valueSecondPart}
      />
    </div>
  );
}

HighlightedSearchEmail.propTypes = {
  value: string.isRequired,
  searchKeyword: string.isRequired,
};

export default HighlightedSearchEmail;
