import { createSelector } from 'reselect';
import queryString from 'query-string';
import _ from 'lodash';

export const getPage = (state) => state.investmentOpportunities.page;
export const getFunds = (state) => state.investmentOpportunities.funds;
export const getPageActions = (state) => state.investmentOpportunities.pageActions;
export const getFilterSelectedValues = (state) => state.investmentOpportunities.filterSelectedValues;
export const getFilterOptions = (state) => state.investmentOpportunities.filterOptions;
export const getIsLoading = (state) => state.investmentOpportunities.isLoading;
export const getIsLastPage = (state) => state.investmentOpportunities.isLastPage;
export const getLoadingFirstPage = (state) => state.investmentOpportunities.isLoadingFirstPage;
export const getSearchTerms = (state) => state.investmentOpportunities.pageActions.searchTerms;

export const getPafs = (state) => state.investmentOpportunities.pageActions.pafs;
export const getResultsCount = (state) => state.investmentOpportunities.totalCount;
export const getCurrentPage = (state) => state.investmentOpportunities.currentPage;
export const getRequestMade = (state) => state.investmentOpportunities.requestMade;
export const getResultsPerPage = (state) => state.investmentOpportunities.resultsPerPage;
export const getLastKnownScrollPosition = (state) => state.investmentOpportunities.lastKnownScrollPosition;
export const getPafsWithReports = (state) => state.investmentOpportunities.flash_reports.pafs_with_reports;
export const getFlashReports = (state) => state.investmentOpportunities.flash_reports;
export const getInfoMessage = (state) => state.investmentOpportunities.infoMessage;
export const getShowDiligence = (state) => state.investmentOpportunities.filterOptions.show_diligence_filter;
export const getShowHasInvestmentEducation = (state) =>
  state.investmentOpportunities.filterOptions.show_education_filter;
export const getShowMarketplacePartner = (state) =>
  state.investmentOpportunities.filterOptions.show_marketplace_partner_filter;
export const getShowObjectives = (state) => state.investmentOpportunities.filterOptions.show_objectives_filter;
export const getshowDistributors = (state) => state.investmentOpportunities.filterOptions.show_distributors_filter;
export const getShowAccountTypes = (state) => state.investmentOpportunities.filterOptions.show_account_type_filter;
export const getShowLiquidity = (state) => state.investmentOpportunities.filterOptions.show_liquidity_filter;
export const getShowJurisdiction = (state) => state.investmentOpportunities.filterOptions.show_jurisdiction_filter;
export const getShowClosedBadge = (state) => state.investmentOpportunities.filterOptions.show_closed_filter;

export const showDiligenceFilter = (state) => state.investmentOpportunities.filterOptions.show_diligence_filter;

export const showIsDistributedByIcapitalFilter = (state) =>
  state.investmentOpportunities.filterOptions.show_is_distributed_by_icapital_filter;

export const hasFilterSelected = createSelector(getFilterSelectedValues, (filterSelectedValues) => {
  const filtersStatus = Object.keys(filterSelectedValues).reduce((acc, cur) => {
    acc.push(filterSelectedValues[cur].length > 0);
    return acc;
  }, []);
  const countFilters = Object.keys(filterSelectedValues).length;
  const countFiltersFalse = filtersStatus.filter((filter) => filter === false).length;

  return countFiltersFalse !== countFilters;
});

export const atLeastOneFilterEnabled = createSelector(
  getFilterOptions,
  (filterOptions) =>
    filterOptions.show_objectives_filter ||
    filterOptions.show_category_filter ||
    filterOptions.show_currency_filter ||
    filterOptions.show_strategy_filter ||
    filterOptions.show_qualification_filter ||
    filterOptions.show_investor_jurisdiction_filter ||
    filterOptions.show_account_type_filter ||
    filterOptions.show_investment_ranges_filter ||
    filterOptions.show_fund_manager_filter ||
    filterOptions.show_liquidity_filter ||
    filterOptions.show_icapital_features_filter ||
    filterOptions.show_closed_filter ||
    filterOptions.show_jurisdiction_filter ||
    filterOptions.show_offshore_filter ||
    filterOptions.show_distributors_filter
);

export const getParamsToUrl = createSelector(getPageActions, getFilterOptions, (pageActions, filterOptions) => {
  const getActionValue = (value) => (_.isEmpty(value) ? undefined : value);
  const params = {
    view_type: getActionValue(pageActions.view_type),
    sort_type: getActionValue(pageActions.sort_type),
    search_terms: getActionValue(pageActions.searchTerms),
    pafs: getActionValue(pageActions.pafs),
    show_closed_funds: filterOptions.show_closed_filter ? pageActions.showClosedFunds : undefined,
    investment_objective: getActionValue(pageActions.filters.investmentObjective),
    investor_type: getActionValue(pageActions.filters.investorType),
    investor_jurisdiction: getActionValue(pageActions.filters.investorJurisdiction),
    features: getActionValue(pageActions.filters.features),
    category: getActionValue(pageActions.filters.category),
    currency: getActionValue(pageActions.filters.currency),
    following: getActionValue(pageActions.filters.following),
    investment_ranges: getActionValue(pageActions.filters.investmentRanges),
    investor_qualification: getActionValue(pageActions.filters.investorQualification),
    fund_manager: getActionValue(pageActions.filters.fundManager),
    liquidity: getActionValue(pageActions.filters.liquidity),
    distributors: getActionValue(pageActions.filters.distributors),
    strategy: getActionValue(pageActions.filters.strategy),
    jurisdiction: getActionValue(pageActions.filters.jurisdiction),
    offshore: getActionValue(pageActions.filters.offshore),
  };
  return queryString.stringify(params);
});

export const hasFunds = createSelector(getFunds, (funds) => !_.isEmpty(funds));

export const getFilterFeaturesValues = (state) => state.investmentOpportunities.filterValues.features;
