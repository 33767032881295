import thunkMiddleware from 'redux-thunk';
import rootReducers from "./reducers"
import {createStore, applyMiddleware, compose} from "redux";
import ReduxPromise from "redux-promise";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const initialState = {};
const store = createStore(
  rootReducers,
  initialState,
  composeEnhancers(applyMiddleware(ReduxPromise, thunkMiddleware))
);
export default store;
