import { FALLBACK_ERROR_MESSAGE } from 'containers/client_management/constants';
import { postRequest } from 'services/apiRequest';

export const createResendOfferingMaterials = (investmentId, signerIds) => (
  postRequest({
    url: `/client_management/investments/${investmentId}/resend_offering_materials`,
    data: {
      signer_ids: signerIds,
    },
    errorMessage: FALLBACK_ERROR_MESSAGE,
  })
);
