import update from 'immutability-helper';

import {
  EXPORT_MODAL_SYNTHESIS_SET_PERIOD,
  EXPORT_MODAL_SYNTHESIS_SET_INDEX_MASTERS,
  EXPORT_MODAL_SYNTHESIS_SET_SHARE_CLASSES,
  EXPORT_MODAL_SYNTHESIS_SET_REPORT_TYPES,
  EXPORT_MODAL_SYNTHESIS_SET_RETURN_TYPES,
  EXPORT_MODAL_SYNTHESIS_SET_FUND_GROUPS,
  EXPORT_MODAL_SYNTHESIS_SET_FUNDS,
  EXPORT_MODAL_SYNTHESIS_LOAD_INDEX_MASTER_LIST,
  EXPORT_MODAL_SYNTHESIS_LOAD_SHARE_CLASS_LIST,
  EXPORT_MODAL_SYNTHESIS_LOAD_FUND_GROUP_LIST,
  EXPORT_MODAL_SYNTHESIS_LOAD_FUND_LIST,
  REFRESH_DATE_SELECTION,
  REFRESH_MODE_SELECTION,
} from 'actions/actionsConstants';

export const initialState = {
  indexMasters: [],
  indexMasterList: [],
  shareClasses: [],
  shareClassList: [],
  period: '',
  yearsPeriod: [],
  reportTypes: [],
  returnTypes: [],
  fundGroups: [],
  fundGroupList: [],
  funds: [],
  fundList: [],
  since: undefined,
  refreshMode: true,
};

export default function synthesisExportModalReducer(state = initialState, action) {
  switch (action.type) {
    case EXPORT_MODAL_SYNTHESIS_SET_INDEX_MASTERS:
      return update(state, {
        indexMasters: { $set: action.payload },
      });
    case EXPORT_MODAL_SYNTHESIS_LOAD_INDEX_MASTER_LIST:
      return update(state, {
        indexMasterList: { $set: action.payload },
      });
    case EXPORT_MODAL_SYNTHESIS_SET_SHARE_CLASSES:
      return update(state, {
        shareClasses: { $set: action.payload },
      });
    case EXPORT_MODAL_SYNTHESIS_LOAD_SHARE_CLASS_LIST:
      return update(state, {
        shareClassList: { $set: action.payload },
      });
    case EXPORT_MODAL_SYNTHESIS_SET_PERIOD:
      return update(state, {
        period: { $set: action.payload },
      });
    case EXPORT_MODAL_SYNTHESIS_SET_REPORT_TYPES:
      return update(state, {
        reportTypes: { $set: action.payload },
      });
    case EXPORT_MODAL_SYNTHESIS_SET_RETURN_TYPES:
      return update(state, {
        returnTypes: { $set: action.payload },
      });
    case EXPORT_MODAL_SYNTHESIS_SET_FUND_GROUPS:
      return update(state, {
        fundGroups: { $set: action.payload },
      });
    case EXPORT_MODAL_SYNTHESIS_LOAD_FUND_GROUP_LIST:
      return update(state, {
        fundGroupList: { $set: action.payload },
      });
    case EXPORT_MODAL_SYNTHESIS_SET_FUNDS:
      return update(state, {
        funds: { $set: action.payload },
      });
    case EXPORT_MODAL_SYNTHESIS_LOAD_FUND_LIST:
      return update(state, {
        fundList: { $set: action.payload },
      });
    case REFRESH_DATE_SELECTION:
      return update(state, {
        since: { $set: action.payload },
      });
    case REFRESH_MODE_SELECTION:
      return update(state, {
        refreshMode: { $set: action.payload },
      });
    default:
      return state;
  }
}
