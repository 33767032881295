import {
  PDE_CREATE,
  PDE_EDIT,
  PDE_SET_DATA,
  PDE_SHOW,
  PDE_UPDATE,
  PDE_DELETE,
  PDE_SET_FUNDS,
  PDE_SET_FIRMS,
} from 'actions/actionsConstants';

import update from 'immutability-helper';

const initialState = {
  publishedDocumentExports: [],
  queryServices: [],
  extraServices: [],
  frequencies: [],
  whiteLabelPartners: [],
  funds: [],
  firms: [],
  docsMethods: [],
  integrationPartners: [],
  editPublishedDocumentExport: undefined,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case PDE_CREATE:
      return update(state, {
        publishedDocumentExports: {
          $push: [payload],
        },
      });
    case PDE_UPDATE:
      if (payload.id) {
        const index = state.publishedDocumentExports.findIndex((e) => e.id === payload.id);
        const newList = [...state.publishedDocumentExports];
        newList.splice(index, 1, payload);
        return update(state, {
          publishedDocumentExports: { $set: newList },
        });
      }
      return { ...state };
    case PDE_EDIT:
      return update(state, {
        editPublishedDocumentExport: {
          $set: payload,
        },
      });
    case PDE_SET_FUNDS:
      return update(state, {
        funds: {
          $set: payload,
        },
      });
    case PDE_SET_FIRMS:
      return update(state, {
        firms: {
          $set: payload,
        },
      });
    case PDE_SET_DATA:
      return update(state, {
        whiteLabelPartners: {
          $set: payload.wlps,
        },
        integrationPartners: {
          $set: payload.ips,
        },
        frequencies: {
          $set: payload.frequencies,
        },
        queryServices: {
          $set: payload.services,
        },
        extraServices: {
          $set: payload.extra_services,
        },
        docsMethods: {
          $set: payload.docs_methods,
        },
      });
    case PDE_SHOW:
      return update(state, {
        publishedDocumentExports: {
          $set: payload,
        },
      });
    case PDE_DELETE:
      return update(state, {
        publishedDocumentExports: {
          $set: state.publishedDocumentExports.filter((e) => payload !== e.id),
        },
      });
    default:
      return state;
  }
};
