import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getRequest } from 'services/apiRequest';

const url = 'client_management/external_apis/accounts/fetch';

export const useFetchAccount = (accountId, privateAccessFundId, firmId, isProspect = false) => {
  const type = useMemo(() => {
    if (isProspect) return 'prospect';

    return 'account';
  }, [isProspect]);

  const params = useMemo(() => ({
    type,
    account_number: accountId,
    private_access_fund_id: privateAccessFundId,
    firm_id: firmId,
  }), [accountId, firmId, privateAccessFundId, type]);

  return useQuery({
    queryKey: [url, params],
    queryFn: () => getRequest({ url, params }),
    enabled: false,
    cacheTime: 0,
    select: (data) => ({
      accountId,
      accountNumber: data.account_number,
      investmentExternalId: data.investment_external_id,
      availableSigners: data.signers,
      selectedSigners: [],
      transactionId: data.transaction_uuid,
      preApprovalQuestionnaire: data.actionables?.pre_approval_questionnaire,
      investorName: data.investor_name,
      subscriptionType: data.subscription_type_name,
    }),
  });
};
