import { capitalize } from 'lodash';

export function mapStatusValue(status) {
  switch (status) {
    case 'available':
      return 'Complete';
    case 'started':
      return 'In progress';
    default:
      return capitalize(status);
  }
}

export function documentExportMapper(doc, fileExtension) {
  const date = new Date(doc.created_at);
  return {
    name: `${doc.name}.${fileExtension}`,
    created_at: date.toUTCString(),
    file_url: doc.file_url,
    status: mapStatusValue(doc.status),
  };
}
