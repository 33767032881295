import React from 'react';
import { connect } from 'react-redux';
import { CircularProgress } from '@icapitalnetwork/supernova-core-v2';
import { getIcnReactBootstrap } from 'selectors/icnBootstrapSelectors';
import LayoutContainer from 'components/shared/layout_global/LayoutContainer';
import { IcnSpinner } from 'components/shared/icn_spinner/IcnSpinner';

function Loading(props) {
  // Change to correct multi asset permission when created
  const isMultiAsset = props.icnReactBootstrap?.user?.permissions?.includes('see_unified_homepage');

  if (props.error) {
    return (
      <div>
        An error occurred: {props.error.name}: {props.error.message}
      </div>
    );
  }
  return (
    <LayoutContainer>
      {isMultiAsset ? (
        <CircularProgress sx={{ position: 'fixed', top: '48%', left: '48%' }} />
      ) : (
        <IcnSpinner isFetching />
      )}
    </LayoutContainer>
  );
}

function mapStateToProps(state) {
  return {
    icnReactBootstrap: getIcnReactBootstrap(state),
  };
}

export default connect(mapStateToProps, null)(Loading);
