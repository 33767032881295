/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable max-lines */
/* eslint-disable max-statements */
/* eslint-disable max-lines-per-function */

import React, { useState } from 'react';
import { shape, bool, func, arrayOf } from 'prop-types';
import { bindActionCreators } from 'redux';
import actions from 'actions';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { publicPath as reactPublicPath } from 'router';
import { isReactAppUrl } from 'services/react_url';
import { isEmpty } from 'lodash';
import cx from 'classnames/bind';
import 'focus-within-polyfill';
import { IcnButton } from '@icapitalnetwork/react-component-library';
import IcnHeaderLogo from 'images/production_logo_110x26.svg';
import SearchIcon from 'images/icon-search-white.svg';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import ASNavbar from 'containers/admin_search/as_navbar/ASNavbar';
import { HelpDialog } from 'components/shared/help/HelpDialog';
import LanguageMenu from 'modules/polyglot/components/LanguageMenu';
import { withTranslation } from 'react-i18next';
import { convertToKey } from 'i18n/helpers';
import DomainToggle from 'components/domain_toggle/DomainToggle';
import SiteNavMFE from 'components/site_nav_mfe/SiteNavMFE';
import { routeCodes } from 'modules/impersonation/router';
import { useAuth } from '@icapitalnetwork/authentication';

import Stack from '@icapitalnetwork/supernova-core/Stack';
import { users as usersType } from 'modules/passport/prop_types/user';
import PassportMenu from 'modules/passport/components/PassportMenu/PassportMenu';
import styles from './icnHeader.module.scss';
import supportModalStyles from './supportModalBox.module.scss';

const { IMPERSONATION } = routeCodes;

export const getLinkComponent = (subMenuObj, mainAppUrl, history, t) => {
  // guard against menu item with no href
  if (!subMenuObj.href) {
    return null;
  }

  const name = t(`common:header.${convertToKey(subMenuObj.name)}`, subMenuObj.name);
  // To account for possible prefix (set as basename on router),
  // we use relative href for linking within React app
  // For linking back to Angular app, we need to prepend the starting slash to the href
  if (subMenuObj.href.startsWith(reactPublicPath)) {
    const state = subMenuObj.foreground_modal ? { background: history.location } : null;

    return (
      <Link to={{ pathname: subMenuObj.href, state }} className="icn_wlp_submenu_links_a_tag">
        <span>{name}</span>
      </Link>
    );
  }
  if (subMenuObj.href.startsWith('http')) {
    // We need to account for complete urls, for example when we link to different
    // applications like dispatcher and fund master, so we're assuming those urls
    // will include the entire link and do not need to add the main app url.
    return (
      <a href={subMenuObj.href} className="icn_wlp_submenu_links_a_tag">
        <span>{name}</span>
      </a>
    );
  }

  return (
    <a href={`${mainAppUrl}/${subMenuObj.href}`} className="icn_wlp_submenu_links_a_tag">
      <span>{name}</span>
    </a>
  );
};

export const createIcnHref = (href, mainAppUrl) => {
  // don't modify
  if (href.includes('http')) {
    return href;
  }

  let finalHref = mainAppUrl;

  // prepend a starting slash for links back to Angular app
  if (!isReactAppUrl(href) && !href.startsWith('/')) {
    finalHref += '/';
  }
  finalHref += href;

  return finalHref;
};

function IcnHeader({
  icnReactBootstrap,
  history,
  admin_search,
  setSearchKeyword,
  setIsAdminSearchOpen,
  t,
  candidates,
}) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [preventSubMenuAnimation, setPreventSubMenuAnimation] = useState(false);

  // Modal box functions
  function toggleAdminSearch() {
    if (admin_search.is_search_button_enable) {
      if (admin_search.is_admin_search_open) setSearchKeyword('');
      setIsAdminSearchOpen(!admin_search.is_admin_search_open);
    }
  }

  function closeModal() {
    setModalIsOpen(false);
  }

  function openModal() {
    setModalIsOpen(true);
  }

  // functions for rendering
  function isWhiteLabel() {
    if (!isEmpty(icnReactBootstrap.white_label_partner) && icnReactBootstrap.white_label_partner !== '') {
      return icnReactBootstrap.white_label_partner.id !== 0;
    }

    return false;
  }

  function onMouseEvent(mainMenu, property) {
    if (Array.isArray(mainMenu[property])) {
      return setPreventSubMenuAnimation(false);
    }
    setPreventSubMenuAnimation(true);
  }

  function onSingleTopNavClick(href, target) {
    window.open(href, target);
  }

  function generateTopLevelLists(mainMenu) {
    const mainMenuList = [];
    if (icnReactBootstrap.pending_mfa_registration) {
      return mainMenuList;
    }

    const renderOrder = [
      'invest',
      'research',
      'reporting',
      'manage',
      'learn',
      'analyze',
      'admin',
      'subdocs',
      'management_reporting',
    ];

    let menuListItemCount = 0;
    let currentMenuIndex = 0;
    for (const obj in mainMenu) {
      if (obj !== 'main' && mainMenu.hasOwnProperty(obj) && Object.keys(mainMenu[obj] || {}).length !== 0) {
        menuListItemCount += 1;
      }
    }

    renderOrder.forEach((property) => {
      if (property !== 'main') {
        if (mainMenu.hasOwnProperty(property) && Object.keys(mainMenu[property] || {}).length !== 0) {
          mainMenuList.push(
            <li
              key={`icn-header-top-level-${property}`}
              name={property.split('_').join('-')}
              className={cx({
                'header__nav-item': true,
                icn_wlp_nav_item: true,
                'header__submenu-none': !Array.isArray(mainMenu[property]),
                'header__nav-item--last': currentMenuIndex === menuListItemCount - 1,
              })}
              onMouseEnter={() => onMouseEvent(mainMenu, property)}
            >
              <span
                className="header__nav-item-label icn_wlp_nav_letter icn_wlp_selected_nav_a_tag"
                tabIndex="0"
                onClick={() =>
                  Array.isArray(mainMenu[property])
                    ? null
                    : onSingleTopNavClick(
                        createIcnHref(mainMenu[property].href, icnReactBootstrap.main_app_url),
                        mainMenu[property].target
                      )
                }
                data-cta={`${property}Button`}
              >
                <span>
                  {Array.isArray(mainMenu[property]) ? (
                    t(`common:header.${property}`, property)
                  ) : (
                    <div className="single-action-item-container">
                      <span className="single-action-item">{t(`common:header.${property}`, property)}</span>
                    </div>
                  )}
                </span>
              </span>
              {generateSubList(mainMenu, property)}
            </li>
          );
          currentMenuIndex += 1;
        }
      }
    });
    return mainMenuList;
  }

  function generateSubListItem(subMenuObj, index) {
    return (
      <div
        key={`icn-header-sub-menu-link-${index}`}
        className={cx({
          icn_wlp_submenu_links_divider: index !== 0,
        })}
      >
        {getLinkComponent(subMenuObj, icnReactBootstrap.main_app_url, history, t)}
      </div>
    );
  }

  function generateSubList(mainMenu, property) {
    let subMenuList;
    let index = 0;
    if (mainMenu.hasOwnProperty(property) && Array.isArray(mainMenu[property])) {
      subMenuList = (
        <section
          name={property.split('_').join('-')}
          className="header__submenu icn_wlp_submenu_background"
          key={`icn-header-sub-menu-${property}`}
        >
          <div className="container">
            <div className="row header__submenu-links icn_wlp_submenu_links_container">
              {mainMenu[property].map((subMenuObj) => {
                index += 1;
                return generateSubListItem(subMenuObj, index);
              })}
            </div>
          </div>
        </section>
      );
    }
    return subMenuList;
  }

  function generateUserMenuLists(userMenu) {
    const userMenuList = [];
    if (icnReactBootstrap.pending_mfa_registration) {
      return userMenuList;
    }
    userMenu.map((userMenuObject) => {
      if (userMenuObject.name === 'Support') {
        if (!userMenuObject.href) {
          userMenuList.push(
            <li
              role="presentation"
              key={`icn-react-header-user-menu-${userMenuObject.name.toLowerCase()}`}
              className="dropdown-item"
            >
              <a
                role="menuitem"
                onClick={openModal}
                className={cx({
                  icn_wlp_header_link_tag: true,
                  icn_wlp_dropdown_menu_link_tag: true,
                  icn_wlp_nav_letter: true,
                  support_link: true,
                })}
                tabIndex="0"
              >
                {t('common:header.support', 'Support')}
              </a>
              <HelpDialog
                open={modalIsOpen}
                onClose={closeModal}
                needAssistanceOverride={icnReactBootstrap.white_label_partner.need_assistance_override}
                whiteLabelPartnerId={icnReactBootstrap.white_label_partner.id}
              />
            </li>
          );
        } else {
          userMenuList.push(
            <li role="presentation" key={`icn-react-header-user-menu-${userMenuObject.name.toLowerCase()}`}>
              <a
                role="menuitem"
                href={userMenuObject.href}
                target=""
                className="icn_wlp_header_link_tag icn_wlp_dropdown_menu_link_tag icn_wlp_nav_letter"
                tabIndex="0"
              >
                {t('common:header.support', 'Support')}
              </a>
            </li>
          );
        }
      } else {
        const href = createIcnHref(userMenuObject.href, icnReactBootstrap.main_app_url);
        userMenuList.push(
          <li
            role="presentation"
            key={`icn-react-header-user-menu-${userMenuObject.name.toLowerCase()}`}
            className="dropdown-item"
          >
            <a
              role="menuitem"
              href={href}
              target=""
              className="icn_wlp_header_link_tag icn_wlp_dropdown_menu_link_tag icn_wlp_nav_letter"
              tabIndex="0"
            >
              {t(`common:header.${convertToKey(userMenuObject.name)}`, userMenuObject.name)}
            </a>
          </li>
        );
      }
      return userMenuObject;
    });
    return userMenuList;
  }

  function visitImpersonate() {
    window.location.assign(IMPERSONATION);
  }

  function impersonationLink() {
    const { canImpersonate, canDelegate, canInvestorRepresentation } = icnReactBootstrap.user;

    if (!(canImpersonate || canDelegate) || (canDelegate && canInvestorRepresentation)) return null;

    const buttonText = (() => {
      if (canImpersonate && canDelegate) return 'Impersonate/Delegate';
      if (canImpersonate) return 'Impersonate';
      return 'Act as Delegate';
    })();

    return (
      <IcnButton
        size="sm"
        styleType="impersonate"
        className="icn_btn_impersonate
          impersonation_btn_border
          impersonation_btn_txt
          impersonation_btn_txt_hover
          pull-right"
        callback={visitImpersonate}
        text={t(`common:header.${convertToKey(buttonText)}`, buttonText)}
      />
    );
  }

  // Link to return the user to the current Partner SSO
  function ssoReturnButton() {
    const { navReturnName, navReturnUrl } = icnReactBootstrap.user;

    if (!navReturnName || !navReturnUrl) return null;

    const buttonCallback = () => {
      window.location.href = navReturnUrl;
    };

    return (
      <IcnButton
        size="sm"
        styleType="impersonate"
        className="icn_btn_impersonate
          sso-return-button
          sso_return_btn_border
          sso_return_btn_txt
          sso_return_btn_txt_hover
          pull-right"
        callback={buttonCallback}
        text={navReturnName}
      />
    );
  }

  const { useLogout } = useAuth();
  const logoutMutation = useLogout();

  // MFE
  if (icnReactBootstrap.white_label_partner.enable_sitenav_mfe) {
    return <SiteNavMFE />;
  }

  const {
    main_app_url: mainAppUrl,
    pending_mfa_registration: pendingMfaRegistration,
    user,
    white_label_partner: whiteLabelPartner,
  } = icnReactBootstrap;

  const logoHref = createIcnHref(
    pendingMfaRegistration ? '/logout' : icnReactBootstrap.main_menu.main.href,
    mainAppUrl
  );

  const viewPassportMenu =
    !user.is_imposter && (whiteLabelPartner.allow_passport_enrollment || user.passport_linked_users.includes(user.id));

  return (
    <div className={`${styles.wrapper} icn-header react_white_label_override`}>
      <header className="default_outline icn_wlp_header_outline">
        <div className="header icn_wlp_header_bg_top icn_wlp_header_staging_bg_top">
          <div className="header__container container">
            <a
              id="skip_navigation_link"
              href="#main_page_heading"
              className="visually_hidden visible_when_focused bypass_block_link"
              tabIndex="0"
            >
              {t('common:header.skip_navigation', 'SKIP TO CONTENT')}
            </a>
            <div className="header__logo-container">
              <a
                id="dashboard-link"
                tabIndex="0"
                aria-label="Logo - Home page link"
                className="icn_wlp_header_link_tag"
                href={logoHref}
              >
                <img
                  className={isWhiteLabel() ? 'header__logo--wlp' : 'header__logo'}
                  alt="Header Logo"
                  src={
                    icnReactBootstrap.header_logo === 'production_logo.svg'
                      ? IcnHeaderLogo
                      : icnReactBootstrap.header_logo
                  }
                />
              </a>
              <div className="header__domain-nav">{icnReactBootstrap.user?.canDomainToggle && <DomainToggle />}</div>
            </div>

            <nav className="header__nav">
              <ul className="header__nav-list">{generateTopLevelLists(icnReactBootstrap.main_menu)}</ul>
              <section
                className={cx('header__submenu-background', 'icn_wlp_submenu_background', {
                  'hide-submenu': preventSubMenuAnimation,
                })}
              />
            </nav>

            {impersonationLink()}

            {ssoReturnButton()}

            <Stack spacing={2} direction="row" alignItems="center" justifyContent="space-between">
              {viewPassportMenu && <PassportMenu />}
              <div id="account-info" className="drop-down header__actions icn_wlp_account_info" tabIndex="0">
                <a id="user-menu-dropdown" className="icn_wlp_header_link_tag">
                  <i className="fa fa-bars header_hamburger icn_wlp_header_hamburger_color" />
                  <div className="header__actions-info">
                    <div className="header__actions-name icn_wlp_nav_letter">{icnReactBootstrap.user.name}</div>
                  </div>
                </a>
                <div className="dropdown-menu-spacer" />
                <ul className="dropdown-menu fade icn_wlp_dropdown_menu_color">
                  {generateUserMenuLists(icnReactBootstrap.user_menu)}
                  <li role="presentation" className="header__actions-logout dropdown-item">
                    <a
                      role="menuitem"
                      tabIndex="0"
                      className="icn_wlp_header_link_tag icn_wlp_dropdown_menu_link_tag icn_wlp_nav_letter"
                      href={`${icnReactBootstrap.main_app_url}/logout`}
                      data-testid="logoutBtn"
                      onClick={async (e) => {
                        e.preventDefault();
                        await logoutMutation.mutateAsync();
                      }}
                    >
                      {t('common:header.log_out', 'Log Out')}
                    </a>
                  </li>
                </ul>
              </div>
              <Stack direction="row" alignItems="center">
                {icnReactBootstrap.user.canViewAdminSearch && (
                  <OverlayTrigger
                    placement="bottom"
                    delayShow={550}
                    overlay={
                      <Tooltip
                        id="search_tooltip"
                        className={cx(styles.search_tooltip_box, styles.search_icon_position)}
                      >
                        <span className={styles.search_icon_tooltip}>{t('common:search', 'Search')}</span>
                      </Tooltip>
                    }
                  >
                    <div className={styles.search_icon_position}>
                      <img
                        onClick={() => toggleAdminSearch()}
                        alt="searchIcon"
                        name="searchIcon"
                        src={SearchIcon}
                        className={supportModalStyles.search_icon}
                      />
                      {admin_search.is_admin_search_open && <div className={styles.search_navbar_arrow} />}
                    </div>
                  </OverlayTrigger>
                )}
                <LanguageMenu />
              </Stack>
            </Stack>
          </div>
        </div>
      </header>
      <div
        className={
          admin_search.is_admin_search_open ? 'header_space_holder_with_admin_search_open' : 'header_space_holder'
        }
      />
      {admin_search.is_admin_search_open && <ASNavbar />}
    </div>
  );
}

IcnHeader.propTypes = {
  icnReactBootstrap: shape({
    user: shape({
      canImpersonate: bool,
      canDelegate: bool,
      canViewAdminSearch: bool,
    }),
  }),
  history: shape({}).isRequired,
  admin_search: shape({
    is_admin_search_open: bool,
    is_search_button_enable: bool,
  }),
  setIsAdminSearchOpen: func.isRequired,
  setSearchKeyword: func.isRequired,
  candidates: arrayOf(shape(usersType)),
  t: func.isRequired,
};

IcnHeader.defaultProps = {
  icnReactBootstrap: {
    user: {
      canImpersonate: false,
      canDelegate: false,
      canViewAdminSearch: false,
    },
  },
  admin_search: shape({
    is_admin_search_open: false,
    is_search_button_enable: true,
  }),
  candidates: [],
};

function mapStateToProps(state) {
  return {
    icnReactBootstrap: state.icnReactBootstrap.icn_react_bootstrap,
    admin_search: state.adminSearch,
    candidates: state.passport.users,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchIcnBootstrap: actions.fetchIcnBootstrap,
      setIsAdminSearchOpen: actions.admsSetIsAdminSearchOpen,
      setSearchKeyword: actions.admsSetSearchKeyword,
    },
    dispatch
  );
}

const ConnectedIcnHeader = withRouter(connect(mapStateToProps, mapDispatchToProps)(IcnHeader));
export { ConnectedIcnHeader, IcnHeader };
export default withTranslation('common')(ConnectedIcnHeader);
