export const generalStates = {
  error: false,
  message: '',
  request_object: '',
  request_type: '',
  request_total: 0,
};

export const whiteLabelPartnerStates = {
  white_label_partners: [],
};

export const providerStates = {
  providers: [],
};

export const fileStates = {
  files: {
    list: [],
    white_label_partner_id: null,
  },
  presigned_properties: null,
  response_file_upload: {
    file_key: null,
    uploaded: null,
  },
  response_file_deletion: {
    file_key: null,
    deleted: null,
  },
};

export const dataSyncStates = {
  data_sync: {
    id: 0,
    name: null,
    started_at: null,
    finished_at: null,
    created_by_id: null,
    canceled_by_id: null,
    ran_for: null,
    batch_identifier: null,
    processors: [],
    settings: {
      pipeline: [],
    },
    white_label_partner_id: null,
  },
  data_syncs: {
    records: [],
    pagination: {
      total_count: 0,
      total_pages: 0,
      total_per_page: 0,
      current_page: 1,
      prev_page: null,
      next_page: null,
    },
    white_label_partner_id: null,
    sorted_by_attribute: null,
    sorted_by_orientation: null,
    processor: null,
    status: null,
    temp_status: null,
    user: null,
    start_date: null,
    end_date: null,
    filter_by_etl_process_text: '',
    selected_period: null,
    selected_range: '3M',
    total_processes: 0,
    failed_processes: 0,
    pass: 0,
    pass_with_errors: 0,
    average_duration: 0,
    total_rows: 0,
    processed_rows: 0,
    failed_rows: 0,
  },
};

export const dataSyncFileStates = {
  data_sync_files: {
    records: [],
    created_by_id: null,
    disallow_report: false,
  },
};

export const dataSyncFileReportStates = {
  dataSyncFileReport: {
    records: [],
    pagination: {
      total_count: 0,
      total_pages: 0,
      total_per_page: 0,
      current_page: 1,
      prev_page: null,
      next_page: null,
    },
  },
};

export const dataSyncSchemaStates = {
  dataSyncSchema: {
    identifiers: [],
    statuses: [],
    users: [],
    time_ranges: [],
    schema: {},
  },
};

export default {
  ...generalStates,
  ...whiteLabelPartnerStates,
  ...providerStates,
  ...dataSyncStates,
  ...dataSyncFileStates,
  ...dataSyncFileReportStates,
  ...dataSyncSchemaStates,
};
