import {
  sortKeepAllObjBeginning,
  flagshipWlp,
  removeDuplicates,
} from 'services/document_manager';
import { getRequest } from 'actions/utils';

const URL = 'white_label_partners';
export const fetchWlpList = ({ onSuccess = null }) => getRequest({ url: URL, onSuccess });


export const sortWlp = (data = []) => {
  let wlps = [...data];
  wlps.push(flagshipWlp);
  wlps = removeDuplicates(wlps);
  return wlps.sort(sortKeepAllObjBeginning);
};
