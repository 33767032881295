import { generatePath } from 'react-router-dom';
import { publicPath } from 'router';

export const BASE_ROUTE = `${publicPath}transfers/:id/review`;

export const generateTransferReviewUrl = (id) => generatePath(BASE_ROUTE, { id });

export const routeCodes = {
  BASE: BASE_ROUTE,
};
