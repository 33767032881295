import {
  DR_GET_SETTINGS,
  DR_SAVE_SETTINGS,
} from 'actions/actionsConstants';

import initialState from '../initialState';
import { updateState } from '../helpers';

const REQUEST_OBJECT = 'data_recon_parameter';

export default (state = initialState, action) => {
  switch (action.type) {
    case DR_GET_SETTINGS:
      return updateState(state, action.payload, (data) => ({
        request_object: REQUEST_OBJECT,
        request_type: 'set_get_settings',
        message: 'Fetched Data Recon Parameter.',
        data_recon_parameter: data,
      }));
    case DR_SAVE_SETTINGS:
      return updateState(state, action.payload, (data) => ({
        request_object: REQUEST_OBJECT,
        request_type: 'set_save_settings',
        message: data.error || 'Succeeded on saving Data Recon Parameter.',
        data_recon_parameter: data,
      }));
    default:
      return state;
  }
};
