/* eslint-disable max-statements */
import findIndex from 'lodash/findIndex';
import transform from 'lodash/transform';
import quarterYearFormatter from 'services/formatters/quarterYearFormatter';
import dateFnsFormat from 'date-fns/format';

function calculatePercentage(dividend, divisor) {
  return (dividend !== 0 && divisor !== 0) ? ((dividend * 100.0) / divisor) : 0;
}

function allPcapsWithNonCumulativeValues(pcaps) {
  let maxValue = Number.NEGATIVE_INFINITY;
  const pcapsWithNonCumulativeValues = [];

  for (let i = 0; i < pcaps?.length; i += 1) {
    const pcap = pcaps[i];

    const previousContributions = pcapsWithNonCumulativeValues[i - 1]?.paid_in_capital_value || 0;
    const previousDistributions = pcapsWithNonCumulativeValues[i - 1]?.distributions_value || 0;
    const previousRecallableCommitment = pcapsWithNonCumulativeValues[i - 1]?.recallable_commitment || 0;
    const previousSubscriptions = pcaps[i - 1]?.subscriptions || 0;
    const previousRebalance = pcaps[i - 1]?.rebalance || 0;
    const previousRedemptions = pcaps[i - 1]?.redemptions || 0;
    const previousTransfersOut = pcaps[i - 1]?.transfers_out || 0;
    const previousTransfersIn = pcaps[i - 1]?.transfers_in || 0;
    const previousDividendReinvestment = pcaps[i - 1]?.dividend_reinvestment || 0;

    const contributionsByPeriod = pcap.paid_in_capital_value - previousContributions;
    const distributionsByPeriod = pcap.distributions_value - previousDistributions;
    const recallableCommitmentByPeriod = pcap.recallable_commitment - previousRecallableCommitment;
    const netCashFlowByPeriod = distributionsByPeriod - contributionsByPeriod;
    const subscriptionsByPeriod = pcap.subscriptions - previousSubscriptions;
    const rebalanceByPeriod = pcap.rebalance - previousRebalance;
    const redemptionsByPeriod = pcap.redemptions - previousRedemptions;
    const transfersOutByPeriod = pcap.transfers_out - previousTransfersOut;
    const transfersInByPeriod = pcap.transfers_in - previousTransfersIn;
    const dividendReinvestmentByPeriod = pcap.dividend_reinvestment - previousDividendReinvestment;

    const period = quarterYearFormatter(pcap.year, pcap.quarter);

    const pcapByPeriod = {
      period,
      paidin_capital_commitment_percentage:
        calculatePercentage(pcap.paid_in_capital_value, pcap.total_commitment),
      paid_in_capital_value: pcap.paid_in_capital_value,
      net_moic: pcap.moic,
      distributions_paidin_capital_percentage:
        calculatePercentage(pcap.distributions_value, pcap.paid_in_capital_value),
      distributions_value: pcap.distributions_value,
      contributionsByPeriod,
      negativeContributionsByPeriod: -contributionsByPeriod,
      distributionsByPeriod,
      cumulativeNetCashFlow: pcap.distributions_value - pcap.paid_in_capital_value,
      end_date: pcap.quarter_end_date,
      report_period: pcap.report_period,
      nav: pcap.capital_balance,
      totalValue: pcap.total_value,
      negativePaidinCapitalValue: -pcap.paid_in_capital_value,
      netCashFlowByPeriod,
      recallable_commitment: pcap.recallable_commitment,
      recallableCommitmentByPeriod,
      remaining_commitment: pcap.remaining_commitment,
      percentage_funded: pcap.percentage_funded,
      total_commitment: pcap.total_commitment,
      net_irr: pcap.net_irr,
      subscriptions: pcap.subscriptions,
      redemptions: pcap.redemptions,
      shares: pcap.shares,
      yearly_net_irr: pcap.yearly_net_irr,
      dividend_reinvestment: pcap.dividend_reinvestment,
      nav_price: pcap.nav_price,
      dividendReinvestmentByPeriod,
      subscriptionsByPeriod,
      rebalance: pcap.rebalance,
      rebalanceByPeriod,
      redemptionsByPeriod,
      transfersOutByPeriod,
      transfers_out: pcap.transfers_out,
      transfersInByPeriod,
      transfers_in: pcap.transfers_in,
      year: String(pcap.year),
      quarter: dateFnsFormat(new Date(pcap.quarter_end_date), 'QQQ'),
      period_net_irr: pcap.period_net_irr,
    };

    pcapsWithNonCumulativeValues.push(pcapByPeriod);

    maxValue = Math.max(
      maxValue,
      Math.abs(contributionsByPeriod),
      Math.abs(pcap.distributions_value)
    );
  }
  return { pcaps: pcapsWithNonCumulativeValues, maxValueOfPcaps: maxValue };
}

function allPcapsByYear({ pcaps }) {
  const aggregateValueProperty = [
    'contributionsByPeriod',
    'distributionsByPeriod',
    'negativeContributionsByPeriod',
    'netCashFlowByPeriod',
    'recallableCommitmentByPeriod',
    'subscriptionsByPeriod',
    'rebalanceByPeriod',
    'redemptionsByPeriod',
    'dividendReinvestmentByPeriod',
    'transfersOutByPeriod',
    'transfersInByPeriod',
  ];
  const pcapsByYear = [];

  for (let i = 0; i < pcaps.length; i += 1) {
    const pcap = pcaps[i];
    const year = pcap.period?.split('-')[1] || pcap.period;

    const indexOfYear = findIndex(pcapsByYear, ['period', year]);

    if (indexOfYear !== -1) {
      // Sum all values from all pcap variables but period
      transform(pcap, (result, value, key) => {
        if (aggregateValueProperty.includes(key)) {
          pcapsByYear[indexOfYear][key] += value;
        } else if (key !== 'period') {
          pcapsByYear[indexOfYear][key] = value;
        }
      }, pcapsByYear[indexOfYear]);
    } else {
      pcapsByYear.push({
        ...pcap,
        period: year,
      });
    }
  }

  return pcapsByYear;
}

export {
  allPcapsWithNonCumulativeValues,
  allPcapsByYear,
};
