export function sortArrayAlphabetically(array, firstProp, secondProp = null) {
  return array.sort((el1, el2) => {
    const el1Content = (secondProp ? el1[firstProp][secondProp] : el1[firstProp]).toLowerCase();
    const el2Content = (secondProp ? el2[firstProp][secondProp] : el2[firstProp]).toLowerCase();
    if (el1Content < el2Content) {
      return -1;
    }
    if (el1Content > el2Content) {
      return 1;
    }
    return 0;
  });
}

export function normalizeCurrentPage(newValue, state) {
  const lastPage = Math.ceil(state.assignments_count / state.results_per_page);
  let page = newValue;
  if (page > lastPage) {
    page = lastPage;
  }
  return Math.max(page, 1);
}
