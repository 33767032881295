/* globals document */
export default function usersnap(env, isWhiteLabel, whiteLabelPartner) {
  if (!(env === 'test' || env === 'development') && (!isWhiteLabel || whiteLabelPartner.enable_usersnap)) {
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.src = '//api.usersnap.com/load/bf3459c0-659b-4a49-aae2-54c47f3fe9b1.js';
    const x = document.getElementsByTagName('head')[0];
    x.appendChild(s);
  }
}
