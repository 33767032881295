import { actionFactory } from 'services/actions';

import {
  PDFVIEWER_SET_CURRENT_PAGE,
  PDFVIEWER_SET_NUMBER_OF_PAGES,
  PDFVIEWER_SET_DOCUMENT,
  PDFVIEWER_SET_LOADING_ERROR_MESSAGE,
  PDFVIEWER_IS_LOADING,
  PDFVIEWER_SET_SCALE,
  PDFVIEWER_SET_DOCUMENT_NAME,
  PDFVIEWER_SET_PAGE_VIEWPORTS,
  PDFVIEWER_SET_HAS_SCROLLED_INTO_PAGE,
} from 'actions/actionsConstants';
import { getRequest } from 'actions/utils';
import { debounce } from 'lodash';
import { DOCUMENT_BEING_PROCESSED_MESSAGE, TOKEN_ERROR_FETCH_DOCUMENT_MESSAGE } from '../services/constants';

const pdfViewerSetCurrentPage = actionFactory(PDFVIEWER_SET_CURRENT_PAGE);
const pdfViewerSetNumberOfPages = actionFactory(PDFVIEWER_SET_NUMBER_OF_PAGES);
const pdfViewerSetDocument = actionFactory(PDFVIEWER_SET_DOCUMENT);
const pdfViewerSetLoadingErrorMessage = actionFactory(PDFVIEWER_SET_LOADING_ERROR_MESSAGE);
const pdfViewerSetIsLoading = actionFactory(PDFVIEWER_IS_LOADING);
const pdfViewerSetScale = actionFactory(PDFVIEWER_SET_SCALE);
const pdfViewerSetDocumentName = actionFactory(PDFVIEWER_SET_DOCUMENT_NAME);
const pdfViewerSetPageViewports = actionFactory(PDFVIEWER_SET_PAGE_VIEWPORTS);
const pdfViewerHasScrolledIntoPage = actionFactory(PDFVIEWER_SET_HAS_SCROLLED_INTO_PAGE);

function pdfViewerGetDocument(token, retries = 5) {
  return (dispatch) => dispatch(getRequest({
    url: 'pdf_viewer',
    params: {
      access_token: token,
    },
    showSpinner: false,
    onSuccess: pdfViewerSetDocument,
    onFailure: (props) => {
      if (props.data?.message === DOCUMENT_BEING_PROCESSED_MESSAGE && retries > 0) {
        return debounce(pdfViewerGetDocument(token, retries - 1), 3000);
      }
      dispatch(pdfViewerSetIsLoading(false));
      return pdfViewerSetLoadingErrorMessage(props.data?.message || TOKEN_ERROR_FETCH_DOCUMENT_MESSAGE);
    },
  }));
}

export {
  pdfViewerSetCurrentPage,
  pdfViewerSetNumberOfPages,
  pdfViewerGetDocument,
  pdfViewerSetLoadingErrorMessage,
  pdfViewerSetIsLoading,
  pdfViewerSetScale,
  pdfViewerSetDocumentName,
  pdfViewerSetPageViewports,
  pdfViewerHasScrolledIntoPage,
};
