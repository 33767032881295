/* eslint-disable eqeqeq */
export default function floatFormatter(value, fixedDecimal = 2, optionalDecimal = false) {
  if (value == null || Number.isNaN(Number(value))) {
    return 0;
  }

  let floatVal = parseFloat(value).toFixed(fixedDecimal);

  if (optionalDecimal) {
    const [integerPart, decimalPart] = floatVal.split('.');
    const lastDigit = decimalPart.slice(-1);

    floatVal = decimalPart == 0 ? integerPart : (
        lastDigit == 0 ? floatVal.slice(0, -1) : floatVal
      );
  }

  return floatVal;
}
