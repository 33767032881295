import update from 'immutability-helper';
import { RGA_SET_REPORT_FIELDS } from 'actions/actionsConstants';

import initialState from 'reducers/report_generator_reducers/initialState';

export default (state = initialState, action) => {
  switch (action.type) {
    case RGA_SET_REPORT_FIELDS: {
      const { base_query_id, fields } = action.payload;
      const { frequently_used_fields, ...available_fields } = fields;
      return {
        ...state,
        available_fields: update(state.available_fields, {
          [base_query_id]: { $set: available_fields },
        }),
        frequently_used_fields: update(state.frequently_used_fields, {
          [base_query_id]: { $set: frequently_used_fields },
        }),
      };
    }
    default:
      return state;
  }
};
