import {
  SET_SPINNER_TEXT,
  BEGIN_API_REQUEST,
  END_API_REQUEST,
  SET_SPINNER_DISPLAY,
} from 'actions/actionsConstants';

import update from 'immutability-helper';

const initialState = {
  isFetching: 0,
  spinnerText: '',
  display: true,
};

export { initialState };

export default (state = initialState, action) => {
  const p = action.payload || {};
  let nextState = {};

  switch (action.type) {
    case BEGIN_API_REQUEST:
      nextState = { isFetching: state.isFetching + 1 };
      if (Object.prototype.hasOwnProperty.call(p, 'spinnerText')) {
        nextState.spinnerText = p.spinnerText;
      }
      return update(state, {
        $merge: nextState,
      });

    case END_API_REQUEST:
      nextState = {
        isFetching: state.isFetching > 1 ? state.isFetching - 1 : 0,
        spinnerText: '',
      };
      return update(state, {
        $merge: nextState,
      });

    case SET_SPINNER_TEXT:
      return update(state, {
        spinnerText: {
          $set: p.spinnerText,
        },
      });
    case SET_SPINNER_DISPLAY: {
      return update(state, {
        display: {
          $set: p.display,
        },
      });
    }

    default:
      return state;
  }
};
