import moment from 'moment';

const DEFAULT_DATE = 'DD-MM-YYYY';

// this checks if two arrays and it's elements match (only goes 1 level deep)
export const deepAreEqual = (a, b) => {
  if (Array.isArray(a) && Array.isArray(b) && (a.length === b.length)) {
    return !a.some((item, index) => item !== b[index]);
  }
  return a === b;
};

export const baseComparator = (comparator) => (a, b, inverted = false) => {
  if (a === b) {
    return 0;
  }
  if (a === null || a === undefined) {
    return inverted ? -1 : 1;
  }
  if (b === null || b === undefined) {
    return inverted ? 1 : -1;
  }
  return comparator(a, b);
};

// Use for primitive values that support < or > comparation
export const simpleComparator = baseComparator((a, b) => (a < b ? -1 : 1));

/**
 * @function alphaNumericComparator
   @desc 'natural' sort for alphanumeric strings e.g.

   const a = ['12-asd','123_asd', '10--asd']
   a.sort()
   >> ["10--asd", "12-asd", "123_asd"]

   @param a Item 1 for sort comparison
   @param b Item 2 for sort comparison
 */
export const alphaNumericComparator = baseComparator((a, b) => (
  a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' })
));

export const dateComparator = (format = DEFAULT_DATE) => baseComparator((a, b) => {
  const dateA = moment(a, [format]).toDate();
  const dateB = moment(b, [format]).toDate();

  return dateA < dateB ? -1 : 1;
});
export const defaultDateComparator = dateComparator(DEFAULT_DATE);

export const stringComparator = baseComparator((a, b) => a.localeCompare(b));
