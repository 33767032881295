import {
  getRequest,
} from 'actions/utils';
import { actionFactory } from 'services/actions';

import {
  RAT_LOAD_ACCOUNT_TYPES,
  RAT_SET_ACCOUNT_TYPE,
} from '../actionsConstants';

const ratGetAccountTypes = actionFactory(RAT_LOAD_ACCOUNT_TYPES);
const ratSetAccountType = actionFactory(RAT_SET_ACCOUNT_TYPE);

const registrationAccountTypesUrl = '/registration_account_types';

function ratLoadAccountTypes(whiteLabelPartnerId, visibility_at, user_invite = null, showSpinner = false) {
  const params = { whiteLabelPartnerId, visibility_at, user_invite };
  return getRequest({
    showSpinner,
    url: registrationAccountTypesUrl,
    params,
    onSuccess: ratGetAccountTypes,
  });
}

function ratSetAccountTypeInvite(selectedAccountType, showSpinner = false) {
  return (dispatch) => {
    dispatch(ratSetAccountType(selectedAccountType));
  };
}

function ratGetAccountType(id, showSpinner = false) {
  return getRequest({
    showSpinner,
    url: `${registrationAccountTypesUrl}/${id}`,
    onSuccess: ratSetAccountType,
  });
}

export default {
  ratLoadAccountTypes,
  ratGetAccountType,
  ratSetAccountTypeInvite,
};
