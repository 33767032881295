import {
  SWS_SET_COMBINATIONS,
  SWS_DELETE_FROM_GRID,
  SWS_SET_TRIGGER_OPTIONS,
  SWS_SET_FILTER_OPTIONS,
  SWS_SET_SELECTED_FILTERS_FOR_GROUP,
  SWS_SET_FILTERED_COMBINATIONS,
  SWS_CLEAR_SELECTED_FILTERS,
} from 'actions/actionsConstants';

import update from 'immutability-helper';
import snakeCase from 'lodash/snakeCase';
import isObject from 'lodash/isObject';

const statusMap = {
  in_process: 'pending_review',
  external_review: 'external_review',
  document_review: 'ir_review',
  legal_review: 'compliance_review',
  approved: 'approved',
  accepted: 'accepted',
  pre_approval: 'pre_approval',
};

function createFilterOption(content, filterType, typedFilterId) {
  return {
    content,
    filter_type: filterType,
    typed_filter_id: typedFilterId,
  };
}

const workflowStatusFilters = () =>
  ['Pre-Approval', 'In-Process', 'External Review', 'Document Review', 'Legal Review', 'Approved', 'Accepted'].map(
    (status) => createFilterOption(status, 'workflow_status', statusMap[snakeCase(status)])
  );

export const initialState = {
  combinations: [],
  offering_materials_trigger_options: {},
  filtered_combinations: [],
  filter_options: {
    firms: [],
    funds: [],
    wlps: [],
    workflow_status: workflowStatusFilters(),
  },
  selected_filters: {
    firms: [],
    funds: [],
    wlps: [],
    workflow_status: [],
  },
};

export default (state = initialState, action) => {
  let index = null;
  const ft = isObject(action.payload) ? action.payload.filterType : null;
  const f = isObject(action.payload) ? action.payload.options : null;

  switch (action.type) {
    case SWS_SET_COMBINATIONS:
      return update(state, {
        combinations: { $set: action.payload },
      });

    case SWS_DELETE_FROM_GRID:
      index = state.combinations.findIndex((e) => e.id === action.payload);
      return update(state, {
        combinations: { $splice: [[index, 1]] },
      });

    // set all filter options
    case SWS_SET_FILTER_OPTIONS:
      return update(state, {
        filter_options: {
          $merge: action.payload,
        },
      });

    // update state specific filter group
    case SWS_SET_SELECTED_FILTERS_FOR_GROUP:
      return update(state, {
        selected_filters: (selectedFilters) =>
          update(selectedFilters || {}, {
            [ft]: (filterType) =>
              update(filterType || [], {
                $set: f,
              }),
          }),
      });

    // clear all selected filters
    case SWS_CLEAR_SELECTED_FILTERS:
      return update(state, {
        selected_filters: {
          $set: {
            firms: [],
            funds: [],
            wlps: [],
            workflow_status: [],
          },
        },
      });

    // set list of combinations after applying filters
    case SWS_SET_FILTERED_COMBINATIONS:
      return update(state, {
        filtered_combinations: { $set: action.payload },
      });

    // set options to fill offering materials trigger dropdown
    case SWS_SET_TRIGGER_OPTIONS:
      return update(state, {
        offering_materials_trigger_options: { $set: action.payload },
      });

    default:
      return state;
  }
};
