import update from 'immutability-helper';
import {
  TOU_SET_TERMS,
  TOU_SET_ERROR_MESSAGE,
} from 'actions/actionsConstants';

export const initialState = {
  terms: [],
  errorMessage: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TOU_SET_TERMS:
      return {
        ...state,
        terms: [...state.terms, action.payload.terms_of_use],
      };
    case TOU_SET_ERROR_MESSAGE:
      return update(state, {
        errorMessage: { $set: action.payload },
      });
    default:
      return state;
  }
};
