import {
  arrayOf, shape, oneOfType, string, number,
} from 'prop-types';

const FilterOption = {
  content: string,
  filter_type: string,
  typed_filter_id: oneOfType([number, string]),
};

const Shape = shape(FilterOption);

export const FilterOptionArray = arrayOf(Shape);
export default Shape;
