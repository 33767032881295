/* eslint-disable max-lines */
/* eslint-disable max-statements */
/* eslint-disable max-lines-per-function */
import {
  OPERATIONS_SET_VISIBLE_CAPITAL_EVENT_IDS,
  OPERATIONS_SET_CAPITAL_EVENTS,
  OPERATIONS_UPDATE_CAPITAL_EVENT_STATUS,
  OPERATIONS_UPDATE_UND_CAPITAL_EVENTS,
  OPERATIONS_DELETE_STANDALONE_UND_CAPITAL_EVENT,
  OPERATIONS_SET_CAPITAL_EVENT_AS_PAID,
  OPERATIONS_SET_OUTSTANDING_INVESTMENTS,
  OPERATIONS_SET_ALL_CAPITAL_EVENTS_EXPORTS,
  OPERATIONS_SET_ALL_OUTSTANDING_INVESTMENTS,
  OPERATIONS_SET_FILTERED_OUTSTANDING_INVESTMENTS,
  OPERATIONS_SET_OUTSTANDING_DISTRIBUTION_REPORTS,
  OPERATIONS_SET_ACTIVE_STAT,
  OPERATIONS_SET_FILTERED_CAPITAL_EVENTS,
  OPERATIONS_SET_CAPITAL_EVENTS_FILTERS,
  OPERATIONS_SET_CAPITAL_EVENTS_ACTIVE_FILTERS,
  OPERATIONS_SET_CAPITAL_EVENTS_CALL_DATE_FILTER,
  OPERATIONS_SET_CAPITAL_EVENTS_IS_FETCHING,
  OPERATIONS_SET_GL_APPROVED,
  OPERATIONS_SET_LAST_EXPORTED_FILE,
  OPERATIONS_SET_HAS_NEW_EXPORTED_FILE,
} from 'actions/actionsConstants';
import moment from 'moment';
import isNumber from 'lodash/isNumber';
import update from 'immutability-helper';
import { stringDateComparator } from 'components/fund_admin/capital_event_operations_cell_helper/cellRendererHelper';
import initialState from '../initialState';

function sortDescendingByDueDate(a, b) {
  return stringDateComparator(b.due_date, a.due_date);
}

const isStandaloneDistribution = (status) => status === 'Hold Distribution';
const isDistribution = (
  reconciledCapitalCallAmount, reconciledGrossDistribution
) => reconciledCapitalCallAmount - reconciledGrossDistribution < 0;
const isToday = (dateString, format = 'YYYY-MM-DD') => moment(dateString, format).isSame(moment(), 'day');
const filterCapitalEvents = (capitalEvents) => {
  const pastDueEvents = [];
  const t14PastDueEvents = [];
  const t35PastDueEvents = [];
  const t45PastDueEvents = [];
  const attentionRequiredEvents = [];
  const outForApprovalEvents = [];
  const approvedEvents = [];
  const distributionEvents = [];
  const executedTodayCapitalCalls = [];
  const executedTodayDistributions = [];

  capitalEvents.forEach((event) => {
    if (event.days_count_after_ff_due_date > 0 && event.ff_funding_status < 1 &&
        !isDistribution(event.reconciled_capital_call_amount, event.reconciled_gross_distribution)) {
      pastDueEvents.push(event);
    }
    if (event.calendar_days_after_due_date >= 14) t14PastDueEvents.push(event);
    if (event.calendar_days_after_due_date >= 35) t35PastDueEvents.push(event);
    if (event.calendar_days_after_due_date >= 45) t45PastDueEvents.push(event);
    if (
      (event.days_count_after_ff_due_date === 0 && event.days_outstanding_until_due_date <= 2 &&
            event.ff_funding_status < 1) || event.status === 'Review Needed' || event.status === 'rejected') {
      attentionRequiredEvents.push(event);
    }
    if (event.status === 'out_for_approval') {
      outForApprovalEvents.push(event);
    }
    if (event.status === 'approved') {
      approvedEvents.push(event);
    }
    if ((event.days_count_after_ff_due_date > 0 &&
          isDistribution(event.reconciled_capital_call_amount, event.reconciled_gross_distribution)) ||
          isStandaloneDistribution(event.status)) {
      distributionEvents.push(event);
    }
    if (!isDistribution(event.reconciled_capital_call_amount, event.reconciled_gross_distribution) &&
    isToday(event.executed_at)) {
      executedTodayCapitalCalls.push(event);
    }

    if (isDistribution(event.reconciled_capital_call_amount, event.reconciled_gross_distribution) &&
          isToday(event.executed_at)) {
      executedTodayDistributions.push(event);
    }
  });

  return {
    pastDueEvents,
    t14PastDueEvents,
    t35PastDueEvents,
    t45PastDueEvents,
    attentionRequiredEvents,
    outForApprovalEvents,
    approvedEvents,
    distributionEvents,
    executedTodayCapitalCalls,
    executedTodayDistributions,
    allEvents: capitalEvents,
  };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case OPERATIONS_SET_CAPITAL_EVENTS:
      return {
        ...state,
        capitalEvents: action.payload,
      };
    case OPERATIONS_UPDATE_CAPITAL_EVENT_STATUS: {
      const indexEvent = state.capitalEvents.findIndex((event) => (
        event.underlying_capital_event?.id === action.payload.id
      ));
      return {
        ...state,
        capitalEvents: [
          ...state.capitalEvents.map((capitalEvent, index) => {
            if (index !== indexEvent) {
              return capitalEvent;
            }
            return {
              ...capitalEvent,
              status: 'Updating',
            };
          }),
        ],
      };
    }
    case OPERATIONS_UPDATE_UND_CAPITAL_EVENTS: {
      const newPayload = action.payload
        .filter((element) => !state.capitalEvents.some((event) => (element.id && event.id === element.id) ||
          (event.underlying_capital_event &&
            event.underlying_capital_event.id === element.underlying_capital_event.id)));

      return {
        ...state,
        capitalEvents: [
          ...state.capitalEvents.map((capitalEvent) => {
            const updatedPayload = action.payload
              .filter((element) => (capitalEvent.id && element.id === capitalEvent.id) ||
                (element.underlying_capital_event &&
                  capitalEvent.underlying_capital_event.id === element.underlying_capital_event.id));
            if (!updatedPayload.length) {
              return capitalEvent;
            }
            const status = ['Cash On Hand', 'Hold Distribution'].includes(updatedPayload[0].status) ? updatedPayload[0].status : capitalEvent.status;

            return {
              ...capitalEvent,
              status,
              owner: updatedPayload[0].owner,
              underlying_capital_event: updatedPayload[0].underlying_capital_event,
            };
          }),
          ...newPayload,
        ].sort(sortDescendingByDueDate),
      };
    }
    case OPERATIONS_DELETE_STANDALONE_UND_CAPITAL_EVENT:
      const index = state.capitalEvents.findIndex((event) => (
        event.underlying_capital_event &&
        event.underlying_capital_event.id === action.payload.underlying_capital_event.id
      ));

      const isStandaloneUnderlyingEvent = !isNumber(state.capitalEvents[index].id);
      if (isStandaloneUnderlyingEvent) {
        return {
          ...state,
          capitalEvents: [
            ...state.capitalEvents.slice(0, index),
            ...state.capitalEvents.slice(index + 1),
          ],
        };
      }

      return {
        ...state,
        capitalEvents: [
          ...state.capitalEvents.map((capitalEvent, idx) => {
            if (index !== idx) {
              return capitalEvent;
            }

            return {
              ...capitalEvent,
              underlying_capital_event: { },
            };
          }),
        ],
      };
    case OPERATIONS_SET_CAPITAL_EVENT_AS_PAID:
      const capitalEventIndex = state.capitalEvents
        .findIndex((e) => e.underlying_capital_event && e.underlying_capital_event.id === action.payload.id);
      if (capitalEventIndex >= 0) {
        return update(state, {
          capitalEvents: {
            $set: update(state.capitalEvents, {
              [capitalEventIndex]: {
                underlying_capital_event: {
                  paid: { $set: true },
                  paid_at: { $set: action.payload.paid_at },
                },
              },
            }),
          },
        });
      }
      break;
    case OPERATIONS_SET_OUTSTANDING_INVESTMENTS:
      return {
        ...state,
        capitalEventOutstandingInvestments: action.payload,
      };
    case OPERATIONS_SET_ALL_CAPITAL_EVENTS_EXPORTS:
      return {
        ...state,
        allCapitalEventsExports: action.payload,
      };
    case OPERATIONS_SET_ALL_OUTSTANDING_INVESTMENTS:
      return {
        ...state,
        allOutstandingInvestments: action.payload,
      };
    case OPERATIONS_SET_FILTERED_OUTSTANDING_INVESTMENTS:
      return {
        ...state,
        filteredOutstandingInvestments: action.payload,
      };
    case OPERATIONS_SET_OUTSTANDING_DISTRIBUTION_REPORTS:
      return {
        ...state,
        filteredOutstandingDistributionReports: action.payload,
      };
    case OPERATIONS_SET_ACTIVE_STAT:
      return update(state, {
        activeStat: { $set: action.payload },
      });
    case OPERATIONS_SET_FILTERED_CAPITAL_EVENTS: {
      const filteredCapitalEvents = filterCapitalEvents(state.capitalEvents);
      return update(state, {
        filteredCapitalEvents: { $set: filteredCapitalEvents },
        isFetching: { $set: false },
      });
    }
    case OPERATIONS_SET_VISIBLE_CAPITAL_EVENT_IDS:
      return update(state, {
        visibleCapitalEventIds: { $set: action.payload },
      });
    case OPERATIONS_SET_CAPITAL_EVENTS_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };
    case OPERATIONS_SET_CAPITAL_EVENTS_ACTIVE_FILTERS:
      return update(state, {
        activeFilters: { $merge: action.payload },
      });
    case OPERATIONS_SET_CAPITAL_EVENTS_CALL_DATE_FILTER:
      return {
        ...state,
        callDateFilter: action.payload,
      };
    case OPERATIONS_SET_CAPITAL_EVENTS_IS_FETCHING:
      return {
        ...state,
        isFetching: action.payload,
      };
    case OPERATIONS_SET_GL_APPROVED:
      const ceIdx = state.capitalEvents.findIndex(c => c.id === action.payload.id)
      return update(state, {
        capitalEvents: {
          $set: update(state.capitalEvents, {
            [ceIdx]: {
                gl_accepted: {$set: true},
                formated_gl_accepted: {$set: action.payload.formated_gl_accepted},
              },
            }),
          },
        });
    case OPERATIONS_SET_LAST_EXPORTED_FILE:
      return {
        ...state,
        lastExportedFile: action.payload,
      };
    case OPERATIONS_SET_HAS_NEW_EXPORTED_FILE:
      return {
        ...state,
        hasNewExportedFile: action.payload,
      };
    default:
      return state;
  }
};
