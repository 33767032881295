import percentageFormatter from 'services/formatters/percentageFormatter';
import { labelFormatterCapitalize } from 'services/formatters/labelFormatter';
import { formatDate, parseDate } from 'services/DateUtils';
import { simpleComparator } from 'services/customComparators';

const capitalEventStatusCellRenderer = ({ value }) => {
  const status = labelFormatterCapitalize({ value });
  return value && status;
};

const capitalEventPaidRenderer = ({ value }) => {
  if (value == null) return '';

  return value.toString() === 'true' ? 'Paid' : 'Not Paid';
};

const capitalEventGlAcceptedRenderer = ({ value }) => {
  if (value == null) return '';
  if (value === 'N/A') return 'N/A';
  return value.toString() === 'true' ? 'Approved' : 'Not Approved';
};

const underlyingEventPercentageCellRenderer = (params) => {
  let cells = '';
  if (params.value) {
    cells = percentageFormatter({ value: params.value / 100 });
  }
  return cells;
};

function compareNums(valueA, valueB) {
  let result;
  const valA = typeof valueA === 'number' ? valueA : null;
  const valB = typeof valueB === 'number' ? valueB : null;
  if (valA === null && valB === null) {
    result = 0;
  } else if (valA === null) {
    result = 1;
  } else if (valB === null) {
    result = -1;
  } else {
    result = simpleComparator(valB, valA);
  }

  return result;
}

function compareDates(fromDate, toDate) {
  let result;
  if (fromDate === null && toDate === null) {
    result = 0;
  } else if (fromDate === null) {
    result = -1;
  } else if (toDate === null) {
    result = 1;
  } else {
    result = fromDate - toDate;
  }
  return result;
}

const isValidDate = (dateObject) => new Date(dateObject).toString() !== 'Invalid Date';

function stringDateComparator(stringFromDate, stringToDate) {
  const fromDate = isValidDate(stringFromDate) ? new Date(stringFromDate) : null;
  const toDate = isValidDate(stringToDate) ? new Date(stringToDate) : null;
  return compareDates(fromDate, toDate);
}

function formatValueByField({ field, value }) {
  const dateFields = [
    'due_date',
    'initiated_at',
    'executed_at',
    'underlying_capital_event.call_date',
    'underlying_capital_event.paid_at',
    'send_reminder',
    'underlying_capital_event.date_received',
    'last_updated_funding_status_date',
  ];
  const cell = {value: value};
  let valueFormatted = value;

  const objectFields = [
    'underlying_fund_managers',
    'fund_distributors',
    'fund_administrators',
  ];

  if (field === 'status') {
    valueFormatted = capitalEventStatusCellRenderer(cell);
  } else if (field === 'underlying_capital_event.paid') {
    valueFormatted = capitalEventPaidRenderer(cell);
  } else if (field === 'gl_accepted') {
    valueFormatted = capitalEventGlAcceptedRenderer(cell);
  } else if (dateFields.includes(field)) {
    valueFormatted = isValidDate(value) && value != null
      ? formatDate(parseDate(value, 'yyyy-MM-dd'), 'dd-MMM-yyyy')
      : value;
  } else if (objectFields.includes(field)) {
    if(value != null && value.length !== 0) {
      valueFormatted = value.map((obj) => obj.name).join();
    }
  } else if (!value) {
    valueFormatted = '';
  }

  return valueFormatted;
}

export {
  stringDateComparator,
  compareNums,
  underlyingEventPercentageCellRenderer,
  capitalEventGlAcceptedRenderer,
  capitalEventPaidRenderer,
  capitalEventStatusCellRenderer,
  formatValueByField,
};
