import {
  DVI_SELECT_FUND,
  DVI_SELECT_DOCUMENT_TYPE,
  DVI_SELECT_ACT_DATE,
  DVI_SELECT_EFF_DATE,
  DVI_SET_FUNDS,
  DVI_SET_DOCUMENT_TYPES,
  DVI_SET_SORTING,
  DVI_LOADING_FUNDS,
  DVI_SET_HIT_RECORDS,
  DVI_UPDATE_PAGINATION,
  DVI_UPDATE_TOTAL_RESULTS,
} from 'actions/actionsConstants';

import update from 'immutability-helper';

const initialState = {
  fund: null,
  documentType: null,
  actionDate: null,
  effectiveDate: null,
  funds: [],
  document_types: [],
  loadingFunds: false,
  hitRecords: [],
  resultsPerPage: 25,
  totalResults: 0,
  currentPage: 1,
  sortingOrder: 'desc',
  sortingColumn: 'created_at',
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case DVI_SET_FUNDS:
      return update(state, {
        funds: {
          $set: payload,
        },
      });
    case DVI_SET_DOCUMENT_TYPES:
      return update(state, {
        document_types: {
          $set: payload,
        },
      });
    case DVI_SET_SORTING:
      return update(state, {
        sortingOrder: {
          $set: payload.sortingOrder,
        },
        sortingColumn: {
          $set: payload.sortingColumn,
        },
        currentPage: {
          $set: 1,
        },
      });
    case DVI_SELECT_FUND:
      return update(state, {
        fund: {
          $set: payload,
        },
        currentPage: {
          $set: 1,
        },
      });
    case DVI_SELECT_DOCUMENT_TYPE:
      return update(state, {
        documentType: {
          $set: payload,
        },
        currentPage: {
          $set: 1,
        },
      });
    case DVI_SELECT_ACT_DATE:
      return update(state, {
        actionDate: {
          $set: payload,
        },
        currentPage: {
          $set: 1,
        },
      });
    case DVI_SELECT_EFF_DATE:
      return update(state, {
        effectiveDate: {
          $set: payload,
        },
        currentPage: {
          $set: 1,
        },
      });
    case DVI_LOADING_FUNDS:
      return update(state, {
        loadingFunds: {
          $set: payload,
        },
      });
    case DVI_SET_HIT_RECORDS:
      return update(state, {
        hitRecords: {
          $set: payload,
        },
      });
    case DVI_UPDATE_TOTAL_RESULTS:
      return update(state, {
        totalResults: {
          $set: payload,
        },
      });
    case DVI_UPDATE_PAGINATION:
      return update(state, {
        currentPage: {
          $set: payload,
        },
      });
    default:
      return state;
  }
};
