import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@icapitalnetwork/supernova-core';

import {
  bool,
  func,
  number,
  string,
} from 'prop-types';
import styles from './Help.module.scss';

const renderDialogContentText = (text, email, phone) => (
  <DialogContentText>
    <b>{text}</b>
    <br />
    <a
      href={`mailto:${email}`}
      className={styles.link}
    >
      {email}
    </a>
    <br />
    {phone}
  </DialogContentText>
);

const privateFundAndHedgeFundSalesSupport = (t) => (
  renderDialogContentText(t('private_fund_and_hedge_fund_sales_support'), 'sales@icapitalnetwork.com', '212-994-7344')
);

const structuredInvestmentsSalesSupport = (t) => (
  renderDialogContentText(t('structured_investments_sales_support'), 'sisales@icapitalnetwork.com', '877-346-7763')
);

const investorRelations = (t) => (
  renderDialogContentText(t('investor_relations'), 'ir@icapitalnetwork.com', '212-994-7333')
);

export function HelpDialog({
  open,
  onClose,
  needAssistanceOverride,
  whiteLabelPartnerId,
}) {
  const { t } = useTranslation('common');

  const defaultContent = () => (
    <>
      {whiteLabelPartnerId === 0 && privateFundAndHedgeFundSalesSupport(t)}
      {whiteLabelPartnerId === 0 && structuredInvestmentsSalesSupport(t)}
      {investorRelations(t)}
    </>
  );

  const needAssistanceOverrideContent = () => (
    <div
      className={styles.content_wrapper}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: needAssistanceOverride }}
    />
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle onClose={onClose}>
        {t('common:need_assistance', 'Need Assistance?')}
      </DialogTitle>
      <DialogContent>
        {needAssistanceOverride ? needAssistanceOverrideContent() : defaultContent()}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" size="medium" onClick={onClose}>
          {t('common:ok', 'Ok')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

HelpDialog.propTypes = {
  needAssistanceOverride: string,
  whiteLabelPartnerId: number,
  onClose: func,
  open: bool,
};

HelpDialog.defaultProps = {
  whiteLabelPartnerId: undefined,
  needAssistanceOverride: undefined,
  onClose: undefined,
  open: undefined,
};

export default HelpDialog;
