import i18n from 'i18n.js';

export const TERMS_PATH = 'https://icapital.com/terms-of-service/';
export const WLP_TERMS_PATH = (mainAppUrl, wlpId) => {
  let url = `${mainAppUrl}/terms_of_use`;
  if (wlpId !== 0) {
    url = `${url}/${wlpId}`;
  }
  return url;
}

export const scrollRequiredLabel =
  i18n.t('disclaimer:please_read_through', 'Please read through the text in full before proceeding');

export default {
  TERMS_PATH,
  WLP_TERMS_PATH,
};
