import configurationReducer from './configurationReducer';
import investmentOpportunityCollectionsReducer from './investmentOpportunityCollection/investmentOpportunityCollectionsReducer';
import marketingFieldsReducer from './marketingFieldsReducer';
import announcementsReducer from './announcement/announcementsReducer';
import insightsEditReducer from './insight/insightsEditReducer';
import announcementReducer from './announcement/announcementReducer';
import announcementTranslationReducer from './announcement/translation/announcementTranslationReducer';
import insightsReducer from './insight/insightsReducer';
import initialState from './initialState';

const fn = [
  configurationReducer,
  announcementReducer,
  marketingFieldsReducer,
  investmentOpportunityCollectionsReducer,
  announcementsReducer,
  announcementTranslationReducer,
  insightsEditReducer,
  insightsReducer,
];

export const reducer = (state = initialState, action) => (
  fn.reduce((stateAccumulator, fnc) => fnc(stateAccumulator, action), state)
);

export default reducer;
