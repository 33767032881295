import moment from 'moment';
import { getRequest, postRequest, deleteRequest } from 'actions/utils';
import { createAction } from 'redux-actions';
import notificationService from 'services/notifications';

import {
  FUND_ADMIN_UPLOADER_SET_RESULT_DOCUMENTS,
  FUND_ADMIN_NAV_UPLOADER_SET_RESULT_DOCUMENTS,
  FUND_ADMIN_UPLOADER_REFRESH_PAGE,
  FUND_ADMIN_NAV_UPLOADER_REMOVE_RESULT_DOCUMENT,
  FUND_ADMIN_NAV_UPLOADER_SET_FUNDS,
  FUND_ADMIN_UPLOADER_SET_HF_REPORT,
} from 'actions/actionsConstants';
import { buildPeriodRange } from 'services/DateUtils';

const setUploaderResultDoc = createAction(FUND_ADMIN_UPLOADER_SET_RESULT_DOCUMENTS);
const setNavUploadResultDoc = createAction(FUND_ADMIN_NAV_UPLOADER_SET_RESULT_DOCUMENTS);
const removeNavUploadResultDoc = createAction(FUND_ADMIN_NAV_UPLOADER_REMOVE_RESULT_DOCUMENT);
const fundAdminUploaderRefreshPage = createAction(FUND_ADMIN_UPLOADER_REFRESH_PAGE);
const setPrivateAccessFunds = createAction(FUND_ADMIN_NAV_UPLOADER_SET_FUNDS);
const setHedgeFundAccountingReport = createAction(FUND_ADMIN_UPLOADER_SET_HF_REPORT);

function getUploadResult(permissions) {
  const sinceDate = moment().subtract(7, 'days').format('YYYY-MM-DD');
  const docTypes = [];

  permissions.forEach((permission) => {
    if (permission.canUploadPcapReport) docTypes.push('PcapUploadResultCSV');
    if (permission.canUploadNavReport || permission.canUploadHfReport) docTypes.push('HedgeFundsUploadResultCSV');
  });

  return getRequest({
    url: '/document_exports',
    params: {
      document_types: docTypes,
      since_date: sinceDate,
    },
    withCredentials: true,
    onSuccess: (data) => setUploaderResultDoc(data),
  });
}

function uploadCapitalBalance(file) {
  return postRequest({
    url: '/private_equity/pcap_uploader',
    data: file,
    withCredentials: true,
    infoMessage: {
      message: 'Your upload may take a few moments. We will notify you when it is done.',
    },
    errorMessage: {
      title: 'Upload Not Successful',
      message: 'An error occurred while uploading the file.',
    },
  });
}

function getNavUploadResult(pafId = '', startDate, endDate) {
  return getRequest({
    url: '/hedge_fund_accounting_reports',
    params: {
      period: buildPeriodRange(startDate, endDate || new Date()),
      private_access_fund_id: pafId,
    },
    withCredentials: true,
    onSuccess: (data) => setNavUploadResultDoc(data),
    onFailure: ({ data: { error } }) => notificationService.notifyError('Error occured when fetching Reports', error),
  });
}

function uploadNav(file) {
  return postRequest({
    url: '/hedge_fund_accounting_reports/upload',
    data: file,
    withCredentials: true,
    infoMessage: {
      message: 'Your upload may take a few moments. We will notify you when it is done.',
    },
    errorMessage: {
      title: 'Upload Not Successful',
      message: 'An error occurred while uploading the file.',
    },
  });
}

function deleteNav(id) {
  return deleteRequest({
    url: `/hedge_fund_accounting_reports/${id}`,
    withCredentials: true,
    onSuccess: () => removeNavUploadResultDoc(id),
  });
}

function downloadCapitalBalanceTemplate() {
  return getRequest({
    url: '/icn_documents/file_path/?doctype=capital_balance_upload_template',
    withCredentials: true,
    onSuccess: (response) => window.open(response.file.url, '_blank'),
  });
}

function downloadNavTemplate() {
  return getRequest({
    url: '/icn_documents/file_path/?doctype=hf_nav_upload_template',
    withCredentials: true,
    onSuccess: (response) => window.open(response.file.url, '_blank'),
  });
}

const getPrivateAccessFundsForUploader = (fundType, showSpinner = true) => (dispatch) => dispatch(
  getRequest({
    url: 'private_access_funds/for_fund_admin',
    params: {
      fund_type: fundType,
      vintage: true,
    },
    onSuccess: setPrivateAccessFunds,
    showSpinner,
  })
);

const getHedgeFundAccountingReport = (reportId, showSpinner = true) => (dispatch) => dispatch(
  getRequest({
    url: `/hedge_fund_accounting_reports/${reportId}`,
    onSuccess: setHedgeFundAccountingReport,
    showSpinner,
  })
);

export default {
  getUploadResult,
  uploadCapitalBalance,
  getNavUploadResult,
  uploadNav,
  deleteNav,
  fundAdminUploaderRefreshPage,
  downloadCapitalBalanceTemplate,
  downloadNavTemplate,
  getPrivateAccessFundsForUploader,
  getHedgeFundAccountingReport,
};
