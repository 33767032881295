import { actionFactory } from 'services/actions';
import { getRequest } from 'actions/utils';
import {
  RGA_SET_REPORT_FIELDS,
  RGA_SET_SELECTED_FIELDS,
} from 'actions/actionsConstants';

import * as filterActions from './filterActions';
import * as numericFilterActions from './numericFilterActions';
import * as transformFilterActions from './transformFilterActions';
import * as dateFilterActions from './dateFilterActions';
import * as stringFilterActions from './stringFilterActions';
import * as booleanFilterActions from './booleanFilterActions';
import {
  reportGeneratorConfigsSetNumericFilterOptions,
  reportGeneratorConfigsRemoveNumericFilteredOption,
  reportGeneratorConfigsSetNumericFilterType,
  reportGeneratorConfigsSetNumericFilterRange,
  reportGeneratorConfigsSetNullOption,
  reportGeneratorConfigsSetStringFilterOptions,
  reportGeneratorConfigsRemoveStringFilteredOption,
  resetStringFilter,
  resetNumericFilter,
} from './optionActions';
import crudActions from '../crudActions';

const rgaSetReportFields = actionFactory(RGA_SET_REPORT_FIELDS);
const rgaSetSelectedFields = actionFactory(RGA_SET_SELECTED_FIELDS);

function getReportGeneratorConfigsFields(
  configId,
  baseQueryId = null,
  superUser = false,
) {
  return (dispatch, getState) => {
    return dispatch(
      getRequest({
        url: `report_generator/configs/${configId}/fields`,
        params: {
          report_generator_base_query_id: baseQueryId,
          super_user: superUser || undefined,
        },
        onSuccess: rgaSetReportFields,
      }),
    );
  };
}

function reportGeneratorConfigsSetSelectedFields(selectedFields) {
  return (dispatch) => {
    dispatch(rgaSetSelectedFields(selectedFields));
    return dispatch(crudActions.saveReportGeneratorConfigs(null));
  };
}

export default {
  ...filterActions,
  ...numericFilterActions,
  ...transformFilterActions,
  ...dateFilterActions,
  ...stringFilterActions,
  ...booleanFilterActions,
  resetStringFilter,
  resetNumericFilter,
  getReportGeneratorConfigsFields,
  reportGeneratorConfigsSetNumericFilterOptions,
  reportGeneratorConfigsRemoveNumericFilteredOption,
  reportGeneratorConfigsSetNumericFilterType,
  reportGeneratorConfigsSetNumericFilterRange,
  reportGeneratorConfigsSetNullOption,
  reportGeneratorConfigsSetStringFilterOptions,
  reportGeneratorConfigsRemoveStringFilteredOption,
  reportGeneratorConfigsSetSelectedFields,
};
