import Loading from 'components/shared/loading/Loading';
import PrivateRoute from 'containers/private_route/PrivateRoute';
import React from 'react';
import Loadable from 'react-loadable';
import { routeCodes } from './router';
import HomepageMFE from './containers/unified_homepage';

const LoadableAnnouncement = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "Announcement" */ 'modules/landing_page/containers/announcement/detail'
    ),
  loading: Loading,
});

const LoadableAnnouncements = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "Announcements" */ 'modules/landing_page/containers/announcement'
    ),
  loading: Loading,
});

const LoadableHome = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "Entry" */ 'modules/landing_page/containers/entry/Entry'
    ),
  loading: Loading,
});

const LoadableInsights = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "Insights" */ 'modules/landing_page/containers/insight'
    ),
  loading: Loading,
});

export default function App() {
  return (
    <>
      <PrivateRoute
        exact
        path={routeCodes.ANNOUNCEMENT}
        component={LoadableAnnouncement}
      />
      <PrivateRoute exact path={routeCodes.HOME} component={LoadableHome} />
      <PrivateRoute
        exact
        path={routeCodes.ANNOUNCEMENTS}
        component={LoadableAnnouncements}
      />
      <PrivateRoute
        key={routeCodes.INSIGHTS}
        path={routeCodes.INSIGHTS}
        component={LoadableInsights}
      />
    </>
  );
}
