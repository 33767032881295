/* eslint-disable default-case */
/* eslint-disable max-statements */
/* eslint-disable camelcase */
import {
  getDateFilterLabel,
  dateFormatter,
  getNumericFilterRangeLabel,
} from '../utils';

// eslint-disable-next-line no-underscore-dangle
export function _getFilterDetails(fieldName, storedQueries, options) {
  const nullOption = storedQueries[fieldName].null_option;
  switch (storedQueries[fieldName].data_type) {
    case 'CURRENCY':
    case 'NUMBER(SCALE 5)':
    case 'NUMBER': {
      let total;
      const appliedRanges = storedQueries[fieldName].ranges;
      const chosenOption = storedQueries[fieldName].chosen_options;
      const appliedFilterType = storedQueries[fieldName].filter_type;

      if (appliedFilterType !== 'value' && appliedRanges && appliedRanges.length === 1) {
        total = `${getNumericFilterRangeLabel(appliedRanges[0], chosenOption)}${nullOption ? ', NULL' : ''}`;
      } else {
        const choosenOptionsLength = storedQueries[fieldName].chosen_options.length;
        total = nullOption ? choosenOptionsLength + 1 : choosenOptionsLength;
      }

      return {
        total,
        filteredOptions: chosenOption,
        appliedRanges,
        appliedFilterType,
        filteredTotals: chosenOption.length,
        toggle_tracker: storedQueries[fieldName].all_options,
        nullOption,
      };
    }
    case 'STRING': {
      let choosenOptionsLength = storedQueries[fieldName].chosen_options.length;
      if (nullOption) {
        choosenOptionsLength++;
      }
      const total = choosenOptionsLength !== 0 ? `${choosenOptionsLength}` : '';

      return {
        total,
        filteredOptions: storedQueries[fieldName].chosen_options,
        appliedRanges: storedQueries[fieldName].ranges,
        filteredTotals: storedQueries[fieldName].chosen_options.length,
        toggle_tracker: storedQueries[fieldName].all_options,
        option: options[fieldName],
        nullOption,
      };
    }
    case 'POSTPROCESS':
    case 'TRANSFORM': {
      const total = storedQueries[fieldName].value.map((option) =>
        option === '' ? '(Blank)' : option,
      );

      if (nullOption) {
        total.push('NULL');
      }

      return {
        total: total.join(),
        nullOption,
      };
    }
    case 'DATETIME':
    case 'DATE': {
      const {
        from,
        to,
        relative_date,
        from_duration: fromDuration,
        to_duration: toDuration,
        from_sign: fromSign,
        to_sign: toSign,
        from_month_span: fromMonthSpan,
        to_month_span: toMonthSpan,
      } = storedQueries[fieldName].range;
      const fromValue = relative_date ? from : dateFormatter(from);
      const toValue = relative_date ? to : dateFormatter(to);

      return {
        total: getDateFilterLabel(
          from,
          to,
          relative_date,
          fromDuration,
          toDuration,
          fromSign,
          toSign,
          fromMonthSpan,
          toMonthSpan,
        ),
        option: {
          from: fromValue,
          to: toValue,
          relative_date,
          fromDuration,
          toDuration,
          fromSign,
          toSign,
          fromMonthSpan,
          toMonthSpan,
        },
      };
    }
    case 'BOOLEAN': {
      const boolean_values = Array.isArray(storedQueries[fieldName].value)
        ? storedQueries[fieldName].value
        : [storedQueries[fieldName].value];
      const totalsArray = [...boolean_values];

      if (nullOption) {
        totalsArray.push('NULL');
      }

      return {
        total: totalsArray.join(),
        option: { value: boolean_values },
        nullOption,
      };
    }
    default:
      return { total: '' };
  }
}

const formattedConfigFields = (configFields) =>
  configFields.reduce((object, field) => {
    if (Object.keys(field.filter).length === 0) return object;

    const dataType =
      field.field_type === 'Currency'
        ? 'NUMBER'
        : field.field_type.toUpperCase();
    const fieldValue = {
      ...field.filter,
      field: field.field_method,
      data_type: dataType,
      display_name: field.display_name,
    };

    return { ...object, [field.display_name]: fieldValue };
  }, {});

export function getFilterConfigs(configFields, state) {
  const { configurableId } = state;
  const selectedFields = {
    ...state.selectedFields,
    [configurableId]: configFields.filter(({ selected }) => selected).map(({ field_method }) => field_method),
  };
  const filteredOptions = { ...state.filteredOptions, [configurableId]: {} };
  const totals = { ...state.totals, [configurableId]: {} };
  const appliedRanges = { ...state.appliedRanges, [configurableId]: {} };
  const appliedFilterType = {
    ...state.appliedFilterType,
    [configurableId]: {},
  };
  const filteredTotals = { ...state.filteredTotals, [configurableId]: {} };
  const toggle_tracker = { ...state.toggle_tracker, [configurableId]: {} };
  const options = {
    ...state.options,
    [configurableId]: { ...state.options[configurableId] },
  };
  const storedQueries = { ...state.storedQueries, [configurableId]: {} };
  const nullOptions = { ...state.nullOptions, [configurableId]: {} };
  const disableSave = { ...state.disableSave, [configurableId]: {} };
  let filterQuery;

  if (configFields.length > 0) {
    storedQueries[configurableId] = formattedConfigFields(configFields);
    Object.keys(storedQueries[configurableId]).forEach((fieldName) => {
      filterQuery = _getFilterDetails(
        fieldName,
        storedQueries[configurableId],
        options[configurableId],
      );

      totals[configurableId][fieldName] = filterQuery.total;

      if (filterQuery.filteredOptions != undefined) {
        filteredOptions[configurableId][fieldName] =
          filterQuery.filteredOptions;
      }

      if (filterQuery.appliedRanges != undefined) {
        appliedRanges[configurableId][fieldName] = filterQuery.appliedRanges;
      }

      if (filterQuery.appliedFilterType != undefined) {
        appliedFilterType[configurableId][fieldName] =
          filterQuery.appliedFilterType;
      }

      if (filterQuery.filteredTotals != undefined) {
        filteredTotals[configurableId][fieldName] = filterQuery.filteredTotals;
      }

      if (filterQuery.toggle_tracker != undefined) {
        toggle_tracker[configurableId][fieldName] = filterQuery.toggle_tracker;
      }

      if (filterQuery.option != undefined) {
        options[configurableId][fieldName] = filterQuery.option;
      }

      if (filterQuery.nullOption != undefined) {
        nullOptions[configurableId][fieldName] = filterQuery.nullOption;
      }
    });
  }
  return {
    options,
    totals,
    stored_queries: storedQueries,
    filteredOptions,
    appliedRanges,
    appliedFilterType,
    filteredTotals,
    toggle_tracker,
    nullOptions,
    initialOptions: options,
    disableSave,
    selectedFields,
  };
}
