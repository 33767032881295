/* eslint-disable max-lines */
import axios from 'axios';
import _ from 'lodash';
import queryString from 'query-string';
import { createAction } from 'redux-actions';
import notificationService from 'services/notifications';

import {
  FETCH_INVESTOR_PROFILE,
  SET_INVESTOR_PROFILE,
  FETCH_CUSTODIANS_FOR_CONTACT_CARD,
  IP_SET_INITIAL_VALUES,
  SET_CUSTODIANS,
  SET_PROFILE_FIELDS,
  SET_CHOOSE_TO_PARTICIPATE,
  SET_NEW_ISSUES_QUESTIONS,
  SET_NEW_ISSUES_NONE_APPLY,
  SET_NEW_ISSUES_DEFAULT,
  SET_CUSTODIAN_ACCOUNT_FIELD,
  ADD_INTERESTED_PARTY,
  REMOVE_INTERESTED_PARTY,
  UPDATE_INTERESTED_PARTY,
  RESET_INTERESTED_PARTY_ERRORS,
  REMOVE_ALL_INTERESTED_PARTIES,
  RESET_INTERESTED_PARTIES_ERRORS,
  ADD_SIGNER,
  REMOVE_SIGNER,
  UPDATE_SIGNER,
  RESET_CUSTODIAN_ACCOUNT,
  SET_HAS_CUSTODIAN,
  SET_CURRENT_CUSTODIAN,
  SET_PROFILE_ERRORS,
  SET_INTERESTED_PARTIES_ERRORS,
  SET_CUSTODIAN_ACCOUNT_ERRORS,
  RESET_CUSTODIAN_ACCOUNT_ERRORS,
  SET_SIGNERS_ERRORS,
  SET_BATCH_UPDATE_OPTIONS,
  SET_PROFILE_COUNTRIES,
  SET_CONDITIONAL_COUNTRIES,
  SET_PAF_EXCEPTION_APPLIES,
  FETCH_INVESTOR_PROFILE_COMPLETENESS,
  SET_INVESTOR_PROFILE_COMPLETENESS,
  SET_INVESTOR_PROFILE_SIGNER_TYPES,
  SET_INVESTOR_PROFILE_VISIBILITY,
  CHECK_AND_SET_API_ERRORS,
  REMOVE_SIGNER_ERROR,
  RESET_SIGNER_ERRORS,
  ADD_SIGNER_ERROR,
  WHITE_LABEL_SIDE_EFFECTS,
  RAYMOND_JAMES_SIDE_EFFECTS,
  SET_INBOUND_ANGULAR_QUERY_PARAMS,
  SAVE_NEW_ISSUES_PROFILE,
  SET_PROFILE_WLP_CONFIG,
  SET_SIGNERS_BACKUP,
  SET_INTERESTED_PARTIES_BACKUP,
  SET_PROFILE_COUNTRY_BACKUP,
  INVESTOR_PROFILE_UNMOUNT,
  SET_CUSTOM_ANALYZERS,
} from 'actions/actionsConstants';

import spinnerActions from 'actions/spinner_actions/spinnerActions';
import sideEffects from 'actions/investor_profile_actions/helpers/side_effects/index';
import initialValues from 'actions/investor_profile_actions/helpers/initialValues';
import hasCustodianResets from 'actions/investor_profile_actions/helpers/hasCustodianResets';
import newIssuesProfileCompleteness from 'actions/investor_profile_actions/helpers/newIssuesProfileCompleteness';
import paths from 'actions/investor_profile_actions/helpers/paths';
import newProfileFromExistingProfile from 'actions/investor_profile_actions/helpers/newProfileValues';
import { decoratedCustodianAccountName } from 'services/investor_profile/custodianAccountName';

import {
  checkProfileValidations,
  checkCustodianValidations,
  checkInterestedPartiesValidations,
  checkSignersValidations,
} from 'actions/investor_profile_actions/helpers/errors/checkValidations';

import {
  checkSignersUniquenessValidations,
} from 'actions/investor_profile_actions/helpers/errors/checkUniquenessValidations';

import checkEmptySignerEmailValidation from 'actions/investor_profile_actions/helpers/errors/checkEmptySignerEmailValidations';
import checkEmptyInterestedPartyEmailValidation from 'actions/investor_profile_actions/helpers/errors/checkEmptyInterestedPartyEmailValidation';

import countryFieldResets from 'actions/investor_profile_actions/helpers/countryFieldsResets';

import {
  removeAndShiftBeneficialOwners,
  newBeneficialOwner,
} from 'services/investor_profile/beneficialOwner';

import {
  extractDelimitedValues,
  isWritableField,
} from 'services/investor_profile/index';

import { isMainSitePafAdmin as isMainSitePafAdminSelector } from 'selectors/icnBootstrapSelectors';
import { profileFieldVisibilitySelector } from 'reducers/investor_profile_reducers/selectors';

const {
  beginApiRequest,
  endApiRequest,
  failedApiRequest,
} = spinnerActions;

function axiosPromiseCreator(dispatch, requestConfig) {
  dispatch(beginApiRequest());

  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      withCredentials: true,
      ...requestConfig,
    })
      .then((response) => response.data)
      .then((json) => {
        dispatch(endApiRequest());
        resolve(json);
      })
      .catch((error) => {
        dispatch(failedApiRequest(error));
        reject(error);
      });
  });
}

function fetchInvestorProfile(profileId) {
  return (dispatch) => {
    dispatch({
      type: FETCH_INVESTOR_PROFILE,
    });

    const requestConfig = {
      url: paths.investorProfilePath(profileId),
    };

    return axiosPromiseCreator(dispatch, requestConfig);
  };
}

function setInvestorProfile(payload) {
  return {
    type: SET_INVESTOR_PROFILE,
    payload,
  };
}

function addInterestedParty() {
  return {
    type: ADD_INTERESTED_PARTY,
  };
}

function createNewInvestorProfile(pafId, pafOverride) {
  return (dispatch, getState) => {
    const { user, white_label_partner: whiteLabelPartner } = getState().icnReactBootstrap.icn_react_bootstrap;

    const payload = newProfileFromExistingProfile(user.profile);
    const name = user.name;
    payload.signer_type = '0';
    payload.number_of_investments = 0;
    payload.section_a_name_of_investor = name;
    payload.wire_instructions_account_with_us_bank = '0';

    if (whiteLabelPartner.suppress_investor_communications_default) {
      payload.section_b_ria_access = '0';
      payload.section_b_email_only_ria = '1';
    }

    dispatch({
      type: SET_PROFILE_FIELDS,
      payload,
    });

    dispatch(fetchInvestorProfileSignerTypes());
    dispatch(setSignersBackup(payload.signers));
    dispatch(setInterestedPartiesBackup(payload.interested_parties));
    dispatch(setProfileCountryBackup(payload));
    dispatch(setInitialProfileValues());
    dispatch(fetchCustodiansForContactCard(pafId, pafOverride)).then(() => {
      dispatch(resetCustodianAccount());
      dispatch(setInitialCurrentCustodian());
      dispatch(setHasCustodian(false, true));
    });

    const promises = [
      fetchAllIcnGeoCountries(dispatch),
      fetchViewableIcnGeoCountries(dispatch),
      fetchInvestorProfileIcnGeoCountries(
        dispatch,
        null,
        null
      ),
      bootstrapWlpConfig(dispatch, getState),
    ];
    Promise.all(promises).then((results) => {
      dispatch(
        setProfileCountries({
          all: results[0].icn_geo_countries,
          viewable: results[1].icn_geo_countries,
          paf_whitelisted: results[2].icn_geo_countries,
          is_ria: user.isRia,
        })
      );
      dispatch(setConditionalCountries());
      dispatch(resetCountryFields());
    });
  };
}

function bootstrapInvestorProfileCountries(profileId) {
  return (dispatch, getState) => {
    const user = getState().icnReactBootstrap.icn_react_bootstrap.user;

    setPafExceptionApplied(dispatch, user.isRia, profileId).then(() => {
      const batchUpdateLive = _.get(
        getState().investor_profile,
        ['batch_update_options', 'live']
      );
      const promises = [
        fetchAllIcnGeoCountries(dispatch),
        fetchViewableIcnGeoCountries(dispatch),
        fetchInvestorProfileIcnGeoCountries(dispatch, profileId, batchUpdateLive),
      ];
      Promise.all(promises).then((results) => {
        dispatch(setProfileCountries({
          all: results[0].icn_geo_countries,
          viewable: results[1].icn_geo_countries,
          paf_whitelisted: results[2].icn_geo_countries,
          is_ria: user.isRia,
        }));
        dispatch(setConditionalCountries());
        dispatch(resetCountryFields());
      });
    });
  };
}

function setInboundAngularQueryParams(searchString, invProfId) {
  return (dispatch) => {
    let {
      investment_status,
      returnUrl,
      investment_id,
      from,
      irPath,
      private_access_fund_id,
      contact_card_id,
      ffrom,
      commitmentAmount,
    } = queryString.parse(searchString);

    const [
      pafId,
      contactCardId,
    ] = extractDelimitedValues(irPath, '_');

    if (!private_access_fund_id) {
      private_access_fund_id = pafId;
    }

    if (!contact_card_id) {
      contact_card_id = contactCardId;
    }

    const payload = {
      returnUrl,
      from,
      investment_status,
      invProfId,
      investment_id,
      private_access_fund_id,
      contact_card_id,
      ffrom,
      commitmentAmount,
    };

    dispatch({
      type: SET_INBOUND_ANGULAR_QUERY_PARAMS,
      payload,
    });
  };
}

function setCustomAnalyzers(searchString) {
  return (dispatch) => {
    const {
      custom_analyzers,
    } = queryString.parse(searchString, { arrayFormat: 'bracket' });

    dispatch({
      type: SET_CUSTOM_ANALYZERS,
      payload: custom_analyzers,
    });
  };
}

function fetchInvestorProfileCompleteness(profileId, investment_id, custom_analyzers) {
  return (dispatch) => {
    dispatch({
      type: FETCH_INVESTOR_PROFILE_COMPLETENESS,
    });

    const requestConfig = {
      url: paths.investorProfileCompletenessPath(profileId),
      params: {
        investment_id,
        custom_analyzers,
      },
    };

    return axiosPromiseCreator(dispatch, requestConfig);
  };
}

function setInvestorProfileCompleteness(completenessObj) {
  return (dispatch, getState) => {
    const { profile, has_custodian } = getState().investorProfile;
    const { new_issues_profile, signer_type } = profile;
    const newIssuesProfileComplenessObj = newIssuesProfileCompleteness(
      new_issues_profile, signer_type
    );
    const newCompletenessObj = completenessObj;
    if (has_custodian && completenessObj.custodian_id !== 'complete') {
      newCompletenessObj.custodian_id = 'incomplete';
    }

    dispatch({
      type: SET_INVESTOR_PROFILE_COMPLETENESS,
      payload: { ...newCompletenessObj, ...newIssuesProfileComplenessObj },
    });
  };
}

function fetchInvestorProfileFieldVisibility(pafId) {
  return (dispatch, getState) => {
    const { inboundAngularQueryParams } = getState().investorProfile;
    const requestConfig = {
      url: 'investor_profiles/field_visibility',
      params: {
        private_access_fund_id: pafId || inboundAngularQueryParams.private_access_fund_id,
        investment_id: inboundAngularQueryParams.investment_id,
      },
    };

    return axiosPromiseCreator(dispatch, requestConfig);
  };
}

function setInvestorProfileFieldVisibility(visibilityObj) {
  const { profile_visibility, new_issues_visibility } = visibilityObj;
  return {
    type: SET_INVESTOR_PROFILE_VISIBILITY,
    payload: {
      ...profile_visibility,
      new_issues_question_1: new_issues_visibility.question1,
      new_issues_question_2: new_issues_visibility.question2,
    },
  };
}

function contactCardId(investorProfile, icnReactBootstrap) {
  if (investorProfile && investorProfile.profile && !_.isEmpty(investorProfile.profile)) {
    return investorProfile.profile.contact_card.id;
  }
  return icnReactBootstrap.icn_react_bootstrap.user.id;
}

function validateCustodianAccountFields(updateObj, profile, whiteLabelPartner, currentCustodian) {
  return {
    type: SET_CUSTODIAN_ACCOUNT_ERRORS,
    ...checkCustodianValidations(updateObj, profile, whiteLabelPartner, currentCustodian),
  };
}

function setDecoratedInvestorName() {
  return (dispatch, getState) => {
    const {
      current_custodian,
      profile,
    } = getState().investorProfile;
    const decoratedName = decoratedCustodianAccountName(current_custodian, profile.custodian_account);
    dispatch(setProfileFields({
      investor_name: decoratedName,
    }));
  };
}

function setCustodianAccountField(payload) {
  return (dispatch, getState) => {
    const { investorProfile, icnReactBootstrap } = getState();


    const { profile, current_custodian } = investorProfile;


    const { white_label_partner } = icnReactBootstrap.icn_react_bootstrap;

    dispatch(setDecoratedInvestorName());
    dispatch(validateCustodianAccountFields(payload, profile, white_label_partner, current_custodian));
    dispatch({
      type: SET_CUSTODIAN_ACCOUNT_FIELD,
      payload,
    });

    dispatch(wlpSideEffects(investorProfile, white_label_partner, payload));
  };
}

function raymondJamesSideEffects(updatePayload) {
  return (dispatch, getState) => {
    const { investorProfile, icnReactBootstrap } = getState();
    const { profile } = investorProfile;
    const { white_label_partner } = icnReactBootstrap.icn_react_bootstrap;

    if (investorProfile.has_custodian && updatePayload.account_number) {
      if (profile.wlp_name === 'Raymond James') {
        const externalIdSuffix = profile.contact_card.white_label_partner_id;

        dispatch(updateAccountNumExternalId(externalIdSuffix));
      } else if (white_label_partner && white_label_partner.name === 'Raymond James') {
        const externalIdSuffix = white_label_partner.id;

        dispatch(updateAccountNumExternalId(externalIdSuffix));
      }
    }

    dispatch({
      type: RAYMOND_JAMES_SIDE_EFFECTS,
    });
  };
}

function wlpSideEffects(investorProfile, whiteLabelPartner, updatePayload) {
  return (dispatch) => {
    dispatch(raymondJamesSideEffects(updatePayload));
    dispatch({
      type: WHITE_LABEL_SIDE_EFFECTS,
    });
  };
}

function updateAccountNumExternalId(externalIdSuffix) {
  return (dispatch, getState) => {
    const { profile } = getState().investorProfile;

    const custodianAccountNum = _.get(profile, 'custodian_account.account_number') || '';
    externalIdSuffix = externalIdSuffix || '';

    if (profile.account_num !== '') {
      dispatch(setProfileFields({ account_num: custodianAccountNum }));
    }

    if (profile.external_id !== '') {
      dispatch(setProfileFields({ external_id: `${custodianAccountNum}#${externalIdSuffix}` }));
    }
  };
}

function setCustodians(payload) {
  return {
    type: SET_CUSTODIANS,
    payload,
  };
}

function fetchCustodiansForContactCard(pafId, pafOverride) {
  return (dispatch, getState) => {
    const { investorProfile, icnReactBootstrap } = getState();

    dispatch({
      type: FETCH_CUSTODIANS_FOR_CONTACT_CARD,
    });

    const requestConfig = {
      url: paths.forContactCard(),
      isArray: true,
      params: {
        contact_card_id: contactCardId(investorProfile, icnReactBootstrap),
        paf_override: pafOverride,
        private_access_fund_id: pafId,
      },
    };

    return axiosPromiseCreator(dispatch, requestConfig).then((custodians) => {
      dispatch(setCustodians(custodians));
    });
  };
}

function resetCustodianAccount(custodianId) {
  return {
    type: RESET_CUSTODIAN_ACCOUNT,
    custodian_id: custodianId,
  };
}

function setCurrentCustodian(id, skipResets = false) {
  return (dispatch, getState) => {
    const { custodians } = getState();

    const currentCustodian = _.find(custodians, { id }) || {};

    dispatch({
      type: RESET_CUSTODIAN_ACCOUNT_ERRORS,
    });

    dispatch({
      type: SET_CURRENT_CUSTODIAN,
      payload: currentCustodian,
    });

    if (!skipResets) {
      dispatch(resetCustodianAccount(id));
    }
  };
}

function setInitialCurrentCustodian() {
  return (dispatch, getState) => {
    const { investorProfile, custodians } = getState();


    const { custodian_account } = investorProfile.profile;

    if (!_.isEmpty(custodian_account)) {
      const { custodian_id } = custodian_account;


      const currentCustodian = _.find(custodians, { id: custodian_id }) || {};

      if (!_.isEmpty(currentCustodian)) {
        dispatch(setCurrentCustodian(custodian_id, true));
      }
    }
  };
}

function validateFields(updateObj, profile, whiteLabelPartner) {
  return {
    type: SET_PROFILE_ERRORS,
    ...checkProfileValidations(updateObj, profile, whiteLabelPartner),
  };
}

function setAdjustedProfileFields(payload) {
  return {
    type: SET_PROFILE_FIELDS,
    payload,
  };
}

function setProfileCountryBackup(profile) {
  const payload = _.pick(profile, [
    'section_a_individual_state_country',
    'section_a_entity_state_country',
    'section_a_mailing_state_country',
    'entity_jurisdiction_of_organization_country',
    'entity_domicile_country',
  ]);

  return {
    type: SET_PROFILE_COUNTRY_BACKUP,
    payload,
  };
}


function setProfileFields(updateObj) {
  return (dispatch, getState) => {
    const state = getState();
    const { investorProfile, icnReactBootstrap } = state;
    const profile = investorProfile.profile;
    const has_custodian = investorProfile.has_custodian;
    const { white_label_partner } = icnReactBootstrap.icn_react_bootstrap;
    const profileFieldVisibility = profileFieldVisibilitySelector(state);
    const isMainSitePafAdmin = isMainSitePafAdminSelector(state);
    const updates = sideEffects(updateObj, profile, has_custodian, profileFieldVisibility, isMainSitePafAdmin);

    dispatch(setAdjustedProfileFields(updates));
    dispatch(validateFields(updates, profile, white_label_partner));
    if (updates.signer_type || updates.section_a_entity_type) {
      dispatch(setConditionalCountries());
      dispatch(resetCountryFields());
      dispatch(resetIncomeAndNetWorthFields());
    }
  };
}

function resetCountryFields(updateObj) {
  return (dispatch, getState) => {
    const { investorProfile, icnReactBootstrap } = getState();
    const profile = investorProfile.profile;
    const countries = investorProfile.countries;
    const { white_label_partner } = icnReactBootstrap.icn_react_bootstrap;
    const profileCountryBackup = investorProfile.country_backup;
    const changes = countryFieldResets(profile, countries, profileCountryBackup);

    if (!_.isEmpty(changes)) {
      dispatch(setAdjustedProfileFields(changes));
      dispatch(validateFields(changes, profile, white_label_partner));
    }
  };
}

function resetIncomeAndNetWorthFields(updateObj) {
  return (dispatch, getState) => {
    const {
      investorProfile,
      icnReactBootstrap,
    } = getState();
    const profile = investorProfile.profile;
    const { white_label_partner } = icnReactBootstrap.icn_react_bootstrap;

    const changes = {
      section_e_income_1: '',
      section_e_income_2: '',
      section_e_marital: '',
      section_e_net_worth: '',
      section_e_net_worth_2: '',
    };

    if (!_.isEmpty(changes)) {
      dispatch(setAdjustedProfileFields(changes));
      dispatch(validateFields(changes, profile, white_label_partner));
    }
  };
}

function setConditionalCountries() {
  return {
    type: SET_CONDITIONAL_COUNTRIES,
  };
}

function setPafExceptionApplies(payload) {
  return {
    type: SET_PAF_EXCEPTION_APPLIES,
    payload,
  };
}

function setProfileCountries(payload) {
  return {
    type: SET_PROFILE_COUNTRIES,
    payload,
  };
}

function fetchAllIcnGeoCountries(dispatch) {
  const requestConfig = {
    url: 'icn_geo_countries',
  };

  return axiosPromiseCreator(dispatch, requestConfig);
}

function fetchViewableIcnGeoCountries(dispatch) {
  const requestConfig = {
    url: 'icn_geo_countries',
    params: {
      for_user: true,
    },
  };

  return axiosPromiseCreator(dispatch, requestConfig);
}

function fetchWhiteLabelPartnerConfig() {
  return (dispatch) => {
    const requestConfig = {
      url: 'white_label_partners/current',
    };

    return axiosPromiseCreator(dispatch, requestConfig);
  };
}

function setWhiteLabelPartnerConfig(payload) {
  return {
    type: SET_PROFILE_WLP_CONFIG,
    payload,
  };
}

function setHasCustodian(hasCustodian, skipResets = false) {
  return (dispatch) => {
    if (!skipResets) {
      dispatch(setProfileFields(hasCustodianResets(hasCustodian)));
    }
    return dispatch({
      type: SET_HAS_CUSTODIAN,
      value: hasCustodian,
    });
  };
}

function bootstrapWlpConfig(dispatch, getState) {
  const {
    icn_react_bootstrap: {
      white_label_partner: {
        id: wlpId,
      },
    },
  } = getState().icnReactBootstrap;

  if (_.isNumber(wlpId) && wlpId !== 0) {
    return dispatch(fetchWhiteLabelPartnerConfig(dispatch)).then((wlpConfig) => {
      if (wlpConfig && wlpConfig.white_label_partner) {
        dispatch(setWhiteLabelPartnerConfig(wlpConfig.white_label_partner));
      }
      return Promise.resolve();
    });
  }

  return Promise.resolve();
}

function adjustControlPersonCountry(dispatch, getState) {
  const state = getState();
  const ip = state.investorProfile.profile;
  const controlPersonCountryWritable = isWritableField(state, 'control_person_country');
  const country = ip.control_person_country;
  const defaultCountry = 'United States of America';

  if (
    (country === '' || country === undefined) &&
    controlPersonCountryWritable
  ) {
    dispatch(setProfileFields({ control_person_country: defaultCountry }));
  }
}

function adjustInterestedParties(dispatch, getState) {
  const state = getState();
  const ip = state.investorProfile.profile;
  const intPartyCopyWritable = isWritableField(state, 'section_c_copy_interested_parties');
  const intPartyNameWritable = isWritableField(state, 'interested_party_1_name');
  const intPartyRelationshipWritable = isWritableField(state, 'interested_party_1_relationship_to_investor');
  const intPartyEmailWritable = isWritableField(state, 'interested_party_1_email');
  const intPartyPhoneWritable = isWritableField(state, 'interested_party_1_phone');
  const anyIntPartyFieldsWritable = [
    intPartyNameWritable,
    intPartyRelationshipWritable,
    intPartyEmailWritable,
    intPartyPhoneWritable,
  ].find((p) => !!p);

  // ensure fields are writable before adding an int party
  if (!intPartyCopyWritable ||
    !anyIntPartyFieldsWritable) {
    return false;
  }

  if (ip.section_c_copy_interested_parties === '1' &&
    ip.interested_parties === undefined) {
    return dispatch(addInterestedParty());
  }

  return false;
}

function adjustProfileAttributes(dispatch, getState) {
  adjustControlPersonCountry(dispatch, getState);
  adjustInterestedParties(dispatch, getState);
}

function setNewIssuesDefault() {
  return {
    type: SET_NEW_ISSUES_DEFAULT,
  };
}

function fetchInvestorProfileSignerTypes() {
  return (dispatch) => axiosPromiseCreator(dispatch, { url: paths.investorProfileSignerTypes() })
    .then((json) => {
      dispatch({
        type: SET_INVESTOR_PROFILE_SIGNER_TYPES,
        payload: json,
      });
    });
}
function setHasInvestorProfileTemplate(investmentId) {
  return (dispatch) => axiosPromiseCreator(
    dispatch,
    {
      url: paths.newInvestorProfilePath(),
      params: {
        investment_id: investmentId,
      },
    }
  );
}

function bootstrapInvestorProfile(profileId, pafId, pafOverride, newIssuesOverride) {
  return (dispatch, getState) => {
    dispatch(fetchInvestorProfile(profileId)).then((json) => {
      const investorProfile = json.investor_profile;
      const { inboundAngularQueryParams, custom_analyzers } = getState().investorProfile;

      dispatch(setInvestorProfile(investorProfile));
      dispatch(setInitialProfileValues());
      dispatch(bootstrapInvestorProfileCountries(profileId));
      dispatch(setSignersBackup(investorProfile.signers));
      dispatch(setInterestedPartiesBackup(investorProfile.interested_parties));
      dispatch(setProfileCountryBackup(investorProfile));

      dispatch(fetchInvestorProfileFieldVisibility(pafId)).then((visibilityJson) => {
        dispatch(setInvestorProfileFieldVisibility(visibilityJson));
      });

      dispatch(fetchInvestorProfileCompleteness(profileId, inboundAngularQueryParams.investment_id, custom_analyzers)).then((completenessJson) => {
        dispatch(setInvestorProfileCompleteness(completenessJson.by_field));
      });

      dispatch(fetchCustodiansForContactCard(pafId, pafOverride)).then(() => {
        const hasCustodianInitially = investorProfile.custodian_account_id > 0;

        dispatch(setInitialCurrentCustodian());
        dispatch(setHasCustodian(hasCustodianInitially, true));
      });

      dispatch(fetchInvestorProfileSignerTypes());

      bootstrapWlpConfig(dispatch, getState).then(() => {
        adjustProfileAttributes(dispatch, getState);

        // TODO, check if newIssues fields hidden via WLP admin
        if (newIssuesOverride && _.isEmpty(investorProfile.new_issues_profile)) {
          dispatch(setNewIssuesDefault());
        }
      });
    });
  };
}

function fetchInvestorProfileIcnGeoCountries(dispatch, investorProfileId, batchUpdate) {
  const requestConfig = {
    url: 'icn_geo_countries/for_investor_profile',
    params: {
      investor_profile_id: investorProfileId,
      apply_to_live: batchUpdate,
    },
  };

  return axiosPromiseCreator(dispatch, requestConfig);
}

function setPafExceptionApplied(dispatch, isRia, profileId) {
  return new Promise((resolve, reject) => {
    if (isRia) {
      fetchPafExceptionApplied(dispatch, profileId).then((data) => {
        dispatch(setPafExceptionApplies(data.result));
        resolve();
      }).catch((error) => {
        reject(error);
      });
    } else {
      resolve();
    }
  });
}

function fetchPafExceptionApplied(dispatch, profileId) {
  const requestConfig = {
    url: `user_paf_exceptions/check_exception_applied/${profileId}`,
  };

  return axiosPromiseCreator(dispatch, requestConfig);
}

function setInitialProfileValues() {
  return (dispatch, getState) => {
    const { profile, has_custodian } = getState().investorProfile;


    const payload = initialValues(profile, has_custodian);

    dispatch({
      type: IP_SET_INITIAL_VALUES,
      payload,
    });
  };
}

function profileAdjustments() {
  return (dispatch, getState) => {
    const { custodian_account } = getState().investorProfile.profile;

    const strippedAccountNumber = (custodian_account.account_number || ' ').replace(/\s/g, '');

    dispatch(setCustodianAccountField({ account_number: strippedAccountNumber }));
  };
}

function custodianAccountNumberServerError(errorData) {
  return {
    type: SET_CUSTODIAN_ACCOUNT_ERRORS,
    errors: {
      account_number: [errorData[1]],
    },
    errorless: [],
  };
}

function financialAdvisorEmailServerError(errorMsg) {
  return {
    type: SET_PROFILE_ERRORS,
    errors: {
      section_b_ria_email: [errorMsg],
    },
    errorless: [],
  };
}

function emailServerError(errorMsg, idx = 0) {
  return {
    type: SET_SIGNERS_ERRORS,
    errors: {
      email: [errorMsg],
    },
    errorless: [],
    idx,
  };
}

function checkAndSetServerError(error) {
  if (!_.get(error, 'data.error')) { return {}; }

  const { data } = error;

  const unknownError = data.error[0] === 'unknown';
  const custodianAccountError = data.error[0] === 'custodian_account_number';
  const emailError = (data.error.email && data.error.email[0] === 'has already been taken') ||
    data.error[0] === 'email';
  const investorProfileNoRefCodeError = (data.error === 'Investor profile without referral code');
  const invalidFinancialAdvisorError = (data.error === 'Invalid financial advisor email');

  return (dispatch, getState) => {
    if (custodianAccountError) {
      dispatch(custodianAccountNumberServerError(data.error));
    } else if (emailError) {
      const errorMsg = 'Email address entered is already in use';
      const { signers } = getState().investorProfile.profile;
      let idx = signers.findIndex((e) => e.email?.toLowerCase() === data.error[2]);
      if (idx === -1) { idx = 0; }
      dispatch(emailServerError(errorMsg, idx));
    } else if (invalidFinancialAdvisorError) {
      const errorMsg = 'The e-mail entered is not linked to an Advisor on this investment\'s team code.' +
        ' Please enter the e-mail of a valid Advisor.';
      dispatch(financialAdvisorEmailServerError(errorMsg));
    } else if (investorProfileNoRefCodeError) {
      const errorMsg = 'There are no Advisor team codes assigned to this account.' +
        ' Please contact Support for additional information.';
      dispatch(financialAdvisorEmailServerError(errorMsg));
    } else if (unknownError) {
      notificationService.notifyError('', data.error[1]);
    }
    dispatch({ type: CHECK_AND_SET_API_ERRORS });
    dispatch(endApiRequest());
  };
}

function saveNewIssuesProfile(redirectCallback) {
  return (dispatch, getState) => {
    dispatch(beginApiRequest());

    const { profile, batch_update_options: batchUpdateOptions } = getState().investorProfile;
    const { new_issues_profile: newIssuesProfile } = profile;

    newIssuesProfile.batch_update = batchUpdateOptions;
    let url = paths.newIssuesProfilePath(newIssuesProfile.id);
    let method = 'patch';

    if (!newIssuesProfile.id) {
      newIssuesProfile.investor_profile_id = profile.id;
      url = 'new_issues_profiles';
      method = 'post';
    }

    dispatch({
      type: SAVE_NEW_ISSUES_PROFILE,
      data: newIssuesProfile,
    });

    return axios({
      url,
      method,
      withCredentials: true,
      data: newIssuesProfile,
    })

      .then((response) => {
        dispatch(bootstrapInvestorProfile(response.data.investor_profile_id, null, null));
      })

      .then(() => {
        redirectCallback();
        dispatch(endApiRequest());
      })

      .catch((error) => {
        dispatch(endApiRequest());
        dispatch(failedApiRequest(error));
        dispatch(checkAndSetServerError(error));
        redirectCallback();
      });
  };
}

function saveInvestorProfile(callback) {
  return (dispatch, getState) => {
    dispatch(beginApiRequest());
    dispatch(profileAdjustments());
    const investorProfileState = getState().investorProfile;
    const { profile } = investorProfileState;
    const { batch_update_options, inboundAngularQueryParams, custom_analyzers } = investorProfileState;
    const { id } = profile;
    const { live, frozen } = batch_update_options;
    // update the backup value to whatever the curent value is
    dispatch(setProfileCountryBackup(profile));
    if (id === undefined) {
      return axios({
        url: 'investor_profiles',
        method: 'post',
        withCredentials: true,
        data: {
          profile,
          skip_custodian_validation: true,
        },
      })

        .then((response) => response.data)

        .then((data) => {
          dispatch(endApiRequest());
          const payload = data.investor_profile;
          dispatch({
            type: SET_PROFILE_FIELDS,
            payload,
          });
          dispatch(setSignersBackup(payload.signers));
          dispatch(setInterestedPartiesBackup(payload.interested_parties));

          dispatch(bootstrapInvestorProfile(data.investor_profile.id, null, null));
          callback();
        })

        .catch((error) => dispatch(failedApiRequest(error)));
    }
    profile.investment_id = inboundAngularQueryParams.investment_id;
    profile.from = inboundAngularQueryParams.from;
    profile.ffrom = inboundAngularQueryParams.ffrom;
    return axios({
      url: paths.investorProfilePath(id),
      method: 'patch',
      withCredentials: true,
      data: {
        batch_update: { live, frozen },
        id,
        profile,
        skip_custodian_validation: true,
      },
    })

      .then((response) => response.data)

      .then((data) => {
        dispatch(fetchInvestorProfileCompleteness(id, profile.investment_id, custom_analyzers)).then((json) => {
          dispatch(setInvestorProfileCompleteness(json.by_field));
          dispatch(endApiRequest());
          const payload = data.investor_profile;
          dispatch({
            type: SET_PROFILE_FIELDS,
            payload,
          });
          dispatch(setSignersBackup(payload.signers));
          dispatch(setInterestedPartiesBackup(payload.interested_parties));
          callback();
        });
      })

      .catch((error) => {
        dispatch(failedApiRequest(error));
        dispatch(checkAndSetServerError(error));
      });
  };
}

function removeBeneficialOwner(owner) {
  return (dispatch, getState) => {
    const updateObj = removeAndShiftBeneficialOwners(owner, getState().investorProfile.profile);
    dispatch(setProfileFields(updateObj));
  };
}

function addBeneficialOwner(owner) {
  return (dispatch, getState) => {
    const country = getState().investorProfile.profile[`beneficial_owners_${owner}_owner_address_country`];


    const updateObj = newBeneficialOwner(owner, country);

    dispatch(setProfileFields(updateObj));
  };
}

function setChooseToParticipate(bool) {
  return {
    type: SET_CHOOSE_TO_PARTICIPATE,
    payload: bool,
  };
}

function setNewIssuesQuestions(question, payload) {
  return {
    type: SET_NEW_ISSUES_QUESTIONS,
    payload,
    question,
  };
}

function setNewIssuesNoneApply(question, field, payloadBool) {
  return {
    type: SET_NEW_ISSUES_NONE_APPLY,
    payload: payloadBool,
    field,
    question,
  };
}

function removeInterestedParty(idx) {
  return (dispatch) => {
    dispatch({
      type: SET_INTERESTED_PARTIES_ERRORS,
      errors: [],
      errorless: [],
      idx,
    });
    dispatch({
      idx,
      type: RESET_INTERESTED_PARTY_ERRORS,
    });
    dispatch({
      idx,
      type: REMOVE_INTERESTED_PARTY,
    });
  };
}

function removeAllInterestedParties() {
  return (dispatch) => {
    dispatch({
      type: RESET_INTERESTED_PARTIES_ERRORS,
    });
    dispatch({
      type: REMOVE_ALL_INTERESTED_PARTIES,
    });
  };
}

function setInterestedPartiesErrors(idx, key, value) {
  return (dispatch, getState) => {
    const { investorProfile, icnReactBootstrap } = getState();


    const { profile } = investorProfile;


    const { white_label_partner } = icnReactBootstrap.icn_react_bootstrap;

    dispatch({
      type: SET_INTERESTED_PARTIES_ERRORS,
      ...checkInterestedPartiesValidations({ [key]: value }, profile, white_label_partner, idx),
      idx,
    });
  };
}

function updateInterestedParty(idx, key, value) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_INTERESTED_PARTY,
      idx,
      key,
      value,
    });
    dispatch(setInterestedPartiesErrors(idx, key, value));

    if (key === 'email') {
      dispatch(setEmptyInterestedPartyEmailError(idx, value));
    }
  };
}

function addRemoveSignerErrors(fields, errorMsg, add, dispatch) {
  const type = add ? ADD_SIGNER_ERROR : REMOVE_SIGNER_ERROR;

  fields.forEach((fullFieldStr) => {
    const [signer, idx, field] = fullFieldStr.split('.');

    dispatch({
      type,
      errorMsg,
      field,
      idx,
    });
  });
}

function setSignersErrors(idx, field, value) {
  return (dispatch, getState) => {
    const { investorProfile, icnReactBootstrap } = getState();

    const { profile } = investorProfile;

    const { white_label_partner } = icnReactBootstrap.icn_react_bootstrap;

    dispatch({
      type: SET_SIGNERS_ERRORS,
      ...checkSignersValidations({ [field]: value }, profile, white_label_partner),
      idx,
    });

    const { uniqueVals, duplicateVals, errorMsg } = checkSignersUniquenessValidations(field, profile);

    addRemoveSignerErrors(duplicateVals, errorMsg, true, dispatch);
    addRemoveSignerErrors(uniqueVals, errorMsg, false, dispatch);
  };
}

function setEmptySignerEmailError(idx, value) {
  return (dispatch, getState) => {
    const { investorProfile } = getState();
    const { profile, signers_backup } = investorProfile;
    const signerBackup = signers_backup[idx] || {};
    const previousEmail = signerBackup.email;
    const signer = profile.signers[idx];

    const errorMsg = checkEmptySignerEmailValidation(signer, previousEmail, value);

    if (!_.isNil(errorMsg)) {
      addRemoveSignerErrors([`signer.${idx}.email`], errorMsg, true, dispatch);
    }
  };
}

function setEmptyInterestedPartyEmailError(idx, value) {
  return (dispatch, getState) => {
    const { investorProfile } = getState();
    const { interested_parties_backup } = investorProfile;
    const interestedPartiesBackup = interested_parties_backup[idx] || {};
    const previousEmail = interestedPartiesBackup.email;

    const errorMsg = checkEmptyInterestedPartyEmailValidation(previousEmail, value);
    const validationObject = { errors: {}, errorless: [] };

    if (!_.isNil(errorMsg)) {
      validationObject.errors.email = [errorMsg];
    }

    dispatch({
      type: SET_INTERESTED_PARTIES_ERRORS,
      ...validationObject,
      idx,
    });
  };
}

function setSignersBackup(signers) {
  return {
    type: SET_SIGNERS_BACKUP,
    payload: signers || [],
  };
}

function setInterestedPartiesBackup(parties) {
  return {
    type: SET_INTERESTED_PARTIES_BACKUP,
    payload: parties || [],
  };
}

function addSigner() {
  return {
    type: ADD_SIGNER,
  };
}

function removeSigner(idx) {
  return (dispatch) => {
    dispatch({
      type: RESET_SIGNER_ERRORS,
      idx,
    });
    dispatch({
      idx,
      type: REMOVE_SIGNER,
    });
    dispatch({
      type: SET_SIGNERS_ERRORS,
      errors: {},
      errorless: [],
      idx,
    });
  };
}

function updateSigner(idx, key, value) {
  return (dispatch, getState) => {
    dispatch({
      type: UPDATE_SIGNER,
      idx,
      key,
      value,
    });
    dispatch(setSignersErrors(idx, key, value));

    if (key === 'email') {
      dispatch(setEmptySignerEmailError(idx, value));
    }
  };
}

function setBatchUpdateOptions(payload) {
  return {
    type: SET_BATCH_UPDATE_OPTIONS,
    payload,
  };
}

const investorProfileUnmount = createAction(INVESTOR_PROFILE_UNMOUNT);

export default {
  bootstrapInvestorProfile,
  bootstrapInvestorProfileCountries,
  fetchInvestorProfile,
  fetchCustodiansForContactCard,
  saveInvestorProfile,
  setInvestorProfile,
  createNewInvestorProfile,
  setInitialProfileValues,
  setProfileFields,
  setChooseToParticipate,
  setNewIssuesQuestions,
  setNewIssuesNoneApply,
  addInterestedParty,
  removeInterestedParty,
  updateInterestedParty,
  addSigner,
  removeSigner,
  updateSigner,
  removeAllInterestedParties,
  setCustodianAccountField,
  resetCustodianAccount,
  resetIncomeAndNetWorthFields,
  setCustodians,
  addBeneficialOwner,
  removeBeneficialOwner,
  setHasCustodian,
  setCurrentCustodian,
  setDecoratedInvestorName,
  setInitialCurrentCustodian,
  setBatchUpdateOptions,
  setConditionalCountries,
  setPafExceptionApplies,
  setInvestorProfileCompleteness,
  checkAndSetServerError,
  custodianAccountNumberServerError,
  emailServerError,
  wlpSideEffects,
  raymondJamesSideEffects,
  updateAccountNumExternalId,
  setSignersErrors,
  addRemoveSignerErrors,
  setInboundAngularQueryParams,
  fetchInvestorProfileFieldVisibility,
  setInvestorProfileFieldVisibility,
  saveNewIssuesProfile,
  fetchWhiteLabelPartnerConfig,
  setWhiteLabelPartnerConfig,
  setEmptySignerEmailError,
  setEmptyInterestedPartyEmailError,
  adjustControlPersonCountry,
  adjustInterestedParties,
  setProfileCountryBackup,
  resetCountryFields,
  fetchInvestorProfileSignerTypes,
  investorProfileUnmount,
  setCustomAnalyzers,
  setHasInvestorProfileTemplate,
};
