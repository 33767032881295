import { RG_SET_SNOWFLAKE_CONFIGS } from 'actions/actionsConstants';
import { getRequest } from 'actions/utils';
import actionFactory from 'services/actions';

const BASE_URL = 'report_generator/snowflake_configs';
const getSnowflakeConfigSuccess = actionFactory(RG_SET_SNOWFLAKE_CONFIGS);

const getRgSnowflakeConfigs = () => (dispatch) =>
  dispatch(
    getRequest({
      url: BASE_URL,
      onSuccess: getSnowflakeConfigSuccess,
    }),
  );

export default { getRgSnowflakeConfigs };
