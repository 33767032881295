import { isEmpty } from 'lodash';
import { needsInvestorCertification, setEmptyValues } from 'services/investor_profile';

const sideEffects = (updateObj, profile, fieldVisibility, isMainSitePafAdmin) => {
  let modifiedObj = {};
  const hasValue = !isEmpty(profile.has_accredited_investor_certification);
  const showSection = needsInvestorCertification(
    { ...profile, ...updateObj },
    fieldVisibility,
    isMainSitePafAdmin
  );
  if (hasValue && !showSection) {
    modifiedObj = {
      ...modifiedObj,
      ...setEmptyValues(['has_accredited_investor_certification']),
    };
  }

  return { ...updateObj, ...modifiedObj };
};

export default sideEffects;
