import update from 'immutability-helper';
import { MASS_MAILER_DOCUMENTS } from '../actions';
import { MASS_MAILER_DOCUMENTS_SHOW } from './actions';

export const initialState = {
  isLoading: false,
  massMailerDocument: null,
};

const massMailerDocumentsShowReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case MASS_MAILER_DOCUMENTS.READ.REQUEST:
      return update(state, { isLoading: { $set: true } });
    case MASS_MAILER_DOCUMENTS.READ.FAILURE:
      return update(state, {
        isLoading: { $set: false },
      });
    case MASS_MAILER_DOCUMENTS.READ.SUCCESS: {
      const document = payload.response;
      document.effective_date = new Date(document.effective_date);

      return update(state, {
        isLoading: { $set: false },
        massMailerDocument: { $set: document },
      });
    }
    case MASS_MAILER_DOCUMENTS_SHOW.UNMOUNT:
      return initialState;
    default:
      return state;
  }
};

export default massMailerDocumentsShowReducer;
