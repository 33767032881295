import {
  FETCH_ICN_GEO_COUNTRIES,
} from 'actions/actionsConstants';
import update from 'immutability-helper';

export const initialState = {
  countries: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ICN_GEO_COUNTRIES: {
      return update(state, {
        countries: {
          $set: action.payload,
        },
      });
    }
    default: {
      return state;
    }
  }
};
