import {
  ED_SET_FOUNDATIONAL_DATA,
  ED_SET_FOUNDATIONAL_SORT,
  ED_SET_FOUNDATIONAL_CURRENT_PAGE,
  ED_SET_FOUNDATIONAL_PAGE_ACTIONS,
  ED_SET_FOUNDATIONAL_IS_LOADING_FIRST_PAGE,
  ED_SET_FOUNDATIONAL_REQUEST_MADE,
  ED_SET_FOUNDATIONAL_SEARCH_TERMS,
} from '../actions/constants';
import update from 'immutability-helper';
import initialState from './initialState';

export default (state = initialState, action) => {
  switch (action.type) {
    case ED_SET_FOUNDATIONAL_DATA: {
      return update(state, {
        foundationalData: {
          data: { $set: action.payload.data },
          currentPage: { $set: action.payload.current_page },
          resultsPerPage: { $set: action.payload.results_per_page },
          hasNextPage: { $set: action.payload.has_next_page },
          details: { $set: action.payload.details },
          filterOptions: { $set: action.payload.wlp_filter_options },
          filterValues: { $set: action.payload.filters },
          filterSelectedValues: { $set: action.payload.filters_selected_values },
          isLoadingFirstPage: { $set: false },
          requestMade: { $set: false },
          infoMessage: { $set: action.payload.info_message },
        },
      });
    }

    case ED_SET_FOUNDATIONAL_SEARCH_TERMS: {
      return update(state, {
        foundationalData: {
          pageActions: { searchTerms: { $set: action.payload } },
        },
      });
    }

    case ED_SET_FOUNDATIONAL_SORT: {
      return update(state, {
        foundationalData: {
          pageActions: { sort: { $set: action.payload } },
        },
      });
    }

    case ED_SET_FOUNDATIONAL_PAGE_ACTIONS: {
      return update(state, {
        foundationalData: {
          pageActions: { $merge: action.payload },
        },
      });
    }

    case ED_SET_FOUNDATIONAL_CURRENT_PAGE: {
      return update(state, { foundationalData: { currentPage: { $set: action.payload } } });
    }

    case ED_SET_FOUNDATIONAL_REQUEST_MADE: {
      return update(state, { foundationalData: { requestMade: { $set: action.payload } } });
    }

    case ED_SET_FOUNDATIONAL_IS_LOADING_FIRST_PAGE: {
      return update(state, { foundationalData: { isLoadingFirstPage: { $set: action.payload } } });
    }

    default:
      return state;
  }
};
