/* eslint-disable max-lines-per-function */
import {
  DC_SET_DOCUMENT_FILTER_LIST,
  DC_SET_ADVISORY_FIRM_FILTER_LIST,
  DC_SET_FUND_FILTER_LIST,
  DC_SET_FUND_ADMIN_GROUP_FILTER_LIST,
  DC_SET_INVESTORS_FILTER_LIST,
  DC_SET_ACCOUNT_NAME_FILTER_LIST,
  DC_SET_ACCOUNT_NUMBER_FILTER_LIST,
  DC_SET_ADVISOR_FILTER_LIST,
  DC_CLEAR_FILTER_LISTS,
  DC_SET_AUTO_SUGGEST_FILTER_LIST,
  DC_SET_DISPLAY_NAME_FILTER_LIST,
  DC_SET_WHITE_LABEL_PARTNER_FILTER_LIST,
} from 'actions/actionsConstants';

import update from 'immutability-helper';

const initialState = {
  dcDocumentFilterList: null,
  dcAdvisoryFirmFilterList: null,
  dcFundFilterList: null,
  dcDisplayNameFilterList: null,
  dcFundAdminGroupFilterList: null,
  dcInvestorsFilterList: null,
  dcAccountNameFilterList: null,
  dcAccountNumberFilterList: null,
  dcAdvisorFilterList: null,
  dcDocumentThreshold: null,
  dcAdvisoryFirmThreshold: null,
  dcFundFilterThreshold: null,
  dcDisplayNameThreshold: null,
  dcFundAdminGroupThreshold: null,
  dcAdvisorThreshold: null,
  dcInvestorsThreshold: null,
  dcAccountNameThreshold: null,
  dcAccountNumberThreshold: null,
  dcAutoSuggestFilterList: [],
  DCAutoSuggestDisplayPrompt: false,
  dcWhiteLabelPartnerFilterList: null,
  dcWhiteLabelPartnerThreshold: null,
};

export { initialState };

export default (state = initialState, action) => {
  switch (action.type) {
    case DC_SET_DOCUMENT_FILTER_LIST:
      return update(state, {
        dcDocumentFilterList: {
          $set: action.payload,
        },
        dcDocumentThreshold: {
          $set: action.count,
        }
      });
    case DC_SET_ADVISORY_FIRM_FILTER_LIST:
      return update(state, {
        dcAdvisoryFirmFilterList: {
          $set: action.payload,
        },
        dcAdvisoryFirmThreshold: {
          $set: action.count,
        },
      });
    case DC_SET_FUND_FILTER_LIST:
      return update(state, {
        dcFundFilterList: {
          $set: action.payload,
        },
        dcFundThreshold: {
          $set: action.count,
        },
      });
    case DC_SET_DISPLAY_NAME_FILTER_LIST:
      return update(state, {
        dcDisplayNameFilterList: {
          $set: action.payload,
        },
        dcDisplayNameThreshold: {
          $set: action.count,
        },
      });
    case DC_SET_FUND_ADMIN_GROUP_FILTER_LIST:
      return update(state, {
        dcFundAdminGroupFilterList: {
          $set: action.payload,
        },
        dcFundAdminGroupThreshold: {
          $set: action.count,
        },
      });
    case DC_SET_INVESTORS_FILTER_LIST:
      return update(state, {
        dcInvestorsFilterList: {
          $set: action.payload,
        },
        dcInvestorsThreshold: {
          $set: action.count,
        },
      });
    case DC_SET_ACCOUNT_NAME_FILTER_LIST:
      return update(state, {
        dcAccountNameFilterList: {
          $set: action.payload,
        },
        dcAccountNameThreshold: {
          $set: action.count,
        },
      });
    case DC_SET_WHITE_LABEL_PARTNER_FILTER_LIST:
      return update(state, {
        dcWhiteLabelPartnerFilterList: {
          $set: action.payload,
        },
        dcWhiteLabelPartnerThreshold: {
          $set: action.count,
        },
      });
    case DC_SET_ACCOUNT_NUMBER_FILTER_LIST:
      return update(state, {
        dcAccountNumberFilterList: {
          $set: action.payload,
        },
        dcAccountNumberThreshold: {
          $set: action.count,
        },
      });
    case DC_SET_ADVISOR_FILTER_LIST:
      return update(state, {
        dcAdvisorFilterList: {
          $set: action.payload,
        },
        dcAdvisorThreshold: {
          $set: action.count,
        },
      });
    case DC_CLEAR_FILTER_LISTS:
      return update(state, {
        dcDocumentFilterList: {
          $set: null,
        },
        dcFundFilterList: {
          $set: null,
        },
        dcFundAdminGroupFilterList: {
          $set: null,
        },
        dcInvestorsFilterList: {
          $set: null,
        },
        dcAccountNameFilterList: {
          $set: null,
        },
        dcAccountNumberFilterList: {
          $set: null,
        },
        dcAdvisorFilterList: {
          $set: null,
        },
        dcDisplayNameFilterList: {
          $set: null,
        },
        dcWhiteLabelPartnerFilterList: {
          $set: null,
        },
      });

    case DC_SET_AUTO_SUGGEST_FILTER_LIST:
      return update(state, {
        dcAutoSuggestFilterList: {
          $set: action.payload.filter_options,
        },
        DCAutoSuggestDisplayPrompt: {
          $set: action.payload.display_prompt,
        },
      });

    default:
      return state;
  }
};
