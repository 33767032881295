export default {
  exclusions: [],
  rias_firms: [],
  funds: [],
  exclusions_count: 0,
  results_per_page: 25,
  current_page: 1,
  sorting_column: 'firm_name',
  sorting_order: 'asc',
  active_modal: null,
  selected_row: null,
};
