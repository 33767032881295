import React from 'react';
import {
  Box,
  CircularProgress,
  Typography,
} from '@icapitalnetwork/supernova-core';
import {
  func,
  number,
  shape,
  bool,
} from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import AdditionalRiaAttestations from 'containers/registration/qualification_step/sections/AdditionalRiaAttestations';
import ProgressBar from './ProgressBar';
import ForwardBackButtons from './ForwardBackButtons';
import StyledDialogContent from './StyledDialogContent';
import { calculateCanSubmit } from '../helpers/validations';

function RiaUserIdentificationPageFive({
  progress,
  onNext,
  onBack,
  saveInformation,
  information,
  isSubmitting,
}) {
  const { t } = useTranslation(['common']);
  const theme = useTheme();

  const canSubmit = calculateCanSubmit(information);

  return (
    <>
      <StyledDialogContent>
        {isSubmitting
          ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: theme.spacing(4) }}>
              <CircularProgress />
            </Box>
          )
          : (
            <>
              <ProgressBar progress={progress} />

              <Typography variant="h5">
                Attestation
              </Typography>
              <AdditionalRiaAttestations updateStore={saveInformation} profileData={information} />
            </>
          )}
      </StyledDialogContent>
      <ForwardBackButtons
        onNext={onNext}
        onBack={onBack}
        canContinue={canSubmit}
        isSubmitting={isSubmitting}
        continueText={t('common:confirm', 'Confirm')}
      />
    </>
  );
}

RiaUserIdentificationPageFive.propTypes = {
  progress: number.isRequired,
  saveInformation: func.isRequired,
  onNext: func.isRequired,
  onBack: func.isRequired,
  information: shape({}).isRequired,
  isSubmitting: bool.isRequired,
};

export default RiaUserIdentificationPageFive;
