import reducers from './reducers';
import initialState from './initialState';

const fn = [
  reducers.assignmentsReducer,
  reducers.filterReducer,
  reducers.firmsReducer,
  reducers.fundsReducer,
  reducers.modalReducer,
  reducers.reviewersReducer,
];

const reducer = (state = initialState, action) =>
  fn.reduce((stateAccumulator, fnc) => fnc(stateAccumulator, action), state);

export default reducer;
