import update from 'immutability-helper';
import {
  LP_ADD_MORE_ANNOUNCEMENTS,
  LP_SET_ANNOUNCEMENTS,
  LP_SET_ANNOUNCEMENTS_FILTER,
  LP_SET_ANNOUNCEMENTS_LOADING,
} from 'modules/landing_page/actions/constants';
import initialState from '../initialState';

export function addMoreAnnouncements(state, { announcements: nextAnnouncements }) {
  return update(state, { announcements: { list: { $push: nextAnnouncements } }, loading: { $set: false } });
}

export function setAnnouncements(state, { announcements, announcements_size: announcementsSize }) {
  return update(state, {
    announcements: { list: { $set: announcements }, size: { $set: announcementsSize } },
    loading: { $set: false },
  });
}

export function setAnnouncementsFilter(state, announcementsFilter) {
  return update(state, { announcements: { filter: { $set: announcementsFilter } } });
}

export function setAnnouncementsLoading(state, loading) {
  return update(state, { loading: { $set: loading } });
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case LP_ADD_MORE_ANNOUNCEMENTS:
      return addMoreAnnouncements(state, payload);
    case LP_SET_ANNOUNCEMENTS:
      return setAnnouncements(state, payload);
    case LP_SET_ANNOUNCEMENTS_FILTER:
      return setAnnouncementsFilter(state, payload);
    case LP_SET_ANNOUNCEMENTS_LOADING:
      return setAnnouncementsLoading(state, payload);
    default:
      return state;
  }
};
