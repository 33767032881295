/* eslint-disable max-statements */
/* eslint-disable max-lines-per-function */
import update from 'immutability-helper';
import {
  ACTIVITY_LOG_GP_PROSPECTS_SET_CURRENT_PAGE,
  ACTIVITY_LOG_GP_PROSPECTS_SET_DATA,
  ACTIVITY_LOG_GP_PROSPECTS_SET_RESULTS_PER_PAGE,
  ACTIVITY_LOG_GP_PROSPECTS_SET_SORT,
  ACTIVITY_LOG_GP_PROSPECTS_SET_FILTERS,
  ACTIVITY_LOG_GP_PROSPECTS_SET_PAGE_ACTIONS,
  ACTIVITY_LOG_GP_PROSPECTS_SET_SEARCH_TERMS,
  ACTIVITY_LOG_GP_PROSPECTS_REMOVE_ALL_FILTERS,
  ACTIVITY_LOG_GP_PROSPECTS_REMOVE_FILTER_USER,
  ACTIVITY_LOG_GP_PROSPECTS_REMOVE_FILTER_EVENT,
  ACTIVITY_LOG_GP_PROSPECTS_REMOVE_FILTER_PRODUCT,
  ACTIVITY_LOG_GP_PROSPECTS_REMOVE_DATE_RANGE_FILTER,
  ACTIVITY_LOG_GP_PROSPECTS_REMOVE_FILTER_FIRM,
  ACTIVITY_LOG_GP_PROSPECTS_SET_FILTER_OPTIONS,
} from 'modules/activity_log/actions/constants';
import initialState from '../initialState';

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIVITY_LOG_GP_PROSPECTS_SET_DATA: {
      return update(state, {
        gpProspectsData: {
          data: { $set: action.payload.data },
          currentPage: { $set: action.payload.current_page },
          resultsPerPage: { $set: action.payload.results_per_page },
          hasNextPage: { $set: action.payload.has_next_page },
          totalCount: { $set: action.payload.total_count },
        },
      });
    }

    case ACTIVITY_LOG_GP_PROSPECTS_SET_FILTER_OPTIONS: {
      return update(state, {
        gpProspectsData: {
          engagementFilters: { $set: action.payload.engagement_filters },
          stateFilters: { $set: action.payload.state_filters },
        },
      });
    }

    case ACTIVITY_LOG_GP_PROSPECTS_SET_FILTERS: {
      return update(state, {
        gpProspectsData: {
          filterValues: { $set: action.payload.filters },
        },
      });
    }

    case ACTIVITY_LOG_GP_PROSPECTS_SET_CURRENT_PAGE: {
      return update(state, { gpProspectsData: { currentPage: { $set: action.payload } } });
    }

    case ACTIVITY_LOG_GP_PROSPECTS_SET_RESULTS_PER_PAGE: {
      return update(state, {
        gpProspectsData: {
          resultsPerPage: { $set: action.payload },
          currentPage: { $set: 1 },
        },
      });
    }

    case ACTIVITY_LOG_GP_PROSPECTS_SET_SORT: {
      return update(state, { gpProspectsData: { sort: { $set: action.payload } } });
    }

    case ACTIVITY_LOG_GP_PROSPECTS_SET_PAGE_ACTIONS: {
      return update(state, { gpProspectsData: { pageActions: { $merge: action.payload } } });
    }

    case ACTIVITY_LOG_GP_PROSPECTS_REMOVE_ALL_FILTERS: {
      initialState.gpProspectsData.pageActions.filters.startDate = moment().subtract(1, 'month');
      initialState.gpProspectsData.pageActions.filters.endDate = moment();
      return update(state, {
        gpProspectsData: {
          pageActions: {
            filters: { $set: initialState.gpProspectsData.pageActions.filters },
          },
        },
      });
    }

    case ACTIVITY_LOG_GP_PROSPECTS_REMOVE_FILTER_USER: {
      return update(state, {
        gpProspectsData: {
          pageActions: {
            filters: {
              users: {
                $set: state.gpProspectsData.pageActions.filters.users.filter(
                  (elem) => elem.id !== action.payload.id
                ),
              },
            },
          },
        },
      });
    }

    case ACTIVITY_LOG_GP_PROSPECTS_REMOVE_FILTER_FIRM: {
      return update(state, {
        gpProspectsData: {
          pageActions: {
            filters: {
              firms: {
                $set: state.gpProspectsData.pageActions.filters.firms.filter(
                  (elem) => elem.id !== action.payload.id
                ),
              },
            },
          },
        },
      });
    }

    case ACTIVITY_LOG_GP_PROSPECTS_REMOVE_FILTER_EVENT: {
      return update(state, {
        gpProspectsData: {
          pageActions: {
            filters: {
              events: {
                $set: state.gpProspectsData.pageActions.filters.events.filter(
                  (elem) => elem.id !== action.payload.id
                ),
              },
            },
          },
        },
      });
    }

    case ACTIVITY_LOG_GP_PROSPECTS_REMOVE_FILTER_PRODUCT: {
      return update(state, {
        gpProspectsData: {
          pageActions: {
            filters: {
              products: {
                $set: state.gpProspectsData.pageActions.filters.products.filter(
                  (elem) => elem.id !== action.payload.id
                ),
              },
            },
          },
        },
      });
    }

    case ACTIVITY_LOG_GP_PROSPECTS_REMOVE_DATE_RANGE_FILTER: {
      return update(state, {
        gpProspectsData: {
          pageActions: {
            filters: {
              endDate: {
                $set: moment(),
              },
              startDate: {
                $set: moment().subtract(1, 'month'),
              },
            },
          },
        },
      });
    }

    case ACTIVITY_LOG_GP_PROSPECTS_SET_SEARCH_TERMS: {
      return update(state, {
        gpProspectsData: {
          pageActions: { searchTerms: { $set: action.payload } },
        },
      });
    }

    default:
      return state;
  }
};
