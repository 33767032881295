export const publicPath = '/icn_react/static/';
export const baseRoute = `${publicPath}compare_products`;
export const REPORT_ID_PARAM = 'reportId';
export const REPORT_ID_PARAM_PART = `:${REPORT_ID_PARAM}`;

export const homePath = `${publicPath}home`;

export const routeCodes = {
  COMPARE_PRODUCTS: baseRoute,
  CUSTOM_COMPARE: `${baseRoute}/custom/${REPORT_ID_PARAM_PART}`,
  CUSTOM_COMPARE_PRODUCT_SELECTION: `${baseRoute}/custom/${REPORT_ID_PARAM_PART}?step=0`,
  CUSTOM_COMPARE_ATTRIBUTE_SELECTION: `${baseRoute}/custom/${REPORT_ID_PARAM_PART}?step=1`,
  STANDARD_COMPARE: `${baseRoute}/standard/${REPORT_ID_PARAM_PART}`,
  CUSTOM_REPORT: `${baseRoute}/custom/${REPORT_ID_PARAM_PART}/report`,
  STANDARD_REPORT: `${baseRoute}/standard/${REPORT_ID_PARAM_PART}/report`,
};

export const hideFooterRoutes = {
  OTHER_ROUTES_THAN_LANDING_PAGE: `${baseRoute}(?!$)(\\/.*)?$`,
};
