import React from 'react';
import _ from 'lodash';
import { string, arrayOf } from 'prop-types';
import styles from './IcnSimpleListTooltip.module.scss';

const IcnSimpleListTooltip = ({ title, collection }) => {
  const renderValueList = () => (
    <ul>
      {_.map(collection, (val, index) => <li key={`tool-tip-${index}`}>{val}</li>)}
    </ul>
  );

  return (
    <div className={styles.tooltip}>
      <b>{title}</b>
      <div>{renderValueList()}</div>
    </div>
  );
};

IcnSimpleListTooltip.propTypes = {
  title: string.isRequired,
  collection: arrayOf(string).isRequired,
};


export default IcnSimpleListTooltip;
