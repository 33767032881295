import {
  SE_LOAD_FUNDS_SUCCESS,

} from 'actions/actionsConstants';

import update from 'immutability-helper';

import initialState from '../initialState';

export default (state = initialState, action) => {
  switch (action.type) {
    case SE_LOAD_FUNDS_SUCCESS:
      return update(state, { funds: { $set: action.payload } });
    default:
      return state;
  }
};
