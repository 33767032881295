import { actionFactory } from 'services/actions';
import store from 'icnStore';
import { endApiRequest, beginApiRequest } from 'actions/spinner_actions/spinnerActions';
import {
  setUserImpersonate, getDelegateUsers, getImpersonationUsers, stopImpersonation,
} from '../services/ImpersonationServices';

import {
  I_SET_USERS,
  I_SET_IMPERSONATION_LOADING,
  I_SET_IMPERSONATION_USER,
  I_SET_DELEGATE_USERS,
} from './ImpersonationConstants';

const iSetUsers = actionFactory(I_SET_USERS);
const iSetDelegateUsers = actionFactory(I_SET_DELEGATE_USERS);
const iSetLoading = actionFactory(I_SET_IMPERSONATION_LOADING);
const iSetImpersonationUser = actionFactory(I_SET_IMPERSONATION_USER);

export function stopImpersonate(isInvestor = false) {
  return (dispatch, getState) => {
    const state = getState();
    const rootUrl = state.icnReactBootstrap.icn_react_bootstrap.main_app_url;
    const userId = state.icnReactBootstrap.icn_react_bootstrap.user.id;
    const url = `users/${userId}/stop_impersonate`;

    store.dispatch(beginApiRequest());

    return stopImpersonation({
      url,
      userId,
      onSuccess: () => {
        if (isInvestor) {
          window.location.assign(`${rootUrl}/investor_representatives`);
        } else {
          window.location.assign(`${rootUrl}/icn_react/static/impersonation`);
        }
        store.dispatch(endApiRequest());
        store.dispatch(iSetUsers(false));
      },
      onFail: () => store.dispatch(endApiRequest()),
    });
  };
}

export function iFetchUsers(payload) {
  return (dispatch, getState) => {
    const state = getState();
    const userId = state.icnReactBootstrap.icn_react_bootstrap.user.id;
    const { filter } = payload;
    dispatch(iSetLoading(true));
    return dispatch(getImpersonationUsers({ userId, filter, onSuccess: iSetUsers }));
  };
}

export function iFetchDelegateUsers() {
  return (dispatch) => {
    dispatch(iSetLoading(true));
    return dispatch(getDelegateUsers(iSetDelegateUsers));
  };
}

export function iSetUser(payload) {
  return (dispatch) => {
    dispatch(iSetLoading(true));
    return dispatch(setUserImpersonate(payload, () => iSetImpersonationUser(true)));
  };
}

export function iCleanUsers() {
  return (dispatch) => {
    dispatch(iSetUsers([]));
    return dispatch(iSetDelegateUsers([]));
  };
}

export default {
  stopImpersonate,
  iFetchUsers,
  iFetchDelegateUsers,
  iCleanUsers,
  iSetUser,
};
