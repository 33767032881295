import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { stopImpersonate } from 'modules/impersonation/actions/ImpersonationActions';
import { Alert, Button, Box, Provider } from '@icapitalnetwork/supernova-core';
import styles from './ImpersonationHeader.module.scss';

class ImpersonationHeader extends Component {
  constructor(props) {
    super(props);
    this.stopImpersonate = this.stopImpersonate.bind(this);
    this.impersonatedOrDelegated = this.impersonatedOrDelegated.bind(this);
  }

  stopImpersonate(e) {
    e.preventDefault();
    this.props.stopImpersonate(this.props.isInvestor);
  }

  impersonatedOrDelegated() {
    return this.props.isDelegated;
  }

  investorAsRepresentative() {
    return this.props.isInvestor;
  }

  render() {
    let stopImpersonatingText = this.props.t('impersonation:stop_impersonating', 'Stop Impersonating');

    if (this.impersonatedOrDelegated()) {
      stopImpersonatingText = this.props.t('impersonation:stop_acting_as_delegate', 'Stop Acting as Delegate');
    } else if (this.investorAsRepresentative()) {
      stopImpersonatingText = this.props.t('impersonation:stop_acting_as_a_representative', 'Stop Acting as a Representative');
    }

    return (
      <Provider>
        <Box sx={(theme) => ({
          display: 'flex',
          justifyContent: 'center',
          background: theme.palette.warning.lightest || '#FEF8EB',
          borderBottom: '1px solid #FFA901',
          padding: '0 48px',
        })}
        >
          <Alert
            sx={{ maxWidth: '1680px', width: '100%', borderBottom: 'unset', padding: '6px 0' }}
            severity="warning"
            action={(
              <Box sx={{ display: 'flex' }}>
                <Button size="small" onClick={this.stopImpersonate} name="btn-stop-acting">
                  {stopImpersonatingText}
                </Button>
              </Box>
            )}
            onClose={this.stopImpersonate}
          >
            <span className={styles.text}>
              {this.impersonatedOrDelegated() || this.investorAsRepresentative()
                ? this.props.t('impersonation:you_are_acting_on_behalf_of', 'You are acting on behalf of')
                : this.props.t('impersonation:you_are_impersonating', 'You are impersonating')}
            </span>
            <span className={styles.current_name}>
              {' '}
              {this.props.userName}
            </span>
          </Alert>
        </Box>
      </Provider>
    );
  }
}
function mapStateToProps(state) {
  return {
    userName: state.icnReactBootstrap.icn_react_bootstrap.user.name,
    isDelegated: state.icnReactBootstrap.icn_react_bootstrap.user.is_delegated,
    isInvestor: state.icnReactBootstrap.icn_react_bootstrap.user.is_investor_role,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    stopImpersonate,
  }, dispatch);
}

ImpersonationHeader.defaultProps = {
  userName: '',
  isDelegated: false,
  isInvestor: false,
};

ImpersonationHeader.propTypes = {
  isDelegated: PropTypes.bool,
  isInvestor: PropTypes.bool,
  userName: PropTypes.string,
};

const ConnectedImpersonationHeader = connect(mapStateToProps, mapDispatchToProps)(ImpersonationHeader);
export { ConnectedImpersonationHeader };
export default withTranslation(['impersonation'])(ConnectedImpersonationHeader);
