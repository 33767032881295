import React from 'react';
import Loading from 'components/shared/loading/Loading';
import PrivateRoute from 'containers/private_route/PrivateRoute';
import Loadable from 'react-loadable';
import dashboardRouteCodes from './modules/dashboard/router';

const LoadableDashboardApp = Loadable({
  loader: () => import(
    /* webpackChunkName: "ValidatrDashboardApp" */ './modules/dashboard'
  ),
  loading: Loading,
});

export function App() {
  return (
    <PrivateRoute
      path={Object.values(dashboardRouteCodes)}
      component={LoadableDashboardApp}
    />
  );
}

export default App;
