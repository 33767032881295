import { getRequest, postRequest } from 'services/apiRequest';
import { FALLBACK_ERROR_MESSAGE } from 'containers/client_management/constants';

export const getAssignableUsers = () => getRequest({
  errorMessage: FALLBACK_ERROR_MESSAGE,
  url: 'team_management/referral_codes/assignable_users',
  params: { all: true },
});

export const validateCreateTeam = (data) => postRequest({
  url: 'team_management/referral_codes/validate',
  data,
});

export const createTeam = (data) => postRequest({
  url: 'team_management/referral_codes',
  data,
});
