import { isString } from 'lodash';

export const getPartnerProxyAPIPrefix = () => window.partnerProxyAPIPrefix;

export const hasPartnerProxyAPIPrefix = () => {
  const prefix = getPartnerProxyAPIPrefix();
  return isString(prefix) && prefix != '';
};

export const prependPartnerProxyAPIPrefix = (url) => (
  (hasPartnerProxyAPIPrefix() && url) ?
  `${getPartnerProxyAPIPrefix()}${url}` :
  url
);

export const ensureRelative = (url) => (
  // If the URL begins with a slash, remove it
  url.match(/^\//) ?
  url.replace(/^\//,'') :
  url
);

export function rootUrl() {
  const defaultAPIPath = '/api/v1/';
  if (hasPartnerProxyAPIPrefix()) {
    return prependPartnerProxyAPIPrefix(defaultAPIPath);
  }
  return defaultAPIPath;
}

/* eslint-disable no-param-reassign */
export function setBaseUrl(Axios) {
  Axios.defaults.baseURL = rootUrl();
}
/* eslint-enable no-param-reassign */
