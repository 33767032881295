import update from 'immutability-helper';
import { ED_UNMOUNT, ED_SET_IS_RENDERING_TABLE } from '../actions/constants';
import initialState from './initialState';

export default (state = initialState, action) => {
  switch (action.type) {
    case ED_UNMOUNT: {
      return initialState;
    }

    case ED_SET_IS_RENDERING_TABLE: {
      return update(state, { [action.payload.store]: { isRenderingTable: { $set: action.payload.isRendering } } });
    }

    default:
      return state;
  }
};
