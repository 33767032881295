import notificationService from 'services/notifications';
import { createAction } from 'redux-actions';
import {
  getRequest, postRequest,
} from 'actions/utils';

import {
  VERIFICATION_CODE_INVALID,
  CONSENT_REQUEST_VALID,
  AUTHENTICATION_VALID,
  PERMISSIONS_INVALID,
  VERIFICATION_CODE_SENT,
  SUBMIT_SUCCESS,
} from 'containers/consent_form/entry/pageStates';

import {
  CONSENT_FORM_SET_AUTHENTICATION_CODE,
  CONSENT_FORM_SET_VERIFY_CODE,
  CONSENT_FORM_SET_PAGE_STATE,
  CONSENT_FORM_SET_CONTACT_CARD_REQUEST,
  CONSENT_FORM_SET_FORM_SUBMITTED,
} from '../actionsConstants';

const setAuthenticationToken = createAction(CONSENT_FORM_SET_AUTHENTICATION_CODE);
const setContactCardRequest = createAction(CONSENT_FORM_SET_CONTACT_CARD_REQUEST);
const setConsentRequestVerifyCodeAction = createAction(CONSENT_FORM_SET_VERIFY_CODE);
const setPageState = createAction(CONSENT_FORM_SET_PAGE_STATE);
const setFormSubmitted = createAction(CONSENT_FORM_SET_FORM_SUBMITTED);

const buildRequestParams = (params, getState) => (
  {
    ...params,
    customInterceptors: {
      request: {
        success: (config) => {
          const state = getState();
          const icnBootstrap = state.icnReactBootstrap.icn_react_bootstrap;
          const csrf = icnBootstrap.csrf_token;

          config.headers['X-CSRF-Token'] = csrf;
          return config;
        },
        error: (error) => Promise.reject(error),
      },
      response: {
        success: (response) => response,
        error: (error) => Promise.reject(error.response),
      },
    },
  }
);

const loadConsentRequestCallback = (response) => (
  (dispatch) => {
    dispatch(setContactCardRequest(response));
    dispatch(setPageState(CONSENT_REQUEST_VALID));
  }
);

const loadConsentRequestErrorCallback = () => (
  (dispatch) => {
    dispatch(setPageState(PERMISSIONS_INVALID));
  }
);

const sendConsentRequestVerificationCodeCallback = () => (
  (dispatch) => {
    dispatch(setPageState(VERIFICATION_CODE_SENT));
    dispatch(setConsentRequestVerifyCodeAction(''));
  }
);

const sendConsentRequestVerificationCodeErrorCallback = () => (
  notificationService.notifyError('Could not send new verification code')
);

const validateConsentRequestEmailCodeCallback = (response) => (
  (dispatch) => {
    dispatch(setPageState(AUTHENTICATION_VALID));
    dispatch(setAuthenticationToken(response.authentication_token));
  }
);

const validateConsentRequestEmailCodeErrorCallback = (error) => (
  (dispatch) => {
    if (error.status === 404) {
      dispatch(setPageState(PERMISSIONS_INVALID));
    } else {
      dispatch(setPageState(VERIFICATION_CODE_INVALID));
    }
  }
);

const submitConsentCallback = () => (
  (dispatch) => {
    dispatch(setPageState(SUBMIT_SUCCESS));
    dispatch(setFormSubmitted());
  }
);

const submitConsentErrorCallback = (error) => (
  (dispatch) => {
    if (error.status === 401) {
      dispatch(setPageState(VERIFICATION_CODE_INVALID));
    } else {
      const defaultMessage = 'Something went wrong. Please try again.';

      notificationService.notifyError(error?.data?.message ?? defaultMessage);
    }
  }
);

function loadConsentRequest(token) {
  return (dispatch, getState) => dispatch(getRequest(buildRequestParams({
    url: `contact_card_consents/consent_requests/${token}`,
    onSuccess: loadConsentRequestCallback,
    onFailure: loadConsentRequestErrorCallback,
  }, getState)));
}

function sendConsentRequestVerificationCode(token) {
  return (dispatch, getState) => dispatch(postRequest(buildRequestParams({
    url: `contact_card_consents/consent_requests/${token}/send_code`,
    onSuccess: sendConsentRequestVerificationCodeCallback,
    onFailure: sendConsentRequestVerificationCodeErrorCallback,
  }, getState)));
}

function validateConsentRequestEmailCode(token, code) {
  return (dispatch, getState) => dispatch(postRequest(buildRequestParams({
    url: `contact_card_consents/consent_requests/${token}/validate_code`,
    data: {
      code,
    },
  }, getState))).then((response) => {
    dispatch(validateConsentRequestEmailCodeCallback(response));
  }).catch((error) => {
    dispatch(validateConsentRequestEmailCodeErrorCallback(error));
  });
}

function setConsentRequestVerifyCode(verifyCode) {
  return (dispatch) => dispatch(setConsentRequestVerifyCodeAction(verifyCode));
}

function submitConsent(consentRequestToken, authenticationToken, value) {
  return (dispatch, getState) => dispatch(postRequest(buildRequestParams({
    url: `contact_card_consents/consent_requests/${consentRequestToken}/consents/update_consent`,
    data: {
      auth_token: authenticationToken,
      user_consent: value,
    },
  }, getState))).then(() => {
    dispatch(submitConsentCallback());
  }).catch((error) => {
    dispatch(submitConsentErrorCallback(error));
  });
}

export default {
  loadConsentRequest,
  validateConsentRequestEmailCode,
  sendConsentRequestVerificationCode,
  setConsentRequestVerifyCode,
  submitConsent,
};
