// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.entry-module__not_found___jDDF2{background-color:gold}`, "",{"version":3,"sources":["webpack://./icn_react/src/containers/not_found/entry.module.scss"],"names":[],"mappings":"AAAA,iCACI,qBAAA","sourcesContent":[".not_found {\n    background-color: gold;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"not_found": `entry-module__not_found___jDDF2`
};
export default ___CSS_LOADER_EXPORT___;
