import notificationService from 'services/notifications';
import {
  RGA_ADD_FIELD_FILTER,
  RGA_SET_CONFIG_FIELDS,
} from 'actions/actionsConstants';
import {
  deleteRequest,
  getRequest,
  patchRequest,
  postRequest,
} from 'actions/utils';
import {
  getCurrentConfigId,
  getStoredQueries,
} from 'reducers/report_generator_reducers/selectors';
import { actionFactory } from 'services/actions';
import scheduleActions from './scheduleActions';

const BASE_URL = 'report_generator/config_fields';
const setConfigFields = actionFactory(RGA_SET_CONFIG_FIELDS);
const addFilter = actionFactory(RGA_ADD_FIELD_FILTER);

const handleConfigFieldAction = (message) => (dispatch, getState) => {
  notificationService.notifySuccess(message);

  const configId = getCurrentConfigId(getState());

  dispatch(scheduleActions.reportScheduleData(configId));
  dispatch(getConfigFields(configId));
};

const handleConfigFieldCreation = (field, configId) => (dispatch) => {
  const payload = { filter: field.name, type: field.type };
  dispatch(addFilter(payload));
  return dispatch(createConfigField(field.method, configId));
};

const getConfigFields =
  (configId, admin = false) =>
  (dispatch) =>
    dispatch(
      getRequest({
        url: BASE_URL,
        params: {
          config_id: configId,
          admin: admin || undefined,
        },
        onSuccess: setConfigFields,
        errorMessage: {
          title: 'Error',
          message: 'Failed to fetch config fields',
        },
      }),
    );

const createConfigField = (fieldMethod, configId) => (dispatch) =>
  dispatch(
    postRequest({
      url: BASE_URL,
      data: { config_id: configId, field_method: fieldMethod },
      onSuccess: ({ message }) => handleConfigFieldAction(message),
      onFailure: ({ data: { message } }) =>
        notificationService.notifyError(message),
    }),
  );

const updateConfigField = (payload) => (dispatch) =>
  dispatch(
    patchRequest({
      url: `${BASE_URL}/${payload.id}`,
      data: { report_config_field: payload },
      onSuccess: () => handleConfigFieldAction('Field updated successfully.'),
      onFailure: ({ data: { message } }) =>
        notificationService.notifyError(message),
    }),
  );

const deleteConfigField = (id) => (dispatch) =>
  dispatch(
    deleteRequest({
      url: `${BASE_URL}/${id}`,
      onSuccess: () => handleConfigFieldAction('Field removed successfully.'),
      onFailure: ({ data: { message } }) =>
        notificationService.notifyError(message),
    }),
  );

const selectAllFields = (configId) => (dispatch) =>
  dispatch(
    postRequest({
      url: `${BASE_URL}/select_all`,
      data: { config_id: configId },
      onSuccess: ({ message }) => handleConfigFieldAction(message),
      onFailure: ({ data: { message } }) =>
        notificationService.notifyError(message),
    }),
  );

const removeAllFields = (configId) => (dispatch) =>
  dispatch(
    deleteRequest({
      url: `${BASE_URL}/remove_all`,
      params: { config_id: configId },
      onSuccess: () => handleConfigFieldAction('All fields removed.'),
      onFailure: ({ data: { message } }) =>
        notificationService.notifyError(message),
    }),
  );

const handleSaveFilter = (fieldName, fieldId) => (dispatch, getState) => {
  const storedQueries = getStoredQueries(getState());
  const filter = storedQueries[fieldName];
  const payload = { id: fieldId, filter };

  return dispatch(updateConfigField(payload));
};

export default {
  getConfigFields,
  createConfigField,
  updateConfigField,
  deleteConfigField,
  selectAllFields,
  removeAllFields,
  handleSaveFilter,
  handleConfigFieldCreation,
};
