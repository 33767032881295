import {
  shape, bool, string, number,
} from 'prop-types';

export default shape({
  id: number,
  isPafAdmin: bool,
  isRia: bool,
  name: string,
  firm_id: number,
});
