import update from 'immutability-helper';
import {
  RP_AVAILABLE_FUNDS,
  RP_REPORT_TYPE,
  RP_OPERATIONS_REPORTS,
  RP_OR_SET_MESSAGE_BANNER,
  RP_SET_SELECTED_FUNDS,
} from '../../actions/actionsConstants';

const initialState = {
  availableFunds: [],
  selectedFunds: [],
  operationsReportType: undefined,
  reports: [],
  message: undefined,
  messageType: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case RP_AVAILABLE_FUNDS:
      return update(state, { availableFunds: { $set: action.payload } });
    case RP_REPORT_TYPE: {
      return update(state, {
        operationsReportType: { $set: action.payload },
      });
    }
    case RP_OPERATIONS_REPORTS:
      return update(state, { reports: { $set: action.payload } });
    case RP_OR_SET_MESSAGE_BANNER:
      return update(state, {
        message: { $set: action.payload.message },
        messageType: { $set: action.payload.messageType },
      });
    case RP_SET_SELECTED_FUNDS: {
      const ids = action.payload;
      const selectedFunds = state.availableFunds.filter(f => ids.find(x => x === f.id));

      return update(state, {
        selectedFunds: { $set: selectedFunds },
      });
    }
    default:
      return state;
  }
};
