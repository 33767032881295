import { map, get } from 'lodash';
import {
  getRequest, postRequest, patchRequest, deleteRequest,
} from 'actions/utils';
import { actionFactory } from 'services/actions';

import {
  AS_LOAD_REPRESENTATIONS_SUCCESS,
  AS_LOAD_REPRESENTATIONS_COUNT,
  AS_REMOVE_REPRESENTATION,
  AS_LOAD_RIAS_FIRMS_SUCCESS,
  AS_LOAD_WHITE_LABEL_PARTNERS_FIRMS_SUCCESS,
  AS_LOAD_FUNDS_SUCCESS,
  AS_LOAD_FILTER_CONTENTS_SUCCESS,
  AS_SET_ACTIVE_MODAL,
  AS_SET_SELECTED_ROW,
  AS_SET_SELECTED_WLPS,
  AS_SET_SELECTED_FUNDS,
  AS_SELECTED_RIAS_FIRMS,
  AS_SELECTED_REP_TYPE,
  AS_SET_RESULTS_PER_PAGE,
  AS_SET_CURRENT_PAGE,
  AS_SET_SORTING,
} from '../actionsConstants';

const asLoadRepresentationsSuccess = actionFactory(AS_LOAD_REPRESENTATIONS_SUCCESS);

const asLoadRepresentationsCount = actionFactory(AS_LOAD_REPRESENTATIONS_COUNT);

const asLoadRiasFirmsSuccess = actionFactory(AS_LOAD_RIAS_FIRMS_SUCCESS);

const asLoadWhiteLabelPartnersSuccess = actionFactory(AS_LOAD_WHITE_LABEL_PARTNERS_FIRMS_SUCCESS);

const asLoadFundsSuccess = actionFactory(AS_LOAD_FUNDS_SUCCESS);

const asLoadFilterContentsSuccess = actionFactory(AS_LOAD_FILTER_CONTENTS_SUCCESS);

const asSetActiveModal = actionFactory(AS_SET_ACTIVE_MODAL);

const asSetSelectedRow = actionFactory(AS_SET_SELECTED_ROW);

function asLoadRepresentationsData(data) {
  return (dispatch) => {
    dispatch(asLoadRepresentationsSuccess(data.representations));
    dispatch(asLoadRepresentationsCount(data.representations_count));
  };
}

function asLoadRepresentations() {
  return (dispatch, getState) => {
    const state = getState().amlSuitabilityReps;
    const formattedRepTypes = state.selected_representation_type.reduce((result, el) => ({
      ...result,
      [el.filter]: el.toggle,
    }), {});
    const params = {
      advisory_firms_ids: map(state.selected_rias_firms, 'id'),
      funds_ids: map(state.selected_funds, 'id'),
      white_label_partner_ids: map(state.selected_wlps, 'id'),
      flags_filter: formattedRepTypes,
      page: state.current_page,
      per_page: state.results_per_page,
      sorting_column: state.sorting_column,
      sorting_order: state.sorting_order,
    };
    return dispatch(getRequest({
      url: '/representations',
      params,
      onSuccess: asLoadRepresentationsData,
    }));
  };
}

function asUpdateRepresentation(payload) {
  return patchRequest({
    url: `/representations/${payload.id}`,
    params: {
      id: payload.id,
      white_label_partner_id: payload.wlpId,
      firm_id: payload.firmId,
      fund_id: payload.fundId,
      flags: {
        aml: payload.aml,
        suitability: payload.suitability,
      },
    },
    onSuccess: asLoadRepresentations,
    successMessage: { message: 'Representation Edited' },
    errorMessage: { title: 'Error', message: 'An error Occurred Updating Representation' },
  });
}

function asCreateRepresentation(payload) {
  return postRequest({
    url: '/representations',
    params: {
      white_label_partner_id: (payload.wlp && payload.wlp.id) || null,
      firm_id: (payload.riaFirm && payload.riaFirm.id) || null,
      fund_id: (payload.fund && payload.fund.id) || null,
      flags: {
        aml: payload.aml,
        suitability: payload.suitability,
      },
    },
    onSuccess: asLoadRepresentations,
    successMessage: { message: 'Representation Created' },
    errorMessage: { title: 'Error', message: 'Representation Already Exists' },
  });
}

function asRemoveRepresentationsSuccess() {
  return (dispatch) => {
    dispatch({
      type: AS_REMOVE_REPRESENTATION,
    });
    return dispatch(asLoadRepresentations());
  };
}

function asRemoveRepresentation(representation) {
  return deleteRequest({
    url: `/representations/${representation.id}`,
    onSuccess: asRemoveRepresentationsSuccess,
    infoMessage: { message: 'Representation Deleted' },
  });
}

function asLoadFilterContents() {
  return getRequest({
    url: '/representations/representations_relations',
    onSuccess: asLoadFilterContentsSuccess,
  });
}

function asLoadWhiteLabelPartners() {
  return getRequest({
    url: '/white_label_partners',
    onSuccess: asLoadWhiteLabelPartnersSuccess,
  });
}

function asLoadRiasFirms() {
  return (dispatch) => dispatch(getRequest({
    url: 'firms/advisors_firms',
    onSuccess: asLoadRiasFirmsSuccess,
  }));
}

function asLoadFunds(query = null) {
  return getRequest({
    url: 'funds/autocomplete',
    params: { query },
    onSuccess: asLoadFundsSuccess,
  });
}

function asSetSelectedWlps(payload) {
  return (dispatch) => {
    dispatch({
      type: AS_SET_SELECTED_WLPS,
      payload,
    });
    return dispatch(asLoadRepresentations());
  };
}

function asSetSelectedFunds(payload) {
  return (dispatch) => {
    dispatch({
      type: AS_SET_SELECTED_FUNDS,
      payload,
    });
    return dispatch(asLoadRepresentations());
  };
}

function asSetSelectedRiasFirms(payload) {
  return (dispatch) => {
    dispatch({
      type: AS_SELECTED_RIAS_FIRMS,
      payload,
    });
    return dispatch(asLoadRepresentations());
  };
}

function asSetSelectedRepresentations(payload) {
  return (dispatch) => {
    dispatch({
      type: AS_SELECTED_REP_TYPE,
      payload,
    });
    return dispatch(asLoadRepresentations());
  };
}

function asSetResultsPerPage(payload) {
  return (dispatch) => {
    dispatch({
      type: AS_SET_RESULTS_PER_PAGE,
      payload,
    });
    return dispatch(asLoadRepresentations());
  };
}

function asSetCurrentPage(payload) {
  return (dispatch) => {
    dispatch(
      {
        type: AS_SET_CURRENT_PAGE,
        payload,
      }
    );
    return dispatch(asLoadRepresentations());
  };
}

function asSetSorting(sortingColumn, sortingOrder) {
  return (dispatch) => {
    dispatch({
      type: AS_SET_SORTING,
      payload: { sortingColumn, sortingOrder },
    });
    return dispatch(asLoadRepresentations());
  };
}

export default {
  asLoadRepresentations,
  asUpdateRepresentation,
  asCreateRepresentation,
  asRemoveRepresentation,
  asLoadRiasFirms,
  asLoadFunds,
  asLoadWhiteLabelPartners,
  asLoadFilterContents,
  asSetSelectedWlps,
  asSetSelectedFunds,
  asSetSelectedRiasFirms,
  asSetSelectedRepresentations,
  asSetResultsPerPage,
  asSetCurrentPage,
  asSetSorting,
  asSetActiveModal,
  asSetSelectedRow,
};
