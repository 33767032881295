import update from 'immutability-helper';
import { MASS_MAILER_FIRMS, MASS_MAILER_FIRMS_LIST } from './actions';

export const initialState = {
  massMailer: {
    isLoading: true,
    list: [],
  },
  all: {
    isLoading: true,
    list: [],
  },
};

const massMailerEmailPartners = (state = initialState, { type, payload }) => {
  switch (type) {
    case MASS_MAILER_FIRMS.LIST.REQUEST:
      return update(state, { massMailer: { isLoading: { $set: true } } });
    case MASS_MAILER_FIRMS.LIST.SUCCESS:
      return update(state, {
        massMailer: {
          isLoading: { $set: false },
          list: {
            $set: payload.response.map((item) => ({
              label: item.display_name,
              value: item.id,
            })),
          },
        },
      });
    case MASS_MAILER_FIRMS.ALL.REQUEST:
      return update(state, { all: { isLoading: { $set: true } } });
    case MASS_MAILER_FIRMS.ALL.SUCCESS: {
      const firms = payload.response.map((item) => ({
        label: item.name,
        value: item.id,
      }));
      firms.unshift({ label: 'All Firms', value: null });
      return update(state, {
        all: {
          isLoading: { $set: false },
          list: {
            $set: firms,
          },
        },
      });
    }
    case MASS_MAILER_FIRMS.ALL.FAILURE:
      return update(state, { all: { isLoading: { $set: true } } });
    case MASS_MAILER_FIRMS.LIST.FAILURE:
      return update(state, { massMailer: { isLoading: { $set: true } } });
    case MASS_MAILER_FIRMS_LIST.UNMOUNT:
      return initialState;
    default:
      return state;
  }
};

export default massMailerEmailPartners;
