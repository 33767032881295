import React, {
  useState, useEffect,
} from 'react';
import {
  bool, func,
} from 'prop-types';
import {
  DialogTitle,
  Dialog,
  DialogContent,
  FormGroup,
  DialogActions,
  Button,
  FormControl,
  FormHelperText,
} from '@icapitalnetwork/supernova-core';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import actions from 'actions';
import { bindActionCreators } from 'redux';
import { getInvestorQualificationStandardQuestions } from 'reducers/qualification_popup_reducers/selectors';
import QualificationQuestions from 'containers/registration/qualification_step/sections/QualificationQuestions';

const DEFAULT_ERROR_MESSAGE = 'There was an unexpected error, you are not able to update your qualification at this time. Please email support@icapitalnetwork.com or call 212-994-7333 for assistance.';

const QualificationPopup = ({
  isOpen,
  getInvestorQualificationStandardQuestions,
  investorQualificationStandardQuestions,
  checkInvestorQualificationStandardQuestions,
  createUserAcknowledgement,
}) => {
  const [isModalOpen, setModalOpen] = useState(isOpen);
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    if (isEmpty(investorQualificationStandardQuestions)) {
      getInvestorQualificationStandardQuestions();
    }
  }, [])

  useEffect(() => {
    setModalOpen(isOpen)
  }, [isOpen])

  const onRequestClose = () => {
    window.location.href = '/logout';
  }

  // Create user_acknowledgement and update investor qualification standards
  const saveUserInvestorQualificationStandards = () => {
    let iqs_ids = investorQualificationStandardQuestions.filter(iqs => iqs.checked).map(iqs => iqs.id);
    createUserAcknowledgement(iqs_ids)
      .then(() => setModalOpen(false))
      .catch(() => setErrorMessage(DEFAULT_ERROR_MESSAGE));
  }

  return (
    <>
      {
        investorQualificationStandardQuestions.length > 0 && (
          <Dialog
            maxWidth="sm"
            open={isModalOpen}
            onClose={onRequestClose}
          >
            <DialogTitle>
              Qualification
            </DialogTitle>

            <DialogContent>
              <FormControl
                required
                error={errorMessage.length > 0}>
                <FormGroup>
                  <QualificationQuestions
                    questions={investorQualificationStandardQuestions}
                    updateStore={checkInvestorQualificationStandardQuestions}
                  />
                </FormGroup>
                <FormHelperText>{errorMessage}</FormHelperText>
              </FormControl>
            </DialogContent>

            <DialogActions>
              <Button
                onClick={saveUserInvestorQualificationStandards}
                disabled={!investorQualificationStandardQuestions.filter(iqs => iqs.checked).length}
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>
        )
      }
      {
        investorQualificationStandardQuestions.length == 0 && (
          <>
          </>
        )
      }
    </>

  )
}

function mapStateToProps(state) {
  return {
    investorQualificationStandardQuestions: getInvestorQualificationStandardQuestions(state),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getInvestorQualificationStandardQuestions: actions.getInvestorQualificationStandardQuestions,
    checkInvestorQualificationStandardQuestions: actions.checkInvestorQualificationStandardQuestions,
    createUserAcknowledgement: actions.createUserAcknowledgement,
    ratLoadAccountTypes: actions.ratLoadAccountTypes
  }, dispatch)
}

QualificationPopup.propTypes = {
  isOpen: bool.isRequired,
  getInvestorQualificationStandardQuestions: func.isRequired,
  checkInvestorQualificationStandardQuestions: func.isRequired,
  createUserAcknowledgement: func.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(QualificationPopup)
