export default {
  accounts: {
    accountProfiles: {
      account_profiles: [],
      total: 0,
      page: 1,
      per_page: 25,
    },
    apReferralCodesFilter: {
      referral_codes: [],
      total: 0,
      page: 1,
      per_page: 25,
      show_null_option: false,
    },
    apAccountProfilesFilter: {
      account_profiles: [],
      total: 0,
      page: 1,
      per_page: 25,
    },
    branchCodesFilter: {
      branch_codes: [],
      total: 0,
      page: 1,
      per_page: 25,
    },
    apSelectedAccountsFilter: [],
    apSelectedRefCodesFilter: [],
    selectedBranchCodesFilter: [],
    apSelectedAccountsOptionsFilter: [],
    apSelectedRefCodesOptionsFilter: [],
    selectedBranchCodesOptionsFilter: [],
  },
  teams: {
    referralCodes: {
      referral_codes: [],
      total: 0,
      page: 1,
      per_page: 25,
      timestamp: 0, // Only to force the teams table to refresh when updating the team members
    },
    referralCodesFilter: {
      referral_codes: [],
      total: 0,
      page: 1,
      per_page: 25,
    },
    usersFilter: {
      users: [],
      total: 0,
      page: 1,
      per_page: 25,
    },
    branchCodesFilter: {
      branch_codes: [],
      total: 0,
      page: 1,
      per_page: 25,
    },
    selectedReferralCodesFilter: [],
    selectedUsersFilter: [],
    selectedBranchCodesFilter: [],
    selectedReferralCodesOptionsFilter: [],
    selectedUsersOptionsFilter: [],
    selectedBranchCodesOptionsFilter: [],
    rcAssignableUsers: {
      users: [],
      total: 0,
      page: 1,
      per_page: 25,
      loaded: false,
    },
    rcReferralCodeUsers: [],
    rcAssignableReferralCodes: {
      referral_codes: [],
      total: 0,
      page: 1,
      per_page: 25,
      loaded: false,
    },
    rcAssignableReferralCodesCount: 0,
    rcUserReferralCodes: {
      referral_codes: [],
      loaded: false,
    },
  },
  branches: {
    assignableBranchCodes: {
      branch_codes: [],
      total: 0,
      page: 1,
      per_page: 25,
    },
    branchReferralCodes: {
      referral_codes: [],
      loaded: false,
    },
  },
  teamInvites: {
    invites: {
      invites: [],
      total: 0,
      page: 1,
      per_page: 25,
    },
    invitesFilter: {
      invites: [],
      total: 0,
      page: 1,
      per_page: 25,
    },
    selectedInvitesFilter: [],
  },
  modals: {
    editTeamModal: {
      isOpen: false,
      rowData: {},
    },
    deleteTeamModal: {
      isOpen: false,
      rowData: {},
    },
  },
};
