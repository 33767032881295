/* eslint-disable max-lines-per-function */
import React from 'react';
import { Box } from '@icapitalnetwork/supernova-core';
import {
  func, number, shape,
} from 'prop-types';
import { useTheme } from '@mui/material';
import RegisteredAsBdOrRia from 'containers/registration/complete_profile/sections/RegisteredAsBdOrRia';
import RegistrationSeries from 'containers/registration/complete_profile/sections/RegistrationSeries';
import IndividualCrdNumber from 'containers/registration/complete_profile/sections/IndividualCrdNumber';
import {
  registeredAsBDOrIA,
  isRegisteredRepresentative,
  registeredRepresentativeSeries,
  iaWithSeries65,
} from 'containers/registration/complete_profile/profileData';
import ProgressBar from './ProgressBar';
import YesNoQuestion from './YesNoQuestion';
import ForwardBackButtons from './ForwardBackButtons';
import StyledDialogContent from './StyledDialogContent';
import { calculateCanCompleteStepThree } from '../helpers/validations';

function RiaUserIdentificationPageThree({
  progress, onNext, onBack, saveInformation, information,
}) {
  const theme = useTheme();

  const selectRegistrationStatus = ({ target: { value } }) => {
    saveInformation({ [registeredAsBDOrIA.question_key]: value });
  };

  const saveRegisteredRepInfo = (keyValuePair) => {
    saveInformation(keyValuePair);
    // If value is false, reset array of series to empty array
    if (!Object.values(keyValuePair)[0]) {
      saveInformation({ [registeredRepresentativeSeries.question_key]: [] });
    }
  };

  const canContinue = calculateCanCompleteStepThree(information);

  return (
    <>
      <StyledDialogContent>
        <ProgressBar progress={progress} />
        <RegisteredAsBdOrRia
          selectRegistrationStatus={selectRegistrationStatus}
          selected={information?.[registeredAsBDOrIA.question_key] || ''}
          label={registeredAsBDOrIA.text}
          hasHeadlineText
        />

        <Box sx={{ marginTop: theme.spacing(3) }}>
          <YesNoQuestion
            informationKey={isRegisteredRepresentative.question_key}
            questionText={isRegisteredRepresentative.text}
            information={information}
            saveInformation={saveRegisteredRepInfo}
          />
          {information[isRegisteredRepresentative.question_key] && (
            <RegistrationSeries
              currentSeriesSelections={information?.[registeredRepresentativeSeries.question_key] || []}
              updateStore={saveInformation}
            />
          )}
        </Box>

        {/* Series 65 */}
        <Box sx={{ marginTop: theme.spacing(1.5) }}>
          <YesNoQuestion
            informationKey={iaWithSeries65.question_key}
            questionText={iaWithSeries65.text}
            information={information}
            saveInformation={saveInformation}
          />
        </Box>

        <Box sx={{ marginTop: theme.spacing(1) }}>
          <IndividualCrdNumber
            profileData={information}
            updateStore={saveInformation}
            hasHeadlineText
            isPopup
          />
        </Box>

      </StyledDialogContent>
      <ForwardBackButtons onNext={onNext} onBack={onBack} canContinue={canContinue} />
    </>
  );
}

RiaUserIdentificationPageThree.propTypes = {
  progress: number.isRequired,
  information: shape({}).isRequired,
  onNext: func.isRequired,
  onBack: func.isRequired,
  saveInformation: func.isRequired,
};

export default RiaUserIdentificationPageThree;
