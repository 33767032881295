import {
  DC_SET_RESULTS_PER_PAGE,
} from "../../actionsConstants"

import localStorageAdapter from 'services/local_storage_adapter/localStorageAdapter';
import {ICN_PER_PAGE_LOCAL_STORAGE_PROP} from 'constants/index';
import store from 'icnStore';
import dcDownloadActions from "../doc_center_download_actions/dcDownloadActions";

const {
  toggleSelectAll,
} = dcDownloadActions;



function setPerPage(perPage) {
  const dcDownloads = store.getState().dcDownloads;
  if (!dcDownloads.allSelected) {
    store.dispatch(toggleSelectAll({showBanner: false}));
  }

  localStorageAdapter.set(ICN_PER_PAGE_LOCAL_STORAGE_PROP, perPage);
  return {
    type: DC_SET_RESULTS_PER_PAGE,
    payload: perPage,
  }
}

export default {
  setPerPage,
}
