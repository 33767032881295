import { FLEXIBLE_FEED_DASHBOARD_SET_BREADCRUMBS } from 'actions/actionsConstants';

import { updateState } from '../helpers';
import initialState from '../initialState';

export default (state = initialState, action) => {
  switch (action.type) {
    case FLEXIBLE_FEED_DASHBOARD_SET_BREADCRUMBS:
      return updateState(state, action.payload, (steps) => ({
        breadcrumbs: steps,
      }));
    default:
      return state;
  }
};
