import {
  ADMS_SET_IS_PREVIEW_RESULTS_OPEN,
  ADMS_SET_IS_SEARCH_BUTTON_ENABLE,
  ADMS_SET_IS_SEARCH_INPUT_DISABLE,
  ADMS_SET_RESULTS_PER_PAGE,
  ADMS_SET_CURRENT_PAGE,
  ADMS_SET_SELECTED_RESULT,
  ADMS_SET_SELECTED_RESULT_POSITION,
  ADMS_SET_RELATED_INFO_DATA,
  ADMS_SET_IS_EDIT_CONTACT_CARD_EMAIL_MODAL_OPEN,
  ADMS_SET_EDIT_CONTACT_CARD_EMAIL_ERRORS,
  ADMS_SET_RELATED_TABLES_PAGES,
  ADMS_SET_CONTACT_CARD_TO_EDIT,
  ADMS_SET_EDIT_TYPE,
  ADMS_SET_CLONED_USER_PASSWORD,
  ADMS_SET_CLONED_USER_DATA,
  ADMS_SET_PREVIOUS_PAGE_URL,
} from 'actions/actionsConstants';

import update from 'immutability-helper';

import initialState from '../initialState';

export default (state = initialState, action) => {
  switch (action.type) {
    case ADMS_SET_IS_PREVIEW_RESULTS_OPEN: {
      return update(state, {
        is_preview_results_open: { $set: action.payload },
      });
    }
    case ADMS_SET_IS_SEARCH_BUTTON_ENABLE: {
      return update(state, {
        is_search_button_enable: { $set: action.payload },
      });
    }
    case ADMS_SET_IS_SEARCH_INPUT_DISABLE: {
      return update(state, {
        is_search_input_disable: { $set: action.payload },
      });
    }
    case ADMS_SET_RESULTS_PER_PAGE: {
      return update(state, {
        results_per_page: { $set: action.payload },
        current_page: { $set: 1 },
      });
    }
    case ADMS_SET_CURRENT_PAGE: {
      return update(state, {
        current_page: { $set: action.payload },
      });
    }
    case ADMS_SET_SELECTED_RESULT: {
      return update(state, {
        selected_result: { $set: action.payload },
      });
    }
    case ADMS_SET_SELECTED_RESULT_POSITION: {
      return update(state, {
        selected_result_position: { $set: action.payload },
      });
    }
    case ADMS_SET_RELATED_INFO_DATA: {
      return update(state, {
        related_info_data: { $set: action.payload },
      });
    }
    case ADMS_SET_IS_EDIT_CONTACT_CARD_EMAIL_MODAL_OPEN: {
      return update(state, {
        is_edit_contact_card_email_modal_open: { $set: action.payload },
      });
    }
    case ADMS_SET_EDIT_CONTACT_CARD_EMAIL_ERRORS: {
      return update(state, {
        edit_contact_card_email_errors: { $set: action.payload },
      });
    }
    case ADMS_SET_RELATED_TABLES_PAGES: {
      return update(state, {
        related_tables_pages: { $set: action.payload },
      });
    }
    case ADMS_SET_CONTACT_CARD_TO_EDIT: {
      return update(state, {
        contact_card_to_edit: { $set: action.payload },
      });
    }
    case ADMS_SET_EDIT_TYPE: {
      return update(state, {
        edit_type: { $set: action.payload },
      });
    }
    case ADMS_SET_CLONED_USER_PASSWORD: {
      return update(state, {
        cloned_user_password: { $set: action.payload },
      });
    }
    case ADMS_SET_CLONED_USER_DATA: {
      return update(state, {
        cloned_user_email: { $set: action.payload.email },
        cloned_user_first_name: { $set: action.payload.firstName },
        cloned_user_last_name: { $set: action.payload.lastName },
        cloned_user_ticket_id: { $set: action.payload.ticketId },
      });
    }
    case ADMS_SET_PREVIOUS_PAGE_URL: {
      return update(state, {
        previous_page_url: { $set: action.payload },
      });
    }
    default:
      return state;
  }
};
