import update from 'immutability-helper';
import {
  LP_SET_ANNOUNCEMENT_TRANSLATION_DIALOG_IS_OPEN,
  LP_SET_ANNOUNCEMENT_TRANSLATION,
  LP_SET_ANNOUNCEMENT_LIST_TRANSLATION,
  LP_SET_ANNOUNCEMENT_ORIGINAL_TRANSLATION,
} from 'modules/landing_page/actions/constants';
import initialState from '../../initialState';

// eslint-disable-next-line default-param-last
export default (state = initialState, { type, payload = {} }) => {
  switch (type) {
    case LP_SET_ANNOUNCEMENT_TRANSLATION_DIALOG_IS_OPEN: {
      return update(state, {
        edit: {
          translation: {
            announcementDialogOpen: { $set: payload },
          },
        },
      });
    }
    case LP_SET_ANNOUNCEMENT_TRANSLATION: {
      return update(state, {
        edit: {
          translation: {
            announcement: { $set: payload },
          },
        },
      });
    }
    case LP_SET_ANNOUNCEMENT_LIST_TRANSLATION: {
      return update(state, {
        edit: {
          translation: {
            list: { $merge: payload },
          },
        },
      });
    }
    case LP_SET_ANNOUNCEMENT_ORIGINAL_TRANSLATION: {
      return update(state, {
        edit: {
          translation: {
            originalAnnouncement: { $set: payload },
          },
        },
      });
    }
    default:
      return state;
  }
}

function upsert(array, element) { // (1)
  const i = array.findIndex(_element => _element.id === element.id);
  if (i > -1) array[i] = element; // (2)
  else array.push(element);
}
