import update from 'immutability-helper';

import {
  RGA_SCHEDULED_REQUEST_SET_DELIVERY_SCHEDULES,
  RGA_SCHEDULED_REQUEST_SHOW_DELIVERY_SCHEDULE_MODAL,
  RG_SET_CONFIG_ID_TO_DISABLE,
} from 'actions/actionsConstants';

import initialState from '../initialState';

export default (state = initialState, action) => {
  switch (action.type) {
    case RGA_SCHEDULED_REQUEST_SHOW_DELIVERY_SCHEDULE_MODAL: {
      return update(state, {
        showDeliveryScheduleModal: { $set: action.payload },
      });
    }
    case RGA_SCHEDULED_REQUEST_SET_DELIVERY_SCHEDULES: {
      const { past_schedules = [], upcoming_schedules = [] } = action.payload;

      return {
        ...state,
        deliverySchedules: update(state.deliverySchedules, {
          pastSchedules: { $set: past_schedules },
          upcomingSchedules: { $set: upcoming_schedules },
        }),
      };
    }
    case RG_SET_CONFIG_ID_TO_DISABLE: {
      return update(state, {
        scheduleIdToDisable: { $set: action.payload },
      });
    }
    default:
      return state;
  }
};
