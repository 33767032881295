export const PPB_SET_LOADING = 'PPB_SET_LOADING';
export const PPB_SET_FUND_TEMPLATE = 'PPB_SET_FUND_TEMPLATE';
export const PPB_SET_FUND_TEMPLATE_DETAILS = 'PPB_SET_FUND_TEMPLATE_DETAILS';
export const PPB_SET_FUND_TEMPLATES = 'PPB_SET_FUND_TEMPLATES';
export const PPB_SET_FUND_PROFILE = 'PPB_SET_FUND_PROFILE';
export const PPB_SET_FUND_PROFILE_VIEW = 'PPB_SET_FUND_PROFILE_VIEW';
export const PPB_SET_FUND_PROFILE_FUND = 'PPB_SET_FUND_PROFILE_FUND';
export const PPB_SET_IS_INVESTOR_PROFILE_COMPLETED = 'PPB_SET_IS_INVESTOR_PROFILE_COMPLETED';
export const PPB_SET_USER_HAS_INVESTOR_PROFILE = 'PPB_SET_USER_HAS_INVESTOR_PROFILE';
export const PPB_SET_IS_CONTACT_INFO_OPEN = 'PPB_SET_IS_CONTACT_INFO_OPEN';
