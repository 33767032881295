import { PASSPORT_SET_CANDIDATES_DATA, PASSPORT_MENU_TOGGLE } from 'modules/passport/actions/constants';
import update from 'immutability-helper';
import initialState from './initialState';

export default (action, state = initialState) => {
  switch (action.type) {
    case PASSPORT_SET_CANDIDATES_DATA: {
      return update(state, { $set: action.payload });
    }
    case PASSPORT_MENU_TOGGLE: {
      return update(state, { isPassportMenuOpen: { $set: !state.isPassportMenuOpen } });
    }
    default:
      return state;
  }
};
