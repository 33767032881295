import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { userShape } from 'prop_types/shared';
import { getUser } from 'selectors/icnBootstrapSelectors';

export const CurrentUserContext = createContext();

function CurrentUserProvider({ user, children }) {
  return (
    <CurrentUserContext.Provider value={user}>
      {children}
    </CurrentUserContext.Provider>
  );
}

function mapStateToProps(state) {
  const user = getUser(state);
  return {
    user,
  };
}

CurrentUserProvider.propTypes = {
  user: userShape,
  children: PropTypes.node.isRequired,
};

CurrentUserProvider.defaultProps = {
  user: null,
};

export default connect(mapStateToProps, null)(CurrentUserProvider);
