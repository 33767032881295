// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LayoutContainer-module__LayoutContainer___XuTaZ{flex:1 0 auto;margin-top:32px;margin-bottom:0;margin-right:48px;margin-left:48px}.LayoutContainer-module__LayoutContainer--fullHeight___ZvZ4c{margin-top:0;margin-bottom:0}.LayoutContainer-module__LayoutContainer--fullWidth___QCocr{margin-right:0;margin-left:0}.LayoutContainer-module__LayoutContainer--headerAlert___E3_65{margin-top:52px}@media screen and (max-width: 650px){.LayoutContainer-module__LayoutContainer--headerAlert___E3_65{margin-top:72px}}`, "",{"version":3,"sources":["webpack://./icn_react/src/components/shared/layout_global/LayoutContainer.module.scss"],"names":[],"mappings":"AAAA,iDACE,aAAA,CACA,eAAA,CACA,eAAA,CACA,iBAAA,CACA,gBAAA,CAEA,6DACE,YAAA,CACA,eAAA,CAGF,4DACE,cAAA,CACA,aAAA,CAGF,8DACE,eAAA,CAEA,qCAHF,8DAII,eAAA,CAAA","sourcesContent":[".LayoutContainer {\n  flex: 1 0 auto;\n  margin-top: 32px;\n  margin-bottom: 0;\n  margin-right: 48px;\n  margin-left: 48px;\n\n  &--fullHeight {\n    margin-top: 0;\n    margin-bottom: 0;\n  }\n\n  &--fullWidth {\n    margin-right: 0;\n    margin-left: 0;\n  }\n\n  &--headerAlert {\n    margin-top: 52px;\n\n    @media screen and (max-width: 650px) {\n      margin-top: 72px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"LayoutContainer": `LayoutContainer-module__LayoutContainer___XuTaZ`,
	"LayoutContainer--fullHeight": `LayoutContainer-module__LayoutContainer--fullHeight___ZvZ4c`,
	"LayoutContainer--fullWidth": `LayoutContainer-module__LayoutContainer--fullWidth___QCocr`,
	"LayoutContainer--headerAlert": `LayoutContainer-module__LayoutContainer--headerAlert___E3_65`
};
export default ___CSS_LOADER_EXPORT___;
