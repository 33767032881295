/* eslint-disable max-lines-per-function */
/* eslint-disable max-lines */
import { formatDate } from 'services/DateUtils';
import currencyFormatter from 'services/pcaps/currencyFormatter';
import percentageFormatter from 'services/formatters/percentageFormatter';
import returnTypeFormatter, { frequencyFormatter } from '../formatters';
import NumericCellRenderer from '../NumericCellRenderer';

const columnDefinitions = [
  {
    headerName: 'Fund Name',
    field: 'fund_name',
    pinned: 'left',
    minWidth: 220,
    lockPinned: true,
  },
  {
    headerName: 'Investment Strategy',
    field: 'display_strategy',
    pinned: 'left',
    minWidth: 110,
    lockPinned: true,
  },
  {
    headerName: 'Share Class Name',
    field: 'share_class_name',
    pinned: 'left',
    minWidth: 200,
    lockPinned: true,
  },
  {
    headerName: 'Return type',
    field: 'source',
    pinned: 'left',
    valueFormatter: returnTypeFormatter,
    lockPinned: true,
  },
  {
    headerName: 'Return Date',
    field: 'return_date',
    pinned: 'left',
    valueFormatter: (params) => formatDate(params.value, 'dd-MMM-yyyy'),
    lockPinned: true,
  },
  {
    headerName: 'Return Frequency',
    field: 'valuation_frequency',
    pinned: 'left',
    valueFormatter: frequencyFormatter,
    lockPinned: true,
  },
  {
    headerName: 'Return',
    field: 'mtd_rate_of_return',
    valueFormatter: (value) => percentageFormatter(value),
    cellRenderer: NumericCellRenderer,
    type: 'numericColumn',
  },
  {
    headerName: 'Prior Period Return',
    field: 'previous_month_return',
    valueFormatter: (value) => percentageFormatter(value),
    cellRenderer: NumericCellRenderer,
    type: 'numericColumn',
  },
  {
    headerName: 'Trailing 3-Month Return',
    field: 'three_month_return',
    minWidth: 140,
    valueFormatter: (value) => percentageFormatter(value),
    cellRenderer: NumericCellRenderer,
    type: 'numericColumn',
  },
  {
    headerName: 'Trailing 6-Month Return',
    field: 'six_month_return',
    minWidth: 140,
    valueFormatter: (value) => percentageFormatter(value),
    cellRenderer: NumericCellRenderer,
    type: 'numericColumn',
  },
  {
    headerName: 'Year-To-Date Return',
    field: 'ytd_return',
    valueFormatter: (value) => percentageFormatter(value),
    cellRenderer: NumericCellRenderer,
    type: 'numericColumn',
  },
  {
    headerName: 'Trailing One-Year Return',
    field: 'one_year_annualized',
    valueFormatter: (value) => percentageFormatter(value),
    cellRenderer: NumericCellRenderer,
    type: 'numericColumn',
  },
  {
    headerName: 'Trailing Three-Year Return',
    field: 'three_year_trailing',
    minWidth: 130,
    valueFormatter: (value) => percentageFormatter(value),
    cellRenderer: NumericCellRenderer,
    type: 'numericColumn',
  },
  {
    headerName: 'Trailing Five-Year Return',
    field: 'five_year_trailing',
    valueFormatter: (value) => percentageFormatter(value),
    cellRenderer: NumericCellRenderer,
    type: 'numericColumn',
  },
  {
    headerName: 'Since Inception Return',
    field: 'inception_return',
    minWidth: 140,
    valueFormatter: (value) => percentageFormatter(value),
    cellRenderer: NumericCellRenderer,
    type: 'numericColumn',
  },
  {
    headerName: 'Standard Deviation',
    field: 'standard_deviation',
    valueFormatter: (params) => currencyFormatter(params, false, 4, true),
    cellRenderer: NumericCellRenderer,
    type: 'numericColumn',
  },
  {
    headerName: 'Sharpe Ratio',
    field: 'class_sharpe_ratio',
    valueFormatter: (params) => currencyFormatter(params, false, 4, true),
    cellRenderer: NumericCellRenderer,
    type: 'numericColumn',
  },
  {
    headerName: 'Downside Deviation',
    field: 'downside_deviation',
    valueFormatter: (params) => currencyFormatter(params, false, 4, true),
    cellRenderer: NumericCellRenderer,
    type: 'numericColumn',
  },
  {
    headerName: 'Sortino Ratio',
    field: 'class_sortino',
    valueFormatter: (params) => currencyFormatter(params, false, 4, true),
    cellRenderer: NumericCellRenderer,
    type: 'numericColumn',
  },
  {
    headerName: 'Negative Periods (%)',
    field: 'neg_months',
    valueFormatter: (value) => percentageFormatter(value),
    cellRenderer: NumericCellRenderer,
    type: 'numericColumn',
  },
  {
    headerName: 'Positive Periods (%)',
    field: 'pos_months',
    valueFormatter: (value) => percentageFormatter(value),
    cellRenderer: NumericCellRenderer,
    type: 'numericColumn',
  },
  {
    headerName: 'Inception Date',
    field: 'share_class_inception_date',
    valueFormatter: (params) => formatDate(params.value, 'dd-MMM-yyyy'),
    type: 'numericColumn',
  },
  {
    headerName: 'Share Class ID',
    field: 'share_class_id',
    minWidth: 90,
    type: 'numericColumn',
  },
  {
    headerName: 'ID',
    field: 'class_client_id',
    minWidth: 90,
    type: 'numericColumn',
  },
  {
    headerName: 'Current Drawdown',
    field: 'current_drawdown',
    valueFormatter: (value) => percentageFormatter(value),
    cellRenderer: NumericCellRenderer,
    type: 'numericColumn',
  },
  {
    headerName: 'Max Drawdown',
    field: 'max_drawdown',
    valueFormatter: (value) => percentageFormatter(value),
    cellRenderer: NumericCellRenderer,
    type: 'numericColumn',
  },
  {
    headerName: 'Lowest Return',
    field: 'lowest_return',
    valueFormatter: (value) => percentageFormatter(value),
    cellRenderer: NumericCellRenderer,
    type: 'numericColumn',
  },
  {
    headerName: 'Highest Return',
    field: 'highest_return',
    valueFormatter: (value) => percentageFormatter(value),
    cellRenderer: NumericCellRenderer,
    type: 'numericColumn',
  },
  {
    headerName: 'Three Year Annualized',
    field: 'three_year_annualized',
    valueFormatter: (value) => percentageFormatter(value),
    cellRenderer: NumericCellRenderer,
    type: 'numericColumn',
  },
  {
    headerName: 'Five Year Annualized',
    field: 'five_year_annualized',
    valueFormatter: (value) => percentageFormatter(value),
    cellRenderer: NumericCellRenderer,
    type: 'numericColumn',
  },
  {
    headerName: 'ITD Annualized',
    field: 'itd_annualized',
    valueFormatter: (value) => percentageFormatter(value),
    cellRenderer: NumericCellRenderer,
    type: 'numericColumn',
  },
  {
    headerName: 'Period Yield %',
    field: 'mtd_yield',
    valueFormatter: (value) => percentageFormatter(value),
    type: 'numericColumn',
  },
  {
    headerName: 'Period Yield % Annualized',
    field: 'mtd_yield_annualized',
    valueFormatter: (value) => percentageFormatter(value),
    type: 'numericColumn',
  },
  {
    headerName: 'Distribution Per Share',
    field: 'distribution_per_share',
    valueFormatter: (params) => currencyFormatter(params, false, 4, true),
    cellRenderer: NumericCellRenderer,
    type: 'numericColumn',
  },
  {
    headerName: 'Last 3 Months Annualized Distribution Rate',
    field: 'three_month_distribution_rate_annualized',
    valueFormatter: (value) => percentageFormatter(value),
    cellRenderer: NumericCellRenderer,
    type: 'numericColumn',
  },
  {
    headerName: 'Last 12 Months Annualized Distribution Rate',
    field: 'twelve_month_distribution_rate_annualized',
    valueFormatter: (value) => percentageFormatter(value),
    cellRenderer: NumericCellRenderer,
    type: 'numericColumn',
  },
  {
    headerName: 'NAV',
    field: 'actual_nav_per_share',
    valueFormatter: (params) => currencyFormatter(params, false, 4, true),
    cellRenderer: NumericCellRenderer,
    type: 'numericColumn',
  },
  {
    headerName: 'Fund AUM',
    field: 'fund_closing_balance',
    valueFormatter: (params) => currencyFormatter(params, false, 4, true),
    cellRenderer: NumericCellRenderer,
    type: 'numericColumn',
  },
];

export default columnDefinitions;
