import {
  EMAIL_REGEX, MMDD_REGEX, MMDDYYYY_REGEX, MMDDYYYY_SLASH_REGEX, ALLOWED_CHARACTERS_REGEX,
} from 'services/regex';
import _ from 'lodash';

const validators = {
  unique: (value, { compareFields, includeField, errorMsg }, profile) => {
    const valid = compareFields.filter((field) => (
      value === _.get(profile, field)
    )).length <= (includeField ? 1 : 0);

    return {
      valid,
      errorMsg: errorMsg || 'uniqueness error...',
    };
  },

  raymondJamesTemp: (value, { errorMsg }) => ({
    valid: false,
    errorMsg: errorMsg || 'error...',
  }),

  phone: (value, { errorMsg }) => ({
    valid: value.length === 10,
    errorMsg: errorMsg || 'Please enter a valid phone number.',
  }),

  length: (value, { validLength, errorMsg }) => ({
    valid: value.length === validLength,
    errorMsg: errorMsg || `Please provide ${validLength} digits.`,
  }),

  email: (value, { errorMsg }) => ({
    valid: !!value.match(EMAIL_REGEX),
    errorMsg: errorMsg || 'Please provide a valid email address.',
  }),

  allowedCharacters: (value, { errorMsg }) => ({
    valid: !!value.match(ALLOWED_CHARACTERS_REGEX),
    errorMsg: errorMsg || 'Special characters are not allowed in this field.',
  }),

  min: (value, { validMin, errorMsg }) => ({
    valid: value >= validMin,
    errorMsg: errorMsg || `Please enter a number greater than ${validMin - 1}`,
  }),

  date: (value, { format, errorMsg }) => {
    switch (format) {
      case 'MMDD':
        return {
          valid: !!value.match(MMDD_REGEX),
          errorMsg: errorMsg || 'Please provide date in MM/DD format.',
        };
      case 'MMDDYYYY':
        return {
          valid: !!value.match(MMDDYYYY_REGEX),
          errorMsg: errorMsg || 'Please provide date in MM/DD/YYYY format.',
        };
      case 'MM/DD/YYYY':
        return {
          valid: !!value.match(MMDDYYYY_SLASH_REGEX),
          errorMsg: errorMsg || 'Please provide date in MM/DD/YYYY format.',
        };
      default:
        return {
          valid: true,
          errorMsg: errorMsg || null,
        };
    }
  },
};

export default validators;
