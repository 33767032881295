import {
  SUPP_FORM_SET_FORM_DATA,
  SUPP_FORM_SET_SCHEMA,
  SUPPLEMENTAL_FORM_UNMOUNT,
  SUPP_FORM_START_REQUEST,
  SUPP_FORM_END_REQUEST,
  SUPP_FORM_STARTED,
  SUPP_FORM_ACCOUNT_INFO,
  SUPP_FORM_SET_EDIT_PERMISSION,
} from 'actions/actionsConstants';
import { createAction } from 'redux-actions';
import localStorageAdapter from 'services/local_storage_adapter/localStorageAdapter';
import { cloneDeep, omit } from 'lodash';
import { getRequest, patchRequest, postRequest } from '../utils';

const suppFormSetFormData = createAction(SUPP_FORM_SET_FORM_DATA);
const suppFormSetSchema = createAction(SUPP_FORM_SET_SCHEMA);
const suppFormStartRequest = createAction(SUPP_FORM_START_REQUEST, (requestsLoading = 1) => ({ requestsLoading }));
const suppFormEndRequest = createAction(SUPP_FORM_END_REQUEST);
const suppFormStarted = createAction(SUPP_FORM_STARTED);
const suppFormAccountInfo = createAction(SUPP_FORM_ACCOUNT_INFO);
const suppFormSetEditPermission = createAction(SUPP_FORM_SET_EDIT_PERMISSION);

function suppFormInvestmentResponses(investmentId) {
  return getRequest({
    url: `investment_supplemental_responses/${investmentId}`,
    onSuccess: suppFormSetFormData,
  });
}

function suppFormUpdate(investmentId, data) {
  return patchRequest({
    url: `investment_supplemental_responses/${investmentId}`,
    data,
    errorMessage: {
      message: 'Something went wrong. Please contact support.',
    },
  });
}

function updateSchemas(data) {
  return (dispatch) => {
    if (data.schema) {
      const schemas = {
        schema: cloneDeep(data.schema),
        uiSchema: cloneDeep(data.ui_schema),
      };

      dispatch(suppFormSetSchema(schemas));
    }
  };
}

function updateAccountInfo(data) {
  return (dispatch) => {
    const accountInfo = {
      accountName: data.account_name,
      accountNum: data.account_num,
    };
    dispatch(suppFormAccountInfo(accountInfo));
  };
}

function updateFormData(data) {
  return (dispatch) => {
    const formData = {
      ...omit(data, ['schema', 'ui_schema', 'account_name', 'account_num', 'can_edit_frozen_supp_form']),
    };

    if (data.signer_type) {
      formData.signer_type = data.signer_type === '1' ? 'entity' : 'individual';
    }

    dispatch(suppFormSetFormData(formData));
  };
}

function onSuccessInvestmentDetailsCallback(data) {
  return (dispatch) => {
    dispatch(updateFormData(data));
    dispatch(updateSchemas(data));
    dispatch(updateAccountInfo(data));
    dispatch(suppFormSetEditPermission(data.can_edit_frozen_supp_form));
  };
}

function suppFormInvestmentDetails(investmentId) {
  return getRequest({
    url: `investment_supplemental_responses/${investmentId}/investment_details`,
    onSuccess: onSuccessInvestmentDetailsCallback,
  });
}

function suppFormDetails(pafId, investorProfileId = null) {
  const params = { paf_id: pafId };
  if (investorProfileId) {
    params.investor_profile_id = investorProfileId;
  }

  return getRequest({
    url: 'investment_supplemental_responses/supplemental_form_details',
    params,
    onSuccess: onSuccessInvestmentDetailsCallback,
  });
}

function suppFormInitInvestment(investmentId) {
  return (dispatch) => {
    dispatch(suppFormStartRequest(2));
    dispatch(suppFormInvestmentDetails(investmentId)).finally(() => {
      dispatch(suppFormEndRequest());
    });
    dispatch(suppFormInvestmentResponses(investmentId)).finally(() => {
      dispatch(suppFormEndRequest());
    });
  };
}

const parseSuppFormAnswer = (data) => {
  try {
    return JSON.parse(data);
  } catch (err) {
    return null;
  }
};

function suppFormInitPreInvestment(signerType, pafId, investorProfileId, preInvestment) {
  return (dispatch) => {
    let finalFormData = {
      signer_type: signerType === '1' ? 'entity' : 'individual',
    };

    let request = Promise.resolve();

    if (pafId) {
      dispatch(suppFormStartRequest());
      request = dispatch(suppFormDetails(pafId, investorProfileId)).finally(() => {
        dispatch(suppFormEndRequest());
      });
    }

    if (preInvestment) {
      const answers = parseSuppFormAnswer(localStorageAdapter.get('supplemental_form_answers'));
      finalFormData = answers ? { ...finalFormData, ...answers } : finalFormData;
    }
    return request.then(() => {
      dispatch(suppFormSetFormData(finalFormData));
    });
  };
}

const supplementalFormUnmount = createAction(SUPPLEMENTAL_FORM_UNMOUNT);

function redemptionSuppFormCreateResponse(data) {
  return postRequest({
    url: 'redemption_supplemental_form_responses',
    data,
    errorMessage: {
      message: 'Something went wrong. Please contact support.',
    },
  });
}

function redemptionSuppFormUpdate(redemptionId, data) {
  return patchRequest({
    url: `redemption_supplemental_form_responses/${redemptionId}`,
    data,
    errorMessage: {
      message: 'Something went wrong. Please contact support.',
    },
  });
}

function redemptionSuppFormDetails(redemptionId) {
  return getRequest({
    url: `redemption_supplemental_forms/${redemptionId}`,
    onSuccess: onSuccessInvestmentDetailsCallback,
  });
}

function redemptionSuppFormResponses(redemptionId) {
  return getRequest({
    url: `redemption_supplemental_form_responses/${redemptionId}`,
    onSuccess: suppFormSetFormData,
  });
}

function redemptionSuppForm(redemptionId) {
  return (dispatch) => {
    dispatch(redemptionSuppFormDetails(redemptionId));
    dispatch(redemptionSuppFormResponses(redemptionId))
      .catch((error) => {
        if (error.status === 404) {
          console.error('No Redemption Supplemental Form Response Exists', error);
          dispatch(suppFormStarted(false));
        }
      })
      .then(dispatch(suppFormStarted(true)));
  };
}

export default {
  suppFormInitInvestment,
  suppFormInitPreInvestment,
  suppFormInvestmentDetails,
  suppFormInvestmentResponses,
  redemptionSuppForm,
  redemptionSuppFormDetails,
  redemptionSuppFormResponses,
  redemptionSuppFormCreateResponse,
  redemptionSuppFormUpdate,
  suppFormUpdate,
  suppFormDetails,
  supplementalFormUnmount,
  suppFormStartRequest,
  suppFormEndRequest,
  suppFormStarted,
};
