/* eslint-disable max-statements */
import { isUndefined, isBoolean } from 'lodash';
import {
  registeredAsBDOrIA,
  iaWithSeries65,
  isRegisteredRepresentative,
  registeredRepresentativeSeries,
  advisorCrdNumber,
  insuranceAgent,
  trustOrFiduciary,
  firmRoleIfOther,
  isOtherTypeInstitution,
  otherTypeInstitutionalInvestor,
  totalAssets,
} from '../../complete_profile/profileData';

export function formatExistingInformation(answers) {
  const seriesUserHas = [];
  if (answers.registered_representative_series_7 === true) seriesUserHas.push('registered_representative_series_7');
  if (answers.registered_representative_series_6 === true) seriesUserHas.push('registered_representative_series_6');
  if (answers.registered_representative_series_1 === true) seriesUserHas.push('registered_representative_series_1');

  const formattedAnswers = {
    role: answers.role,
    [firmRoleIfOther.question_key]: answers[firmRoleIfOther.question_key],
    [registeredAsBDOrIA.question_key]: answers[registeredAsBDOrIA.question_key],
    [registeredRepresentativeSeries.question_key]: seriesUserHas,
    [isRegisteredRepresentative.question_key]: seriesUserHas.length > 0,
    [iaWithSeries65.question_key]: isBoolean(answers[iaWithSeries65.question_key]) ? answers[iaWithSeries65.question_key] : '',
    [advisorCrdNumber.question_key]: answers[advisorCrdNumber.question_key] || '',
    [insuranceAgent.question_key]: isBoolean(answers[insuranceAgent.question_key]) ? answers[insuranceAgent.question_key] : '',
    [trustOrFiduciary.question_key]: isBoolean(answers[trustOrFiduciary.question_key]) ? answers[trustOrFiduciary.question_key] : '',
    capable_evaluating_investments: answers.capable_evaluating_investments === true,
    exercise_independent_judgement: answers.exercise_independent_judgement === true,
    responsible_verification_client_eligibility: answers.responsible_verification_client_eligibility === true,
  };

  if (answers[isOtherTypeInstitution.question_key] === false) {
    formattedAnswers[isOtherTypeInstitution.question_key] = false;
  } else if (isUndefined(answers[isOtherTypeInstitution.question_key])) {
    formattedAnswers[isOtherTypeInstitution.question_key] = !!answers[otherTypeInstitutionalInvestor.question_key];
  } else {
    formattedAnswers[isOtherTypeInstitution.question_key] =
      answers[isOtherTypeInstitution.question_key] === true ||
      formattedAnswers[otherTypeInstitutionalInvestor.question_key];
  }
  const otherTypeInst = [
    'savings_and_loan_association',
    'insurance_company',
    'registered_investment_company',
    'any_other_entity',
  ].filter((key) => answers[key] === true)?.[0];
  if (otherTypeInst) {
    formattedAnswers[otherTypeInstitutionalInvestor.question_key] = otherTypeInst;
    if (otherTypeInst === 'any_other_entity') {
      formattedAnswers[totalAssets.question_key] = answers[totalAssets.question_key];
    }
  }

  return formattedAnswers;
}
