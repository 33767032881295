/* eslint-disable consistent-return */

import axios from 'axios';

// just get the data from the response
export async function getRequest(url, params, config) {
  try {
    const { data } = await axios(url, params, config);
    return data;
  } catch (error) {
    // ignore errors
  }
}

// just get the data from the response
export async function postRequest(url, config) {
  try {
    const { data } = await axios.post(url, config);
    return data;
  } catch {
    // ignore errors
  }
}

export default { getRequest, postRequest };
