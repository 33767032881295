import {
  shape, bool, string, number, array, arrayOf,
} from 'prop-types';
import statusLabelsShape from 'prop_types/shared/approval_status_labels';

export default shape({
  id: number,
  category: string,
  name: string,
  open_ended: bool,
  funding_for_close: bool,
  pre_close_required: bool,
  paf_supplemental_questions_form: shape({
    id: number,
    template_path: string,
    name: string,
    allowed_fields: array,
  }),
  has_fund_admin_integration: bool,
  has_tax_integration: bool,
  status: string,
  gp_firm_name: string,
  integration_partner_name: string,
  minimum_investment: number,
  icn_geo_country_firms: arrayOf(
    shape({
      name: string,
      firm_id: number,
    })
  ),
  custodian_names: arrayOf(string),
  investor_notice_period: number,
  fund_currency: shape({
    iso_code: string,
  }),
  show_net_worth_in_metadata: bool,
  minimum_net_worth_individual: number,
  minimum_net_worth_entity: number,
});

export const PafFundNameStatusShape = shape({
  id: number,
  fund_name: string,
  closed: bool,
  status: string,
});
