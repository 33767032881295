import { noValue, isIndividual } from 'services/investor_profile';

const defaultGeneralValues = {
  mailing_address_same_as_permanent: '0',
};

const defaultIndividualValues = {
  section_a_individual_state_country: 'United States of America',
  section_a_individual_address_2: '',
  section_a_individual_address: '',
  section_a_individual_city: '',
  section_a_individual_state: '',
  section_a_individual_zip: '',

};

const defaultEntityValues = {
  section_a_entity_state_country: 'United States of America',
  section_a_entity_address_2: '',
  section_a_entity_address: '',
  section_a_entity_city: '',
  section_a_entity_state: '',
  section_a_entity_zip: '',
  entity_domicile_country: 'United States of America',
  entity_jurisdiction_of_organization_country: 'United States of America',
};

function conditionalValues(profile, hasCustodian) {
  const { section_b_recipient_account_name, section_a_name_of_investor } = profile;
  const fields = {};

  if (
    !hasCustodian &&
    section_a_name_of_investor &&
    !section_b_recipient_account_name
  ) {
    fields.section_b_recipient_account_name = section_a_name_of_investor;
  }

  return fields;
}

function setDefaultsForEmptyFields(profile, defaults) {
  const valuesToSet = {};

  Object.keys(defaults).forEach((field) => {
    if (noValue(profile[field])) {
      valuesToSet[field] = defaults[field];
    }
  });

  return valuesToSet;
}

function initialValues(profile, hasCustodian) {
  const signerTypeValues = isIndividual(profile) ?
    setDefaultsForEmptyFields(profile, defaultIndividualValues) :
    setDefaultsForEmptyFields(profile, defaultEntityValues);

  return {
    ...setDefaultsForEmptyFields(profile, defaultGeneralValues),
    ...conditionalValues(profile, hasCustodian),
    ...signerTypeValues,
  };
}

export default initialValues;
