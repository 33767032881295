import { INITIAL_SCALE, MIN_ZOOM } from '../services/constants';

export default {
  currentPage: 1,
  numberOfPages: 1,
  document: null,
  loadingErrorMessage: '',
  isLoading: false,
  scale: INITIAL_SCALE,
  zoom: MIN_ZOOM,
  documentName: '',
  pageViewports: null,
  hasScrolledIntoPage: false,
};
