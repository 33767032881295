import {
  SET_INVESTOR_QUALIFICATION_STANDARD_QUESTIONS,
  UPDATE_INVESTOR_QUALIFICATION_STANDARDS_QUESTION,
} from 'actions/actionsConstants';
import update from 'immutability-helper';

export const initialState = {
  investorQualificationStandardQuestions: [],
  errorMessage: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_INVESTOR_QUALIFICATION_STANDARD_QUESTIONS: {
      let data = action.payload.map((iqs) => {
        return {
          ...iqs,
          checked: false
        }
      })
      return update(state, {
        investorQualificationStandardQuestions: {
          $set: data,
        }
      })
    }
    case UPDATE_INVESTOR_QUALIFICATION_STANDARDS_QUESTION: {
      let iqs = state.investorQualificationStandardQuestions.find(iqs => iqs.id === action.payload.id)
      if (!iqs) return
      return update(state, {
        investorQualificationStandardQuestions: {
          [state.investorQualificationStandardQuestions.indexOf(iqs)]: { $set: { ...iqs, checked: action.payload.checked } }
        }
      })
    }
    default: {
      return state;
    }
  }
};
