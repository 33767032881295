import filterReducer from './filterReducer';
import modalReducer from './modalReducer';
import configsReducer from './configsReducer';
import configFieldsReducer from './configFieldsReducer';

export default {
  filterReducer,
  modalReducer,
  configsReducer,
  configFieldsReducer,
};
