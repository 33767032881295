import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actions from 'actions';
import { string, bool, func } from 'prop-types';

import classnames from 'classnames';
import Table from 'react-bootstrap/Table';

import { searchResult } from 'prop_types/admin_search/index';
import contactCardColDefs from 'components/admin_search/preview_results/col_defs/preview_results_contact_cards';

import HighlightedSearchText from 'components/admin_search/cell_renderers/HighlightedSearchText';
import HighlightedSearchEmail from 'components/admin_search/cell_renderers/HighlightedSearchEmail';
import styles from './ASPreviewResultsList.module.scss';

const cx = classnames.bind(styles);

const components = {
  HighlightedSearchText,
  HighlightedSearchEmail,
  a: 'a',
};

export const ASPreviewResultsList = ({
  searchResults,
  searchKeyword,
  displayTitle,
  setIsSearchInputDisable,
}) => {
  useEffect(() => {
    setIsSearchInputDisable(false);
  }, [searchResults, setIsSearchInputDisable]);

  const columnDefs = (type, record) => {
    // Add here other types of results.
    // For each type create the columns definition in the colDefs folder.
    // For properties where we search for keywords, add the following properties to colDefs:
    // renderComponent = 'HighlightedSearchText';
    // renderComponentProps = {
    //   searchKeyword,
    //   value,
    // };
    switch (type) {
      case 'contact_card':
        return contactCardColDefs(searchKeyword, record);

      default:
        return [];
    }
  };

  const renderField = (field) => {
    const Component = components[field.renderComponent];

    if (Component) {
      return (
        <Component
          key={field.key}
          className={
            cx({
              [styles[field.className]]: true,
              [styles.id_column]: Component === components.a,
            })
          }
          {...field.renderComponentProps}
        >
          { field.value}
        </Component>
      );
    }

    return (
      <span
        key={field.key}
        className={
          cx({
            [styles[field.className]]: true,
          })
        }
      >
        {field.value}
      </span>
    );
  };

  return (
    <div>
      {displayTitle && (
        <span className={styles.as_title}>{searchResults.title}</span>
      )}
      {searchResults.results?.length > 0 ? (
        <Table
          className={styles.table}
          hover
        >
          <tbody>
            {searchResults.results.map((result) => (
              <tr key={result.id}>
                {columnDefs(searchResults.type, result)?.map((field, index) => (
                  <td
                    key={`${field}_${result.id}_${index}`}
                    className={styles[field.className] ? cx({
                      [styles[field.className]]: true,
                    }) : ''}
                  >
                    {renderField(field)}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <span className={styles.no_results_found}>
            No results found. Try adjusting your search to find what you’re looking for.
        </span>
      )}
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    setIsSearchInputDisable: actions.admsSetIsSearchInputDisable,
  }, dispatch);
}

ASPreviewResultsList.defaultProps = {
  searchResults: {},
};

ASPreviewResultsList.propTypes = {
  searchResults: searchResult,
  searchKeyword: string.isRequired,
  displayTitle: bool.isRequired,
  setIsSearchInputDisable: func.isRequired,
};

export default connect(null, mapDispatchToProps)(ASPreviewResultsList);
