import {
  DD_LOAD_DATA_SYNC_FILES,
} from 'actions/actionsConstants';

import initialState from '../initialState';
import { updateState } from '../helpers';

const REQUEST_OBJECT = 'data_sync_file';

export default (state = initialState, action) => {
  switch (action.type) {
    case DD_LOAD_DATA_SYNC_FILES:
      return updateState(state, action.payload, (data) => ({
        request_object: REQUEST_OBJECT,
        request_type: 'set_data_sync_files_list',
        message: 'Fetched Data Syncs Files List.',
        data_sync_files: {
          records: data.data_sync_files,
          created_by_id: data.meta.created_by_id,
          disallow_report_download: data.meta.disallow_report_download,
          responseId: Date.now(),
        },
      }));
    default:
      return state;
  }
};
