import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ReactModal from 'react-modal';
import { IcnButton } from '@icapitalnetwork/react-component-library';

import classNames from 'classnames/bind';
import styles from './IcnModal.module.scss';

const cx = classNames.bind(styles);

class IcnModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: props.modalOpen,
    };

    this.reactModal = null;
    this.openModal = this.openModal.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.renderButtons = this.renderButtons.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      modalOpen: nextProps.modalOpen,
    });
  }

  setOverlayClickCallback(callback) {
    if (callback) {
      const overlay = this.reactModal.node.firstChild;

      overlay.onclick = (event) => {
        if (overlay === event.target || !overlay.contains(event.target)) {
          callback();
        }
      };
    }
  }

  openModal() {
    this.setState({ modalOpen: true });
  }

  handleClick(callback) {
    this.setState({ modalOpen: false });

    if (callback) {
      callback();
    }
  }

  renderButtons() {
    const { buttons } = this.props;

    if (buttons.length <= 0) {
      return null;
    }
    return (
      <div className={cx({ buttons_container: true }, this.props.buttonsContainerClassName)}>
        {
          buttons.map((button) => {
            const {
              callback,
              text,
              styleType,
              disabled,
              className,
              size = 'sm',
            } = button;

            return (
              <IcnButton
                key={text}
                callback={() => this.handleClick(callback)}
                text={text}
                styleType={styleType}
                size={size}
                className={className}
                disabled={disabled}
              />
            );
          })
        }
      </div>
    );
  }

  render() {
    const {
      header, subHeader, body, contentLabel, openLinkText, size, onOverlayClick,
    } = this.props;


    const { modalOpen } = this.state;

    return (
      <div>
        {openLinkText && openLinkText.length > 0 && (
          <div className={styles.open_link_text_container}>
            <a onClick={this.openModal} href="#" role="button">
              {openLinkText}
            </a>
          </div>
        )}

        <ReactModal
          ref={(el) => { this.reactModal = el; }}
          isOpen={modalOpen}
          className={cx({ icn_modal_content: true, [size]: true }, this.props.modalClassName)}
          overlayClassName={cx({ icn_modal_overlay: true }, this.props.modalOverlayClassName)}
          contentLabel={contentLabel}
          onAfterOpen={() => { this.setOverlayClickCallback(onOverlayClick); }}
        >
          {header && (
            <h2 className={styles.modal_header}>{header}</h2>
          )}
          {subHeader && (
            <h4 className={styles.modal_sub_header}>{subHeader}</h4>
          )}
          {body && <div className={styles.modal_body}>{body}</div>}
          {this.renderButtons()}
        </ReactModal>
      </div>
    );
  }
}

IcnModal.defaultProps = {
  modalOpen: false,
  size: 'sm',
  buttons: [],
  header: '',
  subHeader: '',
  body: '',
  contentLabel: '',
  openLinkText: '',
  onOverlayClick: undefined,
  modalClassName: '',
  modalOverlayClassName: '',
  buttonsContainerClassName: '',
};

IcnModal.propTypes = {
  header: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  subHeader: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  body: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({}),
    PropTypes.node,
  ]),
  buttons: PropTypes.arrayOf(PropTypes.object),
  contentLabel: PropTypes.string,
  openLinkText: PropTypes.string,
  modalOpen: PropTypes.bool,
  size: PropTypes.string,
  onOverlayClick: PropTypes.func,
  modalClassName: PropTypes.string,
  modalOverlayClassName: PropTypes.string,
  buttonsContainerClassName: PropTypes.string,
};

export default IcnModal;
