export const BASE_URLS = {
  simon: '/simon/api/v2',
  icn: '/api/v2',
};

export const ALL = 'all';
export const LINKED = 'linked';
export const UNLINKED = 'unlinked';
export const NEW = 'new';
export const CURRENT = 'current';
export const CONFIRM = 'confirm';
export const LINK_ACCOUNT = 'linkAccount';
export const UNIFIED_PASSWORD = 'unifiedPassword';
export const CHANGE_PASSWORD = 'changePassword';
export const WELCOME = 'welcome';
export const ICN = 'icn';
export const SIMON = 'simon';
export const POST = 'post';
export const GET = 'get';
export const SOURCE = 'source';
export const DESTINATION = 'destination';
