import { setEmptyValues } from 'services/investor_profile';

export const INDIVIDUAL_DISREGARDED_ENTITY_FIELDS = [
  'disregarded_individual_account_type',
];

export const ENTITY_DISREGARDED_ENTITY_FIELDS = [
  'disregarded_entity_account_type',
  'disregarded_entity_other_account_type',
  'disregarded_entity_tax_classification',
  'disregarded_entity_other_tax_classification',
];

export const DISREGARDED_ENTITY_FIELDS = [
  'disregarded_entity_type',
  'disregarded_entity_beneficial_owner_name',
  'disregarded_entity_beneficial_owner_tax_id',
  'disregarded_entity_beneficial_owner_address_line1',
  'disregarded_entity_beneficial_owner_address_line2',
  'disregarded_entity_beneficial_owner_address_city',
  'disregarded_entity_beneficial_owner_address_state',
  'disregarded_entity_beneficial_owner_address_zipcode',
  'disregarded_entity_beneficial_owner_address_country',
  ...INDIVIDUAL_DISREGARDED_ENTITY_FIELDS,
  ...ENTITY_DISREGARDED_ENTITY_FIELDS,
];

function clearedMailingAddressFields() {
  const fields = ['section_a_mailing_address', 'section_a_mailing_address_2',
    'section_a_mailing_city', 'section_a_mailing_state', 'section_a_mailing_zip',
    'section_a_mailing_state_country'];

  return setEmptyValues(fields);
}

function permanentMailingAddressFields(profile) {
  return {
    section_a_mailing_address: profile.section_a_individual_address,
    section_a_mailing_address_2: profile.section_a_individual_address_2,
    section_a_mailing_city: profile.section_a_individual_city,
    section_a_mailing_state: profile.section_a_individual_state,
    section_a_mailing_zip: profile.section_a_individual_zip,
    section_a_mailing_state_country: profile.section_a_individual_state_country,
  };
}

function sideEffects(updateObj, profile, hasCustodian) {
  let modifiedObj = {};

  if (profile.section_a_entity_type === 'Other' &&
    updateObj.section_a_entity_type &&
    updateObj.section_a_entity_type !== 'Other') {
    modifiedObj.section_a_entity_type_other = '';
  }

  if (profile.section_a_entity_tax_classification === 'Other' &&
    updateObj.section_a_entity_tax_classification &&
    updateObj.section_a_entity_tax_classification !== 'Other') {
    modifiedObj.section_a_entity_other_tax_classification = '';
  }

  if (updateObj.mailing_address_same_as_permanent === '1') {
    modifiedObj = { ...modifiedObj, ...clearedMailingAddressFields() };
  }

  if (updateObj.mailing_address_same_as_permanent === '0') {
    modifiedObj = { ...modifiedObj, ...permanentMailingAddressFields(profile) };
  }

  if (updateObj.disregarded_entity === '0') {
    modifiedObj = { ...modifiedObj, ...setEmptyValues(DISREGARDED_ENTITY_FIELDS) };
  }

  if (updateObj.disregarded_entity_type === '0') {
    modifiedObj = { ...modifiedObj, ...setEmptyValues(ENTITY_DISREGARDED_ENTITY_FIELDS) };
  }

  if (updateObj.disregarded_entity_type === '1') {
    modifiedObj = { ...modifiedObj, ...setEmptyValues(INDIVIDUAL_DISREGARDED_ENTITY_FIELDS) };
  }

  if (profile.disregarded_entity_account_type === 'Other' &&
    updateObj.disregarded_entity_account_type &&
    updateObj.disregarded_entity_account_type !== 'Other') {
    modifiedObj.disregarded_entity_other_account_type = '';
  }

  if (profile.disregarded_entity_tax_classification === 'Other' &&
    updateObj.disregarded_entity_tax_classification &&
    updateObj.disregarded_entity_tax_classification !== 'Other') {
    modifiedObj.disregarded_entity_other_tax_classification = '';
  }

  if (hasCustodian && ('investor_name' in updateObj)) {
    modifiedObj.section_a_name_of_investor = updateObj.investor_name;
  }

  if (!hasCustodian && ('section_a_name_of_investor' in updateObj)) {
    modifiedObj.section_b_recipient_account_name = updateObj.section_a_name_of_investor;
  }

  if (updateObj.section_a_entity_state_country &&
    updateObj.section_a_entity_state_country !== profile.section_a_entity_state_country) {
    modifiedObj.section_a_entity_phone = '';
  }

  if (updateObj.section_a_individual_state_country &&
    updateObj.section_a_individual_state_country !== profile.section_a_individual_state_country) {
    modifiedObj.section_a_individual_pone = '';
  }

  return { ...updateObj, ...modifiedObj };
}

export default sideEffects;
