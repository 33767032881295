import {
  INTEGRATION_PARTNER_TEMPLATES_SET_INT_PARTNERS,
  INTEGRATION_PARTNER_TEMPLATES_SET_FUNDS,
  INTEGRATION_PARTNER_TEMPLATES_SET_BASE_TEMPLATES,
  INTEGRATION_PARTNER_TEMPLATES_SET_BASE_TEMPLATE,
  INTEGRATION_PARTNER_TEMPLATES_SET_TEMPLATES,
  INTEGRATION_PARTNER_TEMPLATES_SET_TEMPLATE,
  INTEGRATION_PARTNER_TEMPLATES_UPDATE_PAGINATION,
  INTEGRATION_PARTNER_TEMPLATES_UPDATE_FILTER,
} from 'actions/actionsConstants';
import update from 'immutability-helper';
import _ from 'lodash';
import {
  convertObjectToQuery,
  initialPaginationState,
  initialSortState,
} from '../../services/metaService';

const pagination = initialPaginationState();
const sort = initialSortState({ sort_field: 'integration_partner_templates.name' });

export const initialState = {
  integration_partner_templates: [],
  filter_options: {},
  pagination: { ...pagination, ...sort },
  integration_partners: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case INTEGRATION_PARTNER_TEMPLATES_SET_INT_PARTNERS: {
      return update(state, {
        integration_partners: {
          $set: action.payload,
        },
      });
    }

    case INTEGRATION_PARTNER_TEMPLATES_SET_FUNDS: {
      return update(state, {
        funds: {
          $set: action.payload,
        },
      });
    }

    case INTEGRATION_PARTNER_TEMPLATES_SET_BASE_TEMPLATES: {
      return update(state, {
        integration_partner_base_templates: {
          $set: action.payload,
        },
      });
    }

    case INTEGRATION_PARTNER_TEMPLATES_SET_BASE_TEMPLATE: {
      return update(state, {
        integration_partner_base_template: {
          $set: action.payload,
        },
      });
    }

    case INTEGRATION_PARTNER_TEMPLATES_SET_TEMPLATES: {
      return update(state, {
        integration_partner_templates: {
          $set: action.payload.integration_partner_templates,
        },
        pagination: {
          $set: _.omit(action.payload.meta, 'available_filters'),
        },
        available_filters: {
          $set: action.payload.meta.available_filters,
        },
      });
    }
    case INTEGRATION_PARTNER_TEMPLATES_SET_TEMPLATE: {
      return update(state, {
        integration_partner_template: {
          $set: action.payload,
        },
      });
    }

    case INTEGRATION_PARTNER_TEMPLATES_UPDATE_PAGINATION: {
      const paginationKeys = Object.keys(action.payload);
      const paginationQuery = {};
      _.each(paginationKeys, (key) => {
        paginationQuery[key] = { $set: action.payload[key] };
      });

      return update(state, { pagination: paginationQuery });
    }

    case INTEGRATION_PARTNER_TEMPLATES_UPDATE_FILTER: {
      const query = convertObjectToQuery(action.payload);
      return update(state, query);
    }

    default: {
      return state;
    }
  }
};
