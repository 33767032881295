import {
  FUND_ADMIN_UPLOADER_SET_RESULT_DOCUMENTS,
  FUND_ADMIN_NAV_UPLOADER_SET_RESULT_DOCUMENTS,
  FUND_ADMIN_NAV_UPLOADER_REMOVE_RESULT_DOCUMENT,
  FUND_ADMIN_UPLOADER_REFRESH_PAGE,
  FUND_ADMIN_NAV_UPLOADER_SET_FUNDS,
  FUND_ADMIN_UPLOADER_SET_HF_REPORT,
} from 'actions/actionsConstants';

import update from 'immutability-helper';
import find from 'lodash/find';

const initialState = {
  items: [],
  uploaderResultDocuments: [],
  navResultDocuments: [],
  refresh: 0,
};

export const getSelectedItem = (state, selectedItemId) => find(state.items, { id: selectedItemId });

export default function uploaderReducer(state = initialState, action) {
  switch (action.type) {
    case FUND_ADMIN_UPLOADER_SET_RESULT_DOCUMENTS:
      return update(state, {
        uploaderResultDocuments: { $set: action.payload },
      });
    case FUND_ADMIN_NAV_UPLOADER_SET_RESULT_DOCUMENTS:
      return update(state, {
        navResultDocuments: { $set: action.payload },
      });
    case FUND_ADMIN_NAV_UPLOADER_REMOVE_RESULT_DOCUMENT:
      return update(state, {
        navResultDocuments: { $set: state.navResultDocuments.filter((doc) => doc.id !== action.payload) },
      });
    case FUND_ADMIN_UPLOADER_REFRESH_PAGE:
      return update(state, {
        refresh: { $set: Date.now() },
      });
    case FUND_ADMIN_NAV_UPLOADER_SET_FUNDS:
      return update(state, {
        items: { $set: action.payload },
      });
    case FUND_ADMIN_UPLOADER_SET_HF_REPORT:
      return update(state, {
        hedgeFundAccountingReport: { $set: action.payload },
      });
    default:
      return state;
  }
}
