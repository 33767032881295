import { isEmpty, isUndefined } from 'lodash';
import initialState from './initialState';

const initialPayload = {
  response: {},
  status: 0,
  statusText: '',
};

const expectedHttpStatus = [200, 201, 202, 302];

export const updateState = (state = initialState, payload = initialPayload, actionResult = () => { }) => {
  const { response, status, statusText } = payload;
  const nextState = actionResult(response);
  const serverError = !expectedHttpStatus.includes(status);

  return {
    ...Object.assign({}, state, nextState),
    error: nextState.error || serverError,
    request_total: state.request_total + 1,
    message: serverError ? statusText : (nextState.message || 'No message applied...'),
  };
};

export const updateItems = (items, previousItems = []) => {
  if (!isUndefined(items) && !isEmpty(items)) {
    items.forEach((item) => { previousItems = updateItem(item, previousItems) });
  }

  return previousItems;
};

export const updateItem = (item, items = []) => {
  if (!isUndefined(item) && !isEmpty(item)) {
    const index = items.findIndex((element) => (element.id === item.id));
    items[index] = item;
  }

  return items;
};
