import React from 'react';
import { shape } from 'prop-types';
import { connect } from 'react-redux';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';
import OpportunitiesDisclaimer from 'components/shared/disclaimer/OpportunitiesDisclaimer';
import { BaseDisclaimer } from 'components/shared/disclaimer/BaseDisclaimer';
import { Typography } from '@icapitalnetwork/supernova-core';
import * as lpSelectors from 'modules/landing_page/selectors';

export function HomeDisclaimer({
  configurations,
}) {
  const { customDisclaimer, displayInvestmentOpportunities } = configurations;

  const parsedCustomDisclaimer = customDisclaimer
    ? parse(DOMPurify.sanitize(customDisclaimer))
    : null;

  if (displayInvestmentOpportunities !== 'none') {
    return (
      <BaseDisclaimer dragToBottom>
        <OpportunitiesDisclaimer customDisclaimer={parsedCustomDisclaimer} />
      </BaseDisclaimer>
    );
  }

  return (
    <BaseDisclaimer dragToBottom>
      <Typography variant="smallParagraph" paragraph>
        {parsedCustomDisclaimer}
      </Typography>
    </BaseDisclaimer>
  );
}

function mapStateToProps(state) {
  return {
    configurations: lpSelectors.getConfigurations(state),
  };
}

HomeDisclaimer.propTypes = {
  configurations: shape({}).isRequired,
};

export default connect(mapStateToProps)(HomeDisclaimer);
