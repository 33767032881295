import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  DialogActions,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@icapitalnetwork/supernova-core';
import {
  func, number, shape,
} from 'prop-types';
import FieldConfiguration from 'containers/investor_profile/field_configuration/FieldConfiguration';
import FieldWrap from 'components/shared/icn_form/field_wrap/FieldWrap';
import FirmRoleIfOther from 'containers/registration/complete_profile/sections/FirmRoleIfOther';
import { firmRole } from '../../complete_profile/profileData';
import { calculateCanCompleteStepTwo } from '../helpers/validations';
import ProgressBar from './ProgressBar';
import StyledDialogContent from './StyledDialogContent';

function RiaUserIdentificationPageTwo({
  progress, onNext, saveInformation, information,
}) {
  const { t } = useTranslation(['common']);

  const saveRole = ({ target: { value } }) => {
    if (value !== 'Other') {
      saveInformation({ role_response: '' });
    }
    saveInformation({ role: value });
  };

  const canContinue = calculateCanCompleteStepTwo(information);

  return (
    <>
      <StyledDialogContent>
        <ProgressBar progress={progress} />

        <Typography variant="h5">
          Please choose Your Role
        </Typography>
        <FieldWrap size="full_fluid">
          <FieldConfiguration
            fieldName="role"
          >
            {(config) => (
              <FormControl>
                <RadioGroup
                  row={false}
                  aria-labelledby="role"
                  onChange={saveRole}
                  value={information.role || ''}
                >
                  {firmRole.role_options.map(({ text, value: optionValue }) => (
                    <FormControlLabel
                      key={text}
                      value={optionValue}
                      control={<Radio />}
                      label={text}
                      disabled={config.readOnly}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            )}
          </FieldConfiguration>
        </FieldWrap>

        {information.role === 'Other' && (
          <FirmRoleIfOther updateStore={saveInformation} profileData={information} />
        )}

      </StyledDialogContent>
      <DialogActions>
        <Button autoFocus onClick={onNext} variant="contained" disabled={!canContinue}>
          {t('common:continue', 'Continue')}
        </Button>
      </DialogActions>
    </>
  );
}

RiaUserIdentificationPageTwo.propTypes = {
  progress: number.isRequired,
  onNext: func.isRequired,
  information: shape({}).isRequired,
  saveInformation: func.isRequired,
};

export default RiaUserIdentificationPageTwo;
