import { RG_SET_CONFIG_FILE_ATTRIBUTES } from 'actions/actionsConstants';
import { getRequest, patchRequest } from 'actions/utils';
import actionFactory from 'services/actions';

const BASE_URL = 'report_generator/config_file_attributes';
const getFileAttributesSuccess = actionFactory(RG_SET_CONFIG_FILE_ATTRIBUTES);

const getRgFileAttributes =
  (configId, admin = false) =>
  (dispatch) =>
    dispatch(
      getRequest({
        url: `${BASE_URL}/${configId}`,
        params: {
          admin: admin || undefined,
        },
        onSuccess: getFileAttributesSuccess,
      }),
    );

const updateRgFileAttributes = (id, params) => (dispatch) =>
  dispatch(
    patchRequest({
      url: `${BASE_URL}/${id}`,
      data: { report_config_file_attribute: params },
      onSuccess:  getFileAttributesSuccess
  
    }),
  );

export default { getRgFileAttributes, updateRgFileAttributes };
