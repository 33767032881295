import _ from 'lodash';
import { createSelector } from 'reselect';
import IcnIdentity from 'services/icn_identity/icnIdentity';
import { stripIcnExtension } from 'components/client_management/modals/send_subscription/helpers';

export const noValue = (field) => field === '' || field === undefined || field === null;

export const arrToObj = (arr, defaultValue) => (
  arr.reduce((o, key) => Object.assign(o, { [key]: defaultValue }), {})
);

export const gatherChildKeys = (children) => {
  if (!children) { return []; }

  let childKeys = [];

  children.responses.forEach((childResponse) => {
    if (childResponse.key) {
      childKeys.push(childResponse.key);
    }
    if (childResponse.children) {
      childKeys = [...childKeys, ...gatherChildKeys(childResponse.children)];
    }
  });

  return childKeys;
};

export const hasUsBank = (profile) => profile.wire_instructions_account_with_us_bank === '1';

export const usDefault = (countryField) => (!noValue(countryField) ? countryField : 'United States of America');

export const countriesForSelect = (countries) => (
  countries.map((c) => ({ text: c.name, value: c.name }))
);

export const setEmptyValues = (fieldArr) => arrToObj(fieldArr, '');

export const investingInRetirementPlan = (profile) => profile.investing_in_retirement_plan === '0';

export const isIndividual = (profile) => profile.signer_type === '0';

export const isIndividualDisregardedEntity = (profile) => profile.disregarded_entity_type === '0';

export const isEntity = (profile) => profile.signer_type === '1';

export const isTypeOfAccountToBlock = (profile) => (
  profile.section_a_individual_type &&
  ['IRA', 'SEP IRA', 'Individual'].includes(profile.section_a_individual_type)
);

export const allowUnlimitedSigners = (profile, wlp) => (
  wlp && wlp.sequence_signers_required && isEntity(profile)
);

export const isJointAccount = (profile) => (
  profile.section_a_individual_type &&
  profile.section_a_individual_type.includes('Joint Account')
);

export const isTrustEntity = (profile) => isEntity(profile) && (
  profile.section_a_entity_type === 'Grantor Trust' ||
  profile.section_a_entity_type === 'Non-Grantor Trust'
);

export const signerTypeStr = (profile) => {
  if (isIndividual(profile)) {
    return 'individual';
  } if (isEntity(profile)) {
    return 'entity';
  }
  return undefined;
};

export const entityTypeStr = (profile) => (isIndividual(profile) ? 'individual' : 'entity');

export const phoneNumberFormatting = (profile) => {
  const signerType = entityTypeStr(profile);

  return ['United States of America', 'Canada']
    .includes(profile[`section_a_${signerType}_state_country`]) ?
    'phone' :
    '';
};

export const isFrozen = (profile) => profile.is_frozen;
export const isLive = (profile) => profile.number_of_investments > 0;

export const profileLoaded = (profile) => {
  return profile && Object.keys(profile).filter((k) => k !== 'new_issues_profile').length > 0;
};

export const gatherResponses = (arr) => {
  if (_.isEmpty(arr) || _.isNull(arr)) { return []; }

  const responses = arr.map((item) => ({
    value: item.id,
    text: item.name,
  }));

  return _.orderBy(responses, [(response) => response.text.toLowerCase()], ['asc']);
};

export const withPrefix = (fields, prefix) => fields.map((field) => `${prefix}_${field}`);

export const flattenObjArrCollection = (collection) => {
  // recursively flattens collection containing nested objects and/or arrays

  let result = [];
  _.each(collection, (item) => {
    if (_.isArray(item) || _.isObject(item)) {
      result = [...result, ...flattenObjArrCollection(item)];
    } else if (item) {
      result.push(item);
    }
  });

  return result;
};

const investorProfile = (state) => state.investorProfile.profile;

// We'll need to add the logic to check the Investment Status and User role to this selector
export const readOnlyProfileSelector = createSelector(
  [investorProfile],
  (profile) => profile.is_read_only
);

// White Label Admin tool expects 1 not 0 based index
export const wlpAdminIndex = (i = 0) => i + 1;

// If > 3 signers, use the configuration from 1st signer
export const wlpAdminSignerIndex = (n) => (n > 3 ? 1 : n);

// If > 5 signers, use the configuration from 5th signer
export const wlpAdminInterestedPartyIndex = (n) => (n > 5 ? 5 : n);

export const assetsTotal = (questions, profile) => (
  questions.reduce((total, question) => total + Number(profile[question.key]), 0)
);

export const assetLimit = (total, val) => 100 - total + val;

export const hasSourceOfFunds = (profile) => {
  const suffixes = ['inheritance', 'other', 'retirement_payment',
    'salary', 'savings', 'windfall_payment'];

  return suffixes.some((suffix) => profile[`source_of_funds_${suffix}`] === '1');
};

export const getSourceOfFundsSelector = createSelector(
  [hasSourceOfFunds],
  (selectedSourceOfFunds) => selectedSourceOfFunds
);

export const inProgress = (status = '') => (
  status.toLowerCase() === 'in progress'
);

export const showGlow = (status = '', incomplete = false) => (
  inProgress(status) && incomplete
);


const getCompleteness = (state) => state.investorProfile.completeness;

export const completenessSelector = createSelector(
  [getCompleteness],
  (completeness) => completeness
);

export const singleFieldVisibility = (fieldName, wlpFieldConfig = {}) => ({
  fieldName,
  readOnlyField: wlpFieldConfig[fieldName] === 'read_only',
  hiddenField: wlpFieldConfig[fieldName] === 'hidden',
});

export const isHiddenField = (field = {}) => {
  if (_.isEmpty(field)) {
    return false;
  }
  return field.hiddenField;
};

export const allIncomplete = (fields = [], completeness = {}) => (
  (
    Array.isArray(fields) ?
      fields :
      [fields]
  ).every(
    (f) => completeness[f] === 'incomplete'
  )
);

export const allFieldsHidden = (fieldVisibility = []) => {
  if (_.isEmpty(fieldVisibility)) {
    return false;
  }

  return fieldVisibility.every(isHiddenField);
};
export const isReadOnlyField = (fieldVisibility = []) => (
  fieldVisibility.length > 0 ?
    fieldVisibility.length === 1 && fieldVisibility[0].readOnlyField :
    false
);

export const mapFieldVisibility = (fields = [], profileFieldVisibility = {}, profile) => {
  const fieldsArray = Array.isArray(fields) ? fields : [fields];

  return fieldsArray.reduce((array, field) => {
    // handle dynamic field
    if (typeof field === 'function') {
      const dynamicField = field(profile);
      const fieldsVisibility = (Array.isArray(dynamicField) ? dynamicField : [dynamicField])
        .map((f) => singleFieldVisibility(f, profileFieldVisibility));

      return [...array, ...fieldsVisibility];
    }
    return [...array, singleFieldVisibility(field, profileFieldVisibility)];
  }, []);
};

export const cftcType = (n) => `section_j_cftc_registration_type_${n}`;
export const cftcNotRequired = (n) => `section_j_cftc_registration_not_required_reason_${n}`;
export const clearNotRequiredReasons = () => {
  const obj = {};
  [1, 2, 3].map((n) => obj[cftcNotRequired(n)] = '');
  return obj;
};

export const clearRegistrationTypes = () => {
  const obj = {};
  [1, 2, 3, 4, 5].map((n) => obj[cftcType(n)] = '');
  return obj;
};

export const hasTruthyValues = (fields = [], obj = {}) => (
  fields.find((f) => Boolean(obj[f]))
);

/* API will mark these fields as 'incomplete' until their cumulative value is 100.
   Check to see if any have a truthy value, and if so,
   treat the page as "In Progress", which will trigger glow/completeness indicators */
export const assetSlidersHaveValues = (profile = {}, fields = []) => {
  const sliderFields = [
    'section_d_percent_equities',
    'section_d_percent_fixed',
    'section_d_percent_options',
    'section_d_percent_real_estate',
    'section_d_percent_private_equity',
    'section_d_percent_other',
  ];

  // return if our fields not in the list
  if (!fields.includes(sliderFields[0])) {
    return false;
  }

  return hasTruthyValues(sliderFields, profile);
};

export const isHiddenPage = (page, profileType, profileFieldVisibility = {}, profile = {}) => {
  // get only the fields for current profile type
  const fieldsForProfileType = page.fieldsForPageVisibility(profileType);
  const fieldVisibility = mapFieldVisibility(
    fieldsForProfileType,
    profileFieldVisibility,
    profile
  );
  return allFieldsHidden(fieldVisibility);
};

export const mapAngularQueryParams = (params = {}) => {
  const without = _.omit(
    params,
    ['returnUrl', 'contact_card_id', 'private_access_fund_id']
  );

  const result = Object.assign({}, without, {
    contactCardId: params.contact_card_id,
    pafId: params.private_access_fund_id,
  });

  return _.omitBy(result, _.isNil);
};

export const extractDelimitedValues = (str = '', delimiter = '') => {
  const pattern = new RegExp(`\(\[\^${delimiter}\]\+\)`, 'g');
  const result = str.match(pattern);
  return Array.isArray(result) ? result : [];
};

export const hasNonEmailError = (error = '') => {
  if (!_.isString(error)) {
    return null;
  }

  return !error.match(/email/i);
};

// search for non-email errors
export const pafAdminErrorRules = (errors) => errors.find(hasNonEmailError);

export const configurationForField = (state, fieldName, completenessFieldName) => {
  const completeness = completenessSelector(state);
  const profileFieldVisibility = state.investorProfile.visibility;
  const fieldVisibility = mapFieldVisibility(
    fieldName,
    profileFieldVisibility,
    state.investorProfile.profile
  );

  return {
    fieldName,
    readOnlyField: isReadOnlyField(fieldVisibility),
    hiddenField: allFieldsHidden(fieldVisibility),
    readOnlyProfile: readOnlyProfileSelector(state),
    incomplete: allIncomplete(completenessFieldName || fieldName, completeness),
  };
};

export const isWritableField = (state, fieldName) => {
  const config = configurationForField(state, fieldName);
  const writable = !config.readOnlyField && !config.hiddenField && !config.readOnlyProfile;
  return writable;
};

export const isIncome = (profile, income) => (
  profile.section_e_income_1 === income || profile.section_e_income_2 === income
);

export const needsInvestorCertification = (profile, visibility, isMainSitePafAdmin) => {
  if (!isIndividual(profile)) return false;

  const incomeFields = [
    'section_e_income_2',
    'section_e_income_1',
    'section_e_marital',
  ];
  const incomeFieldVisibility = mapFieldVisibility(incomeFields, visibility, profile);

  const withLowNetWorth = profile.section_e_net_worth === 'Less than $500,000' ||
    profile.section_e_net_worth === '$500,000 - $999,999';

  // In case the income fields are all hidden, we only take into consideration the low networth
  if (allFieldsHidden(incomeFieldVisibility) || isMainSitePafAdmin) return withLowNetWorth;

  const withIndividualIncome = profile.section_e_marital === '0';
  const withSpouseIncome = profile.section_e_marital === '1';

  const inIncomeBracket1 = isIncome(profile, 'Less than $200,000');
  const inIncomeBracket2 = isIncome(profile, '$200,000 - $299,999');

  return withLowNetWorth && (
    (withIndividualIncome && inIncomeBracket1) ||
    (withSpouseIncome && (inIncomeBracket1 || inIncomeBracket2))
  );
};

export const showEmailHideIcnSuffix = (readOnly, email, whiteLabelPartner) => {
  if (!readOnly) {
    return email;
  }

  return IcnIdentity.hideIcnSuffixFromProfile(whiteLabelPartner) ? stripIcnExtension(email) : email;
};

export const retrieveInputValue = (key, readOnly, data, whiteLabelPartner) => (
  key === 'email' ?
    showEmailHideIcnSuffix(readOnly, data[key], whiteLabelPartner) :
    data[key]
);
