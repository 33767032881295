import {
  DD_LOAD_WHITE_LABEL_PARTNERS,
  DD_LOAD_PROVIDERS,
  DD_LOAD_DATA_SYNCS,
  DD_LOAD_DATA_SYNC,
  DD_CANCEL_DATA_SYNC,
  DD_LOAD_DATA_SYNC_FILES,
  DD_LOAD_DATA_SYNC_FILE_REPORT,
  DD_EXECUTE_DATA_SYNC,
  DD_EXECUTE_TRANSFORMATION,
  DD_LOAD_DATA_SYNC_SCHEMA,
  DD_LOAD_DATA_SYNC_IDENTIFIERS,
  DD_LOAD_DATA_SYNC_STATUSES,
  DD_LOAD_DATA_SYNC_USERS,
  DD_LOAD_DATA_SYNC_TIME_RANGES,
  DD_LOAD_STORAGED_FILES,
  DD_INITIATE_UPLOAD_FILE,
  DD_DELETE_STORAGED_FILE,
} from 'actions/actionsConstants';

import { actionFactory } from 'services/actions';

export const ddLoadWhiteLabelPartners = actionFactory(DD_LOAD_WHITE_LABEL_PARTNERS);

export const ddLoadProviders = actionFactory(DD_LOAD_PROVIDERS);

export const ddLoadDataSyncs = actionFactory(DD_LOAD_DATA_SYNCS);

export const ddLoadDataSync = actionFactory(DD_LOAD_DATA_SYNC);

export const ddCancelDataSync = actionFactory(DD_CANCEL_DATA_SYNC);

export const ddLoadDataSyncFiles = actionFactory(DD_LOAD_DATA_SYNC_FILES);

export const ddLoadDataSyncFileReport = actionFactory(DD_LOAD_DATA_SYNC_FILE_REPORT);

export const ddRunDataSync = actionFactory(DD_EXECUTE_DATA_SYNC);

export const ddRunTransformation = actionFactory(DD_EXECUTE_TRANSFORMATION);

export const ddLoadDataSyncSchema = actionFactory(DD_LOAD_DATA_SYNC_SCHEMA);

export const ddLoadDataSyncIdentifiers = actionFactory(DD_LOAD_DATA_SYNC_IDENTIFIERS);

export const ddLoadDataSyncStatuses = actionFactory(DD_LOAD_DATA_SYNC_STATUSES);

export const ddLoadDataSyncUsers = actionFactory(DD_LOAD_DATA_SYNC_USERS);

export const ddLoadDataSyncTimeRanges = actionFactory(DD_LOAD_DATA_SYNC_TIME_RANGES);

export const ddLoadStoragedFiles = actionFactory(DD_LOAD_STORAGED_FILES);

export const ddInitiateUploadFile = actionFactory(DD_INITIATE_UPLOAD_FILE);

export const ddDeleteStoragedFile = actionFactory(DD_DELETE_STORAGED_FILE);
