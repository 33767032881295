export default {
  showObjectives: true,
  showAccountTypes: true,
  showLiquidity: true,
  showJurisdiction: true,
  showFlashReports: false,
  selectedReportPafId: null,
  isInvestorProfileCompleted: false,
  currentUserHasProfile: false,
  investorProfileId: '',
  opportunities: {
    collections: [],
    collectionsAsTilesList: [],
    collectionsOfSolutions: [],
  },
};
