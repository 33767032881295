import i18n from 'i18n.js';
import configuration from 'configuration';

const GP_ADMIN_INVITE_LIMIT = parseInt(configuration.GP_ADMIN_INVITE_LIMIT, 10);

export const GATEWAY_TIMEOUT_ERROR = 'GATEWAY_TIMEOUT_ERROR';

export const WARNING_MESSAGE = i18n.t('sales_dashboard:messages.warning_message', 'Before you send invitations please confirm the clients below');
export const ERROR_MESSAGE = i18n.t('sales_dashboard:messages.error_message', 'The following invitations are in error and will not be sent');
export const RE_INVITATION_GRID_TITLE = i18n.t('sales_dashboard:titles.re_invitation_grid_title', 'Clients Previously Invited');
export const CLIENTS_REMINDER_GRID_TITLE = i18n.t('sales_dashboard:titles.clients_reminder_grid_title', 'Clients Already Registered');
export const REPRESENTATIVE_CONFIRMATION_GRID_TITLE = i18n.t('sales_dashboard:titles.representative_confirmation_grid_title', 'Clients are Investor Representatives');
export const CLIENTS_NOT_REPRESENTATIVE_GRID_TITLE = i18n.t('sales_dashboard:titles.clients_not_representative_grid_title', 'Clients are not Investor Representatives');
export const INVALID_SALES_PERSON_GRID_TITLE = i18n.t('sales_dashboard:titles.invalid_sales_person_grid_title', 'Clients with Invalid Salesperson');
export const FAILED_CLIENTS_REMINDER_GRID_TITLE = i18n.t('sales_dashboard:titles.failed_clients_reminder_grid_title', 'Registration Reminders Failed');
export const CLIENT_REMINDER_NOT_FOUND_TITLE = i18n.t('sales_dashboard:titles.client_reminder_not_found_title', 'Registration Reminders Failed - Clients not found');
export const CLIENT_EMAIL_INVALID_TITLE = i18n.t('sales_dashboard:titles.client_email_invalid_title', 'Clients with Invalid Email');
export const SALESPERSON_EMAIL_INVALID_TITLE = i18n.t('sales_dashboard:titles.salesperson_email_invalid_title', 'Clients with Salesperson Invalid Email');
export const CLIENT_TYPE_INVALID_TITLE = i18n.t('sales_dashboard:titles.client_type_invalid_title', 'Clients with Invalid Client Type');
export const INVALID_PAF_IDS_TITLE = i18n.t('sales_dashboard:titles.invalid_paf_ids_title', 'Clients with Invalid Private Access Funds');
export const INVALID_FUND_IDS_TITLE = i18n.t('sales_dashboard:titles.invalid_fund_ids_title', 'Clients with Invalid Funds');

export const GATEWAY_TIMEOUT_MESSAGE = i18n.t('sales_dashboard:messages.gateway_timeout_message', 'An error occurred while processing the request. Please try again.');
export const RE_INVITATION_GRID_MESSAGE = i18n.t('sales_dashboard:messages.re_invitation_grid_message', 'The client(s) below have been previously invited. Upon clicking Send, another invitation will be sent.');
export const CLIENTS_REMINDER_GRID_MESSAGE = i18n.t('sales_dashboard:messages.clients_reminder_grid_message', 'The client(s) below are already registered. Upon clicking Send, a reminder regarding their account will be sent.');
export const REPRESENTATIVE_CONFIRMATION_GRID_MESSAGE = i18n.t('sales_dashboard:messages.representative_confirmation_grid_message', 'The client(s) below can currently request authorization to act as a Representative on behalf of investors. By clicking Send below, you are indicating that this user would like to invest themselves. An email with a link to the Investor Questionnaire will be sent to the investor to fill out on behalf of themselves.');
export const CLIENTS_NOT_REPRESENTATIVE_GRID_MESSAGE = i18n.t('sales_dashboard:messages.clients_not_representative_grid_message', 'The client(s) below are not Representatives. You will need to reconcile this before an invitation can be sent.');
export const INVALID_SALES_PERSON_GRID_MESSAGE = i18n.t('sales_dashboard:messages.invalid_sales_person_grid_message', 'The client(s) below are not valid salespeople. You will need to reconcile this before an invitation can be sent.');
export const FAILED_CLIENTS_REMINDER_GRID_MESSAGE = i18n.t('sales_dashboard:messages.failed_clients_reminder_grid_message', 'The client(s) below failed the reminder attempt. Please try again later or contact the support team.');
export const CLIENT_REMINDER_NOT_FOUND_MESSAGE = i18n.t('sales_dashboard:messages.client_reminder_not_found_message', 'The client(s) below failed the reminder attempt because they were not found.');
export const CLIENT_EMAIL_INVALID_MESSAGE = i18n.t('sales_dashboard:messages.client_email_invalid_message', 'The client(s) below failed because they have an invalid email. You will need to reconcile this before an invitation can be sent.');
export const CLIENT_TYPE_INVALID_MESSAGE = i18n.t('sales_dashboard:messages.client_type_invalid_message', 'The client(s) below failed because they have an invalid client type. You will need to reconcile this before an invitation can be sent.');
export const SALESPERSON_EMAIL_INVALID_MESSAGE = i18n.t('sales_dashboard:messages.salesperson_email_invalid_message', 'The client(s) below failed because they have an invalid salesperson email. You will need to reconcile this before an invitation can be sent.');
export const INVALID_PAF_IDS_MESSAGE = i18n.t('sales_dashboard:messages.invalid_paf_ids_message', 'The client(s) below failed because they have invalid Private Access Funds IDs. You will need to reconcile this before an invitation can be sent.');
export const INVALID_FUND_IDS_MESSAGE = i18n.t('sales_dashboard:messages.invalid_fund_ids_message', 'The client(s) below failed because they have invalid Funds IDs. You will need to reconcile this before an invitation can be sent.');
export const WARNING_MAIN_SITE_INVITE_LIMIT = i18n.t('sales_dashboard:messages.warning_main_site_invite_limit','Bulk Invitations are limited to {{invite_limit}} rows. To create the best experience for Marketplace users and to comply with prohibitions against general solicitation which may apply, please only invite clients with whom you have already established a relationship.', { invite_limit: GP_ADMIN_INVITE_LIMIT });
export const WARNING_INVITE_LIMIT = i18n.t('sales_dashboard:messages.warning_invite_limit', 'Bulk Invitations are limited to {{invite_limit}} rows. To create the best experience for users and to comply with prohibitions against general solicitation which may apply, please only invite clients with whom you have already established a relationship.', { invite_limit: GP_ADMIN_INVITE_LIMIT });
export const ERROR_MESSAGE_GP_ADMIN_INVITE_LIMIT = i18n.t('sales_dashboard:messages.error_message_gp_admin_invite_limit', 'Bulk Invitations are limited to {{invite_limit}} rows.', { invite_limit: GP_ADMIN_INVITE_LIMIT});
