import _ from 'lodash';
import styles from '../../containers/integration_partners_dashboard/table/IPTable.module.scss';

import MultiItemRenderer from '../../components/shared/cell_renderers/MultiItemRenderer/MultiItemRenderer';
import DeleteEditExecuteHistoryButtonCellRenderer
  from '../../components/shared/cell_renderers/DeleteEditExecuteHistoryButtonCellRenderer/DeleteEditExecuteHistoryButtonCellRenderer';
import { createFilterOption } from '../dropdown_filter';
import investmentStatuses from '../../constants/investmentStatuses';

const getState = (state) => state.integrationPartnerTemplates;

export const integrationPartnersSelector = (state) => getState(state).integration_partners;
export const integrationPartnerBaseTemplatesSelector = (state) => getState(state).integration_partner_base_templates;
export const integrationPartnerBaseTemplateSelector = (state) => getState(state).integration_partner_base_template;
export const integrationPartnerTemplatesSelector = (state) => getState(state).integration_partner_templates;
export const integrationPartnerTemplateSelector = (state) => getState(state).integration_partner_template;
export const integrationPartnerTemplatesPaginationSelector = (state) => getState(state).pagination;
export const integrationPartnerTemplatesAvailableFilterSelector = (state) => {
  return getState(state).available_filters || {};
};
export const integrationPartnerTemplateFundsSelector = (state) => getState(state).funds;

export const sharedGridProps = {
  rowClassRules: {
    [styles.even_row]: (entry) => entry.node.rowIndex % 2 === 0,
    [styles.odd_row]: (entry) => entry.node.rowIndex % 2 !== 0,
  },
  defaultColDef: {
    suppressMovable: true,
    suppressMenu: true,
    headerClass: styles.grid_header,
    cellClass: styles.grid_cell,
  },
  suppressContextMenu: true,
  frameworkComponents: { DeleteEditExecuteHistoryButtonCellRenderer, MultiItemRenderer },
  headerHeight: 50,
  rowHeight: 40,
  domLayout: 'autoHeight',
};

export const integrationPartnerTemplatesSelectedFundsFilterSelector = (state) => getState(state).funds || [];

export const integrationPartnerTemplatesStatusesFilterSelector = (state) => {
  const statuses = integrationPartnerTemplatesAvailableFilterSelector(state).statuses || [];

  return _.map(statuses,
    (item) => createFilterOption(investmentStatuses[item], 'status', item));
};

export const integrationPartnerTemplatesSelectedStatusesFilterSelector = (state) => getState(state).selectedStatuses || [];

export const integrationPartnerTemplatesWlpsFilterSelector = (state) => {
  const wlps = integrationPartnerTemplatesAvailableFilterSelector(state).wlps || [];

  return _.map(wlps,
    (item) => createFilterOption(item.name, 'wlp_ids', item.id));
};

export const integrationPartnerTemplatesSelectedWlpsFilterSelector = (state) => getState(state).selectedWlps || [];

export const integrationPartnerTemplatesIntegrationPartnerFilterSelector = (state) => {
  const integrationPartners = integrationPartnerTemplatesAvailableFilterSelector(state).integration_partners || [];

  return _.map(integrationPartners,
    (item) => createFilterOption(item.name, 'integration_partner_ids', item.id));
};

export const integrationPartnerTemplatesSelectedIntegrationPartnerSelector = (state) => {
  return getState(state).selectedIntegrationPartners || [];
};

export const integrationPartnerTemplatesBaseTemplateFilterSelector = (state) => {
  const baseTemplates = integrationPartnerTemplatesAvailableFilterSelector(state).base_templates || [];

  return _.map(baseTemplates,
    (item) => createFilterOption(item.name, 'base_template_names', item.name));
};

export const integrationPartnerTemplatesSelectedBaseTemplateSelector = (state) => {
  return getState(state).selectedBaseTemplates || [];
};

export const getAllSelectedOptions = (state) => {
  const selectedOptions = [];
  selectedOptions.push(...integrationPartnerTemplatesSelectedStatusesFilterSelector(state));
  selectedOptions.push(...integrationPartnerTemplatesSelectedWlpsFilterSelector(state));
  selectedOptions.push(...integrationPartnerTemplatesSelectedIntegrationPartnerSelector(state));
  selectedOptions.push(...integrationPartnerTemplatesSelectedBaseTemplateSelector(state));
  selectedOptions.push(...integrationPartnerTemplatesSelectedFundsFilterSelector(state));
  return selectedOptions;
};
