const getState = (state) => state.investorRepresentative;

export const irInvestors = (state) => getState(state).investors;
export const irInvestor = (state) => getState(state).investor;
export const irManagers = (state) => getState(state).managers;
export const irAddInvestor = (state) => getState(state).newInvestor;
export const irQuestionnare = (state) => getState(state).questionnaire;
export const irSuccessToastMessage = (state) => getState(state).successToastMessage;
export const irErrorToastMessage = (state) => getState(state).errorToastMessage;
export const irIsFetching = (state) => getState(state).isFetching;
