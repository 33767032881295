import {
  AS_LOAD_FILTER_CONTENTS_SUCCESS,
  AS_SET_SELECTED_WLPS,
  AS_SET_SELECTED_FUNDS,
  AS_SELECTED_RIAS_FIRMS,
  AS_SELECTED_REP_TYPE,
  AS_SET_RESULTS_PER_PAGE,
  AS_SET_CURRENT_PAGE,
  AS_SET_SORTING,
} from 'actions/actionsConstants';

import update from 'immutability-helper';

import { normalizeCurrentPage } from '../utils';
import initialState from '../initialState';

export default (state = initialState, action) => {
  let newState = null;

  switch (action.type) {
    case AS_LOAD_FILTER_CONTENTS_SUCCESS:
      return update(state, {
        funds_list: { $set: action.payload.funds },
        firms_list: { $set: action.payload.firms },
        wlps_list: { $set: action.payload.white_label_partners },
      });
    case AS_SET_SELECTED_WLPS:
      return update(state, {
        selected_wlps: { $set: action.payload },
        current_page: { $set: normalizeCurrentPage(1, state) },
      });
    case AS_SET_SELECTED_FUNDS:
      return update(state, {
        selected_funds: { $set: action.payload },
        current_page: { $set: normalizeCurrentPage(1, state) },
      });
    case AS_SELECTED_RIAS_FIRMS:
      return update(state, {
        selected_rias_firms: { $set: action.payload },
        current_page: { $set: normalizeCurrentPage(1, state) },
      });
    case AS_SELECTED_REP_TYPE:
      return update(state, {
        selected_representation_type: { $set: action.payload },
        current_page: { $set: normalizeCurrentPage(1, state) },
      });
    case AS_SET_RESULTS_PER_PAGE:
      newState = update(state, {
        results_per_page: { $set: action.payload },
        current_page: { $set: initialState.current_page },
      });
      return newState;
    case AS_SET_CURRENT_PAGE:
      newState = update(state, {
        current_page: { $set: normalizeCurrentPage(action.payload, state) },
      });
      return newState;
    case AS_SET_SORTING:
      newState = update(state, {
        sorting_column: { $set: action.payload.sortingColumn },
        sorting_order: { $set: action.payload.sortingOrder },
      });
      return newState;
    default:
      return state;
  }
};
