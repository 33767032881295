import activityLogReducer from './activityLogReducer';
import userActivityLogReducer from './userActivityLogReducer';
import activityLogGPActivitiesReducer from './activities/activityLogGPActivitiesReducer';
import activityLogGPUserActivitiesReducer from './activities/activityLogGPUserActivitiesReducer';
import activityLogGPFundFollowingsReducer from './fund_followings/activityLogGPFundFollowingsReducer';
import activityLogGPProspectsReducer from './prospects/activityLogGPProspectsReducer';
import activityLogAdvisorActivitiesReducer from './activities/activityLogAdvisorActivitiesReducer';
import engagementScoreReducer from '../engagement_score/reducers/engagementScoreReducer';
import salesCoverageReducer from '../sales_coverage/reducers/salesCoverageReducer';
import exportHistoryReducer from './exportHistoryReducer';
import initialState from './initialState';

const fn = [
  activityLogReducer,
  userActivityLogReducer,
  activityLogGPActivitiesReducer,
  activityLogGPUserActivitiesReducer,
  activityLogGPFundFollowingsReducer,
  activityLogGPProspectsReducer,
  activityLogAdvisorActivitiesReducer,
  salesCoverageReducer,
  engagementScoreReducer,
  exportHistoryReducer,
];

export const reducer = (state = initialState, action) => (
  fn.reduce((stateAccumulator, fnc) => fnc(stateAccumulator, action), state)
);

export default reducer;
