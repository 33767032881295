import update from 'immutability-helper';
import API_DASHBOARD_ACTIONS from '../../actionsConstants';
import { initialDashboardState } from '../../reducer';

const dltEventDashboardReducer = (
  state = initialDashboardState,
  { type, payload },
) => {
  switch (type) {
    case API_DASHBOARD_ACTIONS.SET_SELECTED_DLT_EVENT:
      return update(state, {
        selectedDltEvent: { $set: payload },
      });
    case API_DASHBOARD_ACTIONS.SET_DLT_EVENT_CURRENT_VIEW_FILTERS:
      return update(state, {
        currentViewFilters: { $set: payload },
      });
    case API_DASHBOARD_ACTIONS.SET_DLT_EVENT_DEFAULT_VIEW_FILTERS:
      return update(state, {
        defaultViewFilters: { $set: payload },
      });
    case API_DASHBOARD_ACTIONS.SET_DLT_EVENT_CURRENT_PAGE:
      return update(state, {
        currentPage: { $set: payload },
      });
    case API_DASHBOARD_ACTIONS.SET_DLT_EVENT_CURRENT_PAGE_ROWS:
      return update(state, {
        currentPageRows: { $set: payload },
      });
    default:
      return state;
  }
};

export default dltEventDashboardReducer;
