import { actionFactory } from 'services/actions';
import {
  getRequest,
} from 'actions/utils';

import {
  FIRM_GET_FIRMS_FOR_REGISTRATION,
  FIRM_HAS_KNOWN_DOMAIN,
} from 'actions/actionsConstants';

const rFirmsForRegistration = actionFactory(FIRM_GET_FIRMS_FOR_REGISTRATION);
const rFirmsHasKnownDomain = actionFactory(FIRM_HAS_KNOWN_DOMAIN);

const firmsUrl = 'firms';

function hasKnownDomain(email) {
  const params = { email };
  return getRequest({
    url: `${firmsUrl}/has_known_domain`,
    params,
    onSuccess: (response) => rFirmsHasKnownDomain(response),
  });
}

function getFirmsForRegistration(email, fromDomain, query) {
  const params = { email, from_domain: fromDomain, query };
  return getRequest({
    url: `${firmsUrl}/firms_for_registration`,
    params,
    onSuccess: (response) => rFirmsForRegistration(response),
  });
}

export default {
  getFirmsForRegistration,
  hasKnownDomain,
};
