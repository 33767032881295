import {
  IO_SET_FUNDS,
  IO_SET_CURRENT_PAGE,
  IO_SET_PAGE_ACTIONS,
  IO_SET_REQUEST_MADE,
  IO_SET_LOADING_FIRST_PAGE,
  IO_COMPONENT_UNMOUNT,
  IO_SET_ROWS_PER_PAGE,
} from 'modules/investment_opportunity/actions/constants';

import update from 'immutability-helper';

import initialState from './initialState';

export default (state = initialState, action) => {
  switch (action.type) {
    case IO_SET_FUNDS: {
      const pageCount = Math.floor((action.payload.io_total_count - 1) / action.payload.results_per_page) + 1;

      return update(state, {
        funds: { $set: action.payload.funds },
        page: { $set: action.payload.page },
        flash_reports: { $set: action.payload.flash_reports || [] },
        filterOptions: { $set: action.payload.wlp_filter_options },
        filterValues: { $set: action.payload.filters },
        sortOptions: { $set: action.payload.wlp_sort_options },
        currentPage: { $set: action.payload.current_page },
        resultsPerPage: { $set: action.payload.results_per_page },
        totalCount: { $set: action.payload.io_total_count },
        infoMessage: { $set: action.payload.info_message },
        filterSelectedValues: { $set: action.payload.filters_selected_values },
        isLoading: { $set: false },
        requestMade: { $set: false },
        isLoadingFirstPage: { $set: false },
        isLastPage: { $set: pageCount <= action.payload.current_page },
      });
    }
    case IO_SET_CURRENT_PAGE: {
      return update(state, { currentPage: { $set: action.payload } });
    }
    case IO_SET_ROWS_PER_PAGE: {
      return update(state, { resultsPerPage: { $set: action.payload } });
    }
    case IO_SET_PAGE_ACTIONS: {
      return update(state, {
        pageActions: { $set: action.payload },
      });
    }
    case IO_SET_REQUEST_MADE: {
      return update(state, { requestMade: { $set: action.payload } });
    }
    case IO_SET_LOADING_FIRST_PAGE: {
      return update(state, { isLoadingFirstPage: { $set: action.payload } });
    }
    case IO_COMPONENT_UNMOUNT:
      return initialState;
    default:
      return state;
  }
};
