/* eslint-disable max-statements */
/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
import update from 'immutability-helper';

import {
  CAPITAL_EVENTS_SET_PRIVATE_ACCESS_FUNDS,
  CAPITAL_EVENTS_SET_GRID_FETCHING,
  CAPITAL_EVENTS_UNSET_GRID_FETCHING,
  CAPITAL_EVENTS_SET_MODAL_FETCHING,
  CAPITAL_EVENTS_UNSET_MODAL_FETCHING,
  CAPITAL_EVENTS_OPEN_NOTICE_EDITOR,
  CAPITAL_EVENTS_CLOSE_NOTICE_EDITOR,
  CAPITAL_EVENTS_UPDATE_GROUPS,
  CAPITAL_EVENTS_OPEN_CREATE_FORM,
  CAPITAL_EVENTS_CLOSE_CREATE_FORM,
  CAPITAL_EVENTS_OPEN_EDIT_FORM,
  CAPITAL_EVENTS_CLOSE_EDIT_FORM,
  CAPITAL_EVENTS_OPEN_EXPENSES_MODAL,
  CAPITAL_EVENTS_CLOSE_EXPENSES_MODAL,
  CAPITAL_EVENTS_OPEN_REMINDER_MODAL,
  CAPITAL_EVENTS_CLOSE_REMINDER_MODAL,
  CAPITAL_EVENTS_OPEN_STATUS_REPORT_MODAL,
  CAPITAL_EVENTS_CLOSE_STATUS_REPORT_MODAL,
  CAPITAL_EVENTS_OPEN_CUSTODIAN_NOTICES,
  CAPITAL_EVENTS_CLOSE_CUSTODIAN_NOTICES,
  CAPITAL_EVENTS_SET_CUSTODIAN_NOTICES,
  CAPITAL_EVENTS_OPEN_NOMINEE_NOTICES,
  CAPITAL_EVENTS_CLOSE_NOMINEE_NOTICES,
  CAPITAL_EVENTS_SET_NOMINEE_NOTICES,
  CAPITAL_EVENTS_OPEN_EXECUTE_MODAL,
  CAPITAL_EVENTS_CLOSE_EXECUTE_MODAL,
  CAPITAL_EVENTS_OPEN_FIRM_REPORTS,
  CAPITAL_EVENTS_CLOSE_FIRM_REPORTS,
  CAPITAL_EVENTS_SET_FIRM_REPORTS,
  CAPITAL_EVENTS_OPEN_DISTRIBUTION_REPORTS_MODAL,
  CAPITAL_EVENTS_CLOSE_DISTRIBUTION_REPORTS_MODAL,
  CAPITAL_EVENTS_SET_DISTRIBUTION_REPORTS,
  CAPITAL_EVENTS_OPEN_DELETE_MODAL,
  CAPITAL_EVENTS_CLOSE_DELETE_MODAL,
  CAPITAL_EVENTS_SET_RECONCILIATION_EXPORTS,
  CAPITAL_EVENTS_OPEN_RECONCILIATION_MODAL,
  CAPITAL_EVENTS_CLOSE_RECONCILIATION_MODAL,
  CAPITAL_EVENTS_SET_STATUS_REPORTS,
  CAPITAL_EVENTS_SET_FILTER_DATE_LIST,
  CAPITAL_EVENTS_SET_SELECTED_FILTER_DATE,
  CAPITAL_EVENTS_OPEN_REJECT_MODAL,
  CAPITAL_EVENTS_CLOSE_REJECT_MODAL,
  CAPITAL_EVENTS_OPEN_UPLOAD_FORM,
  CAPITAL_EVENTS_CLOSE_UPLOAD_FORM,
  CAPITAL_EVENTS_OPEN_BULK_PREVIEW_NOTICES,
  CAPITAL_EVENTS_CLOSE_BULK_PREVIEW_NOTICES,
  CAPITAL_EVENTS_SET_BULK_PREVIEW_NOTICES,
  CAPITAL_EVENTS_OPEN_RESET_MODAL,
  CAPITAL_EVENTS_CLOSE_RESET_MODAL,
  CAPITAL_EVENTS_OPEN_RESEND_FAILED_NOTICE_MODAL,
  CAPITAL_EVENTS_CLOSE_RESEND_FAILED_NOTICE_MODAL,
  CAPITAL_EVENTS_SET_FINANCE_OWNER_OPTIONS,
  CAPITAL_EVENTS_SET_UNDERLYING_FUNDS,
  CAPITAL_EVENTS_SET_UNDERLYING_CAPITAL_EVENTS,
  CAPITAL_EVENTS_OPEN_RESEND_EVENT_NOTICE_MODAL,
  CAPITAL_EVENTS_CLOSE_RESEND_EVENT_NOTICE_MODAL,
  CAPITAL_EVENTS_OPEN_PAST_DUE_MODAL,
  CAPITAL_EVENTS_CLOSE_PAST_DUE_MODAL,
  CAPITAL_EVENTS_OPEN_UPLOAD_UPDATE_MODAL,
  CAPITAL_EVENTS_CLOSE_UPLOAD_UPDATE_MODAL,
  CAPITAL_EVENTS_OPEN_UPLOAD_RECONCILIATION_MODAL,
  CAPITAL_EVENTS_CLOSE_UPLOAD_RECONCILIATION_MODAL,
  CAPITAL_EVENTS_OPEN_EDIT_FROM_FILE_FORM,
  CAPITAL_EVENTS_CLOSE_EDIT_FROM_FILE_FORM,
  CAPITAL_EVENTS_SET_SUMMARY,
  CAPITAL_EVENTS_CLEAR_SUMMARIES,
  CAPITAL_EVENTS_SET_FILTER_WHITE_LABEL_PARTNERS_LIST,
  CAPITAL_EVENTS_SET_SELECTED_FILTER_WHITE_LABEL_PARTNER,
  CAPITAL_EVENTS_SET_FILTER_OPTIONS,
  CAPITAL_EVENTS_SET_SELECTED_FILTER_TEXT,
  CAPITAL_EVENTS_SET_SELECTED_FILTER_OPTION,
  CAPITAL_EVENTS_SET_SELECTED_GROUP_BY_CLOSE,
  CAPITAL_EVENTS_SET_SELECTED_FILTER_UNRECONCILED,
  CAPITAL_EVENTS_OPEN_UF_MODAL,
  CAPITAL_EVENTS_CLOSE_UF_MODAL,
  CAPITAL_EVENTS_OPEN_NOTES_MODAL,
  CAPITAL_EVENTS_CLOSE_NOTES_MODAL,
  CAPITAL_EVENTS_SET_NOTES,
  CAPITAL_EVENTS_OPEN_SUBMIT_APPROVAL_MODAL,
  CAPITAL_EVENTS_CLOSE_SUBMIT_APPROVAL_MODAL,
  CAPITAL_EVENTS_SET_MANAGEMENT_FEES,
  CAPITAL_EVENTS_SET_PAF_CHARGED_FEE_QUARTERS,
  CAPITAL_EVENTS_SET_MENU_OPTIONS,
  CAPITAL_EVENTS_SET_EXECUTE_INFO,
  CAPITAL_EVENTS_OPEN_UNPUBLISH_NOTICE_MODAL,
  CAPITAL_EVENTS_OPEN_RESEND_ITEM_NOTICE_MODAL,
  CAPITAL_EVENTS_CLOSE_UNPUBLISH_NOTICE_MODAL,
  CAPITAL_EVENTS_CLOSE_RESEND_ITEM_NOTICE_MODAL,
  CAPITAL_EVENTS_OPEN_INITIAL_DEFAULT_MODAL,
  CAPITAL_EVENTS_CLOSE_INITIAL_DEFAULT_MODAL,
  CAPITAL_EVENTS_OPEN_FINAL_DEFAULT_MODAL,
  CAPITAL_EVENTS_CLOSE_FINAL_DEFAULT_MODAL,
  CAPITAL_EVENTS_OPEN_UNPUBLISH_EVENT_NOTICES_MODAL,
  CAPITAL_EVENTS_CLOSE_UNPUBLISH_EVENT_NOTICES_MODAL,
  CAPITAL_EVENTS_DISABLE_GRID_MENU,
  CAPITAL_EVENTS_ENABLE_GRID_MENU,
  CAPITAL_EVENTS_SET_UF_FUNDS_FETCHING,
  CAPITAL_EVENTS_UNSET_UF_FUNDS_FETCHING,
  CAPITAL_EVENTS_SET_UF_DATES,
  CAPITAL_EVENTS_SET_UF_DUE_DATE,
  CAPITAL_EVENTS_SET_UF_RECEIVED_DATE,
  CAPITAL_EVENTS_SET_SELECTED_EVENT,
  CAPITAL_EVENTS_OPEN_UNDERLYING_CAPITAL_EVENT_DRAWER,
  CAPITAL_EVENTS_CLOSE_UNDERLYING_CAPITAL_EVENT_DRAWER,
  CAPITAL_EVENTS_OPEN_UNDERLYING_CAPITAL_EVENT_DELETE_MODAL,
  CAPITAL_EVENTS_CLOSE_UNDERLYING_CAPITAL_EVENT_DELETE_MODAL,
} from 'actions/actionsConstants';

export const initialState = {
  is_fetching_grid: {
    generic: false,
    management_fees: false,
    fee_quarters: false,
    wlps: false,
    call_dates: false,
    menu_options: false,
  },
  is_uf_funds_fetching: false,
  is_fetching_modal: false,
  private_access_funds: [],
  underlying_capital_event: undefined,
  resend_event_id: undefined,
  capital_event_line_groups: [],
  filter_date_list: [],
  selected_filtered_date: undefined,
  filter_wlp_list: [],
  selected_filtered_wlp: undefined,
  uf_dates_list: {},
  selected_uf_due_date: undefined,
  selected_uf_received_date: undefined,
  filter_options: [],
  filter_text: '',
  selected_filter_option: {},
  group_by_close: true,
  filter_unreconciled: false,
  menu_options: {},
  finance_owner_options: [],
  underlying_funds: [],
  summaries: {},
  notes: [],
  status_reports: [],
  firm_reports: [],
  distribution_reports: [],
  reconciliation_exports: [],
  bulk_preview_notices: [],
  custodian_notices: [],
  management_fees: [],
  selected_fee_quarters: [],
  paf_charged_fee_quarters: [],
  capital_event_gl_info: [],
  fetching_closes: false,
  is_notice_editor_open: false,
  is_create_form_open: false,
  is_edit_form_open: false,
  is_expenses_modal_open: false,
  is_reminder_modal_open: false,
  is_status_report_modal_open: false,
  is_custodian_notices_open: false,
  is_execute_modal_open: false,
  is_firm_reports_open: false,
  is_distribution_reports_modal_open: false,
  is_delete_modal_open: false,
  is_reconciliation_modal_open: false,
  is_past_due_modal_open: false,
  is_upload_update_form_open: false,
  is_upload_reconciliation_modal_open: false,
  is_edit_from_file_form_open: false,
  is_nominee_notices_open: false,
  is_submit_approval_modal_open: false,
  is_notes_modal_open: false,
  is_create_uf_capital_event_form_open: false,
  is_resend_notice_modal_open: false,
  is_resend_event_notice_modal_open: false,
  is_bulk_preview_notices_open: false,
  is_reset_modal_open: false,
  is_resend_failed_notices_modal_open: false,
  is_reject_modal_open: false,
  is_upload_form_open: false,
  is_unpublish_notice_modal_open: false,
  is_resend_item_notice_modal_open: false,
  re_un_publish_line_item_id: undefined,
  is_unpublish_event_notices_modal_open: false,
  ce_execute_info: {},
  is_initial_default_modal_open: false,
  is_final_default_modal_open: false,
  disable_grid_menu: [],
  is_underlying_capital_event_drawer_open: false,
  is_underlying_capital_event_delete_modal_open: false,
};

export default function capitalEventReducer(state = initialState, action) {
  switch (action.type) {
    case CAPITAL_EVENTS_SET_GRID_FETCHING:
      let set_key = action.payload?.key || action.payload?.request?.key
      return update(state, {
        is_fetching_grid: {
          $merge: { [set_key]: true },
        },
      });
    case CAPITAL_EVENTS_UNSET_GRID_FETCHING:
      let unset_key = action.payload?.key || action.payload?.request?.key
      return update(state, {
        is_fetching_grid: {
          $merge: { [unset_key]: false },
        },
      });
    case CAPITAL_EVENTS_SET_MODAL_FETCHING:
      return update(state, {
        is_fetching_modal: { $set: true },
      });
    case CAPITAL_EVENTS_UNSET_MODAL_FETCHING:
      return update(state, {
        is_fetching_modal: { $set: false },
      });
    case CAPITAL_EVENTS_SET_PRIVATE_ACCESS_FUNDS:
      return update(state, {
        private_access_funds: { $set: action.payload },
      });
    case CAPITAL_EVENTS_OPEN_NOTICE_EDITOR:
      return update(state, {
        is_notice_editor_open: { $set: true },
        selected_item: { $set: action.payload },
      });
    case CAPITAL_EVENTS_CLOSE_NOTICE_EDITOR:
      return update(state, {
        is_notice_editor_open: { $set: false },
        $unset: ['selected_item'],
      });
    case CAPITAL_EVENTS_UPDATE_GROUPS:
      return update(state, {
        capital_event_line_groups: { $set: action.payload },
      });
    case CAPITAL_EVENTS_OPEN_CREATE_FORM:
      return update(state, {
        is_create_form_open: { $set: true },
      });
    case CAPITAL_EVENTS_CLOSE_CREATE_FORM:
      return update(state, {
        is_create_form_open: { $set: false },
      });
    case CAPITAL_EVENTS_OPEN_EDIT_FORM:
      return update(state, {
        is_edit_form_open: { $set: true },
        selected_item: { $set: action.payload },
      });
    case CAPITAL_EVENTS_CLOSE_EDIT_FORM:
      return update(state, {
        is_edit_form_open: { $set: false },
        $unset: ['selected_item'],
      });
    case CAPITAL_EVENTS_OPEN_EXPENSES_MODAL:
      return update(state, {
        is_expenses_modal_open: { $set: true },
      });
    case CAPITAL_EVENTS_CLOSE_EXPENSES_MODAL:
      return update(state, {
        is_expenses_modal_open: { $set: false },
      });
    case CAPITAL_EVENTS_OPEN_REMINDER_MODAL:
      return update(state, {
        is_reminder_modal_open: { $set: true },
        selected_item: { $set: action.payload },
      });
    case CAPITAL_EVENTS_CLOSE_REMINDER_MODAL:
      return update(state, {
        is_reminder_modal_open: { $set: false },
        $unset: ['selected_item'],
      });
    case CAPITAL_EVENTS_OPEN_STATUS_REPORT_MODAL:
      return update(state, {
        is_status_report_modal_open: { $set: true },
        selected_item: { $set: action.payload },
      });
    case CAPITAL_EVENTS_CLOSE_STATUS_REPORT_MODAL:
      return update(state, {
        is_status_report_modal_open: { $set: false },
        $unset: ['selected_item'],
      });
    case CAPITAL_EVENTS_SET_STATUS_REPORTS:
      return update(state, {
        status_reports: { $set: action.payload },
      });
    case CAPITAL_EVENTS_OPEN_CUSTODIAN_NOTICES:
      return update(state, {
        is_custodian_notices_open: { $set: true },
        selected_item: { $set: action.payload },
      });
    case CAPITAL_EVENTS_CLOSE_CUSTODIAN_NOTICES:
      return update(state, {
        is_custodian_notices_open: { $set: false },
        $unset: ['selected_item'],
      });
    case CAPITAL_EVENTS_SET_CUSTODIAN_NOTICES:
      return update(state, {
        custodian_notices: { $set: action.payload },
      });
    case CAPITAL_EVENTS_OPEN_NOMINEE_NOTICES:
      return update(state, {
        is_nominee_notices_open: { $set: true },
        selected_item: { $set: action.payload },
      });
    case CAPITAL_EVENTS_CLOSE_NOMINEE_NOTICES:
      return update(state, {
        is_nominee_notices_open: { $set: false },
        $unset: ['selected_item'],
      });
    case CAPITAL_EVENTS_SET_NOMINEE_NOTICES:
      return update(state, {
        nominee_notices: { $set: action.payload },
      });
    case CAPITAL_EVENTS_SET_SUMMARY:
      return update(state, {
        summaries: {
          $merge: { [action.id]: action.payload },
        },
      });
    case CAPITAL_EVENTS_CLEAR_SUMMARIES:
      return update(state, {
        summaries: { $set: {} },
      });
    case CAPITAL_EVENTS_OPEN_EXECUTE_MODAL:
      return update(state, {
        is_execute_modal_open: { $set: true },
        selected_item: { $set: action.payload },
      });
    case CAPITAL_EVENTS_CLOSE_EXECUTE_MODAL:
      return update(state, {
        is_execute_modal_open: { $set: false },
        $unset: ['selected_item'],
      });
    case CAPITAL_EVENTS_OPEN_FIRM_REPORTS:
      return update(state, {
        is_firm_reports_open: { $set: true },
        selected_item: { $set: action.payload },
      });
    case CAPITAL_EVENTS_CLOSE_FIRM_REPORTS:
      return update(state, {
        is_firm_reports_open: { $set: false },
        $unset: ['selected_item'],
      });
    case CAPITAL_EVENTS_SET_FIRM_REPORTS:
      return update(state, {
        firm_reports: { $set: action.payload },
      });
    case CAPITAL_EVENTS_OPEN_DISTRIBUTION_REPORTS_MODAL:
      return update(state, {
        is_distribution_reports_modal_open: { $set: true },
        selected_item: { $set: action.payload },
      });
    case CAPITAL_EVENTS_CLOSE_DISTRIBUTION_REPORTS_MODAL:
      return update(state, {
        is_distribution_reports_modal_open: { $set: false },
        $unset: ['selected_item'],
      });
    case CAPITAL_EVENTS_SET_DISTRIBUTION_REPORTS:
      return update(state, {
        distribution_reports: { $set: action.payload },
      });
    case CAPITAL_EVENTS_OPEN_DELETE_MODAL:
      return update(state, {
        is_delete_modal_open: { $set: true },
        selected_item: { $set: action.payload },
      });
    case CAPITAL_EVENTS_CLOSE_DELETE_MODAL:
      return update(state, {
        is_delete_modal_open: { $set: false },
        $unset: ['selected_item'],
      });
    case CAPITAL_EVENTS_OPEN_REJECT_MODAL:
      return update(state, {
        is_reject_modal_open: { $set: true },
        selected_item: { $set: action.payload },
      });
    case CAPITAL_EVENTS_CLOSE_REJECT_MODAL:
      return update(state, {
        is_reject_modal_open: { $set: false },
        $unset: ['selected_item'],
      });
    case CAPITAL_EVENTS_SET_RECONCILIATION_EXPORTS:
      return update(state, {
        reconciliation_exports: { $set: action.payload },
      });
    case CAPITAL_EVENTS_OPEN_RECONCILIATION_MODAL:
      return update(state, {
        is_reconciliation_modal_open: { $set: true },
        selected_item: { $set: action.payload },
      });
    case CAPITAL_EVENTS_CLOSE_RECONCILIATION_MODAL:
      return update(state, {
        is_reconciliation_modal_open: { $set: false },
        $unset: ['selected_item'],
      });
    case CAPITAL_EVENTS_SET_FILTER_DATE_LIST:
      return update(state, {
        filter_date_list: { $set: action.payload },
      });
    case CAPITAL_EVENTS_SET_SELECTED_FILTER_DATE:
      return update(state, {
        selected_filtered_date: { $set: action.payload },
      });
    case CAPITAL_EVENTS_SET_FILTER_WHITE_LABEL_PARTNERS_LIST:
      return update(state, {
        filter_wlp_list: { $set: action.payload },
      });
    case CAPITAL_EVENTS_SET_SELECTED_FILTER_WHITE_LABEL_PARTNER:
      return update(state, {
        selected_filtered_wlp: { $set: action.payload },
      });
    case CAPITAL_EVENTS_SET_FILTER_OPTIONS:
      return update(state, {
        filter_options: { $set: action.payload },
        selected_filter_option: { $set: action.payload[0] },
      });
    case CAPITAL_EVENTS_SET_SELECTED_FILTER_TEXT:
      return update(state, {
        filter_text: { $set: action.payload },
      });
    case CAPITAL_EVENTS_SET_SELECTED_FILTER_OPTION:
      return update(state, {
        selected_filter_option: { $set: action.payload },
      });
    case CAPITAL_EVENTS_SET_SELECTED_GROUP_BY_CLOSE:
      return update(state, {
        group_by_close: { $set: action.payload },
      });
    case CAPITAL_EVENTS_SET_SELECTED_FILTER_UNRECONCILED:
      return update(state, {
        filter_unreconciled: { $set: action.payload },
      });
    case CAPITAL_EVENTS_OPEN_UPLOAD_FORM:
      return update(state, {
        is_upload_form_open: { $set: true },
      });
    case CAPITAL_EVENTS_CLOSE_UPLOAD_FORM:
      return update(state, {
        is_upload_form_open: { $set: false },
      });
    case CAPITAL_EVENTS_OPEN_BULK_PREVIEW_NOTICES:
      return update(state, {
        is_bulk_preview_notices_open: { $set: true },
        selected_item: { $set: action.payload },
      });
    case CAPITAL_EVENTS_CLOSE_BULK_PREVIEW_NOTICES:
      return update(state, {
        is_bulk_preview_notices_open: { $set: false },
        $unset: ['selected_item'],
      });
    case CAPITAL_EVENTS_SET_BULK_PREVIEW_NOTICES:
      return update(state, {
        bulk_preview_notices: { $set: action.payload },
      });
    case CAPITAL_EVENTS_OPEN_RESET_MODAL:
      return update(state, {
        is_reset_modal_open: { $set: true },
        selected_item: { $set: action.payload },
      });
    case CAPITAL_EVENTS_CLOSE_RESET_MODAL:
      return update(state, {
        is_reset_modal_open: { $set: false },
        $unset: ['selected_item'],
      });
    case CAPITAL_EVENTS_OPEN_RESEND_FAILED_NOTICE_MODAL:
      return update(state, {
        is_resend_failed_notices_modal_open: { $set: true },
        selected_item: { $set: action.payload },
      });
    case CAPITAL_EVENTS_CLOSE_RESEND_FAILED_NOTICE_MODAL:
      return update(state, {
        is_resend_failed_notices_modal_open: { $set: false },
        $unset: ['selected_item'],
      });
    case CAPITAL_EVENTS_SET_FINANCE_OWNER_OPTIONS:
      return update(state, {
        finance_owner_options: { $set: action.payload },
      });
    case CAPITAL_EVENTS_SET_UNDERLYING_FUNDS:
      return update(state, {
        underlying_funds: { $set: action.payload },
      });
    case CAPITAL_EVENTS_SET_UNDERLYING_CAPITAL_EVENTS:
      return update(state, {
        underlying_capital_event: { $set: action.payload },
      });
    case CAPITAL_EVENTS_OPEN_RESEND_EVENT_NOTICE_MODAL:
      return update(state, {
        is_resend_event_notice_modal_open: { $set: true },
        resend_event_id: { $set: action.payload },
      });
    case CAPITAL_EVENTS_CLOSE_RESEND_EVENT_NOTICE_MODAL:
      return update(state, {
        is_resend_event_notice_modal_open: { $set: false },
        $unset: ['resend_event_id'],
      });
    case CAPITAL_EVENTS_OPEN_PAST_DUE_MODAL:
      return update(state, {
        is_past_due_modal_open: { $set: true },
        selected_item: { $set: action.payload },
      });
    case CAPITAL_EVENTS_CLOSE_PAST_DUE_MODAL:
      return update(state, {
        is_past_due_modal_open: { $set: false },
        $unset: ['selected_item'],
      });
    case CAPITAL_EVENTS_OPEN_UPLOAD_UPDATE_MODAL:
      return update(state, {
        is_upload_update_form_open: { $set: true },
        selected_item: { $set: action.payload },
      });
    case CAPITAL_EVENTS_CLOSE_UPLOAD_UPDATE_MODAL:
      return update(state, {
        is_upload_update_form_open: { $set: false },
        $unset: ['selected_item'],
      });
    case CAPITAL_EVENTS_OPEN_UPLOAD_RECONCILIATION_MODAL:
      return update(state, {
        is_upload_reconciliation_modal_open: { $set: true },
        selected_item: { $set: action.payload },
      });
    case CAPITAL_EVENTS_CLOSE_UPLOAD_RECONCILIATION_MODAL:
      return update(state, {
        is_upload_reconciliation_modal_open: { $set: false },
        $unset: ['selected_item'],
      });
    case CAPITAL_EVENTS_OPEN_EDIT_FROM_FILE_FORM:
      return update(state, {
        is_edit_from_file_form_open: { $set: true },
        selected_item: { $set: action.payload },
      });
    case CAPITAL_EVENTS_CLOSE_EDIT_FROM_FILE_FORM:
      return update(state, {
        is_edit_from_file_form_open: { $set: false },
        $unset: ['selected_item'],
      });
    case CAPITAL_EVENTS_OPEN_UF_MODAL:
      return update(state, {
        is_create_uf_capital_event_form_open: { $set: true },
        selected_item: { $set: action.payload },
      });
    case CAPITAL_EVENTS_CLOSE_UF_MODAL:
      return update(state, {
        is_create_uf_capital_event_form_open: { $set: false },
        $unset: ['selected_item'],
      });
    case CAPITAL_EVENTS_OPEN_NOTES_MODAL:
      return update(state, {
        is_notes_modal_open: { $set: true },
        selected_item: { $set: action.payload },
      });
    case CAPITAL_EVENTS_CLOSE_NOTES_MODAL:
      return update(state, {
        is_notes_modal_open: { $set: false },
        $unset: ['selected_item'],
      });
    case CAPITAL_EVENTS_SET_NOTES:
      return update(state, {
        notes: { $set: action.payload },
      });
    case CAPITAL_EVENTS_OPEN_SUBMIT_APPROVAL_MODAL:
      return update(state, {
        is_submit_approval_modal_open: { $set: true },
        selected_item: { $set: action.payload },
      });
    case CAPITAL_EVENTS_CLOSE_SUBMIT_APPROVAL_MODAL:
      return update(state, {
        is_submit_approval_modal_open: { $set: false },
        $unset: ['selected_item'],
      });
    case CAPITAL_EVENTS_SET_MANAGEMENT_FEES:
      return update(state, {
        management_fees: { $set: action.payload },
      });
    case CAPITAL_EVENTS_SET_PAF_CHARGED_FEE_QUARTERS:
      return update(state, {
        paf_charged_fee_quarters: { $set: action.payload },
      });
    case CAPITAL_EVENTS_SET_MENU_OPTIONS:
      return update(state, {
        menu_options: { $set: action.payload },
      });
    case CAPITAL_EVENTS_SET_EXECUTE_INFO:
      return update(state, {
        ce_execute_info: { $set: action.payload },
      });
    case CAPITAL_EVENTS_OPEN_UNPUBLISH_NOTICE_MODAL:
      return update(state, {
        is_unpublish_notice_modal_open: { $set: true },
        re_un_publish_line_item_id: { $set: action.payload },
      });
    case CAPITAL_EVENTS_OPEN_RESEND_ITEM_NOTICE_MODAL:
      return update(state, {
        is_resend_item_notice_modal_open: { $set: true },
        re_un_publish_line_item_id: { $set: action.payload },
      });
    case CAPITAL_EVENTS_CLOSE_UNPUBLISH_NOTICE_MODAL:
      return update(state, {
        is_unpublish_notice_modal_open: { $set: false },
        re_un_publish_line_item_id: { $set: undefined },
      });
    case CAPITAL_EVENTS_CLOSE_RESEND_ITEM_NOTICE_MODAL:
      return update(state, {
        is_resend_item_notice_modal_open: { $set: false },
        re_un_publish_line_item_id: { $set: undefined },
      });
    case CAPITAL_EVENTS_OPEN_INITIAL_DEFAULT_MODAL:
      return update(state, {
        is_initial_default_modal_open: { $set: true },
        selected_item: { $set: action.payload },
      });
    case CAPITAL_EVENTS_CLOSE_INITIAL_DEFAULT_MODAL:
      return update(state, {
        is_initial_default_modal_open: { $set: false },
        $unset: ['selected_item'],
      });
    case CAPITAL_EVENTS_OPEN_FINAL_DEFAULT_MODAL:
      return update(state, {
        is_final_default_modal_open: { $set: true },
        selected_item: { $set: action.payload },
      });
    case CAPITAL_EVENTS_CLOSE_FINAL_DEFAULT_MODAL:
      return update(state, {
        is_final_default_modal_open: { $set: false },
        $unset: ['selected_item'],
      });
    case CAPITAL_EVENTS_OPEN_UNPUBLISH_EVENT_NOTICES_MODAL:
      return update(state, {
        is_unpublish_event_notices_modal_open: { $set: true },
        selected_item: { $set: action.payload },
      });
    case CAPITAL_EVENTS_CLOSE_UNPUBLISH_EVENT_NOTICES_MODAL:
      return update(state, {
        is_unpublish_event_notices_modal_open: { $set: false },
        $unset: ['selected_item'],
      });
    case CAPITAL_EVENTS_DISABLE_GRID_MENU:
      return update(state, {
        disable_grid_menu: { $push: [action.payload] },
      });
    case CAPITAL_EVENTS_ENABLE_GRID_MENU: {
      const index = state.disable_grid_menu.findIndex((it) => it.id === action.payload);
      return update(state, {
        disable_grid_menu: { $splice: [[index, 1]] },
      });
    }
    case CAPITAL_EVENTS_SET_UF_FUNDS_FETCHING: {
      return update(state, {
        is_uf_funds_fetching: { $set: true },
      });
    }
    case CAPITAL_EVENTS_UNSET_UF_FUNDS_FETCHING: {
      return update(state, {
        is_uf_funds_fetching: { $set: false },
      });
    }
    case CAPITAL_EVENTS_SET_UF_DATES: {
      return update(state, {
        uf_dates_list: { $set: action.payload },
        selected_uf_due_date: { $set: undefined },
        selected_uf_received_date: { $set: undefined },
      });
    }
    case CAPITAL_EVENTS_SET_UF_DUE_DATE: {
      return update(state, {
        selected_uf_due_date: { $set: action.payload },
      });
    }
    case CAPITAL_EVENTS_SET_UF_RECEIVED_DATE: {
      return update(state, {
        selected_uf_received_date: { $set: action.payload },
      });
    }
    case CAPITAL_EVENTS_SET_SELECTED_EVENT: {
      return update(state, {
        selected_item: { $set: action.payload },
      });
    }
    case CAPITAL_EVENTS_OPEN_UNDERLYING_CAPITAL_EVENT_DRAWER: {
      return update(state, {
        is_underlying_capital_event_drawer_open: { $set: true },
        selected_item: { $set: action.payload },
      });
    }
    case CAPITAL_EVENTS_CLOSE_UNDERLYING_CAPITAL_EVENT_DRAWER: {
      return update(state, {
        is_underlying_capital_event_drawer_open: { $set: false },
        $unset: ['selected_item'],
      });
    }
    case CAPITAL_EVENTS_OPEN_UNDERLYING_CAPITAL_EVENT_DELETE_MODAL: {
      return update(state, {
        is_underlying_capital_event_delete_modal_open: { $set: true },
        selected_item: { $set: action.payload },
      });
    }
    case CAPITAL_EVENTS_CLOSE_UNDERLYING_CAPITAL_EVENT_DELETE_MODAL: {
      return update(state, {
        is_underlying_capital_event_delete_modal_open: { $set: false },
        $unset: ['selected_item'],
      });
    }
    default:
      return state;
  }
}
