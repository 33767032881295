const interestedPartyValidations = {
  name: {
    allowedCharacters: {},
  },
  relationship_to_investor: {
    allowedCharacters: {},
  },
  phone: {
    allowedCharacters: {},
  },
  email: {
    email: {},
  },
};

export default interestedPartyValidations;
