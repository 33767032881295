import passportActions from 'modules/passport/actions';
import whiteLabelPartnerActions from 'modules/wlp_admin/WlpAdmin.actions';
import icnBootstrapActions from './icnBootstrapActions';
import dcFilterActions from './doc_center_actions/doc_center_filter_actions/dcFilterActions';
import dcFilterListActions from './doc_center_actions/doc_center_filter_actions/dcFilterListActions';
import dcResultsPerPageActions from './doc_center_actions/doc_center_results_per_page_actions/dcResultsPerPageActions';
import dcResultsActions from './doc_center_actions/doc_center_results_actions/dcResultsActions';
import dcDownloadActions from './doc_center_actions/doc_center_download_actions/dcDownloadActions';
import dcSavedViewsActions from './doc_center_actions/doc_center_saved_views_actions/dcSavedViewsActions';
import dcUploaderActions from './doc_center_actions/doc_center_uploader_actions/dcUploaderActions';
import investorProfileActions from './investor_profile_actions/investorProfileActions';
import capitalEventActions from './fund_admin_actions/capitalEventActions';
import fundAdminActions from './fund_admin_actions/fundAdminActions';
import reportGeneratorActions from './report_generator_actions/reportGeneratorActions';
import documentManagerActions from './document_manager_actions/documentManagerActions';
import fundDashboardActions from './fund_dashboard_actions/fundDashboardActions';
import salesReportsActions from './sales_reports_actions/salesReportsActions';
import salesDetailActions from './sales_detail_actions/salesDetailActions';
import userSettingsActions from './user_settings_actions/userSettingsActions';
import investmentDashboardActions from './investment_dashboard_actions/investmentDashboardActions';
import investmentDashboardPdfReportActions from './investment_dashboard_actions/pdfReportActions';
import inviteClientsActions from './sales_invite_clients/inviteClientsActions';
import subscriptionWorkflowStatusActions from './subscription_workflow_status_actions/subscriptionWorkflowStatusActions';
import subscriptionReviewerActions from './investor_relations_actions/subscriptionReviewerActions';
import documentDefinitionsActions from './document_definitions_actions/documentDefinitionsActions';
import pcapActions from './fund_admin_actions/pcapActions';
import oneTimePopupActions from './one_time_popup_actions';
import reportingActions from './reporting_actions/operationsReportsActions';
import ppmWatermarkingActions from './reporting_actions/ppmWatermarkingActions';
import dataDashboardActions from './data_dashboard_actions/dataDashboardActions';
import subscriptionsDashboardActions from './subscriptions_dashboard_actions/subscriptionsDashboardActions';
import navActions from './fund_admin_actions/navActions';
import statementsActions from './fund_admin_actions/statementsActions';
import reconciliationActions from './fund_admin_actions/reconciliationActions';
import subscriptionReviewActions from './subscription_review_actions/subscriptionReviewActions';
import manageFundingStatus from './manage_funding_status_actions/manageFundingStatusActions';
import notificationEvent from './notification_events_actions/notificationEventActions';
import authorizationGroupsActions from './authorization_groups_actions/authorizationGroupsActions';
import apiLogFinderActions from './api_log_finder_actions/apiLogFinderActions';
import operationsCapitalEventsActions from './fund_admin_actions/operations_dashboard/capitalEventsActions';
import subdocExclusionsActions from './subdoc_exclusions_actions/subdocExclusionsActions';
import integrationPartnerDashboardActions from './integration_partner_dashboard_actions/integrationPartnerDashboardActions';
import fundFeesActions from './fund_admin_actions/fund_fees_actions/fundFeesActions';
import supplementalFormActions from './supplemental_form_actions/supplementalFormActions';
import fundFinancesSynthesisActions from './fund_finances_actions/fundFinancesSynthesisActions';
import synthesisExportModalActions from './fund_finances_actions/synthesisExportModalActions';
import refreshDataSynthesisActions from './fund_finances_actions/refreshDataSynthesisActions';
import levenshteinMatcherActions from './levenshtein_matcher_actions/LevenshteinMatcherActions';
import amlSuitabilityRepsActions from './aml_suitability_reps_actions/amlSuitabilityRepsActions';
import secureDocumentTransferActions from './secure_document_transfer_actions/secureDocumentTransferActions';
import adminSearchNavbarActions from './admin_search/adminSearchActions';
import generalLedgersActions from './fund_admin_actions/general_ledgers_actions/generalLedgersActions';
import documentCenterWidgetActions from './document_center_widget_actions/documentCenterWidgetActions';
import documentViewInfoActions from './document_view_info/documentViewInfoActions';
import investorRepresentativeActions from './investor_representative_actions/investorRepresentativeActions';
import termsOfUsePopupActions from './terms_of_use_popup_actions/termsOfUsePopupActions';
import teamManagementActions from './team_management/teamManagementActions';
import integrationPartnerTemplatesActions from './integration_partner_templates_actions/integrationPartnerTemplatesActions';
import dataReconciliationActions from '././data_reconciliation_actions/dataReconciliationActions';
import loginActions from './login_actions/loginActions';
import complianceActions from './compliance_actions/complianceActions';
import registrationAccountTypeActions from './registration_account_types_actions/registrationAccountTypeActions';
import registrationActions from './registration_actions/registrationActions';
import preApprovalQuestionnaireActions from './pre_approval_questionnaire_actions/preApprovalQuestionnaireActions';
import fundAdminUploaderActions from './fund_admin_actions/fundAdminUploaderActions';
import agreementSigningActions from './agreement_signing/agreementSigningActions';
import ceCashFundingActions from './fund_admin_actions/capital_events/cash_funding_actions/index';
import multifactorAuthenticationActions from './multifactor_authentication_actions/multifactorAuthenticationActions';
import icnGeoCountriesActions from './icn_geo_countries_actions/icnGeoCountriesActions';
import fundDocumentEmailsActions from './fund_document_emails_actions/fundDocumentEmailsActions';
import docCenterNotesActions from './doc_center_actions/doc_center_notes_actions/docCenterNotesActions';
import docCenterHotButtonsActions from './doc_center_actions/doc_center_hot_buttons_actions/docCenterHotButtonsActions';
import reportScheduleRequestsActions from './report_schedule_requests_actions/reportScheduleRequestsActions';
import publishedDocumentExportActions from './published_document_export_actions/publishedDocumentExportActions';
import * as pdfViewerActions from '../modules/pdf_viewer/state/actions';
import agreementsActions from './access_agreements_actions/accessAgreementsActions';
import nomineeReviewActions from './nominee_review_actions/nomineeReviewActions';
import reportScheduleLogActions from './report_schedule_log_actions/reportScheduleLogActions';
import spinnerActions from './spinner_actions/spinnerActions';
import * as oktaAuthenticationActions from './okta_authentication_actions/oktaAuthenticationActions';
import qualificationPopupActions from './qualification_popup_actions/qualificationPopupActions';
import userAcknowledgmentActions from './user_acknowledgments_actions/userAcknowledgmentsActions';
import flexibleFeedDashboardActions from './flexible_feed_dashboard_actions/flexibleFeedDashboardActions';
import consentFormActions from './consent_form_actions/consentFormActions';
import firmActions from './firms_actions/firmActions';

export default {
  ...icnBootstrapActions,
  ...dcFilterActions,
  ...dcFilterListActions,
  ...dcResultsPerPageActions,
  ...dcResultsActions,
  ...dcDownloadActions,
  ...dcSavedViewsActions,
  ...dcUploaderActions,
  ...investorProfileActions,
  ...capitalEventActions,
  ...fundAdminActions,
  ...reportGeneratorActions,
  ...documentManagerActions,
  ...fundDashboardActions,
  ...salesReportsActions,
  ...salesDetailActions,
  ...userSettingsActions,
  ...investmentDashboardActions,
  ...investmentDashboardPdfReportActions,
  ...inviteClientsActions,
  ...subscriptionWorkflowStatusActions,
  ...subscriptionReviewerActions,
  ...documentDefinitionsActions,
  ...pcapActions,
  ...oneTimePopupActions,
  ...reportingActions,
  ...ppmWatermarkingActions,
  ...dataDashboardActions,
  ...subscriptionsDashboardActions,
  ...navActions,
  ...statementsActions,
  ...reconciliationActions,
  ...subscriptionReviewActions,
  ...manageFundingStatus,
  ...notificationEvent,
  ...authorizationGroupsActions,
  ...apiLogFinderActions,
  ...operationsCapitalEventsActions,
  ...subdocExclusionsActions,
  ...integrationPartnerDashboardActions,
  ...integrationPartnerTemplatesActions,
  ...fundFeesActions,
  ...supplementalFormActions,
  ...fundFinancesSynthesisActions,
  ...synthesisExportModalActions,
  ...refreshDataSynthesisActions,
  ...levenshteinMatcherActions,
  ...amlSuitabilityRepsActions,
  ...secureDocumentTransferActions,
  ...adminSearchNavbarActions,
  ...generalLedgersActions,
  ...documentCenterWidgetActions,
  ...documentViewInfoActions,
  ...investorRepresentativeActions,
  ...termsOfUsePopupActions,
  ...teamManagementActions,
  ...dataReconciliationActions,
  ...loginActions,
  ...complianceActions,
  ...registrationAccountTypeActions,
  ...registrationActions,
  ...preApprovalQuestionnaireActions,
  ...fundAdminUploaderActions,
  ...agreementSigningActions,
  ...ceCashFundingActions,
  ...multifactorAuthenticationActions,
  ...icnGeoCountriesActions,
  ...fundDocumentEmailsActions,
  ...docCenterNotesActions,
  ...docCenterHotButtonsActions,
  ...publishedDocumentExportActions,
  ...whiteLabelPartnerActions,
  ...reportScheduleRequestsActions,
  ...pdfViewerActions,
  ...agreementsActions,
  ...nomineeReviewActions,
  ...reportScheduleLogActions,
  ...spinnerActions,
  ...oktaAuthenticationActions,
  ...qualificationPopupActions,
  ...userAcknowledgmentActions,
  ...flexibleFeedDashboardActions,
  ...consentFormActions,
  ...firmActions,
  ...passportActions,
};
