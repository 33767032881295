import { createAction } from 'redux-actions';
import { createRequestTypes } from 'actions/helpers';
import { BASE_NAME as ENTRY_BASE_NAME } from '../actions';
import { apiShowFormTemplate } from '../../actions';

export const BASE_NAME = `${ENTRY_BASE_NAME}_versions_create`;

export const FORM_TEMPLATES_VERSIONS_CREATE = {
  UNMOUNT: `${BASE_NAME}_UNMOUNT`,
  READ: createRequestTypes(`${BASE_NAME}_READ`),
};

export const onUnmount = createAction(FORM_TEMPLATES_VERSIONS_CREATE.UNMOUNT);

export const showFormTemplate = apiShowFormTemplate(FORM_TEMPLATES_VERSIONS_CREATE.READ);

export default {
  onUnmount,
  showFormTemplate,
};
