import {
  RGA_SET_SELECTED_EXCLUSIONS,
  RGA_SET_RESULTS_PER_PAGE,
  RGA_SET_CURRENT_PAGE,
  RGA_SET_SORTING,
  RG_APPLY_FILTERS,
} from 'actions/actionsConstants';

import update from 'immutability-helper';

import { normalizeCurrentPage } from '../utils';
import initialState from 'reducers/report_generator_reducers/initialState';

export default (state = initialState, action) => {
  let newState = null;

  switch (action.type) {
    case RGA_SET_SELECTED_EXCLUSIONS:
      newState = update(state, {
        selected_rias_firms: { $set: action.payload },
        current_page: { $set: normalizeCurrentPage(1, state) },
      });
      return newState;
    case RGA_SET_RESULTS_PER_PAGE:
      newState = update(state, {
        results_per_page: { $set: action.payload },
        current_page: { $set: initialState.current_page },
      });
      return newState;
    case RGA_SET_CURRENT_PAGE:
      newState = update(state, {
        current_page: { $set: normalizeCurrentPage(action.payload, state) },
      });
      return newState;
    case RGA_SET_SORTING:
      newState = update(state, {
        sorting_column: { $set: action.payload.sortingColumn },
        sorting_order: { $set: action.payload.sortingOrder },
      });
      return newState;
    case RG_APPLY_FILTERS:
      const { nameFilter, baseQueryFilter, deliveryFilter } = action.payload;
      newState = update(state, {
        nameFilter: { $set: nameFilter },
        baseQueryFilter: { $set: baseQueryFilter },
        deliveryFilter: { $set: deliveryFilter },
        current_page: { $set: normalizeCurrentPage(1, state) },
      });
      return newState;
    default:
      return state;
  }
};
