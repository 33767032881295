import { RGA_SET_CONFIG_FIELDS } from 'actions/actionsConstants';

import initialState from 'reducers/report_generator_reducers/initialState';
import { getFilterConfigs } from './utils';

export default (state = initialState, action) => {
  switch (action.type) {
    case RGA_SET_CONFIG_FIELDS: {
      const { report_config_fields } = action.payload;
      return {
        ...state,
        configFields: report_config_fields,
        ...getFilterConfigs(report_config_fields, state),
      };
    }
    default:
      return state;
  }
};
