import React from 'react';
import { shape, bool } from 'prop-types';
import { useTheme } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import Stack from '@icapitalnetwork/supernova-core/Stack';
import Typography from '@icapitalnetwork/supernova-core/Typography';

import { users as usersType } from 'modules/passport/prop_types/user';
import styles from './PassportMenu.module.scss';

const LinkedAccount = ({ wlp, isCurrentWlp }) => {
  const theme = useTheme();
  const name = wlp?.wlp_name;
  const bgColor =
    wlp?.wlp_primary_color === 'white' || wlp?.wlp_primary_color.toLowerCase() === '#ffffff'
      ? theme.palette.grey[500]
      : wlp?.wlp_primary_color;

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" className={styles.linked_account}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Avatar className={styles.company_avatar} sx={{ bgcolor: bgColor }}>
          <Typography variant="caption">{name && name[0]}</Typography>
        </Avatar>
        <Typography variant={isCurrentWlp ? 'columnHeader' : ''}>{name}</Typography>
      </Stack>
      <SwapHorizIcon color="primary" fontSize="small" className={styles.hidden} />
    </Stack>
  );
};

LinkedAccount.defaultProps = {
  wlp: null,
  isCurrentWlp: false,
};

LinkedAccount.propTypes = {
  wlp: shape(usersType),
  isCurrentWlp: bool,
};

export default LinkedAccount;
