import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './IcnTabs.module.scss';

const IcnTabs = ({
  className,
  children,
}) => (
  <div className={classNames(styles.IcnTabs, className)}>
    <div className={styles.IcnTabs__container}>
      <div className={styles.IcnTabs__scrollableContent}>
        {children}
      </div>
    </div>
  </div>
);

IcnTabs.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

IcnTabs.defaultProps = {
  className: null,
};

export default React.memo(IcnTabs);
