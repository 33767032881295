export const generalStates = {
  error: false,
  message: '',
  request_object: '',
  request_type: '',
  request_total: 0,
};

export const dataReconStates = {
  data_recons: {
    records: [],
    pagination: {
      total_count: 0,
      total_pages: 0,
      total_per_page: 0,
      current_page: 1,
      prev_page: null,
      next_page: null,
    },
    response_id: null,
    sorted_by: null,
  },
};

export const dataReconFileStates = {
  data_recon_files: {
    records: [],
    pagination: {
      total_count: 0,
      total_pages: 0,
      total_per_page: 0,
      current_page: 1,
      prev_page: null,
      next_page: null,
    },
    response_id: null,
    sorted_by: null,
  },
};

export const statisticsStates = {
  statistics: {
    total_count: null,
    total_approved: null,
    total_need_approval: null,
    total_ready_to_reconcile: null,
    total_need_internal_file: null,
  },
};

export const filterSourceStates = {
  private_access_funds: [],
  fund_admin_groups: [],
  reconciliation_types: [],
  reconciliation_periods: [],
  fund_admins: [],
};
export const dataReconParameterStates = {
  data_recon_parameter: {
    private_access_fund_id: null,
    automatic_reconcile_eclerx: null,
    uat_data: null,
    true_up: null,
    upload: null,
    reconcile: null,
    new_record: null,
  },
};

export default {
  ...generalStates,
  ...dataReconStates,
  ...dataReconFileStates,
  ...statisticsStates,
  ...filterSourceStates,
  ...dataReconParameterStates,
};
