import update from 'immutability-helper';
import { MASS_MAILER_ALL_FUNDS, MASS_MAILER_ALL_FUNDS_LIST } from './actions';

export const initialState = {
  isLoading: true,
  list: [],
};

const massMailerFundsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case MASS_MAILER_ALL_FUNDS.LIST.REQUEST:
      return update(state, { isLoading: { $set: true } });
    case MASS_MAILER_ALL_FUNDS.LIST.SUCCESS:
      return update(state, {
        isLoading: { $set: false },
        list: {
          $set: payload.response.map((item) => ({
            label: item.name,
            value: item.id,
          })),
        },
      });
    case MASS_MAILER_ALL_FUNDS.LIST.FAILURE:
      return update(state, { isLoading: { $set: false } });
    case MASS_MAILER_ALL_FUNDS_LIST.UNMOUNT:
      return initialState;
    default:
      return state;
  }
};

export default massMailerFundsReducer;
