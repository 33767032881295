import update from 'immutability-helper';

import {
  RGA_SAVE_FILTER_TRANSFORM,
  RGA_SET_TRANSFORM_NULL_FILTER,
} from 'actions/actionsConstants';

import initialState from 'reducers/report_generator_reducers/initialState';
import { retrieveDBKey } from 'reducers/report_generator_reducers/utils';

export default (state = initialState, action) => {
  switch (action.type) {
    case RGA_SAVE_FILTER_TRANSFORM:
      const { filter, options, data_type } = action.payload;
      const configurableId = state.configurableId;
      const availableFields = state.available_fields[configurableId];
      const nullOption = state.nullOptions[configurableId][filter];

      const saveObject = {
        data_type,
        value: options,
        null_option: nullOption,
      };

      const dbMethod = retrieveDBKey(filter, availableFields);

      const description = options
        .map((option) => (option === '' ? '(Blank)' : option))
        .toString();

      return {
        ...state,
        stored_queries: update(state.stored_queries, {
          [state.configurableId]: {
            [filter]: { $set: { ...saveObject, ...dbMethod } },
          },
        }),
        totals: update(state.totals, {
          [state.configurableId]: { [filter]: { $set: description } },
        }),
      };
    case RGA_SET_TRANSFORM_NULL_FILTER: {
      const {
        payload: { filter, nullOption },
      } = action;
      const { configurableId } = state;

      return {
        ...state,
        nullOptions: update(state.nullOptions, {
          [configurableId]: { [filter]: { $set: nullOption } },
        }),
      };
    }
    default:
      return state;
  }
};
