import {
  NOTIFY_SUCCESS,
  NOTIFY_ERROR,
} from 'actions/actionsConstants';

const initialState = {};

function notificationState(type, action) {
  return {
    type,
    title: action.title,
    message: action.message,
  };
}

export default (state = initialState, action) => {
  switch (action.type) {
    case NOTIFY_SUCCESS: {
      const success = notificationState('success', action);
      return {
        ...state,
        ...success,
      };
    }
    case NOTIFY_ERROR: {
      const error = notificationState('error', action);
      return {
        ...state,
        ...error,
      };
    }
    default:
      return state;
  }
};
