export const ACTIVITY_LOG_SET_LOGS = 'ACTIVITY_LOG_SET_LOGS';
export const ACTIVITY_LOG_SET_CURRENT_PAGE = 'ACTIVITY_LOG_SET_CURRENT_PAGE';
export const ACTIVITY_LOG_SET_RESULTS_PER_PAGE = 'ACTIVITY_LOG_SET_RESULTS_PER_PAGE';
export const ACTIVITY_LOG_SET_SORT = 'ACTIVITY_LOG_SET_SORT';
export const ACTIVITY_LOG_SET_PAGE_ACTIONS = 'ACTIVITY_LOG_SET_PAGE_ACTIONS';
export const ACTIVITY_LOG_REMOVE_ALL_FILTERS = 'ACTIVITY_LOG_REMOVE_ALL_FILTERS';
export const ACTIVITY_LOG_REMOVE_FILTER_USER = 'ACTIVITY_LOG_REMOVE_FILTER_USER';
export const ACTIVITY_LOG_REMOVE_FILTER_EVENT = 'ACTIVITY_LOG_REMOVE_FILTER_EVENT';
export const ACTIVITY_LOG_REMOVE_FILTER_PRODUCT = 'ACTIVITY_LOG_REMOVE_FILTER_PRODUCT';
export const ACTIVITY_LOG_REMOVE_FILTER_CATEGORY = 'ACTIVITY_LOG_REMOVE_FILTER_CATEGORY';
export const ACTIVITY_LOG_REMOVE_FILTER_STRATEGY = 'ACTIVITY_LOG_REMOVE_FILTER_STRATEGY';
export const ACTIVITY_LOG_REMOVE_FILTER_COURSE_TYPE = 'ACTIVITY_LOG_REMOVE_FILTER_COURSE_TYPE';
export const ACTIVITY_LOG_SET_FILTERS = 'ACTIVITY_LOG_SET_FILTERS';
export const ACTIVITY_LOG_REMOVE_DATE_RANGE_FILTER = 'ACTIVITY_LOG_REMOVE_DATE_RANGE_FILTER';
export const ACTIVITY_LOG_REMOVE_USER_STATUS_FILTER = 'ACTIVITY_LOG_REMOVE_USER_STATUS_FILTER';
export const ACTIVITY_LOG_SET_USER_SAVED_VIEW = 'ACTIVITY_LOG_SET_USER_SAVED_VIEW';

// User Activity Logs
export const ACTIVITY_LOG_USER_SET_DETAILS = 'ACTIVITY_LOG_USER_SET_DETAILS';
export const ACTIVITY_LOG_USER_SET_LOGS = 'ACTIVITY_LOG_USER_SET_LOGS';
export const ACTIVITY_LOG_USER_SET_CURRENT_PAGE = 'ACTIVITY_LOG_USER_SET_CURRENT_PAGE';
export const ACTIVITY_LOG_USER_SET_RESULTS_PER_PAGE = 'ACTIVITY_LOG_USER_SET_RESULTS_PER_PAGE';
export const ACTIVITY_LOG_USER_SET_SORT = 'ACTIVITY_LOG_USER_SET_SORT';
export const ACTIVITY_LOG_USER_SET_PAGE_ACTIONS = 'ACTIVITY_LOG_USER_SET_PAGE_ACTIONS';
export const ACTIVITY_LOG_USER_REMOVE_ALL_FILTERS = 'ACTIVITY_LOG_USER_REMOVE_ALL_FILTERS';
export const ACTIVITY_LOG_USER_REMOVE_FILTER_EVENT = 'ACTIVITY_LOG_USER_REMOVE_FILTER_EVENT';
export const ACTIVITY_LOG_USER_REMOVE_FILTER_PRODUCT = 'ACTIVITY_LOG_USER_REMOVE_FILTER_PRODUCT';
export const ACTIVITY_LOG_USER_REMOVE_FILTER_CATEGORY = 'ACTIVITY_LOG_USER_REMOVE_FILTER_CATEGORY';
export const ACTIVITY_LOG_USER_REMOVE_FILTER_STRATEGY = 'ACTIVITY_LOG_USER_REMOVE_FILTER_STRATEGY';
export const ACTIVITY_LOG_USER_REMOVE_FILTER_COURSE_TYPE = 'ACTIVITY_LOG_USER_REMOVE_FILTER_COURSE_TYPE';
export const ACTIVITY_LOG_USER_SET_FILTERS = 'ACTIVITY_LOG_USER_SET_FILTERS';
export const ACTIVITY_LOG_USER_REMOVE_DATE_RANGE_FILTER = 'ACTIVITY_LOG_USER_REMOVE_DATE_RANGE_FILTER';

// GP Activities
export const ACTIVITY_LOG_GP_ACTIVITIES_SET_DATA = 'ACTIVITY_LOG_GP_ACTIVITIES_SET_DATA';
export const ACTIVITY_LOG_GP_ACTIVITIES_SET_CURRENT_PAGE = 'ACTIVITY_LOG_GP_ACTIVITIES_SET_CURRENT_PAGE';
export const ACTIVITY_LOG_GP_ACTIVITIES_SET_RESULTS_PER_PAGE = 'ACTIVITY_LOG_GP_ACTIVITIES_SET_RESULTS_PER_PAGE';
export const ACTIVITY_LOG_GP_ACTIVITIES_SET_SORT = 'ACTIVITY_LOG_GP_ACTIVITIES_SET_SORT';
export const ACTIVITY_LOG_GP_ACTIVITIES_SET_PAGE_ACTIONS = 'ACTIVITY_LOG_GP_ACTIVITIES_SET_PAGE_ACTIONS';
export const ACTIVITY_LOG_GP_ACTIVITIES_REMOVE_ALL_FILTERS = 'ACTIVITY_LOG_GP_ACTIVITIES_REMOVE_ALL_FILTERS';
export const ACTIVITY_LOG_GP_ACTIVITIES_REMOVE_FILTER_USER = 'ACTIVITY_LOG_GP_ACTIVITIES_REMOVE_FILTER_USER';
export const ACTIVITY_LOG_GP_ACTIVITIES_REMOVE_FILTER_FIRM = 'ACTIVITY_LOG_GP_ACTIVITIES_REMOVE_FILTER_FIRM';
export const ACTIVITY_LOG_GP_ACTIVITIES_REMOVE_FILTER_EVENT = 'ACTIVITY_LOG_GP_ACTIVITIES_REMOVE_FILTER_EVENT';
export const ACTIVITY_LOG_GP_ACTIVITIES_REMOVE_FILTER_PRODUCT = 'ACTIVITY_LOG_GP_ACTIVITIES_REMOVE_FILTER_PRODUCT';
export const ACTIVITY_LOG_GP_ACTIVITIES_REMOVE_FILTER_STATE = 'ACTIVITY_LOG_GP_ACTIVITIES_REMOVE_FILTER_STATE';
export const ACTIVITY_LOG_GP_ACTIVITIES_REMOVE_FILTER_CATEGORY = 'ACTIVITY_LOG_GP_ACTIVITIES_REMOVE_FILTER_CATEGORY';
export const ACTIVITY_LOG_GP_ACTIVITIES_REMOVE_FILTER_STRATEGY = 'ACTIVITY_LOG_GP_ACTIVITIES_REMOVE_FILTER_STRATEGY';
export const ACTIVITY_LOG_GP_ACTIVITIES_SET_FILTERS = 'ACTIVITY_LOG_GP_ACTIVITIES_SET_FILTERS';
export const ACTIVITY_LOG_GP_ACTIVITIES_REMOVE_DATE_RANGE_FILTER = 'ACTIVITY_LOG_GP_ACTIVITIES_REMOVE_DATE_RANGE_FILTER';
export const ACTIVITY_LOG_GP_ACTIVITIES_SET_USER_SAVED_VIEW = 'ACTIVITY_LOG_GP_ACTIVITIES_SET_USER_SAVED_VIEW';


// GP User Activities
export const ACTIVITY_LOG_GP_USER_ACTIVITIES_SET_DETAILS = 'ACTIVITY_LOG_GP_USER_ACTIVITIES_SET_DETAILS';
export const ACTIVITY_LOG_GP_USER_ACTIVITIES_SET_LOGS = 'ACTIVITY_LOG_GP_USER_ACTIVITIES_SET_LOGS';
export const ACTIVITY_LOG_GP_USER_ACTIVITIES_SET_CURRENT_PAGE = 'ACTIVITY_LOG_GP_USER_ACTIVITIES_SET_CURRENT_PAGE';
export const ACTIVITY_LOG_GP_USER_ACTIVITIES_SET_RESULTS_PER_PAGE = 'ACTIVITY_LOG_GP_USER_ACTIVITIES_SET_RESULTS_PER_PAGE';
export const ACTIVITY_LOG_GP_USER_ACTIVITIES_SET_SORT = 'ACTIVITY_LOG_GP_USER_ACTIVITIES_SET_SORT';
export const ACTIVITY_LOG_GP_USER_ACTIVITIES_SET_PAGE_ACTIONS = 'ACTIVITY_LOG_GP_USER_ACTIVITIES_SET_PAGE_ACTIONS';
export const ACTIVITY_LOG_GP_USER_ACTIVITIES_REMOVE_ALL_FILTERS = 'ACTIVITY_LOG_GP_USER_ACTIVITIES_REMOVE_ALL_FILTERS';
export const ACTIVITY_LOG_GP_USER_ACTIVITIES_REMOVE_FILTER_EVENT = 'ACTIVITY_LOG_GP_USER_ACTIVITIES_REMOVE_FILTER_EVENT';
export const ACTIVITY_LOG_GP_USER_ACTIVITIES_REMOVE_FILTER_PRODUCT = 'ACTIVITY_LOG_GP_USER_ACTIVITIES_REMOVE_FILTER_PRODUCT';
export const ACTIVITY_LOG_GP_USER_ACTIVITIES_REMOVE_FILTER_CATEGORY = 'ACTIVITY_LOG_GP_USER_ACTIVITIES_REMOVE_FILTER_CATEGORY';
export const ACTIVITY_LOG_GP_USER_ACTIVITIES_REMOVE_FILTER_STRATEGY = 'ACTIVITY_LOG_GP_USER_ACTIVITIES_REMOVE_FILTER_STRATEGY';
export const ACTIVITY_LOG_GP_USER_ACTIVITIES_SET_FILTERS = 'ACTIVITY_LOG_GP_USER_ACTIVITIES_SET_FILTERS';
export const ACTIVITY_LOG_GP_USER_ACTIVITIES_REMOVE_DATE_RANGE_FILTER = 'ACTIVITY_LOG_GP_USER_ACTIVITIES_REMOVE_DATE_RANGE_FILTER';
export const ACTIVITY_LOG_GP_USER_ACTIVITIES_SET_USER_SAVED_VIEW = 'ACTIVITY_LOG_GP_USER_ACTIVITIES_SET_USER_SAVED_VIEW';

// GP Fund Followings
export const ACTIVITY_LOG_GP_FUND_FOLLOWINGS_SET_DATA = 'ACTIVITY_LOG_GP_FUND_FOLLOWINGS_SET_DATA';
export const ACTIVITY_LOG_GP_FUND_FOLLOWINGS_SET_CURRENT_PAGE = 'ACTIVITY_LOG_GP_FUND_FOLLOWINGS_SET_CURRENT_PAGE';
export const ACTIVITY_LOG_GP_FUND_FOLLOWINGS_SET_RESULTS_PER_PAGE = 'ACTIVITY_LOG_GP_FUND_FOLLOWINGS_SET_RESULTS_PER_PAGE';
export const ACTIVITY_LOG_GP_FUND_FOLLOWINGS_SET_SORT = 'ACTIVITY_LOG_GP_FUND_FOLLOWINGS_SET_SORT';

// GP Prospects
export const ACTIVITY_LOG_GP_PROSPECTS_SET_CURRENT_PAGE = 'ACTIVITY_LOG_GP_PROSPECTS_SET_CURRENT_PAGE';
export const ACTIVITY_LOG_GP_PROSPECTS_SET_DATA = 'ACTIVITY_LOG_GP_PROSPECTS_SET_DATA';
export const ACTIVITY_LOG_GP_PROSPECTS_SET_FILTER_OPTIONS = 'ACTIVITY_LOG_GP_PROSPECTS_SET_FILTER_OPTIONS';
export const ACTIVITY_LOG_GP_PROSPECTS_SET_RESULTS_PER_PAGE = 'ACTIVITY_LOG_GP_PROSPECTS_SET_RESULTS_PER_PAGE';
export const ACTIVITY_LOG_GP_PROSPECTS_SET_SORT = 'ACTIVITY_LOG_GP_PROSPECTS_SET_SORT';
export const ACTIVITY_LOG_GP_PROSPECTS_SET_PAGE_ACTIONS = 'ACTIVITY_LOG_GP_PROSPECTS_SET_PAGE_ACTIONS';
export const ACTIVITY_LOG_GP_PROSPECTS_SET_SEARCH_TERMS = 'ACTIVITY_LOG_GP_PROSPECTS_SET_SEARCH_TERMS';
export const ACTIVITY_LOG_GP_PROSPECTS_SET_FILTERS = 'ACTIVITY_LOG_GP_PROSPECTS_SET_FILTERS';
export const ACTIVITY_LOG_GP_PROSPECTS_REMOVE_ALL_FILTERS = 'ACTIVITY_LOG_GP_PROSPECTS_REMOVE_ALL_FILTERS';
export const ACTIVITY_LOG_GP_PROSPECTS_REMOVE_FILTER_USER = 'ACTIVITY_LOG_GP_PROSPECTS_REMOVE_FILTER_USER';
export const ACTIVITY_LOG_GP_PROSPECTS_REMOVE_FILTER_EVENT = 'ACTIVITY_LOG_GP_PROSPECTS_REMOVE_FILTER_EVENT';
export const ACTIVITY_LOG_GP_PROSPECTS_REMOVE_FILTER_PRODUCT = 'ACTIVITY_LOG_GP_PROSPECTS_REMOVE_FILTER_PRODUCT';
export const ACTIVITY_LOG_GP_PROSPECTS_REMOVE_DATE_RANGE_FILTER = 'ACTIVITY_LOG_GP_PROSPECTS_REMOVE_DATE_RANGE_FILTER';
export const ACTIVITY_LOG_GP_PROSPECTS_SET_USERS_SEARCH_TERMS = 'ACTIVITY_LOG_GP_PROSPECTS_SET_USERS_SEARCH_TERMS';
export const ACTIVITY_LOG_GP_PROSPECTS_SET_FIRMS_SEARCH_TERMS = 'ACTIVITY_LOG_GP_PROSPECTS_SET_FIRMS_SEARCH_TERMS';
export const ACTIVITY_LOG_GP_PROSPECTS_REMOVE_FILTER_FIRM = 'ACTIVITY_LOG_GP_PROSPECTS_REMOVE_FILTER_FIRM';

// Advisor Activities
export const ACTIVITY_LOG_ADVISOR_ACTIVITIES_SET_DATA = 'ACTIVITY_LOG_ADVISOR_ACTIVITIES_SET_DATA';
export const ACTIVITY_LOG_ADVISOR_ACTIVITIES_SET_CURRENT_PAGE = 'ACTIVITY_LOG_ADVISOR_ACTIVITIES_SET_CURRENT_PAGE';
export const ACTIVITY_LOG_ADVISOR_ACTIVITIES_SET_RESULTS_PER_PAGE = 'ACTIVITY_LOG_ADVISOR_ACTIVITIES_SET_RESULTS_PER_PAGE';
export const ACTIVITY_LOG_ADVISOR_ACTIVITIES_SET_SORT = 'ACTIVITY_LOG_ADVISOR_ACTIVITIES_SET_SORT';
export const ACTIVITY_LOG_ADVISOR_ACTIVITIES_SET_PAGE_ACTIONS = 'ACTIVITY_LOG_ADVISOR_ACTIVITIES_SET_PAGE_ACTIONS';
export const ACTIVITY_LOG_ADVISOR_ACTIVITIES_REMOVE_ALL_FILTERS = 'ACTIVITY_LOG_ADVISOR_ACTIVITIES_REMOVE_ALL_FILTERS';
export const ACTIVITY_LOG_ADVISOR_ACTIVITIES_REMOVE_FILTER_EVENT = 'ACTIVITY_LOG_ADVISOR_ACTIVITIES_REMOVE_FILTER_EVENT';
export const ACTIVITY_LOG_ADVISOR_ACTIVITIES_REMOVE_FILTER_PRODUCT = 'ACTIVITY_LOG_ADVISOR_ACTIVITIES_REMOVE_FILTER_PRODUCT';
export const ACTIVITY_LOG_ADVISOR_ACTIVITIES_REMOVE_FILTER_CATEGORY = 'ACTIVITY_LOG_ADVISOR_ACTIVITIES_REMOVE_FILTER_CATEGORY';
export const ACTIVITY_LOG_ADVISOR_ACTIVITIES_REMOVE_FILTER_STRATEGY = 'ACTIVITY_LOG_ADVISOR_ACTIVITIES_REMOVE_FILTER_STRATEGY';
export const ACTIVITY_LOG_ADVISOR_ACTIVITIES_REMOVE_FILTER_COURSE_TYPE = 'ACTIVITY_LOG_ADVISOR_ACTIVITIES_REMOVE_FILTER_COURSE_TYPE';
export const ACTIVITY_LOG_ADVISOR_ACTIVITIES_SET_FILTERS = 'ACTIVITY_LOG_ADVISOR_ACTIVITIES_SET_FILTERS';
export const ACTIVITY_LOG_ADVISOR_ACTIVITIES_REMOVE_DATE_RANGE_FILTER = 'ACTIVITY_LOG_ADVISOR_ACTIVITIES_REMOVE_DATE_RANGE_FILTER';

export const ACTIVITY_LOG_ADVISOR_ACTIVITIES_SET_USER_SAVED_VIEW = 'ACTIVITY_LOG_ADVISOR_ACTIVITIES_SET_USER_SAVED_VIEW';

// Export History
export const EXPORT_HISTORY_SET_DATA = 'EXPORT_HISTORY_SET_DATA';
export const EXPORT_HISTORY_SET_SORT = 'EXPORT_HISTORY_SET_SORT';
export const EXPORT_HISTORY_SET_CURRENT_PAGE = 'EXPORT_HISTORY_SET_CURRENT_PAGE';
export const EXPORT_HISTORY_SET_RESULTS_PER_PAGE = 'EXPORT_HISTORY_SET_RESULTS_PER_PAGE';
export const EXPORT_HISTORY_SET_DATE_RANGE = 'EXPORT_HISTORY_SET_DATE_RANGE';
