import {
  DD_LOAD_DATA_SYNCS,
  DD_LOAD_DATA_SYNC,
  DD_EXECUTE_DATA_SYNC,
  DD_CANCEL_DATA_SYNC,
} from 'actions/actionsConstants';

import initialState from '../initialState';
import { updateState } from '../helpers';

const REQUEST_OBJECT = 'data_sync';

export default (state = initialState, action) => {
  switch (action.type) {
    case DD_LOAD_DATA_SYNCS:
      return updateState(state, action.payload, (data) => ({
        request_object: REQUEST_OBJECT,
        request_type: 'set_data_syncs_list',
        message: 'Fetched Data Syncs List.',
        data_syncs: {
          records: data.data_syncs,
          pagination: data.meta.pagination,
          responseId: Date.now(),
          white_label_partner_id: data.meta.white_label_partner_id,
          processor: data.meta.processor,
          user: data.meta.user,
          status: data.meta.status,
          filter_by_etl_process_text: data.meta.filter_by_etl_process_text,
          sorted_by_attribute: data.meta.sorted_by_attribute,
          sorted_by_orientation: data.meta.sorted_by_orientation,
          start_date: data.meta.period_start_date,
          end_date: data.meta.period_end_date,
          selected_period: data.meta.selected_period,
          selected_range: data.meta.selected_range,
          total_processes: data.meta.total_processes,
          failed_processes: data.meta.failed_processes,
          pass: data.meta.pass,
          pass_with_errors: data.meta.pass_with_errors,
          average_duration: data.meta.average_duration,
          total_rows: data.meta.total_rows,
          processed_rows: data.meta.processed_rows,
          failed_rows: data.meta.failed_rows,
        },
      }));
    case DD_LOAD_DATA_SYNC:
      return updateState(state, action.payload, (data) => ({
        request_object: REQUEST_OBJECT,
        request_type: 'set_data_sync_item',
        message: 'Fetched Data Sync Item.',
        data_sync: {
          ...state.data_sync,
          ...data,
        },
      }));
    case DD_EXECUTE_DATA_SYNC:
      return updateState(state, action.payload, (data) => ({
        error: data.error,
        request_object: REQUEST_OBJECT,
        request_type: 'execute_data_sync',
        message: `${data.error ? 'Failed' : 'Succeeded'} on executing Data Sync process`,
        data_syncs: {
          ...state.data_syncs,
          records: [data].concat(state.data_syncs.records),
          responseId: Date.now(),
        },
      }));
    case DD_CANCEL_DATA_SYNC:
      return updateState(state, action.payload, (data) => ({
        request_object: REQUEST_OBJECT,
        request_type: 'cancel_data_sync',
        message: 'Canceled Data Sync process successfully.',
        data_sync: {
          ...state.data_sync,
          ...data,
        },
      }));
    default:
      return state;
  }
};
