import update from 'immutability-helper';

import {
  RG_SET_SCHEDULE_EXECUTION_LOGS,
  RG_SET_SCHEDULE_EXECUTION_LOGS_COUNT,
  RG_UPDATE_SCHEDULE_EXECUTION_LOG,
} from 'actions/actionsConstants';

import initialState from '../initialState';

export default (state = initialState, action) => {
  switch (action.type) {
    case RG_SET_SCHEDULE_EXECUTION_LOGS:
      return update(state, {
        scheduleExecutionLogs: { $set: action.payload },
        loading: { $set: false },
      });
    case RG_SET_SCHEDULE_EXECUTION_LOGS_COUNT:
      return update(state, {
        scheduleExecutionLogsCount: { $set: action.payload },
      });
    case RG_UPDATE_SCHEDULE_EXECUTION_LOG: {
      const scheduleExecutionLog = action.payload;
      const index = state.scheduleExecutionLogs.findIndex(
        (log) => log.id === scheduleExecutionLog.id,
      );
      return update(state, {
        scheduleExecutionLogs: {
          [index]: { $set: scheduleExecutionLog },
        },
      });
    }
    default:
      return state;
  }
};
