import { actionFactory } from 'services/actions';

import {
  RGA_CHANGE_DATE_FILTER_RANGE,
  RGA_SAVE_FILTER_DATE,
  RGA_CLOSE_FIELD_FILTER,
} from 'actions/actionsConstants';
import configFieldActions from '../configFieldActions';

const saveFilter = actionFactory(RGA_SAVE_FILTER_DATE);
const closeFilter = actionFactory(RGA_CLOSE_FIELD_FILTER);

export const reportGeneratorConfigsChangeDateFilterRange = actionFactory(
  RGA_CHANGE_DATE_FILTER_RANGE,
);
export const reportGeneratorConfigsSaveFilterDate =
  (filter, fieldId) => (dispatch) => {
    dispatch(saveFilter(filter));
    dispatch(configFieldActions.handleSaveFilter(filter.filter, fieldId));
    dispatch(closeFilter(filter));
  };
