// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OneTimePopup-module__one_time_popup_content___IAGAo{max-height:80%;width:699px}`, "",{"version":3,"sources":["webpack://./icn_react/src/components/one_time_popup/OneTimePopup.module.scss"],"names":[],"mappings":"AAAA,qDACE,cAAA,CACA,WAAA","sourcesContent":[".one_time_popup_content {\n  max-height: 80%;\n  width: 699px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"one_time_popup_content": `OneTimePopup-module__one_time_popup_content___IAGAo`
};
export default ___CSS_LOADER_EXPORT___;
