const convertToKey = (str) => str.toLowerCase().replace(/\s+/g, '_').replace(/&/g, 'and');

// fix cases where a template variable may contain special characters
const htmlDecode = (input) => {
  const doc = new DOMParser().parseFromString(input, "text/html");
  return doc.documentElement.textContent;
};

// given a string split in half and join with new line
const splitJoinNewLine = (sentence, separator = null) => {
  const words = sentence.split(/\s/g);
  const middleIndex = Math.ceil(words.length / 2);
  const firstHalf = words.slice(0, middleIndex).join(' ');
  const secondHalf = words.slice(middleIndex).join(' ');
  return separator ? [firstHalf, secondHalf].join(`\n${separator}`) : [firstHalf, secondHalf].join('\n');
};

export { convertToKey, htmlDecode, splitJoinNewLine };
