import update from 'immutability-helper';
import {
  RP_PW_DISABLE_GENERATE_BUTTON,
  RP_PW_SET_MESSAGE_BANNER,
  RP_SET_WATERMARKED_FILES,
} from '../../actions/actionsConstants';

const initialState = {
  disablePPMGen: true,
  message: undefined,
  messageType: undefined,
  documents: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case RP_PW_DISABLE_GENERATE_BUTTON:
      return update(state, { disablePPMGen: { $set: action.payload } });
    case RP_PW_SET_MESSAGE_BANNER:
      return update(state, {
        message: { $set: action.payload.message },
        messageType: { $set: action.payload.messageType },
      });
    case RP_SET_WATERMARKED_FILES:
      return update(state, { documents: { $set: action.payload } });
    default:
      return state;
  }
};
