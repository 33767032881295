/* eslint-disable max-statements */
import { getRequest } from 'actions/utils';

import {
  getMostRecentStatementInSelectedCurrency,
  getSelectedCurrencyCode,
  getDataSetsLoaded,
  canSeeInvestmentDashboardTransactions,
} from 'reducers/investment_dashboard_reducers/selectors';
import { filterProps } from 'hooks/investment_dasboard/useTransactionsFilter';

import { idSetGeneralLedgerTransactionsData, idSetAllTransactions, idUpdateDataSetsLoaded } from './helpers/constants';

const idFetchGeneralLedgerTransactions = (ids) => (
  (dispatch, getState) => {
    const state = getState();
    const effectiveDate = getMostRecentStatementInSelectedCurrency(state).quarter_end_date;
    const selectedCurrency = getSelectedCurrencyCode(state);

    const url = 'investment_dashboard/general_ledger_transactions';
    const params = {
      pcap_date: effectiveDate,
      selected_currency: selectedCurrency,
      ...ids,
    };

    return dispatch(getRequest({
      url,
      params,
      onSuccess: idSetGeneralLedgerTransactionsData,
    }));
  }
);

const idFetchGeneralLedgerTransactionsList = (params) => (
  getRequest({
    url: 'investment_dashboard/general_ledger_transactions/list_transactions',
    params: {
      ...filterProps(),
      ...params,
    },
    onSuccess: idSetAllTransactions,
  })
);

const idLoadGeneralLedgerTransactionsSet = (ids) => (
  (dispatch, getState) => {
    const state = getState();
    const dataSetsLoaded = getDataSetsLoaded(state);
    const canSeeIDTransactions = canSeeInvestmentDashboardTransactions(state);

    if (dataSetsLoaded.transactionsDataSet) return;

    dispatch(idFetchGeneralLedgerTransactions(ids));

    if (canSeeIDTransactions) {
      dispatch(idFetchGeneralLedgerTransactionsList(ids));
    }

    dispatch(idUpdateDataSetsLoaded({ transactionsDataSet: true }));
  }
);

export {
  idFetchGeneralLedgerTransactions,
  idFetchGeneralLedgerTransactionsList,
  idLoadGeneralLedgerTransactionsSet,
};
