import whiteLabelPartnerReducer from './whiteLabelPartnerReducer';
import providerReducer from './providerReducer';
import fileReducer from './fileReducer';
import dataSyncReducer from './dataSyncReducer';
import dataSyncFileReducer from './dataSyncFileReducer';
import dataSyncFileReportReducer from './dataSyncFileReportReducer';
import dataSyncSchemaReducer from './dataSyncSchemaReducer';
import transformationReducer from './transformationReducer';

export default {
  whiteLabelPartnerReducer,
  providerReducer,
  fileReducer,
  dataSyncReducer,
  dataSyncFileReducer,
  dataSyncFileReportReducer,
  dataSyncSchemaReducer,
  transformationReducer,
};
