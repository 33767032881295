import _ from 'lodash';
import { createSelector } from 'reselect';

import { getFilterTypeFromFilterConfig } from 'reducers/report_generator_reducers/utils';
import initialState from './initialState';
import { formatDataSet } from '../../containers/report_generator/view/utils';
import { BASE_QUERY_TYPE } from '../../containers/report_generator/view/constants';

const rootState = (state) => state.reportGeneratorConfigs;

// Worker selectors
export const getWorkerId = (state) => rootState(state).workerId;
export const getWorkerStatus = (state) =>
  rootState(state).workerStatus !== null ? rootState(state).workerStatus : '';
export const getTotalProgress = (state) => rootState(state).total;
export const getCurrentProgress = (state) => rootState(state).at;

// Filter selectors
export const getRGNameFilter = (state) => rootState(state).nameFilter || initialState.nameFilter;
export const getRGBaseQueryFilter = (state) => rootState(state).baseQueryFilter || initialState.baseQueryFilter;
export const getRGDeliveryFilter = (state) => rootState(state).deliveryFilter || initialState.deliveryFilter;
// Index page selectors
export const getConfigs = (state) => state.reportGeneratorConfigs.configs;
export const getConfigsCount = (state) =>
  state.reportGeneratorConfigs.configs_count;
export const getResultsPerPage = (state) =>
  state.reportGeneratorConfigs.results_per_page;
export const getCurrentPage = (state) =>
  state.reportGeneratorConfigs.current_page;
export const getSelectedConfigs = (state) =>
  state.reportGeneratorConfigs.selected_configs;
export const getSortingColumn = (state) =>
  state.reportGeneratorConfigs.sorting_column;
export const getSortingOrder = (state) =>
  state.reportGeneratorConfigs.sorting_order;
export const getActiveModal = (state) =>
  state.reportGeneratorConfigs.active_modal;
export const getSelectedRow = (state) =>
  state.reportGeneratorConfigs.selected_row;
export const getSharedUsers = (state) =>
  state.reportGeneratorConfigs.config_shared_users;
export const getSharedUsersCount = (state) =>
  state.reportGeneratorConfigs.config_shared_users_count;

export const getFormattedConfigs = createSelector([getConfigs], (configs) =>
  configs.map((el) => ({
    id: el.id,
    lockedConfigId: el.locked_config_id,
    name: el.name,
    fields: el.fields,
    created_at: el.created_at,
    updated_at: el.updated_at,
    sharedConfig: el.shared_config,
    owner: el.owner,
    shared_users_count: el.shared_users_count,
    delivery_enabled: el.delivery_enabled,
    configurableType: el.configurable_type,
    configurableName: el.configurable_name,
    dataSetName: el.data_set_name,
    validConfig: el.valid_config,
    approvalStatus: el.approval_status,
    emailRecipients: el.email_recipients,
    sftpAccount: el.sftp_account,
    scheduleTime: el.schedule_time,
    sftpUploadPath: el.sftp_upload_path,
    job_status: el.scheduled_job_status,
    delivery_approval_status: el.scheduled_delivery_approval_status,
  })),
);

// Report data visualization selectors
export const getReportData = (state) => rootState(state).reportData;
export const getReportDataTotalRows = (state) =>
  rootState(state).reportDataTotalRows;
export const getReportExecutedQuery = (state) =>
  rootState(state).reportExecutedQuery;
export const getReportDataTableColumnMetadata = (state) =>
  rootState(state).reportDataTableColumnMetadata;
export const getReportDataFetchStatus = (state) =>
  rootState(state).reportDataFetchInProgress;

export const makeGetReportDataSelectedFields = () =>
  createSelector(
    [getAvailableFields, getSelectedFields],
    (availableFields, selectedFields) => {
      const result = [];
      let method_name;
      if (!_.isEmpty(selectedFields)) {
        const filter_methods = selectedFields;
        filter_methods.forEach((filter_method) => {
          Object.keys(availableFields).forEach((fieldGroupName) => {
            Object.keys(availableFields[fieldGroupName]).forEach(
              (fieldName) => {
                method_name = availableFields[fieldGroupName][fieldName].method;
                if (filter_method === method_name) {
                  result.push(availableFields[fieldGroupName][fieldName]);
                }
              },
            );
          });
        });
      }
      return result;
    },
  );

// Schedule page selectors
export const getScheduleType = (state) => rootState(state).schedule.type;
export const getScheduleInterval = (state) =>
  rootState(state).schedule.interval;
export const getScheduleDatesOfYear = (state) =>
  rootState(state).schedule.dates_of_year;
export const getScheduleDaysOfMonth = (state) =>
  rootState(state).schedule.days_of_month;
export const getScheduleExpiryDuration = (state) =>
  rootState(state).schedule.expiry_duration;
export const getScheduleEnabled = (state) => rootState(state).schedule.enabled;
export const getScheduleApprovalStatus = (state) =>
  rootState(state).schedule.approval_status;
export const getScheduleApprovalStatusText = (state) =>
  rootState(state).schedule.approval_status_text;
export const getScheduleRejectionReason = (state) =>
  rootState(state).schedule.rejection_reason;
export const getScheduleTime = (state) => rootState(state).schedule.time;
export const getScheduleDays = (state) => rootState(state).schedule.days;
export const getScheduleEmailRecipients = (state) =>
  rootState(state).schedule.email_recipients || initialState.email_recipients;
export const getScheduleEmailSubject = (state) =>
  rootState(state).schedule.email_subject || initialState.email_subject;
export const getScheduleEmailContent = (state) =>
  rootState(state).schedule.email_content || initialState.email_content;
export const getSchedulingTypes = (state) =>
  rootState(state).schedulingTypes || initialState.schedulingTypes;
export const getSftpAccountId = (state) =>
  rootState(state).schedule.sftpAccountId;
export const getSftpUploadPath = (state) =>
  rootState(state).schedule.sftpUploadPath;
export const getSftpAccounts = (state) =>
  rootState(state).sftpAccounts || initialState.sftpAccounts;
export const getForInternalReporting = (state) =>
  rootState(state).schedule.forInternalReporting;

// Edit page selectors
const _getFilter = (state, fieldName) => fieldName;
const _isActiveFilter = (state, fieldName) =>
  rootState(state).active_filter === fieldName;
const _getAppliedRangesForFilter = (state, fieldName) =>
  rootState(state).appliedRanges[getConfigurableId(state)][fieldName];
const _getFilteredOptions = (state, fieldName) =>
  rootState(state).filteredOptions[getConfigurableId(state)] &&
  rootState(state).filteredOptions[getConfigurableId(state)][fieldName];
const _getfilteredOptionsPicked = (state, fieldName) =>
  rootState(state).filteredOptionsPicked[getConfigurableId(state)] &&
  rootState(state).filteredOptionsPicked[getConfigurableId(state)][fieldName];

const _getQueryTotalsForFilter = (state, fieldName) => {
  const configurableId = getConfigurableId(state);
  return (
    (!_.isEmpty(rootState(state).query_totals) &&
      rootState(state).query_totals[configurableId][fieldName]) ||
    undefined
  );
};
const _getOptionsForFilter = (state, fieldName) =>
  rootState(state).options[getConfigurableId(state)][fieldName];
const _getRequestForFilter = (state, fieldName) =>
  rootState(state).requests[getConfigurableId(state)][fieldName];
const _getQueryForFilter = (state, fieldName) =>
  rootState(state).queries[getConfigurableId(state)][fieldName];
const _getFilteredRequestForFilter = (state, fieldName) =>
  rootState(state).filteredRequests[getConfigurableId(state)][fieldName];
const _getFilteredQueries = (state, fieldName) =>
  rootState(state).filteredQueries[getConfigurableId(state)][fieldName];
const _getStoredQueriesForFilter = (state, fieldName) =>
  rootState(state).stored_queries[getConfigurableId(state)][fieldName];
const _getTotalsForFilter = (state, fieldName) =>
  rootState(state).totals[getConfigurableId(state)] &&
  rootState(state).totals[getConfigurableId(state)][fieldName];
const _getExcludedRangesForFilter = (state, fieldName) =>
  rootState(state).excludedRanges[getConfigurableId(state)][fieldName];
const _getFilteredTotalsForFilter = (state, fieldName) =>
  rootState(state).filteredTotals[getConfigurableId(state)][fieldName];
const _getFilteredSubTotals = (state, fieldName) =>
  rootState(state).filteredSubTotals[getConfigurableId(state)][fieldName];
const _getNumericFilterType = (state, fieldName) =>
  rootState(state).appliedFilterType[getConfigurableId(state)][fieldName] ||
  getFilterTypeFromFilterConfig(_getStoredQueriesForFilter(state, fieldName));
const _getNumericFilterRangeFrom = (state, fieldName) => {
  const ranges = getAppliedRanges(state)[fieldName];
  return ranges && ranges.length > 0 ? ranges[0][0] : '';
};
const _getNumericFilterRangeTo = (state, fieldName) => {
  const ranges = getAppliedRanges(state)[fieldName];
  return ranges && ranges.length > 0 ? ranges[0][1] : '';
};
const _getDateFilterType = (state, fieldName) =>
  rootState(state).options[getConfigurableId(state)][fieldName] &&
  rootState(state).options[getConfigurableId(state)][fieldName].relative_date;
const _getRelativeDateFromDuration = (state, fieldName) =>
  (rootState(state).options[getConfigurableId(state)][fieldName] &&
    rootState(state).options[getConfigurableId(state)][fieldName]
      .fromDuration) ||
  '';
const _getRelativeDateToDuration = (state, fieldName) =>
  (rootState(state).options[getConfigurableId(state)][fieldName] &&
    rootState(state).options[getConfigurableId(state)][fieldName].toDuration) ||
  '';
const _getNullOptions = (state, fieldName) =>
  rootState(state).nullOptions[getConfigurableId(state)] &&
  rootState(state).nullOptions[getConfigurableId(state)][fieldName];
const _getDisableSaveForFilter = (state, fieldName) =>
  rootState(state).disableSave[getConfigurableId(state)] &&
  rootState(state).disableSave[getConfigurableId(state)][fieldName];

export const getFilteredQueries = (state) => rootState(state).filteredQueries;
export const getFilteredOptionsPicked = (state) =>
  rootState(state).filteredOptionsPicked[getConfigurableId(state)];
export const getAppliedRanges = (state) =>
  rootState(state).appliedRanges[getConfigurableId(state)];
export const getFilteredOptions = (state) =>
  rootState(state).filteredOptions[getConfigurableId(state)];

export const getCurrentConfig = (state) => rootState(state).current_configs;
export const getFileName = (state) => rootState(state).current_configs.name;
export const getSelectedFieldMetadata = (state, field) =>
  rootState(state).selectedFieldMetadata[getConfigurableId(state)] &&
  rootState(state).selectedFieldMetadata[getConfigurableId(state)][field];
export const getConfigDescription = (state) =>
  rootState(state).current_configs.description;
export const getConfigDatabaseName = (state) =>
  rootState(state).current_configs.database_name;
export const getIsSharedConfig = (state) =>
  rootState(state).current_configs.shared_config;
export const getConfigUser = (state) =>
  rootState(state).current_configs.current_user || {};
export const getOpenConfigId = (state) =>
  rootState(state).current_configs.open_config_id;
export const getConfigConfigurableName = (state) =>
  rootState(state).current_configs.configurable_name;
export const getConfigFileAttributes = (state) =>
  rootState(state).fileAttributes;
export const getConfigFileFormat = createSelector(
  [getConfigFileAttributes],
  (fileAttributes) => fileAttributes?.file_format,
);
export const getLockedScheduleTime = (state) =>
  rootState(state).current_configs.schedule_time;
export const getLockedScheduleSftpAccount = (state) =>
  rootState(state).current_configs.sftp_account;
export const getLockedScheduleSftpUploadPath = (state) =>
  rootState(state).current_configs.sftp_upload_path;
export const getLockedScheduleEmailRecipients = (state) =>
  rootState(state).current_configs.email_recipients;
export const getLockedConfigId = (state) =>
  rootState(state).current_configs.locked_config_id;
export const getFieldAlias = (state, fieldId) =>
  rootState(state).configFields.find(({ id }) => fieldId === id)?.field_alias;
export const getFieldFormat = (state, fieldId) =>
  rootState(state).configFields.find(({ id }) => fieldId === id)?.field_format;

export const getSelectedFields = (state) =>
  rootState(state).selectedFields === undefined
    ? []
    : rootState(state).selectedFields[getConfigurableId(state)];

export const getAvailableFields = (state) =>
  rootState(state).available_fields[getConfigurableId(state)] || {};
export const getFrequentlyUsedFields = (state) =>
  rootState(state).frequently_used_fields[getConfigurableId(state)] || {};
export const getStoredExcludedQueries = (state) =>
  rootState(state).storedExcludedQueries[getConfigurableId(state)];
export const getStoredQueries = (state) =>
  rootState(state).stored_queries[getConfigurableId(state)];
export const getReportConfigFields = (state) => rootState(state).configFields;

export const getCurrentConfigId = (state) =>
  rootState(state).current_configs.id;
export const getBaseQueryModels = (state) =>
  rootState(state).report_generator_base_queries;
export const getConfigurableId = (state) => rootState(state).configurableId;
export const getTemporaryConfigurableId = (state) =>
  rootState(state).temporaryConfigurableId;
export const getConfigurableType = (state) => rootState(state).configurableType;
export const getTemporaryConfigurableType = (state) =>
  rootState(state).temporaryConfigurableType;
export const getCurrentConfigValidConfig = (state) =>
  rootState(state).current_configs.valid_config;
export const getBaseQuery = (state) =>
  rootState(state).current_configs.report_generator_base_query;
export const getCustomQuery = (state) =>
  rootState(state).current_configs.report_generator_custom_query;

// custom query
export const getCustomQueries = (state) =>
  rootState(state).reportGeneratorCustomQueries;
export const getCreateCustomQuery = (state) =>
  rootState(state).createCustomQuery;

// additional settings
export const getFailedScheduleNotificationRecipients = (state) =>
  rootState(state).failedScheduleNotificationRecipients;

export const selectIfActiveFilter = createSelector(
  [_isActiveFilter],
  (isActive) => isActive,
);
export const getAppliedRangesForFilter = createSelector(
  [_getAppliedRangesForFilter],
  (ranges) => ranges,
);
export const getFilteredOptionsForFilter = createSelector(
  [_getFilteredOptions],
  (options) => options,
);
export const getQueryTotalsForFilter = createSelector(
  [_getQueryTotalsForFilter],
  (total) => total || 0,
);
export const getOptionsForFilter = createSelector(
  [_getOptionsForFilter],
  (options) => options || initialState.options,
);
export const getRequestForFilter = createSelector(
  [_getRequestForFilter],
  (request) => request || false,
);
export const getQueryForFilter = createSelector(
  [_getQueryForFilter],
  (query) => query,
);
export const getExcludedRangesForFilter = createSelector(
  [_getExcludedRangesForFilter],
  (ranges) => ranges,
);
export const getFilteredTotalsForFilter = createSelector(
  [_getFilteredTotalsForFilter],
  (total) => total || 0,
);
export const getFilteredSubTotals = createSelector(
  [_getFilteredSubTotals],
  (total) => total || 0,
);
export const getFilteredRequestForFilter = createSelector(
  [_getFilteredRequestForFilter],
  (request) => request || false,
);

export const makeGetFilteredRequestForFilter = () =>
  createSelector([_getFilteredRequestForFilter], (request) => request || false);
export const makeGetRequestForFilter = () =>
  createSelector([_getRequestForFilter], (request) => request || false);
export const makeGetFilteredQueryForFilter = () =>
  createSelector([_getFilteredQueries], (query) => query);
export const makeGetQueryForFilter = () =>
  createSelector([_getQueryForFilter], (query) => query);
export const makeGetTotalsForFilter = () =>
  createSelector([_getTotalsForFilter], (total) => total || 0);
export const makeGetOptionsForFilter = () =>
  createSelector([_getOptionsForFilter], (options) => options);
export const makeGetNumericFilterType = () =>
  createSelector([_getNumericFilterType], (type) => type);
export const makeGetNumericFilterRangeFrom = () =>
  createSelector([_getNumericFilterRangeFrom], (from) => from);
export const makeGetNumericFilterRangeTo = () =>
  createSelector([_getNumericFilterRangeTo], (to) => to);
export const makeGetDateFilterType = () =>
  createSelector([_getDateFilterType], (dateType) => dateType);
export const makeGetRelativeDateFromDuration = () =>
  createSelector([_getRelativeDateFromDuration], (duration) => duration);
export const makeGetRelativeDateToDuration = () =>
  createSelector([_getRelativeDateToDuration], (duration) => duration);
export const makeDisableSaveForFilter = () =>
  createSelector([_getDisableSaveForFilter], (disableSave) => disableSave);

export const makeGetFilteredOptions = () =>
  createSelector([_getFilteredOptions], (options = []) =>
    options.filter(({ name }) => name !== ''),
  );
export const makeBlankOptionChecked = () =>
  createSelector([_getFilteredOptions], (options = []) =>
    options.some(({ name }) => name === ''),
  );
export const makeGetDividedOptionsFor = () =>
  createSelector([_getOptionsForFilter], (options = []) =>
    options.filter(({ name }) => name !== ''),
  );

export const makeGetStoredQueriesForFilter = () =>
  createSelector([_getStoredQueriesForFilter], (queries) => queries || {});

export const makeGetTransformDataForFilter = () =>
  createSelector([getAvailableFields, _getFilter], (fields, filter) => {
    let options = [];

    Object.keys(fields).some((key) =>
      Object.keys(fields[key]).some((item) => {
        if (fields[key][item].name === filter) {
          options = fields[key][item].values;
          return true;
        }
        return false;
      }),
    );

    return options;
  });

export const getNullOption = () =>
  createSelector([_getNullOptions], (nullSelection) => nullSelection);

export const getRgSnowflakeConfigs = (state) =>
  rootState(state).snowflakeConfigs;
export const getRgSnowflakeClients = (state) =>
  rootState(state).snowflakeClients;
export const getSnowflakeClientId = (state) =>
  rootState(state).schedule.snowflakeClientId;
export const getSnowflakeTableName = (state) =>
  rootState(state).schedule.snowflakeTableName;
export const getBaseQueryFieldsOptions = (state) => {
  const options = new Set();
  getBaseQueryModels(state).forEach((baseQuery) => {
    baseQuery.fields.forEach((item) => options.add(item));
  });
  return Array.from(options);
};
export const getDataSetsOptions = (state) =>
  formatDataSet(getBaseQueryModels(state), BASE_QUERY_TYPE);
export const getError = (state) => state.reportGeneratorConfigs.error;
export const getConfigLoaded = (state) =>
  state.reportGeneratorConfigs.configLoaded;
