import React, { useMemo } from 'react';
import { string, arrayOf, oneOfType } from 'prop-types';
import _ from 'lodash';
import FilterOption from 'prop_types/shared/filter_options';
import NameItem from 'prop_types/shared/private_access_fund';
import InfoIcon from 'images/information-icon.svg';
import IcnTooltipOverlayTrigger from 'components/shared/icn_tooltip_overlay_trigger/IcnTooltipOverlayTrigger';
import IcnSimpleListTooltip from 'components/shared/icn_tooltips/icn_simple_list/IcnSimpleListTooltip';
import styles from './MultiItemRenderer.module.scss';

const MultiItemRenderer = ({
  value, itemLabel, fallbackLabel, toolTipLabel, noItemLabel,
}) => {
  const collection = useMemo(() => _.map(value, (val) => val[itemLabel]), [itemLabel, value]);

  const renderItems = () => {
    if (value.length > 1) {
      return (
        <span>
          {fallbackLabel}
          &nbsp;
          <span className={styles.counter}>
            (
            {value.length}
            )
          </span>
        </span>
      );
    }
    if (value.length === 0) {
      return noItemLabel;
    }
    return value[0][itemLabel];
  };

  const infoButton = () => {
    if (value.length <= 1) {
      return '';
    }
    return (
      <div className={styles.info}>
        <IcnTooltipOverlayTrigger
          popOverAccessibilityId={toolTipLabel}
          popOverContent={<IcnSimpleListTooltip title={toolTipLabel} collection={collection} />}
        >
          <img src={InfoIcon} alt="Info Icon" />
        </IcnTooltipOverlayTrigger>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      {renderItems()}
      {infoButton()}
    </div>
  );
};

MultiItemRenderer.propTypes = {
  itemLabel: string.isRequired,
  fallbackLabel: string.isRequired,
  toolTipLabel: string.isRequired,
  noItemLabel: string,
  value: arrayOf(oneOfType([NameItem, FilterOption])).isRequired,
};

MultiItemRenderer.defaultProps = {
  noItemLabel: '-',
};

export default MultiItemRenderer;
