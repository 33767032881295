import update from 'immutability-helper';
import { MASS_MAILER_DOCUMENTS } from '../actions';
import { MASS_MAILER_DOCUMENTS_LIST } from './actions';

export const initialState = {
  isLoading: true,
  mass_mailer_documents: [],
  total: 0,
  page: 1,
  per_page: 25,
};

const massMailerListReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case MASS_MAILER_DOCUMENTS.LIST.REQUEST:
      return update(state, { isLoading: { $set: true } });
    case MASS_MAILER_DOCUMENTS.LIST.SUCCESS:
      return update(state, {
        isLoading: { $set: false },
        mass_mailer_documents: { $set: payload.response.mass_mailer_documents },
        total: { $set: payload.response.total },
        page: { $set: payload.response.page },
        per_page: { $set: payload.response.per_page },
      });
    case MASS_MAILER_DOCUMENTS.LIST.FAILURE:
      return update(state, { isLoading: { $set: false } });
    case MASS_MAILER_DOCUMENTS_LIST.UNMOUNT:
      return initialState;
    default:
      return state;
  }
};

export default massMailerListReducer;
