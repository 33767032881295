/* eslint-disable max-lines-per-function */
import React from 'react';
import { shape, func } from 'prop-types';
import {
  InputAdornment,
  NumericFormatAdapter,
  TextField,
  Typography,
} from '@icapitalnetwork/supernova-core';
import { totalAssets } from '../profileData';

const TotalAssets = ({ profileData, updateStore, config }) => {
  const updateTotalAssets = ({ target: { value } }) => updateStore({ [totalAssets.question_key]: value });

  return (
    <NumericFormatAdapter
      thousandSeparator
      valueIsNumericString
      decimalScale={0}
      allowNegative={false}
    >
      <TextField
        fullWidth
        id={totalAssets.question_key}
        label={totalAssets.text}
        value={profileData[totalAssets.question_key]}
        onChange={updateTotalAssets}
        disabled={config?.readOnly}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Typography variant="smallParagraph" color="text.lighter">
                USD
              </Typography>
            </InputAdornment>
          ),
        }}
      />
    </NumericFormatAdapter>
  );
};

TotalAssets.propTypes = {
  profileData: shape({}).isRequired,
  updateStore: func.isRequired,
  config: shape({}),
};

TotalAssets.defaultProps = {
  config: undefined,
};

export default TotalAssets;
