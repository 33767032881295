import React from 'react';
import { string } from 'prop-types';
import Highlighter from 'react-highlight-words';
import { wordsToHighlight } from 'containers/admin_search/services/textFormatter';
import styles from './HighlightedSearchText.module.scss';

function HighlightedSearchText({
  value,
  searchKeyword,
}) {
  return (
    <Highlighter
      highlightClassName={styles.highlight_class}
      searchWords={wordsToHighlight(searchKeyword)}
      autoEscape
      textToHighlight={value}
    />
  );
}

HighlightedSearchText.propTypes = {
  value: string.isRequired,
  searchKeyword: string.isRequired,
};

export default HighlightedSearchText;
