export const NO_REVIEWER_ID = 'NO_REVIEWER_ID';

export const STATUSES = [
  {
    id: 'reverted',
    name: 'Attention Required',
    index: 0,
  },
  {
    id: 'pre_approval',
    name: 'Pre-Approval',
    index: 2,
  },
  {
    id: 'pre_approval_secondary',
    name: 'Pre-Approval 2',
    index: 3,
  },
  {
    id: 'pending_review',
    name: 'In Process',
    index: 4,
  },
  {
    id: 'external_review',
    name: 'External Review',
    index: 5,
  },
  {
    id: 'external_secondary_review',
    name: 'External Review 2',
    index: 6,
  },
  {
    id: 'ir_review',
    name: 'Document Review',
    index: 7,
  },
  {
    id: 'compliance_review',
    name: 'Legal Review',
    index: 8,
  },
  {
    id: 'approved',
    name: 'Approved',
    index: 9,
  },
  {
    id: 'accepted',
    name: 'Accepted',
    index: 10,
  },
];

export const ACCOUNT_NAME_SEARCH = Object.freeze({
  id: 'account_name',
  i18nPlaceholderKey: 'subscriptions_dashboard:search_labels.account_name',
  i18nLabelKey: 'subscriptions_dashboard:search_types.account_name',
});

export const ACCOUNT_NUMBER_SEARCH = Object.freeze({
  id: 'account_number',
  i18nPlaceholderKey: 'subscriptions_dashboard:search_labels.account_number',
  i18nLabelKey: 'subscriptions_dashboard:search_types.account_number',
});

export const SEARCH_TYPES = Object.freeze([ACCOUNT_NAME_SEARCH, ACCOUNT_NUMBER_SEARCH]);
