import {
  SR_SET_SELECTED_FUNDS,
  SR_SELECTED_RIAS_FIRMS,
  SR_SELECTED_CONTACTS,
  SR_SET_RESULTS_PER_PAGE,
  SR_SET_CURRENT_PAGE,
  SR_SET_SORTING,
} from 'actions/actionsConstants';

import update from 'immutability-helper';

import { normalizeCurrentPage } from '../utils';
import initialState from '../initialState';

export default (state = initialState, action) => {
  switch (action.type) {
    case SR_SET_SELECTED_FUNDS:
      return update(state, {
        selected_funds: { $set: action.payload },
        current_page: { $set: normalizeCurrentPage(1, state) },
      });
    case SR_SELECTED_RIAS_FIRMS:
      return update(state, {
        selected_rias_firms: { $set: action.payload },
        current_page: { $set: normalizeCurrentPage(1, state) },
      });
    case SR_SELECTED_CONTACTS:
      return update(state, {
        selected_contacts: { $set: action.payload.contacts },
        show_no_reviewers: { $set: action.payload.showNoReviewers },
        current_page: { $set: normalizeCurrentPage(1, state) },
      });
    case SR_SET_RESULTS_PER_PAGE:
      return update(state, {
        results_per_page: { $set: action.payload },
        current_page: { $set: initialState.current_page },
      });
    case SR_SET_CURRENT_PAGE:
      return update(state, {
        current_page: { $set: normalizeCurrentPage(action.payload, state) },
      });
    case SR_SET_SORTING:
      return update(state, {
        sorting_column: { $set: action.payload.sortingColumn },
        sorting_order: { $set: action.payload.sortingOrder },
      });
    default:
      return state;
  }
};
