import {
  shape, number, string, arrayOf,
} from 'prop-types';

const user = shape({
  domain: string,
  email: string,
  icn_id: number,
  wlp_id: number,
  wlp_logo_url: string,
  wlp_name: string,
  wlp_passport_type: string,
  wlp_primary_color: string,
});

const users = arrayOf(user).isRequired;

export { user, users };
