import initialState from './initialState';

const initialPayload = {
  response: {},
  status: 0,
  statusText: '',
};

export const updateState = (state = initialState, payload = initialPayload, actionResult = () => { }) => {
  const nextState = actionResult(payload);
  return { ...state, ...nextState };
};
