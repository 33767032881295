import _ from 'lodash';
import { isTrustEntity } from 'services/investor_profile';

const profileCountriesState = (all, viewable, pafWhitelisted, isRia, pafExceptionApplied) => {
  const countries = {};

  countries.all = all;
  if (isRia && pafExceptionApplied) {
    countries.paf_whitelisted = all;
    countries.viewable = all;
  } else {
    countries.viewable = viewable;
    countries.paf_whitelisted = pafWhitelisted;
  }
  countries.lock_country_fields = _.isEmpty(countries.paf_whitelisted);

  return countries;
};

const conditionalCountriesState = (profile, viewable, pafWhitelisted, lockCountryFields) => {
  const countries = {};

  if (isTrustEntity(profile)) {
    countries.entity_permanent_address = pafWhitelisted;
    countries.lock_permanent_address_country_field = lockCountryFields;
    countries.domicile = viewable;
    countries.lock_domicile_country_field = false;
  } else {
    countries.entity_permanent_address = viewable;
    countries.lock_permanent_address_country_field = false;
    countries.domicile = pafWhitelisted;
    countries.lock_domicile_country_field = lockCountryFields;
  }

  return countries;
};

export {
  profileCountriesState,
  conditionalCountriesState,
};
