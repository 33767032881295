import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

const initialState = {
  response: {},
  errors: {},
  isFetching: false,
};

export const { actions, reducer } = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setResponse: (state, action) => {
      state.response = action.payload.investment;
      state.errors = action.payload.errors;
    },
    setIsFetching: (state, action) => {
      state.isFetching = action.payload;
    },
    setErrors: (state, action) => {
      state.response = initialState.response;
      state.errors = action.payload;
    },
  }
});
