import update from 'immutability-helper';
import {
  ED_SET_COURSE_FORM_DATA,
  ED_SET_FIRMS_FILTER_VALUES,
} from '../actions/constants';
import initialState from './initialState';

export default (state = initialState, action) => {
  switch (action.type) {
    case ED_SET_COURSE_FORM_DATA: {
      return update(state, {
        courseFormData: {
          data: { $set: action.payload.data },
          filterValues: { $set: action.payload.filters },
        },
      });
    }
    case ED_SET_FIRMS_FILTER_VALUES: {
      return update(state, {
        courseFormData: {
          filterValues: {
            firms: { $set: action.payload },
          },
        },
      });
    }
    default:
      return state;
  }
};
