import executeRequest from './helpers/executions';
import { scopedPartnerUrl, dataReconFilesUrl } from './helpers/treatment';
import {
  drLoadDataRecons,
  drLoadDataReconFiles,
  drLoadDataReconsStatistics,
  drCreateReconciliation,
  drCreateBulkReconciliation,
  drRetryReconciliation,
  drRetryBulkReconciliation,
  drUploadReconciliation,
  drUploadBulkReconciliation,
  drApproveReconciliation,
  drApproveBulkReconciliation,
  drStopReconciliation,
  drStopBulkReconciliation,
  drLoadPrivateAccessFunds,
  drLoadFundAdminGroups,
  drLoadReconciliationTypes,
  drLoadReconciliationPeriods,
  drLoadFundAdmins,
  drGetSettings,
  drSaveSettings,
} from './helpers/constants';

const loadDataRecons = (params = {}, options = {}) => {
  const url = scopedPartnerUrl(params);

  return executeRequest(url, 'GET', drLoadDataRecons, { params, options });
};

const loadDataReconFiles = (params = {}, options = {}) => {
  const url = dataReconFilesUrl(params);

  return executeRequest(url, 'GET', drLoadDataReconFiles, { params, options });
};

const loadDataReconsStatistics = (params = {}, options = {}) => {
  const url = '/statistics';

  return executeRequest(url, 'GET', drLoadDataReconsStatistics, { params, options });
};

const createDataRecon = (params = {}, options = {}) => {
  const url = '/';
  const data = { data_recon: params };

  return executeRequest(url, 'POST', drCreateReconciliation, { data, options });
};

const createBulkDataRecon = (params = {}, options = {}) => {
  const url = '/bulk_create';
  const data = { data_recon: params };

  return executeRequest(url, 'PUT', drCreateBulkReconciliation, { data, options });
};

const retryDataRecon = (params = {}, options = {}) => {
  const url = `/${params.id}/restart`;
  const data = { data_recon: params };

  return executeRequest(url, 'PUT', drRetryReconciliation, { data, options });
};

const retryBulkDataRecon = (params = {}, options = {}) => {
  const url = '/bulk_restart';
  const data = { data_recon: params };

  return executeRequest(url, 'PUT', drRetryBulkReconciliation, { data, options });
};

const uploadDataRecon = (params = {}, options = {}) => {
  const url = `/${params.id}/upload`;
  const data = { data_recon: params };

  return executeRequest(url, 'PUT', drUploadReconciliation, { data, options });
};

const uploadBulkDataRecon = (params = {}, options = {}) => {
  const url = '/bulk_upload';
  const data = { data_recon: params };

  return executeRequest(url, 'PUT', drUploadBulkReconciliation, { data, options });
};

const approveDataRecon = (params = {}, options = {}) => {
  const url = `/${params.id}/approve`;
  const data = { data_recon: params };

  return executeRequest(url, 'PUT', drApproveReconciliation, { data, options });
};

const approveBulkDataRecon = (params = {}, options = {}) => {
  const url = '/bulk_approve';
  const data = { data_recon: params };

  return executeRequest(url, 'PUT', drApproveBulkReconciliation, { data, options });
};

const stopDataRecon = (params = {}, options = {}) => {
  const url = `/${params.id}/stop`;
  const data = { data_recon: params };

  return executeRequest(url, 'PUT', drStopReconciliation, { data, options });
};

const stopBulkDataRecon = (params = {}, options = {}) => {
  const url = '/bulk_stop';
  const data = { data_recon: params };

  return executeRequest(url, 'PUT', drStopBulkReconciliation, { data, options });
};

const loadPrivateAccessFunds = (params = {}, options = {}) => {
  const url = '/private_access_funds';

  return executeRequest(url, 'GET', drLoadPrivateAccessFunds, { params, options });
};

const loadFundAdminGroups = (params = {}, options = {}) => {
  const url = '/fund_admin_groups';

  return executeRequest(url, 'GET', drLoadFundAdminGroups, { params, options });
};

const loadReconciliationTypes = (params = {}, options = {}) => {
  const url = '/reconciliation_types';

  return executeRequest(url, 'GET', drLoadReconciliationTypes, { params, options });
};

const loadReconciliationPeriods = (params = {}, options = {}) => {
  const url = '/reconciliation_periods';

  return executeRequest(url, 'GET', drLoadReconciliationPeriods, { params, options });
};
const loadFundAdmins = (params = {}, options = {}) => {
  const url = '/fund_admins';
  return executeRequest(url, 'GET', drLoadFundAdmins, { params, options });
};

const getSettings = (params = {}, options = {}) => {
  const url = '/get_settings';

  return executeRequest(url, 'GET', drGetSettings, { params, options });
};

const saveSettings = (params = {}, options = {}) => {
  const url = '/save_settings';

  return executeRequest(url, 'POST', drSaveSettings, { params, options });
};

export default {
  loadDataRecons,
  loadDataReconFiles,
  loadDataReconsStatistics,
  createDataRecon,
  createBulkDataRecon,
  retryDataRecon,
  retryBulkDataRecon,
  uploadDataRecon,
  uploadBulkDataRecon,
  approveDataRecon,
  approveBulkDataRecon,
  stopDataRecon,
  stopBulkDataRecon,
  loadPrivateAccessFunds,
  loadFundAdminGroups,
  loadReconciliationTypes,
  loadReconciliationPeriods,
  loadFundAdmins,
  getSettings,
  saveSettings,
};
