import prepareHttpRequest from './helpers/executions';
import {scopedPartnerUrl} from './helpers/treatment';
import {
  ddCancelDataSync,
  ddDeleteStoragedFile,
  ddInitiateUploadFile,
  ddLoadDataSync,
  ddLoadDataSyncFileReport,
  ddLoadDataSyncFiles,
  ddLoadDataSyncIdentifiers,
  ddLoadDataSyncStatuses,
  ddLoadDataSyncUsers,
  ddLoadDataSyncTimeRanges,
  ddLoadDataSyncs,
  ddLoadDataSyncSchema,
  ddLoadProviders,
  ddLoadStoragedFiles,
  ddLoadWhiteLabelPartners,
  ddRunDataSync,
} from './helpers/constants';

const loadWhiteLabelPartners = (params = {}, options = {}) => {
  const url = '/white_label_partners';
  return prepareHttpRequest(url, 'GET', ddLoadWhiteLabelPartners, { params, options });
};

const loadProviders = (params = {}, options = {}) => {
  const url = '/providers';
  return prepareHttpRequest(url, 'GET', ddLoadProviders, { params, options });
};

const loadDataSyncs = (params = {}, options = {}) => {
  const url = scopedPartnerUrl(params.wlpId);
  return prepareHttpRequest(url, 'GET', ddLoadDataSyncs, { params, options });
};

const loadDataSync = (params = {}, options = {}) => {
  const url = scopedPartnerUrl(params.wlpId, `/${params.id}`);
  return prepareHttpRequest(url, 'GET', ddLoadDataSync, { params, options });
};

const cancelDataSync = (params = {}, options = {}) => {
  const url = scopedPartnerUrl(params.wlpId, `/${params.id}`);
  return prepareHttpRequest(url, 'DELETE', ddCancelDataSync, { params, options });
};

const loadDataSyncFiles = (params = {}, options = {}) => {
  const url = `/data_sync_files?data_sync_id=${params.id}`;
  return prepareHttpRequest(url, 'GET', ddLoadDataSyncFiles, { params, options });
};

const loadDataSyncFileReport = (params = {}, options = {}) => {
  const url = scopedPartnerUrl(params.wlpId, `/data_sync_file_reports/${params.id}`);
  return prepareHttpRequest(url, 'GET', ddLoadDataSyncFileReport, { params, options });
};

const runDataSync = (params = {}, options = {}) => {
  const url = scopedPartnerUrl(params.wlpId);
  return prepareHttpRequest(url, 'POST', ddRunDataSync, { params, options });
};

const loadDataSyncSchema = (params = {}, options = {}) => (
  prepareHttpRequest('/data_sync_schema', 'GET', ddLoadDataSyncSchema, { params, options })
);

const loadDataSyncIdentifiers = (params = {}, options = {}) => (
  prepareHttpRequest('/data_sync_schema/identifiers', 'GET', ddLoadDataSyncIdentifiers, { params, options })
);

const loadDataSyncStatuses = (params = {}, options = {}) => (
  prepareHttpRequest('/data_sync_schema/statuses', 'GET', ddLoadDataSyncStatuses, { params, options })
);

const loadDataSyncUsers = (params = {}, options = {}) => (
  prepareHttpRequest('/data_sync_schema/users', 'GET', ddLoadDataSyncUsers, { params, options })
);

const loadDataSyncTimeRanges = (params = {}, options = {}) => (
  prepareHttpRequest('/data_sync_schema/time_ranges', 'GET', ddLoadDataSyncTimeRanges, { params, options })
);

const loadStoragedFiles = (params = {}, options = {}) => {
  const url = scopedPartnerUrl(params.wlpId, '/files');
  return prepareHttpRequest(url, 'GET', ddLoadStoragedFiles, { params, options });
};

const initiateUploadFile = (params = {}, options = {}) => {
  const data = { file: params.file };
  const url = scopedPartnerUrl(params.wlpId, '/files');
  return prepareHttpRequest(url, 'POST', ddInitiateUploadFile, { data, options });
};

const deleteStoragedFile = (params = {}, options = {}) => {
  const url = scopedPartnerUrl(params.wlpId, `/files/${params.file}`);
  return prepareHttpRequest(url, 'DELETE', ddDeleteStoragedFile, { params, options });
};

export default {
  loadWhiteLabelPartners,
  loadProviders,
  loadDataSyncs,
  loadDataSync,
  cancelDataSync,
  loadDataSyncFiles,
  loadDataSyncFileReport,
  runDataSync,
  loadDataSyncSchema,
  loadDataSyncIdentifiers,
  loadDataSyncStatuses,
  loadDataSyncUsers,
  loadDataSyncTimeRanges,
  loadStoragedFiles,
  initiateUploadFile,
  deleteStoragedFile,
  ddLoadWhiteLabelPartners,
  ddLoadProviders,
  ddLoadDataSyncs,
  ddLoadDataSync,
  ddCancelDataSync,
  ddLoadDataSyncFiles,
  ddLoadDataSyncFileReport,
  ddRunDataSync,
  ddLoadDataSyncSchema,
  ddLoadDataSyncIdentifiers,
  ddLoadDataSyncStatuses,
  ddLoadDataSyncUsers,
  ddLoadDataSyncTimeRanges,
  ddLoadStoragedFiles,
  ddInitiateUploadFile,
  ddDeleteStoragedFile,
};
