import { getRequest } from 'actions/utils';
import { COM_SET_FUNDS } from 'actions/actionsConstants';
import { actionFactory } from 'services/actions';

const comSetFunds = actionFactory(COM_SET_FUNDS);

function onComFetchFundsSuccess(fundsData) {
  return (dispatch) => {
    const funds = fundsData.map(({ private_access_fund: { name, id } }) => ({ name, id }))
      // alphabetize
      .sort((fund1Upper, fund2Upper) => {
        let { name: fund1 = fund1Upper } = fund1Upper;
        let { name: fund2 = fund2Upper } = fund2Upper;
        fund1 = fund1?.toLocaleLowerCase() || fund1;
        fund2 = fund2?.toLocaleLowerCase() || fund2;
        return (fund1 > fund2 && 1) || (fund2 < fund1 && -1) || 0;
      });
    dispatch(comSetFunds(funds));
  };
}

export function comFetchFunds() {
  return (dispatch) => dispatch(
    getRequest({
      url: '/private_access_funds/admin_index/?private_equity=true',
      onSuccess: onComFetchFundsSuccess,
    }),
  );
}

export default { comFetchFunds };
