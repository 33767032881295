import {
  DC_HOT_BUTTONS_CLEAR_SELECTED,
  DC_SET_HOT_BUTTONS_CURRENT_SELECTED,
  DC_SET_HOT_BUTTONS,
} from 'actions/actionsConstants';

import update from 'immutability-helper';

const initialState = {
  hotButtonList: [],
  typeHotButton: 'default',
  selectedHotButton: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case DC_SET_HOT_BUTTONS:
      return update(state, {
        hotButtonList: { $set: action.payload.external_document_type_group },
        typeHotButton: { $set: action.payload.type_hot_buttons },
      });
    case DC_SET_HOT_BUTTONS_CURRENT_SELECTED:
      return update(state, {
        selectedHotButton: { $set: action.payload },
      });
    case DC_HOT_BUTTONS_CLEAR_SELECTED:
      return update(state, {
        selectedHotButton: { $set: null },
      });
    default:
      return state;
  }
}

export default reducer;
