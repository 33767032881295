import reducers from './reducers';
import initialState from './initialState';

const reducersList = [
  reducers.dataReconReducer,
  reducers.dataReconFileReducer,
  reducers.filterSourceReducer,
  reducers.dataReconParameterReducer,
];

const reducer = (state = initialState, action) => reducersList.reduce((stateAccumulator, exec) => exec(stateAccumulator, action), state);

export default reducer;
