import {
  DR_LOAD_DATA_RECONS,
  DR_LOAD_DATA_RECONS_STATISTICS,
  DR_CREATE_RECONCILIATION,
  DR_CREATE_BULK_RECONCILIATION,
  DR_RETRY_RECONCILIATION,
  DR_RETRY_BULK_RECONCILIATION,
  DR_UPLOAD_RECONCILIATION,
  DR_UPLOAD_BULK_RECONCILIATION,
  DR_APPROVE_RECONCILIATION,
  DR_APPROVE_BULK_RECONCILIATION,
  DR_STOP_RECONCILIATION,
  DR_STOP_BULK_RECONCILIATION,
} from 'actions/actionsConstants';

import initialState from '../initialState';
import { updateState, updateItem, updateItems } from '../helpers';

const REQUEST_OBJECT = 'data_recon';

export default (state = initialState, action) => {
  switch (action.type) {
    case DR_LOAD_DATA_RECONS:
      return updateState(state, action.payload, (data) => ({
        request_object: REQUEST_OBJECT,
        request_type: 'set_data_recon_list',
        message: 'Fetched Data Recon List.',
        data_recons: {
          records: data.data_recons,
          response_id: Date.now(),
          ...data.meta,
        },
      }));
    case DR_LOAD_DATA_RECONS_STATISTICS:
      return updateState(state, action.payload, (data) => {
        const { statistics } = data;
        const main_statistics_index = statistics.findIndex(obj => obj.private_access_fund_id === null);
        const main_statistics_obj = statistics.splice(main_statistics_index, 1)[0];

        return {
          request_object: REQUEST_OBJECT,
          request_type: 'set_data_recons_statistics',
          message: 'Fetched Data Recon Statistics.',
          statistics: main_statistics_obj,
          statistics_by_paf: statistics,
        };
      });
    case DR_CREATE_RECONCILIATION:
      return updateState(state, action.payload, (data) => ({
        request_object: REQUEST_OBJECT,
        request_type: 'create_reconciliation',
        message: data.error || 'Succeeded on creating a reconciliation',
        data_recons: {
          ...state.data_recons,
          records: [data].concat(state.data_recons.records),
          response_id: Date.now(),
        },
      }));
    case DR_CREATE_BULK_RECONCILIATION:
      return updateState(state, action.payload, (data) => ({
        request_object: REQUEST_OBJECT,
        request_type: 'bulk_create_reconciliation',
        message: data.error || 'Succeeded on creating multiple reconciliations',
        data_recons: {
          ...state.data_recons,
          records: data.concat(state.data_recons.records),
          response_id: Date.now(),
        },
      }));
    case DR_RETRY_RECONCILIATION:
      return updateState(state, action.payload, (data) => ({
        request_object: REQUEST_OBJECT,
        request_type: 'retry_reconciliation',
        message: data.error || 'Succeeded on retrying a reconciliation',
        data_recons: {
          ...state.data_recons,
          records: updateItem(data, state.data_recons.records),
          response_id: Date.now(),
        },
      }));
    case DR_RETRY_BULK_RECONCILIATION:
      return updateState(state, action.payload, (data) => ({
        request_object: REQUEST_OBJECT,
        request_type: 'bulk_retry_reconciliation',
        message: data.error || 'Succeeded on retrying multiple reconciliations',
        data_recons: {
          ...state.data_recons,
          records: updateItems(data, state.data_recons.records),
          response_id: Date.now(),
        },
      }));
    case DR_UPLOAD_RECONCILIATION:
      return updateState(state, action.payload, (data) => ({
        request_object: REQUEST_OBJECT,
        request_type: 'upload_reconciliation',
        message: data.error || 'Succeeded on uploading a reconciliation',
        data_recons: {
          ...state.data_recons,
          records: updateItem(data, state.data_recons.records),
          response_id: Date.now(),
        },
      }));
    case DR_UPLOAD_BULK_RECONCILIATION:
      return updateState(state, action.payload, (data) => ({
        request_object: REQUEST_OBJECT,
        request_type: 'bulk_upload_reconciliation',
        message: data.error || 'Succeeded on uploading multiple reconciliations',
        data_recons: {
          ...state.data_recons,
          records: updateItems(data, state.data_recons.records),
          response_id: Date.now(),
        },
      }));
    case DR_APPROVE_RECONCILIATION:
      return updateState(state, action.payload, (data) => ({
        request_object: REQUEST_OBJECT,
        request_type: 'approve_reconciliation',
        message: data.error || 'Succeeded on approving a reconciliation',
        data_recons: {
          ...state.data_recons,
          records: updateItem(data, state.data_recons.records),
          response_id: Date.now(),
        },
      }));
    case DR_APPROVE_BULK_RECONCILIATION:
      return updateState(state, action.payload, (data) => ({
        request_object: REQUEST_OBJECT,
        request_type: 'bulk_approve_reconciliation',
        message: data.error || 'Succeeded on approving multiple reconciliations',
        data_recons: {
          ...state.data_recons,
          records: updateItems(data, state.data_recons.records),
          response_id: Date.now(),
        },
      }));
    case DR_STOP_RECONCILIATION:
      return updateState(state, action.payload, (data) => ({
        request_object: REQUEST_OBJECT,
        request_type: 'stop_reconciliation',
        message: data.error || 'Succeeded on stopping a reconciliation process',
        data_recons: {
          ...state.data_recons,
          records: updateItem(data, state.data_recons.records),
          response_id: Date.now(),
        },
      }));
    case DR_STOP_BULK_RECONCILIATION:
      return updateState(state, action.payload, (data) => ({
        request_object: REQUEST_OBJECT,
        request_type: 'bulk_stop_reconciliation',
        message: data.error || 'Succeeded on stopping multiple reconciliations processes',
        data_recons: {
          ...state.data_recons,
          records: updateItems(data, state.data_recons.records),
          response_id: Date.now(),
        },
      }));
    default:
      return state;
  }
};
