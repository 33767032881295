import {
  INTEGRATION_PARTNER_DASHBOARD_UPDATE_PAGINATION,
  INTEGRATION_PARTNER_DASHBOARD_UPDATE_FILTER,
  INTEGRATION_PARTNER_DASHBOARD_SET_WLPS,
  INTEGRATION_PARTNER_DASHBOARD_SET_RULES,
  INTEGRATION_PARTNER_DASHBOARD_SET_FUNDS,
  INTEGRATION_PARTNER_DASHBOARD_SET_TRIGGER,
  INTEGRATION_PARTNER_DASHBOARD_SET_DOC_TYPES,
  INTEGRATION_PARTNER_DASHBOARD_SET_INT_PARTNERS,
} from 'actions/actionsConstants';
import update from 'immutability-helper';
import {
  initialPaginationState,
  initialSortState,
  convertObjectToQuery,
} from 'services/metaService';
import _ from 'lodash';

const pagination = initialPaginationState();
const sort = initialSortState({ sort_field: 'integration_partner_rules.created_at' });

export const initialState = {
  funds: [],
  integration_partners: [],
  triggers: [],
  document_types: [],
  rules: [],
  filter_options: {},
  pagination: { ...pagination, ...sort },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case INTEGRATION_PARTNER_DASHBOARD_UPDATE_PAGINATION: {
      const paginationKeys = Object.keys(action.payload);
      const paginationQuery = {};
      _.each(paginationKeys, (key) => {
        paginationQuery[key] = { $set: action.payload[key] };
      });

      return update(state, { pagination: paginationQuery });
    }
    case INTEGRATION_PARTNER_DASHBOARD_UPDATE_FILTER: {
      const query = convertObjectToQuery(action.payload);
      return update(state, query);
    }
    case INTEGRATION_PARTNER_DASHBOARD_SET_RULES: {
      return update(state, {
        rules: {
          $set: action.payload.integration_partner_rules,
        },
        available_filters: {
          $set: action.payload.meta.available_filters,
        },
        pagination: {
          $set: _.omit(action.payload.meta, 'available_filters'),
        },
      });
    }
    case INTEGRATION_PARTNER_DASHBOARD_SET_WLPS: {
      return update(state, {
        wlps: {
          $set: action.payload,
        },
      });
    }
    case INTEGRATION_PARTNER_DASHBOARD_SET_FUNDS: {
      return update(state, {
        funds: {
          $set: action.payload,
        },
      });
    }
    case INTEGRATION_PARTNER_DASHBOARD_SET_TRIGGER: {
      return update(state, {
        triggers: {
          $set: action.payload,
        },
      });
    }
    case INTEGRATION_PARTNER_DASHBOARD_SET_DOC_TYPES: {
      return update(state, {
        document_types: {
          $set: action.payload,
        },
      });
    }
    case INTEGRATION_PARTNER_DASHBOARD_SET_INT_PARTNERS: {
      return update(state, {
        integration_partners: {
          $set: action.payload,
        },
      });
    }
    default: {
      return state;
    }
  }
};
