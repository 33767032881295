import { createAction } from 'redux-actions';
import { BASE_NAME as ENTRY_BASE_NAME } from '../actions';

export const BASE_NAME = `${ENTRY_BASE_NAME}_list`;

export const MASS_MAILER_DOCUMENTS_LIST = {
  UNMOUNT: `${BASE_NAME}_UNMOUNT`,
};

export const onUnmount = createAction(MASS_MAILER_DOCUMENTS_LIST.UNMOUNT);

export default {
  onUnmount,
};
