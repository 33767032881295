import {
  ONE_TIME_POPUP_SET,
  ONE_TIME_POPUP_READ,
} from "actions/actionsConstants";

const initialState = {
  popups: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ONE_TIME_POPUP_SET:
      return {
        ...state,
        popups: action.payload,
      };
    case ONE_TIME_POPUP_READ:
      return {
        ...state,
        popups: state.popups.slice(1),
      };
    default:
      return state;
  }
};
