import {
  DC_HOT_BUTTONS_CLEAR_SELECTED,
  DC_SET_HOT_BUTTONS,
  DC_SET_HOT_BUTTONS_CURRENT_SELECTED,
} from 'actions/actionsConstants';
import dcResultsActions from 'actions/doc_center_actions/doc_center_results_actions/dcResultsActions';
import { getRequest } from '../../utils';

const setHotButtonSelected = (hotButtonSelected) => (dispatch) => {
  dispatch({
    type: DC_SET_HOT_BUTTONS_CURRENT_SELECTED,
    payload: hotButtonSelected,
  });
  dispatch(dcResultsActions.dcGetResults());
};

const setHotButtonsList = (hotButtons) => (dispatch) => {
  dispatch({
    type: DC_SET_HOT_BUTTONS,
    payload: hotButtons,
  });
};

const getHotButtons = () => (dispatch) => dispatch(getRequest({
  url: 'doc_center/external_document_type_groups',
  onSuccess: setHotButtonsList,
}));

const clearSelectedHotButton = () => (dispatch) => {
  dispatch({
    type: DC_HOT_BUTTONS_CLEAR_SELECTED,
  });
  dispatch(dcResultsActions.dcGetResults());
};

export default {
  setHotButtonSelected,
  clearSelectedHotButton,
  getHotButtons,
};
