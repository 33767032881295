/* eslint-disable max-statements */
/* eslint-disable max-lines-per-function */
import React, { useState, useEffect, useCallback } from 'react';
import { shape, func } from 'prop-types';
import {
  isOtherTypeInstitution,
  otherTypeInstitutionalInvestor,
  totalAssets,
} from '../../complete_profile/profileData';
import { getIsOtherTypeInstInvNeeded, getDoesFirmTypeRequireCrdNumber } from '../../complete_profile/TwoPageCompleteProfileValidations';
import RiaUserIdentificationPageOne from './RiaUserIdentificationPageOne';
import RiaUserIdentificationPageTwo from './RiaUserIdentificationPageTwo';
import RiaUserIdentificationPageThree from './RiaUserIdentificationPageThree';
import RiaUserIdentificationPageFour from './RiaUserIdentificationPageFour';
import RiaUserIdentificationPageFive from './RiaUserIdentificationPageFive';
import { generateUserIdentificationInformation } from '../../RegistrationUtils';
import { FINANCIAL_PROFESSIONAL } from '../constants';
import { formatExistingInformation } from '../helpers/formatters';

const NUMBER_PAGES = 5;

function RiaPopup({
  user,
  submit: submitUserAcknowledgments,
  updateUser,
  lastAcknowledgment,
}) {
  const [activeStep, setActiveStep] = useState(0);
  const [information, setInformation] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (Object.keys(lastAcknowledgment).includes('answers')) {
      const existingInformation = formatExistingInformation(lastAcknowledgment.answers);
      setInformation(existingInformation);
    }
  }, [lastAcknowledgment]);

  const saveInformation = useCallback((newInfo) => {
    setInformation((info) => ({ ...info, ...newInfo }));
  }, []);

  const firmTypeRequiresCrdNumber = getDoesFirmTypeRequireCrdNumber(information);

  const shouldAskIfOtherType = getIsOtherTypeInstInvNeeded(information);

  useEffect(() => {
    // Reset other_institutional_investor and other_type_institutional_investor and other_entity_total_assets
    if (!shouldAskIfOtherType) {
      saveInformation({ [isOtherTypeInstitution.question_key]: '' });
      saveInformation({ [otherTypeInstitutionalInvestor.question_key]: '' });
      saveInformation({ [totalAssets.question_key]: '' });
    }
  }, [firmTypeRequiresCrdNumber, shouldAskIfOtherType, saveInformation]);

  const onBack = () => {
    setActiveStep((step) => step - 1);
  };

  const onNext = () => {
    setActiveStep((step) => step + 1);
  };

  const submit = () => {
    const informationToSubmit = generateUserIdentificationInformation(information);

    setIsSubmitting(true);

    submitUserAcknowledgments({
      answers: informationToSubmit,
      acknowledgement_type: FINANCIAL_PROFESSIONAL,
      from_identification_user_modal: true,
    })
      .then(() => {
        const userAcknowledgments = user.user_acknowledgements;
        updateUser({
          identification_user_modal: false,
          user_acknowledgements: { ...userAcknowledgments, financial_professional: false },
        });
        setIsSubmitting(false);
      })
      .catch(() => window.location.assign('/logout'));
  };

  const progress = (activeStep / NUMBER_PAGES) * 100;

  return (
    <>
      { activeStep === 0 && <RiaUserIdentificationPageOne user={user} onNext={onNext} /> }
      { activeStep === 1 && (
        <RiaUserIdentificationPageTwo
          progress={progress}
          onNext={onNext}
          saveInformation={saveInformation}
          information={information}
        />
      ) }
      { activeStep === 2 && (
        <RiaUserIdentificationPageThree
          progress={progress}
          onNext={onNext}
          onBack={onBack}
          information={information}
          saveInformation={saveInformation}
        />
      ) }
      { activeStep === 3 && (
        <RiaUserIdentificationPageFour
          progress={progress}
          onNext={onNext}
          onBack={onBack}
          saveInformation={saveInformation}
          information={information}
        />
      ) }
      { activeStep === 4 && (
        <RiaUserIdentificationPageFive
          progress={progress}
          onNext={submit}
          onBack={onBack}
          saveInformation={saveInformation}
          information={information}
          isSubmitting={isSubmitting}
        />
      )}
    </>
  );
}

RiaPopup.propTypes = {
  user: shape({}).isRequired,
  submit: func.isRequired,
  updateUser: func.isRequired,
  lastAcknowledgment: shape({}),
};

RiaPopup.defaultProps = {
  lastAcknowledgment: undefined,
};

export default RiaPopup;
