import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import {
  configurationForField,
} from 'services/investor_profile';

export class FieldConfiguration extends Component {
  assembleProps() {
    const {
      readOnlyProfile, readOnlyField, incomplete, fieldName,
    } = this.props;

    return {
      readOnly: readOnlyProfile || readOnlyField,
      incomplete,
      fieldName,
    };
  }

  render() {
    if (this.props.hiddenField) {
      return null;
    }
    return _.isFunction(this.props.children) ?
      this.props.children(this.assembleProps()) :
      this.props.children;
  }
}

export const mapStateToProps = (state, ownProps) => {
  const { fieldName, completenessFieldName } = ownProps;
  return configurationForField(state, fieldName, completenessFieldName);
};

FieldConfiguration.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]).isRequired,
  readOnlyProfile: PropTypes.bool,
  readOnlyField: PropTypes.bool,
  hiddenField: PropTypes.bool,
  incomplete: PropTypes.bool,
  fieldName: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
};

FieldConfiguration.defaultProps = {
  readOnlyProfile: false,
  readOnlyField: false,
  hiddenField: false,
  incomplete: false,
  fieldName: '',
};

export default connect(mapStateToProps)(FieldConfiguration);
