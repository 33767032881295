import Axios from 'axios';
import { mainAppUrl } from 'services/bootstrapServices';
import { setBaseUrl } from 'services/axios/setBaseUrl';
import store from './icnStore';

// set for environment
setBaseUrl(Axios);

// Interceptors
// Request Interceptor
Axios.interceptors.request.use(
  (config) => {
    const state = store.getState();
    const icnBootstrap = state.icnReactBootstrap.icn_react_bootstrap;
    const csrf = icnBootstrap.csrf_token;

    config.headers['X-CSRF-Token'] = csrf;

    if (config.method === 'patch') {
      if (!config.data) {
        // Set the Content-Length header to '2' if no payload is provided
        config.data = {};
        config.headers['Content-Length'] = 2;
      }
    }

    return config;
  },
  (error) => Promise.reject(error)
);

// Response Interceptor
Axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (Axios.isCancel(error)) return Promise.reject(error);

    let res = error.response;

    if (res.status === 401) {
      const redirectUrl = mainAppUrl();
      if (!window.location.href.startsWith(redirectUrl)) {
        window.location.replace(redirectUrl);
      }
    }
    return Promise.reject(res);
  }
);
