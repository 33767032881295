import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DomainNavIcon from '@mui/icons-material/ExpandCircleDown';
import DomainNavUnderlayIcon from '@mui/icons-material/FiberManualRecord';
import CheckIcon from '@mui/icons-material/Check';
import { Menu, MenuItem, Typography, Box, Stack } from '@icapitalnetwork/supernova-core';

const DomainToggle = ({
    color = '#ffffff',
    hoverColor = '#ffffff',
    bgColor = '#495783',
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const config = [
        {
            description: "Alternative Investments",
            current: true,
        },
        {
            description: "Structured Investments, Annuities, BETA+, and ETFs",
            current: false,
            onClick: () => {
                window.location = "/icn_react/static/architect?toggle=true";
            },
        },
    ];

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleItemClick = (callback) => {
        handleClose();
        callback();
    };

    return (
        <>
            <Box
                aria-controls={open ? 'domain-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                sx={{
                    color: open ? hoverColor : color,
                    '&:hover': {
                      color: hoverColor,
                    },
                    transform: `${open ? 'rotate(180deg)' : 'none'}`,
                    ...toggle_button,
                }}
            >
                <DomainNavUnderlayIcon sx={{ ...domain_nav_underlay }} />
                <DomainNavIcon sx={{ color: bgColor, ...domain_nav }} />
            </Box>
            <Menu
                id="domain-menu"
                aria-labelledby="domain-menu-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <Box sx={section_title}>PLATFORM</Box>
                {config.map((item, index) => {
                    return (
                        <MenuItem
                            key={`domain-${index}`}
                            onClick={() => handleItemClick(item.onClick)}
                            disableRipple
                            selected={item.current}
                        >
                            <Box sx={toggle_option}>
                              <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between"
                              >
                                <Box sx={{ pr: '12px' }}>
                                  {item.title}
                                  {item.description && (
                                    <Typography component="div" sx={toggle_description}>
                                      {item.description}
                                    </Typography>
                                  )}
                                </Box>
                                <Box>{item.current && <CheckIcon sx={check} />}</Box>
                              </Stack>
                            </Box>
                        </MenuItem>
                    );
                })}
            </Menu>
        </>
    );
};

const toggle_button = {
    width: '24px',
    height: '24px',
    position: 'relative',
    cursor: 'pointer',
    flexShrink: 0,
};

const domain_nav = {
    position: 'absolute',
    top: '0px',
    left: '0px',
};

const domain_nav_underlay = {
    position: 'absolute',
    top: '0px',
    left: '0px',
};

const check = {
    fontSize: '16px',
};

const toggle_option = {
    display: 'block',
    position: 'relative',
    width: '260px',
    paddingTop: '10px',
    paddingBottom: '10px',
};

const toggle_description = {
    fontSize: '14px',
    color: '#252525',
    textWrap: 'wrap',
};

const section_title = {
    color: '#708794',
    padding: '2px 14px 8px',
    fontWeight: '600',
    fontSize: '0.9em',
};

DomainToggle.propTypes = {
    color: PropTypes.string,
    hoverColor: PropTypes.string,
    bgColor: PropTypes.string,
}

export default DomainToggle;
