import { RGA_SET_SFTP_ACCOUNTS } from 'actions/actionsConstants';
import { getRequest } from 'actions/utils';
import { actionFactory } from 'services/actions';

const BASE_URL = 'report_generator/sftp_accounts';
const getSftpAccountsSuccess = actionFactory(RGA_SET_SFTP_ACCOUNTS);
const fetchSftpAccounts = () => (dispatch) =>
  dispatch(
    getRequest({
      url: BASE_URL,
      onSuccess: getSftpAccountsSuccess,
    }),
  );

export default {
  fetchSftpAccounts,
};
