/* eslint-disable max-lines */
import { createAction } from 'redux-actions';
import axios from 'axios';
import { beginApiRequest, endApiRequest } from 'actions/spinner_actions/spinnerActions';
import { getRequest, postRequest } from 'actions/utils';

import {
  SUB_DOCS_LOAD_DOCUMENTS_SUCCESS,
  DEFINITION_SET_CURRENT_PAGE,
  DEFINITION_SET_RESULTS_PER_PAGE,
  SUB_DOCS_SET_ACTIVE_MODAL,
  SUB_DOCS_SET_SELECTED_ROW,
  SUB_DOCS_SET_SORTING,
  SUB_DOCS_SET_WLP_FILTER,
  SUB_DOCS_SET_DOCUMENT_TYPES_FILTER,
  SUB_DOCS_SET_PAF_FILTER,
  SUB_DOCS_SET_TYPE_FILTER,
  SUB_DOCS_SET_LIMIT_CODE_FILTER,
  SUB_DOCS_SET_FIRM_FILTER,
  DEFINITION_SET_DOCUMENT_TYPES,
  DEFINITION_SET_FUNDS,
  DEFINITION_SET_WHITE_LABEL_PARTNERS,
  DEFINITION_SET_DEFINITION_TYPES,
  DEFINITION_SET_SIGNER_TYPES,
  DEFINITION_SET_ACCOUNT_TYPES,
  DEFINITION_SET_LIBRARY_TEMPLATES,
  DEFINITION_SET_REQUIRED_DOCUMENT_TYPES,
} from '../actionsConstants';

const BASE_URL = 'subscription_document_definitions';
const AGREEMENT_PREVIEW_BASE_URL = 'agreement_previews';

const setDocumentTypes = createAction(DEFINITION_SET_DOCUMENT_TYPES);
const setFunds = createAction(DEFINITION_SET_FUNDS);
const setWhiteLabelPartners = createAction(DEFINITION_SET_WHITE_LABEL_PARTNERS);
const setSubDocTypes = createAction(DEFINITION_SET_DEFINITION_TYPES);
const setSignerTypes = createAction(DEFINITION_SET_SIGNER_TYPES);
const setAccountTypes = createAction(DEFINITION_SET_ACCOUNT_TYPES);
const setLibraryTemplates = createAction(DEFINITION_SET_LIBRARY_TEMPLATES);
const setRequiredDocumentTypes = createAction(DEFINITION_SET_REQUIRED_DOCUMENT_TYPES);

function loadSubscriptionDocumentsSuccess(payload) {
  return {
    type: SUB_DOCS_LOAD_DOCUMENTS_SUCCESS,
    payload,
  };
}

function loadSubscriptionDocuments() {
  return (dispatch, getState) => {
    const state = getState().subscriptionDocuments;

    const params = {
      page: state.current_page,
      per_page: state.results_per_page,
      sorting_column: state.sorting_column,
      sorting_order: state.sorting_order,
      paf_filter: state.selected_paf_filter?.id,
      wlp_filter: state.selected_wlp_filter?.id,
      document_type_filter: state.selected_document_type_filter?.id,
      sub_doc_type_filter: state.selected_sub_doc_type_filter?.id,
      limit_code_filter: state.selected_limit_code_filter?.id,
      firm_filter: state.selected_firm_filter?.id,
      paginate: true,
    };

    return dispatch(
      getRequest({
        url: BASE_URL,
        params,
        onSuccess: loadSubscriptionDocumentsSuccess,
      })
    );
  };
}

function subDocSetCurrentPage(payload) {
  return (dispatch) => {
    dispatch({
      type: DEFINITION_SET_CURRENT_PAGE,
      payload,
    });
    return dispatch(loadSubscriptionDocuments());
  };
}

function subDocSetResultsPerPage(payload) {
  return (dispatch) => {
    dispatch({
      type: DEFINITION_SET_RESULTS_PER_PAGE,
      payload,
    });
    return dispatch(loadSubscriptionDocuments());
  };
}

function removeSubscriptionDocument(document) {
  const url = `${BASE_URL}/${document.id}`;
  return (dispatch) => {
    dispatch(beginApiRequest());
    return axios
      .delete(url, { withCredentials: true })
      .then(() => {
        dispatch(loadSubscriptionDocuments());
      })
      .finally(() => {
        dispatch(endApiRequest());
      });
  };
}

function subDocSetSorting(sortingColumn, sortingOrder) {
  return (dispatch) => {
    dispatch({
      type: SUB_DOCS_SET_SORTING,
      payload: { sortingColumn, sortingOrder },
    });
    return dispatch(loadSubscriptionDocuments());
  };
}

function subDocsSetActiveModal(payload) {
  return {
    type: SUB_DOCS_SET_ACTIVE_MODAL,
    payload,
  };
}

function subDocsSetSelectedRow(payload) {
  return {
    type: SUB_DOCS_SET_SELECTED_ROW,
    payload,
  };
}

function subDocsSetWlpFilter(payload) {
  return (dispatch) => {
    dispatch({
      type: SUB_DOCS_SET_WLP_FILTER,
      payload,
    });
    return dispatch(loadSubscriptionDocuments());
  };
}

function subDocsSetDocumentTypeFilter(payload) {
  return (dispatch) => {
    dispatch({
      type: SUB_DOCS_SET_DOCUMENT_TYPES_FILTER,
      payload,
    });
    return dispatch(loadSubscriptionDocuments());
  };
}

function subDocsSetTypeFilter(payload) {
  return (dispatch) => {
    dispatch({
      type: SUB_DOCS_SET_TYPE_FILTER,
      payload,
    });
    return dispatch(loadSubscriptionDocuments());
  };
}

function subDocsSetLimitCodeFilter(payload) {
  return (dispatch) => {
    dispatch({
      type: SUB_DOCS_SET_LIMIT_CODE_FILTER,
      payload,
    });
    return dispatch(loadSubscriptionDocuments());
  };
}

function subDocsSetFirmFilter(payload) {
  return (dispatch) => {
    dispatch({
      type: SUB_DOCS_SET_FIRM_FILTER,
      payload,
    });
    return dispatch(loadSubscriptionDocuments());
  };
}

function subDocsSetPafFilter(payload) {
  return (dispatch) => {
    dispatch({
      type: SUB_DOCS_SET_PAF_FILTER,
      payload,
    });
    return dispatch(loadSubscriptionDocuments());
  };
}

const getSubDocDefinition = (id) =>
  getRequest({
    url: `${BASE_URL}/${id}`,
  });

const previewDefinition = (id, signerType, accountTypes) =>
  postRequest({
    data: {
      account_types: accountTypes,
      subscription_document_definition_id: id,
      signer_type: signerType,
      type: 'subscription_document_definition',
    },
    showSpinner: false,
    url: `${AGREEMENT_PREVIEW_BASE_URL}/`,
    successMessage: {
      message: 'The agreement preview will be generated',
    },
  });

const loadDefinitionDocumentTypes = (showSpinner = true) =>
  getRequest({
    showSpinner,
    url: `${BASE_URL}/get_document_types`,
    onSuccess: setDocumentTypes,
  });

const loadDefinitionFunds = (showSpinner = true) =>
  getRequest({
    showSpinner,
    url: 'private_access_funds/investor_relations',
    onSuccess: setFunds,
  });

const loadDefinitionWhiteLabelPartners = (showSpinner = true) =>
  getRequest({
    showSpinner,
    url: 'white_label_partners',
    onSuccess: setWhiteLabelPartners,
  });

const loadDefinitionSubDocTypes = (showSpinner = true) =>
  getRequest({
    showSpinner,
    url: 'sub_doc_types',
    onSuccess: setSubDocTypes,
  });

const loadDefinitionRequiredDocumentTypes = (showSpinner = true) =>
  getRequest({
    showSpinner,
    url: 'required_document_types/e_signables',
    onSuccess: setRequiredDocumentTypes,
  });

const loadDefinitionSignerTypes = (showSpinner = true) =>
  getRequest({
    showSpinner,
    url: 'investor_profiles/signer_types',
    onSuccess: setSignerTypes,
  });

const loadAccountTypes = (showSpinner = true) =>
  getRequest({
    showSpinner,
    url: `${BASE_URL}/retrieve_account_types`,
    onSuccess: setAccountTypes,
  });

const loadDefinitionLibraryTemplates = (showSpinner = true) =>
  getRequest({
    showSpinner,
    url: 'agreement_templates',
    onSuccess: setLibraryTemplates,
  });

export default {
  loadSubscriptionDocuments,
  subDocSetSorting,
  subDocSetCurrentPage,
  subDocSetResultsPerPage,
  subDocsSetActiveModal,
  subDocsSetSelectedRow,
  subDocsSetPafFilter,
  subDocsSetWlpFilter,
  subDocsSetDocumentTypeFilter,
  removeSubscriptionDocument,
  subDocsSetTypeFilter,
  subDocsSetLimitCodeFilter,
  subDocsSetFirmFilter,
  getSubDocDefinition,
  previewDefinition,
  loadDefinitionDocumentTypes,
  loadDefinitionFunds,
  loadDefinitionWhiteLabelPartners,
  loadDefinitionSubDocTypes,
  loadDefinitionSignerTypes,
  loadAccountTypes,
  loadDefinitionLibraryTemplates,
  loadDefinitionRequiredDocumentTypes,
};
