import {
  number,
  string,
  shape,
  arrayOf,
  oneOfType,
} from 'prop-types';

export const searchResultItem = shape({
  id: number.isRequired,
  name: string,
  thirdColumn: oneOfType([
    string,
    number,
  ]),
  fourthColumn: oneOfType([
    string,
    number,
  ]),
});

export const searchResult = shape({
  results: arrayOf(searchResultItem),
  type: string,
  title: string,
});

export const resultsListItem = shape({
  value: number.isRequired,
  label: string.isRequired,
});

export const tab = shape({
  id: number,
  label: string,
  number_of_results: number,
});
