export const ALL_REPORTABLE_CLASSES_OPTION_ID = -1;

// Used to track loading in components/fund_finances/fund_finances_header/FundFinancesHeader.jsx
export const FUND_GROUP_FILTER_LABEL = 'Fund Group';
export const FUND_NAME_FILTER_LABEL = 'Fund Name';
export const INDEX_NAME_FILTER_LABEL = 'Index Name';
export const SHARE_CLASS_FILTER_LABEL = 'Share Class / ID';
export const RETURN_TYPE_FILTER_LABEL = 'Return Type';
export const REPORT_TYPE_FILTER_LABEL = 'Report Type';

export const PRO_FORMA_RETURNS_RETURN_TYPES = [
  { id: 'pro_forma', name: 'Pro Forma' },
  { id: 'estimate', name: 'Estimate' },
];
export const SHARE_CLASS_PERFORMANCE_RETURN_TYPES = [
  ...PRO_FORMA_RETURNS_RETURN_TYPES,
  { id: 'actual', name: 'Actual' },
];

export const INDEX_PERFORMANCES_TOTAL_URL = '/fund_finances/index_performances/total_index_returns';
export const INDEX_RETURNS_TOTAL_URL = '/fund_finances/index_returns/total_index_returns';
export const PRO_FORMA_RETURNS_TOTAL_URL = '/fund_finances/pro_forma_returns/total_pro_returns';
export const SHARE_CLASS_PERFORMANCES_TOTAL_URL = '/fund_finances/share_class_performances/performance_totals';

export const FUND_FINANCE_MODEL_INDEX = 'index';
export const FUND_FINANCE_MODEL_SHARE_CLASS_PERFORMANCES = 'share_class_performances';
export const FUND_FINANCE_MODEL_PRO_FORMA = 'pro_forma';

export const INDEX_MODEL_RETURNS = 'returns';
export const INDEX_MODEL_PERFORMANCES = 'performances';
