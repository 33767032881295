import React, {
  useMemo,
  useCallback,
  useRef,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

import styles from './IcnTab.module.scss';

export const IcnTab = ({
  className,
  children,
  onClick,
  isSelected,
  href,
  ...rest
}) => {
  const tabClass = useMemo(() => classNames(styles.IcnTab, className, {
    [styles['IcnTab--selected']]: isSelected,
  }), [isSelected, className]);

  const tabRef = useRef(null);

  const positionTab = useCallback(() => {
    if (tabRef && tabRef.current) {
      // adjust tab position
      const tab = tabRef.current;
      const parent = tab.offsetParent || {};
      const scrollLeft = parent.scrollLeft || 0;
      const clientWidth = parent.clientWidth || 0;
      // has overflow left
      if ((tab.offsetLeft - scrollLeft) < 0) {
        tab.scrollIntoView({ behavior: 'smooth', inline: 'start' });
      }

      const parentSize = clientWidth + scrollLeft;
      const tabPosition = tab.offsetWidth + tab.offsetLeft;
      // has overflow right
      if ((parentSize - tabPosition) < 0) {
        tab.scrollIntoView({ behavior: 'smooth', inline: 'end' });
      }
    }
  }, []);

  useEffect(() => {
    if (isSelected) {
      positionTab();
    }
  }, [isSelected, positionTab]);

  if (href) {
    if (href.match(/https?:/gi)) {
      return (
        <a className={tabClass} href={href} {...rest}>{children}</a>
      );
    }

    return (
      <NavLink
        className={tabClass}
        activeClassName={styles['IcnTab--selected']}
        to={href}
        {...rest}
      >
        {children}
      </NavLink>
    );
  }

  return (
    <button
      ref={tabRef}
      type="button"
      className={tabClass}
      aria-hidden="true"
      onClick={onClick}
      {...rest}
    >
      {children}
    </button>
  );
};

IcnTab.propTypes = {
  onClick: PropTypes.func,
  isSelected: PropTypes.bool,
  className: PropTypes.string,
  href: PropTypes.string,
  children: PropTypes.node,
};

IcnTab.defaultProps = {
  onClick: null,
  isSelected: false,
  className: null,
  href: null,
  children: null,
};

export default IcnTab;
