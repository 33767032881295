/* eslint-disable max-statements */
import update from 'immutability-helper';
import _ from 'lodash';

import {
  RGA_SAVE_FILTER_NUMERIC,
  RGA_REMOVE_NUMERIC_FILTERED_OPTIONS,
  RGA_SET_NUMERIC_FILTER_OPTIONS,
  RGA_SET_NUMERIC_FILTER_TYPE,
  RGA_SET_NUMERIC_FILTER_RANGE,
  RGA_SET_NUMERIC_NULL_OPTION,
} from 'actions/actionsConstants';

import initialState from 'reducers/report_generator_reducers/initialState';
import {
  retrieveDBKey,
  getNumericFilterRangeLabel,
  mapOptions,
} from 'reducers/report_generator_reducers/utils';

export const DEFAULT_RANGE_VALUE = [['', '', true, []]];

const isRangeFilterType = (filterType) => {
  return (
    filterType === 'range' ||
    filterType === 'greater' ||
    filterType === 'lesser'
  );
};

export default (state = initialState, action) => {
  switch (action.type) {
    case RGA_SAVE_FILTER_NUMERIC: {
      const { filter } = action.payload;
      const configurableId = state.configurableId;
      const storedRanges =
        (state.stored_queries[configurableId][filter] &&
          state.stored_queries[configurableId][filter].ranges) ||
        [];
      const nullOption = state.nullOptions[configurableId][filter];
      const filterType = state.appliedFilterType[configurableId][filter];
      const rangeFilterType = isRangeFilterType(filterType);
      const nullOptionIsNil = _.isNil(nullOption);

      if (
        nullOptionIsNil &&
        rangeFilterType &&
        _.isEqual(storedRanges, state.appliedRanges[configurableId][filter])
      )
        return state;

      if (
        nullOptionIsNil &&
        !rangeFilterType &&
        !state.filteredOptions[configurableId][filter]
      )
        return state;

      const saveObject = {
        data_type: 'NUMBER',
        chosen_options: [],
        ranges: [],
        filter_type: 'value',
        null_option: nullOption || false,
      };

      if (rangeFilterType) {
        saveObject.filter_type =
          state.appliedFilterType[configurableId][filter];
        saveObject['ranges'] = state.appliedRanges[configurableId][filter];
      } else if (state.filteredOptions[configurableId][filter]?.length > 0) {
        saveObject.chosen_options =
          state.filteredOptions[configurableId][filter];
      }

      const dbMethod = retrieveDBKey(
        filter,
        state.available_fields[configurableId],
      );
      let filterLabel = '';
      if (saveObject.ranges && saveObject.ranges.length > 0) {
        filterLabel = `${getNumericFilterRangeLabel(
          saveObject.ranges[0],
          saveObject.chosen_options,
        )}${nullOption ? ', NULL' : ''}`;
      } else if (state.filteredTotals[configurableId][filter] !== 0) {
        const filteredTotals = state.filteredTotals[configurableId][filter];
        filterLabel = nullOption
          ? Number(state.filteredTotals[configurableId][filter]) + 1
          : filteredTotals;
      }

      return {
        ...state,
        stored_queries: update(state.stored_queries, {
          [configurableId]: {
            [filter]: { $set: { ...saveObject, ...dbMethod } },
          },
        }),
        totals: update(state.totals, {
          [configurableId]: { [filter]: { $set: filterLabel } },
        }),
        filteredOptions: update(state.filteredOptions, {
          [configurableId]: { [filter]: { $set: saveObject.chosen_options } },
        }),
        filteredOptionsPicked: update(state.filteredOptionsPicked, {
          [configurableId]: { [filter]: { $set: {} } },
        }),
      };
    }
    case RGA_SET_NUMERIC_FILTER_TYPE: {
      const { filter, filter_type } = action.payload;
      const configurableId = state.configurableId;
      return {
        ...state,
        appliedFilterType: update(state.appliedFilterType, {
          [configurableId]: { [filter]: { $set: filter_type } },
        }),
        appliedRanges: update(state.appliedRanges, {
          [configurableId]: { [filter]: { $set: [] } },
        }),
      };
    }
    case RGA_SET_NUMERIC_FILTER_RANGE: {
      const { filter, range_type, filter_value } = action.payload;
      const configurableId = state.configurableId;
      let range = _.isEmpty(state.appliedRanges[configurableId][filter])
        ? _.cloneDeep(DEFAULT_RANGE_VALUE)
        : _.cloneDeep(state.appliedRanges[configurableId][filter]);
      if (range_type === 'from') {
        range[0][0] = filter_value;
      } else {
        range[0][1] = filter_value;
      }

      return {
        ...state,
        appliedRanges: update(state.appliedRanges, {
          [configurableId]: { [filter]: { $set: range } },
        }),
      };
    }
    case RGA_SET_NUMERIC_FILTER_OPTIONS: {
      const configurableId = state.configurableId;
      const { filter, filter_value } = action.payload;
      const newOption = mapOptions(filter_value);

      let filteredOptions = state.filteredOptions[configurableId][filter] || [];

      if (newOption) {
        filteredOptions = [...filteredOptions, newOption];

        let filteredOptionsPicked =
          state.filteredOptionsPicked[configurableId][filter] || [];
        filteredOptionsPicked = [...filteredOptionsPicked, newOption];

        return {
          ...state,
          filteredOptions: update(state.filteredOptions, {
            [configurableId]: { [filter]: { $set: filteredOptions } },
          }),
          filteredOptionsPicked: update(state.filteredOptionsPicked, {
            [configurableId]: { [filter]: { $set: filteredOptionsPicked } },
          }),
          filteredTotals: update(state.filteredTotals, {
            [configurableId]: {
              [filter]: {
                $set: (state.filteredTotals[configurableId][filter] || 0) + 1,
              },
            },
          }),
        };
      } else {
        return state;
      }
    }
    case RGA_REMOVE_NUMERIC_FILTERED_OPTIONS: {
      const configurableId = state.configurableId;
      const { filter, options } = action.payload;
      const optionIds = options.map((option) => option.id);
      const filtered = state.filteredOptions[configurableId][filter].filter(
        (item) => !optionIds.includes(item.id),
      );
      const total = state.filteredTotals[configurableId][filter] - 1;

      let pickedOptions =
        state.filteredOptionsPicked[configurableId][filter] || [];
      pickedOptions = pickedOptions.filter(
        (item) => !optionIds.includes(item.id),
      );

      return {
        ...state,
        filteredOptions: update(state.filteredOptions, {
          [configurableId]: { [filter]: { $set: filtered } },
        }),
        filteredTotals: update(state.filteredTotals, {
          [configurableId]: { [filter]: { $set: total } },
        }),
        filteredOptionsPicked: update(state.filteredOptionsPicked, {
          [configurableId]: { [filter]: { $set: pickedOptions } },
        }),
      };
    }
    case RGA_SET_NUMERIC_NULL_OPTION: {
      const { configurableId } = state;
      const {
        payload: { filter, nullOption },
      } = action;

      return {
        ...state,
        nullOptions: update(state.nullOptions, {
          [configurableId]: { [filter]: { $set: nullOption } },
        }),
      };
    }
    default:
      return state;
  }
};
