import update from 'immutability-helper';
import { SET_POLYGLOT_AVAILABLE_LANGUAGES_DATA } from './actions';

export const initialState = {
  availableLanguagesData: {
    data: [],
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_POLYGLOT_AVAILABLE_LANGUAGES_DATA: {
      return update(state, {
        availableLanguagesData: {
          data: { $set: action.payload.data },
        },
      });
    }
    default:
      return state;
  }
};
