import {
  DC_SET_SAVED_VIEWS,
  DC_SET_SAVED_VIEWS_SORT,
  DC_SET_ACTIVE_SAVED_VIEW,
} from 'actions/actionsConstants';

import update from 'immutability-helper';
import _ from 'lodash';

const initialState = {
  active_saved_view_id: null,
  saved_views: [],
  sort: {
    sort_field: '',
  },
  error: null,
};

export { initialState };

export default (state = initialState, action) => {

  switch (action.type) {

  case DC_SET_ACTIVE_SAVED_VIEW:
    return update(state, {
      active_saved_view_id: {
        $set: action.payload,
      },
    });

  case DC_SET_SAVED_VIEWS:
    let savedViews = action.payload.saved_views;

    return update(state, {
      saved_views: {
        $set: savedViews,
      },
    });

  case DC_SET_SAVED_VIEWS_SORT:
    let nextSort = {
      sort_field: _.isEmpty(action.payload) ? '' : action.payload
    };

    return update(state, {
      sort: {
        $set: nextSort,
      },
    });

  default:
    return state;
  }
};
