export const VERIFICATION_CODE_INVALID = 'VERIFICATION_CODE_INVALID';
export const VERIFICATION_CODE_SENT = 'VERIFICATION_CODE_SENT';
export const TRANSFER_VALID = 'TRANSFER_VALID';
export const AUTHENTICATION_VALID = 'AUTHENTICATION_VALID';
export const PAGE_LOADING = 'PAGE_LOADING';
export const PERMISSIONS_INVALID = 'PERMISSIONS_INVALID';

export const PAGE_STATES = [
  VERIFICATION_CODE_INVALID,
  VERIFICATION_CODE_SENT,
  TRANSFER_VALID,
  AUTHENTICATION_VALID,
  PAGE_LOADING,
  PERMISSIONS_INVALID,
];

export default PAGE_STATES;
