import reducers from './reducers';
import initialState from './initialState';

const reducersList = [
  reducers.snackbarReducer,
  reducers.breadcrumbsReducer,
  reducers.baseTemplatesReducer,
  reducers.baseTemplateReducer,
  reducers.templatesReducer,
  reducers.templatesPaginationReducer,
  reducers.templateReducer,
  reducers.integrationPartnersReducer,
  reducers.privateAccessFundsReducer,
  reducers.providersReducer,
  reducers.whiteLabelPartnersReducer,
  reducers.generalLedgerAccountsReducer,
  reducers.firmsReducer,
  reducers.filterOptionsReducer,
];

const reducer = (state = initialState, action) => reducersList.reduce((stateAccumulator, exec) => exec(stateAccumulator, action), state);

export default reducer;
