import axios from 'axios';

import {
  beginApiRequest,
  endApiRequest,
} from '../spinner_actions/spinnerActions';

const bridges = () => (dispatch) => {
  dispatch(beginApiRequest());

  return axios
    .get('api_log_finder/bridges', {
      withCredentials: true,
    })
    .then((response) => {
      dispatch(endApiRequest());
      return response.data;
    })
    .catch(() => {
      dispatch(endApiRequest());
      return [];
    });
};

const recent = (bridge) => (dispatch) => {
  dispatch(beginApiRequest());

  return axios
    .get('api_log_finder/recent', {
      withCredentials: true,
      params: {
        bridge,
      },
    })
    .then((response) => {
      dispatch(endApiRequest());
      return response.data;
    })
    .catch(() => {
      dispatch(endApiRequest());
      return [];
    });
};

const load = (id) => (dispatch) => {
  dispatch(beginApiRequest());

  return axios
    .get('api_log_finder/load', {
      withCredentials: true,
      params: {
        id,
      },
    })
    .then((response) => {
      dispatch(endApiRequest());
      return response.data;
    })
    .catch(() => {
      dispatch(endApiRequest());
      return {};
    });
};

export default {
  bridges,
  recent,
  load,
};
