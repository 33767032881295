import {
  REGISTER_USER,
  REGISTER_UPDATE_STEP,
  REGISTER_SET_PROFILE,
  REGISTER_SET_PROFILE_INITIAL_STATE,
  REGISTER_SET_PROFILE_OPTIONS,
  REGISTER_SET_INITIAL_STATE,
  REGISTER_SET_USER_INVITE,
  REGISTER_INVITED_USERS,
  REGISTER_USER_INVITE_OPEN_REMINDER_MODAL,
  REGISTER_USER_INVITE_CLOSE_REMINDER_MODAL,
  REGISTER_SET_QUALIFICATION_QUESTIONS,
  REGISTER_UPDATE_QUALIFICATION_QUESTIONS,
  REGISTER_SET_IS_LOADING,
} from 'actions/actionsConstants';
import update from 'immutability-helper';
import _ from 'lodash';

export const initialState = {
  currentStep: 1,
  userInvite: {},
  newUser: {},
  profileInitialState: {},
  profile: {},
  profileOps: {},
  qualificationQuestions: [],
  isRegistrationLoading: false,
  invitedUsers: {
    total: 0,
    page: 1,
    per_page: 10,
    user_invites: [],
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REGISTER_USER: {
      return update(state, {
        newUser: {
          $set: action.payload,
        },
      });
    }
    case REGISTER_SET_INITIAL_STATE: {
      return update(state, {
        $set: action.payload,
      });
    }
    case REGISTER_UPDATE_STEP: {
      return update(state, {
        currentStep: {
          $set: action.payload,
        },
      });
    }
    case REGISTER_SET_PROFILE: {
      const _payload = _.omit(action.payload, 'accreditation');

      return update(state, {
        profile: (profile) => update(profile || {}, {
          $merge: _payload,
        }),
      });
    }
    case REGISTER_SET_PROFILE_INITIAL_STATE: {
      const _payload = _.omit(action.payload, 'accreditation');

      return update(state, {
        profileInitialState: (profile) => update(profile || {}, {
          $merge: _payload,
        }),
      });
    }
    case REGISTER_SET_PROFILE_OPTIONS: {
      return update(state, {
        profileOps: (profileOps) => update(profileOps || {}, {
          $merge: action.payload,
        }),
      });
    }
    case REGISTER_SET_USER_INVITE: {
      return update(state, {
        userInvite: {
          $set: action.payload,
        },
      });
    }
    case REGISTER_SET_QUALIFICATION_QUESTIONS: {
      let data = action.payload.map((question) => {
        question.checked = false;
        return question;
      })

      return update(state, {
        qualificationQuestions: {
          $set: data,
        },
      });
    }
    case REGISTER_UPDATE_QUALIFICATION_QUESTIONS: {
      return {
        ...state,
        qualificationQuestions: state.qualificationQuestions.map(
          (question) => question.id === action.payload.id ? { ...question, checked: !action.payload.checked }
                                                          : question)
      }
    }
    case REGISTER_INVITED_USERS: {
      return update(state, {
        invitedUsers: {
          $set: action.payload,
        },
      });
    }
    case REGISTER_USER_INVITE_OPEN_REMINDER_MODAL:
      return update(state, {
        isUserInviteReminderModalOpen: { $set: true },
        selected_item: { $set: action.payload },
    });
    case REGISTER_USER_INVITE_CLOSE_REMINDER_MODAL:
      return update(state, {
        isUserInviteReminderModalOpen: { $set: false },
        $unset: ['selected_item'],
    });
    case REGISTER_SET_IS_LOADING:
      return update(state, {
        isRegistrationLoading: {
          $set: action.payload,
        },
      });
    default: {
      return state;
    }
  }
};
