import { useState } from 'react';

const INITIAL_STATE = {
  resultsPerPage: 25,
  page: 1,
  period: 'All',
  categoryFilter: '',
  allowCategoryNil: false,
  sortCol: '',
  sortOrder: '',
};

export const useTransactionsFilter = () => {
  const [filter, setFilter] = useState(INITIAL_STATE);

  const setPeriod = (period) => {
    setFilter((currentFilter) => ({
      ...currentFilter,
      period,
      page: INITIAL_STATE.page,
    }));
  };

  const setPreviousPage = () => {
    setFilter((currentFilter) => ({
      ...currentFilter,
      page: currentFilter.page - 1,
    }));
  };

  const setNextPage = () => {
    setFilter((currentFilter) => ({
      ...currentFilter,
      page: currentFilter.page + 1,
    }));
  };

  const setResultsPerPage = (resultsPerPage) => {
    setFilter((currentFilter) => ({
      ...currentFilter,
      resultsPerPage: parseInt(resultsPerPage, 10) || 0,
      page: INITIAL_STATE.page,
    }));
  };

  const handleSortChange = (sortState) => {
    setFilter((currentFilter) => ({
      ...currentFilter,
      sortCol: sortState[0].colId,
      sortOrder: sortState[0].sort,
    }));
  };

  const handleCategoryFilter = (values, allowCategoryNil) => {
    setFilter((currentFilter) => ({
      ...currentFilter,
      page: INITIAL_STATE.page,
      categoryFilter: values,
      allowCategoryNil,
    }));
  };

  const reset = () => setFilter(INITIAL_STATE);

  const actions = {
    setPeriod,
    setPreviousPage,
    setNextPage,
    setResultsPerPage,
    handleSortChange,
    handleCategoryFilter,
    reset,
  };

  return [filter, actions];
};

export const filterProps = (filter = INITIAL_STATE) => ({
  page: filter.page,
  per_page: filter.resultsPerPage,
  period: filter.period,
  allow_category_nil: filter.allowCategoryNil,
  sort_col: filter.sortCol,
  sort_order: filter.sortOrder,
});
