import { SR_SET_ACTIVE_MODAL, SR_SET_SELECTED_ROW } from 'actions/actionsConstants';

import update from 'immutability-helper';

import initialState from '../initialState';

export default (state = initialState, action) => {
  switch (action.type) {
    case SR_SET_ACTIVE_MODAL:
      return update(state, { active_modal: { $set: action.payload } });
    case SR_SET_SELECTED_ROW:
      return update(state, { selected_row: { $set: action.payload } });
    default:
      return state;
  }
};
