export default {
  fund: {
    id: 0,
    name: '',
    diligence: false,
    manager: '',
    profileSubtitle: '',
    category: '',
    strategy: '',
    minimumInvestment: undefined,
    minimumAddOnInvestment: undefined,
    subscriptionMinimumDetail: '',
    investorQualification: '',
    jurisdiction: '',
    tabs: null,
  },
  currentUserHasProfile: null,
  isInvestorProfileCompleted: null,
  investorProfileId: '',
  isContactInfoOpen: false,
};
