import {
  LO_SET_IS_INVESTOR_PROFILE_COMPLETED,
  LO_SET_USER_HAS_INVESTOR_PROFILE,
  LO_SET_SHOW_FLASH_REPORTS,
  LO_COMPONENT_UNMOUNT,
  LO_SET_COLLECTIONS,
} from 'modules/investment_opportunity/actions/constants';
import update from 'immutability-helper';
import initialState from './initialState';

export default (state = initialState, action) => {
  switch (action.type) {
    case LO_SET_IS_INVESTOR_PROFILE_COMPLETED: {
      return update(state, {
        isInvestorProfileCompleted: { $set: action.payload.is_completed },
        investorProfileId: { $set: action.payload.investor_profile_id },
      });
    }
    case LO_SET_USER_HAS_INVESTOR_PROFILE: {
      return update(state, { currentUserHasProfile: { $set: action.payload.has_profile } });
    }
    case LO_SET_SHOW_FLASH_REPORTS: {
      return update(state, {
        showFlashReports: { $set: action.payload.status },
        selectedReportPafId: { $set: action.payload.selectedPafId },
      });
    }
    case LO_SET_COLLECTIONS: {
      return update(state, {
        opportunities: {
          collections: { $set: action.payload.collections },
          collectionsAsTilesList: { $set: action.payload.collections_as_tiles },
          collectionsOfSolutions: { $set: action.payload.collections_of_solutions },
        },
      });
    }
    case LO_COMPONENT_UNMOUNT:
      return initialState;
    default:
      return state;
  }
};
