import axios from 'axios';
import notificationService from 'services/notifications';
import _ from 'lodash';
import { patchRequest } from 'actions/utils';
import { createRequestTypes } from 'actions/helpers';
import spinnerActions from '../spinner_actions/spinnerActions';

import {
  MANAGE_FUNDING_STATUS_SET_SELECTABLE_STATUSES,
  MANAGE_FUNDING_STATUS_SET_SELECTED_STATUSES,
  MANAGE_FUNDING_STATUS_SET_PRIVATE_ACCESS_FUND,
  MANAGE_FUNDING_STATUS_SET_READY_TO_FUND_INVESTMENTS,
  MANAGE_FUNDING_STATUS_SET_NOT_READY_TO_FUND_INVESTMENTS,
  MANAGE_FUNDING_STATUS_SET_NEXT_FUNDING_WINDOW,
  MANAGE_FUNDING_STATUS_UPDATE_OVERRIDE_FUNDS,
} from '../actionsConstants';

export const UPDATE_OVERRIDE_STATUS = createRequestTypes(
  MANAGE_FUNDING_STATUS_UPDATE_OVERRIDE_FUNDS
);

function getClosesUrl() {
  return 'closes/closes_for_fund';
}

function getAllowedStatusUrl(pafId) {
  return `private_access_funds/${pafId}/manage_funding_status/allowed_statuses`;
}

function getFilteredInvestmentsUrl(pafId) {
  return `private_access_funds/${pafId}/manage_funding_status/filtered_investments`;
}

function getSetReadyToFundUrl(pafId) {
  return `private_access_funds/${pafId}/manage_funding_status/set_ready_to_fund`;
}

function getOverrideFieldUrl(pafId) {
  return `private_access_funds/${pafId}/manage_funding_status/update_override_field`;
}

function setSelectableStatuses(statuses) {
  return {
    type: MANAGE_FUNDING_STATUS_SET_SELECTABLE_STATUSES,
    payload: statuses,
  };
}

function setSelectedStatuses(statuses) {
  return {
    type: MANAGE_FUNDING_STATUS_SET_SELECTED_STATUSES,
    payload: statuses,
  };
}

function setPrivateAccessFund(paf) {
  return {
    type: MANAGE_FUNDING_STATUS_SET_PRIVATE_ACCESS_FUND,
    payload: paf,
  };
}

function setReadyToFundInvestments(investments) {
  return {
    type: MANAGE_FUNDING_STATUS_SET_READY_TO_FUND_INVESTMENTS,
    payload: investments,
  };
}

function setNotReadyToFundInvestments(investments) {
  return {
    type: MANAGE_FUNDING_STATUS_SET_NOT_READY_TO_FUND_INVESTMENTS,
    payload: investments,
  };
}

function setNextFundingWindow(fundingWindow) {
  return {
    type: MANAGE_FUNDING_STATUS_SET_NEXT_FUNDING_WINDOW,
    payload: fundingWindow,
  };
}

function getNextCloseDateWindow(pafId) {
  return (dispatch) => {
    const url = getClosesUrl(pafId);

    dispatch(spinnerActions.beginApiRequest());

    return axios.get(url, {
      params: { fund_id: pafId, status: 'next_close' },
      withCredentials: true,
    }).then((response) => {
      const closes = response.data;
      if (closes.length > 0) {
        const nextClose = closes[0];

        if (nextClose.funding_window_start !== null && nextClose.funding_window_end !== null) {
          const fundingWindow = `${nextClose.funding_window_start} – ${nextClose.funding_window_end}`;
          dispatch(setNextFundingWindow(fundingWindow));
        }
      }

      dispatch(spinnerActions.endApiRequest());
    });
  };
}

function loadInvestments(pafId, approvalStatuses = null) {
  return (dispatch) => {
    const url = getFilteredInvestmentsUrl(pafId);

    dispatch(spinnerActions.beginApiRequest());

    return axios.get(url, {
      params: { approval_statuses: approvalStatuses },
      withCredentials: true,
    }).then((response) => {
      const investments = response.data.investments;
      const readyInvestments = _.filter(investments, ['ready_to_fund', 'Yes']);
      const notReadyInvestments = _.filter(investments, ['ready_to_fund', 'No']);

      dispatch(setPrivateAccessFund(response.data.paf));
      dispatch(setReadyToFundInvestments(readyInvestments));
      dispatch(setNotReadyToFundInvestments(notReadyInvestments));
      dispatch(spinnerActions.endApiRequest());
    });
  };
}

function allowedStatuses(pafId) {
  return (dispatch) => {
    const url = getAllowedStatusUrl(pafId);

    dispatch(spinnerActions.beginApiRequest());

    return axios.get(url, {
      params: {},
      withCredentials: true,
    }).then((response) => {
      let statusItem = null;
      const statuses = response.data;

      if (statuses.length > 0) {
        const index = _.findIndex(statuses, ['id', 'approved']);
        if (index !== -1) {
          statusItem = statuses[index];
        } else {
          statusItem = statuses[0];
        }
      }

      dispatch(setSelectableStatuses(statuses));

      if (statusItem !== null) {
        dispatch(setSelectedStatuses([statusItem]));
      }

      dispatch(spinnerActions.endApiRequest());
    });
  };
}

function setReadyToFund(pafId, investmentIds, status, investmentStatuses) {
  return (dispatch) => {
    const url = getSetReadyToFundUrl(pafId);

    dispatch(spinnerActions.beginApiRequest());

    return axios.patch(url, { investment_ids: investmentIds, status }, {
      withCredentials: true,
    }).then(() => {
      dispatch(loadInvestments(pafId, investmentStatuses));
    }).catch(() => {
      notificationService.notifyError(
        '',
        'Failed to update funding status.'
      );
    }).finally(() => {
      dispatch(spinnerActions.endApiRequest());
    });
  };
}

function updateOverrideFunds(investmentId, pafId, overrideValue, isFundingWindow) {
  const url = getOverrideFieldUrl(pafId);
  const overrideFieldName = isFundingWindow ? 'override_funding_window' : 'override_insufficient_funds';
  const data = {
    investment_id: investmentId,
    override_field_name: overrideFieldName,
    override_value: overrideValue,
  };

  return patchRequest({
    url,
    method: 'patch',
    data,
    actions: UPDATE_OVERRIDE_STATUS,
    requestParams: data,
    errorMessage: {
      title: '',
      message: `Failed to update ${overrideFieldName}`,
    },
  });
}

export default {
  setSelectedStatuses,
  allowedStatuses,
  getNextCloseDateWindow,
  loadInvestments,
  setReadyToFund,
  updateOverrideFunds,
};
