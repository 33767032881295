import { actionFactory } from 'services/actions';
import { getRequest, postRequest } from 'actions/utils';
import {
  TOU_SET_TERMS,
  TOU_SET_ERROR_MESSAGE,
} from 'actions/actionsConstants';

const DEFAULT_ERROR_MESSAGE = 'There was an unexpected error, you are not able to accept the terms at this time. Please email support@icapitalnetwork.com or call 212-994-7333 for assistance.';

const baseUrl = '/terms_of_uses/';
const activeMainSiteTerms = 'active_main_site_terms';
const activeWlpTerms = 'active_wlp_terms/';

const userAcceptedTermsUrl = '/user_accepted_terms/';
const acceptTermsUrl = 'accept_terms/';

const touSetTerms = actionFactory(TOU_SET_TERMS);
const setAcceptTermsErrorMessage = actionFactory(TOU_SET_ERROR_MESSAGE);

function getActiveMainSiteTerm() {
  return getRequest({
    url: baseUrl + activeMainSiteTerms,
    onSuccess: touSetTerms,
  });
}

function getActiveWlpTerm(wlpId) {
  return getRequest({
    url: baseUrl + activeWlpTerms,
    params: { wlp_id: wlpId },
    onSuccess: touSetTerms,
  });
}

function acceptTermsOfUse(termsId, translationId = null) {
  return postRequest({
    url: userAcceptedTermsUrl + acceptTermsUrl,
    params: { terms_id: termsId, translation_id: translationId },
    withCredenticals: true,
    onFailure: () => setAcceptTermsErrorMessage(DEFAULT_ERROR_MESSAGE),
  });
}

function resetErrorMessage() {
  return (dispatch) => {
    dispatch(setAcceptTermsErrorMessage(''));
  };
}

export default {
  getActiveMainSiteTerm,
  getActiveWlpTerm,
  acceptTermsOfUse,
  resetErrorMessage,
};
