export default {
  representations: [],
  rias_firms: [],
  funds: [],
  funds_list: [],
  firms_list: [],
  white_label_partners: [],
  wlps_list: [],
  representations_count: 0,
  selected_wlps: [],
  selected_funds: [],
  selected_rias_firms: [],
  selected_representation_type: [],
  results_per_page: 25,
  current_page: 1,
  sorting_column: 'white_label_partner_name',
  sorting_order: 'asc',
  active_modal: null,
  selected_row: null,
};
