import { createAction } from 'redux-actions';
import { getRequest, postRequest } from 'actions/utils';
import {
  SHARE_CLASS_PERFORMANCE_REPORT_TYPES,
  INDEX_PERFORMANCE_REPORT_TYPES,
} from 'constants/synthesis';
import { SHARE_CLASS_PERFORMANCE_RETURN_TYPES } from 'components/fund_finances/filters/constants';

import shClassPerfColumns from 'components/fund_finances/tables/share_class_performances/gridDefs.js';

import {
  EXPORT_MODAL_SYNTHESIS_SET_INDEX_MASTERS,
  EXPORT_MODAL_SYNTHESIS_SET_PERIOD,
  EXPORT_MODAL_SYNTHESIS_SET_SHARE_CLASSES,
  EXPORT_MODAL_SYNTHESIS_SET_REPORT_TYPES,
  EXPORT_MODAL_SYNTHESIS_SET_RETURN_TYPES,
  EXPORT_MODAL_SYNTHESIS_SET_FUND_GROUPS,
  EXPORT_MODAL_SYNTHESIS_SET_FUNDS,
  EXPORT_MODAL_SYNTHESIS_LOAD_INDEX_MASTER_LIST,
  EXPORT_MODAL_SYNTHESIS_LOAD_SHARE_CLASS_LIST,
  EXPORT_MODAL_SYNTHESIS_LOAD_FUND_GROUP_LIST,
  EXPORT_MODAL_SYNTHESIS_LOAD_FUND_LIST,
} from '../actionsConstants';

const synthesisModalSetIndexMasters = createAction(EXPORT_MODAL_SYNTHESIS_SET_INDEX_MASTERS);
const synthesisModalSetShareClasses = createAction(EXPORT_MODAL_SYNTHESIS_SET_SHARE_CLASSES);
const synthesisModalSetPeriod = createAction(EXPORT_MODAL_SYNTHESIS_SET_PERIOD);
const synthesisModalSetReportTypes = createAction(EXPORT_MODAL_SYNTHESIS_SET_REPORT_TYPES);
const synthesisModalSetReturnTypes = createAction(EXPORT_MODAL_SYNTHESIS_SET_RETURN_TYPES);
const synthesisModalSetFundGroups = createAction(EXPORT_MODAL_SYNTHESIS_SET_FUND_GROUPS);
const synthesisModalSetFunds = createAction(EXPORT_MODAL_SYNTHESIS_SET_FUNDS);
const syntModalSetIndexMasterList = createAction(EXPORT_MODAL_SYNTHESIS_LOAD_INDEX_MASTER_LIST);
const syntModalSetFundGroupList = createAction(EXPORT_MODAL_SYNTHESIS_LOAD_FUND_GROUP_LIST);
const syntModalSetShareClassList = createAction(EXPORT_MODAL_SYNTHESIS_LOAD_SHARE_CLASS_LIST);
const syntModalSetFundList = createAction(EXPORT_MODAL_SYNTHESIS_LOAD_FUND_LIST);

function exportSynthesisData(url, params) {
  return postRequest({
    url,
    data: params,
    withCredentials: true,
    errorMessage: {
      title: 'Synthesis Export',
      message: 'An error occurred while exporting.',
    },
  });
}

function exportIndexReturns() {
  return (dispatch, getState) => {
    const state = getState().synthesisExportModal;
    const params = {
      index_master_ids: state.indexMasters,
      selected_period: state.period,
    };
    return dispatch(exportSynthesisData('/fund_finances/index_returns/export_performance', params));
  };
}

const getReportTypes = (list, selected) => (
  list.filter((rep) => selected.includes(rep.id)).map((elem) => elem.reportName)
);

const getReturnTypes = (list, selected) => (
  list.filter((type) => selected.includes(type.id)).map((t) => t.id)
);

const getVisibleColumns = (columnsToCheck) => (
  columnsToCheck.filter((col) => !col.hide).map((col) => col.colId)
);

const getSelectedColumns = (visibleColumns, allColumns) => {
  const definitions = allColumns.reduce((a, v) => ({ ...a, [v.field]: v.headerName }), {});
  return visibleColumns.map((elem) => [elem, definitions[elem]]);
};

function exportIndexPerfomance() {
  return (dispatch, getState) => {
    const state = getState().synthesisExportModal;
    const shareClassesSelected = [...state.shareClasses];
    if (shareClassesSelected.includes(-1)) shareClassesSelected.shift();
    const reportTypes = getReportTypes(INDEX_PERFORMANCE_REPORT_TYPES, state.reportTypes);
    const params = {
      fund_group_ids: state.fundGroups,
      fund_ids: state.funds,
      index_master_ids: state.indexMasters,
      share_classes_ids: shareClassesSelected,
      report_names: reportTypes,
      selected_period: state.period,
    };
    return dispatch(exportSynthesisData('/fund_finances/index_performances/export_performance', params));
  };
}

function exportShareClassPerformance() {
  return (dispatch, getState) => {
    const state = getState().synthesisExportModal;
    const stateFundFinance = getState().fundFinancesSynthesis;
    const shareClassesSelected = [...state.shareClasses];
    if (shareClassesSelected.includes(-1)) shareClassesSelected.shift();
    const reportTypes = getReportTypes(SHARE_CLASS_PERFORMANCE_REPORT_TYPES, state.reportTypes);
    const returnTypes = getReturnTypes(SHARE_CLASS_PERFORMANCE_RETURN_TYPES, state.returnTypes);
    const visibleColumns = getVisibleColumns(stateFundFinance.gridState);
    const allColumns = shClassPerfColumns;
    const selectedColumns = getSelectedColumns(visibleColumns, allColumns);
    const params = {
      fund_group_ids: state.fundGroups,
      fund_ids: state.funds,
      share_classes_ids: shareClassesSelected,
      report_names: reportTypes,
      return_types: returnTypes,
      selected_period: state.period,
      selected_columns: selectedColumns,
    };
    return dispatch(exportSynthesisData('/fund_finances/share_class_performances/export_performance', params));
  };
}

function synthesisModalLoadShareClassList(fundIds) {
  return getRequest({
    url: '/fund_finances/share_classes',
    showSpinner: false,
    onSuccess: syntModalSetShareClassList,
    params: { fund_ids: fundIds },
    errorMessage: {
      message: 'An error occurred while fetching the share classes list.',
    },
  });
}

function exportProFormaReturns() {
  return (dispatch, getState) => {
    const state = getState().synthesisExportModal;
    const shareClassesSelected = [...state.shareClasses];
    if (shareClassesSelected.includes(-1)) shareClassesSelected.shift();
    const params = {
      fund_group_ids: state.fundGroups,
      fund_ids: state.funds,
      share_classes_ids: shareClassesSelected,
      selected_period: state.period,
    };
    return dispatch(exportSynthesisData('/fund_finances/pro_forma_returns/export_performance', params));
  };
}

function initExportListAsPagesLists() {
  return (dispatch, getState) => {
    const state = getState().fundFinancesSynthesis;
    dispatch(syntModalSetFundGroupList(state.fundGroupsList));
    dispatch(syntModalSetFundList(state.fundsList));
    dispatch(syntModalSetIndexMasterList(state.indexMastersList));
    dispatch(syntModalSetShareClassList(state.shareClassesList));
    return Promise.resolve();
  };
}

function initExportValuesAsPagesValues() {
  return (dispatch, getState) => {
    const state = getState().fundFinancesSynthesis;
    dispatch(synthesisModalSetFundGroups(state.fundGroups));
    dispatch(synthesisModalSetFunds(state.funds));
    dispatch(synthesisModalSetIndexMasters(state.indexMasters));
    dispatch(synthesisModalSetShareClasses(state.shareClasses));
    dispatch(synthesisModalSetPeriod(state.period));
    dispatch(synthesisModalSetReportTypes([]));
    dispatch(synthesisModalSetReturnTypes(state.returnTypes));
    return Promise.resolve();
  };
}

export default {
  synthesisModalSetIndexMasters,
  synthesisModalSetShareClasses,
  synthesisModalSetPeriod,
  synthesisModalSetReportTypes,
  synthesisModalSetReturnTypes,
  synthesisModalSetFundGroups,
  synthesisModalSetFunds,
  synthesisModalLoadShareClassList,
  exportIndexReturns,
  exportIndexPerfomance,
  exportShareClassPerformance,
  initExportListAsPagesLists,
  initExportValuesAsPagesValues,
  exportProFormaReturns,
};
