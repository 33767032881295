import { fundReducer } from './reducers';

import initialState from './initialState';

const fn = [fundReducer];

export const reducer = (state = initialState, action = {}) => (
  fn.reduce((stateAccumulator, fnc) => fnc(stateAccumulator, action), state)
);

export default reducer;
