import React from 'react';
import {
  bool, string, shape, func,
} from 'prop-types';
import FieldConfiguration from 'containers/investor_profile/field_configuration/FieldConfiguration';
import FieldWrap from 'components/shared/icn_form/field_wrap/FieldWrap';
import {
  Box,
  InputAdornment,
  Skeleton,
  TextField,
  Typography,
  Tooltip,
} from '@icapitalnetwork/supernova-core';
import { useTheme } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import {
  advisorCrdNumber, isRegisteredRepresentative, iaWithSeries65, FINANCIAL_ADVISOR_ROLE,
} from '../profileData';

const IndividualCrdNumber = ({
  isRegistrationLoading,
  profileData,
  updateStore,
  hasHeadlineText,
  isPopup,
  hideAdornment,
}) => {
  const theme = useTheme();

  const onChange = ({ target: { value } }) => updateStore({ [advisorCrdNumber.question_key]: value });

  // Advisor CRD Number is required on FA questionnaire if role is FA and firm located in US
  const crdNumberAdornment = !(
    profileData[isRegisteredRepresentative.question_key] ||
    profileData[iaWithSeries65.question_key] ||
    profileData.role === FINANCIAL_ADVISOR_ROLE
  ) || (!isPopup && !profileData.firm_confirm_us_location)
    ? {
      endAdornment: (
        <InputAdornment position="end">
          <Typography variant="smallParagraph" color="text.lighter">
            If Applicable
          </Typography>
        </InputAdornment>
      ),
    } : null;

  return (
    <>
      <Typography variant={hasHeadlineText ? 'h5' : 'paragraph'} paddingBottom={theme.spacing(1)}>
        {isRegistrationLoading ? <Skeleton width="10%" /> : advisorCrdNumber.text}
      </Typography>
      <FieldWrap size="full">
        <FieldConfiguration
          fieldName={advisorCrdNumber.question_key}
        >
          {(config) => (
            isRegistrationLoading ? <Skeleton height={theme.spacing(8)} /> : (
              <Box display="flex" columnGap={theme.spacing(1)} alignItems="center">
                <TextField
                  fullWidth
                  id={advisorCrdNumber.question_key}
                  label="Individual CRD Number"
                  value={profileData.advisor_crd_number || ''}
                  onChange={onChange}
                  disabled={config.readOnly}
                  InputProps={!hideAdornment && crdNumberAdornment}
                />
                <Tooltip title={advisorCrdNumber.tooltip} variant="light">
                  <InfoOutlined fontSize="small" />
                </Tooltip>
              </Box>
            ))}
        </FieldConfiguration>
      </FieldWrap>
    </>
  );
};

IndividualCrdNumber.propTypes = {
  isRegistrationLoading: bool,
  updateStore: func.isRequired,
  profileData: shape({
    advisor_crd_number: string,
  }).isRequired,
  hasHeadlineText: bool,
  isPopup: bool,
  hideAdornment: bool,
};

IndividualCrdNumber.defaultProps = {
  isRegistrationLoading: false,
  hasHeadlineText: false,
  isPopup: false,
  hideAdornment: false,
};

export default IndividualCrdNumber;
