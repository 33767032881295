// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MultiItemRenderer-module__container___AE9Ln{display:flex}.MultiItemRenderer-module__container___AE9Ln .MultiItemRenderer-module__info___dLstu{padding-left:1em;cursor:pointer}`, "",{"version":3,"sources":["webpack://./icn_react/src/components/shared/cell_renderers/MultiItemRenderer/MultiItemRenderer.module.scss"],"names":[],"mappings":"AAAA,6CACE,YAAA,CAEA,qFACE,gBAAA,CACA,cAAA","sourcesContent":[".container {\n  display: flex;\n\n  .info {\n    padding-left: 1em;\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `MultiItemRenderer-module__container___AE9Ln`,
	"info": `MultiItemRenderer-module__info___dLstu`
};
export default ___CSS_LOADER_EXPORT___;
