import { FALLBACK_ERROR_MESSAGE } from 'containers/client_management/constants';
import { patchRequest } from 'services/apiRequest';

export const assignInvestorProfile = ({ investmentId, transactionId, privateAccessFundId }) => patchRequest({
  errorMessage: FALLBACK_ERROR_MESSAGE,
  url: `/client_management/external_apis/investments/${investmentId}/assign_investor_profile`,
  data: {
    transaction_uuid: transactionId,
    private_access_fund_id: privateAccessFundId,
  },
});
