import update from 'immutability-helper';

import {
  RGA_LOAD_SCHEDULED_CONFIGS_SUCCESS,
  RGA_LOAD_SCHEDULED_CONFIGS_COUNT,
} from 'actions/actionsConstants';

import initialState from '../initialState';

export default (state = initialState, action) => {
  switch (action.type) {
    case RGA_LOAD_SCHEDULED_CONFIGS_SUCCESS:
      return {
        ...state,
        scheduledConfigs: update(state.scheduledConfigs, {
          $set: action.payload,
        }),
        loading: false,
      };
    case RGA_LOAD_SCHEDULED_CONFIGS_COUNT:
      return update(state, { scheduledConfigsCount: { $set: action.payload } });
    default:
      return state;
  }
};
