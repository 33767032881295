export const VERSION_STATUSES = {
  DRAFT: 'draft',
  LIVE: 'live',
  ARCHIVED: 'archived',
};

export const BASE_UI_SCHEMA = { subFields: [] };
export const BASE_SCHEMA = {
  $id: 'icn_form_templates/investor_profile_template_mock',
  type: 'object',
  $schema: 'http://json-schema.org/draft-07/schema#',
};

export const SUCCESS_CREATE_MESSAGE = 'Investor Profile successfully created';
export const ERROR_CREATE_MESSAGE = 'An error occurred while creating the Investor Profile. Please try again later.';

export default {
  VERSION_STATUSES,
  SUCCESS_CREATE_MESSAGE,
  ERROR_CREATE_MESSAGE,
  BASE_SCHEMA,
  BASE_UI_SCHEMA,
};
