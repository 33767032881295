import axios from 'axios';
import notificationService from 'services/notifications';
import spinnerActions from '../spinner_actions/spinnerActions';

import {
  NOTIFICATIONS_EVENTS_SET_NOTIFICATIONS_EVENT,
  NOTIFICATIONS_EVENTS_SET_NOTIFICATIONS_EVENTS,
  NOTIFICATIONS_EVENTS_DELETE_NOTIFICATION_EVENT,
  NOTIFICATIONS_EVENTS_SET_NOTIFICATION_EVENT_TYPES,
  NOTIFICATIONS_EVENTS_SET_PRIVATE_ACCESS_FUNDS,
  NOTIFICATIONS_EVENTS_SET_INTEGRATION_PARTNERS,
  NOTIFICATIONS_EVENTS_SET_TO_CONTACTABLES,
  NOTIFICATIONS_EVENTS_SET_CC_CONTACTABLES,
  NOTIFICATION_EVENTS_SET_LOADING_TO_CONTACTABLES,
  NOTIFICATION_EVENTS_SET_LOADING_CC_CONTACTABLES,
} from '../actionsConstants';

function setEvent(event) {
  return {
    type: NOTIFICATIONS_EVENTS_SET_NOTIFICATIONS_EVENT,
    payload: event,
  };
}

function setEvents(events) {
  return {
    type: NOTIFICATIONS_EVENTS_SET_NOTIFICATIONS_EVENTS,
    payload: events,
  };
}

function updateEvent(event) {
  return {
    type: NOTIFICATIONS_EVENTS_SET_NOTIFICATIONS_EVENT,
    payload: event,
  };
}

function deleteEvent(event) {
  return {
    type: NOTIFICATIONS_EVENTS_DELETE_NOTIFICATION_EVENT,
    payload: event,
  };
}

function setEventTypes(eventTypes) {
  return {
    type: NOTIFICATIONS_EVENTS_SET_NOTIFICATION_EVENT_TYPES,
    payload: eventTypes,
  };
}

function setPrivateAccessFunds(funds) {
  return {
    type: NOTIFICATIONS_EVENTS_SET_PRIVATE_ACCESS_FUNDS,
    payload: funds,
  };
}

function setIntegrationPartners(partners) {
  return {
    type: NOTIFICATIONS_EVENTS_SET_INTEGRATION_PARTNERS,
    payload: partners,
  };
}

function neLoadedToContactables() {
  return {
    type: NOTIFICATION_EVENTS_SET_LOADING_TO_CONTACTABLES,
    payload: false,
  };
}

function neLoadingToContactables() {
  return {
    type: NOTIFICATION_EVENTS_SET_LOADING_TO_CONTACTABLES,
    payload: true,
  };
}

function setToContactables(contactables) {
  return {
    type: NOTIFICATIONS_EVENTS_SET_TO_CONTACTABLES,
    payload: contactables,
  };
}

function neLoadedCcContactables() {
  return {
    type: NOTIFICATION_EVENTS_SET_LOADING_CC_CONTACTABLES,
    payload: false,
  };
}

function neLoadingCcContactables() {
  return {
    type: NOTIFICATION_EVENTS_SET_LOADING_CC_CONTACTABLES,
    payload: true,
  };
}

function setCcContactables(contactables) {
  return {
    type: NOTIFICATIONS_EVENTS_SET_CC_CONTACTABLES,
    payload: contactables,
  };
}


function getNotificationEvent(id) {
  return (dispatch) => {
    const url = `notification_events/${id}`;
    const params = {};

    dispatch(spinnerActions.beginApiRequest());

    return axios.get(url, {
      params,
      withCredentials: true,
    }).then((response) => {
      const event = response.data;

      dispatch(setEvent(event));
      dispatch(spinnerActions.endApiRequest());
    }).catch(() => {
      dispatch(spinnerActions.endApiRequest());
    });
  };
}

function getNotificationEvents() {
  return (dispatch) => {
    const url = 'notification_events';
    const params = {};

    dispatch(spinnerActions.beginApiRequest());

    return axios.get(url, {
      params,
      withCredentials: true,
    }).then((response) => {
      const events = response.data;

      dispatch(setEvents(events));
      dispatch(spinnerActions.endApiRequest());
    }).catch(() => {
      dispatch(spinnerActions.endApiRequest());
    });
  };
}

function createNotificationEvent(notificationEventParams) {
  return (dispatch) => {
    const url = 'notification_events';
    const params = notificationEventParams;
    dispatch(spinnerActions.beginApiRequest());

    return axios.post(url, params, {
      withCredentials: true,
    }).then(() => {
      dispatch(spinnerActions.endApiRequest());

      notificationService.notifySuccess(
        '',
        'Notification Event was successfully created.'
      );
    }).catch((error) => {
      dispatch(spinnerActions.endApiRequest());
      const hasErrorMessage = error.data && error.data.errors;
      notificationService.notifyError(
        '',
        hasErrorMessage ? Object.values(error.data.errors)[0] : 'An error eccurred trying to create this event.'
      );
    });
  };
}

function updateNotificationEvent(notificationEventId, notificationEventParams) {
  return (dispatch) => {
    const url = `notification_events/${notificationEventId}`;
    const params = notificationEventParams;
    dispatch(spinnerActions.beginApiRequest());

    return axios.patch(url, params, {
      withCredentials: true,
    }).then((response) => {
      const event = response.data;

      dispatch(updateEvent(event));
      dispatch(spinnerActions.endApiRequest());

      notificationService.notifySuccess(
        '',
        'Notification Event was successfully updated.'
      );
    }).catch((error) => {
      dispatch(spinnerActions.endApiRequest());
      const hasErrorMessage = error.data && error.data.errors;
      notificationService.notifyError(
        '',
        hasErrorMessage ? Object.values(error.data.errors)[0] : 'An error eccurred trying to create this event.'
      );
    });
  };
}

function deleteNotificationEvent(notificationEventId) {
  return (dispatch) => {
    const url = `notification_events/${notificationEventId}`;
    const params = {};
    dispatch(spinnerActions.beginApiRequest());

    return axios.delete(url, {
      params,
      withCredentials: true,
    }).then((response) => {
      const event = response.data;

      dispatch(deleteEvent(event));
      dispatch(spinnerActions.endApiRequest());

      notificationService.notifySuccess(
        '',
        'Notificatin Event was successfully deleted.'
      );
    }).catch(() => {
      dispatch(spinnerActions.endApiRequest());

      notificationService.notifyError(
        '',
        'An error eccurred trying to delete this event.'
      );
    });
  };
}

function getNotificationEventTypes() {
  return (dispatch) => {
    const url = 'notification_events/event_types';
    const params = {};

    dispatch(spinnerActions.beginApiRequest());

    return axios.get(url, {
      params,
      withCredentials: true,
    }).then((response) => {
      const eventTypes = response.data;

      dispatch(setEventTypes(eventTypes));
      dispatch(spinnerActions.endApiRequest());
    }).catch(() => {
      dispatch(spinnerActions.endApiRequest());
    });
  };
}

function getNotificationEventsPrivateAccessFunds() {
  return (dispatch) => {
    dispatch(spinnerActions.beginApiRequest());
    return axios.get('private_access_funds/for_fund_admin', {
      withCredentials: true,
      params: {
        vintage: true,
      },
    }).then((response) => {
      const pafs = response.data;

      dispatch(setPrivateAccessFunds(pafs));
      dispatch(spinnerActions.endApiRequest());
    }).catch(() => {
      dispatch(spinnerActions.endApiRequest());
    });
  };
}

function getNotificationEventsIntegrationPartners() {
  return (dispatch) => {
    dispatch(spinnerActions.beginApiRequest());

    return axios.get('integration_partner', {
      withCredentials: true,
      params: {},
    }).then((response) => {
      const partners = response.data;

      dispatch(setIntegrationPartners(partners));
      dispatch(spinnerActions.endApiRequest());
    }).catch(() => {
      dispatch(spinnerActions.endApiRequest());
    });
  };
}

function getContactablesToList(string) {
  return (dispatch) => {
    const url = 'contactables';
    const params = { query: string };

    dispatch(neLoadingToContactables());

    return axios.get(url, {
      params,
      withCredentials: true,
    }).then((response) => {
      const contactables = response.data;

      dispatch(setToContactables(contactables));
      dispatch(neLoadedToContactables());
    }).catch(() => {
      dispatch(neLoadedToContactables());
    });
  };
}

function getContactablesCcList(string) {
  return (dispatch) => {
    const url = 'contactables';
    const params = { query: string };

    dispatch(neLoadingCcContactables());

    return axios.get(url, {
      params,
      withCredentials: true,
    }).then((response) => {
      const contactables = response.data;

      dispatch(setCcContactables(contactables));
      dispatch(neLoadedCcContactables());
    }).catch(() => {
      dispatch(neLoadedCcContactables());
    });
  };
}

export default {
  getNotificationEvent,
  getNotificationEvents,
  createNotificationEvent,
  updateNotificationEvent,
  deleteNotificationEvent,
  getNotificationEventTypes,
  getNotificationEventsPrivateAccessFunds,
  getNotificationEventsIntegrationPartners,
  getContactablesToList,
  getContactablesCcList,
};
