export const RANGE_SELECT_DATA = {
  today: { value: 'today', label: 'Today' },
  now: { value: 'now', label: 'Now' },
  fix_date: { value: 'date', label: 'Date' },
};
export const SIGN_SELECT_DATA = {
  minus: { value: 'minus', label: 'Minus' },
  plus: { value: 'plus', label: 'Plus' },
};
export const DURATION_SELECT_DATA = {
  days: { value: 'days', label: 'Days' },
  weeks: { value: 'weeks', label: 'Weeks' },
  months: { value: 'months', label: 'Months' },
  years: { value: 'years', label: 'Years' },
};

export const MONTH_SPAN_SELECT_DATA = {
  exact: { value: '', label: 'Exact day' },
  beginning: { value: 'start', label: 'Start of Month' },
  end: { value: 'end', label: 'End of Month' },
};

export const FIX_DATE_DURATION_VALUE = 'fix_date';
