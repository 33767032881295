import { noValue } from 'services/investor_profile';
import _ from 'lodash';

import {
  signersUniquenessValidations,
} from 'actions/investor_profile_actions/helpers/errors/validations/index';

const sortByValue = (compareFields, profile, uniqueVals, valsHash) => {
  compareFields.forEach((field) => {
    let val = _.get(profile, field);

    if (_.isString(val)) {
      val = val.toLowerCase();
    }

    if (noValue(val)) {
      uniqueVals.push(field);
    } else {
      valsHash[val] = valsHash[val] || [];
      valsHash[val].push(field);
    }
  });
};

const sortByUniqueness = (valsHash, uniqueVals, duplicateVals) => {
  Object.values(valsHash).forEach((fields) => {
    if (fields.length <= 1) {
      uniqueVals = [...uniqueVals, ...fields];
    } else {
      duplicateVals = [...duplicateVals, ...fields];
    }
  });

  return [uniqueVals, duplicateVals];
};

export const checkUniquenessValidations = (field, profile, validationsObj) => {
  let duplicateVals = [];
  let uniqueVals = [];
  const valsHash = {};

  if (!field) { return { uniqueVals, duplicateVals, errorMsg: null }; }

  const { compareFields, errorMsg } = validationsObj[field] || {};

  // exit if no validations exist for field
  if (!compareFields) { return { uniqueVals, duplicateVals, errorMsg }; }

  sortByValue(compareFields, profile, uniqueVals, valsHash);

  [uniqueVals, duplicateVals] = sortByUniqueness(valsHash, uniqueVals, duplicateVals);

  return { uniqueVals, duplicateVals, errorMsg };
};

export const checkSignersUniquenessValidations = (field, profile) => checkUniquenessValidations(field, profile, signersUniquenessValidations);
