import { createAction } from 'redux-actions';
import { postRequest } from 'actions/utils';
import {
  REFRESH_DATE_SELECTION,
  REFRESH_MODE_SELECTION,
} from '../actionsConstants';

const refreshSetDateSince = createAction(REFRESH_DATE_SELECTION);
const refreshSetRefreshMode = createAction(REFRESH_MODE_SELECTION);

function refreshSynthesisData(url, params) {
  return postRequest({
    url,
    data: params,
    withCredentials: true,
    errorMessage: {
      title: 'Synthesis Refresh Data',
      message: 'An error occurred while refreshing data.',
    },
  });
}

function refreshIndexReturns() {
  return (dispatch, getState) => {
    const state = getState().synthesisExportModal;
    const params = {
      index_master_ids: state.indexMasters,
      since: state.since,
    };
    return dispatch(refreshSynthesisData('/fund_finances/index_returns/refresh_returns', params));
  };
}

function refreshIndexPerfomance() {
  return (dispatch, getState) => {
    const state = getState().synthesisExportModal;
    const shareClassesSelected = [...state.shareClasses];
    if (shareClassesSelected.includes(-1)) shareClassesSelected.shift();
    const params = {
      fund_group_ids: state.fundGroups,
      fund_ids: state.funds,
      index_master_ids: state.indexMasters,
      share_classes_ids: shareClassesSelected,
      since: state.since,
    };
    return dispatch(refreshSynthesisData('/fund_finances/index_performances/refresh_performance', params));
  };
}

function refreshShareClassPerformance() {
  return (dispatch, getState) => {
    const state = getState().synthesisExportModal;
    const shareClassesSelected = [...state.shareClasses];
    if (shareClassesSelected.includes(-1)) shareClassesSelected.shift();
    const params = {
      fund_group_ids: state.fundGroups,
      fund_ids: state.funds,
      share_classes_ids: shareClassesSelected,
      since: state.since,
      refresh_mode: state.refreshMode,
    };
    return dispatch(refreshSynthesisData('/fund_finances/share_class_performances/refresh_performance', params));
  };
}

export default {
  refreshSetDateSince,
  refreshSetRefreshMode,
  refreshIndexReturns,
  refreshIndexPerfomance,
  refreshShareClassPerformance,
};
