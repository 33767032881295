import update from 'immutability-helper';
import API_DASHBOARD_ACTIONS from '../../actionsConstants';

const initialState = {
  tab: 0,
  apiProcessBridgeIds: {},
};
const dashboardPageReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case API_DASHBOARD_ACTIONS.SET_TAB:
      return update(state, {
        tab: {
          $set: payload,
        },
      });
    case API_DASHBOARD_ACTIONS.GET_API_PROCESS_BRIDGE_IDS:
      return update(state, {
        apiProcessBridgeIds: {
          $set: payload,
        },
      });
    default:
      return state;
  }
};

export default dashboardPageReducer;
