import React from 'react';
import PropTypes from 'prop-types';
import HoneybadgerErrorBoundary from '@honeybadger-io/react';
import Honeybadger from '@honeybadger-io/js';
import { disableThirdPartyJs } from 'services/bootstrapServices';
import configuration from 'configuration';

const environment = configuration.RAILS_ENV || process.env.NODE_ENV;
const reportErrors = environment === 'production' || environment === 'staging';

const honeybadgerConfiguration = {
  apiKey: configuration.HONEYBADGER_JS_API_KEY,
  environment,
  revision: configuration.GIT_COMMIT_HASH,

  enableUnhandledRejection: reportErrors,
  enableUncaught: reportErrors,
  reportData: reportErrors,
  debug: false,
  disabled: environment === 'test' || environment === 'development',
};

const honeybadger = Honeybadger.configure(honeybadgerConfiguration);
Honeybadger.beforeNotify(() => !disableThirdPartyJs());

export const ErrorBoundary = (props) => (
  <HoneybadgerErrorBoundary honeybadger={honeybadger} {...props}>
    {props.children}
  </HoneybadgerErrorBoundary>
);

ErrorBoundary.propTypes = {
  children: PropTypes.element.isRequired,
};

export const notify = (error, noticeBody) => {
  Honeybadger.notify(error, noticeBody);
};

export const setNotificationContext = (context) => {
  Honeybadger.setContext(context);
};

export const clearNotificationContext = () => {
  Honeybadger.clearContext();
};
