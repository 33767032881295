import update from 'immutability-helper';

import {
  STATEMENTS_SET_DOCUMENT_EXPORTS,
  STATEMENTS_SET_RESULTS_PER_PAGE,
  STATEMENTS_SET_SELECTED,
  STATEMENTS_SET_INVESTORS,
} from 'actions/actionsConstants';

export const getExportsCount = (state) => state.document_exports.total;

export const initialState = {
  document_exports: {
    exports: [],
    total: 0,
  },
  results_per_page: 25,
  selectable_ids: [],
  selected_ids: [],
  investors_ids: [],
};

export default function statementsReducer(state = initialState, action) {
  switch (action.type) {
    case STATEMENTS_SET_DOCUMENT_EXPORTS:
      return update(state, {
        document_exports: { $set: action.payload },
      });
    case STATEMENTS_SET_RESULTS_PER_PAGE:
      return update(state, {
        results_per_page: { $set: action.per_page },
        current_page: { $set: { [action.fund_id]: 0 } },
      });
    case STATEMENTS_SET_SELECTED:
      return update(state, {
        selected_ids: { $set: action.payload },
      });
    case STATEMENTS_SET_INVESTORS:
      return update(state, {
        investors_ids: { $set: action.payload },
      });
    default:
      return state;
  }
}
