/* eslint-disable max-statements */
/* eslint-disable max-lines-per-function */
import update from 'immutability-helper';
import {
  LP_SET_INSIGHT_EDIT,
  LP_SET_INSIGHT_UPDATE,
  LP_SET_INSIGHT_EDIT_DIALOG_IS_OPEN,
  LP_SET_INSIGHT_EDIT_CHECK_FOR_ERRORS,
  LP_SET_INSIGHT_EDIT_UPDATED,
  LP_SET_INSIGHT_EDIT_ID,
  LP_SET_INSIGHT_EDIT_MODE,
  LP_SET_INSIGHT_EDIT_NEW,
} from 'modules/landing_page/actions/constants';
import initialState from '../initialState';

// eslint-disable-next-line default-param-last
export default (state = initialState, { type, payload = {} }) => {
  let newState = {};
  switch (type) {
    case LP_SET_INSIGHT_EDIT: {
      newState = {
        insights: {
          edit: {
            initialState: { $set: { ...payload.insight } },
            options: { $set: { ...payload.options } },
            checkForErrors: { $set: false },
            changed: { $set: false },
          },
        },
      };
      break;
    }
    case LP_SET_INSIGHT_EDIT_NEW: {
      newState = {
        insights: {
          edit: {
            initialState: { $set: { ...initialState?.insights?.edit?.initialState } },
            checkForErrors: { $set: false },
            changed: { $set: false },
          },
        },
      };
      break;
    }
    case LP_SET_INSIGHT_UPDATE: {
      const changed = !(payload.error);
      newState = {
        insights: {
          edit: {
            changed: { $set: changed },
          },
        },
      };
      break;
    }
    case LP_SET_INSIGHT_EDIT_DIALOG_IS_OPEN: {
      newState = {
        insights: {
          edit: {
            dialogOpen: { $set: payload },
          },
        },
      };
      break;
    }
    case LP_SET_INSIGHT_EDIT_CHECK_FOR_ERRORS: {
      newState = {
        insights: {
          edit: {
            checkForErrors: { $set: payload },
          },
        },
      };
      break;
    }
    case LP_SET_INSIGHT_EDIT_UPDATED: {
      newState = {
        insights: {
          edit: {
            updatedState: { $set: payload },
          },
        },
      };
      break;
    }
    case LP_SET_INSIGHT_EDIT_ID: {
      newState = {
        insights: {
          edit: {
            insightId: { $set: payload },
          },
        },
      };
      break;
    }
    case LP_SET_INSIGHT_EDIT_MODE: {
      newState = {
        insights: {
          edit: {
            mode: { $set: payload },
          },
        },
      };
      break;
    }
    default:
      newState = null;
  }
  return newState ? update(state, newState) : state;
};
