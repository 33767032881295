export function normalizeCurrentPage(newPage, totalCount, perPage) {
  const lastPage = Math.ceil(totalCount / perPage);
  const page = Math.min(newPage, lastPage);
  return Math.max(page, 1);
}

export const RESULTS_PER_PAGE = [
  {
    value: '5',
    text: '5',
  },
  {
    value: '10',
    text: '10',
  },
  {
    value: '15',
    text: '15',
  },
  {
    value: '20',
    text: '20',
  },
  {
    value: '25',
    text: '25',
  },
];
