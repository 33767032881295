import { postRequest } from 'actions/utils';
import 'reducers/investor_representative_reducers/selectors';

const registrationUrl = 'registration/';
const loginUrl = 'login.json';

const login = (user, queryString, showSpinner = true) => postRequest({
  showSpinner,
  url: loginUrl,
  data: {
    query_string: queryString,
    user,
  },
});

const getUserEmail = (resetPasswordToken) => {
  return postRequest({
    url: `${registrationUrl}get_user_email_from_token`,
    data: {
      reset_password_token: resetPasswordToken,
    },
  });
};

const setPassword = (resetPasswordToken, password, confirmPassword) => {
  return postRequest({
    url: `${registrationUrl}set_password`,
    data: {
      reset_password_token: resetPasswordToken,
      password,
      password_confirmation: confirmPassword,
    },
  });
};

export default {
  getUserEmail,
  setPassword,
  login,
};
