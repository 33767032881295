import { getRequest, patchRequest, postRequest } from 'services/apiRequest';
import { cancelInvestment } from 'services/client_management/requests/cancelInvestment';
import { FALLBACK_ERROR_MESSAGE } from 'containers/client_management/constants';
import { TYPES } from 'components/client_management/modals/create_investment/constants';

/**
 * PRIVATE FUNCTIONS
 */

const createForInvestorProfile = (pafId, data, requestParams = {}) => patchRequest({
  errorMessage: FALLBACK_ERROR_MESSAGE,
  url: `private_access_funds/${pafId}/investments/create_for_investor_profile`,
  data,
  ...requestParams,
});

const createForContactCard = (pafId, data, requestParams = {}) => patchRequest({
  errorMessage: FALLBACK_ERROR_MESSAGE,
  url: `private_access_funds/${pafId}/investments/create_for_contact_card`,
  data,
  ...requestParams,
});

const createForNewInvestor = (pafId, data, requestParams = {}) => postRequest({
  errorMessage: FALLBACK_ERROR_MESSAGE,
  url: `private_access_funds/${pafId}/investments`,
  data,
  ...requestParams,
});

const createForProspectInvestorProfile = (pafId, data, requestParams = {}) => patchRequest({
  errorMessage: FALLBACK_ERROR_MESSAGE,
  url: `private_access_funds/${pafId}/investments/create_for_prospect_investor_profile`,
  data,
  ...requestParams,
});

const getSubmitRequest = (type, isExistingProfile) => {
  switch (type) {
    case TYPES.ACCOUNT:
    case TYPES.PROSPECT_ACCOUNT:
    case TYPES.EXISTING_INVESTOR:
      return isExistingProfile ? createForInvestorProfile : createForContactCard;
    case TYPES.NEW_INVESTOR:
    case TYPES.NEW_PROSPECT:
      return createForNewInvestor;
    case TYPES.EXISTING_PROSPECT:
      return createForProspectInvestorProfile;
    default:
      throw new Error('Unknown submit type!');
  }
};

/**
 * PUBLIC FUNCTIONS
 */

export const createInvestment = ({
  investment,
  createType,
  pafId,
  wlpId,
  acceptedBlocks = undefined,
  requestParams = {},
}) => {
  const maybeCancelInvestment = investment.transferee_investment_id > 0
    ? cancelInvestment(investment.transferee_investment_id)
    : Promise.resolve();

  return maybeCancelInvestment.then(() => {
    const isExistingProfile = Boolean(investment.investor_profile_id);
    const request = getSubmitRequest(createType, isExistingProfile);
    return request(
      pafId,
      {
        privateAccessFundId: pafId,
        from_transfer: Boolean(investment.transfer_investment_id),
        wlp_id: wlpId,
        accepted_blocks: acceptedBlocks,
        investment,
      },
      requestParams
    );
  });
};

export const getInitialInvestment = (pafId, data) => postRequest({
  errorMessage: FALLBACK_ERROR_MESSAGE,
  url: `private_access_funds/${pafId}/investments/initial_investment`,
  data,
});

export const getInitialInvestmentPresent = (pafId, data) => postRequest({
  errorMessage: FALLBACK_ERROR_MESSAGE,
  url: `private_access_funds/${pafId}/investments/initial_investment_present`,
  data,
});

export const getAttestationRequired = (params) => getRequest({
  errorMessage: FALLBACK_ERROR_MESSAGE,
  url: 'firm_attestation_overrides/attestation_required',
  params,
});

export const createInvestorProfile = (data) => postRequest({
  errorMessage: FALLBACK_ERROR_MESSAGE,
  url: 'client_management/external_apis/investor_profiles',
  data,
});

export const createAddOn = ({
  privateAccessFundId, investmentId, amount, answers,
}) => {
  const investment = answers ? { questionnaire_answers: answers } : undefined;
  return patchRequest({
    url: `/private_access_funds/${privateAccessFundId}/investments/${investmentId}/create_additional_contribution`,
    data: {
      investment,
      amount,
      investmentId,
      privateAccessFundId,
      call_validations: false,
      date: true,
    },
    successMessage: { message: 'The additional subscription has been created.' },
    errorMessage: { message: 'The additional subscription was not created due to an error.' },
  });
};

export const getRiaClients = (riaId, pafId, wlpId) => getRequest({
  errorMessage: FALLBACK_ERROR_MESSAGE,
  url: `users/${riaId}/ria_clients`,
  params: {
    private_access_fund_id: pafId,
    with_canceled: true,
    wlp_id: wlpId,
  },
});

export const getProspects = () => getRequest({
  errorMessage: FALLBACK_ERROR_MESSAGE,
  url: 'investor_profiles/prospects',
});

export const getQuestionnaireExist = (pafId, wlpId, firmId) => getRequest({
  errorMessage: FALLBACK_ERROR_MESSAGE,
  url: 'required_pre_approval_questionnaires/for_investor',
  params: { private_access_fund_id: pafId, white_label_partner_id: wlpId, firm_id: firmId },
});

export const getExceptions = (pafId) => getRequest({
  errorMessage: FALLBACK_ERROR_MESSAGE,
  url: 'user_paf_exceptions/user_paf_unused_exceptions',
  params: { paf_id: pafId },
});

export const getInvestorProfiles = (contactCardId, pafId, advisorId = null) => getRequest({
  errorMessage: FALLBACK_ERROR_MESSAGE,
  url: `contact_cards/${contactCardId}/investor_profiles`,
  params: { private_access_fund_id: pafId, advisor_id: advisorId },
});

export const getQualifiedPurchasers = (pafId, wlpId) => getRequest({
  errorMessage: FALLBACK_ERROR_MESSAGE,
  url: `private_access_funds/${pafId}/contact_cards/qualified_purchasers`,
  params: { wlp_id: wlpId },
});

export const getAdvisorReferralCodes = (advisorId) => getRequest({
  errorMessage: FALLBACK_ERROR_MESSAGE,
  url: `users/${advisorId}/referral_codes`,
});

export const validateEmail = (pafId, email) => postRequest({
  url: `private_access_funds/${pafId}/investments/validate_new_investor_email`,
  data: { email },
});

export const createInvestmentBundle = (bundleSource, modelId, modelSource) => postRequest({
  errorMessage: FALLBACK_ERROR_MESSAGE,
  url: 'bulk_investment/investment_bundles',
  params: { bundle_source: bundleSource, model_id: modelId, model_source: modelSource },
});

export const isModelSale = (modelId, allocations) => getRequest({
  errorMessage: FALLBACK_ERROR_MESSAGE,
  url: `bulk_investment/model_portfolios/check_model_sale`,
  params: { model_id: modelId, allocations },
});
