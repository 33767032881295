import React from 'react';
import {
  string, func, bool,
} from 'prop-types';
import FieldConfiguration from 'containers/investor_profile/field_configuration/FieldConfiguration';
import {
  FormControl,
  MenuItem,
  Select,
  Skeleton,
  Typography,
} from '@icapitalnetwork/supernova-core';
import { useTheme } from '@mui/material';
import {
  registeredAsBDOrIA,
} from '../profileData';

function RegisteredAsBdOrRia({
  selectRegistrationStatus, selected, isRegistrationLoading, label, hasHeadlineText,
}) {
  const theme = useTheme();

  return (
    <>
      {!!label && (
        <Typography variant={hasHeadlineText ? 'h5' : undefined} paddingBottom={theme.spacing(2)}>
          {label}
        </Typography>
      )}
      <FieldConfiguration
        fieldName={registeredAsBDOrIA.question_key}
      >
        {(config) => (
          isRegistrationLoading ? <Skeleton width={theme.spacing(4)} /> : (
            <FormControl disabled={config.readOnly} fullWidth size="medium">
              <Select
                labelId={registeredAsBDOrIA.question_key}
                value={selected || ''}
                onChange={selectRegistrationStatus}
              >
                {registeredAsBDOrIA?.options?.map(({ text, value }) => (
                  <MenuItem key={value} value={value}>
                    {text}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ))}
      </FieldConfiguration>
    </>
  );
}

RegisteredAsBdOrRia.propTypes = {
  selectRegistrationStatus: func.isRequired,
  selected: string.isRequired,
  isRegistrationLoading: bool,
  label: string,
  hasHeadlineText: bool,
};

RegisteredAsBdOrRia.defaultProps = {
  isRegistrationLoading: false,
  label: '',
  hasHeadlineText: false,
};

export default RegisteredAsBdOrRia;
