import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const PassportIcon = forwardRef(({ color }, ref) => {
  const svg = `
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="1" width="23" height="23" rx="11.5" stroke="${color}" />
      <path
        d="M8.66 11.834L6 14.5007L8.66 17.1673V15.1673H13.3333V13.834H8.66V11.834ZM18 10.5007L15.34 7.83398V9.83398H10.6667V11.1673H15.34V13.1673L18 10.5007Z"
        fill="${color}"
      />
    </svg>
  `;

  const src = `data:image/svg+xml;base64,${btoa(svg)}`;

  return <img ref={ref} className="passport_menu" src={src} alt="passport menu" />;
});

PassportIcon.propTypes = {
  color: PropTypes.string.isRequired,
};

export default PassportIcon;
