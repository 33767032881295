import React from 'react';

const SiteNavMFE = () => (
  <div className="icn-header">
    <div
      id="single-spa-application:unified-sitenav"
      className="icn_wlp_header_bg_top"
    />
  </div>
);

export default SiteNavMFE;
