import { createAction } from 'redux-actions';
import { getRequest, postRequest, patchRequest, deleteRequest } from 'actions/utils';

import {
  SET_AUTHORIZATION_GROUPS,
  SET_WLPS_AG,
  SET_PERMISSIONS_AG,
  DELETE_AUTHORIZATION_GROUP,
  SET_GROUP_FOR_ACTION,
  SET_PERMISSION_FOR_GROUP,
} from '../actionsConstants';

const setAuthorizationGroups = createAction(SET_AUTHORIZATION_GROUPS);
const setWhiteLabelPartners = createAction(SET_WLPS_AG);
const setPermissions = createAction(SET_PERMISSIONS_AG);
const setGroupForAction = createAction(SET_GROUP_FOR_ACTION);
const deleteAuthGroup = createAction(DELETE_AUTHORIZATION_GROUP);
const setGroupPermissions = createAction(SET_PERMISSION_FOR_GROUP);

const getAuthorizationGroups =
  (userOptions = 'with') =>
  (dispatch) =>
    dispatch(
      getRequest({
        url: 'groups',
        params: {
          inclusion_of_users: userOptions,
          withCredentials: true,
        },
        onSuccess: setAuthorizationGroups,
      })
    );

const createAuthorizationGroup = (group) => (dispatch) =>
  dispatch(
    postRequest({
      url: 'groups',
      data: {
        group,
      },
      withCredentials: true,
      successMessage: { message: 'Group Created' },
      errorMessage: { title: 'Error', message: 'Failed to create group' },
    })
  );

const getPermissionsAuthorizationGroup = (id) => (dispatch) =>
  dispatch(
    getRequest({
      url: `groups/${id}/permissions`,
      params: {
        withCredentials: true,
      },
      onSuccess: (response) => setGroupPermissions(response.group_permissions),
    })
  );

const updatePermissionsAuthorizationGroup = (group, permissions) => (dispatch) =>
  dispatch(
    patchRequest({
      url: `groups/${group.id}`,
      data: {
        group: {
          ...group,
          permissions,
        },
        id: group.id,
      },
      withCredentials: true,
      successMessage: { message: 'Group updated' },
      errorMessage: { title: 'Error', message: 'Failed to update group' },
    })
  );

const deleteAuthorizationGroup = (id) => (dispatch) =>
  dispatch(
    deleteRequest({
      url: `groups/${id}`,
      data: {
        id,
      },
      withCredentials: true,
      onSuccess: () => deleteAuthGroup(id),
      successMessage: { message: 'Group deleted' },
      errorMessage: { title: 'Error', message: 'Failed to delete group' },
    })
  );

const getWhiteLabelPartners = () => (dispatch) =>
  dispatch(
    getRequest({
      url: 'white_label_partners',
      params: {
        withCredentials: true,
      },
      onSuccess: setWhiteLabelPartners,
    })
  );

const getPermissions = () => (dispatch) =>
  dispatch(
    getRequest({
      url: 'permissions',
      params: {
        withCredentials: true,
      },
      onSuccess: setPermissions,
    })
  );

export default {
  getAuthorizationGroups,
  getWhiteLabelPartners,
  getPermissions,
  createAuthorizationGroup,
  setGroupForAction,
  getPermissionsAuthorizationGroup,
  setGroupPermissions,
  updatePermissionsAuthorizationGroup,
  deleteAuthorizationGroup,
};
