import update from 'immutability-helper';

import {
  NOTIFICATIONS_EVENTS_SET_NOTIFICATIONS_EVENT,
  NOTIFICATIONS_EVENTS_SET_NOTIFICATIONS_EVENTS,
  NOTIFICATIONS_EVENTS_DELETE_NOTIFICATION_EVENT,
  NOTIFICATIONS_EVENTS_SET_NOTIFICATION_EVENT_TYPES,
  NOTIFICATIONS_EVENTS_SET_PRIVATE_ACCESS_FUNDS,
  NOTIFICATIONS_EVENTS_SET_INTEGRATION_PARTNERS,
  NOTIFICATIONS_EVENTS_SET_TO_CONTACTABLES,
  NOTIFICATIONS_EVENTS_SET_CC_CONTACTABLES,
  NOTIFICATION_EVENTS_SET_LOADING_TO_CONTACTABLES,
  NOTIFICATION_EVENTS_SET_LOADING_CC_CONTACTABLES,
} from 'actions/actionsConstants';

export const initialState = {
  notification_events: [],
  private_access_funds: [],
  integration_partners: [],
  notification_event: null,
  event_types: [],
  to_contactables: [],
  cc_contactables: [],
  to_contactables_loading: false,
  cc_contactables_loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case NOTIFICATIONS_EVENTS_SET_NOTIFICATIONS_EVENT:
      return update(state, {
        notification_event: { $set: action.payload },
      });

    case NOTIFICATIONS_EVENTS_SET_NOTIFICATIONS_EVENTS:
      return update(state, {
        notification_events: { $set: action.payload },
      });

    case NOTIFICATIONS_EVENTS_DELETE_NOTIFICATION_EVENT:
      return update(state, {
        notification_events: { $set: state.notification_events.filter((event) => event.id !== action.payload.id) },
      });

    case NOTIFICATIONS_EVENTS_SET_NOTIFICATION_EVENT_TYPES:
      return update(state, {
        event_types: { $set: action.payload },
      });

    case NOTIFICATIONS_EVENTS_SET_PRIVATE_ACCESS_FUNDS:
      return update(state, {
        private_access_funds: { $set: action.payload },
      });

    case NOTIFICATIONS_EVENTS_SET_INTEGRATION_PARTNERS:
      return update(state, {
        integration_partners: { $set: action.payload },
      });

    case NOTIFICATIONS_EVENTS_SET_TO_CONTACTABLES:
      return update(state, { to_contactables: { $set: action.payload } });

    case NOTIFICATIONS_EVENTS_SET_CC_CONTACTABLES:
      return update(state, { cc_contactables: { $set: action.payload } });

    case NOTIFICATION_EVENTS_SET_LOADING_TO_CONTACTABLES:
      return update(state, { to_contactables_loading: { $set: action.payload } });

    case NOTIFICATION_EVENTS_SET_LOADING_CC_CONTACTABLES:
      return update(state, { cc_contactables_loading: { $set: action.payload } });

    default:
      return state;
  }
}
