import i18n from 'i18n';

export * from './investment_statuses';

export const ICN_PER_PAGE_LOCAL_STORAGE_PROP = 'icn_doc_per_page';
export const AG_GRID_LICENSE =
  'iCapital_Network_iCapital_Platform_5Devs_2Deployment_29_March_2020__MTU4NTQ0MDAwMDAwMA==2aacecc7ab46e79f047e447179476808';
export const WLP_STYLE_NAMES = {
  doc_center: {
    saved_views: {
      save_button: 'wlp_dc_saved_views_save_button_background_color',
      cancel_button: 'wlp_dc_saved_views_cancel_button_background_color',
      bookmark_icon: 'wlp_dc_saved_views_bookmark_icon',
    },
  },
  fund_offerings: {
    paf_tile_item_top_bar: 'icn_wlp_paf_tile_item_top_bar',
    paf_list_item_left_bar: 'icn_wlp_paf_list_item_left_bar',
    paf_list_item_logo_background: 'icn_wlp_paf_list_item_logo_background',
    paf_list_item_accent_text_color: 'icn_wlp_paf_list_item_accent_text_color',
    paf_list_item_category_text: 'icn_wlp_paf_list_item_category_text',
    paf_tile_item_category_text: 'icn_wlp_paf_list_item_category_text',
    paf_tile_item_diligence_badge: 'icn_wlp_paf_tile_item_diligence_badge',
  },
};
export const PRIVATE_FIELD_MASK_ATTRIBUTE = 'data-private-field-mask';

export const PUSH_EVENTS = {
  EXPORT_STARTED: 'EXPORT_STARTED',
  EXPORT_FINISHED: 'EXPORT_FINISHED',
  EXPORT_FAILED: 'EXPORT_FAILED',
  JOB_STARTED: 'JOB_STARTED',
  JOB_COMPLETE: 'JOB_COMPLETE',
  JOB_SUCCESS: 'JOB_SUCCESS',
  JOB_FAILED: 'JOB_FAILED',
  IMPORT_FINISHED: 'IMPORT_FINISHED',
  IMPORT_FAILED: 'IMPORT_FAILED',
  EXPORT_DOWNLOAD: 'EXPORT_DOWNLOAD',
  FILE_DOWNLOAD: 'FILE_DOWNLOAD',
  WATERMARK_DOCUMENT_READY: 'WATERMARK_DOCUMENT_READY',
  WATERMARK_DOCUMENT_ERROR: 'WATERMARK_DOCUMENT_ERROR',
  REFRESH_STARTED: 'REFRESH_STARTED',
  REFRESH_ONGOING: 'REFRESH_ONGOING',
  REFRESH_FINISHED: 'REFRESH_FINISHED',
  EMAIL_STATUS_PROCESSING: 'EMAIL_STATUS_PROCESSING',
  EMAIL_STATUS_FINISHED: 'EMAIL_STATUS_FINISHED',
  AGREEMENT_PREVIEW_GENERATION_STARTED: 'AGREEMENT_PREVIEW_GENERATION_STARTED',
  AGREEMENT_PREVIEW_GENERATION_ERROR: 'AGREEMENT_PREVIEW_GENERATION_ERROR',
  AGREEMENT_PREVIEW_GENERATION_FINISHED: 'AGREEMENT_PREVIEW_GENERATION_FINISHED',
  INVESTMENT_DASHBOARD_PDF_REPORT_READY: 'INVESTMENT_DASHBOARD_PDF_REPORT_READY',
  INVESTMENT_DASHBOARD_PDF_REPORT_FAILED: 'INVESTMENT_DASHBOARD_PDF_REPORT_FAILED',
  INVESTMENT_DASHBOARD_PDF_REPORT_NO_DATA: 'INVESTMENT_DASHBOARD_PDF_REPORT_NO_DATA',
  PUBLISH_REPORT_DOCUMENTS_DONE: 'PUBLISH_REPORT_DOCUMENTS_DONE',
  WRAP_DOCUMENT_STARTED: 'WRAP_DOCUMENT_STARTED',
  WRAP_DOCUMENT_FINISHED: 'WRAP_DOCUMENT_FINISHED',
  WRAP_DOCUMENT_FAILED: 'WRAP_DOCUMENT_FAILED',
  ASYNC_AGREEMENT_GENERATING: 'ASYNC_AGREEMENT_GENERATING',
  ASYNC_AGREEMENT_GENERATED: 'ASYNC_AGREEMENT_GENERATED',
  ASYNC_AGREEMENT_ERROR: 'ASYNC_AGREEMENT_ERROR',
  ASYNC_AGREEMENT_GENERATED_NEEDS_ACTION: 'ASYNC_AGREEMENT_GENERATED_NEEDS_ACTION',
};

export const GET_AGREEMENT_STATUS_LABELS = () => ({
  SIGNED: i18n.t('common:signed', 'Signed'),
  NOT_SENT: i18n.t('common:not_sent', 'Not Sent'),
  GENERATING_AGREEMENT: i18n.t('common:generating_agreement', 'Generating Agreement'),
  PENDING_SIGNATURE: i18n.t('common:pending_signature', 'Pending Signature'),
  CANCELED: i18n.t('common:canceled', 'Canceled'),
  PENDING_UPLOAD: i18n.t('common:pending_upload', 'Pending Upload'),
  UPLOAD_SUBSCRIPTION: i18n.t('common:upload_subscription', 'Upload Subscription'),
  UPLOAD_TRANSFER_DOCUMENT: i18n.t('common:upload_transfer_document', 'Upload Transfer Document'),
  SEND_SUBSCRIPTION: i18n.t('common:send_subscription', 'Send Subscription'),
  SEND_DOCUMENT: i18n.t('common:send_document_one', 'Send Document'),
  PENDING_COMPLETION: i18n.t('common:pending_completion', 'Pending Completion'),
});

export const INVESTMENT_AGREEMENT_STATUS = {
  SIGNED: 'signed',
  NOT_SENT: 'not sent',
  PENDING_SIGNATURE: 'pending signature',
  UPLOAD_SUBSCRIPTION: 'upload subscription',
  CANCELED: 'canceled',
};

export const INVESTMENT_AGREEMENT_SIGN_METHOD = {
  ELECTRONIC: 'electronic',
  MANUAL: 'manual',
};

export const INVESTMENT_REVERTED_STATUS = {
  IN_PROGRESS: 'In Progress',
  RESUBMIT: 'Resubmit',
  NEW: 'New',
};

export const EVENT_TYPES = {
  offering_materials_sent_to_signers_old: 'Investment Created',
  offering_materials_sent_to_signers: 'Offering Materials Sent to Signers',
  offering_materials_resent_to_signers: 'Offering Materials Resent to Signers',
  investment_recalled: 'Investment Recalled',
};

export const COUNTRIES_ZIP_NOT_REQUIRED = ['Hong Kong'];
