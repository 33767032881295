// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LayoutGlobal-module__LayoutGlobal___Z15aR{height:100%;display:flex;flex-direction:column}`, "",{"version":3,"sources":["webpack://./icn_react/src/components/shared/layout_global/LayoutGlobal.module.scss"],"names":[],"mappings":"AAAA,2CACE,WAAA,CACA,YAAA,CACA,qBAAA","sourcesContent":[".LayoutGlobal {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"LayoutGlobal": `LayoutGlobal-module__LayoutGlobal___Z15aR`
};
export default ___CSS_LOADER_EXPORT___;
