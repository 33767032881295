import {
  SET_ICN_BOOTSTRAP,
  ADD_REFERRAL_CODE,
  UPDATE_CURRENT_LANGUAGE,
  UPDATE_USER_ATTRIBUTE,
} from 'actions/actionsConstants';

import {
  SET_FUND_FOLLOW,
} from 'modules/investment_opportunity/actions/constants';

import update from 'immutability-helper/index';

const initialState = {
  icn_react_bootstrap: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ICN_BOOTSTRAP:
      return update(state, {
        $set: {
          icn_react_bootstrap: action.payload,
        },
      });
    case SET_FUND_FOLLOW:
      if (action.payload.fund_follow) {
        const { fund_id, id, firm_id } = action.payload.fund_follow;
        return update(state, {
          icn_react_bootstrap: {
            user: {
              fund_follows: {
                $push: [
                  { fund_id, firm_id, follow_id: id },
                ],
              },
            },
          },
        });
      }
      return update(state, {
        icn_react_bootstrap: {
          user: {
            fund_follows: {
              $set: state.icn_react_bootstrap.user.fund_follows.filter(
                (i) => action.payload.fund_id !== i.fund_id
              ),
            },
          },
        },
      });

    case ADD_REFERRAL_CODE:
      return update(state, {
        icn_react_bootstrap: {
          user: { referralCodes: { $push: [action.payload] } },
        },
      });
    case UPDATE_CURRENT_LANGUAGE:
      window.location.reload();
      return update(state, {
        icn_react_bootstrap: {
          user: { $merge: action.payload },
        },
      });
    case UPDATE_USER_ATTRIBUTE:
      return update(state, {
        icn_react_bootstrap: {
          user: { $merge: action.payload },
        },
      });
    default:
      return state;
  }
};
