import { isNil, omit } from 'lodash';

export function scopedPartnerUrl(params = undefined, target = '') {
  return isNil(params?.rtId) ? target : `${target}?reconciliation_type=${params.rtId}`;
}

export function dataReconFilesUrl(params) {
  return !isNil(params?.dataReconId) && `/${params.dataReconId}/data_recon_files`;
}

export function settingParams(params = {}) {
  return omit(params, ['id', 'dataReconId']);
}
