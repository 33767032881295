/* eslint-disable max-lines */
/* eslint-disable max-statements */
import { createAction } from 'redux-actions';
import { getRequest, postRequest } from 'actions/utils';
import groupBy from 'lodash/groupBy';
import {
  FUND_FINANCES_SYNTHESIS_SET_INDEX_MASTERS_LIST,
  FUND_FINANCES_SYNTHESIS_SET_INDEX_MASTERS,
  FUND_FINANCES_SYNTHESIS_SET_YEARS_PERIOD,
  FUND_FINANCES_SYNTHESIS_CHANGE_PERIOD,
  FUND_FINANCES_SYNTHESIS_SET_FUND_GROUPS_LIST,
  FUND_FINANCES_SYNTHESIS_SET_FUND_GROUPS,
  FUND_FINANCES_SYNTHESIS_SET_FUND_LIST,
  FUND_FINANCES_SYNTHESIS_SET_FUNDS,
  FUND_FINANCES_SYNTHESIS_SET_SHARE_CLASS_LIST,
  FUND_FINANCES_SYNTHESIS_SET_SHARE_CLASSES,
  FUND_FINANCES_SYNTHESIS_SET_RETURN_TYPES,
  FUND_FINANCES_SYNTHESIS_SET_RESULTS_TOTAL,
  FUND_FINANCES_SYNTHESIS_SET_GRID_STATE,
  FUND_FINANCES_SYNTHESIS_SET_LOADED_GRID_STATE,
  FUND_FINANCES_SYNTHESIS_SET_ALL_FILTERS,
} from 'actions/actionsConstants';
import {
  ALL_REPORTABLE_CLASSES_OPTION_ID,
  FUND_FINANCE_MODEL_INDEX,
  FUND_FINANCE_MODEL_PRO_FORMA,
  FUND_FINANCE_MODEL_SHARE_CLASS_PERFORMANCES,
  INDEX_MODEL_RETURNS,
  INDEX_PERFORMANCES_TOTAL_URL,
  INDEX_RETURNS_TOTAL_URL,
  PRO_FORMA_RETURNS_TOTAL_URL,
  SHARE_CLASS_PERFORMANCES_TOTAL_URL,
} from 'components/fund_finances/filters/constants';

const setIndexMasters = createAction(FUND_FINANCES_SYNTHESIS_SET_INDEX_MASTERS_LIST);
const changeIndexMaster = createAction(FUND_FINANCES_SYNTHESIS_SET_INDEX_MASTERS);
const setResultsTotal = createAction(FUND_FINANCES_SYNTHESIS_SET_RESULTS_TOTAL);
const setServerYearsToPeriod = createAction(FUND_FINANCES_SYNTHESIS_SET_YEARS_PERIOD);
const changePeriod = createAction(FUND_FINANCES_SYNTHESIS_CHANGE_PERIOD);
const setFundGroupsList = createAction(FUND_FINANCES_SYNTHESIS_SET_FUND_GROUPS_LIST);
const synthesisLoadFundsList = createAction(FUND_FINANCES_SYNTHESIS_SET_FUND_LIST);
const synthesisSetFundGroup = createAction(FUND_FINANCES_SYNTHESIS_SET_FUND_GROUPS);
const synthesisSetFund = createAction(FUND_FINANCES_SYNTHESIS_SET_FUNDS);
const synthesisSetShareClassList = createAction(FUND_FINANCES_SYNTHESIS_SET_SHARE_CLASS_LIST);
const synthesisSetShareClasses = createAction(FUND_FINANCES_SYNTHESIS_SET_SHARE_CLASSES);
const synthesisSetReturnTypes = createAction(FUND_FINANCES_SYNTHESIS_SET_RETURN_TYPES);
const synthesisSetGridState = createAction(FUND_FINANCES_SYNTHESIS_SET_GRID_STATE);
const synthesisSetLoadedGridState = createAction(FUND_FINANCES_SYNTHESIS_SET_LOADED_GRID_STATE);
const synthesisSetAllFilters = createAction(FUND_FINANCES_SYNTHESIS_SET_ALL_FILTERS);

const cancelFundsList = {
  instance: undefined,
};
const cancelShareClassList = {
  instance: undefined,
};
const cancelIndexMasterList = {
  instance: undefined,
};
const cancelResultTotals = {
  instance: undefined,
};

function getServerIndexMasters() {
  return getRequest({
    url: '/fund_finances/index_masters',
    showSpinner: false,
    cancelInstance: cancelIndexMasterList,
    onSuccess: setIndexMasters,
    errorMessage: {
      message: 'An error occurred while fetching the index masters list.',
    },
  });
}

function fetchIndexReturnsTotals() {
  return (dispatch, getState) => {
    const state = getState().fundFinancesSynthesis;

    return dispatch(
      getRequest({
        url: INDEX_RETURNS_TOTAL_URL,
        showSpinner: false,
        params: {
          index_master_id: state.indexMasters?.toString() || undefined,
          selected_period: state.period,
        },
        cancelInstance: cancelResultTotals,
        onSuccess: setResultsTotal,
        errorMessage: {
          title: 'Index Returns',
          message: 'An error occurred while fetching the index returns totals.',
        },
      })
    );
  };
}

function fetchIndexPerformanceTotals() {
  return (dispatch, getState) => {
    const state = getState().fundFinancesSynthesis;
    const shareClassesSelected = [...state.shareClasses];

    if (shareClassesSelected.includes(ALL_REPORTABLE_CLASSES_OPTION_ID)) {
      shareClassesSelected.shift();
    }

    return dispatch(
      getRequest({
        url: INDEX_PERFORMANCES_TOTAL_URL,
        showSpinner: false,
        params: {
          index_master_id: state.indexMasters?.toString() || undefined,
          selected_period: state.period,
          share_class_id: shareClassesSelected?.toString() || undefined,
          fund_group_id: state.fundGroups?.toString() || undefined,
          fund_id: state.funds?.toString() || undefined,
        },
        cancelInstance: cancelResultTotals,
        onSuccess: setResultsTotal,
        errorMessage: {
          title: 'Index Performances',
          message: 'An error occurred while fetching the index performance totals.',
        },
      })
    );
  };
}

function fetchSCPerformanceTotals() {
  return (dispatch, getState) => {
    const state = getState().fundFinancesSynthesis;
    const shareClassesSelected = [...state.shareClasses];

    if (shareClassesSelected.includes(ALL_REPORTABLE_CLASSES_OPTION_ID)) {
      shareClassesSelected.shift();
    }

    return dispatch(
      getRequest({
        url: SHARE_CLASS_PERFORMANCES_TOTAL_URL,
        showSpinner: false,
        params: {
          share_class_id: shareClassesSelected?.toString() || undefined,
          selected_period: state.period,
          fund_group_id: state.fundGroups?.toString() || undefined,
          fund_id: state.funds?.toString() || undefined,
          source: state.returnTypes?.toString() || undefined,
        },
        cancelInstance: cancelResultTotals,
        onSuccess: setResultsTotal,
        errorMessage: {
          title: 'ShareClass Performances',
          message: 'An error occurred while fetching the share class performances totals.',
        },
      })
    );
  };
}

function fetchProFormaReturnTotals() {
  return (dispatch, getState) => {
    const state = getState().fundFinancesSynthesis;
    const shareClassesSelected = [...state.shareClasses];

    if (shareClassesSelected.includes(ALL_REPORTABLE_CLASSES_OPTION_ID)) {
      shareClassesSelected.shift();
    }

    return dispatch(
      getRequest({
        url: PRO_FORMA_RETURNS_TOTAL_URL,
        showSpinner: false,
        params: {
          share_class_id: shareClassesSelected?.toString() || undefined,
          selected_period: state.period,
          fund_group_id: state.fundGroups?.toString() || undefined,
          fund_id: state.funds?.toString() || undefined,
          source: state.returnTypes?.toString() || undefined,
        },
        cancelInstance: cancelResultTotals,
        onSuccess: setResultsTotal,
        errorMessage: {
          title: 'Pro Forma Returns',
          message: 'An error occurred while fetching the pro forma returns totals.',
        },
      })
    );
  };
}

function getServerYearsToPeriod() {
  return (dispatch) => dispatch(
    getRequest({
      url: '/fund_finances/index_returns/years_to_period',
      showSpinner: false,
      onSuccess: setServerYearsToPeriod,
    })
  );
}

function synthesisLoadFundGroups() {
  return (dispatch) => dispatch(
    getRequest({
      url: '/fund_admin_groups/synthesis_groups',
      showSpinner: false,
      onSuccess: setFundGroupsList,
    })
  );
}

function synthesisLoadFunds() {
  return getRequest({
    url: 'private_access_funds/for_fund_finance',
    params: { fund_type: 'hedge' },
    showSpinner: false,
    cancelInstance: cancelFundsList,
    onSuccess: synthesisLoadFundsList,
    errorMessage: {
      message: 'An error occurred while fetching the funds list.',
    },
  });
}

function savedViewElement(
  elem,
  elemStateVal,
  ignore,
  elmContent = 'typed_filter_id'
) {
  if (ignore) {
    return elemStateVal;
  }

  if (!ignore && elem && elem[0].filters.length > 0) {
    return elem[0].filters[0][elmContent];
  }

  return undefined;
}

function synthesisSavedView(data, tabSlug) {
  return (dispatch, getState) => {
    const state = getState().fundFinancesSynthesis;
    const filters = data.filters.drop_down_filters;
    const groupedFilters = groupBy(filters, 'filter_type');
    const tab = tabSlug ? tabSlug.split('/') : [];
    const idxRetTab = tab[0] === FUND_FINANCE_MODEL_INDEX && tab[1] === INDEX_MODEL_RETURNS;
    const shTab = tab[0] === FUND_FINANCE_MODEL_SHARE_CLASS_PERFORMANCES;
    const pfrTab = tab[0] === FUND_FINANCE_MODEL_PRO_FORMA && tab[1] === INDEX_MODEL_RETURNS;

    if (!groupedFilters) {
      return Promise.resolve();
    }

    const newElems = {
      fundGroups: savedViewElement(
        groupedFilters.fundGroups,
        state.fundGroups,
        idxRetTab
      ) || [],
      funds: savedViewElement(groupedFilters.funds, state.funds, idxRetTab) || [],
      period: savedViewElement(groupedFilters.period, state.period),
      indexMasters: savedViewElement(
        groupedFilters.indexMasters,
        state.indexMasters,
        shTab
      ) || [],
      shareClasses: savedViewElement(
        groupedFilters.shareClasses,
        state.shareClasses,
        idxRetTab
      ) || [],
      returnTypes: savedViewElement(
        groupedFilters.returnTypes,
        state.returnTypes,
        !(pfrTab || shTab)
      ) || [],
      gridState: savedViewElement(
        groupedFilters.gridState,
        undefined,
        false,
        'content'
      ) || [],
    };

    dispatch(synthesisSetAllFilters(newElems));

    return Promise.resolve();
  };
}

function synthesisLoadDefaultView(tabSlug) {
  return (dispatch) => dispatch(
    getRequest({
      url: 'user_saved_views/default_view',
      showSpinner: false,
      params: { page_slug: `fund_finances/synthesis/${tabSlug}` },
      onSuccess: (data) => synthesisSavedView(data, tabSlug),
      onFailure: () => synthesisSetLoadedGridState(undefined),
    })
  ).catch((err) => {
    if (err.status !== 404) throw err;
  });
}

function synthesisLoadShareClassList(fundIds) {
  return getRequest({
    url: '/fund_finances/share_classes',
    showSpinner: false,
    cancelInstance: cancelShareClassList,
    onSuccess: synthesisSetShareClassList,
    params: { fund_ids: fundIds },
    errorMessage: {
      message: 'An error occurred while fetching the share classes list.',
    },
  });
}

function getSCPServerYearsToPeriod() {
  return (dispatch) => dispatch(
    getRequest({
      url: '/fund_finances/share_class_performances/years_to_period',
      showSpinner: false,
      onSuccess: setServerYearsToPeriod,
    })
  );
}

function uploadIndexes(data) {
  return (dispatch) => dispatch(
    postRequest({
      url: '/fund_finances/uploaders',
      data,
      showSpinner: false,
    })
  );
}

export default {
  getServerIndexMasters,
  changeIndexMaster,
  fetchIndexReturnsTotals,
  fetchIndexPerformanceTotals,
  getServerYearsToPeriod,
  changePeriod,
  synthesisLoadFundGroups,
  synthesisLoadFunds,
  synthesisSetFundGroup,
  synthesisSetFund,
  synthesisSavedView,
  synthesisLoadShareClassList,
  synthesisSetShareClasses,
  synthesisSetReturnTypes,
  fetchSCPerformanceTotals,
  getSCPServerYearsToPeriod,
  synthesisLoadDefaultView,
  synthesisSetGridState,
  fetchProFormaReturnTotals,
  uploadIndexes,
};
