import { stringComparator } from 'services/customComparators';

export function sortArrayAlphabetically(array, sortingField) {
  return array.sort((el1, el2) => stringComparator(el1[sortingField], el2[sortingField]));
}

export function normalizeCurrentPage(newValue, state) {
  const lastPage = Math.ceil(state.representations_count / state.results_per_page);
  return newValue > lastPage ? Math.max(lastPage, 1) : Math.max(newValue, 1);
}
