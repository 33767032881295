import { useMemo, useCallback } from 'react';
import useCurrentUser from './useCurrentUser';

/**
 * @returns {{
 *  hasPermission: (permission: string) => boolean,
 *  hasAnyPermission: (permissions: string[]) => boolean,
 *  hasAllPermissions: (permissions: string[]) => boolean
 * }}
 */
export default function useCurrentUserPermissions() {
  const currentUser = useCurrentUser();

  const hasPermission = useCallback((permission) => currentUser?.[permission] === true, [currentUser]);

  const hasAnyPermission = useCallback((permissions = []) => permissions.some(hasPermission), [hasPermission]);

  const hasAllPermissions = useCallback((permissions = []) => permissions.every(hasPermission), [hasPermission]);

  return useMemo(() => ({
    hasPermission,
    hasAnyPermission,
    hasAllPermissions,
  }), [
    hasPermission,
    hasAnyPermission,
    hasAllPermissions,
  ]);
}
