import {
  LP_SET_INVESTMENT_OPPORTUNITY_COLLECTIONS,
} from 'modules/landing_page/actions/constants';
import update from 'immutability-helper';
import initialState from '../initialState';

export default (state = initialState, action) => {
  switch (action.type) {
    case LP_SET_INVESTMENT_OPPORTUNITY_COLLECTIONS: {
      return update(state, {
        investmentOpportunityCollections: {
          collectionsList: { $set: action.payload.collections },
          collectionsAsTilesList: { $set: action.payload.collections_as_tiles },
        },
      });
    }
    default:
      return state;
  }
};
