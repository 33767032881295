import {
  RGA_LOAD_CONFIGS_SUCCESS,
  RGA_LOAD_CONFIGS_COUNT,
  RGA_REMOVE_CONFIG,
  RGA_UPDATE_REPORT_CONFIG,
} from 'actions/actionsConstants';

import update from 'immutability-helper';

import reducers from './entry';
import initialState from 'reducers/report_generator_reducers/initialState';

const loadingReducers = (state = initialState, action) => {
  switch (action.type) {
    case RGA_LOAD_CONFIGS_SUCCESS:
      return update(state, {
        configs: { $set: action.payload },
        reportExecutedQuery: { $set: '' },
      });
    case RGA_LOAD_CONFIGS_COUNT:
      return update(state, { configs_count: { $set: action.payload } });
    case RGA_REMOVE_CONFIG:
      if (
        state.configs.length !== 1 ||
        state.current_page <= initialState.current_page
      ) {
        return state;
      }
      return update(state, {
        current_page: { $set: state.current_page - 1 },
      });
    case RGA_UPDATE_REPORT_CONFIG:
      return update(state, { current_configs: { $merge: action.payload } });
    default:
      return state;
  }
};

export default {
  filterReducer: reducers.filterReducer,
  modalReducer: reducers.modalReducer,
  configFieldsReducer: reducers.configFieldsReducer,
  loadingReducers,
};
