import {
  SD_SELECT_FUNDS,
  SD_SET_FUNDS,
  SD_SET_SELECTED_VIEW_MODE,
  SD_SET_DRILLDOWN,
  SD_CLEAR_DRILLDOWN,
  SD_SET_SUMMARY,
} from 'actions/actionsConstants';

import update from 'immutability-helper';

const initialState = {
  funds: [],
  selected_funds: [],
  drilldown_filters: [],
  summary: {},
};

export { initialState };

export default (state = initialState, action) => {
  switch (action.type) {
    case SD_SELECT_FUNDS:
      return update(state, {
        selected_funds: { $set: action.payload },
      });
    case SD_SET_FUNDS: {
      const funds = action.payload.map((fund) => ({
        id: fund.private_access_fund.id,
        name: fund.private_access_fund.name,
        fund_id: fund.private_access_fund.fund_id,
      }));
      return update(state, {
        funds: { $set: funds },
      });
    }
    case SD_SET_SELECTED_VIEW_MODE:
      return update(state, {
        selected_view_mode: { $set: action.payload },
      });
    case SD_SET_DRILLDOWN: {
      return update(state, {
        drilldown_filters: { $set: action.payload },
      });
    }
    case SD_CLEAR_DRILLDOWN:
      return update(state, {
        drilldown_filters: { $set: [] },
      });
    case SD_SET_SUMMARY:
      return update(state, {
        summary: { $set: action.payload },
      });
    default:
      return state;
  }
};
