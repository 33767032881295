import { actionFactory } from 'services/actions';

import {
  RGA_SAVE_FILTER_TRANSFORM,
  RGA_CLOSE_FIELD_FILTER,
  RGA_SET_TRANSFORM_NULL_FILTER,
} from 'actions/actionsConstants';
import configFieldActions from '../configFieldActions';

const saveFilter = actionFactory(RGA_SAVE_FILTER_TRANSFORM);
const closeFilter = actionFactory(RGA_CLOSE_FIELD_FILTER);

export const reportGeneratorConfigsSaveFilterTransform =
  (filter, fieldId) => (dispatch) => {
    dispatch(saveFilter(filter));
    dispatch(configFieldActions.handleSaveFilter(filter.filter, fieldId));
    dispatch(closeFilter(filter));
  };

export const reportGeneratorConfigsSetTransformNullFilter = actionFactory(
  RGA_SET_TRANSFORM_NULL_FILTER,
);
