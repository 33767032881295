import {
  DC_REMOVE_FILTER,
  DC_REMOVE_ALL_FILTERS,
  DC_SET_FILTERS,
  DC_SET_ALL_FILTERS,
  CHANGE_DATE_PICKER_DATE_TYPE,
  DC_SET_SEQUENCE,
} from "../../actionsConstants"

import store from 'icnStore';
import dcDownloadActions from "../doc_center_download_actions/dcDownloadActions";
import dcSavedViewActions from "../doc_center_saved_views_actions/dcSavedViewsActions";

const {
  clearSelectedDocuments,
  clearUnselectedDocuments,
  toggleSelectAll,
} = dcDownloadActions;

const { dcSetActiveSavedView } = dcSavedViewActions;

// filter change means total reset in state
const dcDownloadsPropsToChange = {
  allSelected: false,
  didSelectAll: false,
  showBanner: false,
};

function removeDcFilter(targetFilter) {
  store.dispatch(clearSelectedDocuments());
  store.dispatch(clearUnselectedDocuments());
  store.dispatch(toggleSelectAll(dcDownloadsPropsToChange));
  store.dispatch(dcSetActiveSavedView());

  return {
    type: DC_REMOVE_FILTER,
    payload: targetFilter,
  };
}

function removeAllDcFilter() {
  store.dispatch(clearSelectedDocuments());
  store.dispatch(clearUnselectedDocuments());
  store.dispatch(toggleSelectAll(dcDownloadsPropsToChange));
  store.dispatch(dcSetActiveSavedView());

  return {
    type: DC_REMOVE_ALL_FILTERS,
    payload: '',
  };
}

function setDcFilters(filterType, filterList) {
  store.dispatch(clearSelectedDocuments());
  store.dispatch(clearUnselectedDocuments());
  store.dispatch(toggleSelectAll(dcDownloadsPropsToChange));
  store.dispatch(dcSetActiveSavedView());

  return {
    type: DC_SET_FILTERS,
    key: filterType,
    payload: filterList,
  };
}

function changeDcDatePickerDateType(dateType) {
  return {
    type: CHANGE_DATE_PICKER_DATE_TYPE,
    payload: dateType
  }
}

function setDcSequence(sequence) {
  return {
    type: DC_SET_SEQUENCE,
    payload: sequence
  };
}

function setAllSelectedFilters(payload) {
  return (dispatch, getState) => {
    dispatch({ type: DC_SET_ALL_FILTERS, payload: payload })
  }
}

export default {
  removeDcFilter,
  removeAllDcFilter,
  setDcFilters,
  setAllSelectedFilters,
  changeDcDatePickerDateType,
  setDcSequence,
}
