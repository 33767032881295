import update from 'immutability-helper';

import {
  CONSENT_FORM_SET_AUTHENTICATION_CODE,
  CONSENT_FORM_SET_VERIFY_CODE,
  CONSENT_FORM_SET_PAGE_STATE,
  CONSENT_FORM_SET_CONTACT_CARD_REQUEST,
  CONSENT_FORM_SET_FORM_SUBMITTED,
} from 'actions/actionsConstants';
import {
  PAGE_LOADING,
} from 'containers/consent_form/entry/pageStates';

export const initialState = {
  consent: {},
  contact_card: {},
  email: '',
  verify_code: '',
  authentication_token: '',
  page_state: PAGE_LOADING,
};

export default function consentFormReducer(state = initialState, action) {
  switch (action.type) {
    case CONSENT_FORM_SET_CONTACT_CARD_REQUEST:
      return update(state, {
        consent: { $set: action.payload.consent },
        contact_card: { $set: action.payload.contact_card },
        email: { $set: action.payload.email },
      });
    case CONSENT_FORM_SET_AUTHENTICATION_CODE:
      return update(state, {
        authentication_token: { $set: action.payload },
      });
    case CONSENT_FORM_SET_VERIFY_CODE:
      return update(state, {
        verify_code: { $set: action.payload },
      });
    case CONSENT_FORM_SET_PAGE_STATE:
      return update(state, {
        page_state: { $set: action.payload },
      });
    case CONSENT_FORM_SET_FORM_SUBMITTED:
      return update(state, {
        authentication_token: { $set: '' },
        verify_code: { $set: '' },
      });
    default:
      return state;
  }
}
