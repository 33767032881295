export default {
  pre_approval: 'Pre-Approval',
  pre_approval_secondary: 'Pre-Approval 2',
  pending_review: 'In-Process',
  external_review: 'External Review',
  external_secondary_review: 'External Review 2',
  ir_review: 'Document Review',
  compliance_review: 'Legal Review',
  approved: 'Approved',
  accepted: 'Accepted',
  canceled: 'Canceled',
  reverted: 'Reverted',
  investment_auto_reverted: 'Auto-Reverted Investment',
};
