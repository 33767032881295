import {
  allBeneficialOwnerFields,
  publiclyTradedFields,
  regulatorFields,
  beneficialOwnerRadioFields,
} from 'services/investor_profile/beneficialOwner';

function checkBeneficialOwnerControlFieldChange(updateObj) {
  // checks if any radio fields that affect display have changed
  return beneficialOwnerRadioFields.some(f => Object.keys(updateObj).includes(f));
}

function clearFields(fieldsCallback) {
  let clearedFields = {};
  [1, 2, 3, 4, 5].forEach(n => clearedFields = { ...clearedFields, ...fieldsCallback(n).reduce((obj, key) => ({ ...obj, [key]: '' }), {}) });

  return clearedFields;
}

const sideEffects = (updateObj, profile) => {
  let modifiedObj = {};

  if (updateObj.beneficial_owners_1_exchange_name && updateObj.beneficial_owners_1_exchange_name !== 'Other (please specify below)') {
    modifiedObj.beneficial_owners_1_other_publicly_traded_exchange_name = '';
  }

  if (checkBeneficialOwnerControlFieldChange(updateObj)) {
    const { investor_has_beneficiaries, investor_beneficiaries_publicly_traded, investor_beneficiaries_regulated_entity } = profile;


    const controlFields = { investor_has_beneficiaries, investor_beneficiaries_publicly_traded, investor_beneficiaries_regulated_entity };

    Object.keys(controlFields).forEach((field) => {
      if (updateObj[field]) {
        controlFields[field] = updateObj[field];
      }
    });

    if (Object.values(controlFields).every(val => val === '0')) {
      modifiedObj = { ...clearFields(allBeneficialOwnerFields), ...modifiedObj };
    } else if (updateObj.investor_beneficiaries_publicly_traded === '0') {
      modifiedObj = { ...clearFields(publiclyTradedFields), ...modifiedObj };
    } else if (updateObj.investor_beneficiaries_regulated_entity === '0') {
      modifiedObj = { ...clearFields(regulatorFields), ...modifiedObj };
    }
  }

  return { ...updateObj, ...modifiedObj };
};

export default sideEffects;
