export const capableEvaluatingInvestments = {
  questionKey: 'capable_evaluating_investments',
  text: 'I am capable of evaluating investment risks independently, both in general and with regard to all transactions and investment strategies involving a security or securities',
};

export const exerciseIndependentJudgment = {
  questionKey: 'exercise_independent_judgement',
  text: 'I do exercise independent judgment in evaluating the recommendations of any broker-dealer or its associated persons',
};

export const responsibleVerificationClientEligibility = {
  questionKey: 'responsible_verification_client_eligibility',
  text: 'I agree that I am solely responsible for verifying that any client meets any applicable eligibility requirements prior to entering into any transaction and/or investment strategies involving a security or securities',
};
