// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("text-security-circle.eot", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("text-security-circle.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("text-security-circle.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("text-security-circle.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("text-security-circle.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("text-security-square.eot", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("text-security-square.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_7___ = new URL("text-security-square.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_8___ = new URL("text-security-square.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_9___ = new URL("text-security-square.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_10___ = new URL("text-security-disc.eot", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_11___ = new URL("text-security-disc.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_12___ = new URL("text-security-disc.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_13___ = new URL("text-security-disc.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_14___ = new URL("text-security-disc.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___, { hash: "#text-security" });
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_9___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_10___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
var ___CSS_LOADER_URL_REPLACEMENT_11___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_9___, { hash: "#text-security" });
var ___CSS_LOADER_URL_REPLACEMENT_12___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_10___);
var ___CSS_LOADER_URL_REPLACEMENT_13___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_10___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_14___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_11___);
var ___CSS_LOADER_URL_REPLACEMENT_15___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_12___);
var ___CSS_LOADER_URL_REPLACEMENT_16___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_13___);
var ___CSS_LOADER_URL_REPLACEMENT_17___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_14___, { hash: "#text-security" });
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {font-family: 'text-security-circle';src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('embedded-opentype'),url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('woff2'),url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('woff'),url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format('truetype'),url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format('svg');}
@font-face {font-family: 'text-security-square';src: url(${___CSS_LOADER_URL_REPLACEMENT_6___});src: url(${___CSS_LOADER_URL_REPLACEMENT_7___}) format('embedded-opentype'),url(${___CSS_LOADER_URL_REPLACEMENT_8___}) format('woff2'),url(${___CSS_LOADER_URL_REPLACEMENT_9___}) format('woff'),url(${___CSS_LOADER_URL_REPLACEMENT_10___}) format('truetype'),url(${___CSS_LOADER_URL_REPLACEMENT_11___}) format('svg');}
@font-face {font-family: 'text-security-disc';src: url(${___CSS_LOADER_URL_REPLACEMENT_12___});src: url(${___CSS_LOADER_URL_REPLACEMENT_13___}) format('embedded-opentype'),url(${___CSS_LOADER_URL_REPLACEMENT_14___}) format('woff2'),url(${___CSS_LOADER_URL_REPLACEMENT_15___}) format('woff'),url(${___CSS_LOADER_URL_REPLACEMENT_16___}) format('truetype'),url(${___CSS_LOADER_URL_REPLACEMENT_17___}) format('svg');}
`, "",{"version":3,"sources":["webpack://./icn_react/public/webfonts/webfonts.css"],"names":[],"mappings":"AAAA,YAAY,mCAAmC,CAAC,4CAAoC,CAAC,wSAAwR,CAAC;AAC9W,YAAY,mCAAmC,CAAC,4CAAoC,CAAC,0SAAwR,CAAC;AAC9W,YAAY,iCAAiC,CAAC,6CAAkC,CAAC,6SAA8Q,CAAC","sourcesContent":["@font-face {font-family: 'text-security-circle';src: url('text-security-circle.eot');src: url('text-security-circle.eot?#iefix') format('embedded-opentype'),url('text-security-circle.woff2') format('woff2'),url('text-security-circle.woff') format('woff'),url('text-security-circle.ttf') format('truetype'),url('text-security-circle.svg#text-security') format('svg');}\n@font-face {font-family: 'text-security-square';src: url('text-security-square.eot');src: url('text-security-square.eot?#iefix') format('embedded-opentype'),url('text-security-square.woff2') format('woff2'),url('text-security-square.woff') format('woff'),url('text-security-square.ttf') format('truetype'),url('text-security-square.svg#text-security') format('svg');}\n@font-face {font-family: 'text-security-disc';src: url('text-security-disc.eot');src: url('text-security-disc.eot?#iefix') format('embedded-opentype'),url('text-security-disc.woff2') format('woff2'),url('text-security-disc.woff') format('woff'),url('text-security-disc.ttf') format('truetype'),url('text-security-disc.svg#text-security') format('svg');}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
