/* eslint-disable max-statements */
import { isBoolean } from 'lodash';
import {
  iaWithSeries65,
  registeredRepresentativeSeries,
  insuranceAgent,
  trustOrFiduciary,
  advisorCrdNumber,
  isRegisteredRepresentative,
  isOtherTypeInstitution,
  otherTypeInstitutionalInvestor,
  totalAssets,
  firmRoleIfOther,
  registeredAsBDOrIA,
} from './profileData';
import {
  isTextFilled,
  isLocationFilled,
  isFirmAssetsFilled,
  isCrdNumberFilled,
} from './CompleteProfileValidations';
import { showFirmType } from '../RegistrationUtils';

function getDoesFirmTypeRequireCrdNumber(profile) {
  return profile[isRegisteredRepresentative.question_key] || profile[iaWithSeries65.question_key];
}

function getIsRoleFilled(profile) {
  if (profile.role === 'Other') {
    return isTextFilled(profile[firmRoleIfOther.question_key]);
  }
  return isTextFilled(profile.role);
}

function getIsOtherTypeInstInvNeeded(profile) {
  return profile[isRegisteredRepresentative.question_key] === false &&
    profile[iaWithSeries65.question_key] === false &&
    profile[insuranceAgent.question_key] === false &&
    profile[trustOrFiduciary.question_key] === false;
}

function getIsRegistrationSeriesFilled(profile) {
  const radioIsFilled = isBoolean(profile[isRegisteredRepresentative.question_key]);
  if (radioIsFilled) {
    return profile[isRegisteredRepresentative.question_key]
      ? profile[registeredRepresentativeSeries.question_key]?.length > 0
      : true;
  }
  return false;
}

function getIsAdvisorCrdFilled(profile) {
  const doesNotNeedCrd = profile[isRegisteredRepresentative.question_key] === false &&
    profile[iaWithSeries65.question_key] === false;
  const needsAndHasCrd = (profile[isRegisteredRepresentative.question_key] ||
    profile[iaWithSeries65.question_key]) && profile[advisorCrdNumber.question_key];
  return doesNotNeedCrd || needsAndHasCrd;
}

function getIsOtherTypeInstInvestorFilled(profile) {
  const radiosAreAllFalse = getIsOtherTypeInstInvNeeded(profile);
  if (!radiosAreAllFalse) return true;

  const radioIsFilled = isBoolean(profile[isOtherTypeInstitution.question_key]);
  if (radioIsFilled) {
    if (profile[isOtherTypeInstitution.question_key] && profile[otherTypeInstitutionalInvestor.question_key]) {
      return profile[otherTypeInstitutionalInvestor.question_key] === 'any_other_entity'
        ? isTextFilled(profile[totalAssets.question_key])
        : isTextFilled(profile[otherTypeInstitutionalInvestor.question_key]);
    }
    if (!profile[isOtherTypeInstitution.question_key]) {
      return true;
    }
  }
  return false;
}

function useCompleteTwoPageProfile(profile) {
  const calculateFirstPageComplete = () => {
    const isNameFilled = isTextFilled(profile.first_name) && isTextFilled(profile.last_name);
    const isLocFilled = isLocationFilled(profile);
    let isFirmFilled = false;
    if (showFirmType(profile)) {
      isFirmFilled = isTextFilled(profile.firm_name) && isTextFilled(profile.firm_type);
    } else if (!showFirmType(profile)) {
      isFirmFilled = isTextFilled(profile.firm_name);
    } else {
      isFirmFilled = true;
    }

    const isRoleFilled = getIsRoleFilled(profile);
    const isSecFilled = profile.firm_confirm_us_location
      ? isTextFilled(profile[registeredAsBDOrIA.question_key])
      : true;
    const isAssetsFilled = isFirmAssetsFilled(profile);
    return isNameFilled && isLocFilled && isFirmFilled && isCrdNumberFilled(profile) &&
          isRoleFilled && isSecFilled && isAssetsFilled;
  };

  const calculateSecondPageComplete = () => {
    const isRegistrationSeriesFilled = getIsRegistrationSeriesFilled(profile);
    const isSeries65Filled = isBoolean(profile[iaWithSeries65.question_key]);
    const isAdvisorCrdFilled = getIsAdvisorCrdFilled(profile);
    const isInsuranceFilled = isBoolean(profile[insuranceAgent.question_key]);
    const isTrustOrFiduciaryFilled = isBoolean(profile[trustOrFiduciary.question_key]);
    const isOtherTypeInstitutionalInvestorFilled = getIsOtherTypeInstInvestorFilled(profile);
    return isRegistrationSeriesFilled &&
      isSeries65Filled &&
      isAdvisorCrdFilled &&
      isInsuranceFilled &&
      isTrustOrFiduciaryFilled &&
      isOtherTypeInstitutionalInvestorFilled;
  };

  return {
    calculateFirstPageComplete,
    calculateSecondPageComplete,
  };
}

export {
  useCompleteTwoPageProfile,
  getIsRegistrationSeriesFilled,
  getIsOtherTypeInstInvestorFilled,
  getIsOtherTypeInstInvNeeded,
  getDoesFirmTypeRequireCrdNumber,
};
