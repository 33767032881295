import axios from 'axios';
import spinnerActions from 'actions/spinner_actions/spinnerActions';

import {
  DM_SET_FUND_DOCUMENT_EMAILS,
  DM_SET_FUND_DOCUMENT_EMAIL_PARTNERS,
} from 'actions/actionsConstants';

function dmSetFundDocumentEmails(payload) {
  return {
    type: DM_SET_FUND_DOCUMENT_EMAILS,
    payload,
  };
}
function dmSetPartners(payload) {
  return {
    type: DM_SET_FUND_DOCUMENT_EMAIL_PARTNERS,
    payload,
  };
}

function dmFetchFundDocumentEmails(fundDocumentId, query) {
  const url = `document_manager/fund_documents/${fundDocumentId}/fund_document_emails`;

  return (dispatch) => {
    dispatch(spinnerActions.beginApiRequest());
    return axios.get(url, {
      withCredentials: true,
      params: query,
    }).then((response) => {
      dispatch(spinnerActions.endApiRequest());
      dispatch(dmSetFundDocumentEmails(response.data));
    }).catch(() => {
      dispatch(spinnerActions.endApiRequest());
    });
  };
}

function dmFetchPartners(fundDocumentId) {
  const url = `document_manager/fund_documents/${fundDocumentId}/fund_document_emails/partners`;

  return (dispatch) => {
    dispatch(spinnerActions.beginApiRequest());
    return axios.get(url, {
      withCredentials: true,
    }).then((response) => {
      dispatch(spinnerActions.endApiRequest());
      dispatch(dmSetPartners(response.data));
    }).catch(() => {
      dispatch(spinnerActions.endApiRequest());
    });
  };
}

function exportFundDocumentEmails(fundDocumentId) {
  const url = `document_manager/fund_documents/${fundDocumentId}/fund_document_emails/export`;

  return (dispatch) => {
    dispatch(spinnerActions.beginApiRequest());
    return axios.get(url, {
      withCredentials: true,
    }).then((response) => {
      dispatch(spinnerActions.endApiRequest());
    }).catch(() => {
      dispatch(spinnerActions.endApiRequest());
    });
  };
}

export default {
  dmFetchFundDocumentEmails,
  dmFetchPartners,
  exportFundDocumentEmails,
};
