import { getRequest, postRequest } from 'actions/utils';
import axios from 'axios';

const setUserImpersonate = (payload, onSuccess) => postRequest({
  url: `users/${payload}/impersonate`,
  cancelInstance: { instance: undefined },
  showSpinner: false,
  onSuccess,
});

const getDelegateUsers = (onSuccess) => getRequest({
  url: 'delegation?delegators=true',
  cancelInstance: { instance: undefined },
  params: {},
  showSpinner: false,
  onSuccess,
});

const getImpersonationUsers = ({ userId, filter, onSuccess }) => getRequest({
  url: `users/${userId}/search_for_impersonate`,
  cancelInstance: { instance: undefined },
  params: {
    term: filter || undefined,
  },
  showSpinner: false,
  onSuccess,
});

const stopImpersonation = ({
  url, userId, onSuccess, onFail,
}) => axios({
  url,
  method: 'post',
  withCredentials: true,
  data: { user: { id: userId } },
})
  .then(onSuccess)
  .catch(onFail);

export {
  setUserImpersonate,
  getDelegateUsers,
  getImpersonationUsers,
  stopImpersonation,
};
