import {
  RAT_LOAD_ACCOUNT_TYPES,
  RAT_SET_ACCOUNT_TYPE,
} from 'actions/actionsConstants';
import update from 'immutability-helper';

export const initialState = {
  registrationAccountTypes: [],
  selectedAccountType: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case RAT_LOAD_ACCOUNT_TYPES: {
      return update(state, {
        registrationAccountTypes: {
          $set: action.payload,
        },
      });
    }
    case RAT_SET_ACCOUNT_TYPE: {
      return update(state, {
        selectedAccountType: {
          $set: action.payload,
        },
      });
    }
    default: {
      return state;
    }
  }
};
