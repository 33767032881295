import axios from 'axios';
import { sortKeepAllObjBeginning, removeDuplicates } from 'services/document_manager';

export const fetchFirmsAutocomplete = (query) => {
  const url = 'firms/autocomplete';
  return axios.get(url, {
    params: {
      query,
    },
    withCredentials: true,
  }).then(
    (response) => Promise.resolve(response.data)
  ).catch(
    (error) => Promise.reject(error)
  );
};

export const sortFirms = (data = []) => {
  let firms = [...data];
  firms = removeDuplicates(firms);
  return firms.sort(sortKeepAllObjBeginning);
};

export const fetchFaFeature = (firmId, params = {}) => {
  const url = `firms/${firmId}/fa_feature`;
  return axios.get(url, {
    params,
    withCredentials: true,
  }).then(
    (response) => Promise.resolve(response.data)
  ).catch(
    (error) => Promise.reject(error)
  );
};

export const fetchFrimFinancialAdvisorSettings = async (firmId) => {
  const url = `firms/${firmId}/financial_advisor_settings`;
  const response = await axios.get(url, { withCredentials: true });
  return response.data.firm;
};

export const fetchIntegrationPartnerDashboardSearch = async (firmIds) => {
  const url = 'firms/integration_partner_dashboard_search';
  const response = await axios.get(url, { params: { firm_ids: firmIds }, withCredentials: true });
  return response.data;
};
