import axios from 'axios';

const url = 'integration_partners/rules';
const config = { withCredentials: true };
export const getIntegrationPartnersRules = (meta) => axios.post(`${url}/query`, { meta }, config)
  .then((response) => Promise.resolve(response.data))
  .catch((error) => Promise.reject(error));

export const createIntegrationPartnerRule = (params) => axios.post(url, params, config)
  .then((response) => Promise.resolve(response.data))
  .catch((error) => Promise.reject(error));

export const updateIntegrationPartnerRule = (params) => axios.patch(`${url}/${params.id}`, params, config)
  .then((response) => Promise.resolve(response.data))
  .catch((error) => Promise.reject(error));

export const deleteIntegrationPartnerRule = (id) => axios.delete(`${url}/${id}`, config)
  .then((response) => Promise.resolve(response.data))
  .catch((error) => Promise.reject(error));
