import {
  DD_EXECUTE_TRANSFORMATION,
} from 'actions/actionsConstants';

import initialState from '../initialState';
import { updateState } from '../helpers';

const REQUEST_OBJECT = 'transformation';

export default (state = initialState, action) => {
  switch (action.type) {
    case DD_EXECUTE_TRANSFORMATION:
      return updateState(state, action.payload, (data) => ({
        error: data.error,
        request_object: REQUEST_OBJECT,
        request_type: 'execute_transformation',
        message: `${data.error ? 'Failed' : 'Succeeded'} on executing Transformation process`,
      }));
    default:
      return state;
  }
};
