/* eslint-disable max-lines-per-function */
import update from 'immutability-helper';
import produce from 'immer';
import { sortBy, some } from 'lodash';
import { INVESTMENT_APPROVAL_STATUS_OVERRIDES } from 'constants/index';
import { CLIENT_MANAGEMENT } from './actions';

const addElementInArray = (array, element) => produce(array, (draft) => {
  const index = draft.findIndex((current) => current === element);
  if (index < 0) draft.push(element);
});

const removeElementInArray = (array, element) => produce(array, (draft) => {
  const index = draft.findIndex((current) => current === element);
  if (index >= 0) draft.splice(index, 1);
});

export const initialState = {
  pafList: null,
  currentPaf: null,
  expandedGrids: [],
  redemptionsOpenGroups: [],
  loadingGrids: [],
  investmentsByState: {},
  createInvestmentFlow: 'none',
  selectedAdvisorId: null,
  showCanceledInvestments: false,
  canCreateProspectInvestments: false,
  hasCanceledInvestments: false,
  whiteLabelPartner: {},
  searchTerm: '',
  searchType: 'account_name',
  redemptionsTotals: { completed: 0, holdback: 0, active: 0 },
};

const clientManagementReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case CLIENT_MANAGEMENT.PAF_LIST.SUCCESS:
      return update(state, {
        pafList: {
          $set:
            sortBy(
              payload.response,
              [({ name }) => name.trim().toLowerCase()],
              ['desc']
            ),
        },
      });
    case CLIENT_MANAGEMENT.SET_EXPAND_INVESTMENT_STATE: {
      return produce(state, (draft) => {
        const method = payload.value ? addElementInArray : removeElementInArray;
        draft.expandedGrids = method(state.expandedGrids, payload.state);
      });
    }
    case CLIENT_MANAGEMENT.SET_REDEMPTIONS_OPEN_GROUPS: {
      const { value, group } = payload;
      return produce(state, (draft) => {
        const method = value ? addElementInArray : removeElementInArray;
        draft.redemptionsOpenGroups = method(state.redemptionsOpenGroups, group);
      });
    }
    case CLIENT_MANAGEMENT.EXPORT_FINISHED:
      return produce(state, (draft) => {
        draft.currentPaf.has_exports = true;
      });
    case CLIENT_MANAGEMENT.SET_SELECTED_ADVISOR:
      return update(state, { selectedAdvisorId: { $set: payload } });
    case CLIENT_MANAGEMENT.SET_SHOW_CANCELED_INVESTMENTS:
      return update(state, {
        showCanceledInvestments: { $set: payload },
      });
    case CLIENT_MANAGEMENT.CANCEL_INVESTMENT:
      return {
        ...state,
        hasCanceledInvestments: true,
      };
    case CLIENT_MANAGEMENT.WHITE_LABEL_PARTNER.SUCCESS:
      return update(state, { whiteLabelPartner: { $set: payload.response.white_label_partner } });
    case CLIENT_MANAGEMENT.SET_SEARCH_TERM:
      return produce(state, (draft) => {
        draft.searchTerm = payload;
        Object.keys(draft.investmentsByState).forEach((stateKey) => {
          draft.investmentsByState[stateKey].expired = true;
        });
      });
    case CLIENT_MANAGEMENT.SET_SEARCH_TYPE:
      return produce(state, (draft) => {
        draft.searchType = payload;
        Object.keys(draft.investmentsByState).forEach((stateKey) => {
          draft.investmentsByState[stateKey].expired = true;
        });
      });
    case CLIENT_MANAGEMENT.SET_INVESTMENT_STATS:
      return produce(state, (draft) => {
        draft.investmentsByState = Object.keys(payload.response).reduce((result, stateKey) => {
          result[stateKey] = {
            list: [],
            expired: true,
            count: payload.response[stateKey].count,
            commitment: payload.response[stateKey].commitment,
          };

          return result;
        }, {});

        const autoRevertedState = draft
          .investmentsByState[INVESTMENT_APPROVAL_STATUS_OVERRIDES.INVESTMENT_AUTO_REVERTED];
        if (autoRevertedState) {
          const revertedState = draft.investmentsByState[INVESTMENT_APPROVAL_STATUS_OVERRIDES.REVERTED];
          draft.investmentsByState[INVESTMENT_APPROVAL_STATUS_OVERRIDES.REVERTED] = {
            list: [],
            expired: true,
            count: (revertedState?.count || 0) + autoRevertedState.count,
            commitment: (revertedState?.commitment || 0) + autoRevertedState.commitment,
          };

          delete draft.investmentsByState[INVESTMENT_APPROVAL_STATUS_OVERRIDES.INVESTMENT_AUTO_REVERTED];
        }
      });
    case CLIENT_MANAGEMENT.INVESTMENT_LIST.SUCCESS:
      return produce(state, (draft) => {
        draft.loadingGrids = removeElementInArray(draft.loadingGrids, payload.request.state);
        draft.investmentsByState[payload.request.state].list = payload.response;
      });
    case CLIENT_MANAGEMENT.INVESTMENT_LIST.REQUEST:
      return produce(state, (draft) => {
        draft.investmentsByState[payload.request.state].expired = false;
        draft.investmentsByState[payload.request.state].list = [];
        draft.loadingGrids = addElementInArray(draft.loadingGrids, payload.request.state);
      });
    case CLIENT_MANAGEMENT.INVESTMENT_LIST.FAILURE:
      return produce(state, (draft) => {
        draft.investmentsByState[payload.request.state].expired = true;
        draft.loadingGrids = removeElementInArray(draft.loadingGrids, payload.request.state);
      });
    case CLIENT_MANAGEMENT.TRANSFERS.REQUEST:
      return { ...state, loadingGrids: addElementInArray(state.loadingGrids, 'transfers') };
    case CLIENT_MANAGEMENT.TRANSFERS.FAILURE:
      return { ...state, loadingGrids: removeElementInArray(state.loadingGrids, 'transfers') };
    case CLIENT_MANAGEMENT.TRANSFERS.SUCCESS:
      return {
        ...state,
        transferInvestmentsList: payload.response,
        loadingGrids: removeElementInArray(state.loadingGrids, 'transfers'),
      };
    case CLIENT_MANAGEMENT.GET_PAF.REQUEST:
      return produce(state, (draft) => {
        draft.expandedGrids = [];
      });
    case CLIENT_MANAGEMENT.GET_PAF.SUCCESS:
      return produce(state, (draft) => {
        draft.currentPaf = payload.response;
        draft.canCreateProspectInvestments = draft.currentPaf.can_create_prospect_investments;
        draft.hasCanceledInvestments = draft.currentPaf.has_canceled_investments;
        draft.createInvestmentFlow = draft.currentPaf.investment_creation_flow;

        if (!some(draft.pafList, ({ id }) => id === draft.currentPaf.id)) {
          draft.pafList = sortBy(
            (draft.pafList || []).concat(
              [{ name: draft.currentPaf.name, id: draft.currentPaf.id, status: draft.currentPaf.status }]
            ),
            [({ name }) => name.trim().toLowerCase()],
            ['desc']
          );
        }
      });
    case CLIENT_MANAGEMENT.GET_PAF.FAILURE:
      return produce(state, (draft) => {
        draft.currentPaf = null;
        draft.canCreateProspectInvestments = false;
        draft.hasCanceledInvestments = false;
        draft.createInvestmentFlow = 'none';
      });
    case CLIENT_MANAGEMENT.CLEAR_REDEMPTIONS_TOTALS:
      return update(state, {
        redemptionsTotals: { $set: [] },
        loadingGrids: { $set: removeElementInArray(state.loadingGrids, 'redemptions') },
      });
    case CLIENT_MANAGEMENT.SET_EXPIRED_INVESTMENT_STATE:
      return produce(state, (draft) => {
        draft.acceptedInvestmentListExpired = true;
        draft.acceptedInvestmentListAllLoaded = false;
      });
    default:
      return state;
  }
};

export default clientManagementReducer;
