import {
  ID_SET_ADVISORS,
  ID_SELECT_ADVISOR,
  ID_SET_INVESTORS,
  ID_SELECT_INVESTOR,
  ID_SET_ACCOUNTS,
  ID_SELECT_ACCOUNTS,
  ID_RESET_ADVISOR,
  ID_RESET_INVESTOR,
  ID_RESET_ACCOUNT,
  ID_SET_FUNDS,
  ID_SELECT_FUND,
  ID_SET_FUND_DATA,
  ID_RESET_FUND_DATA,
  ID_SET_INVESTMENT_DATA,
  ID_RESET_INVESTMENT_DATA,
  ID_SET_GENERAL_LEDGER_TRANSACTIONS_DATA,
  ID_SET_ALL_GENERAL_LEDGER_TRANSACTIONS_DATA,
  ID_SET_ALL_GENERAL_LEDGER_EXTERNAL_COMMITMENT_TRANSACTIONS_DATA,
  ID_SET_INVESTOR_PROFILE_QUERY_STRING,
  ID_SELECT_ACTIVE_TAB,
  ID_UPDATE_DATA_SETS_LOADED,
  ID_SET_FUND_DATA_METRICS,
  ID_FETCHING_ADVISORS_ON_GOING,
  ID_FETCHING_INVESTORS_ON_GOING,
  ID_FETCHING_INVESTOR_PROFILE_QUERY_STRING_ON_GOING,
  ID_FETCHING_ACCOUNTS_ON_GOING,
  ID_FETCHING_FUNDS_ON_GOING,
  ID_SET_DOC_CENTER_WIDGET_CACHE,
  ID_SET_EXPORTS,
  ID_SET_EXPORT_FLYOVER_STATUS,
  ID_SET_EXPORT_CSV_DATA,
  ID_SET_HIDE_ADVISOR_DROPDOWN,
  ID_SET_AGGREGATION_LEVEL,
  ID_SET_DISCLAIMERS,
  ID_SET_CONFIG,
  ID_SET_SELECTED_ASSET_CLASS,
  ID_SET_TABS,
  ID_SET_ASSET_CLASSES,
  ID_SET_FILTERED_ACCOUNTS,
  ID_RESET_FUND,
  ID_RESET_ASSET_CLASS,
  ID_SET_SELECTED_CURRENCY,
} from 'actions/actionsConstants';

import { createAction } from 'redux-actions';

export const idSetAdvisors = createAction(ID_SET_ADVISORS);
export const idSelectAdvisor = createAction(ID_SELECT_ADVISOR);
export const idSetInvestors = createAction(ID_SET_INVESTORS);
export const idSelectInvestor = createAction(ID_SELECT_INVESTOR);
export const idSetAccounts = createAction(ID_SET_ACCOUNTS);
export const idSelectAccounts = createAction(ID_SELECT_ACCOUNTS);
export const idResetAdvisor = createAction(ID_RESET_ADVISOR);
export const idResetInvestor = createAction(ID_RESET_INVESTOR);
export const idResetAccount = createAction(ID_RESET_ACCOUNT);
export const idSetFunds = createAction(ID_SET_FUNDS);
export const idSelectFund = createAction(ID_SELECT_FUND);
export const idSetFundData = createAction(ID_SET_FUND_DATA);
export const idResetFundData = createAction(ID_RESET_FUND_DATA);
export const idSetInvestmentData = createAction(ID_SET_INVESTMENT_DATA);
export const idResetInvestmentData = createAction(ID_RESET_INVESTMENT_DATA);
export const idSetGeneralLedgerTransactionsData = createAction(ID_SET_GENERAL_LEDGER_TRANSACTIONS_DATA);
export const idSelectActiveTab = createAction(ID_SELECT_ACTIVE_TAB);
export const idSetTabs = createAction(ID_SET_TABS);
export const idUpdateDataSetsLoaded = createAction(ID_UPDATE_DATA_SETS_LOADED);
export const idSetFundDataMetrics = createAction(ID_SET_FUND_DATA_METRICS);
export const idFetchingAdvisorsOnGoing = createAction(ID_FETCHING_ADVISORS_ON_GOING);
export const idFetchingInvestorsOnGoing = createAction(ID_FETCHING_INVESTORS_ON_GOING);
export const idFetchingInvestorProfileQueryStringOnGoing =
  createAction(ID_FETCHING_INVESTOR_PROFILE_QUERY_STRING_ON_GOING);
export const idFetchingAccountsOnGoing = createAction(ID_FETCHING_ACCOUNTS_ON_GOING);
export const idFetchingFundsOnGoing = createAction(ID_FETCHING_FUNDS_ON_GOING);
export const idSetAllTransactions = createAction(ID_SET_ALL_GENERAL_LEDGER_TRANSACTIONS_DATA);
export const idSetAllExternalCommitmentTransactions =
  createAction(ID_SET_ALL_GENERAL_LEDGER_EXTERNAL_COMMITMENT_TRANSACTIONS_DATA);
export const idSetInvestorProfileQueryString = createAction(ID_SET_INVESTOR_PROFILE_QUERY_STRING);
export const idSetExports = createAction(ID_SET_EXPORTS);
export const idSetExportCsvData = createAction(ID_SET_EXPORT_CSV_DATA);
export const idSetExportFlyoverStatus = createAction(ID_SET_EXPORT_FLYOVER_STATUS);
export const idSetDocCenterWidgetCache = createAction(ID_SET_DOC_CENTER_WIDGET_CACHE);
export const idSetHideAdvisorDropdown = createAction(ID_SET_HIDE_ADVISOR_DROPDOWN);
export const idSetAggregationLevel = createAction(ID_SET_AGGREGATION_LEVEL);
export const idSetDisclaimers = createAction(ID_SET_DISCLAIMERS);
export const idSetConfig = createAction(ID_SET_CONFIG);
export const idSetAssetClass = createAction(ID_SET_SELECTED_ASSET_CLASS);
export const idSetAssetClasses = createAction(ID_SET_ASSET_CLASSES);
export const idSetFilteredAccounts = createAction(ID_SET_FILTERED_ACCOUNTS);
export const idResetFund = createAction(ID_RESET_FUND);
export const idResetAssetClass = createAction(ID_RESET_ASSET_CLASS);
export const idSetSelectedCurrency = createAction(ID_SET_SELECTED_CURRENCY);
