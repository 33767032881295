import {
  FF_SET_ACTIVE_SAVED_VIEW,
  FF_SET_REPRESENTATIVES_FOR_FILTER,
  FF_SET_OPTIONS_FOR_FILTER,
  FF_SET_FUNDS_FOR_FILTER,
  FF_SELECTED_FUNDS,
  FF_SELECTED_PERIOD,
  FF_SELECTED_SINGLE_FUND,
  FF_CURRENT_PERIOD_TYPE,
  FF_DASHBOARD_TOTALS,
  FF_SET_CURRENT_PAGE,
  FF_SET_RESULTS_PER_PAGE,
  FF_RESET_DASHBOARD_TOTALS,
  FF_SELECTED_PERIOD_PICKER_TYPE,
  FF_SET_IS_LOADING_DEFAULT_VIEW,
  FF_SET_IS_LOADING_REP_DATA_FOR_FILTER,
  FF_SET_IS_LOADING_FUND_DATA_FOR_FILTER,
  FF_SET_IS_LOADING_FUNDS_FOR_FILTER,
  FF_SET_FEE_TYPE_FILTERS,
} from 'actions/actionsConstants';

import update from 'immutability-helper';

import initialState from './initialState';

export default (state = initialState, action) => {
  switch (action.type) {
    case FF_SET_ACTIVE_SAVED_VIEW:
      return update(state, {
        active_saved_view: { $set: action.payload },
      });
    case FF_SET_REPRESENTATIVES_FOR_FILTER:
      return update(state, {
        representatives: { $set: action.payload },
      });
    case FF_SET_OPTIONS_FOR_FILTER:
      return update(state, {
        options_for_filter: { $set: action.payload },
      });
    case FF_SET_FUNDS_FOR_FILTER:
      return update(state, {
        funds: { $set: action.payload },
      });
    case FF_SELECTED_FUNDS:
      return update(state, {
        selected_funds: { $set: action.payload },
      });
    case FF_SELECTED_PERIOD:
      return update(state, {
        selected_period: { $set: action.payload },
      });
    case FF_SELECTED_SINGLE_FUND:
      return update(state, {
        selected_single_fund: { $set: action.payload },
      });
    case FF_CURRENT_PERIOD_TYPE:
      return update(state, {
        current_period_type: { $set: action.payload },
      });
    case FF_DASHBOARD_TOTALS:
      return update(state, {
        dashboard_totals: (item) => update(item, {
          total_corporate_payment: { $set: action.payload?.corporate_payment || 0 },
          total_investor_fee: { $set: action.payload?.investment_fee_amount || 0 },
          total_revenue_share_fee: { $set: action.payload?.rev_share_fee_amount || 0 },
          total_trail_fee: { $set: action.payload?.trail_fee_amount || 0 },
        }),
      });
    case FF_SET_CURRENT_PAGE:
      return update(state, {
        current_page: { $set: action.payload },
      });
    case FF_SET_RESULTS_PER_PAGE:
      return update(state, {
        results_per_page: { $set: action.payload },
        current_page: { $set: 1 },
      });
    case FF_RESET_DASHBOARD_TOTALS:
      return update(state, {
        dashboard_totals: { $set: initialState.dashboard_totals },
      });
    case FF_SELECTED_PERIOD_PICKER_TYPE:
      return update(state, {
        selected_period_picker_type: { $set: action.payload },
      });
    case FF_SET_IS_LOADING_DEFAULT_VIEW:
      return update(state, {
        is_loading_default_view: { $set: action.payload },
      });
    case FF_SET_IS_LOADING_REP_DATA_FOR_FILTER:
      return update(state, {
        is_loading_rep_data_for_filter: { $set: action.payload },
      });
    case FF_SET_IS_LOADING_FUND_DATA_FOR_FILTER:
      return update(state, {
        is_loading_fund_data_for_filter: { $set: action.payload },
      });
    case FF_SET_IS_LOADING_FUNDS_FOR_FILTER:
      return update(state, {
        is_loading_funds_for_filter: { $set: action.payload },
      });
    case FF_SET_FEE_TYPE_FILTERS:
      return update(state, {
        fund_fee_types: { $set: action.payload },
      });
    default:
      return state;
  }
};
