import _ from 'lodash';

export const initialPaginationState = ({
  total, result_total, page,
  per_page, last_page,
} = {}) => ({
  total: total || 0,
  result_total: result_total || 0,
  page: page || 1,
  per_page: per_page || 25,
  last_page: last_page || 2,
});

export const initialSortState = ({ sort_field, sort_direction } = {}) => ({
  sort_field: sort_field || null,
  sort_direction: sort_direction || 'desc',
});

export const convertObjectToQuery = (filters) => {
  const query = {};
  const keys = Object.keys(filters);

  _.each(keys, (key) => {
    query[key] = { $set: filters[key] };
  });

  return query;
};
