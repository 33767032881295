import configuration from 'configuration';

// This is a temporary change to allow to set any or none CloudFront URL. This config should be revised once iCapital
// decides on CloudFlare Vs Akamai
// https://webpack.js.org/guides/public-path/#on-the-fly
function formatPublicPath() {
  let formattedHost = (configuration.CLOUDFRONT_URL || '/').replace(/^\/*/, '');
  let assetsPath = 'assets';

  if (formattedHost && !/^http/i.test(formattedHost)) {
    formattedHost = `//${formattedHost}`;
  }

  return `${formattedHost}/${assetsPath}/`;
}

// eslint-disable-next-line camelcase, no-undef, no-unused-vars
__webpack_public_path__ = formatPublicPath();
