import { actionFactory } from 'services/actions';
import { getRequest } from 'actions/utils';

import {
  FETCH_ICN_GEO_COUNTRIES,
} from 'actions/actionsConstants';

const fetchIcnGeoCountries = actionFactory(FETCH_ICN_GEO_COUNTRIES);

const icnGeoCountriesUrl = 'icn_geo_countries';

const getIcnGeoCountries = (showSpinner = true) => getRequest({
  url: `${icnGeoCountriesUrl}`,
  showSpinner,
  onSuccess: (response) => fetchIcnGeoCountries(response.icn_geo_countries.filter((c) => c.parsed_name !== null)),
});

export default {
  getIcnGeoCountries,
};
