import filtersReducers from 'reducers/report_generator_reducers/filters';
import initialState from 'reducers/report_generator_reducers/initialState';
import indexReducers from './reportGeneratorConfigsReducer';
import dataReducer from './dataReducer';
import workerReducer from './workerReducer';
import scheduleReducer from './scheduleReducer';
import baseQueryModelsReducer from './baseQueryModelsReducer';
import customQueryReducer from './customQueryReducer';
import sftpAccountReducer from './sftpAccountReducer';
import schedulerNotificationRecipientReducer from './schedulerNotificationRecipientReducer';
import snowflakeConfigReducer from './snowflakeConfigReducer';
import fileAttributeReducer from './fileAttributeReducer';
import snowflakeClientReducer from './snowflakeClientReducer';
import glossaryReducer from 'components/report_generator/glossary/glossarySlice';

const fn = [
  filtersReducers.fieldsReducer,
  filtersReducers.filesReducer,
  filtersReducers.filterReducer,
  filtersReducers.optionsReducer,
  filtersReducers.numericFilterReducer,
  filtersReducers.transformFilterReducer,
  filtersReducers.dateFilterReducer,
  filtersReducers.stringFilterReducer,
  filtersReducers.booleanFilterReducer,

  indexReducers.loadingReducers,
  indexReducers.filterReducer,
  indexReducers.modalReducer,
  indexReducers.configFieldsReducer,
  dataReducer.reportDataReducer,
  workerReducer,
  scheduleReducer,
  baseQueryModelsReducer,
  customQueryReducer,
  sftpAccountReducer,
  schedulerNotificationRecipientReducer,
  snowflakeConfigReducer,
  fileAttributeReducer,
  snowflakeClientReducer,
  glossaryReducer,
];

const reducer = (state = initialState, action) =>
  fn.reduce((stateAccumulator, fnc) => fnc(stateAccumulator, action), state);

export default reducer;
