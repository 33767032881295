import { noValue } from 'services/investor_profile';

const whitelistedProfileAttributes = [
  'signer_type',
  'section_a_individual_us_person',
  'section_a_individual_type',
  'section_a_name_of_investor',
  'section_a_individual_state_country',
  'section_a_individual_address',
  'section_a_individual_address_2',
  'section_a_individual_city',
  'section_a_individual_state',
  'section_a_individual_zip',
  'section_a_individual_pone',
];

const whitelistedContactCardAttributes = [
  'email',
  'first_name',
  'last_name',
  'non_advisor_investor',
];

const whitelistedSignerAttributes = [
  'first_name',
  'last_name',
  'email',
  'primary_relationship',
  'birthdate',
  'section_a_individual_ssn',
  'section_a_title_of_signator',
  'section_a_individual_employment_status',
  'section_a_individual_employer',
  'section_a_individual_occupation',
  'non_advisor_investor',
];

function applyWhitelist(obj, whitelist) {
  const newObj = {};

  whitelist.forEach((field) => {
    if (!noValue(obj[field])) {
      newObj[field] = obj[field];
    }
  });

  return newObj;
}

function newProfileFromExistingProfile(oldProfile) {
  const newContactCard = applyWhitelist(oldProfile.contact_card, whitelistedContactCardAttributes);
  const newSigners = [];
  if (oldProfile.signers) {
    oldProfile.signers.forEach((signer) => {
      if (signer.primary_relationship) {
        newSigners.unshift(applyWhitelist(signer, whitelistedSignerAttributes));
      } else {
        newSigners.push(applyWhitelist(signer, whitelistedSignerAttributes));
      }
    });
  }

  const newProfile = applyWhitelist(oldProfile, whitelistedProfileAttributes);
  newProfile.signers = newSigners;
  newProfile.contact_card = newContactCard;
  return newProfile;
}


export default newProfileFromExistingProfile;
