import update from 'immutability-helper';
import {
  EXPORT_HISTORY_SET_DATA,
  EXPORT_HISTORY_SET_SORT,
  EXPORT_HISTORY_SET_CURRENT_PAGE,
  EXPORT_HISTORY_SET_RESULTS_PER_PAGE,
  EXPORT_HISTORY_SET_DATE_RANGE,
} from '../actions/constants';
import initialState from './initialState';

export default (state = initialState, action) => {
  switch (action.type) {
    case EXPORT_HISTORY_SET_DATA: {
      return update(state, {
        exportHistoryData: {
          data: { $set: action.payload.data },
          currentPage: { $set: action.payload.current_page },
          resultsPerPage: { $set: action.payload.results_per_page },
          totalCount: { $set: action.payload.total_count },
        },
      });
    }
    case EXPORT_HISTORY_SET_SORT: {
      return update(state, { exportHistoryData: { sort: { $set: action.payload } } });
    }
    case EXPORT_HISTORY_SET_CURRENT_PAGE: {
      return update(state, { exportHistoryData: { currentPage: { $set: action.payload } } });
    }
    case EXPORT_HISTORY_SET_RESULTS_PER_PAGE: {
      return update(state, { exportHistoryData: { resultsPerPage: { $set: action.payload } } });
    }

    case EXPORT_HISTORY_SET_DATE_RANGE: {
      return update(state, {
        exportHistoryData: {
          startDate: { $set: action.payload.startDate },
          endDate: { $set: action.payload.endDate },
        },
      });
    }
    default:
      return state;
  }
};
