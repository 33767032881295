import dataReconReducer from './dataReconReducer';
import dataReconFileReducer from './dataReconFileReducer';
import filterSourceReducer from './filterSourceReducer';
import dataReconParameterReducer from './dataReconParameterReducer';

export default {
  dataReconReducer,
  dataReconFileReducer,
  filterSourceReducer,
  dataReconParameterReducer,
};
