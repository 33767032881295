import moment from 'moment';

const pageActions = (filtersData) => ({
  filters: filtersData,
  searchTerms: '',
  usersSearch: '',
  firmsSearch: '',
  sort: [],
});

const exportHistoryData = () => ({
  data: [],
  currentPage: 1,
  resultsPerPage: 25,
  startDate: moment().subtract(1, 'month'),
  endDate: moment(),
  totalCount: 0,
});

const activityLogData = (filters) => ({
  data: [],
  filterValues: {},
  pageActions: pageActions(filters),
});

const userLogData = (filters) => ({
  userDetails: {},
  data: [],
  filterValues: {},
  pageActions: pageActions(filters),
});

const gpActivitiesData = (filters) => ({
  data: [],
  filterValues: {},
  pageActions: pageActions(filters),
});

const gpUserActivitiesData = (filters) => ({
  userDetails: {},
  data: [],
  filterValues: {},
  pageActions: pageActions(filters),
});

const gpFundFollowingsData = () => ({
  data: [],
  currentPage: 1,
  resultsPerPage: 25,
  sort: {},
});

const gpProspectsData = (filters) => ({
  data: [],
  currentPage: 1,
  resultsPerPage: 25,
  filterValues: {},
  pageActions: pageActions(filters),
});

const advisorActivitiesData = (filters) => ({
  userDetails: {},
  data: [],
  filterValues: {},
  pageActions: pageActions(filters),
});

const engagementScoreData = () => ({
  categories: [],
  activity_weights: [],
  user_fund_info: [],
  is_score_info_dialog_open: false,
});

const salesCoverageData = () => ({
  is_user_sales_coverage_dialog_open: false,
  sales_coverage_data: [],
});

export default {
  activityLogData: activityLogData({
    users: [],
    events: [],
    products: [],
    categories: [],
    strategies: [],
    courseTypes: [],
    dateOption: 'all-time',
    startDate: null,
    endDate: null,
    productType: null,
    userStatus: [],
  }),
  userLogData: userLogData({
    events: [],
    products: [],
    categories: [],
    strategies: [],
    courseTypes: [],
    dateOption: 'all-time',
    startDate: null,
    endDate: null,
    productType: null,
    userStatus: [],
  }),
  gpActivitiesData: gpActivitiesData({
    users: [],
    firms: [],
    events: [],
    products: [],
    states: [],
    categories: [],
    strategies: [],
    dateOption: 'all-time',
    startDate: null,
    endDate: null,
    productType: null,
    engagementCategory: [],
  }),
  gpUserActivitiesData: gpUserActivitiesData({
    events: [],
    products: [],
    categories: [],
    strategies: [],
    dateOption: 'all-time',
    startDate: null,
    endDate: null,
    productType: null,
  }),
  gpFundFollowingsData: gpFundFollowingsData(),
  gpProspectsData: gpProspectsData({
    users: [],
    events: [],
    products: [],
    startDate: moment().subtract(1, 'month'),
    endDate: moment(),
    productType: null,
    engagementCategory: [],
  }),
  advisorActivitiesData: advisorActivitiesData({
    events: [],
    products: [],
    categories: [],
    strategies: [],
    courseTypes: [],
    productType: null,
    dateOption: 'all-time',
    startDate: null,
    endDate: null,
    userStatus: [],
  }),
  engagementScoreData: engagementScoreData(),
  exportHistoryData: exportHistoryData(),
  salesCoverageData: salesCoverageData(),
};
