import axios from 'axios';

const integrationPartnersTemplatesPath = 'flexible_feeds/dashboard/templates';
const integrationPartnersBaseTemplatesPath = 'flexible_feeds/dashboard/base_templates';
const integrationPartnersTemplateJobsPath = 'flexible_feeds/dashboard/jobs';

export const getIntegrationPartnerBaseTemplates = () => axios.get(integrationPartnersBaseTemplatesPath, {
  withCredentials: true,
}).then((response) => Promise.resolve(response.data))
  .catch((error) => Promise.reject(error));

export const createIntegrationPartnerTemplate = (template) => axios.post(integrationPartnersTemplatesPath, template, {
  withCredentials: true,
}).then((response) => Promise.resolve(response.data))
  .catch((error) => Promise.reject(error));

export const getIntegrationPartnerBaseTemplate = (baseTemplateName) => {
  const url = `${integrationPartnersBaseTemplatesPath}/${baseTemplateName}`;

  return axios.get(url, {
    withCredentials: true,
  }).then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error));
};

export const getIntegrationPartnerTemplates = (meta) => {
  const url = `${integrationPartnersTemplatesPath}/query`;

  return axios.post(url, { meta }, {
    withCredentials: true,
  }).then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error));
};

export const getIntegrationPartnerTemplate = (templateId) => {
  const url = `${integrationPartnersTemplatesPath}/${templateId}`;

  return axios.get(url, {
    withCredentials: true,
  }).then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error));
};

export const updateIntegrationPartnerTemplate = (template) => {
  const url = `${integrationPartnersTemplatesPath}/${template.id}`;

  return axios.put(url, template, {
    withCredentials: true,
  }).then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error));
};

export const deleteIntegrationPartnerTemplate = (templateId) => {
  const url = `${integrationPartnersTemplatesPath}/${templateId}`;

  return axios.delete(url, {
    withCredentials: true,
  }).then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error));
};

export const executeIntegrationPartnerTemplate = (templateId) => {
  const url = `${integrationPartnersTemplatesPath}/${templateId}/execute`;

  return axios.post(url, { trigger_type: "manual_run" }, {
    withCredentials: true,
  }).then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error));
};

export const executeDownloadIntegrationPartnerTemplate = (templateId) => {
  const url = `${integrationPartnersTemplatesPath}/${templateId}/execute/`;

  return axios.post(url, { trigger_type: "download_run", export: false }, {
    withCredentials: true,
  }).then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error));
};
