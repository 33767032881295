import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import { extractFilterOption } from 'services/dropdown_filter';

export const getFilterOptions = (state) => state.subscriptionWorkflowStatus.filter_options;
export const getSelectedFilters = (state) => state.subscriptionWorkflowStatus.selected_filters;
export const getRowData = (state) => state.subscriptionWorkflowStatus.combinations;
export const getFilteredCombinations = (state) => state.subscriptionWorkflowStatus.filtered_combinations;

const extractFilters = (filterGroup, currentFilter) => {
  filterGroup[currentFilter.typed_filter_id] = true;
  return filterGroup;
};

/*
takes object whose props point to array of objects,
transforms value of typed_filter_id prop into key

input
{
  foo: [{typed_filter_id: 9}, {typed_filter_id: 29}],
  bar: [{typed_filter_id: 12}]
}

produce
{ foo: {9: true, 29:true},
  bar: {12: true}
}
*/

export const activeFiltersAsObj = (selectedFilters) =>
  Object.keys(selectedFilters).reduce((memo, curr) => {
    const group = selectedFilters[curr].reduce(extractFilters, {});
    memo[curr] = group;
    return memo;
  }, {});

export const activeFiltersSelector = createSelector([getSelectedFilters], activeFiltersAsObj);

export const rowDataSelector = createSelector([getRowData], (rowData) => rowData);

const hasProp = (obj, prop) => Object.prototype.hasOwnProperty.call(obj, prop);

// take a filter group object, check the current row to see if it matches any filters applied
export const filterByProp = (selectedFilters, row, attribute, noneSelectedAttribute) => {
  // extract the prop to check from the current row, e.g. row['firm_id']
  let id = row[attribute];

  // cast to a number if possible
  if (!Number.isNaN(parseInt(id, 10))) {
    id = parseInt(id, 10);
  }

  // return true if:
  // the current id is present on the selected filers obj
  // OR
  // id === null and the no_foo filter is applied (no_wlp|no_firm|no_fund)
  if (hasProp(selectedFilters, id) || (id === null && hasProp(selectedFilters, noneSelectedAttribute))) {
    return true;
  }

  return false;
};

export const filterByWorkflowStatus = (selectedFilters, row) => {
  if (isEmpty(selectedFilters)) {
    return true;
  }

  const rowHasFilterSet = Object.keys(selectedFilters).some((status) => row[status] === true);

  return rowHasFilterSet;
};

export const doesPassFilters = (selectedFilters = {}, row) => {
  // check the current row against the groups of selected filters

  // if no filters are applied for the group, then consider that attribute to be passing the filter
  let passesWlp = isEmpty(selectedFilters.wlps);
  let passesFirm = isEmpty(selectedFilters.firms);
  let passesFund = isEmpty(selectedFilters.funds);
  let passesWorkflow = isEmpty(selectedFilters.workflow_status);

  if (!passesWlp) {
    passesWlp = filterByProp(selectedFilters.wlps, row, 'white_label_partner_id', 'no_wlp');
  }

  if (!passesFirm) {
    passesFirm = filterByProp(selectedFilters.firms, row, 'firm_id', 'no_firm');
  }

  if (!passesFund) {
    passesFund = filterByProp(selectedFilters.funds, row, 'private_access_fund_id', 'no_fund');
  }

  if (!passesWorkflow) {
    passesWorkflow = filterByWorkflowStatus(selectedFilters.workflow_status, row);
  }

  return [passesWlp, passesFirm, passesFund, passesWorkflow].every((s) => s === true);
};

export const filterRows = (activeFilters, rows) => rows.filter((row) => doesPassFilters(activeFilters, row));

export const filterCombinations = (state) => {
  const activeFilters = activeFiltersSelector(state);
  const rowData = rowDataSelector(state);

  return filterRows(activeFilters, rowData);
};

export const filteredCombinationsSelector = createSelector(
  [activeFiltersSelector, getRowData],
  (activeFilters, rowData) => filterRows(activeFilters, rowData)
);

// iterate over rows, track unique values, create filter options
export const parseFilters = (data = []) => {
  const tmp = {
    firms: {},
    funds: {},
    wlps: {},
  };

  const buckets = {
    firms: [],
    funds: [],
    wlps: [],
  };

  data.forEach((row) => {
    if (!tmp.firms[row.firm_id]) {
      buckets.firms.push(
        extractFilterOption({
          record: row,
          valueAttr: 'firm_name',
          slugName: 'firm',
          idAttr: 'firm_id',
        })
      );
      tmp.firms[row.firm_id] = 1;
    }

    if (!tmp.wlps[row.white_label_partner_id]) {
      buckets.wlps.push(
        extractFilterOption({
          record: row,
          valueAttr: 'white_label_partner_name',
          slugName: 'wlp',
          idAttr: 'white_label_partner_id',
        })
      );
      tmp.wlps[row.white_label_partner_id] = 1;
    }

    if (!tmp.funds[row.private_access_fund_id]) {
      buckets.funds.push(
        extractFilterOption({
          record: row,
          valueAttr: 'fund_name',
          slugName: 'fund',
          idAttr: 'private_access_fund_id',
        })
      );
      tmp.funds[row.private_access_fund_id] = 1;
    }
  });

  return buckets;
};

export const stringToSeconds = (str) => {
  if (str === '' || str === '__hr__min__sec') return 0;
  const [hours, remainder] = str.split('hr');
  const [minutes, remainder2] = remainder.split('min');
  const [seconds, rest] = remainder2.split('sec');
  return parseInt(hours, 10) * 3600 + parseInt(minutes, 10) * 60 + parseInt(seconds, 10);
};

export const secondsToString = (duration) => {
  if (duration === 0) return '__hr__min__sec';
  const seconds = duration % 60;
  const totalMinutes = Math.floor(duration / 60);
  const minutes = totalMinutes % 60;
  const hours = Math.floor(totalMinutes / 60);
  return (
    `${hours.toString().padStart(2, '0')}hr` +
    `${minutes.toString().padStart(2, '0')}min` +
    `${seconds.toString().padStart(2, '0')}sec`
  );
};
