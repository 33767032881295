import { postRequest } from 'actions/utils';
import {
  getSelectedAdvisor,
  getSelectedInvestor,
  getSelectedCurrencyCode,
  pdfReportTypes,
} from 'reducers/investment_dashboard_reducers/selectors';
import gtmPush from 'services/google_tag_manager';

const prRequestReport = (type) => (dispatch, getState) => {
  const state = getState();
  const { gtmReportType, gtmReportName } = pdfReportTypes(state).find(({ reportType }) => reportType === type);

  gtmPush({
    event: 'icn_investmentDashDownloadCTA',
    eventDisplayName: 'Download Document',
    categories: {
      investmentProduct: ['Alts'],
      feature: 'Investment Dashboard',
      superFeatureSet: 'Manage',
      phase: 'Post-Trade',
      activity: 'Feature Usage',
      action: 'Download',
    },
    properties: {
      buttonProperties: {
        buttonText: 'Download PDF',
        buttonLocation: 'Investment Dashboard Header',
      },
    },
    reportProperties: {
      reportFormat: 'PDF',
      reportType: gtmReportType,
      reportName: gtmReportName,
    },
  });

  return dispatch(
    postRequest({
      url: 'investment_dashboard/pdf_report',
      params: {
        advisor_id: getSelectedAdvisor(state)?.id,
        investor_id: getSelectedInvestor(state).id,
        selected_currency: getSelectedCurrencyCode(state),
        report_type: type,
      },
      showSpinner: false,
    })
  );
};

export default {
  prRequestReport,
};
