export const EMAIL_REGEX = /^$|^[A-Za-z0-9]{1}(?!.*?\.\.)[^ @]*@[A-Za-z0-9\-.]+\.[A-Za-z]+$/;
export const ICAPITAL_EMAIL_REGEX = /^$|^[A-Za-z0-9]{1}(?!.*?\.\.)[^ @]*@icapital(network)?.com$/;
export const MMDD_REGEX = /^(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])$/;
export const MMDDYYYY_REGEX = /^(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])(\d{4})$/;
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\~!@#$%^&*_\-\+=\`:;\"\'<>,.?\/\[\]{}()])[A-Za-z\d\~!@#$%^&*_\-\+=\`:;\"\'<>,.?\/\[\]{}()]{10,}$/;
export const MMDDYYYY_SLASH_REGEX = /^(0[1-9]|1[012])\/(0[1-9]|[12][0-9]|3[01])\/(\d{4})$/;
export const HEX_COLOR_REGEX = /^#(?:[0-9a-fA-F]{3}){1,2}$/;
export const HAS_SIX_DIGITS = /^\d{6}$/gm;
export const HAS_SEVEN_DIGITS = /^\d{7}$/gm;
export const ALLOWED_CHARACTERS_REGEX = /^[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/? ]*$/;
export const URL_REGEX = /^(ftp|http|https):\/\/[^ "]+$/;
//To be modified - safari does not support
//export const HIDE_EMAIL_REGEX = /(?<=...)[^@\n](?=[^@\n]*?@)|(?:(?<=@...)|G(?=[^@\n]*$)).*/gm;
