import axios from 'axios';
import spinnerActions from '../../spinner_actions/spinnerActions';
import { settingParams } from './treatment';

function constructRequest(url, method, params = {}, data = {}) {
  const options = {
    data,
    params,
    method,
    withCredentials: true,
    url: /^(f|ht)tps?:\/\//i.test(url) ? url : `/data_recons${url}`,
  };
  return axios(options);
}

export default function executeRequest(url, method, action, properties = {}) {
  const { params, data, options } = properties;
  const paramsProcessed = settingParams(params);
  const spinnerUsage = options.spinner !== undefined ? options.spinner : true;
  const successCallback = options.successCallback !== undefined ? options.successCallback : (_) => null;
  const failureCallback = options.failureCallback !== undefined ? options.failureCallback : (_) => null;

  return (dispatch) => {
    if (spinnerUsage) {
      dispatch(spinnerActions.beginApiRequest());
    }

    return constructRequest(url, method, paramsProcessed, data).then((response) => {
      dispatch(action({
        response: response.data,
        status: response.status,
        statusText: response.statusText,
      }));

      successCallback(response.data);

      if (spinnerUsage) {
        dispatch(spinnerActions.endApiRequest());
      }
    }).catch(({ request, response, message }) => {
      dispatch(action({
        response: response?.data || {},
        status: response?.status || null,
        statusText: request || response?.status === 500 ? 'No response from the server.' : message,
      }));

      failureCallback(response?.data || {});

      if (spinnerUsage) {
        dispatch(spinnerActions.endApiRequest());
      }
    });
  };
}
