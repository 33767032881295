import {
  SUB_DOCS_LOAD_DOCUMENTS_SUCCESS,
  SUB_DOCS_SET_ACTIVE_MODAL,
  SUB_DOCS_SET_SELECTED_ROW,
  SUB_DOCS_SET_WLP_FILTER,
  SUB_DOCS_SET_DOCUMENT_TYPES_FILTER,
  SUB_DOCS_SET_PAF_FILTER,
  SUB_DOCS_SET_TYPE_FILTER,
  SUB_DOCS_SET_LIMIT_CODE_FILTER,
  SUB_DOCS_SET_FIRM_FILTER,
  SUB_DOCS_SET_SORTING,
} from 'actions/actionsConstants';

import update from 'immutability-helper';

import normalizeCurrentPage from '../utils';
import initialState from '../initialState';

export default (state = initialState, action) => {
  switch (action.type) {
    case SUB_DOCS_LOAD_DOCUMENTS_SUCCESS: {
      let page = null;

      if (action.payload.length === 0 && state.current_page > initialState.current_page) {
        page = state.current_page - 1;
      } else {
        page = state.current_page;
      }

      return update(state, {
        documents: { $set: action.payload.definitions },
        current_page: { $set: page },
        documents_count: { $set: action.payload.definitions_count },
        filter_options: { $set: action.payload.filter_options },
      });
    }
    case SUB_DOCS_SET_ACTIVE_MODAL:
      return update(state, { active_modal: { $set: action.payload } });
    case SUB_DOCS_SET_SELECTED_ROW:
      return update(state, { selected_row: { $set: action.payload } });
    case SUB_DOCS_SET_PAF_FILTER:
      return update(state, {
        selected_paf_filter: { $set: action.payload },
        current_page: { $set: normalizeCurrentPage(1, state) },
      });
    case SUB_DOCS_SET_WLP_FILTER:
      return update(state, {
        selected_wlp_filter: { $set: action.payload },
        current_page: { $set: normalizeCurrentPage(1, state) },
      });
    case SUB_DOCS_SET_DOCUMENT_TYPES_FILTER:
      return update(state, {
        selected_document_type_filter: { $set: action.payload },
        current_page: { $set: normalizeCurrentPage(1, state) },
      });
    case SUB_DOCS_SET_TYPE_FILTER:
      return update(state, {
        selected_sub_doc_type_filter: { $set: action.payload },
        current_page: { $set: normalizeCurrentPage(1, state) },
      });
    case SUB_DOCS_SET_LIMIT_CODE_FILTER:
      return update(state, {
        selected_limit_code_filter: { $set: action.payload },
        current_page: { $set: normalizeCurrentPage(1, state) },
      });
    case SUB_DOCS_SET_FIRM_FILTER:
      return update(state, {
        selected_firm_filter: { $set: action.payload },
        current_page: { $set: normalizeCurrentPage(1, state) },
      });
    case SUB_DOCS_SET_SORTING:
      return update(state, {
        sorting_column: { $set: action.payload.sortingColumn },
        sorting_order: { $set: action.payload.sortingOrder },
      });
    default:
      return state;
  }
};
