import { useEffect } from 'react';
import { getRequest } from '../services/apiRequest';

const checkHeartBeat = () => getRequest({
  url: '/check_session_timeout',
});

const logout = () => document.querySelector("a[href$='logout']")?.click();

const clearTimeout = (interval) => {
  if (interval) {
    clearInterval(interval);
  }
};

const useHeartBeat = (icnReactBootstrap) => {
  useEffect(() => {
    let interval = null;
    if (icnReactBootstrap.user?.isSsoSessionWithLogoutStrategy) {
      interval = setInterval(() => {
        checkHeartBeat().then((data) => {
          if (data.ttl <= 61) {
            clearTimeout(interval);
            logout();
          }
        }).catch(logout);
      }, 60000);
    }
    return () => clearTimeout(interval);
  }, [icnReactBootstrap]);
};

export default useHeartBeat;
