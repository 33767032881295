import {
  FD_SELECT_FUNDS,
  FD_SET_FUNDS,
  FD_SET_VIEW,
  FD_SET_VIEW_MODE,
} from 'actions/actionsConstants';

import {
  FD_FUND_INFO,
  FD_CURRENT_STATUS,
  FD_SITE_ENGAGEMENT,
  FD_SUBSCRIPTION_STATUS,
} from 'actions/fund_dashboard_actions/fundDashboardActions';

import update from 'immutability-helper';

const initialState = {
  funds: [],
  selected_funds: [],
  fund_info: {
    target_commitment: 0,
    documents_due_date: null,
    current_status: {},
    is_loading: false,
  },
  summary: {
    current_status: {},
    current_status_is_loading: false,
    site_engagement: [],
    site_engagement_is_loading: false,
    subscription_status: [],
    subscription_status_is_loading: false,
  },
  view: 'default',
  filters: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FD_SELECT_FUNDS:
      return update(state, {
        selected_funds: { $set: action.payload },
      });
    case FD_SET_FUNDS:
      return update(state, {
        funds: {
          $set: action.payload.map((fund) => ({
            id: fund.private_access_fund.id,
            name: fund.private_access_fund.name,
            fund_id: fund.private_access_fund.fund_id,
          })),
        },
      });
    case FD_FUND_INFO.REQUEST:
      return update(state, {
        fund_info: { is_loading: { $set: true } },
      });
    case FD_FUND_INFO.SUCCESS:
      return update(state, {
        fund_info: { $set: { ...action.payload.response, is_loading: false } },
      });
    case FD_CURRENT_STATUS.REQUEST:
      return update(state, {
        summary: { current_status_is_loading: { $set: true } },
      });
    case FD_CURRENT_STATUS.SUCCESS:
      return update(state, {
        summary: {
          current_status: { $set: action.payload.response },
          current_status_is_loading: { $set: false },
        },
      });
    case FD_SITE_ENGAGEMENT.REQUEST:
      return update(state, {
        summary: { site_engagement_is_loading: { $set: true } },
      });
    case FD_SITE_ENGAGEMENT.SUCCESS:
      return update(state, {
        summary: {
          site_engagement: { $set: action.payload.response },
          site_engagement_is_loading: { $set: false },
        },
      });
    case FD_SUBSCRIPTION_STATUS.REQUEST:
      return update(state, {
        summary: { subscription_status_is_loading: { $set: true } },
      });
    case FD_SUBSCRIPTION_STATUS.SUCCESS:
      return update(state, {
        summary: {
          subscription_status: { $set: action.payload.response },
          subscription_status_is_loading: { $set: false },
        },
      });
    case FD_SET_VIEW:
      return update(state, {
        view: { $set: action.payload },
      });
    case FD_SET_VIEW_MODE:
      return update(state, {
        view: { $set: action.payload.view },
        filters: { $set: action.payload.filters },
      });
    default:
      return state;
  }
};
