import {
  DR_LOAD_DATA_RECON_FILES,
} from 'actions/actionsConstants';

import initialState from '../initialState';
import { updateState } from '../helpers';

const REQUEST_OBJECT = 'data_recon_file';

export default (state = initialState, action) => {
  switch (action.type) {
    case DR_LOAD_DATA_RECON_FILES:
      return updateState(state, action.payload, (data) => ({
        request_object: REQUEST_OBJECT,
        request_type: 'set_data_recon_file_list',
        message: 'Fetched Data Recon File List.',
        data_recon_files: {
          records: data.data_recon_files,
          response_id: Date.now(),
          ...data.meta,
        },
      }));
    default:
      return state;
  }
};
