import update from 'immutability-helper';
import initialState from 'modules/pdf_viewer/state/initialState';

import {
  PDFVIEWER_SET_CURRENT_PAGE,
  PDFVIEWER_SET_NUMBER_OF_PAGES,
  PDFVIEWER_SET_DOCUMENT,
  PDFVIEWER_SET_LOADING_ERROR_MESSAGE,
  PDFVIEWER_IS_LOADING,
  PDFVIEWER_SET_SCALE,
  PDFVIEWER_SET_DOCUMENT_NAME,
  PDFVIEWER_SET_PAGE_VIEWPORTS,
  PDFVIEWER_SET_HAS_SCROLLED_INTO_PAGE,
} from 'actions/actionsConstants';

export default (state = initialState, action) => {
  switch (action.type) {
    case PDFVIEWER_SET_CURRENT_PAGE: {
      return update(state, {
        currentPage: { $set: action.payload },
      });
    }
    case PDFVIEWER_SET_NUMBER_OF_PAGES: {
      return update(state, {
        numberOfPages: { $set: action.payload },
      });
    }
    case PDFVIEWER_SET_DOCUMENT: {
      return update(state, {
        document: { $set: action.payload },
      });
    }
    case PDFVIEWER_SET_LOADING_ERROR_MESSAGE: {
      return update(state, {
        loadingErrorMessage: { $set: action.payload },
      });
    }
    case PDFVIEWER_IS_LOADING: {
      return update(state, {
        isLoading: { $set: action.payload },
      });
    }
    case PDFVIEWER_SET_SCALE: {
      return update(state, {
        scale: { $set: action.payload },
      });
    }
    case PDFVIEWER_SET_DOCUMENT_NAME: {
      return update(state, {
        documentName: { $set: action.payload },
      });
    }
    case PDFVIEWER_SET_PAGE_VIEWPORTS: {
      return update(state, {
        pageViewports: { $set: action.payload },
      });
    }
    case PDFVIEWER_SET_HAS_SCROLLED_INTO_PAGE: {
      return update(state, {
        hasScrolledIntoPage: { $set: action.payload },
      });
    }
    default:
      return state;
  }
};
