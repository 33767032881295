import i18n from 'i18n';
export const INVESTMENT_APPROVAL_STATUS_OVERRIDES = {
    REVERTED: 'reverted',
    INVESTMENT_AUTO_REVERTED: 'investment_auto_reverted',
    PENDING_REVIEW: 'pending_review',
    EXTERNAL_REVIEW: 'external_review',
    EXTERNAL_SECONDARY_REVIEW: 'external_secondary_review',
    IR_REVIEW: 'ir_review',
    COMPLIANCE_REVIEW: 'compliance_review',
    APPROVED: 'approved',
    ACCEPTED: 'accepted',
    PRE_APPROVAL: 'pre_approval',
    PRE_APPROVAL_SECONDARY: 'pre_approval_secondary',
    TRANSFERS: 'transfers',
};
export const CM_TABLE_STATUS = Object.assign(Object.assign({}, INVESTMENT_APPROVAL_STATUS_OVERRIDES), { REDEMPTIONS: 'redemptions', LETTERS_OF_AUTHORIZATION: 'letters_of_authorization' });
export const SR_TABLE_STATUS = CM_TABLE_STATUS;
// todo: we should convert CM_TABLE_STATUS and SR_TABLE_STATUS into a single INVESTMENTS_TABLES_STATUSES
export const GET_INVESTMENT_APPROVAL_STATUS_DEFAULT_LABELS = () => ({
    investment_auto_reverted: i18n.t('common:investment_auto_reverted', 'Auto-Reverted Investment'),
    reverted: i18n.t('common:attention_required', 'Attention Required'),
    pending_review: i18n.t('common:in_process', 'In-Process'),
    external_review: i18n.t('common:external_review', 'External Review'),
    external_secondary_review: i18n.t('common:external_secondary_review', 'External Review 2'),
    ir_review: i18n.t('common:document_review', 'Document Review'),
    compliance_review: i18n.t('common:compliance_review', 'Legal Review'),
    approved: i18n.t('common:approved', 'Approved'),
    accepted: i18n.t('common:accepted', 'Accepted'),
    pre_approval: i18n.t('common:pre_approval', 'Pre-Approval'),
    pre_approval_secondary: i18n.t('common:pre_approval_secondary', 'Pre-Approval 2'),
    transfers: i18n.t('common:transfers', 'Transfers'),
});
export const GET_STATUS_TABLES_DEFAULT_LABELS = () => (Object.assign(Object.assign({}, GET_INVESTMENT_APPROVAL_STATUS_DEFAULT_LABELS()), { redemptions: i18n.t('common:redemptions', 'Redemptions'), letters_of_authorization: i18n.t('common:letters_of_authorization', 'Letters of Authorization') }));
