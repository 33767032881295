import actions from 'actions';
import { getRequest, postRequest, patchRequest } from 'actions/utils';
import { actionFactory } from 'services/actions';

import {
  RGA_LOAD_SCHEDULED_CONFIGS_COUNT,
  RGA_LOAD_SCHEDULED_CONFIGS_SUCCESS,
  RGA_SCHEDULED_REQUEST_SET_ACTIVE_TAB,
  RGA_SCHEDULED_REQUEST_SET_RESULTS_PER_PAGE,
  RGA_SCHEDULED_REQUEST_SET_CURRENT_PAGE,
  RGA_SCHEDULED_REQUEST_SET_SORTING,
  RGA_SCHEDULED_REQUEST_SHOW_DELIVERY_SCHEDULE_MODAL,
  RGA_SCHEDULED_REQUEST_SET_DELIVERY_SCHEDULES,
  RG_SET_CONFIG_ID_TO_DISABLE,
  RGA_SCHEDULED_REQUEST_APPLY_FILTERS,
} from '../actionsConstants';

const rgaLoadScheduledConfigsCount = actionFactory(
  RGA_LOAD_SCHEDULED_CONFIGS_COUNT,
);
const rgaLoadScheduledConfigsSuccess = actionFactory(
  RGA_LOAD_SCHEDULED_CONFIGS_SUCCESS,
);
const rgaScheduledRequestSetDeliverySchedules = actionFactory(
  RGA_SCHEDULED_REQUEST_SET_DELIVERY_SCHEDULES,
);
const rgaScheduledRequestsSetShowDeliveryScheduleModal = actionFactory(
  RGA_SCHEDULED_REQUEST_SHOW_DELIVERY_SCHEDULE_MODAL,
);
const rgSetConfigIdToDisable = actionFactory(RG_SET_CONFIG_ID_TO_DISABLE);
const onReportScheduledConfigsSuccess = (data) => (dispatch) => {
  dispatch(rgaLoadScheduledConfigsSuccess(data.report_scheduled_configs));
  dispatch(rgaLoadScheduledConfigsCount(data.total));
};
const rgaLoadScheduledConfigs = () => (dispatch, getState) => {
  const state = getState().reportScheduleRequests;
  const {
    currentPage,
    resultsPerPage,
    sortingColumn,
    sortingOrder,
    activeTab,
    reportNameFilter,
    freshServiceStatusFilter,
    approvalStatusFilter,
    jobStatusFilter,
    lockedFilter,
    creatorFilter,
    deliveryFilter,
  } = state;

  dispatch(
    getRequest({
      url: '/report_generator/scheduled_configs',
      params: {
        page: currentPage,
        per_page: resultsPerPage,
        sorting_column: sortingColumn,
        sorting_order: sortingOrder,
        schedule_status: activeTab,
        name_filter: reportNameFilter,
        fresh_service_status_filter: freshServiceStatusFilter,
        approval_status_filter: approvalStatusFilter,
        job_status_filter: jobStatusFilter,
        locked_filter: lockedFilter,
        creator_filter: creatorFilter,
        delivery_filter: deliveryFilter,
      },
      onSuccess: onReportScheduledConfigsSuccess,
    }),
  );
};
const setRgaScheduleRequestActiveTab = (activeTab) => (dispatch) => {
  dispatch({ type: RGA_SCHEDULED_REQUEST_SET_ACTIVE_TAB, payload: activeTab });
  dispatch(rgaLoadScheduledConfigs());
};
const setRgaScheduleRequestDeliverySchedules = (data) => (dispatch) => {
  dispatch(rgaScheduledRequestsSetShowDeliveryScheduleModal(true));
  dispatch(rgaScheduledRequestSetDeliverySchedules(data));
};
const setRgaScheduleResultsPerPage = (resultsPerPage) => (dispatch) => {
  dispatch({
    type: RGA_SCHEDULED_REQUEST_SET_RESULTS_PER_PAGE,
    payload: resultsPerPage,
  });
  dispatch(rgaLoadScheduledConfigs());
};
const setRgaScheduleCurrentPage = (currentPage) => (dispatch) => {
  dispatch({
    type: RGA_SCHEDULED_REQUEST_SET_CURRENT_PAGE,
    payload: currentPage,
  });
  dispatch(rgaLoadScheduledConfigs());
};
const setRgaScheduleSorting = (sortingColumn, sortingOrder) => (dispatch) => {
  dispatch({
    type: RGA_SCHEDULED_REQUEST_SET_SORTING,
    payload: { sortingColumn, sortingOrder },
  });
  dispatch(rgaLoadScheduledConfigs());
};
const applyRgaScheduleFilters =
  (
    creatorFilter,
    reportNameFilter,
    freshServiceStatusFilter,
    approvalStatusFilter,
    jobStatusFilter,
    deliveryFilter,
  ) =>
  (dispatch) => {
    dispatch({
      type: RGA_SCHEDULED_REQUEST_APPLY_FILTERS,
      payload: {
        creatorFilter,
        reportNameFilter,
        freshServiceStatusFilter,
        approvalStatusFilter,
        jobStatusFilter,
        deliveryFilter,
      },
    });
    dispatch(rgaLoadScheduledConfigs());
  };

const rgaScheduledRequestUpdateReportDelivery = (
  configId,
  enabled,
  disableReason = null,
) => {
  const message = enabled
    ? 'Report delivery enabled successfully.'
    : 'Report delivery disabled successfully.';

  return patchRequest({
    url: `report_generator/scheduled_configs/${configId}`,
    data: {
      report_config_scheduler: {
        enabled,
        disable_reason: disableReason,
      },
    },
    onSuccess: rgaLoadScheduledConfigs,
    successMessage: { message },
    errorMessage: {
      title: 'Error',
      message: 'Failed to update report delivery',
    },
  });
};

const setRgaScheduleRequestAdminApproval = (
  configId,
  approvalStatus,
  approvalReason,
  forceAutoApprove,
) =>
  postRequest({
    url: `report_generator/scheduled_configs/${configId}/admin_approval`,
    data: {
      report_config_scheduler: {
        approval_status: approvalStatus,
        approval_reason: approvalReason,
        force_auto_approve: forceAutoApprove,
      },
    },
    onSuccess:
      approvalStatus === 'accepted'
        ? () => actions.reportScheduleData(configId, true)
        : null,
    successMessage: { message: `Report schedule ${approvalStatus}` },
    errorMessage: {
      title: 'Error',
      message: 'Failed to accept report schedule',
    },
  });

const getRgaScheduleRequestDeliverySchedules = (configId) =>
  getRequest({
    url: `report_generator/scheduled_configs/${configId}/delivery_schedules`,
    onSuccess: setRgaScheduleRequestDeliverySchedules,
    errorMessage: {
      title: 'Error',
      message: 'Failed to fetch delivery schedules.',
    },
  });

export const rgaRemoveScheduledConfigSuccess = () => (dispatch) =>
  dispatch(rgaLoadScheduledConfigs());

export default {
  rgaLoadScheduledConfigs,
  setRgaScheduleRequestActiveTab,
  setRgaScheduleResultsPerPage,
  setRgaScheduleCurrentPage,
  setRgaScheduleSorting,
  rgaScheduledRequestUpdateReportDelivery,
  setRgaScheduleRequestAdminApproval,
  applyRgaScheduleFilters,
  getRgaScheduleRequestDeliverySchedules,
  rgaScheduledRequestsSetShowDeliveryScheduleModal,
  rgSetConfigIdToDisable,
};
