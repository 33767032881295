import { TOGGLE_NOTES_MODAL, OPERATION_DOCS_SET_NOTES, } from 'actions/actionsConstants';
import { getRequest, postRequest } from '../../utils';

const toggleNotesModal = (docSource, docId) => {
  return (dispatch) => {
    dispatch({ type: TOGGLE_NOTES_MODAL, payload: [docSource, docId] });
  };
}

const createOperationDocNote = (docSource, docId, noteText) => {
  return postRequest({
    url: `doc_center/notes?doc_source=${docSource}&id=${docId}`,
    data: { body: noteText},
    withCredentials: true,
    errorMessage: {
      title: 'Could not create the note',
    },
    onSuccess: () => fetchOperationDocNotes(docSource, docId),
  });
}

const fetchOperationDocNotes = (docSource, docId) => {
  return (dispatch) => dispatch(getRequest({
    url: `doc_center/notes?doc_source=${docSource}&id=${docId}`,
    onSuccess: setOperationDocNotes,
  }));
}

const setOperationDocNotes = (results) => {
  return {
    type: OPERATION_DOCS_SET_NOTES,
    payload: results,
  };
}

export default {
  toggleNotesModal,
  createOperationDocNote,
  fetchOperationDocNotes,
  setOperationDocNotes
}
