import { createAction } from 'redux-actions';
import { BASE_NAME as ENTRY_BASE_NAME } from '../actions';

export const BASE_NAME = `${ENTRY_BASE_NAME}_versions_show`;

export const FORM_TEMPLATES_VERSIONS_SHOW = {
  UNMOUNT: `${BASE_NAME}_UNMOUNT`,
};

export const onUnmount = createAction(FORM_TEMPLATES_VERSIONS_SHOW.UNMOUNT);

export default {
  onUnmount,
};
