import {
  SR_LOAD_RIAS_FIRMS_SUCCESS,
  SR_LOAD_ASSIGNABLE_RIAS_FIRMS_SUCCESS,
  SR_ADD_ASSIGNABLE_RIAS_FIRM_SUCCESS,
  SR_DELETE_ASSIGNABLE_RIAS_FIRM_SUCCESS,
} from 'actions/actionsConstants';

import update from 'immutability-helper';

import { sortArrayAlphabetically } from '../utils';
import initialState from '../initialState';

export default (state = initialState, action) => {
  let newState = null;
  let index = null;
  let riasFirms = [];

  switch (action.type) {
    case SR_LOAD_RIAS_FIRMS_SUCCESS:
      return update(state, { rias_firms: { $set: action.payload } });
    case SR_LOAD_ASSIGNABLE_RIAS_FIRMS_SUCCESS:
      riasFirms = action.payload.map((obj) => ({
        id: obj.id,
        name: `${obj.name} - ${obj.id}`,
      }));
      return update(state, { assignable_rias_firms: { $set: sortArrayAlphabetically(riasFirms, 'name') } });
    case SR_ADD_ASSIGNABLE_RIAS_FIRM_SUCCESS:
      index = state.assignable_rias_firms.findIndex((e) => e.id === action.payload.id);
      if (index === -1) {
        newState = update(state, {
          assignable_rias_firms: {
            $push: [{ id: action.payload.id, name: action.payload.name }],
          },
        });
      } else {
        newState = state;
      }
      return newState;
    case SR_DELETE_ASSIGNABLE_RIAS_FIRM_SUCCESS:
      index = state.assignable_rias_firms.findIndex((e) => e.id === action.payload);
      if (index === -1) {
        newState = state;
      } else {
        newState = update(state, {
          assignable_rias_firms: { $splice: [[index, 1]] },
        });
      }
      return newState;
    default:
      return state;
  }
};
