import { getRequest } from 'actions/utils';

const BASE_AGREEMENTS_URL = 'signature_processes';

const loadAgreementSigningUrl = (params, showSpinner = true) => (
  getRequest({
    showSpinner,
    url: `${BASE_AGREEMENTS_URL}/fetch_sign_url`,
    params,
  })
);

const loadAgreementFirstParticipantSigned = (params, showSpinner = true) => (
  getRequest({
    showSpinner,
    url: `${BASE_AGREEMENTS_URL}/first_participant_signed`,
    params,
  })
);

export default {
  loadAgreementSigningUrl,
  loadAgreementFirstParticipantSigned,
};
