import { RG_SET_SNOWFLAKE_CLIENTS } from 'actions/actionsConstants';
import { getRequest } from 'actions/utils';
import { actionFactory } from 'services/actions';

const BASE_URL = 'report_generator/snowflake_clients';
const setSnowflakeClients = actionFactory(RG_SET_SNOWFLAKE_CLIENTS);
const fetchRgSnowflakeClients = () => (dispatch) =>
  dispatch(
    getRequest({
      url: BASE_URL,
      onSuccess: setSnowflakeClients,
    }),
  );

export default {
  fetchRgSnowflakeClients,
};
