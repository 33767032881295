import {
  DR_LOAD_PRIVATE_ACCESS_FUNDS,
  DR_LOAD_FUND_ADMIN_GROUPS,
  DR_LOAD_RECONCILIATION_TYPES,
  DR_LOAD_RECONCILIATION_PERIODS,
  DR_LOAD_FUND_ADMINS,
} from 'actions/actionsConstants';

import initialState from '../initialState';
import { updateState } from '../helpers';

const REQUEST_OBJECT = 'filter_source';

export default (state = initialState, action) => {
  switch (action.type) {
    case DR_LOAD_PRIVATE_ACCESS_FUNDS:
      return updateState(state, action.payload, (data) => ({
        request_object: REQUEST_OBJECT,
        request_type: 'set_private_access_funds',
        message: 'Fetched Private Access Funds.',
        private_access_funds: data,
      }));
    case DR_LOAD_FUND_ADMIN_GROUPS:
      return updateState(state, action.payload, (data) => ({
        request_object: REQUEST_OBJECT,
        request_type: 'set_fund_admin_groups',
        message: 'Fetched Fund Admin Groups.',
        fund_admin_groups: data,
      }));
    case DR_LOAD_RECONCILIATION_TYPES:
      return updateState(state, action.payload, (data) => ({
        request_object: REQUEST_OBJECT,
        request_type: 'load_reconciliation_types',
        message: 'Fetched Reconciliation Types List.',
        reconciliation_types: data,
      }));
    case DR_LOAD_RECONCILIATION_PERIODS:
      return updateState(state, action.payload, (data) => ({
        request_object: REQUEST_OBJECT,
        request_type: 'load_reconciliation_periods',
        message: 'Fetched Reconciliation Periods List.',
        reconciliation_periods: data,
      }));
    case DR_LOAD_FUND_ADMINS:
      return updateState(state, action.payload, (data) => ({
        request_object: REQUEST_OBJECT,
        request_type: 'load_fund_admins',
        message: 'Fetched Fund Admins.',
        fund_admins: data,
      }));
    default:
      return state;
  }
};
