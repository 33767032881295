import { getRequest, postRequest } from 'actions/utils';
import {
  RGA_WORKER_STARTED,
  RGA_WORKER_FINISHED,
  RGA_UPDATE_WORKER_PROGRESS,
} from 'actions/actionsConstants';
import { actionFactory } from 'services/actions';

import { getWorkerId } from 'reducers/report_generator_reducers/selectors';

export const reportGeneratorConfigsWorkerStarted =
  actionFactory(RGA_WORKER_STARTED);
export const reportGeneratorConfigsWorkerFinished =
  actionFactory(RGA_WORKER_FINISHED);
export const updateReportGeneratorConfigsWorkerStatus = actionFactory(
  RGA_UPDATE_WORKER_PROGRESS,
);

function _processWorkerResponse(data) {
  return (dispatch) => {
    if (data.status === 'complete') {
      window.location.assign(data.download_url);
      dispatch(
        reportGeneratorConfigsWorkerFinished({
          reportExecutedQuery: data.performed_query,
        }),
      );
    } else if (data.status !== 'failed') {
      dispatch(
        updateReportGeneratorConfigsWorkerStatus({
          total: data.total,
          at: data.at,
          status: data.status,
        }),
      );
      setTimeout(() => dispatch(getWorkerStatus()), 1000);
    } else {
      dispatch(
        updateReportGeneratorConfigsWorkerStatus({
          total: data.total,
          at: data.at,
          status: data.status,
        }),
      );
    }
  };
}

export const getWorkerStatus = () =>
  function (dispatch, getState) {
    const workerId = getWorkerId(getState());
    const url = `report_generator/configs/worker_status?worker_id=${workerId}`;

    return dispatch(
      getRequest({
        url,
        onSuccess: _processWorkerResponse,
        onFailure: reportGeneratorConfigsWorkerFinished,
        showSpinner: false,
      }),
    );
  };

export const enqueueReportWorker =
  (configId, admin = false) =>
  (dispatch) =>
    dispatch(
      postRequest({
        url: 'report_generator/schedule/jobs',
        data: { id: configId, admin: admin || undefined },
        successMessage: { message: 'Successfully triggered report delivery' },
        errorMessage: {
          title: 'Error',
          message: 'Failed to trigger report delivery',
        },
      }),
    );
