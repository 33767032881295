import _ from 'lodash';

const beneficialOwnerRadioFields = [
  'investor_has_beneficiaries',
  'investor_beneficiaries_publicly_traded',
  'investor_beneficiaries_regulated_entity',
];

function allBeneficialOwnerFields(n) {
  return beneficialOwnerFields(n)
    .concat(publiclyTradedFields(n))
    .concat(regulatorFields(n));
}

function beneficialOwnerFields(n) {
  // just use suffixes?
  return [
    `beneficial_owners_${n}_owner_address_city`,
    `beneficial_owners_${n}_owner_address_country`,
    `beneficial_owners_${n}_owner_address_line1`,
    `beneficial_owners_${n}_owner_address_line2`,
    `beneficial_owners_${n}_owner_address_state`,
    `beneficial_owners_${n}_owner_address_zipcode`,
    `beneficial_owners_${n}_owner_name`,
  ];
}

function publiclyTradedFields(n) {
  return [
    `beneficial_owners_${n}_exchange_name`,
    `beneficial_owners_${n}_other_publicly_traded_exchange_name`,
  ];
}

function regulatorFields(n) {
  return [
    `beneficial_owners_${n}_regulator_jurisdiction`,
    `beneficial_owners_${n}_regulator_name`,
  ];
}

function removeAndShiftBeneficialOwners(currOwner, profile) {
  const updateObj = {};

  while (currOwner <= 5) {
    const nextOwner = currOwner + 1;


    const nextOwnerFields = allBeneficialOwnerFields(nextOwner);

    if (nextOwner <= 5 && nextOwnerFields.some(field => !!profile[field])) {
      for (const f of nextOwnerFields) {
        const field = f;
        const updateKey = _.replace(field, nextOwner, currOwner);
        updateObj[updateKey] = profile[field];
      }
      currOwner++;
    } else {
      for (const f of allBeneficialOwnerFields(currOwner)) {
        const field = f;
        updateObj[field] = '';
        currOwner = 6;
      }
    }
  }

  return updateObj;
}

function newBeneficialOwner(owner, country) {
  if (owner > 5) { return {}; }

  const updateObj = allBeneficialOwnerFields(owner).reduce((o, key) => ({ ...o, [key]: '' }), {});

  updateObj[`beneficial_owners_${owner}_owner_address_country`] =
    country !== '' && country !== undefined ?
      country :
      'United States of America';

  return updateObj;
}

export {
  allBeneficialOwnerFields,
  publiclyTradedFields,
  regulatorFields,
  beneficialOwnerRadioFields,
  removeAndShiftBeneficialOwners,
  newBeneficialOwner,
};
