import notificationService from 'services/notifications';
import { createAction } from 'redux-actions';
import {
  getRequest, postRequest,
} from 'actions/utils';

import {
  VERIFICATION_CODE_INVALID,
  TRANSFER_VALID,
  AUTHENTICATION_VALID,
  PERMISSIONS_INVALID,
  VERIFICATION_CODE_SENT,
} from 'containers/secure_document_transfer/entry/pageStates.js';

import {
  SECURE_DOCUMENT_TRANSFER_SET_DOWNLOAD_DOCUMENTS,
  SECURE_DOCUMENT_TRANSFER_SET_AUTHENTICATION_CODE,
  SECURE_DOCUMENT_TRANSFER_SET_VERIFY_CODE,
  SECURE_DOCUMENT_TRANSFER_SET_PAGE_STATE,
  SECURE_DOCUMENT_TRANSFER_SET_TRANSFER_OPTIONS,
} from '../actionsConstants';

const setAuthenticationToken = createAction(SECURE_DOCUMENT_TRANSFER_SET_AUTHENTICATION_CODE);
const setTransferOptions = createAction(SECURE_DOCUMENT_TRANSFER_SET_TRANSFER_OPTIONS);
const setDownloadDocuments = createAction(SECURE_DOCUMENT_TRANSFER_SET_DOWNLOAD_DOCUMENTS);
const setVerifyCodeAction = createAction(SECURE_DOCUMENT_TRANSFER_SET_VERIFY_CODE);
const setPageState = createAction(SECURE_DOCUMENT_TRANSFER_SET_PAGE_STATE);

const buildRequestParams = (params, getState) => (
  {
    ...params,
    customInterceptors: {
      request: {
        success: (config) => {
          const state = getState();
          const icnBootstrap = state.icnReactBootstrap.icn_react_bootstrap;
          const csrf = icnBootstrap.csrf_token;

          config.headers['X-CSRF-Token'] = csrf;
          return config;
        },
        error: (error) => Promise.reject(error),
      },
      response: {
        success: (response) => response,
        error: (error) => Promise.reject(error.response),
      },
    },
  }
);

const getDocumentsCallback = (response) => (
  (dispatch) => {
    dispatch(setDownloadDocuments(response));
    dispatch(setPageState(AUTHENTICATION_VALID));
  }
);

const getDocumentsErrorCallback = (error) => (
  (dispatch) => {
    if (error.status === 404) {
      dispatch(setPageState(AUTHENTICATION_VALID));
      notificationService.notifyError('Could not retreive documents to download');
    } else if (error.status === 401) {
      dispatch(setPageState(TRANSFER_VALID));
      dispatch(setAuthenticationToken(''));
      notificationService.notifyError('There was a problem loading your documents');
    }
  }
);

const verifyEmailCodeErrorCallback = (error) => (
  (dispatch) => {
    if (error.status === 404) {
      dispatch(setPageState(PERMISSIONS_INVALID));
    } else {
      dispatch(setPageState(VERIFICATION_CODE_INVALID));
    }
  }
);


const verifyTokenCallback = (response) => (
  (dispatch) => {
    dispatch(setTransferOptions(response));
    dispatch(setPageState(TRANSFER_VALID));
  }
);

const verifyTokenErrorCallback = () => (
  (dispatch) => {
    dispatch(setPageState(PERMISSIONS_INVALID));
  }
);

function getDocuments(transferId) {
  return (dispatch, getState) => {
    const { authentication_token: authToken } = getState().secureDocumentTransfer;

    return dispatch(getRequest(buildRequestParams({
      url: `secure_document_transfer/transfers/${transferId}/documents/?auth_token=${authToken}`,
      onSuccess: getDocumentsCallback,
      onFailure: getDocumentsErrorCallback,
    }, getState)));
  };
}

const verifyEmailCodeCallback = (transferId, response) => (
  (dispatch) => {
    dispatch(setPageState(AUTHENTICATION_VALID));
    dispatch(setAuthenticationToken(response.authentication_token));
    dispatch(getDocuments(transferId));
  }
);

const sendVerificationCodeCallback = () => (
  (dispatch) => {
    dispatch(setPageState(VERIFICATION_CODE_SENT));
    dispatch(setVerifyCodeAction(''));
  }
);

const sendVerificationCodeErrorCallback = () => (
  notificationService.notifyError('Could not send new verification code')
);

function verifyEmailCode(transferId, code) {
  return (dispatch, getState) => dispatch(postRequest(buildRequestParams({
    url: `secure_document_transfer/transfers/${transferId}/validate_code`,
    data: {
      code,
    },
  }, getState))).then((response) => {
    dispatch(verifyEmailCodeCallback(transferId, response));
  }).catch((error) => {
    dispatch(verifyEmailCodeErrorCallback(error));
  });
}

function verifyToken(transferId) {
  return (dispatch, getState) => dispatch(getRequest(buildRequestParams({
    url: `secure_document_transfer/transfers/${transferId}`,
    onSuccess: verifyTokenCallback,
    onFailure: verifyTokenErrorCallback,
  }, getState)));
}

function setVerifyCode(verifyCode) {
  return (dispatch) => dispatch(setVerifyCodeAction(verifyCode));
}

function sendVerificationCode(transferId) {
  return (dispatch, getState) => dispatch(postRequest(buildRequestParams({
    url: `secure_document_transfer/transfers/${transferId}/send_code`,
    onSuccess: sendVerificationCodeCallback,
    onFailure: sendVerificationCodeErrorCallback,
  }, getState)));
}

export default {
  verifyEmailCode,
  verifyToken,
  sendVerificationCode,
  setVerifyCode,
};
