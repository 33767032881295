import { actionFactory } from 'services/actions';
import sendApiRequest from 'modules/passport/helpers/apiRequest';
import { ICN, POST } from 'modules/passport/constants';
import { BEGIN_API_REQUEST, END_API_REQUEST } from 'actions/actionsConstants';
import { PASSPORT_SET_CANDIDATES_DATA, PASSPORT_MENU_TOGGLE } from 'modules/passport/actions/constants';

const togglePassportMenu = actionFactory(PASSPORT_MENU_TOGGLE);

export const passFetchCandidates = () => {
  return (dispatch) => {
    dispatch({ type: BEGIN_API_REQUEST });

    return sendApiRequest(ICN, '/passport/candidates', POST)
      .then((data) => {
        dispatch({ type: PASSPORT_SET_CANDIDATES_DATA, payload: data });
        dispatch({ type: END_API_REQUEST });
      })
      .catch((error) => {
        console.log('Sorry, something went wrong.');
        dispatch({ type: END_API_REQUEST });
        throw error;
      });
  };
};

export default {
  togglePassportMenu,
};
