import React from 'react';
import PropTypes from 'prop-types';
import { Router } from 'react-router-dom';
import { setNotificationContext } from 'services/system_notifier';
import history from '../createHistory';

const setErrorTrackingUrl = () => {
  setNotificationContext({
    url: window.location.href,
  });
};

export default function RouterProvider({ children }) {
  setErrorTrackingUrl();

  return (
    <Router history={history} onChange={setErrorTrackingUrl}>
      {children}
    </Router>
  );
}

RouterProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
