import axios from 'axios';
import map from 'lodash/map';
import { createAction } from 'redux-actions';
import { beginApiRequest, endApiRequest } from 'actions/spinner_actions/spinnerActions';

import { getRequest, postRequest, deleteRequest } from '../utils';

import {
  SR_LOAD_ASSIGNMENTS_SUCCESS,
  SR_LOAD_ASSIGNMENTS_COUNT_SUCCESS,
  SR_LOAD_ASSIGNMENTS_REVIEWERS_SUCCESS,
  SR_LOAD_RIAS_FIRMS_SUCCESS,
  SR_SET_RESULTS_PER_PAGE,
  SR_REMOVE_ASSIGNMENT,
  SR_SET_CURRENT_PAGE,
  SR_SELECTED_RIAS_FIRMS,
  SR_LOAD_ASSIGNABLE_RIAS_FIRMS_SUCCESS,
  SR_SELECTED_CONTACTS,
  SR_DELETE_ASSIGNABLE_RIAS_FIRM_SUCCESS,
  SR_ADD_ASSIGNABLE_RIAS_FIRM_SUCCESS,
  SR_SET_SORTING,
  SR_SET_ACTIVE_MODAL,
  SR_SET_SELECTED_ROW,
  SR_SET_SELECTED_FUNDS,
  SR_LOAD_PRIVATE_ACCESS_FUNDS_SUCCESS,
  SR_ADD_ASSIGNABLE_FUND_SUCCESS,
  SR_DELETE_ASSIGNABLE_FUND_SUCCESS,
} from '../actionsConstants';

const srLoadAssignmentsSuccess = createAction(SR_LOAD_ASSIGNMENTS_SUCCESS);
const srLoadAssignmentsCountSuccess = createAction(SR_LOAD_ASSIGNMENTS_COUNT_SUCCESS);
const srLoadAssignmentsReviewersSuccess = createAction(SR_LOAD_ASSIGNMENTS_REVIEWERS_SUCCESS);
const srLoadRiasFirmsSuccess = createAction(SR_LOAD_RIAS_FIRMS_SUCCESS);
const srLoadAssignableRiasFirmsSuccess = createAction(SR_LOAD_ASSIGNABLE_RIAS_FIRMS_SUCCESS);
const srDeleteAssignableRiasFirmSuccess = createAction(SR_DELETE_ASSIGNABLE_RIAS_FIRM_SUCCESS);
const srAddAssignableRiasFirmSuccess = createAction(SR_ADD_ASSIGNABLE_RIAS_FIRM_SUCCESS);
const srLoadPAFSuccess = createAction(SR_LOAD_PRIVATE_ACCESS_FUNDS_SUCCESS);
const srAddAssignableFundSuccess = createAction(SR_ADD_ASSIGNABLE_FUND_SUCCESS);
const srDeleteAssignableFundSuccess = createAction(SR_DELETE_ASSIGNABLE_FUND_SUCCESS);

function srLoadAssignments() {
  return (dispatch, getState) => {
    const state = getState().subscriptionReviewer;
    const params = {
      advisory_firms_ids: map(state.selected_rias_firms, 'id'),
      funds_ids: map(state.selected_funds, 'id'),
      contacts_ids: map(state.selected_contacts, 'id').filter((id) => id !== 0),
      show_no_reviewers: state.show_no_reviewers,
      page: state.current_page,
      per_page: state.results_per_page,
      sorting_column: state.sorting_column,
      sorting_order: state.sorting_order,
    };
    const url = 'reviewer_responsibility_assignments';
    dispatch(beginApiRequest());
    return axios
      .get(url, { params, withCredentials: true })
      .then((response) => {
        dispatch(srLoadAssignmentsSuccess(response.data.reviewer_responsibility_assignments));
        dispatch(srLoadAssignmentsCountSuccess(response.data.reviewer_responsibility_assignments_count));
        dispatch(endApiRequest());
      })
      .catch(() => {
        dispatch(endApiRequest());
      });
  };
}

function srLoadAssignmentsReviewers() {
  const url = 'users/responsibility_assignments_reviewers';
  return (dispatch) => {
    dispatch(beginApiRequest());
    return axios
      .get(url, { withCredentials: true })
      .then((response) => {
        dispatch(srLoadAssignmentsReviewersSuccess(response.data));
        dispatch(endApiRequest());
      })
      .catch(() => {
        dispatch(endApiRequest());
      });
  };
}

function srLoadRiasFirms() {
  const url = 'firms/advisors_firms';
  return (dispatch) => {
    dispatch(beginApiRequest());
    return axios
      .get(url, {
        withCredentials: true,
        params: { include_assignable_firms: true },
      })
      .then((response) => {
        dispatch(srLoadRiasFirmsSuccess(response.data.filter((firm) => !firm.assignable)));
        dispatch(srLoadAssignableRiasFirmsSuccess(response.data.filter((firm) => firm.assignable)));
        dispatch(endApiRequest());
      })
      .catch(() => {
        dispatch(endApiRequest());
      });
  };
}

function srLoadPrivateAccessFunds() {
  return getRequest({
    url: 'private_access_funds/advisors_private_access_funds',
    onSuccess: srLoadPAFSuccess,
  });
}

function srUpdateResponsibility(payload) {
  const url = `reviewer_responsibility_assignments/${payload.id}`;
  const params = {
    id: payload.id,
    primary_contact_id: payload.primaryContactId,
    secondary_contact_id: payload.secondaryContactId,
  };
  return (dispatch) => {
    dispatch(beginApiRequest());
    return axios
      .patch(url, params, { withCredentials: true })
      .then(() => {
        dispatch(srLoadAssignments());
        dispatch(endApiRequest());
      })
      .catch(() => {
        dispatch(endApiRequest());
      });
  };
}

function onCreateResponsibilitySuccess(payload) {
  return (dispatch) => {
    dispatch(srLoadAssignments());
    if (payload.assignableType === 'Firm') {
      dispatch(srDeleteAssignableRiasFirmSuccess(payload.assignableId));
    } else {
      dispatch(srDeleteAssignableFundSuccess(payload.assignableId));
    }
  };
}

function srCreateResponsibility(payload) {
  return postRequest({
    url: 'reviewer_responsibility_assignments',
    method: 'post',
    data: {
      primary_contact_id: payload.primaryContactId,
      secondary_contact_id: payload.secondaryContactId,
      assignable_id: payload.assignableId,
      assignable_type: payload.assignableType,
    },
    onSuccess: () => onCreateResponsibilitySuccess(payload),
  });
}

function onRemoveResponsibility(responsibility) {
  return (dispatch) => {
    dispatch({
      type: SR_REMOVE_ASSIGNMENT,
    });
    dispatch(srLoadAssignments());
    if (responsibility.assignable_type === 'Firm') {
      dispatch(
        srAddAssignableRiasFirmSuccess({
          id: responsibility.assignable_id,
          name: responsibility.assignable_name,
        })
      );
    } else {
      dispatch(srAddAssignableFundSuccess(responsibility.assignable_id));
    }
  };
}

function srRemoveResponsibility(responsibility) {
  return deleteRequest({
    url: `reviewer_responsibility_assignments/${responsibility.id}`,
    onSuccess: () => onRemoveResponsibility(responsibility),
  });
}

function srSetResultsPerPage(payload) {
  return (dispatch) => {
    dispatch({
      type: SR_SET_RESULTS_PER_PAGE,
      payload,
    });
    return dispatch(srLoadAssignments());
  };
}

function srSetCurrentPage(payload) {
  return (dispatch) => {
    dispatch({
      type: SR_SET_CURRENT_PAGE,
      payload,
    });
    return dispatch(srLoadAssignments());
  };
}

function srSetSelectedFunds(payload) {
  return (dispatch) => {
    dispatch({
      type: SR_SET_SELECTED_FUNDS,
      payload,
    });
    return dispatch(srLoadAssignments());
  };
}

function srSetSelectedRiasFirms(payload) {
  return (dispatch) => {
    dispatch({
      type: SR_SELECTED_RIAS_FIRMS,
      payload,
    });
    return dispatch(srLoadAssignments());
  };
}

function srSetSelectedContacts(contacts, showNoReviewers) {
  return (dispatch) => {
    dispatch({
      type: SR_SELECTED_CONTACTS,
      payload: { contacts, showNoReviewers },
    });
    return dispatch(srLoadAssignments());
  };
}

function srSetSorting(sortingColumn, sortingOrder) {
  return (dispatch) => {
    dispatch({
      type: SR_SET_SORTING,
      payload: { sortingColumn, sortingOrder },
    });
    return dispatch(srLoadAssignments());
  };
}

function srSetActiveModal(payload) {
  return {
    type: SR_SET_ACTIVE_MODAL,
    payload,
  };
}

function srSetSelectedRow(payload) {
  return {
    type: SR_SET_SELECTED_ROW,
    payload,
  };
}

export default {
  srLoadAssignments,
  srLoadAssignmentsReviewers,
  srLoadRiasFirms,
  srLoadPrivateAccessFunds,
  srUpdateResponsibility,
  srCreateResponsibility,
  srRemoveResponsibility,
  srSetResultsPerPage,
  srSetCurrentPage,
  srSetSelectedFunds,
  srSetSelectedRiasFirms,
  srSetSelectedContacts,
  srSetSorting,
  srSetActiveModal,
  srSetSelectedRow,
};
