import { combineReducers } from 'redux';
import massMailerDocumentsShowReducer from './show/reducer';
import massMailerEmailsList from './show/emails/reducer';
import massMailerDocumentTypesReducer from './document_types/reducer';
import massMailerAllFundsReducer from './funds/all/reducer';
import massMailerListReducer from './list/reducer';
import massMailerMMFundsReducer from './funds/mass_mailer/reducer';
import massMailerEmailPartners from './partners/reducer';
import massMailerFirms from './firms/reducer';
import massMailerInvestmentStatus from './investment_labels/reducer';

export default combineReducers({
  show: massMailerDocumentsShowReducer,
  emails: massMailerEmailsList,
  list: massMailerListReducer,
  documentTypes: massMailerDocumentTypesReducer,
  allFunds: massMailerAllFundsReducer,
  funds: massMailerMMFundsReducer,
  partners: massMailerEmailPartners,
  firms: massMailerFirms,
  investmentStatus: massMailerInvestmentStatus,
});
