import axios from 'axios';
import spinnerActions from 'actions/spinner_actions/spinnerActions';
import { mapFundsData, mapRowData } from './helpers/operationReportsHelpers';
import {
  RP_AVAILABLE_FUNDS,
  RP_OPERATIONS_REPORTS,
  RP_SET_SELECTED_FUNDS,
  RP_OR_SET_MESSAGE_BANNER,
  RP_REPORT_TYPE,
} from '../actionsConstants';

const {
  beginApiRequest,
  endApiRequest,
} = spinnerActions;

const OPERATIONS_REPORTS_URL = 'operations_reports';
const AVAILABLE_FUNDS_URL = 'operations_reports/available_funds';

function rpSetAvailableFunds(payload) {
  return {
    type: RP_AVAILABLE_FUNDS,
    payload,
  };
}

function rpSetReportType(payload) {
  return {
    type: RP_REPORT_TYPE,
    payload,
  };
}

function rpSetOperationsReports(payload) {
  return {
    type: RP_OPERATIONS_REPORTS,
    payload,
  };
}

function rpSetSelectedFunds(payload) {
  return {
    type: RP_SET_SELECTED_FUNDS,
    payload,
  };
}

function rpSetMessageBanner(payload) {
  return {
    type: RP_OR_SET_MESSAGE_BANNER,
    payload,
  };
}

function rpClearMessageBanner() {
  return rpSetMessageBanner({ message: undefined, messageType: undefined });
}

function rpSetInfoBanner(message) {
  return rpSetMessageBanner({ message, messageType: 'info' });
}

function rpSetErrorBanner(error) {
  let message = 'Encountered an Error';
  if (error && error.data) {
    message = error.data.message;
  }

  return rpSetMessageBanner({ message, messageType: 'danger' });
}

function rpFetchAvailableFunds() {
  return (dispatch) => {
    dispatch(beginApiRequest());
    return axios.get(AVAILABLE_FUNDS_URL, { withCredentials: true }).then((response) => {
      dispatch(rpSetAvailableFunds(mapFundsData(response.data)));
    }).catch((error) => {
      dispatch(rpSetErrorBanner(error));
    }).finally(() => {
      dispatch(endApiRequest());
    });
  };
}

function rpFetchOperationsReports() {
  return (dispatch) => {
    dispatch(beginApiRequest());
    return axios.get(OPERATIONS_REPORTS_URL, { withCredentials: true }).then((response) => {
      dispatch(rpSetOperationsReports(mapRowData(response.data)));
    }).finally(() => {
      dispatch(endApiRequest());
    });
  };
}

function rpGenerateReport() {
  return (dispatch, getState) => {
    const { operationsReports } = getState();

    const payload = {
      report_type: operationsReports.operationsReportType,
      fund_ids: operationsReports.selectedFunds.map(f => f.id),
    };

    dispatch(beginApiRequest());
    return axios.post(OPERATIONS_REPORTS_URL, payload, { withCredentials: true }).then(() => {
      dispatch(
        rpSetInfoBanner(
          'Request submitted. The report will be available for download upon job completion. Please refresh this page to check status.'
        )
      );
    }).catch((error) => {
      dispatch(rpSetErrorBanner(error));
    }).finally(() => {
      dispatch(endApiRequest());
      dispatch(rpFetchOperationsReports());
    });
  };
}

export default {
  rpGenerateReport,
  rpFetchOperationsReports,
  rpFetchAvailableFunds,
  rpSetAvailableFunds,
  rpSetErrorBanner,
  rpSetInfoBanner,
  rpClearMessageBanner,
  rpSetMessageBanner,
  rpSetSelectedFunds,
  rpSetOperationsReports,
  rpSetReportType,
};
