import { actionFactory } from 'services/actions';
import { getRequest, postRequest, deleteRequest, patchRequest } from 'actions/utils';

import { getCurrentConfigId } from 'reducers/report_generator_reducers/selectors';

import axios from 'axios';
import filterActions from './filters';
import filesActions from './filesActions';
import schedulerNotificationRecipientActions from './schedulerNotificationRecipientActions';
import updateReportGeneratorConfig from './updateReportConfigAction';
import crudActions from './crudActions';
import dataActions from './dataActions';
import baseQueryModelsActions from './baseQueryModelsActions';
import * as workerActions from './workerActions';
import scheduleActions from './scheduleActions';
import customQueryActions from './customQueryActions';
import { rgaRemoveScheduledConfigSuccess } from '../report_schedule_requests_actions/reportScheduleRequestsActions';
import {
  RGA_LOAD_CONFIGS_SUCCESS,
  RGA_LOAD_CONFIGS_COUNT,
  RGA_SET_ACTIVE_MODAL,
  RGA_REMOVE_CONFIG,
  RGA_SET_RESULTS_PER_PAGE,
  RGA_SET_CURRENT_PAGE,
  RGA_SET_SORTING,
  RGA_SET_SELECTED_ROW,
  RGA_SET_CHECKED_ROWS,
  RGA_LOAD_CONFIG_SHARED_USERS,
  RGA_CONFIG_SHARED_USERS_COUNT,
  RG_APPLY_FILTERS
} from '../actionsConstants';
import sftpAccountActions from './sftpAccountActions';
import configFieldActions from './configFieldActions';
import snowflakeConfigActions from './snowflakeConfigActions';
import fileAttributeActions from './fileAttributeActions';
import snowflakeClientActions from './snowflakeClientActions';

const CONFIGS_URL = 'report_generator/configs';
const SHARED_USERS_URL = 'report_generator/shared_users';
const rgaLoadConfigsCount = actionFactory(RGA_LOAD_CONFIGS_COUNT);
const rgaLoadConfigsSuccess = actionFactory(RGA_LOAD_CONFIGS_SUCCESS);
const rgaSetActiveModal = actionFactory(RGA_SET_ACTIVE_MODAL);
const rgaSetSelectedRow = actionFactory(RGA_SET_SELECTED_ROW);
const rgaSetCheckedRows = actionFactory(RGA_SET_CHECKED_ROWS);
const rgaLoadConfigSharedUsers = actionFactory(RGA_LOAD_CONFIG_SHARED_USERS);
const rgaConfigSharedUsersCount = actionFactory(RGA_CONFIG_SHARED_USERS_COUNT);

function onReportGenConfigsSuccess(data) {
  return (dispatch) => {
    dispatch(rgaLoadConfigsSuccess(data.report_generator_configs));
    dispatch(rgaLoadConfigsCount(data.total));
  };
}

function rgaLoadConfigs() {
  return (dispatch, getState) => {
    const state = getState().reportGeneratorConfigs;
    const { nameFilter, baseQueryFilter, deliveryFilter } = state;
    return dispatch(
      getRequest({
        url: CONFIGS_URL,
        params: {
          page: state.current_page,
          per_page: state.results_per_page,
          sorting_column: state.sorting_column,
          sorting_order: state.sorting_order,
          name_filter: nameFilter,
          base_query_filter: baseQueryFilter,
          delivery_filter: deliveryFilter
        },
        onSuccess: onReportGenConfigsSuccess,
      }),
    );
  };
}

function rgaCreateConfig(payload) {
  return postRequest({
    url: CONFIGS_URL,
    data: {
      report_generator_config: payload,
    },
    onSuccess: rgaLoadConfigs,
  });
}

function rgaRemoveConfigSuccess() {
  return (dispatch) => {
    dispatch({
      type: RGA_REMOVE_CONFIG,
    });
    return dispatch(rgaLoadConfigs());
  };
}

function rgaRemoveConfig(config, admin = false) {
  return deleteRequest({
    url: `/${CONFIGS_URL}/${config.id}`,
    data: { admin },
    onSuccess: () =>
      admin ? rgaRemoveScheduledConfigSuccess() : rgaRemoveConfigSuccess(),
  });
}

function rgaSetResultsPerPage(payload) {
  return (dispatch) => {
    dispatch({
      type: RGA_SET_RESULTS_PER_PAGE,
      payload,
    });
    return dispatch(rgaLoadConfigs());
  };
}

function rgaSetCurrentPage(payload) {
  return (dispatch) => {
    dispatch({
      type: RGA_SET_CURRENT_PAGE,
      payload,
    });
    return dispatch(rgaLoadConfigs());
  };
}

function rgaSetSorting(sortingColumn, sortingOrder) {
  return (dispatch) => {
    dispatch({
      type: RGA_SET_SORTING,
      payload: { sortingColumn, sortingOrder },
    });
    return dispatch(rgaLoadConfigs());
  };
}

function rgaUpdateConfigSharedUsers(data) {
  return (dispatch) => {
    dispatch(rgaLoadConfigSharedUsers(data.shared_users));
    dispatch(rgaConfigSharedUsersCount(data.total));
  };
}

function reportGeneratorConfigSharedUsers(reportConfigId) {
  return (dispatch) =>
    dispatch(
      getRequest({
        url: `/${CONFIGS_URL}/shared_users`,
        params: { id: reportConfigId },
        onSuccess: rgaUpdateConfigSharedUsers,
      }),
    );
}

function reportGeneratorSharedUserDelete(sharedConfigId, reportConfigId) {
  return (dispatch) =>
    dispatch(
      deleteRequest({
        url: `/${SHARED_USERS_URL}/${sharedConfigId}`,
        onSuccess: () => reportGeneratorConfigSharedUsers(reportConfigId),
      }),
    );
}

function searchShareableUsers(searchTerm, reportConfigId) {
  return getRequest({
    url: `/${SHARED_USERS_URL}/search_shareable_users`,
    params: { search_term: searchTerm, config_id: reportConfigId },
    withCredentials: true,
    showSpinner: false,
  });
}

function shareConfigWithUsers(params) {
  return postRequest({
    url: SHARED_USERS_URL,
    params,
    withCredentials: true,
    onSuccess: () => reportGeneratorConfigSharedUsers(params.config_id),
  });
}

function cloneConfigData(configId = null) {
  return (dispatch, getState) => {
    let currentConfigId = configId;
    if (!currentConfigId) {
      currentConfigId = getCurrentConfigId(getState());
    }

    return dispatch(
      postRequest({
        url: `${CONFIGS_URL}/${currentConfigId}/clone_config`,
        onSuccess: rgaLoadConfigs,
        successMessage: { message: 'Config cloned successfully' },
        errorMessage: { title: 'Error', message: 'Failed to clone.' },
        showSpinner: true,
      }),
    );
  };
}

function downloadIndexFileSample(configId) {
  const url = `report_generator/downloads/${configId}/index_file_example`;

  return axios
    .get(url, { withCredentials: true, responseType: 'arraybuffer' })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const disposition = response.headers['content-disposition'];
      const support = document.createElement('a');
      support.href = url;
      support.download = disposition.match(/filename="(.*)"/)[1];
      support.click();
      support.remove();
    });
}

const applyReportGeneratorFilters =
  (
    nameFilter,
    baseQueryFilter,
    deliveryFilter,
  ) =>
  (dispatch) => {
    dispatch({
      type: RG_APPLY_FILTERS,
      payload: {
        nameFilter,
        baseQueryFilter,
        deliveryFilter,
      },
    });
    dispatch(rgaLoadConfigs());
  };

  function rgaUpdateReportDelivery(configId, enabled, disableReason = null) {
    const message = enabled
      ? 'Report delivery enabled successfully.'
      : 'Report delivery disabled successfully.';

    return patchRequest({
      url: `report_generator/scheduled_configs/${configId}`,
      data: {
        report_config_scheduler: {
          enabled,
          disable_reason: disableReason,
        },
      },
      onSuccess: rgaLoadConfigs,
      successMessage: { message },
      errorMessage: {
        title: 'Error',
        message: 'Failed to update report delivery',
      },
    });
  };
export default {
  rgaLoadConfigs,
  rgaCreateConfig,
  rgaRemoveConfig,
  rgaSetResultsPerPage,
  rgaSetCurrentPage,
  rgaSetSorting,
  rgaSetActiveModal,
  rgaSetSelectedRow,
  rgaSetCheckedRows,
  reportGeneratorConfigSharedUsers,
  reportGeneratorSharedUserDelete,
  searchShareableUsers,
  shareConfigWithUsers,
  cloneConfigData,
  downloadIndexFileSample,
  applyReportGeneratorFilters,
  rgaUpdateReportDelivery,
  ...filterActions,
  ...filesActions,
  ...schedulerNotificationRecipientActions,
  ...crudActions,
  ...baseQueryModelsActions,
  ...dataActions,
  ...workerActions,
  ...scheduleActions,
  ...updateReportGeneratorConfig,
  ...customQueryActions,
  ...sftpAccountActions,
  ...configFieldActions,
  ...snowflakeConfigActions,
  ...fileAttributeActions,
  ...snowflakeClientActions,
};
