import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import IcnGenericModal from 'components/shared/modals/IcnGenericModal/IcnGenericModal';
import { IcnButton } from '@icapitalnetwork/react-component-library';
import classNames from 'classnames';

import styles from './IcnSimpleChoiceModal.module.scss';

const cx = classNames.bind(styles);

export class IcnSimpleChoiceModal extends PureComponent {
  render() {
    return (
      <IcnGenericModal
        isOpen={this.props.isOpen}
        onRequestClose={this.props.onCancel}
      >
        <Fragment>
          <div className={cx(this.props.modalContainerStyleOverride, 'modal_container')}>
            <h3>{this.props.title}</h3>
            <div>
              { this.props.bodyText && <p>{this.props.bodyText}</p> }
              {this.props.children}
            </div>
            <div className="button_wrapper pull-right">
              { !this.props.hideCancelBtn && (
              <IcnButton
                text={this.props.cancelButtonText}
                callback={this.props.onCancel}
                styleType="tertiary"
                size="md"
              />
              ) }
              <div className={cx(styles.button_spacer)} />
              <IcnButton
                text={this.props.confirmButtonText}
                disabled={this.props.disableConfirm}
                callback={this.props.onConfirm}
                styleType="primary"
                size="md"
              />
            </div>
          </div>
        </Fragment>
      </IcnGenericModal>
    );
  }
}

IcnSimpleChoiceModal.propTypes = {
  cancelButtonText: PropTypes.string,
  confirmButtonText: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  bodyText: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  disableConfirm: PropTypes.bool,
  modalContainerStyleOverride: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  hideCancelBtn: PropTypes.bool,
};

IcnSimpleChoiceModal.defaultProps = {
  cancelButtonText: 'Cancel',
  confirmButtonText: 'YES',
  modalContainerStyleOverride: '',
  children: null,
  disableConfirm: false,
  bodyText: null,
  hideCancelBtn: false,
};

export default IcnSimpleChoiceModal;
