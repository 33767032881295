import { actionFactory } from 'services/actions';
import { postRequest, getRequest } from 'actions/utils';
import {
  SAVE_ACKNOWLEDGMENTS,
  GET_LAST_ACKNOWLEDGMENT,
} from 'actions/actionsConstants';

const rSaveAcknowledgments = actionFactory(SAVE_ACKNOWLEDGMENTS);
const rGetLastAcknowledgment = actionFactory(GET_LAST_ACKNOWLEDGMENT);

const BASE_URL = 'user_acknowledgements';

function saveUserAcknowledgments(data, showSpinner = true) {
  return postRequest({
    showSpinner,
    url: BASE_URL,
    data,
    onSuccess: (response) => rSaveAcknowledgments(response),
  });
}

function getLastUserAcknowledgment(type, showSpinner = true) {
  return getRequest({
    showSpinner,
    params: {
      acknowledgement_type: type,
    },
    url: `${BASE_URL}/last`,
    onSuccess: (response) => rGetLastAcknowledgment(response),
  });
}

export default {
  saveUserAcknowledgments,
  getLastUserAcknowledgment,
};
