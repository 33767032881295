import axios from 'axios';
import spinnerActions from 'actions/spinner_actions/spinnerActions';
import mapDocumentsData from './helpers/ppmWatermarkingHelpers';
import {
  RP_PW_DISABLE_GENERATE_BUTTON,
  RP_PW_SET_MESSAGE_BANNER,
  RP_SET_WATERMARKED_FILES,
} from '../actionsConstants';

const {
  beginApiRequest,
  endApiRequest,
} = spinnerActions;

const PPM_URL = 'private_placement_memorandum';

function rpSetWatermarkedFiles(documents) {
  return {
    type: RP_SET_WATERMARKED_FILES,
    payload: documents,
  };
}

function rpDisableGeneratePpmWatermarkingButton(payload) {
  return {
    type: RP_PW_DISABLE_GENERATE_BUTTON,
    payload,
  };
}

function rpSetPpmSelectedFunds(payload) {
  return {
    type: RP_PW_SET_MESSAGE_BANNER,
    payload,
  };
}

function rpClearMessageBanner() {
  return rpSetPpmSelectedFunds({ message: undefined, messageType: undefined });
}

function rpSetInfoBanner(message) {
  return rpSetPpmSelectedFunds({ message, messageType: 'info' });
}

function rpSetErrorBanner(error) {
  let message = 'Encountered an Error';
  if (error && error.data) {
    message = error.data.message;
  }
  return rpSetPpmSelectedFunds({ message, messageType: 'danger' });
}

function rpGetWatermarkedFiles() {
  return (dispatch) => {
    dispatch(beginApiRequest());
    return axios.get(PPM_URL, { withCredentials: true }).then((response) => {
      dispatch(rpSetWatermarkedFiles(mapDocumentsData(response.data)));
    }).catch((error) => {
      dispatch(rpDisableGeneratePpmWatermarkingButton(false));
      dispatch(rpSetErrorBanner(error));
    }).finally(() => {
      dispatch(endApiRequest());
    });
  };
}

function rpGeneratePPM(fileForm) {
  return (dispatch) => {
    const formData = new FormData();
    formData.append('csvFile', fileForm.csv_upload.files[0]);
    formData.append('pdfFile', fileForm.ppm_upload.files[0]);
    dispatch(rpDisableGeneratePpmWatermarkingButton(true));
    dispatch(beginApiRequest());

    return axios.post(PPM_URL, formData, { withCredentials: true }).then(() => {
      dispatch(
        rpSetInfoBanner(
          'Request submitted. Watermarked files will be available for download upon job completion. Please refresh this page to check status.'
        )
      );
    }).catch((error) => {
      dispatch(rpDisableGeneratePpmWatermarkingButton(false));
      dispatch(rpSetErrorBanner(error));
    }).finally(() => {
      dispatch(endApiRequest());
      dispatch(rpGetWatermarkedFiles());
    });
  };
}

function rpValidateForm(fileForm) {
  const valid = fileForm.ppm_upload.files.length === 1 && fileForm.csv_upload.files.length === 1;
  return rpDisableGeneratePpmWatermarkingButton(!valid);
}

export default {
  rpSetWatermarkedFiles,
  rpDisableGeneratePpmWatermarkingButton,
  rpSetPpmSelectedFunds,
  rpClearMessageBanner,
  rpGetWatermarkedFiles,
  rpGeneratePPM,
  rpSetInfoBanner,
  rpSetErrorBanner,
  rpValidateForm,
};
