import startCase from 'lodash/startCase';
import icnIdentity from 'services/icn_identity/icnIdentity';
import moment from 'moment';
import { GET_AGREEMENT_STATUS_LABELS, INVESTMENT_AGREEMENT_STATUS } from 'constants/index';

const canSendSubscription = (user) => !icnIdentity.isHomeOffice(user) && icnIdentity.canSendSubscription(user);

const sendText = (isTransferInvestment) =>
  isTransferInvestment ? GET_AGREEMENT_STATUS_LABELS().SEND_DOCUMENT : GET_AGREEMENT_STATUS_LABELS().SEND_SUBSCRIPTION;
const uploadText = (isTransferInvestment) =>
  isTransferInvestment
    ? GET_AGREEMENT_STATUS_LABELS().UPLOAD_TRANSFER_DOCUMENT
    : GET_AGREEMENT_STATUS_LABELS().UPLOAD_SUBSCRIPTION;

const fetchStatusFromRequiredDocument = (document, user, isTransferInvestment) => {
  switch (document.status) {
    case 'generating_agreement':
      return GET_AGREEMENT_STATUS_LABELS().GENERATING_AGREEMENT;
    case 'pending_esign':
      return GET_AGREEMENT_STATUS_LABELS().PENDING_SIGNATURE;
    case 'pending_upload':
      if (icnIdentity.isHomeOffice(user)) {
        return GET_AGREEMENT_STATUS_LABELS().PENDING_UPLOAD;
      }
      return uploadText(isTransferInvestment);

    case 'pending_completion':
      return GET_AGREEMENT_STATUS_LABELS().PENDING_COMPLETION;
    case 'uploaded':
    case 'signed':
    case 'reverted':
      return GET_AGREEMENT_STATUS_LABELS().SIGNED;
    default:
      if (canSendSubscription(user) || (isTransferInvestment && !icnIdentity.isHomeOffice(user))) {
        return sendText(isTransferInvestment);
      }

      return GET_AGREEMENT_STATUS_LABELS().NOT_SENT;
  }
};

const fetchStatusFromInvestment = (investment, user) => {
  switch (investment.status) {
    case INVESTMENT_AGREEMENT_STATUS.NOT_SENT:
      return canSendSubscription(user)
        ? GET_AGREEMENT_STATUS_LABELS().SEND_SUBSCRIPTION
        : GET_AGREEMENT_STATUS_LABELS().NOT_SENT;
    case INVESTMENT_AGREEMENT_STATUS.PENDING_SIGNATURE:
      return investment.sign_method === 'manual'
        ? GET_AGREEMENT_STATUS_LABELS().UPLOAD_SUBSCRIPTION
        : GET_AGREEMENT_STATUS_LABELS().PENDING_SIGNATURE;
    default:
      return startCase(investment.status);
  }
};

// eslint-disable-next-line max-statements
export const agreementText = (investment, user, isTransferInvestment = false) => {
  if (!investment) {
    return '';
  }

  if (investment.canceled) {
    return GET_AGREEMENT_STATUS_LABELS().CANCELED;
  }

  if (investment.status_override && !isTransferInvestment) {
    return GET_AGREEMENT_STATUS_LABELS().SIGNED;
  }

  if (isTransferInvestment && investment.transfer_document) {
    return fetchStatusFromRequiredDocument(investment.transfer_document, user, isTransferInvestment);
  }

  if (!isTransferInvestment && investment.subscription_document) {
    return fetchStatusFromRequiredDocument(investment.subscription_document, user, isTransferInvestment);
  }

  // We're not completely removing the status verification due to the fact that
  // we're not going to bust the cache for every investment. With that, not every investment
  // has a subscription/transfer document, but will have the status flag instead.
  return fetchStatusFromInvestment(investment, user);
};

export const hasAgreementDocument = (investment, user) => {
  if (investment.canceled) {
    return false;
  }

  let status;

  // We're not completely removing the status verification due to the fact that
  // we're not going to bust the cache for every investment. With that, not every investment
  // has a subscription document, but will have the status flag instead.
  if (investment.subscription_document) {
    status = fetchStatusFromRequiredDocument(investment.subscription_document, user);
  } else {
    status = fetchStatusFromInvestment(investment, user);
  }

  return [
    GET_AGREEMENT_STATUS_LABELS().PENDING_SIGNATURE,
    GET_AGREEMENT_STATUS_LABELS().SIGNED,
    GET_AGREEMENT_STATUS_LABELS().PENDING_COMPLETION,
    GET_AGREEMENT_STATUS_LABELS().PENDING_UPLOAD,
    GET_AGREEMENT_STATUS_LABELS().UPLOAD_SUBSCRIPTION,
  ].includes(status);
};

export const getSendSubDocDelayDuration = (investment, sendSubDocDelayMap) => {
  const riaFirmId = investment.ria?.firm_id || '';
  return moment.duration(sendSubDocDelayMap[riaFirmId], 'seconds');
};

export const getTimeToSendSubDoc = (investment) => {
  const end = moment(new Date(investment.delay_subscription_document_sending_until));
  const now = moment(new Date());
  return moment.duration(end.diff(now));
};

export default {
  agreementText,
  getTimeToSendSubDoc,
  getSendSubDocDelayDuration,
  hasAgreementDocument,
};
