import {
  DM_SET_FUND_DOCUMENT_EMAILS,
  DM_SET_FUND_DOCUMENT_EMAIL_PARTNERS,
} from 'actions/actionsConstants';

import update from 'immutability-helper';

const initialState = {
  fund_document_emails: [],
  total: 0,
  page: 1,
  per_page: 25,
  partners: [],
  total_per_wlp: {},
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case DM_SET_FUND_DOCUMENT_EMAILS:
      return update(state, {
        fund_document_emails: { $set: payload.fund_document_emails },
        total: { $set: payload.total },
        page: { $set: payload.page },
        per_page: { $set: payload.per_page },
        total_per_wlp: { $set: payload.total_per_wlp },
      });
    case DM_SET_FUND_DOCUMENT_EMAIL_PARTNERS:
      return update(state, {
        partners: { $set: payload.partners },
      });
    default:
      return state;
  }
};
