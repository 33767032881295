import axios from 'axios';
import spinnerActions from '../../spinner_actions/spinnerActions';
import { settingParams } from './treatment';

function executeHttpRequest(url, method, params = {}, data = {}) {
  const options = {
    data,
    params,
    method,
    withCredentials: true,
    url: /^(f|ht)tps?:\/\//i.test(url) ? url : `/data_syncs${url}`,
  };
  return axios(options);
}

export default function prepareHttpRequest(url, method, action, properties = {}) {
  const { params, data, options } = properties;
  const spinnerUsage = options.spinner !== undefined ? options.spinner : true;
  const paramsProcessed = settingParams(params);
  return (dispatch) => {
    if (spinnerUsage) {
      dispatch(spinnerActions.beginApiRequest());
    }
    return executeHttpRequest(url, method, paramsProcessed, data).then((response) => {
      dispatch(action({
        response: response.data,
        status: response.status,
        statusText: response.statusText,
      }));
      if (spinnerUsage) {
        dispatch(spinnerActions.endApiRequest());
      }
    }).catch(({ request, response, message }) => {
      dispatch(action({
        response: response ? response.data : {},
        status: response ? response.status : null,
        statusText: request || (response && response.status === 500) ? 'No response from the server.' : message,
      }));
      if (spinnerUsage) {
        dispatch(spinnerActions.endApiRequest());
      }
    });
  };
}
