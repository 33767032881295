/* eslint-disable max-lines */
import axios from 'axios';
import { postRequest, getRequest } from 'actions/utils';
import notificationService from 'services/notifications';
import { beginApiRequest, endApiRequest } from 'actions/spinner_actions/spinnerActions';
import { createAction } from 'redux-actions';
import sortBy from 'lodash/sortBy';
import unionBy from 'lodash/unionBy';
import oRequest, { oPost, oPatch, oDelete } from './helpers/requests';
import {
  OPERATIONS_SET_CAPITAL_EVENTS,
  OPERATIONS_UPDATE_UND_CAPITAL_EVENTS,
  OPERATIONS_DELETE_STANDALONE_UND_CAPITAL_EVENT,
  OPERATIONS_SET_CAPITAL_EVENT_AS_PAID,
  OPERATIONS_SET_OUTSTANDING_INVESTMENTS,
  OPERATIONS_SET_ALL_CAPITAL_EVENTS_EXPORTS,
  OPERATIONS_SET_ALL_OUTSTANDING_INVESTMENTS,
  OPERATIONS_SET_FILTERED_OUTSTANDING_INVESTMENTS,
  OPERATIONS_SET_OUTSTANDING_DISTRIBUTION_REPORTS,
  OPERATIONS_SET_VISIBLE_CAPITAL_EVENT_IDS,
  OPERATIONS_UPDATE_CAPITAL_EVENT_STATUS,
  OPERATIONS_SET_ACTIVE_STAT,
  OPERATIONS_SET_FILTERED_CAPITAL_EVENTS,
  OPERATIONS_SET_CAPITAL_EVENTS_FILTERS,
  OPERATIONS_SET_CAPITAL_EVENTS_ACTIVE_FILTERS,
  OPERATIONS_SET_CAPITAL_EVENTS_CALL_DATE_FILTER,
  OPERATIONS_SET_CAPITAL_EVENTS_IS_FETCHING,
  OPERATIONS_SET_GL_APPROVED,
  OPERATIONS_SET_LAST_EXPORTED_FILE,
  OPERATIONS_SET_HAS_NEW_EXPORTED_FILE,
} from '../../actionsConstants';

const odSetCapitalEvents = createAction(OPERATIONS_SET_CAPITAL_EVENTS);
const odUpdateUndCapitalEvents = createAction(OPERATIONS_UPDATE_UND_CAPITAL_EVENTS);
const odDeleteStandaloneUndCapitalEvent = createAction(OPERATIONS_DELETE_STANDALONE_UND_CAPITAL_EVENT);
const odSetCapitalEventAsPaid = createAction(OPERATIONS_SET_CAPITAL_EVENT_AS_PAID);
const odUpdateCapitalEventStatus = createAction(OPERATIONS_UPDATE_CAPITAL_EVENT_STATUS);
const oSetActiveStat = createAction(OPERATIONS_SET_ACTIVE_STAT);
const oSetFilteredCapitalEvents = createAction(OPERATIONS_SET_FILTERED_CAPITAL_EVENTS);
const odSetCapitalEventsFilters = createAction(OPERATIONS_SET_CAPITAL_EVENTS_FILTERS);
const odSetCapitalEventsActiveFilters = createAction(OPERATIONS_SET_CAPITAL_EVENTS_ACTIVE_FILTERS);
const setCallDateFilter = createAction(OPERATIONS_SET_CAPITAL_EVENTS_CALL_DATE_FILTER);
const odSetCapitalEventsIsFetching = createAction(OPERATIONS_SET_CAPITAL_EVENTS_IS_FETCHING);
const odSetGlApproved = createAction(OPERATIONS_SET_GL_APPROVED);
const odSetVisibleCapitalEventIds = createAction(OPERATIONS_SET_VISIBLE_CAPITAL_EVENT_IDS);
const odSetLastExportedFile = createAction(OPERATIONS_SET_LAST_EXPORTED_FILE);
const odSetHasNewExportedFile = createAction(OPERATIONS_SET_HAS_NEW_EXPORTED_FILE);
const setOutstandingDistributionReports = createAction(OPERATIONS_SET_OUTSTANDING_DISTRIBUTION_REPORTS);

function odFetchCapitalEventOutstandingInvestments(capitalEventId) {
  return (dispatch) => {
    dispatch(beginApiRequest());
    return axios.get('document_exports', {
      params: {
        capital_event_id: capitalEventId,
        document_types: 'OperationsOutstandingReportCsv',
      },
      withCredentials: true,
    }).then((response) => {
      dispatch({
        type: OPERATIONS_SET_OUTSTANDING_INVESTMENTS,
        payload: response.data,
      });
      dispatch(endApiRequest());
    }).catch(() => {
      dispatch(endApiRequest());
    });
  };
}

function odFetchAllCapitalEventsExports() {
  return (dispatch) => {
    dispatch(beginApiRequest());
    return axios.get('document_exports', {
      params: {
        document_types: 'OperationsAllCapitalEventsReportXlsx',
      },
      withCredentials: true,
    }).then((response) => {
      dispatch({
        type: OPERATIONS_SET_ALL_CAPITAL_EVENTS_EXPORTS,
        payload: response.data,
      });
      dispatch(endApiRequest());
    }).catch(() => {
      dispatch(endApiRequest());
    });
  };
}

function odFetchAllOutstandingInvestments() {
  return (dispatch) => {
    dispatch(beginApiRequest());
    return axios.get('document_exports', {
      params: {
        document_types: 'OperationsAllOutstandingReportCsv',
      },
      withCredentials: true,
    }).then((response) => {
      dispatch({
        type: OPERATIONS_SET_ALL_OUTSTANDING_INVESTMENTS,
        payload: response.data,
      });
      dispatch(endApiRequest());
    }).catch(() => {
      dispatch(endApiRequest());
    });
  };
}

function odFetchFilteredOutstandingInvestments() {
  return (dispatch) => {
    dispatch(beginApiRequest());
    return axios.get('document_exports', {
      params: {
        document_types: 'OperationsFilteredOutstandingReportCsv',
      },
      withCredentials: true,
    }).then((response) => {
      dispatch({
        type: OPERATIONS_SET_FILTERED_OUTSTANDING_INVESTMENTS,
        payload: response.data,
      });
      dispatch(endApiRequest());
    }).catch(() => {
      dispatch(endApiRequest());
    });
  };
}

function odFetcOutstandingDistributionReports() {
  return (dispatch) => dispatch(getRequest({
    url: 'document_exports',
    params: {
      document_types: 'OperationsOutstandingDistributionReportCsv',
    },
    onSuccess: setOutstandingDistributionReports,
  }));
}

const mergeFilterOptions = (filterOptionsA, filterOptionsB) => (
  sortBy(
    unionBy(filterOptionsA, filterOptionsB, 'id'),
    ['name']
  )
);

const addEmptyValueFilterOption = (
  {
    capitalEvents,
    filterOptions,
    filterProperty,
    emptyValueFilterOptionLabel,
  }
) => {
  const emptyValueFilterOption = { id: -1, name: emptyValueFilterOptionLabel };
  const capitalEventWithEmptyValueExists = capitalEvents.some((ce) => {
    const filterPropertyValue = ce[filterProperty];

    return (Array.isArray(filterPropertyValue) && filterPropertyValue.length === 0) || filterPropertyValue === null;
  });

  return capitalEventWithEmptyValueExists ? [emptyValueFilterOption].concat(filterOptions) : filterOptions;
};

const mergeCapitalEventsResponses = (
  capitalEventsResponse,
  standaloneUnderlyingCapitalEventsResponse,
  filterDefinitions
) => {
  // Merge all capital events and sort them by due date from the most recent back.
  const capitalEvents = capitalEventsResponse.capital_events
    .concat(standaloneUnderlyingCapitalEventsResponse.capital_events)
    .sort((x, y) => (x.due_date < y.due_date ? 1 : -1));
  const capitalEventsFilters = Object
    .entries(filterDefinitions)
    .reduce(
      (
        filterAccumulator,
        [
          filterKey,
          { filterOptionsProperty, capitalEventFilterProperty, emptyValueFilterOptionLabel },
        ]
      ) => (
        {
          ...filterAccumulator,
          [filterKey]: addEmptyValueFilterOption({
            capitalEvents,
            filterOptions: mergeFilterOptions(
              capitalEventsResponse[filterOptionsProperty],
              standaloneUnderlyingCapitalEventsResponse[filterOptionsProperty]
            ),
            filterProperty: capitalEventFilterProperty || filterOptionsProperty,
            emptyValueFilterOptionLabel,
          }),
        }
      ),
      {}
    );

  return { capitalEvents, capitalEventsFilters };
};

function odFetchCapitalEvents(callDateFilter, showSpinner = false) {
  return (dispatch) => {
    // Parallelize the call to the back-end
    const endpoint1 = 'operations/capital_events';
    const endpoint2 = 'operations/standalone_underlying_capital_events';
    const called_in_last_45_days = callDateFilter === 'last45Days';

    dispatch(odSetCapitalEventsIsFetching(true));

    return Promise.all([
      dispatch(oRequest(endpoint1, { called_in_last_45_days }, null, { showSpinner })),
      dispatch(oRequest(endpoint2, { called_in_last_45_days }, null, { showSpinner })),
    ]).then((data) => {
      const [response1, response2] = data;

      const { capitalEvents, capitalEventsFilters } = mergeCapitalEventsResponses(
        response1,
        response2,
        {
          fundAdministrators: {
            filterOptionsProperty: 'fund_administrators',
            emptyValueFilterOptionLabel: '(No Administrator)',
          },
          fundDistributors: {
            filterOptionsProperty: 'fund_distributors',
            emptyValueFilterOptionLabel: '(No Distribution Agent)',
          },
          fundFinanceRepresentatives: {
            filterOptionsProperty: 'fund_finance_representatives',
            capitalEventFilterProperty: 'fund_finance_representative',
            emptyValueFilterOptionLabel: '(No Representative)',
          },
          underlyingFundManagers: {
            filterOptionsProperty: 'underlying_fund_managers',
            emptyValueFilterOptionLabel: '(No Underlying Fund Manager)',
          },
        }
      );

      dispatch(odSetCapitalEvents(capitalEvents));
      dispatch(odSetCapitalEventsFilters(capitalEventsFilters));
    }).then(() => (dispatch(oSetFilteredCapitalEvents())));
  };
}

function odUpdateUnderlyingCapitalEvents(pafId, underlyingEventParams) {
  const endpoint = `private_access_funds/${pafId}/underlying_capital_events`;
  return (dispatch) => dispatch(oPost(endpoint, underlyingEventParams, odUpdateUndCapitalEvents))
    .then(() => (dispatch(oSetFilteredCapitalEvents())));
}

function odDeleteUnderlyingCapitalEvent(underlyingCapitalEventId, feederFundId) {
  const endpoint = `underlying_capital_events/${underlyingCapitalEventId}`;
  return (dispatch) => dispatch(
    oDelete(endpoint, { private_access_fund_id: feederFundId }, odDeleteStandaloneUndCapitalEvent)
  ).then(() => (dispatch(oSetFilteredCapitalEvents())));
}

function exportAllCapitalEvents(onSuccess, onFail) {
  return (dispatch) => dispatch(getRequest({ url: 'operations/capital_events/all_capital_events_export' }))
    .then(() => onSuccess())
    .catch(() => onFail());
}

function exportAllOutstandingInvestments(onSuccess, onFail) {
  return (dispatch) => dispatch(getRequest({ url: 'operations/capital_events/all_outstanding_investments' }))
    .then(() => onSuccess())
    .catch(() => onFail());
}

function exportFilteredOutstandingInvestments(capitalEventIds, onSuccess, onFail) {
  return (dispatch) => {
    dispatch(beginApiRequest());
    return axios.post('operations/capital_events/filtered_outstanding_investments', {
      capital_event_ids: capitalEventIds,
      withCredentials: true,
    }).then(() => {
      dispatch(endApiRequest());
      onSuccess();
    }).catch(() => {
      dispatch(endApiRequest());
      onFail();
    });
  };
}

function exportOutstandingDistributionReports(onSuccess, onFail) {
  return (dispatch) => dispatch(postRequest({ url: 'operations/capital_events/outstanding_distribution_report_export' }))
    .then(() => onSuccess())
    .catch(() => onFail());
}

function exportCapitalCallOutstandingInvestments(payload, onSuccess, onFail) {
  return (dispatch) => {
    dispatch(beginApiRequest());

    return axios.post('operations/capital_events/capital_call_outstanding_investments', {
      withCredentials: true,
      paf_name: payload.pafName,
      capital_event_id: payload.capitalEventId,
    }).then(() => {
      dispatch(oSetFilteredCapitalEvents());
      dispatch(endApiRequest());
      onSuccess();
    }).catch(() => {
      dispatch(endApiRequest());
      onFail();
    });
  };
}

function odSetCallDateFilter(callDateFilter) {
  return (dispatch) => dispatch(setCallDateFilter(callDateFilter));
}

function odMarkCapitalEventAsPaid(payload) {
  const data = {
    id: payload.underlyingCapitalEventId,
    paid_at: payload.paidDate,
    ...(payload.lockCapitalEvent !== undefined && { lock_capital_event: payload.lockCapitalEvent }),
  };
  const url = `underlying_capital_events/${payload.underlyingCapitalEventId}/mark_as_paid`;
  return (dispatch) => dispatch(oPatch(url, data, odSetCapitalEventAsPaid, null, { title: '', message: 'Capital event marked as Paid.' }))
    .then(() => (dispatch(oSetFilteredCapitalEvents())))
    .catch((error) => {
      notificationService.notifyError(
        'Error Marking Capital Event as paid',
        error.data?.message,
      );
    });
}

function odUpdateCapitalEventGlApproved(capitalEventId) {
  const url = `capital_events/${capitalEventId}/set_gl_as_accepted`;
  return (dispatch) => dispatch(oPatch(url, null, null, null, { title: '', message: 'Capital event General Ledgers have been Approved.' }))
    .then((response) => {
      dispatch(odSetGlApproved(response));
      dispatch(oSetFilteredCapitalEvents());
    })
    .catch((error) => {
      notificationService.notifyError(
        'An error occurred trying to update this event.',
        error.data?.message,
      );
    });
}

function odGetGLApprovedInformation(capitalEventId, onSuccess) {
  return (dispatch) => {
    dispatch(beginApiRequest());

    return axios.get(`capital_events/${capitalEventId}/gl_approved_information`, {
      withCredentials: true,
    }).then((response) => {
      dispatch(endApiRequest());
      onSuccess(response.data);
    }).catch(() => {
      dispatch(endApiRequest());
    });
  };
}

function odFetchCapitalEventsDashboardPermissions(onSuccess) {
  return (dispatch) => {
    dispatch(odSetCapitalEventsIsFetching(true));

    return axios.get(`operations/capital_events/dashboard_permissions`, {
      withCredentials: true,
    }).then((response) => {
      onSuccess(response.data);
    }).finally(() => {
      dispatch(odSetCapitalEventsIsFetching(false));
    });
  };
}

function logFilteredCapitalEventsExport() {
  return (dispatch) => {
    dispatch(beginApiRequest());
    return axios.post('operations/capital_events/log_filtered_capital_events_export', {
      withCredentials: true,
    }).then((response) => {
      dispatch(endApiRequest());
      return response;
    }).catch((response) => {
      dispatch(endApiRequest());
      notificationService.notifyError(
        '',
        'An error occurred trying to download filtered capital events',
      );
      return response;
    });
  };
}

function odSendInitialDefaultNotice(eventId, itemIds) {
  return postRequest({
    url: `capital_events/${eventId}/send_initial_default_positions`,
    data: { item_ids: itemIds },
    showSpinner: false,
  });
}

function odSendFinalDefaultNotice(eventId, itemIds) {
  return postRequest({
    url: `capital_events/${eventId}/send_final_default_positions`,
    data: { item_ids: itemIds },
    showSpinner: false,
  });
}

export default {
  odSetCapitalEvents,
  odUpdateUndCapitalEvents,
  odUpdateCapitalEventStatus,
  odDeleteStandaloneUndCapitalEvent,
  odFetchCapitalEvents,
  odUpdateUnderlyingCapitalEvents,
  odDeleteUnderlyingCapitalEvent,
  odFetchCapitalEventOutstandingInvestments,
  odFetchAllCapitalEventsExports,
  odFetchAllOutstandingInvestments,
  odFetchFilteredOutstandingInvestments,
  odFetcOutstandingDistributionReports,
  odMarkCapitalEventAsPaid,
  exportAllCapitalEvents,
  exportAllOutstandingInvestments,
  exportFilteredOutstandingInvestments,
  exportOutstandingDistributionReports,
  exportCapitalCallOutstandingInvestments,
  oSetActiveStat,
  odSetCapitalEventsActiveFilters,
  odSetCallDateFilter,
  odSetCapitalEventsIsFetching,
  odUpdateCapitalEventGlApproved,
  odGetGLApprovedInformation,
  odFetchCapitalEventsDashboardPermissions,
  logFilteredCapitalEventsExport,
  odSetVisibleCapitalEventIds,
  odSendInitialDefaultNotice,
  odSendFinalDefaultNotice,
  odSetLastExportedFile,
  odSetHasNewExportedFile,
};
