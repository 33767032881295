function investorProfilePath(id) {
  return `investor_profiles/${id}`;
}

function investorProfileCompletenessPath(id) {
  return `investor_profiles/${id}/is_completed_by_field`;
}

function investorProfileSignerTypes() {
  return 'investor_profiles/signer_types';
}

function icnGeoCountriesPath() {
  return 'icn_geo_countries';
}

function forContactCard() {
  return 'custodians';
}

function newIssuesProfilePath(id) {
  return `new_issues_profiles/${id}`;
}

function newInvestorProfilePath() {
  return 'investments/has_investor_profile_template';
}

export default {
  investorProfilePath,
  investorProfileCompletenessPath,
  investorProfileSignerTypes,
  icnGeoCountriesPath,
  forContactCard,
  newIssuesProfilePath,
  newInvestorProfilePath,
};
