import { createSelector } from 'reselect';

export const selectedResult = (state) => state.adminSearch.selected_result;
export const selectedTabId = (state) => state.adminSearch.selected_tab;
export const tabs = (state) => state.adminSearch.tabs;
export const selectedResultPosition = (state) => state.adminSearch.selected_result_position;
export const currentSearchResults = (state) => state.adminSearch.current_search_results;
export const previousSearchResults = (state) => state.adminSearch.previous_search_results;
export const searchKeyword = (state) => state.adminSearch.search_keyword;
export const currentPage = (state) => state.adminSearch.current_page;
export const resultsPerPage = (state) => state.adminSearch.results_per_page;
export const isPreviewResultsOpen = (state) => state.adminSearch.is_preview_results_open;
export const isSearchInputDisable = (state) => state.adminSearch.is_search_input_disable;
export const isSearchResultsLoaded = (state) => state.adminSearch.search_results_loaded;
export const relatedInfoData = (state) => state.adminSearch.related_info_data;
export const isEditContactCardEmailModalOpen =
  (state) => state.adminSearch.is_edit_contact_card_email_modal_open;
export const editContactCardErrors = (state) => state.adminSearch.edit_contact_card_email_errors;
export const relatedTablesPage = (state) => state.adminSearch.related_tables_pages;
export const contactCardToEdit = (state) => state.adminSearch.contact_card_to_edit;
export const editType = (state) => state.adminSearch.edit_type;
export const clonedUserPassword = (state) => state.adminSearch.cloned_user_password;
export const clonedUserEmail = (state) => state.adminSearch.cloned_user_email;
export const clonedUserName = (state) => `${state.adminSearch.cloned_user_first_name} ${state.adminSearch.cloned_user_last_name}`;
export const clonedUserTicketId = (state) => state.adminSearch.cloned_user_ticket_id;
export const previousPageUrl = (state) => state.adminSearch.previous_page_url;

export const relatedTableCurrentPage = (table) => createSelector(
  relatedTablesPage,
  (currentPages) => currentPages[table] || 1
);

export const relatedAccountsCurrentPage = relatedTableCurrentPage('accounts_current_page');
export const relatedInvestmentsCurrentPage = relatedTableCurrentPage('investments_current_page');
export const relatedTeamsCurrentPage = relatedTableCurrentPage('teams_current_page');

export const selectedTab = createSelector(
  selectedTabId,
  tabs,
  (selected, tabsList) => tabsList.find((tab) => tab.id === selected)
);

export const selectedTabLabel = createSelector(
  selectedTab,
  (tab) => tab?.label
);

export const resultDetailTitle = createSelector(
  selectedTabLabel,
  (tabLabel) => `Related Info For ${tabLabel?.slice(0, -1)}:`
);
