import {
  SET_CUSTODIANS,
} from 'actions/actionsConstants';

import update from 'immutability-helper';

const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CUSTODIANS:
      return update(state, {
        $set: action.payload,
      });

    default:
      return state;
  }
};
