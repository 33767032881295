import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { connect } from 'react-redux';
import actions from 'actions';
import { bindActionCreators } from 'redux';
import { withRouter, useHistory } from 'react-router-dom';
import { generatePath } from 'react-router';
import {
  string, bool, func, arrayOf, shape,
} from 'prop-types';
import _ from 'lodash';

import { routeCodes } from 'router';

import * as asSelectors from 'reducers/admin_search_reducers/selectors';
import { searchResult } from 'prop_types/admin_search/index';

import { searchTextFormatter } from 'containers/admin_search/services/textFormatter';

import IcnTooltipBase from 'components/shared/tooltip/IcnTooltipBase';
import ClearSearchIcon from 'images/clear-icon-sm-hover.svg';
import IcnSearchDefaultIcon from 'images/icon-search-default.svg';
import ASPreviewResults from 'containers/admin_search/as_preview_results/ASPreviewResults';
import classnames from 'classnames';
import styles from './ASNavbar.module.scss';

const placeholder = 'Search by Contact, Account, Investment, Fund, Firm or Referral Code and more…';

export const ASNavbar = ({
  previousSearchResults,
  isPreviewResultsOpen,
  isSearchInputDisable,
  isSearchResultsLoaded,
  searchKeyword,
  fetchFullSearchResults,
  fetchSearchResults,
  setSearchResultsLoaded,
  setCurrentResults,
  setSearchKeyword,
  setIsSearchInputDisable,
  setSelectedResult,
  setSelectedResultPosition,
  setCurrentPage,
  setPreviousPageUrl,
}) => {
  const [searchText, setSearchText] = useState(searchKeyword);
  const inputRef = useRef();

  const { push } = useHistory();

  useEffect(() => {
    if (!isSearchInputDisable) {
      inputRef.current.focus();
    }
  }, [isSearchInputDisable]);

  useEffect(() => (
    document.getElementById('admin_search_navbar')?.classList.add(styles.container_height)
  ), []);

  useEffect(() => {
    setSearchResultsLoaded(false);
    return document.getElementById('admin_search_navbar_input')?.focus();
  }, [setSearchResultsLoaded]);

  const getPageName = window.location.pathname.split('/').slice(3)[0];

  const searchDebounce = () => {
    if (searchText.trim() !== '' && searchText.trim().length > 2 && searchText !== searchKeyword) {
      setIsSearchInputDisable(true);
      setSelectedResult({});
      setSelectedResultPosition(null);
      setCurrentPage(1);
      const previousSearchKeywords = _.keys(previousSearchResults);
      const includesSearchResults = _.includes(
        previousSearchKeywords,
        searchTextFormatter(searchText)
      );
      const sameSearchWord = searchTextFormatter(searchText) === searchKeyword;

      if ((getPageName !== 'admin_search') && (includesSearchResults || sameSearchWord)) {
        if (!sameSearchWord) {
          setCurrentResults(previousSearchResults[searchTextFormatter(searchText)]);
          setSearchKeyword(searchTextFormatter(searchText));
        }
        setSearchResultsLoaded(true);
        setIsSearchInputDisable(false);
      } else if (getPageName === 'admin_search') {
        setSearchKeyword(searchTextFormatter(searchText));
        fetchFullSearchResults();
      } else {
        fetchSearchResults(searchText);
      }
    }
  };

  const delayedQuery = useCallback(_.debounce(searchDebounce, 500), [searchText]);

  useEffect(() => {
    delayedQuery();

    // Cancel the debounce on useEffect cleanup.
    return delayedQuery.cancel;
  }, [searchText, delayedQuery, setSearchResultsLoaded]);

  const onSearchChange = (event) => {
    const text = event.target.value;
    setSearchText(text);

    if (text.trim().length < 3 && isSearchResultsLoaded) {
      setSearchResultsLoaded(false);
    }
  };

  const clearSearchBar = () => {
    setSearchText('');
    setSearchKeyword('');
    setSearchResultsLoaded(false);
  };

  const onKeyPressed = (e) => {
    if (e.keyCode === 13) {
      if (getPageName !== 'admin_search') {
        setPreviousPageUrl(window.location.pathname);
      }
      const adminSearchURL = generatePath(routeCodes.ADMIN_SEARCH, { searchText });
      push(adminSearchURL);
    }
  };

  return (
    <div className={styles.navbar_container} id="admin_search_navbar">
      <img
        alt="searchIcon"
        name="searchIcon"
        src={IcnSearchDefaultIcon}
        className={classnames(styles.search_icon, styles.navbar_item, styles.icon)}
      />
      <div className={styles.search_input_container}>
        <input
          id="admin_search_navbar_input"
          ref={inputRef}
          type="text"
          name="adminSearchInput"
          placeholder={placeholder}
          value={searchText}
          onChange={(e) => onSearchChange(e)}
          className={classnames(styles.search_input, styles.navbar_item)}
          disabled={isSearchInputDisable}
          onKeyDown={onKeyPressed}
        />
        {searchText.trim() !== '' && (
          <img
            alt="clearSearchIcon"
            src={ClearSearchIcon}
            onClick={clearSearchBar}
            className={styles.clear_search_icon}
          />
        )}
      </div>
      <div className={styles.tooltip_container}>
        <IcnTooltipBase
          tooltipType="textside"
          placement="bottom"
          color="#399EAD"
          iconName="fa-question-circle"
        >
          <div className={styles.search_tooltip_box}>
            <span className={styles.tooltip_title}>
              Did you know?
            </span>
            <br />
            <span className={styles.tooltip_content}>
              You can also search by Custodian ID, Brokerage Firm, or Administrator
            </span>
          </div>
        </IcnTooltipBase>
      </div>
      {isSearchResultsLoaded && isPreviewResultsOpen && (
        <div className={styles.preview_results_container}>
          <ASPreviewResults />
        </div>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    previousSearchResults: asSelectors.previousSearchResults(state),
    isPreviewResultsOpen: asSelectors.isPreviewResultsOpen(state),
    isSearchInputDisable: asSelectors.isSearchInputDisable(state),
    isSearchResultsLoaded: asSelectors.isSearchResultsLoaded(state),
    searchKeyword: asSelectors.searchKeyword(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchFullSearchResults: actions.admsFetchFullSearchResults,
    fetchSearchResults: actions.admsFetchAdminSearchResults,
    setSearchResultsLoaded: actions.admsSetSearchResultsLoaded,
    setCurrentResults: actions.admsSetCurrentResults,
    setSearchKeyword: actions.admsSetSearchKeyword,
    setIsSearchInputDisable: actions.admsSetIsSearchInputDisable,
    setSelectedResult: actions.admsOnSelectedResultChange,
    setSelectedResultPosition: actions.admsSetSelectedResultPosition,
    setCurrentPage: actions.admsSetCurrentPage,
    setPreviousPageUrl: actions.admsSetPreviousPageUrl,
  }, dispatch);
}

ASNavbar.defaultProps = {
  previousSearchResults: null,
  isPreviewResultsOpen: null,
  isSearchInputDisable: null,
  isSearchResultsLoaded: null,
  searchKeyword: null,
};

ASNavbar.propTypes = {
  previousSearchResults: shape({
    [string]: arrayOf(searchResult),
  }),
  isPreviewResultsOpen: bool,
  isSearchInputDisable: bool,
  isSearchResultsLoaded: bool,
  searchKeyword: string,
  fetchSearchResults: func.isRequired,
  setSearchResultsLoaded: func.isRequired,
  setCurrentResults: func.isRequired,
  setSearchKeyword: func.isRequired,
  setIsSearchInputDisable: func.isRequired,
  setSelectedResult: func.isRequired,
  setSelectedResultPosition: func.isRequired,
  setCurrentPage: func.isRequired,
  fetchFullSearchResults: func.isRequired,
  setPreviousPageUrl: func.isRequired,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ASNavbar));
