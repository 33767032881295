import { CUSTOM_QUERY_TYPE } from './constants';

export const humanize = (str) =>
  str
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

export const formatDataSet = (dataSets, type) =>
  dataSets.map(({ display_name, ...rest }) => ({
    ...rest,
    type,
    label:
      display_name === 'Report Generator Views'
        ? `${display_name} (Retiring 2024)`
        : display_name,
    key: `${rest.id}-${type}`,
    group: type === CUSTOM_QUERY_TYPE ? 'Custom Data Sets' : 'Normal Data Sets',
  }));
