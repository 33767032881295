import update from 'immutability-helper';
import {
  I_SET_USERS,
  I_SET_IMPERSONATION_LOADING,
  I_SET_IMPERSONATION_USER,
  I_SET_DELEGATE_USERS,
} from '../actions/ImpersonationConstants';

export const initialState = {
  users: null,
  delegateUsers: null,
  loading: false,
  isImpersonating: false,
};

export function setImpersonationLoading(state, loading) {
  return update(state, { loading: { $set: loading } });
}

export function setImpersonationUser(state, isImpersonating) {
  return update(state, { isImpersonating: { $set: isImpersonating } });
}

export default (state = initialState, action) => {
  switch (action.type) {
    case I_SET_USERS: {
      return update(state, {
        users: {
          $set: action.payload.users,
        },
        loading: {
          $set: false,
        },
      });
    }
    case I_SET_DELEGATE_USERS: {
      return update(state, {
        delegateUsers: {
          $set: action.payload.delegators,
        },
        loading: {
          $set: false,
        },
      });
    }
    case I_SET_IMPERSONATION_LOADING:
      return setImpersonationLoading(state, action.payload);
    case I_SET_IMPERSONATION_USER:
      return setImpersonationUser(state, action.payload);
    default: {
      return state;
    }
  }
};
