import { setEmptyValues } from 'services/investor_profile';

const sideEffects = (updateObj, profile) => {
  let modifiedObj = {};

  if (updateObj.investing_in_retirement_plan) {
    const fields = [
      'plan_investor_assets_value',
      'plan_investor_accounting_arrangement_code_1974',
      'declare_as_assets_of_plan_investor',
    ];

    modifiedObj = { ...modifiedObj, ...setEmptyValues(fields) };
  }

  if (updateObj.investing_in_retirement_plan === '0') {
    modifiedObj.plan_investor_assets_value = '100';
  }

  if (updateObj.section_a_individual_type === 'IRA') {
    modifiedObj.investing_in_retirement_plan = '0';
  }

  return { ...updateObj, ...modifiedObj };
};

export default sideEffects;
