import { get, isEmpty } from 'lodash';
import { createSelector } from 'reselect';
import icnIdentity from 'services/icn_identity/icnIdentity';

export const getIcnReactBootstrap = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap', '');
export const isPafAdmin = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.user.isPafAdmin', false);
export const isAdminOrIr = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.user.isAdminOrIr', false);
export const isCsa = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.user.isCsa', false);
export const isBranchManager = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.user.isBranchManager', false);
export const isGpAdmin = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.user.isGpAdmin', false);
export const isInterestedParty = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.user.isInterestedParty', false);
export const getWhiteLabelPartner = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.white_label_partner', {});
export const isWhiteLabelPartner = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.white_label_partner.id', 0) !== 0;
export const isExternalApprover = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.user.isExternalApprover', false);
export const getPrivacyPolicy = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.white_label_partner.privacy_policy', {});
export const hasPrivacyPolicy = (state) => !isEmpty(getPrivacyPolicy(state));
export const getTermsOfUse = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.white_label_partner.terms_of_use', {});
export const getDisclosures = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.white_label_partner.disclaimer', {});
export const getMainAppUrl = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.main_app_url', '');
export const canHaveInvestments = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.user.canHaveInvestments');
export const canAccessReportGenerator = (state) => {
  return (
    get(state.icnReactBootstrap, 'icn_react_bootstrap.user.canManageReportGenerator', false) ||
    get(state.icnReactBootstrap, 'icn_react_bootstrap.user.canRgAdminApproveAndSchedule', false)
  )
};
export const canManageRedemptions = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.user.canManageRedemptions', false);
export const canCreateCapitalEvents = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.user.canCreateCapitalEvents', false);
export const canManageGeneralLedger = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.user.canManageGeneralLedger', false);
export const canManageReporting = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.user.canManageReporting', false);
export const canManageFunds = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.user.canManageFunds', false);
export const canManagePcaps = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.user.canManagePcaps', false);
export const canImportCapitalEvents = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.user.canImportCapitalEvents', false);
export const canManageNavs = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.user.canManageNavs', false);
export const canGenerateWatermarkedPpm = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.user.canGenerateWatermarkedPpm', false);
export const canRunOperationsReports = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.user.canRunOperationsReports', false);
export const canManageFundingStatus = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.user.canManageFundingStatus', false);
export const canSeeOpsDashboard = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.user.canSeeOpsDashboard', false);
export const canSeeDocumentCenter = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.user.canSeeDocumentCenter', false);
export const canReconciliationFirstApprover = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.user.canReconciliationFirstApprover', false);
export const canReconciliationFinalApprover = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.user.canReconciliationFinalApprover', false);
export const canAccessDataDashboard = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.user.canAccessDataDashboard', false);
export const canDownloadDataSyncReports = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.user.canDownloadDataSyncReports', false);
export const canInitiateDataSync = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.user.canInitiateDataSync', false);
export const canViewUbsApiLogs = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.user.canViewUbsApiLogs', false);
export const canViewFees = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.user.canViewFees', false);
export const canViewSupplementalForm = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.user.canViewSupplementalForm', false);
export const canEditSupplementalForm = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.user.canEditSupplementalForm', false);
export const canSaveIncompleteSupplementalForm = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.user.canSaveIncompleteSuppForms', false);
export const canEditSupplementalFormForAddons = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.user.canEditSupplementalFormForAddons', false);
export const getUser = (state) => get(getIcnReactBootstrap(state), 'user');
export const whiteLabelPartner = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.white_label_partner', false);
export const getEtlUrl = (state) => get(getIcnReactBootstrap(state), 'etl_url');
export const isImposter = (state) => get(getUser(state), 'is_imposter', false);
export const canAccessLevenshteinMatcher = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.user.canAccessLevenshteinMatcher', false);
export const isFundDocWatermarkPositionEnabled = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.white_label_partner.doc_center_allow_position_date_watermark', false);
export const isFundDocWatermarkingEnabled = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.white_label_partner.enable_fund_doc_watermarking', false);
export const fundDocsBulkDownloadLimit = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.white_label_partner.fund_docs_bulk_download_limit', 7);
export const viewOperationFiles = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.user.canViewOperationsDocuments', false);
export const viewDocCenterMergeAbility = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.user.canViewDocCenterMergeFiles', false);
export const fundDocsPreviewLimit = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.white_label_partner.fund_docs_preview_limit', 5);
export const hasIcnReactBootstrap = (state) => !isEmpty(getIcnReactBootstrap(state));
export const showAccountNumber = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.white_label_partner.show_account_number', false);
export const showInvestorsFilter = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.white_label_partner.doc_center_show_investors_filter', false);
export const showAdvisorColumn = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.white_label_partner.show_doc_center_advisor_column', false);
export const showDisplayNameColumn = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.white_label_partner.doc_center_see_display_name', false);
export const wlpInvestmentStatusName = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.white_label_partner.name', null);
export const disableEditingSubReviewNotes = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.white_label_partner.disable_editing_sub_review_notes', false);
export const canTeamManagementViewTeams = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.user.canTeamManagementViewTeams', false);
export const canTeamManagementViewAccounts = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.user.canTeamManagementViewAccounts', false);
export const canTeamManagementEditAccounts = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.user.canTeamManagementEditAccounts', false);
export const investmentDashboardAnonymizedFields = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.white_label_partner.investment_dashboard_anonymized_fields', false);
export const wlpExtendedEncryption = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.white_label_partner.extended_encryption', false);
export const canTeamManagementCreateDeleteTeams = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.user.canTeamManagementCreateDeleteTeams', false);
export const canTeamManagementEditTeams = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.user.canTeamManagementEditTeams', false);
export const canTeamManagementAdmin = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.user.canTeamManagementAdmin', false);
export const showTeamManagementBranchCodeFilter = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.user.company.show_team_management_branch_code_filter', false);
export const canTeamManagementCreateManageBranches = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.user.canTeamManagementCreateManageBranches', false);
export const showFundGroupColumn = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.white_label_partner.doc_center_show_fund_group', false);
export const canAdminSearchEditCapabilities = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.user.canAdminSearchEditCapabilities', false);
export const canCloneUsers = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.user.canCloneUsers', false);
export const canViewFormTemplate = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.user.canViewFormTemplate', false);
export const canEditFormTemplate = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.user.canEditFormTemplate', false);
export const canImportFormTemplate = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.user.canImportFormTemplate', false);
export const canPublishMajorVersionFormTemplate = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.user.canPublishMajorVersionFormTemplate', false);
export const canPublishMinorVersionFormTemplate = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.user.canPublishMinorVersionFormTemplate', false);
export const canCopyTemplateSchema = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.user.canAllowCopyFormTemplate', false);
export const getCanPublishIcapitalInsights = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.user.canPublishIcapitalInsights', false);
export const getCanAddIcapitalInsights = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.user.canAddIcapitalInsights', false);
export const canShowFlashReports = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.white_label_partner.show_flash_reports', false);
export const canTeamManagementInvite = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.user.canTeamManagementInvite', false);
export const showInsightsContentOnThankYouPage = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.white_label_partner.show_insights_content_on_thank_you_page', true);
export const canUploadPcapReport = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.user.canUploadPcapReport', false);
export const canUploadNavReport = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.user.canUploadNavReport', false);
export const canUploadHfReport = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.user.canUploadHfReport', false);
export const canFeeExportConfiguration = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.user.canFeeExportConfiguration', false);
export const hideSalesReportingExports = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.white_label_partner.hide_sales_reporting_exports', false);
export const hideThirdPartyVendors = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.white_label_partner.hide_third_party_vendors', false);
export const canSalesInvestmentDetailReport = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.user.canSalesInvestmentDetailReport', false);
export const canSalesUserDetailReport = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.user.canSalesUserDetailReport', false);
export const canSalesReportingAutoReminders = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.user.canSalesReportingAutoReminders', false);
export const canSalesReportingDeepLinkFunds = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.user.canSalesReportingDeepLinkFunds', false);
export const canSalesReportingInviteHistory = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.user.canSalesReportingInviteHistory', false);
export const isSiteNavMfeEnabled = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.white_label_partner.enable_sitenav_mfe', false);
export const canUploadHFReport = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.user.canUploadHfReport', false);
export const canSkipPlacementFeeRestrictions = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.user.canSkipPlacementFeeRestrictions', false);
export const canViewNomineeReview = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.user.canViewNomineeReview', false);
export const canManageNomineeReview = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.user.canManageNomineeReview', false);
export const canApproveNominee = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.user.canApproveNominee', false);
export const canSubmitNominee = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.user.canSubmitNominee', false);
export const userFundFollows = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.user.fund_follows', []);
export const canAccessBnyApi = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.user.canAccessBnyApi', false);
export const hasFeatureFlag = (state, featureFlag) => get(state.icnReactBootstrap, `icn_react_bootstrap.feature_flags.${featureFlag}`, false);
export const canCreateEditTradeTicket = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.user.canCreateEditTradeTicket', false);
export const canTeFirstApprove = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.user.canTradeExecutionFirstApprove', false);
export const canTeFinalApprove = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.user.canTradeExecutionFinalApprove', false);
export const canTeConfirmTradeTicket = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.user.canTradeExecutionConfirmTicket', false);
export const canBulkApproveInvestments = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.user.canBulkApproveInvestments', false);
export const canBulkToggleFunded = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.user.canBulkToggleFunded', false);
export const getUsePpbFundProfiles = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.white_label_partner.use_ppb_fund_profiles', false);

export const canAllowSalesManagementInvite = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.user.canAllowSalesManagementInvite', false);
export const canSalesReportingRerunMetrics = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.user.canSalesReportingRerunMetrics', false);
export const canViewPermissionsDictionary = (state) => get(state.icnReactBootstrap, 'icn_react_bootstrap.user.canViewPermissionsDictionary', false);

export const isWhiteLabel = createSelector(
  getIcnReactBootstrap,
  (icnReactBootstrap) => icnIdentity.isWhiteLabel(icnReactBootstrap)
);

export const isMainSitePafAdmin = createSelector(
  isWhiteLabel,
  getUser,
  (isWlp, user) => !isWlp && user && user.isPafAdmin
);

export const showFlashReports = createSelector(
  isWhiteLabel,
  canShowFlashReports,
  (isWlp, flashReports) => isWlp && flashReports
);
