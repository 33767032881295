/* eslint-disable react/function-component-definition */
/* eslint-disable import/prefer-default-export */
import React, { forwardRef } from 'react';
import { bool, element } from 'prop-types';

import { Paper } from '@icapitalnetwork/supernova-core';
import { useTheme } from '@mui/material';

export const BaseDisclaimer = forwardRef(({ children, dragToBottom, noMinHeight, noTopSpace }, ref) => {
  const theme = useTheme();
  return (
    <Paper
      ref={ref}
      square
      sx={{
        position: 'relative',
        bottom: dragToBottom ? theme.spacing(-12) : null,
        marginTop: dragToBottom || noTopSpace ? null : theme.spacing(12),
        padding: theme.spacing(4, 6, 2, 6),
        minHeight: noMinHeight ? null : theme.spacing(12),
        borderTopColor: theme.palette.surface.lines,
        borderTopStyle: 'solid',
        borderTopWidth: '1px',
      }}
      elevation={0}
    >
      {children}
    </Paper>
  );
});

BaseDisclaimer.defaultProps = {
  children: null,
  dragToBottom: false,
  noMinHeight: false,
};

BaseDisclaimer.propTypes = {
  children: element,
  dragToBottom: bool,
  noMinHeight: bool,
};
