import i18n from 'i18n';
const INVESTMENTS_TAB = Object.freeze({
    label: i18n.t('common:investments'),
    id: 'investments',
});
const ACCEPTED_TAB = Object.freeze({
    label: i18n.t('common:accepted'),
    id: 'accepted',
});
export const TABS = Object.freeze([INVESTMENTS_TAB, ACCEPTED_TAB]);
