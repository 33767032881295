import reducers from './reducers';
import initialState from './initialState';

const reducersList = [
  reducers.whiteLabelPartnerReducer,
  reducers.providerReducer,
  reducers.fileReducer,
  reducers.dataSyncReducer,
  reducers.dataSyncFileReducer,
  reducers.dataSyncFileReportReducer,
  reducers.dataSyncSchemaReducer,
  reducers.transformationReducer,
];

const reducer = (state = initialState, action) => reducersList.reduce((stateAccumulator, exec) => {
  return exec(stateAccumulator, action);
}, state);

export default reducer;
