import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
} from '@icapitalnetwork/supernova-core';
import { shape, bool, func } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from 'actions';
import RiaPopup from './sections/RiaPopup';
import SfoUserIdentificationPageOne from './sections/SfoUserIdentificationPageOne';
import { FINANCIAL_PROFESSIONAL } from './constants';

function IdentifyUserPopup({
  user,
  userIsRia,
  userIsSfo,
  saveUserAcknowledgments,
  getLastUserAcknowledgment,
  updateUserAttributes,
}) {
  const [lastAcknowledgment, setLastAcknowledgment] = useState({});

  useEffect(() => {
    getLastUserAcknowledgment(FINANCIAL_PROFESSIONAL).then((res) => {
      setLastAcknowledgment(res);
    });
  }, []);

  return (
    <Dialog open>
      <DialogTitle>Tell us more about yourself</DialogTitle>

      {userIsRia && (
        <RiaPopup
          user={user}
          submit={saveUserAcknowledgments}
          updateUser={updateUserAttributes}
          lastAcknowledgment={lastAcknowledgment}
        />
      )}

      {userIsSfo && (
        <SfoUserIdentificationPageOne
          user={user}
          submit={saveUserAcknowledgments}
          updateUser={updateUserAttributes}
        />
      )}
    </Dialog>
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    saveUserAcknowledgments: actions.saveUserAcknowledgments,
    getLastUserAcknowledgment: actions.getLastUserAcknowledgment,
    updateUserAttributes: actions.updateUserAttributes,
  }, dispatch);
}

IdentifyUserPopup.propTypes = {
  user: shape({}).isRequired,
  userIsRia: bool,
  userIsSfo: bool,
  saveUserAcknowledgments: func.isRequired,
  getLastUserAcknowledgment: func.isRequired,
  updateUserAttributes: func.isRequired,
};

IdentifyUserPopup.defaultProps = {
  userIsRia: false,
  userIsSfo: false,
};

export default connect(null, mapDispatchToProps)(IdentifyUserPopup);
