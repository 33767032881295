import update from 'immutability-helper';

import {
  RGA_SET_REPORT_CONFIGS,
  RGA_SET_REPORT_CONFIGS_FAILED,
  RGA_SAVE_FILE_NAME,
  RGA_SET_CONFIGURABLE,
  RGA_SET_TEMPORARY_CONFIGURABLE,
  RGA_SET_REPORT_LOADED,
} from 'actions/actionsConstants';

import initialState from 'reducers/report_generator_reducers/initialState';

export default (state = initialState, action) => {
  switch (action.type) {
    case RGA_SET_REPORT_CONFIGS: {
      const config = action.payload;
      const configurableId = config.configurable_id;

      return {
        ...state,
        current_configs: config,
        configurableId,
        configurableType: config.configurable_type,
        requests: { ...state.requests, [configurableId]: {} },
        queries: { ...state.queries, [configurableId]: {} },
        query_totals: { ...state.query_totals, [configurableId]: {} },
        filteredOptionsPicked: {
          ...state.filteredOptionsPicked,
          [configurableId]: {},
        },
        filteredQueries: { ...state.filteredQueries, [configurableId]: {} },
        filteredRequests: { ...state.filteredRequests, [configurableId]: {} },
        storedExcludedQueries: {
          ...state.storedExcludedQueries,
          [configurableId]: {},
        },
        excludedRanges: { ...state.excludedRanges, [configurableId]: {} },
        selectedFields: { ...state.selectedFields, [configurableId]: [] },
        selectedFieldMetadata: {
          ...state.selectedFieldMetadata,
          [configurableId]: {},
        },
        createCustomQuery: false,
        temporaryConfigurableId: undefined,
        temporaryConfigurableType: undefined,
        totals: { ...state.totals, [configurableId]: {} },
        stored_queries: { ...state.stored_queries, [configurableId]: {} },
        filteredOptions: { ...state.filteredOptions, [configurableId]: {} },
        appliedRanges: { ...state.appliedRanges, [configurableId]: {} },
        appliedFilterType: { ...state.appliedFilterType, [configurableId]: {} },
        filteredTotals: { ...state.filteredTotals, [configurableId]: {} },
        toggle_tracker: { ...state.toggle_tracker, [configurableId]: {} },
        nullOptions: { ...state.nullOptions, [configurableId]: {} },
        disableSave: { ...state.disableSave, [configurableId]: {} },
      };
    }
    case RGA_SET_REPORT_CONFIGS_FAILED: {
      return update(state, { error: { $set: action.payload } });
    }
    case RGA_SET_REPORT_LOADED: {
      return update(state, { configLoaded: { $set: action.payload } });
    }
    case RGA_SAVE_FILE_NAME: {
      return update(state, { current_configs: { $merge: action.payload } });
    }
    case RGA_SET_TEMPORARY_CONFIGURABLE: {
      const { configurableId, configurableType } = action.payload;
      return update(state, {
        temporaryConfigurableId: { $set: configurableId },
        temporaryConfigurableType: { $set: configurableType },
      });
    }
    case RGA_SET_CONFIGURABLE: {
      const { configurableId, configurableType } = action.payload;
      return {
        ...state,
        initialOptions: update(state.initialOptions, {
          [configurableId]: {
            $set: state.initialOptions[configurableId] || {},
          },
        }),
        options: update(state.options, {
          [configurableId]: { $set: state.options[configurableId] || {} },
        }),
        totals: update(state.totals, {
          [configurableId]: { $set: state.totals[configurableId] || {} },
        }),
        stored_queries: update(state.stored_queries, {
          [configurableId]: {
            $set: state.stored_queries[configurableId] || {},
          },
        }),
        filteredOptions: update(state.filteredOptions, {
          [configurableId]: {
            $set: state.filteredOptions[configurableId] || {},
          },
        }),
        appliedRanges: update(state.appliedRanges, {
          [configurableId]: { $set: state.appliedRanges[configurableId] || {} },
        }),
        appliedFilterType: update(state.appliedFilterType, {
          [configurableId]: {
            $set: state.appliedFilterType[configurableId] || {},
          },
        }),
        filteredTotals: update(state.filteredTotals, {
          [configurableId]: {
            $set: state.filteredTotals[configurableId] || {},
          },
        }),
        toggle_tracker: update(state.toggle_tracker, {
          [configurableId]: {
            $set: state.toggle_tracker[configurableId] || {},
          },
        }),
        requests: update(state.requests, {
          [configurableId]: { $set: state.requests[configurableId] || {} },
        }),
        queries: update(state.queries, {
          [configurableId]: { $set: state.queries[configurableId] || {} },
        }),
        query_totals: update(state.query_totals, {
          [configurableId]: { $set: state.query_totals[configurableId] || {} },
        }),
        filteredOptionsPicked: update(state.filteredOptionsPicked, {
          [configurableId]: {
            $set: state.filteredOptionsPicked[configurableId] || {},
          },
        }),
        filteredQueries: update(state.filteredQueries, {
          [configurableId]: {
            $set: state.filteredQueries[configurableId] || {},
          },
        }),
        filteredRequests: update(state.filteredRequests, {
          [configurableId]: {
            $set: state.filteredRequests[configurableId] || {},
          },
        }),
        storedExcludedQueries: update(state.storedExcludedQueries, {
          [configurableId]: {
            $set: state.storedExcludedQueries[configurableId] || {},
          },
        }),
        excludedRanges: update(state.excludedRanges, {
          [configurableId]: {
            $set: state.excludedRanges[configurableId] || {},
          },
        }),
        selectedFields: update(state.selectedFields, {
          [configurableId]: {
            $set: state.selectedFields[configurableId] || [],
          },
        }),
        selectedFieldMetadata: update(state.selectedFieldMetadata, {
          [configurableId]: {
            $set: state.selectedFieldMetadata[configurableId] || {},
          },
        }),
        configurableId,
        configurableType,
      };
    }
    default:
      return state;
  }
};
