import React from 'react';
import {
  bool, arrayOf, string, func,
} from 'prop-types';
import FieldConfiguration from 'containers/investor_profile/field_configuration/FieldConfiguration';
import FieldWrap from 'components/shared/icn_form/field_wrap/FieldWrap';
import { useTheme } from '@mui/material';
import {
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Skeleton,
} from '@icapitalnetwork/supernova-core';
import { registeredRepresentativeSeries } from '../profileData';

const RegistrationSeries = ({
  isRegistrationLoading, currentSeriesSelections, updateStore,
}) => {
  const questionKey = registeredRepresentativeSeries.question_key;
  const options = registeredRepresentativeSeries.series_options;

  const theme = useTheme();

  const selectRegistrationSeries = ({ target: { value } }) => {
    updateStore({ [questionKey]: value });
  };

  const handleDelete = (value) => {
    if (Array.isArray(value)) {
      updateStore({ [questionKey]: [] });
    } else {
      updateStore({ [questionKey]: currentSeriesSelections.filter((n) => n !== value) });
    }
  };

  return (
    <FieldWrap size="full_fluid">
      <FieldConfiguration
        fieldName={registeredRepresentativeSeries.question_key}
      >
        {(config) => (
          isRegistrationLoading ? <Skeleton width={theme.spacing(4)} /> : (
            <FormControl disabled={config.readOnly} fullWidth size="medium">
              <InputLabel id="series-multiselect">Series</InputLabel>
              <Select
                id="multiple-checkbox-series"
                multiple
                label="Series"
                value={currentSeriesSelections}
                onChange={selectRegistrationSeries}
                renderValue={(selected) => (
                  <Chip
                    tabIndex={-1}
                    size="small"
                    sx={{
                      mt: 0.25,
                    }}
                    color="primary"
                    label={
                        selected.length <= 1
                          ? registeredRepresentativeSeries.series_options.find(
                            (option) => selected[0] === option.value
                          ).chipText
                          : `${selected.length} Selected`
                      }
                    onMouseDown={(event) => event.stopPropagation()}
                    onDelete={selected.length <= 1 ? () => handleDelete(selected[0]) : () => handleDelete(selected)}
                  />
                )}
              >
                {options.map((serie) => (
                  <MenuItem sx={{ pl: 1 }} key={serie.value} value={serie.value}>
                    <Checkbox checked={currentSeriesSelections.indexOf(serie.value) > -1} />
                    <ListItemText sx={{ pt: 0.25 }} primary={serie.text} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )
        )}
      </FieldConfiguration>
    </FieldWrap>
  );
};

RegistrationSeries.propTypes = {
  isRegistrationLoading: bool,
  currentSeriesSelections: arrayOf(string).isRequired,
  updateStore: func.isRequired,
};

RegistrationSeries.defaultProps = {
  isRegistrationLoading: false,
};

export default RegistrationSeries;
