import React, { Component } from 'react';
import styles from './entry.module.scss';

export default class NotFound extends Component {
    render() {
        return (
            <div className={styles.not_found}>
                <h1>Not Found</h1>
            </div>
        );
    }
}
