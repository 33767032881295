/* eslint-disable max-statements */
import moment from 'moment';
import _ from 'lodash';

import { stringComparator } from 'services/customComparators';
import { DEFAULT_RELATIVE_RANGE_DURATION } from './constants';
import { FIX_DATE_DURATION_VALUE } from 'containers/report_generator/view/ConfigTabContent/filters/DateFilter/RelativeRange/constants';

const DATE_FORMAT = 'DD-MMM-YYYY';

export function dateFormatter(date) {
  return moment(date).format(DATE_FORMAT);
}

const pluralize = (inputCount, str) => {
  const count = parseInt(inputCount);
  return `${count} ${str}${count !== 1 ? 's' : ''}`;
};

const relativeDateString = (range, duration, sign) => {
  if (range === 0) return 'Today';

  const signString = sign === 'minus' ? 'before' : 'after';

  return `${pluralize(range, duration)} ${signString} today`;
};

const relativeDateLabel = (
  from,
  to,
  fromDuration,
  toDuration,
  fromSign,
  toSign,
  toMonthSpan,
  fromMonthSpan,
) => {
  if (!from && !to) return 'Today';

  const rangeArray = [];

  if (from !== '') {
    if (fromDuration === FIX_DATE_DURATION_VALUE) {
      rangeArray.push(dateFormatter(from));
    } else {
      const fromDurationSingular = (
        fromDuration || DEFAULT_RELATIVE_RANGE_DURATION
      ).slice(0, -1);
      rangeArray.push(relativeDateString(from, fromDurationSingular, fromSign));
    }
  }

  if (to !== '') {
    if (toDuration === FIX_DATE_DURATION_VALUE) {
      rangeArray.push(dateFormatter(to));
    } else {
      const toDurationSingular = (
        toDuration || DEFAULT_RELATIVE_RANGE_DURATION
      ).slice(0, -1);
      rangeArray.push(relativeDateString(to, toDurationSingular, toSign));
    }
  }

  return rangeArray.join(' to ');
};

export function getDateFilterLabel(
  from,
  to,
  relative_date,
  fromDuration,
  toDuration,
  fromSign,
  toSign,
  fromMonthSpan,
  toMonthSpan,
) {
  let selectedRangeString = '';
  if (relative_date) {
    selectedRangeString = relativeDateLabel(
      from,
      to,
      fromDuration,
      toDuration,
      fromSign,
      toSign,
      toMonthSpan,
      fromMonthSpan,
    );
  } else {
    if (!from && !to) { return '';}
    if (from) {
      selectedRangeString += dateFormatter(from);
      if (to) {
        selectedRangeString += ' to ' + dateFormatter(to);
      }
    } else {
      selectedRangeString += dateFormatter(to);
    }
  }
  return selectedRangeString;
}

export function sortArrayAlphabetically(array, sortingField) {
  return array.sort((el1, el2) =>
    stringComparator(el1[sortingField], el2[sortingField]),
  );
}

export function normalizeCurrentPage(newValue, state) {
  const lastPage = Math.ceil(
    state.representations_count / state.results_per_page,
  );
  return newValue > lastPage ? Math.max(lastPage, 1) : Math.max(newValue, 1);
}
export const retrieveDBKey = (field, allFields) => {
  let dbInfo = {};
  Object.keys(allFields).some((key) => {
    return Object.keys(allFields[key]).some((item) => {
      if (allFields[key][item]['name'] === field) {
        dbInfo['model'] = key;
        dbInfo['field'] = item;
        return true;
      }
      return false;
    });
  });

  return dbInfo;
};

export function retrieveExcludedQueries(
  ranges,
  availableFields,
  filter,
  dataType,
) {
  const filterRanges = ranges[filter];
  if (filterRanges === undefined) {
    return undefined;
  }

  const queries = retrieveDBKey(filter, availableFields);
  queries.ranges = filterRanges;
  queries.data_type = dataType;

  const option = { id: filterRanges[0][0], name: filterRanges[0][0] };
  queries.chosen_options = [option];
  return queries;
}

function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}

function isString(item) {
  return typeof item === 'string' || item instanceof String;
}

export const mapOptions = (item, selected) => {
  const id = getRandomInt(1000000);

  if (!item || _.isEmpty(item)) {
    const itemName = item !== '' ? 'null' : '';

    return {
      id: isString(item) ? '' : id,
      name: itemName,
      selected,
    };
  }
  const isArray = Array.isArray(item);
  return {
    id: isArray ? id : item,
    name: isArray ? item[0] : item,
    selected,
  };
};

export const compareItems = (i, j) => {
  if (i.id < j.id) {
    return -1;
  }

  if (i.id > j.id) {
    return 1;
  }

  return 0;
};

function stringRanges(ranges, options) {
  return ranges.map((range) => {
    options.forEach((option) => {
      if (option.id.toLowerCase().includes(range[0]) || range[0] === '') {
        range[2].push(option);
      }
    });
    return range;
  });
}

export function getNumericFilterRangeLabel(range, choosenOtions) {
  let label = '';
  if (range[0] != null && range[0] !== '') {
    label += range[0];
    if (range[1]) {
      label += `-${range[1]}`;
    } else {
      label = '>= ' + label;
    }
  } else if (range[1] && range[1] !== '') {
    label += '<= ' + range[1];
  } else if (range[0] === '' && range[1] === '' && choosenOtions.length > 0) {
    label = ` >= ${choosenOtions[0]['name']}`;
  }
  return label;
}

export function updateAppliedRanges(ranges, options, isNumeric) {
  if (!isNumeric) {
    return stringRanges(ranges, options);
  }

  return ranges.map((range) => {
    const min = parseInt(range[0], 10) || 0;
    const max = parseInt(range[1], 10) || 0;
    options.forEach((option) => {
      const id = parseInt(option.id, 10);
      if ((id >= min && id <= max) || (min === 0 && max === 0)) {
        range[3].push(option);
      }
    });
    return range;
  });
}

export function updateExcludedRanges(
  ranges,
  rangeToRemove,
  options,
  selectedAllInRange,
) {
  if (!selectedAllInRange) {
    return ranges;
  }

  const removedRange = [].concat(rangeToRemove).concat([true, options]);
  if (ranges === undefined) {
    return [removedRange];
  }

  ranges.push(removedRange);
  return ranges;
}

// Checks if the user is trying to add options to a previously selected range and returns the range index
export function workingOnAppliedRange(state, filter) {
  if (state.appliedRanges[filter] === undefined) {
    return -1;
  }
  const appliedRanges = state.appliedRanges[filter];
  const currentRange = state.queries[filter];

  if (Array.isArray(currentRange)) {
    return appliedRanges.findIndex(
      (range) => range[0] === currentRange[0] && range[1] === currentRange[1],
    );
  }
  return appliedRanges.findIndex((range) => range[0] === currentRange);
}

export function getFilterTypeFromFilterConfig(config) {
  if (config && config.filter_type) {
    return config.filter_type;
  }

  if (config && config.ranges && config.ranges.length > 0) {
    return 'range';
  }

  return 'value';
}
