import update from 'immutability-helper';
import { RGA_SET_SFTP_ACCOUNTS } from 'actions/actionsConstants';
import initialState from 'reducers/report_generator_reducers/initialState';

export default (state = initialState, action) => {
  switch (action.type) {
    case RGA_SET_SFTP_ACCOUNTS:
      return update(state, {
        sftpAccounts: { $set: action.payload.sftp_accounts },
      });
    default:
      return state;
  }
};
