const wlpToHideFlagsStates = {
  frep: ['Pending Review'],
  partnersgroup: ['Pending Review'],
  ubs: ['Approved', 'IR Review', 'Compliance Review', 'Reverted', 'Pending Review'],
};

const showExceptionFlagsForWlpInvState = (wlpSlug, investmentApprovalState) => {
  const hideStates = wlpToHideFlagsStates[wlpSlug] || [];
  return !hideStates.includes(investmentApprovalState);
};

export default showExceptionFlagsForWlpInvState;
