import { getRequest, postRequest, patchRequest, deleteRequest } from 'actions/utils';
import { createCRUD, createRequestTypes } from 'actions/helpers';

export const BASE_URL = 'form_templates';
export const BASE_NAME = 'formTemplates';

export const FORM_TEMPLATES = createCRUD(BASE_NAME, {
  CREATE_FROM_ARCHIVE: createRequestTypes(`${BASE_NAME}_CREATE_FROM_ARCHIVE`),
  CREATE_VERSION_FROM_ARCHIVE: createRequestTypes(`${BASE_NAME}_CREATE_VERSION_FROM_ARCHIVE`),
  CREATE_FROM_VERSION: createRequestTypes(`${BASE_NAME}_CREATE_FROM_VERSION`),
  CATEGORIES: createRequestTypes(`${BASE_NAME}_CATEGORIES`),
  CUSTOM_QUESTIONS: createRequestTypes(`${BASE_NAME}_CUSTOM_QUESTIONS`),
  TAGS: createRequestTypes(`${BASE_NAME}_TAGS`),
  STOP_LOADING: `${BASE_NAME}_STOP_LOADING`,
});

export const getFormTemplateCategories = () =>
  getRequest({
    showSpinner: false,
    url: `${BASE_URL}/categories`,
    actions: FORM_TEMPLATES.CATEGORIES,
  });

export const getFormTemplateTags = () =>
  getRequest({
    showSpinner: false,
    url: `${BASE_URL}/tags`,
    actions: FORM_TEMPLATES.TAGS,
  });

export const getFormTemplateData = () => (dispatch) => {
  const promises = [dispatch(getFormTemplateCategories()), dispatch(getFormTemplateTags())];

  return Promise.all(promises).finally(() => {
    dispatch({ type: FORM_TEMPLATES.STOP_LOADING });
  });
};

export const createFormTemplate = ({ name, category, tagList }) =>
  postRequest({
    url: BASE_URL,
    actions: FORM_TEMPLATES.CREATE,
    data: {
      name,
      category,
      tag_list: tagList,
    },
  });

export const createFromVersion = ({ name, category, tagList, formTemplateId, versionId }) =>
  postRequest({
    showSpinner: false,
    url: `${BASE_URL}/${formTemplateId}/create_from_version`,
    actions: FORM_TEMPLATES.CREATE_FROM_VERSION,
    data: {
      name,
      category,
      tag_list: tagList,
      form_template_id: formTemplateId,
      version_id: versionId,
    },
  });

export const updateFormTemplateTags = ({ templateId, tagList }) =>
  patchRequest({
    showSpinner: false,
    url: `${BASE_URL}/${templateId}`,
    actions: FORM_TEMPLATES.UPDATE,
    data: {
      form_template: {
        tag_list: tagList,
      },
    },
  });

export const deleteFormTemplate = ({ id }) =>
  deleteRequest({
    url: `${BASE_URL}/${id}`,
    actions: FORM_TEMPLATES.DELETE,
    requestParams: {
      id,
    },
    successMessage: { message: 'Template deleted!' },
    errorMessage: {
      message: 'Unable to delete the template',
    },
  });

export const apiShowFormTemplate =
  (actions) =>
  ({ id }) =>
    getRequest({
      url: `${BASE_URL}/${id}`,
      actions,
      requestParams: {
        id,
      },
    });

export const showFormTemplate = apiShowFormTemplate(FORM_TEMPLATES.READ);

export const createFromArchive = ({ data }) =>
  postRequest({
    showSpinner: false,
    url: `${BASE_URL}/create_from_archive`,
    actions: FORM_TEMPLATES.CREATE_FROM_ARCHIVE,
    data,
  });

export const createVersionFromArchive = ({ templateId, isInvestorProfileTemplate, data }) => {
  const url = isInvestorProfileTemplate
    ? `${BASE_URL}/${templateId}/create_investor_profile_version_from_archive`
    : `${BASE_URL}/${templateId}/create_version_from_archive`;

  return postRequest({
    showSpinner: false,
    url,
    actions: FORM_TEMPLATES.CREATE_VERSION_FROM_ARCHIVE,
    data,
    requestParams: {
      templateId,
    },
  });
};

export default {
  getFormTemplateCategories,
  getFormTemplateTags,
  getFormTemplateData,
  createFormTemplate,
  createFromVersion,
  deleteFormTemplate,
  updateFormTemplateTags,
  showFormTemplate,
  createFromArchive,
  createVersionFromArchive,
};
