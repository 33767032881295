/* globals window */
export default {
  set: (prop, value) => (
    window.localStorage.setItem(prop, value)
  ),
  get: (prop) => (
    window.localStorage.getItem(prop)
  ),
  remove: (key) => (
    window.localStorage.removeItem(key)
  ),
};
