import React, { useEffect, useState, useCallback } from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import styles from './IncidentStatusAlerts.module.scss';
import { fetchAlert } from './api';
import Bar from './IncidentStatusAlertBar';

export default function IncidentStatusAlert({ className, activeClassName, resolvedClassName }) {
  const [alerts, setAlerts] = useState();

  const init = useCallback(async () => {
    const newAlerts = await fetchAlert();
    setAlerts(newAlerts);
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  const activeAlerts = alerts?.filter(({ status = 'active' }) => status === 'active');
  const resolvedAlerts = alerts?.filter(({ status }) => status === 'resolved');

  const baseStyles = classNames(className, styles.bar);

  return (
    <div className={styles.bars} data-test-id="isa-bars">
      <Bar
        status="resolved"
        alerts={resolvedAlerts}
        className={classNames(baseStyles, resolvedClassName, styles.resolvedBar)}
        data-test-id="isa-bar"
      />
      <Bar
        status="active"
        alerts={activeAlerts}
        className={classNames(baseStyles, activeClassName, styles.activeBar)}
        data-test-id="isa-bar"
      />
    </div>
  );
}
IncidentStatusAlert.propTypes = { className: string, activeClassName: string, resolvedClassName: string };
IncidentStatusAlert.defaultProps = { className: '', activeClassName: '', resolvedClassName: '' };
