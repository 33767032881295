import { beginApiRequest, endApiRequest } from 'actions/spinner_actions/spinnerActions';
import { actionFactory } from 'services/actions';
import { allDocumentTypes } from 'services/required_document_types';
import { getPrivateAccessFundsNameList } from 'services/funds/private_access_funds';
import { createFilterOption } from 'services/dropdown_filter';
import { getIntegrationPartners } from 'services/integration_partners';
import {
  deleteIntegrationPartnerRule,
  updateIntegrationPartnerRule,
  createIntegrationPartnerRule,
  getIntegrationPartnersRules,
} from 'services/integration_partners/rules';

import onItemsSelection from 'services/DropDownItemSelectionService';
import { getAllSelectedOptions } from 'services/integration_partner_dashboard';
import investmentStatuses from 'constants/investmentStatuses';
import { fetchWlpList } from 'services/white_label_partner/whiteLabelPartnerList';

import {
  INTEGRATION_PARTNER_DASHBOARD_UPDATE_PAGINATION,
  INTEGRATION_PARTNER_DASHBOARD_UPDATE_FILTER,
  INTEGRATION_PARTNER_DASHBOARD_SET_WLPS,
  INTEGRATION_PARTNER_DASHBOARD_SET_RULES,
  INTEGRATION_PARTNER_DASHBOARD_SET_FUNDS,
  INTEGRATION_PARTNER_DASHBOARD_SET_TRIGGER,
  INTEGRATION_PARTNER_DASHBOARD_SET_DOC_TYPES,
  INTEGRATION_PARTNER_DASHBOARD_SET_INT_PARTNERS,
} from 'actions/actionsConstants';
import _ from 'lodash';

const integrationPartnerDashboardSetWlps = actionFactory(INTEGRATION_PARTNER_DASHBOARD_SET_WLPS);
const integrationPartnerDashboardSetRules = actionFactory(INTEGRATION_PARTNER_DASHBOARD_SET_RULES);
const integrationPartnerDashboardSetFunds = actionFactory(INTEGRATION_PARTNER_DASHBOARD_SET_FUNDS);
const integrationPartnerDashboardSetTrigger = actionFactory(INTEGRATION_PARTNER_DASHBOARD_SET_TRIGGER);
const integrationPartnerDashboardSetDocTypes = actionFactory(INTEGRATION_PARTNER_DASHBOARD_SET_DOC_TYPES);
const integrationPartnerDashboardIntPartners = actionFactory(INTEGRATION_PARTNER_DASHBOARD_SET_INT_PARTNERS);
const integrationPartnerDashboardUpdateFilters = actionFactory(INTEGRATION_PARTNER_DASHBOARD_UPDATE_FILTER);
const integrationPartnerDashboardUpdatePaginationSort = actionFactory(INTEGRATION_PARTNER_DASHBOARD_UPDATE_PAGINATION);

function loadTriggersList() {
  return (dispatch) => {
    dispatch(integrationPartnerDashboardSetTrigger(_.map(Object.keys(investmentStatuses),
      (key) => ({ text: investmentStatuses[key], value: key }))));
  };
}

function loadIntegrationPartnersList() {
  return (dispatch) => {
    dispatch(beginApiRequest());
    getIntegrationPartners().then((response) => {
      dispatch(integrationPartnerDashboardIntPartners(_.map(response.integration_partners,
        (item) => createFilterOption(item.name, 'integration_partners.id', item.id))));
      dispatch(endApiRequest());
    });
  };
}

function loadFundsList() {
  return (dispatch) => {
    dispatch(beginApiRequest());
    getPrivateAccessFundsNameList().then((response) => {
      const items = _.sortBy(_.map(response, (item) => {
        const paf = item.private_access_fund;
        return { text: paf.name, value: paf.id };
      }), ['text']);
      dispatch(integrationPartnerDashboardSetFunds(items));
      dispatch(endApiRequest());
    });
  };
}

function loadDocumentTypesList() {
  return (dispatch) => {
    dispatch(beginApiRequest());
    allDocumentTypes().then((response) => {
      const filter = _.map(_.sortBy(response, ['name']),
        (item) => createFilterOption(item.name, 'required_document_types.id', item.id));
      dispatch(integrationPartnerDashboardSetDocTypes(filter));
      dispatch(endApiRequest());
    });
  };
}

function loadWhiteLabelPartnerList() {
  return fetchWlpList({
    onSuccess: (response) => {
      const flagship = createFilterOption('Flagship Site', 'white_label_partner.id', 0);
      const list = _.map(response, (item) => createFilterOption(item.white_label_partner.name,
        'white_label_partner.id', item.white_label_partner.id));
      return integrationPartnerDashboardSetWlps([flagship, ..._.sortBy(list, ['content'])]);
    },
  });
}

function integrationPartnersDashboardLoadFormOptions() {
  return (dispatch) => {
    dispatch(loadTriggersList());
    dispatch(loadIntegrationPartnersList());
    dispatch(loadFundsList());
    dispatch(loadDocumentTypesList());
    dispatch(loadWhiteLabelPartnerList());
  };
}

function integrationPartnersDashboardLoadRules() {
  return (dispatch, getState) => {
    dispatch(beginApiRequest());
    const state = getState();
    const integrationPartnersDashboard = state.integrationPartnersDashboard;

    const updatedMeta = { ...integrationPartnersDashboard.pagination };
    updatedMeta.filter_options = getAllSelectedOptions(state);

    getIntegrationPartnersRules(updatedMeta).then((response) => {
      dispatch(integrationPartnerDashboardSetRules(response));
      dispatch(endApiRequest());
    }).catch(() => dispatch(endApiRequest()));
  };
}

function dashboardDeleteIntegrationPartnerRule(id) {
  return (dispatch) => {
    dispatch(beginApiRequest());
    deleteIntegrationPartnerRule(id).then(() => {
      dispatch(integrationPartnersDashboardLoadRules());
      dispatch(endApiRequest());
    });
  };
}

const formatError = (error) => {
  const formattedError = { ...error };
  formattedError.message = error.message.split('Validation failed: ')[1];
  return formattedError;
};

function integrationPartnersDashboardRuleUpdate(rule, onSuccess, onFail) {
  return (dispatch) => {
    dispatch(beginApiRequest());
    updateIntegrationPartnerRule(rule).then(() => {
      dispatch(integrationPartnersDashboardLoadRules());
      dispatch(endApiRequest());
      onSuccess();
    }).catch((error) => {
      dispatch(endApiRequest());
      onFail(formatError(error.data));
    });
  };
}

function integrationPartnersDashboardSetPaginationSort(pagination) {
  return (dispatch) => {
    dispatch(integrationPartnerDashboardUpdatePaginationSort(pagination));
    dispatch(integrationPartnersDashboardLoadRules());
  };
}

function integrationPartnersDashboardRuleCreate(rule, onSuccess, onFail) {
  return (dispatch) => {
    dispatch(beginApiRequest());
    createIntegrationPartnerRule(rule).then(() => {
      const pagination = {
        sort_field: 'integration_partner_rules.created_at',
        sort_direction: 'desc',
        page: 1,
      };

      const filters = {
        selectedFunds: [],
        selectedIntegrationPartners: [],
        selectedStatusTriggers: [],
        selectedDocTypes: [],
        selectedWlps: [],
      };
      dispatch(integrationPartnerDashboardUpdateFilters(filters));
      dispatch(integrationPartnersDashboardSetPaginationSort(pagination));
      dispatch(endApiRequest());
      onSuccess();
    }).catch((error) => {
      dispatch(endApiRequest());
      onFail(formatError(error.data));
    });
  };
}

function setSelectedRuleFilters(itemIds, reduxKey, itemCollection) {
  return (dispatch) => {
    const items = onItemsSelection(itemIds, itemCollection);
    dispatch(integrationPartnerDashboardUpdateFilters({ [reduxKey]: items }));
    dispatch(integrationPartnersDashboardLoadRules());
  };
}

export default {
  integrationPartnersDashboardLoadRules,
  integrationPartnersDashboardLoadFormOptions,
  integrationPartnersDashboardRuleUpdate,
  integrationPartnersDashboardRuleCreate,
  setSelectedRuleFilters,
  integrationPartnersDashboardSetPaginationSort,
  dashboardDeleteIntegrationPartnerRule,
  loadWhiteLabelPartnerList,
};
