/**
* Ag Grid Value Formatter for the return_type field
* @param {Object} params ag grid params
* @return {String} return type description
*/
export default function returnTypeFormatter(params) {
  switch (params.value) {
    case 'pro_forma':
      return 'Pro forma';
    case 'actual':
      return 'Actual';
    case 'estimate':
      return 'Estimate';
    default:
      return 'NA';
  }
}

export function frequencyFormatter(params) {
  switch (params.value) {
    case 'monthly':
      return 'Monthly';
    case 'quarterly':
      return 'Quarterly';
    default:
      return 'Monthly';
  }
}
