import update from 'immutability-helper';
import API_DASHBOARD_ACTIONS from '../../actionsConstants';
import { initialDashboardState } from '../../reducer';

const getAccountDashboardReducer = (
  state = initialDashboardState,
  { type, payload },
) => {
  switch (type) {
    case API_DASHBOARD_ACTIONS.SET_SELECTED_GET_ACCOUNT:
      return update(state, {
        selectedItem: {
          $set: payload,
        },
      });
    case API_DASHBOARD_ACTIONS.GET_GET_ACCOUNT_LOGS:
      return update(state, {
        selectedItemLogs: {
          $set: payload,
        },
      });
    case API_DASHBOARD_ACTIONS.SET_GET_ACCOUNT_CURRENT_VIEW_FILTERS:
      return update(state, {
        currentViewFilters: {
          $set: payload,
        },
      });
    case API_DASHBOARD_ACTIONS.SET_GET_ACCOUNT_DEFAULT_VIEW_FILTERS:
      return update(state, {
        defaultViewFilters: {
          $set: payload,
        },
      });
    case API_DASHBOARD_ACTIONS.SET_GET_ACCOUNT_CURRENT_PAGE:
      return update(state, {
        currentPage: {
          $set: payload,
        },
      });
    case API_DASHBOARD_ACTIONS.SET_GET_ACCOUNT_CURRENT_PAGE_ROWS:
      return update(state, {
        currentPageRows: {
          $set: payload,
        },
      });
    case API_DASHBOARD_ACTIONS.SET_GET_ACCOUNT_ANALYTICS:
      return update(state, {
        analytics: {
          $set: payload,
        },
      });
    case API_DASHBOARD_ACTIONS.SET_GET_ACCOUNT_ANALYTICS_DAYS_BEHIND:
      return update(state, {
        analyticsDaysBehind: {
          $set: payload,
        },
      });
    case API_DASHBOARD_ACTIONS.SET_GET_ACCOUNT_ANALYTICS_BRIDGE_SELECTED:
      return update(state, {
        analyticsBridgeSelected: {
          $set: payload,
        },
      });
    default:
      return state;
  }
};

export default getAccountDashboardReducer;
