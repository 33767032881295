import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  DialogActions,
  Typography,
} from '@icapitalnetwork/supernova-core';
import {
  func, shape, string,
} from 'prop-types';
import StyledDialogContent from './StyledDialogContent';

function RiaUserIdentificationPageOne({ user, onNext }) {
  const { t } = useTranslation(['common']);

  return (
    <>
      <StyledDialogContent>
        <Typography variant="extraLargeParagraph" sx={{ lineHeight: '30px' }}>
          {`Hello ${user.first_name},`}
        </Typography>
        <Typography variant="extraLargeParagraph" sx={{ lineHeight: '30px' }}>
          To properly identify who our users are on the iCapital platform, we ask that you please answer the
          following questions.
        </Typography>
      </StyledDialogContent>
      <DialogActions>
        <Button autoFocus onClick={onNext} variant="contained">
          {t('common:start', 'Start')}
        </Button>
      </DialogActions>
    </>
  );
}

RiaUserIdentificationPageOne.propTypes = {
  user: shape({
    first_name: string.isRequired,
  }).isRequired,
  onNext: func.isRequired,
};

export default RiaUserIdentificationPageOne;
