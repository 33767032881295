import React from 'react';
import FieldConfiguration from 'containers/investor_profile/field_configuration/FieldConfiguration';
import FieldWrap from 'components/shared/icn_form/field_wrap/FieldWrap';
import {
  Box,
  Skeleton,
  TextField,
} from '@icapitalnetwork/supernova-core';
import { shape, bool, func } from 'prop-types';
import { useTheme } from '@mui/material';
import {
  firmRoleIfOther,
} from '../profileData';

function FirmRoleIfOther({ isRegistrationLoading, profileData, updateStore }) {
  const theme = useTheme();

  const updateFirmRole = ({ target: { value } }) => updateStore({ [firmRoleIfOther.question_key]: value });

  return (
    <FieldWrap size="full">
      <FieldConfiguration
        fieldName={firmRoleIfOther.question_key}
      >
        {(config) => (
          isRegistrationLoading ? <Skeleton height={theme.spacing(8)} /> : (
            <Box display="flex" columnGap={theme.spacing(1)} alignItems="center">
              <TextField
                fullWidth
                id={firmRoleIfOther.question_key}
                label="Role"
                value={profileData[firmRoleIfOther.question_key] || ''}
                onChange={updateFirmRole}
                disabled={config.readOnly}
              />
            </Box>
          ))}
      </FieldConfiguration>
    </FieldWrap>
  );
}

FirmRoleIfOther.propTypes = {
  isRegistrationLoading: bool,
  profileData: shape({}).isRequired,
  updateStore: func.isRequired,
};

FirmRoleIfOther.defaultProps = {
  isRegistrationLoading: false,
};

export default FirmRoleIfOther;
