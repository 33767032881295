import axios from 'axios';
import { endApiRequest, beginApiRequest } from 'actions/spinner_actions/spinnerActions';

import {
  DVI_SELECT_FUND,
  DVI_SELECT_DOCUMENT_TYPE,
  DVI_SELECT_ACT_DATE,
  DVI_SELECT_EFF_DATE,
  DVI_SET_FUNDS,
  DVI_SET_DOCUMENT_TYPES,
  DVI_SET_SORTING,
  DVI_LOADING_FUNDS,
  DVI_SET_HIT_RECORDS,
  DVI_UPDATE_PAGINATION,
  DVI_UPDATE_TOTAL_RESULTS,
} from 'actions/actionsConstants';

function dviSetFunds(payload) {
  return {
    type: DVI_SET_FUNDS,
    payload,
  };
}

function dviSetDocumentTypes(payload) {
  return {
    type: DVI_SET_DOCUMENT_TYPES,
    payload,
  };
}

function dviSelectFund(payload) {
  return {
    type: DVI_SELECT_FUND,
    payload,
  };
}

function dviSetSorting(payload) {
  return {
    type: DVI_SET_SORTING,
    payload,
  };
}

function dviSelectDocumentType(payload) {
  return {
    type: DVI_SELECT_DOCUMENT_TYPE,
    payload,
  };
}

function dviSelectActionDate(payload) {
  return {
    type: DVI_SELECT_ACT_DATE,
    payload,
  };
}

function dviSelectEffectiveDate(payload) {
  return {
    type: DVI_SELECT_EFF_DATE,
    payload,
  };
}

function dviFundsLoading() {
  return {
    type: DVI_LOADING_FUNDS,
    payload: true,
  };
}

function dviFundsLoaded() {
  return {
    type: DVI_LOADING_FUNDS,
    payload: false,
  };
}

function dviSetHitRecords(payload) {
  return {
    type: DVI_SET_HIT_RECORDS,
    payload,
  };
}

function dviUpdatePagination(payload) {
  return {
    type: DVI_UPDATE_PAGINATION,
    payload,
  };
}

function dviUpdateTotalResults(payload) {
  return {
    type: DVI_UPDATE_TOTAL_RESULTS,
    payload,
  };
}

function dviPageParams(state) {
  return {
    fund_id: state.fund && state.fund.id,
    effective_date: state.effectiveDate,
    action_date: state.actionDate,
    document_type: state.documentType && state.documentType.name,
    page: state.currentPage,
    per_page: state.resultsPerPage,
    sort_col: state.sortingColumn,
    sort_order: state.sortingOrder,
  };
}

function dviDownloadParams(state) {
  return {
    fund_id: state.fund && state.fund.id,
    effective_date: state.effectiveDate,
    action_date: state.actionDate,
    document_type: state.documentType && state.documentType.name,
  };
}

function axiosRequest(url, query) {
  return axios.get(url, {
    params: {
      query,
    },
    withCredentials: true,
  });
}

function dviFetchFunds(query) {
  const url = 'document_view_info/funds';
  return (dispatch) => {
    dispatch(dviFundsLoading());
    return axiosRequest(url, query).then((response) => {
      dispatch(dviSetFunds(response.data));
      dispatch(dviFundsLoaded());
    }).catch(() => {
      dispatch(endApiRequest());
      dispatch(dviFundsLoaded());
    });
  };
}

function dviFetchDocumentTypes(query) {
  const url = 'document_view_info/document_types';
  return (dispatch) => {
    dispatch(beginApiRequest());
    return axiosRequest(url, query).then((response) => {
      dispatch(dviSetDocumentTypes(response.data));
      dispatch(endApiRequest());
    }).catch(() => {
      dispatch(endApiRequest());
    });
  };
}

function dviGetHitRecords() {
  const url = 'document_view_info';

  return (dispatch, getState) => {
    const params = dviPageParams(getState().documentViewInfo);
    dispatch(beginApiRequest());

    return axios.get(url, {
      params,
      withCredentials: true,
    }).then((response) => {
      dispatch(dviSetHitRecords(response.data.rows));
      dispatch(dviUpdatePagination(response.data.meta.current_page));
      dispatch(dviUpdateTotalResults(response.data.meta.total));
      dispatch(endApiRequest());
    }).catch(() => {
      dispatch(endApiRequest());
    });
  };
}

function dviPrepareCSV() {
  const url = 'document_view_info/create_document';

  return (dispatch, getState) => {
    const params = dviDownloadParams(getState().documentViewInfo);
    dispatch(beginApiRequest());

    return axios.post(url, {
      query: params,
      withCredentials: true,
    }).then((response) => {
      // eslint-disable-next-line no-alert
      window.alert(response.data.message);
      dispatch(endApiRequest());
    }).catch(() => {
      dispatch(endApiRequest());
    });
  };
}

function dviDownload(id) {
  const url = 'document_view_info/download_document';

  return axios.get(
    url, { withCredentials: true, params: { id }, responseType: 'arraybuffer'}, 
  ).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const disposition = response.headers['content-disposition'];
    const support = document.createElement('a');
    support.href = url;
    support.download = disposition.match(/filename="(.*)"/)[1];
    support.click();
    support.remove();
  });
}

export default {
  dviSetFunds,
  dviSetDocumentTypes,
  dviSetSorting,
  dviSelectFund,
  dviSelectDocumentType,
  dviSelectActionDate,
  dviSelectEffectiveDate,
  dviFetchFunds,
  dviFetchDocumentTypes,
  dviFundsLoading,
  dviFundsLoaded,
  dviSetHitRecords,
  dviGetHitRecords,
  dviPrepareCSV,
  dviDownload,
  dviUpdatePagination,
  dviUpdateTotalResults,
};
