import { createAction } from 'redux-actions';
import { getRequest } from 'actions/utils';

import { createCRUD, createRequestTypes } from 'actions/helpers';

const BASE_NAME = 'massMailerDocumentTypes';

export const MASS_MAILER_DOCUMENT_TYPES = createCRUD(BASE_NAME, {
  LIST: createRequestTypes(`${BASE_NAME}_LIST`),
});

export const MASS_MAILER_DOCUMENT_TYPES_LIST = {
  UNMOUNT: `${BASE_NAME}_UNMOUNT`,
};

export const fetchDocumentTypes = () => getRequest({
  showSpinner: false,
  url: 'mass_mailer/document_types',
  actions: MASS_MAILER_DOCUMENT_TYPES.LIST,
});
