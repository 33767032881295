import { deleteRequest, getRequest, putRequest, postRequest, patchRequest } from 'actions/utils';
import { createCRUD, createRequestTypes } from 'actions/helpers';
import { BASE_NAME as FORM_TEMPLATES_BASE_NAME } from '../actions';

export const BASE_URL = 'form_templates';
export const INVESTOR_PROFILE_URL = 'investor_profile_template_versions';
export const BASE_NAME = `${FORM_TEMPLATES_BASE_NAME}/versions`;

export const FORM_TEMPLATES_VERSIONS = createCRUD(BASE_NAME, {
  LIST: createRequestTypes(`${BASE_NAME}_LIST`),
  PUBLISH: createRequestTypes(`${BASE_NAME}_PUBLISH`),
  EXPORT: createRequestTypes(`${BASE_NAME}_EXPORT`),
  IMPORT: createRequestTypes(`${BASE_NAME}_IMPORT`),
  READ_CUSTOM_QUESTION: createRequestTypes(`${BASE_NAME}_READ_CUSTOM_QUESTION`),
});

export const getFormTemplateVersions = ({ templateId }) =>
  getRequest({
    showSpinner: false,
    url: `${BASE_URL}/${templateId}/versions`,
    actions: FORM_TEMPLATES_VERSIONS.LIST,
  });

export const createFormTemplateVersion = ({ templateId, uiSchema, validationSchema }) =>
  postRequest({
    url: `${BASE_URL}/${templateId}/versions`,
    actions: FORM_TEMPLATES_VERSIONS.CREATE,
    data: {
      ui_schema: uiSchema,
      schema: validationSchema,
    },
  });

const getFormTemplateVersionUrl = ({ versionId, parentVersionId, formTemplate }) => {
  if (formTemplate.is_category_investor_profile) {
    return `${INVESTOR_PROFILE_URL}/${parentVersionId}/pages/${versionId}`;
  }
  return `${BASE_URL}/${formTemplate.id}/versions/${versionId}`;
};

export const updateFormTemplateVersion = ({ parentVersionId, versionId, formTemplate, uiSchema, validationSchema }) =>
  putRequest({
    url: getFormTemplateVersionUrl({ versionId, parentVersionId, formTemplate }),
    actions: FORM_TEMPLATES_VERSIONS.UPDATE,
    data: {
      ui_schema: uiSchema,
      schema: validationSchema,
    },
  });

export const deleteFormTemplateVersion = ({ templateId, versionId, isInvestorProfileTemplate }) => {
  const url = isInvestorProfileTemplate
    ? `${INVESTOR_PROFILE_URL}/${versionId}`
    : `${BASE_URL}/${templateId}/versions/${versionId}`;

  return deleteRequest({
    url,
    actions: FORM_TEMPLATES_VERSIONS.DELETE,
    requestParams: {
      templateId,
      versionId,
    },
    successMessage: { message: 'Draft version deleted!' },
    errorMessage: {
      message: 'An error occurred while trying to delete the version',
    },
  });
};

export const apiShowFormTemplateVersion =
  (actions) =>
  ({ templateId, versionId }) =>
    getRequest({
      url: `${BASE_URL}/${templateId}/versions/${versionId}`,
      actions,
      requestParams: {
        templateId,
        versionId,
      },
    });

export const showFormTemplateVersion = apiShowFormTemplateVersion(FORM_TEMPLATES_VERSIONS.READ);

// Use this if you want to manually download the form template version
export const exportFormTemplateVersion = ({ templateId, versionId }) =>
  getRequest({
    responseType: 'blob',
    url: `${BASE_URL}/${templateId}/versions/${versionId}/export`,
    actions: FORM_TEMPLATES_VERSIONS.EXPORT,
    requestParams: {
      templateId,
      versionId,
    },
  });

export const publishFormTemplateVersion = ({ template, versionId }) => {
  let versionsUrl = `${BASE_URL}/${template.id}/versions/${versionId}/publish`;
  if (template.is_category_investor_profile) {
    versionsUrl = `${INVESTOR_PROFILE_URL}/${versionId}/publish`;
  }

  return patchRequest({
    url: versionsUrl,
    actions: FORM_TEMPLATES_VERSIONS.PUBLISH,
    params: {
      template_id: template.id,
    },
    successMessage: { message: 'Version successfully published!' },
  });
};

export default {
  getFormTemplateVersions,
  createFormTemplateVersion,
  updateFormTemplateVersion,
  deleteFormTemplateVersion,
  showFormTemplateVersion,
  publishFormTemplateVersion,
};
