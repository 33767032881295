import { getRequest, patchRequest, postRequest } from 'services/apiRequest';
import { FALLBACK_ERROR_MESSAGE } from 'containers/client_management/constants';

export const loadSignerConfigurations = (id, investmentId, investorProfileId) => getRequest({
  url: `subscription_document_definitions/${id}/retrieve_signer_configurations`,
  params: { investment_id: investmentId, investor_profile_id: investorProfileId },
  errorMessage: FALLBACK_ERROR_MESSAGE,
});

export const cancelSubscription = (investmentId, requestArgs = {}) => patchRequest({
  errorMessage: FALLBACK_ERROR_MESSAGE,
  url: `investments/${investmentId}/cancel_subscription`,
  ...requestArgs,
});

export const needsNewAgreementResponses = (investmentId) => getRequest({
  errorMessage: FALLBACK_ERROR_MESSAGE,
  url: `/investments/${investmentId}/needs_new_agreement_responses`,
});

export const uploadUserDoc = (investment, file) => {
  const formData = new FormData();
  formData.append('file', file);
  return postRequest({
    url: `investments/${investment.id}/upload_document`,
    data: formData,
  });
};

export const uploadTransferDoc = (investment, file) => {
  const formData = new FormData();
  formData.append('file', file);
  return postRequest({
    url: `investments/${investment.id}/upload_transfer_document`,
    data: formData,
  });
};

export const eligibleFor2faAuthentication = (investment) => getRequest({
  url: `investments/${investment.id}/eligible_for_2fa_authentication`,
});
