import axios from 'axios';
import { LM_SET_SVC_STATE, LM_SET_POLL_DATA } from 'actions/actionsConstants';

function createDownloadLink(data) {
  const objUrl = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = objUrl;
  link.setAttribute('download', 'result_lev_match.csv');
  document.body.appendChild(link);
  link.click();
}

function downloadResultFile(dispatch, id) {
  axios.get(`levenshtein_matcher/levenshtein_matcher_operations/${id}/export_file`)
    .then((resp) => {
      createDownloadLink(resp.data);
      dispatch({ type: LM_SET_SVC_STATE, payload: 'finished' });
      clearData(id);
    })
    .catch(() => {
      dispatch({ type: LM_SET_SVC_STATE, payload: 'canceled' });
    });
}

function clearData(id) {
  axios.delete(`levenshtein_matcher/levenshtein_matcher_operations/${id}`);
}

function createLevenshteinMatcherCsv(compareFile, clientFile, matchesNumber, callback) {
  return (dispatch) => {
    dispatch({ type: LM_SET_SVC_STATE, payload: 'running' });

    const url = 'levenshtein_matcher/levenshtein_matcher_operations';
    const formData = new FormData();
    formData.append('compare_file', compareFile);
    formData.append('client_file', clientFile);
    formData.append('matches_number', matchesNumber);

    axios.post(url, formData)
      .then((response) => {
        const setIntervalId = setInterval(callback, 5000);
        dispatch({ type: LM_SET_POLL_DATA, payload: { id: response.data.id, setIntervalId } });
      })
      .catch(() => {
        dispatch({ type: LM_SET_SVC_STATE, payload: 'canceled' });
      });
  };
}

function showOperationStatus(id, setIntervalId) {
  return (dispatch) => {
    axios.get(`levenshtein_matcher/levenshtein_matcher_operations/${id}`)
      .then((response) => {
        if (response.data.state === 'completed') {
          clearInterval(setIntervalId);
          downloadResultFile(dispatch, id);
        } else if (response.data.state === 'canceled') {
          dispatch({ type: LM_SET_SVC_STATE, payload: response.data.state });
          clearInterval(setIntervalId);
        }
      })
      .catch(() => {
        dispatch({ type: LM_SET_SVC_STATE, payload: 'canceled' });
        clearInterval(setIntervalId);
      });
  };
}

export default {
  createLevenshteinMatcherCsv,
  showOperationStatus,
};
