import React from 'react';
import i18n from 'i18n.js';

const ExportStarted = () => {
  return (
    <div>
      <span>
       {i18n.t('common:messages.your_export_may_take_a_few_moments', 'Your export may take a few moments.')}
        <br />
        {i18n.t('common:messages.we_will_notify_you', 'We will notify you when it is ready.')}
      </span>
      <button type="button" className="btn-close times-icon" />
    </div>
  );
};

export default ExportStarted;
