// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IcnSimpleChoiceModal-module__button_spacer___YeYMH{width:12px}`, "",{"version":3,"sources":["webpack://./icn_react/src/components/shared/modals/IcnSimpleChoiceModal/IcnSimpleChoiceModal.module.scss"],"names":[],"mappings":"AAAA,oDACE,UAAA","sourcesContent":[".button_spacer {\n  width: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button_spacer": `IcnSimpleChoiceModal-module__button_spacer___YeYMH`
};
export default ___CSS_LOADER_EXPORT___;
