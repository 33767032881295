/* eslint-disable max-statements */
/* eslint-disable camelcase */
import axios from 'axios';
import { beginApiRequest, endApiRequest, failedApiRequest } from 'actions/spinner_actions/spinnerActions';

const API_BASE_URL = 'current_user';

function getUserSettings() {
  return (dispatch) => {
    dispatch(beginApiRequest());
    return new Promise((resolve, reject) => {
      axios({
        url: `${API_BASE_URL}/settings`,
        method: 'get',
        withCredentials: true,
      })
        .then((response) => {
          dispatch(endApiRequest());
          resolve(response);
        })
        .catch((error) => {
          dispatch(failedApiRequest(error));
          reject(error);
        });
    });
  };
}

function assembleDataObj({ user, white_label_partner: whiteLabelPartner }, payload) {
  const {
    oldEmail,
    email,
    currentPassword,
    changePassword,
    confirmPassword,
    oldPassword,
    updateEDelivery,
    currentEDelivery,
    currentSalesReportingShareInfo,
    updateSalesReportingShareInfo,
    firmInformationSharingAcknowledge,
  } = payload;
  const {
    notifications_delivery: notificationDelivery,
  } = whiteLabelPartner;

  let userData = {};

  if (oldEmail !== email && !!currentPassword) {
    userData = { ...userData, email, current_password: currentPassword };
  }
  if (!!oldPassword && !!changePassword && !!confirmPassword) {
    userData = {
      ...userData, current_password: oldPassword, password: changePassword, password_confirmation: confirmPassword,
    };
  }
  if (!!oldPassword) {
    userData.current_password = oldPassword;
  }
  if (notificationDelivery && updateEDelivery) {
    userData.notifications_delivery = currentEDelivery;
  }
  if (user.allowed_to_change_share_information && updateSalesReportingShareInfo) {
    userData.share_info_with_third_party_managers = currentSalesReportingShareInfo;
  }
  if (firmInformationSharingAcknowledge) {
    userData.firm_information_sharing_acknowledge = true;
  }
  return { user: userData };
}

function saveNewUserSettings(payload) {
  return (dispatch, getState) => {
    const dataObj = assembleDataObj(getState().icnReactBootstrap.icn_react_bootstrap, payload);
    dispatch(beginApiRequest());
    return new Promise((resolve, reject) => {
      axios({
        url: API_BASE_URL,
        method: 'patch',
        withCredentials: true,
        data: dataObj,
      })
        .then((response) => {
          dispatch(endApiRequest());
          resolve(response);
        })
        .catch((error) => {
          dispatch(failedApiRequest(error));
          reject(error);
        });
    });
  };
}

export default {
  saveNewUserSettings,
  getUserSettings,
};
