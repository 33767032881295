import update from 'immutability-helper';
import { FORM_TEMPLATES_VERSIONS_UPDATE } from './actions';

export const initialState = {
  isLoading: true,
  formTemplateVersion: null,
};

export const formTemplateVersionEditReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FORM_TEMPLATES_VERSIONS_UPDATE.READ.REQUEST:
      return update(state, {
        isLoading: { $set: true },
      });
    case FORM_TEMPLATES_VERSIONS_UPDATE.READ.SUCCESS:
      return update(state, {
        isLoading: { $set: false },
        formTemplateVersion: { $set: payload.response },
      });
    case FORM_TEMPLATES_VERSIONS_UPDATE.READ.FAILURE:
      return update(state, {
        isLoading: { $set: false },
      });
    case FORM_TEMPLATES_VERSIONS_UPDATE.UNMOUNT:
      return initialState;
    default:
      return state;
  }
};

export default formTemplateVersionEditReducer;
