import {
  SR_LOAD_ASSIGNMENTS_SUCCESS,
  SR_LOAD_ASSIGNMENTS_COUNT_SUCCESS,
  SR_REMOVE_ASSIGNMENT,
} from 'actions/actionsConstants';

import update from 'immutability-helper';

import initialState from '../initialState';

export default (state = initialState, action) => {
  let newState = null;

  switch (action.type) {
    case SR_LOAD_ASSIGNMENTS_SUCCESS:
      if (action.payload.length === 0 && state.current_page > initialState.current_page) {
        newState = update(state, {
          assignments: { $set: action.payload },
          current_page: { $set: state.current_page - 1 },
        });
      } else {
        newState = update(state, {
          assignments: { $set: action.payload },
        });
      }
      return newState;
    case SR_LOAD_ASSIGNMENTS_COUNT_SUCCESS:
      newState = update(state, { assignments_count: { $set: action.payload } });
      return newState;
    case SR_REMOVE_ASSIGNMENT:
      if (state.assignments.length === 1 && state.current_page > initialState.current_page) {
        newState = update(state, {
          current_page: { $set: state.current_page - 1 },
        });
      } else {
        newState = state;
      }
      return newState;
    default:
      return state;
  }
};
