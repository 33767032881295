import update from 'immutability-helper';
import { combineReducers } from 'redux';
import { FORM_TEMPLATES } from '../actions';
import { FORM_TEMPLATES_LIST, FORM_TEMPLATES_FILTERS } from './actions';

export const initialState = {
  categories: [],
  tags: [],
  searchValue: '',
  selectedCategories: [],
  selectedTags: [],
  totalRecords: 10,
  pageIndex: 0,
  pageSize: 10,
  isLoadingTableInfo: false,
  pageCount: 0,
  orderDirection: 'desc',
  sortField: 'created_at',
  isLoadingFiltersData: true,
  deletedTemplate: null,
};

export const categoriesReducer = (state = initialState.categories, { type, payload }) => {
  switch (type) {
    case FORM_TEMPLATES.CATEGORIES.SUCCESS:
      return update(state, {
        $set: [...payload.response],
      });
    default:
      return state;
  }
};

export const tagsReducer = (state = initialState.tags, { type, payload }) => {
  switch (type) {
    case FORM_TEMPLATES.TAGS.SUCCESS:
      return update(state, {
        $set: [...payload.response],
      });
    case FORM_TEMPLATES_LIST.UNMOUNT:
      return initialState.tags;
    default:
      return state;
  }
};

export const formTemplatesSearchValueReducer = (state = initialState.searchValue, { type, payload }) => {
  switch (type) {
    case FORM_TEMPLATES_FILTERS.SET_SEARCH_VALUE:
      return update(state, { $set: payload });
    default:
      return state;
  }
};

export const selectedCategoriesReducer = (state = initialState.selectedCategories, { type, payload }) => {
  switch (type) {
    case FORM_TEMPLATES_FILTERS.SET_SELECTED_CATEGORIES:
      return update(state, { $set: payload });
    default:
      return state;
  }
};

export const selectedTagsReducer = (state = initialState.selectedTags, { type, payload }) => {
  switch (type) {
    case FORM_TEMPLATES_FILTERS.SET_SELECTED_TAGS:
      return update(state, { $set: payload });
    default:
      return state;
  }
};

export const totalRecordsReducer = (state = initialState.totalRecords, { type, payload }) => {
  switch (type) {
    case FORM_TEMPLATES_LIST.SET_TOTAL_RECORDS:
      return update(state, { $set: payload });
    default:
      return state;
  }
};

export const pageIndexReducer = (state = initialState.pageIndex, { type, payload }) => {
  switch (type) {
    case FORM_TEMPLATES_LIST.SET_PAGE_INDEX:
      return update(state, { $set: payload });
    default:
      return state;
  }
};

export const pageSizeReducer = (state = initialState.pageSize, { type, payload }) => {
  switch (type) {
    case FORM_TEMPLATES_LIST.SET_PAGE_SIZE:
      return update(state, { $set: payload });
    default:
      return state;
  }
};

export const loadingReducer = (state = initialState.isLoadingTableInfo, { type, payload }) => {
  switch (type) {
    case FORM_TEMPLATES_LIST.SET_IS_LOADING_TABLE_INFO:
      return update(state, { $set: payload });
    default:
      return state;
  }
};

export const isLoadingFiltersDataReducer = (state = initialState.isLoadingFiltersData, { type }) => {
  switch (type) {
    case FORM_TEMPLATES.STOP_LOADING:
      return update(state, { $set: false });
    default:
      return state;
  }
};

export const pageCountReducer = (state = initialState.pageCount, { type, payload }) => {
  switch (type) {
    case FORM_TEMPLATES_LIST.SET_PAGE_COUNT:
      return update(state, { $set: payload });
    default:
      return state;
  }
};

export const orderDirectionReducer = (state = initialState.orderDirection, { type, payload }) => {
  switch (type) {
    case FORM_TEMPLATES_LIST.SET_ORDER_DIRECTION:
      return update(state, { $set: payload });
    default:
      return state;
  }
};

export const sortFieldReducer = (state = initialState.sortField, { type, payload }) => {
  switch (type) {
    case FORM_TEMPLATES_LIST.SET_SORT_FIELD:
      return update(state, { $set: payload });
    default:
      return state;
  }
};

export const deletedTemplateReducer = (state = initialState.deletedTemplate, { type, payload }) => {
  switch (type) {
    case FORM_TEMPLATES_LIST.SET_DELETED_TEMPLATE:
      return update(state, { $set: payload });
    case FORM_TEMPLATES_LIST.UNMOUNT:
      return initialState.deletedTemplate;
    default:
      return state;
  }
};

export default combineReducers({
  isLoadingFiltersData: isLoadingFiltersDataReducer,
  categories: categoriesReducer,
  tags: tagsReducer,
  searchValue: formTemplatesSearchValueReducer,
  selectedCategories: selectedCategoriesReducer,
  selectedTags: selectedTagsReducer,
  totalRecords: totalRecordsReducer,
  pageIndex: pageIndexReducer,
  pageSize: pageSizeReducer,
  isLoadingTableInfo: loadingReducer,
  pageCount: pageCountReducer,
  orderDirection: orderDirectionReducer,
  sortField: sortFieldReducer,
  deletedTemplate: deletedTemplateReducer,
});
