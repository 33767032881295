import React from 'react';
import { func, number, shape } from 'prop-types';
import {
  insuranceAgent,
  trustOrFiduciary,
  isOtherTypeInstitution,
} from 'containers/registration/complete_profile/profileData';
import OtherTypeInstitutionalInvestor from 'containers/registration/complete_profile/sections/OtherTypeInstitutionalInvestor';
import { getIsOtherTypeInstInvNeeded } from 'containers/registration/complete_profile/TwoPageCompleteProfileValidations';
import ProgressBar from './ProgressBar';
import YesNoQuestion from './YesNoQuestion';
import ForwardBackButtons from './ForwardBackButtons';
import StyledDialogContent from './StyledDialogContent';
import { calculateCanCompleteStepFour } from '../helpers/validations';

function RiaUserIdentificationPageFour({
  progress, onNext, onBack, saveInformation, information,
}) {
  const shouldAskIfOtherType = getIsOtherTypeInstInvNeeded(information);

  const canContinue = calculateCanCompleteStepFour(information);

  return (
    <>
      <StyledDialogContent>
        <ProgressBar progress={progress} />

        {/* Insurance Agent */}
        <YesNoQuestion
          informationKey={insuranceAgent.question_key}
          questionText={insuranceAgent.text}
          information={information}
          saveInformation={saveInformation}
        />

        {/* Trust or Fiduciary */}
        <YesNoQuestion
          informationKey={trustOrFiduciary.question_key}
          questionText={trustOrFiduciary.text}
          information={information}
          saveInformation={saveInformation}
        />

        {shouldAskIfOtherType && (
          <>
            <YesNoQuestion
              informationKey={isOtherTypeInstitution.question_key}
              questionText={isOtherTypeInstitution.text}
              information={information}
              saveInformation={saveInformation}
            />
            {information[isOtherTypeInstitution.question_key] && (
              <OtherTypeInstitutionalInvestor
                profileData={information}
                updateStore={saveInformation}
              />
            )}
          </>
        )}

      </StyledDialogContent>
      <ForwardBackButtons onNext={onNext} onBack={onBack} canContinue={canContinue} />
    </>
  );
}

RiaUserIdentificationPageFour.propTypes = {
  progress: number.isRequired,
  saveInformation: func.isRequired,
  onNext: func.isRequired,
  onBack: func.isRequired,
  information: shape({}).isRequired,
};

export default RiaUserIdentificationPageFour;
