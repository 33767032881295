import { DEFINITION_SET_CURRENT_PAGE, DEFINITION_SET_RESULTS_PER_PAGE } from 'actions/actionsConstants';

import update from 'immutability-helper';

import { normalizeCurrentPage } from 'services/paginationUtils';

import initialState from '../initialState';

export default (state = initialState, action) => {
  switch (action.type) {
    case DEFINITION_SET_CURRENT_PAGE: {
      const totalDocs = state.documents_count;
      const perPage = state.results_per_page;
      const currentPage = normalizeCurrentPage(action.payload, totalDocs, perPage);
      return update(state, { current_page: { $set: currentPage } });
    }
    case DEFINITION_SET_RESULTS_PER_PAGE:
      return update(state, {
        results_per_page: { $set: action.payload },
        current_page: { $set: initialState.current_page },
      });
    default:
      return state;
  }
};
