import update from 'immutability-helper';
import _ from 'lodash';

import {
  RGA_SAVE_FILTER_QUERY,
  RGA_SET_STRING_FILTER_OPTIONS,
  RGA_REMOVE_STRING_FILTERED_OPTIONS,
} from 'actions/actionsConstants';

import initialState from 'reducers/report_generator_reducers/initialState';
import {
  retrieveDBKey,
  retrieveExcludedQueries,
  compareItems,
} from 'reducers/report_generator_reducers/utils';

export default (state = initialState, action) => {
  switch (action.type) {
    case RGA_SET_STRING_FILTER_OPTIONS: {
      const { filter, option, nullOption } = action.payload;
      const configurableId = state.configurableId;

      if (!_.isNil(nullOption)) {
        return {
          ...state,
          nullOptions: update(state.nullOptions, {
            [configurableId]: { [filter]: { $set: nullOption } },
          }),
        };
      }

      const unfiltered = (state.options[configurableId][filter] || []).filter(
        (item) => item.id !== option.id,
      );

      const pickedOptions =
        state.filteredOptionsPicked[configurableId][filter] || [];
      const total =
        state.filteredTotals[configurableId][filter] !== undefined
          ? state.filteredTotals[configurableId][filter]
          : 0;
      const filteredOptions = (
        state.filteredOptions[configurableId][filter] || []
      )
        .concat(option)
        .sort(compareItems);
      return {
        ...state,
        options: update(state.options, {
          [configurableId]: { [filter]: { $set: unfiltered } },
        }),
        filteredOptions: update(state.filteredOptions, {
          [configurableId]: { [filter]: { $set: filteredOptions } },
        }),
        filteredTotals: update(state.filteredTotals, {
          [configurableId]: { [filter]: { $set: total + 1 } },
        }),
        filteredOptionsPicked: update(state.filteredOptionsPicked, {
          [configurableId]: { [filter]: { $set: [...pickedOptions, option] } },
        }),
      };
    }
    case RGA_REMOVE_STRING_FILTERED_OPTIONS: {
      const { filter, options } = action.payload;
      const optionIds = options.map((option) => option.id);
      const configurableId = state.configurableId;
      const unfiltered = state.options[configurableId][filter];
      const filtered = state.filteredOptions[configurableId][filter].filter(
        (item) => !optionIds.includes(item.id),
      );

      let pickedOptions =
        state.filteredOptionsPicked[configurableId][filter] || [];
      pickedOptions = pickedOptions.filter(
        (item) => !optionIds.includes(item.id),
      );

      const total = state.filteredTotals[configurableId][filter];
      const updatedOptions = (unfiltered || [])
        .concat(options)
        .sort(compareItems);

      return {
        ...state,
        options: update(state.options, {
          [configurableId]: { [filter]: { $set: updatedOptions } },
        }),
        filteredOptions: update(state.filteredOptions, {
          [configurableId]: { [filter]: { $set: filtered } },
        }),
        filteredTotals: update(state.filteredTotals, {
          [configurableId]: { [filter]: { $set: total - 1 } },
        }),
        filteredOptionsPicked: update(state.filteredOptionsPicked, {
          [configurableId]: { [filter]: { $set: pickedOptions } },
        }),
      };
    }
    case RGA_SAVE_FILTER_QUERY: {
      const { filter } = action.payload;
      const configurableId = state.configurableId;
      const ranges = state.appliedRanges[configurableId][filter] || [];
      const nullOption = state.nullOptions[configurableId][filter] || false;
      const filtered = state.filteredOptions[configurableId][filter];

      const saveObject = {
        chosen_options: [],
        data_type: 'STRING',
        null_option: nullOption,
        ranges,
      };

      if (!filtered && !nullOption) {
        return state;
      }

      const dbMethod = retrieveDBKey(
        filter,
        state.available_fields[configurableId],
      );
      const totalRows =
        state.filteredTotals[configurableId][filter] !== 0
          ? `${state.filteredTotals[configurableId][filter]}`
          : '';

      if (nullOption && !filtered) {
        return {
          ...state,
          stored_queries: update(state.stored_queries, {
            [configurableId]: {
              [filter]: { $set: { ...saveObject, ...dbMethod } },
            },
          }),
          totals: update(state.totals, {
            [configurableId]: { [filter]: { $set: totalRows } },
          }),
        };
      }

      // if some options are selected, then store those options and check if we should negate the query or not
      // that negation depends on either the user has pressed the select all checkbox or not which is tracked in
      // the redux state toggle_tracker
      if (filtered.length !== 0) {
        saveObject.chosen_options = filtered;
      }

      const excludedQueries = retrieveExcludedQueries(
        state.excludedRanges[configurableId],
        state.available_fields[configurableId],
        filter,
        'STRING',
      );

      return {
        ...state,
        initialOptions: update(state.initialOptions, {
          [configurableId]: {
            [filter]: { $set: [...state.options[configurableId][filter]] },
          },
        }),
        stored_queries: update(state.stored_queries, {
          [configurableId]: {
            [filter]: { $set: { ...saveObject, ...dbMethod } },
          },
        }),
        totals: update(state.totals, {
          [configurableId]: { [filter]: { $set: totalRows } },
        }),
        storedExcludedQueries: update(state.storedExcludedQueries, {
          [configurableId]: { [filter]: { $set: excludedQueries } },
        }),
      };
    }

    default:
      return state;
  }
};
