import { createAction } from 'redux-actions';
import { BASE_NAME as ENTRY_BASE_NAME } from '../actions';

export const BASE_NAME = `${ENTRY_BASE_NAME}_list`;

export const FORM_TEMPLATES_LIST = {
  UNMOUNT: `${BASE_NAME}_UNMOUNT`,
  SET_TOTAL_RECORDS: `${BASE_NAME}_SET_TOTAL_RECORDS`,
  SET_PAGE_SIZE: `${BASE_NAME}_SET_PAGE_SIZE`,
  SET_PAGE_INDEX: `${BASE_NAME}_SET_PAGE_INDEX`,
  SET_IS_LOADING_TABLE_INFO: `${BASE_NAME}_SET_IS_LOADING_TABLE_INFO`,
  SET_PAGE_COUNT: `${BASE_NAME}_SET_PAGE_COUNT`,
  SET_ORDER_DIRECTION: `${BASE_NAME}_SET_ORDER_DIRECTION`,
  SET_SORT_FIELD: `${BASE_NAME}_SET_SORT_FIELD`,
  SET_DELETED_TEMPLATE: `${BASE_NAME}SET_DELETED_TEMPLATE`,
};

export const FORM_TEMPLATES_FILTERS = {
  SET_SEARCH_VALUE: `${BASE_NAME}_SET_SEARCH_VALUE`,
  SET_SELECTED_CATEGORIES: `${BASE_NAME}_SET_SELECTED_CATEGORIES`,
  SET_SELECTED_TAGS: `${BASE_NAME}_SET_SELECTED_TAGS`,
};

const setSearchValue = createAction(FORM_TEMPLATES_FILTERS.SET_SEARCH_VALUE);

const setSelectedCategories = createAction(FORM_TEMPLATES_FILTERS.SET_SELECTED_CATEGORIES);

const setSelectedTags = createAction(FORM_TEMPLATES_FILTERS.SET_SELECTED_TAGS);

export const setPageIndex = createAction(FORM_TEMPLATES_LIST.SET_PAGE_INDEX);

export const setPageSize = createAction(FORM_TEMPLATES_LIST.SET_PAGE_SIZE);

export const setTotal = createAction(FORM_TEMPLATES_LIST.SET_TOTAL_RECORDS);

export const setIsLoadingTableInfo = createAction(FORM_TEMPLATES_LIST.SET_IS_LOADING_TABLE_INFO);

export const setPageCount = createAction(FORM_TEMPLATES_LIST.SET_PAGE_COUNT);

export const setOrderDirection = createAction(FORM_TEMPLATES_LIST.SET_ORDER_DIRECTION);

export const setSortField = createAction(FORM_TEMPLATES_LIST.SET_SORT_FIELD);

export const setDeletedTemplate = createAction(FORM_TEMPLATES_LIST.SET_DELETED_TEMPLATE);

export const onUnmount = createAction(FORM_TEMPLATES_LIST.UNMOUNT);

export default {
  onUnmount,
  setSearchValue,
  setSelectedCategories,
  setSelectedTags,
  setPageIndex,
  setPageSize,
  setTotal,
  setIsLoadingTableInfo,
  setPageCount,
  setOrderDirection,
  setSortField,
  setDeletedTemplate,
};
