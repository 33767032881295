import update from 'immutability-helper';

import {
  SECURE_DOCUMENT_TRANSFER_SET_TRANSFER_OPTIONS,
  SECURE_DOCUMENT_TRANSFER_SET_DOWNLOAD_DOCUMENTS,
  SECURE_DOCUMENT_TRANSFER_SET_AUTHENTICATION_CODE,
  SECURE_DOCUMENT_TRANSFER_SET_VERIFY_CODE,
  SECURE_DOCUMENT_TRANSFER_SET_PAGE_STATE,
} from 'actions/actionsConstants';
import {
  PAGE_LOADING,
} from 'containers/secure_document_transfer/entry/pageStates.js';

export const initialState = {
  verify_code: '',
  name: '',
  download_page_table_heading: '',
  download_page_message: '',
  email: '',
  download_documents: [],
  authentication_token: '',
  has_finished_loading: false,
  page_state: PAGE_LOADING,
};

export default function secureDocumentTransferReducer(state = initialState, action) {
  switch (action.type) {
    case SECURE_DOCUMENT_TRANSFER_SET_TRANSFER_OPTIONS:
      return update(state, {
        name: { $set: action.payload.name },
        download_page_table_heading: { $set: action.payload.download_page_table_heading },
        download_page_message: { $set: action.payload.download_page_message },
        email: { $set: action.payload.email },
        title: { $set: action.payload.title },
      });
    case SECURE_DOCUMENT_TRANSFER_SET_DOWNLOAD_DOCUMENTS:
      return update(state, {
        download_documents: { $set: action.payload },
      });
    case SECURE_DOCUMENT_TRANSFER_SET_AUTHENTICATION_CODE:
      return update(state, {
        authentication_token: { $set: action.payload },
      });
    case SECURE_DOCUMENT_TRANSFER_SET_VERIFY_CODE:
      return update(state, {
        verify_code: { $set: action.payload },
      });
    case SECURE_DOCUMENT_TRANSFER_SET_PAGE_STATE:
      return update(state, {
        page_state: { $set: action.payload },
      });
    default:
      return state;
  }
}
