import update from 'immutability-helper';

import {
  RG_SCHEDULE_LOGS_RESET_FILTERS,
  RG_SCHEDULE_LOGS_SET_CURRENT_PAGE,
  RG_SCHEDULE_LOGS_SET_FILTERS,
  RG_SCHEDULE_LOGS_SET_RESULTS_PER_PAGE,
  RG_SCHEDULE_LOGS_SET_SORTING,
} from 'actions/actionsConstants';
import { normalizeCurrentPage } from 'services/paginationUtils';

import initialState from '../initialState';

export default (state = initialState, action) => {
  switch (action.type) {
    case RG_SCHEDULE_LOGS_SET_RESULTS_PER_PAGE:
      return update(state, {
        resultsPerPage: { $set: action.payload },
        currentPage: { $set: initialState.currentPage },
      });
    case RG_SCHEDULE_LOGS_SET_CURRENT_PAGE: {
      const { scheduleExecutionLogsCount, resultsPerPage } = state;

      return update(state, {
        currentPage: {
          $set: normalizeCurrentPage(
            action.payload,
            scheduleExecutionLogsCount,
            resultsPerPage,
          ),
        },
      });
    }
    case RG_SCHEDULE_LOGS_SET_SORTING: {
      const { sortingColumn, sortingOrder } = action.payload;

      return update(state, {
        sortingColumn: { $set: sortingColumn },
        sortingOrder: { $set: sortingOrder },
      });
    }
    case RG_SCHEDULE_LOGS_SET_FILTERS:
      return update(state, {
        filters: { $set: action.payload },
        currentPage: { $set: initialState.currentPage },
      });
    case RG_SCHEDULE_LOGS_RESET_FILTERS:
      return update(state, { filters: { $set: initialState.filters } });
    default:
      return state;
  }
};
