import axios from 'axios';
import {
  DC_SET_SAVED_VIEWS,
  DC_SET_SAVED_VIEWS_SORT,
  DC_SET_ACTIVE_SAVED_VIEW,
} from 'actions/actionsConstants';
import spinnerActions from 'actions/spinner_actions/spinnerActions';
import dcResultsActions from 'actions/doc_center_actions/doc_center_results_actions/dcResultsActions';
import dcFilterActions from 'actions/doc_center_actions/doc_center_filter_actions/dcFilterActions';
import dcFilterListActions from 'actions/doc_center_actions/doc_center_filter_actions/dcFilterListActions';

const {
  beginApiRequest,
  endApiRequest,
} = spinnerActions;

const API_BASE_URL = 'user_saved_views';
const PAGE_DEFAULT_SLUG = 'doc_center';
const PAGE_OPERATION_DOCS_SLUG = 'doc_center_operation_docs';

// transform JSON to whatever reducer expects
function dcSetSavedViews(json) {
  return {
    type: DC_SET_SAVED_VIEWS,
    payload: json,
  };
}

function dcSetSavedViewsSort(sort) {
  return {
    type: DC_SET_SAVED_VIEWS_SORT,
    payload: sort,
  };
}

function dcSetActiveSavedView(id) {
  return {
    type: DC_SET_ACTIVE_SAVED_VIEW,
    payload: id,
  };
}

function dcLoadAllSavedViews() {
  return (dispatch, getState) => {
    const state = getState();
    const view_operation_docs = state.dcResults.view_operation_docs;
    dispatch(beginApiRequest());

    return axios({
      url: API_BASE_URL,
      method: 'get',
      withCredentials: true,
      params: {
        sort_on: state.dcSavedViews.sort.sort_field,
        page_slug: view_operation_docs ? PAGE_OPERATION_DOCS_SLUG : PAGE_DEFAULT_SLUG,
      },
    })
      .then((response) => {
        dispatch(dcSetSavedViews({ saved_views: response.data }));
        dispatch(endApiRequest());
      })
      .catch(() => {
        dispatch(endApiRequest());
      });
  };
}

function dcUpdateSavedView(viewId, name) {
  return (dispatch) => {
    dispatch(beginApiRequest());

    return new Promise((resolve, reject) => {
      axios({
        url: `${API_BASE_URL}/${viewId}`,
        method: 'put',
        withCredentials: true,
        data: {
          name,
        },
      })
        .then(() => {
          dispatch(dcLoadAllSavedViews());
          dispatch(endApiRequest());
          resolve();
        })
        .catch((e) => {
          dispatch(endApiRequest());
          reject(e);
        });
    });
  };
}

function dcRemoveSavedView(viewId) {
  return (dispatch, getState) => {
    dispatch(beginApiRequest());
    const currentSavedViewId = getState().dcSavedViews.active_saved_view_id;

    return new Promise((resolve, reject) => {
      axios({
        url: `${API_BASE_URL}/${viewId}`,
        method: 'delete',
        withCredentials: true,
        data: {},
      })
        .then(() => {
          if (currentSavedViewId === viewId) {
            dispatch(dcFilterActions.removeAllDcFilter());
            dispatch(dcResultsActions.dcSetPage());
            dispatch(dcResultsActions.dcGetResults());
          }

          dispatch(dcLoadAllSavedViews());
          dispatch(endApiRequest());
          resolve();
        })
        .catch((e) => {
          dispatch(endApiRequest());
          reject(e);
        });
    });
  };
}

function dcLoadSavedView(viewId) {
  return (dispatch) => {
    dispatch(beginApiRequest());

    return new Promise((resolve, reject) => {
      axios({
        url: `${API_BASE_URL}/${viewId}`,
        method: 'get',
        withCredentials: true,
        params: {},
      })

        .then((response) => {
          const filters = response.data.filters.drop_down_filters;
          /*
            - remove active filters
            - set all the filters from saved view
            - call API for Doc Center
            - set sequence from saved view
            - set view id
          */
          dispatch(dcFilterActions.removeAllDcFilter());
          filters.forEach((filter) => {
            // transform name of filter to what frontend expects
            let ft = filter.filter_type;
            if (filter.filters.length
              && (ft === 'effective_date' || ft === 'publish_date')) {
              dispatch(dcFilterActions.changeDcDatePickerDateType(ft));
              ft = 'date';
            }
            dispatch(
              dcFilterActions.setDcFilters(ft, filter.filters)
            );
          });
          dispatch(dcResultsActions.dcGetResults());
          dispatch(dcFilterActions.setDcSequence(response.data.filters.sequence));

          // important for this to happen after filters are set.
          // Modifying a filter needs to clear the active_saved_view_id.
          dispatch(dcSetActiveSavedView(viewId));
          dispatch(endApiRequest());
          resolve();
        })
        .catch((e) => {
          dispatch(endApiRequest());
          reject(e);
        });
    });
  };
}

// called from component, resolves/reject promise
function dcCreateSavedView(name) {
  return (dispatch, getState) => {
    dispatch(beginApiRequest());
    const state = getState();
    const { filters } = dcFilterListActions.dcGetSelectedFilters(state, false);
    const { sort_direction, sort_field } = dcResultsActions.dcGetSort(state);
    const { sequence } = state.dcFilters;
    const perPage = dcResultsActions.dcGetPerPage(state);
    const page = dcResultsActions.dcGetPage(state);
    const view_operation_docs = state.dcResults.view_operation_docs;

    return new Promise((resolve, reject) => {
      axios({
        url: API_BASE_URL,
        method: 'post',
        withCredentials: true,
        data: {
          name,
          filters,
          sort_direction,
          sort_field,
          sequence,
          perPage,
          page,
          page_slug: view_operation_docs ? PAGE_OPERATION_DOCS_SLUG : PAGE_DEFAULT_SLUG,
        },
      })
        .then(() => {
          dispatch(dcLoadAllSavedViews());
          dispatch(endApiRequest());
          resolve();
        }, (e) => {
          dispatch(endApiRequest());
          reject(e);
        });
    });
  };
}


export default {
  dcLoadSavedView,
  dcLoadAllSavedViews,
  dcSetSavedViews,
  dcSetSavedViewsSort,
  dcUpdateSavedView,
  dcCreateSavedView,
  dcRemoveSavedView,
  dcSetActiveSavedView,
};
