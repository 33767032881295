/* eslint-disable max-statements */
import update from 'immutability-helper';
import {
  LP_SET_ANNOUNCEMENT,
  LP_SET_ANNOUNCEMENT_FUNDS,
  LP_SET_ANNOUNCEMENT_EDIT,
  LP_SET_ANNOUNCEMENT_NEW,
  LP_SET_ANNOUNCEMENT_EDIT_DIALOG_IS_OPEN,
  LP_SET_ANNOUNCEMENT_EDIT_CHECK_FOR_ERRORS,
  LP_SET_ANNOUNCEMENT_EDIT_UPDATED_ANNOUCEMENT,
  LP_SET_ANNOUNCEMENT_EDIT_MISSING_CATEGORIES_DATA,
  LP_SET_ANNOUNCEMENT_EDIT_ID,
  LP_SET_ANNOUNCEMENT_EDIT_MODE,
} from 'modules/landing_page/actions/constants';
import initialState from '../initialState';

// eslint-disable-next-line default-param-last
export default (state = initialState, { type, payload = {} }) => {
  switch (type) {
    case LP_SET_ANNOUNCEMENT: {
      const { visible_date: date, image_url: image, ...announcement } = payload;
      return update(state, { announcement: { $set: { ...announcement, date, image } } });
    }
    case LP_SET_ANNOUNCEMENT_FUNDS: {
      const { funds } = payload;
      return update(state, { announcementFunds: { $set: funds } });
    }
    case LP_SET_ANNOUNCEMENT_EDIT: {
      return update(state, {
        edit: {
          initialAnnouncement: { $set: { ...payload.announcement } },
          options: { $set: { ...payload.options } },
          checkForErrors: { $set: false },
          redirectToHome: { $set: false },
        },
      });
    }
    case LP_SET_ANNOUNCEMENT_NEW: {
      return update(state, {
        edit: {
          initialAnnouncement: { $set: { ...initialState.edit.initialAnnouncement } },
          options: { $set: { ...payload.options } },
          checkForErrors: { $set: false },
          redirectToHome: { $set: false },
        },
      });
    }
    case LP_SET_ANNOUNCEMENT_EDIT_DIALOG_IS_OPEN: {
      return update(state, {
        edit: {
          dialogOpen: { $set: payload },
        },
      });
    }
    case LP_SET_ANNOUNCEMENT_EDIT_UPDATED_ANNOUCEMENT: {
      return update(state, {
        edit: {
          updatedAnnouncement: { $set: payload },
        },
      });
    }
    case LP_SET_ANNOUNCEMENT_EDIT_CHECK_FOR_ERRORS: {
      return update(state, {
        edit: {
          checkForErrors: { $set: payload },
        },
      });
    }
    case LP_SET_ANNOUNCEMENT_EDIT_MISSING_CATEGORIES_DATA: {
      return update(state, {
        edit: {
          missingCategoriesData: { $set: payload },
        },
      });
    }
    case LP_SET_ANNOUNCEMENT_EDIT_ID: {
      return update(state, {
        edit: {
          announcementId: { $set: payload },
        },
      });
    }
    case LP_SET_ANNOUNCEMENT_EDIT_MODE: {
      return update(state, {
        edit: {
          mode: { $set: payload },
        },
      });
    }
    default:
      return state;
  }
};
