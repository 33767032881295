import filterReducer from './filterReducer';
import whiteLabelPartnersReducer from './whiteLabelPartnersReducer';
import firmsReducer from './firmsReducer';
import fundsReducer from './fundsReducer';
import modalReducer from './modalReducer';

export default {
  filterReducer,
  whiteLabelPartnersReducer,
  firmsReducer,
  fundsReducer,
  modalReducer,
};
