import {
  INVESTMENT_APPROVAL_STATUS_OVERRIDES,
  GET_AGREEMENT_STATUS_LABELS,
  INVESTMENT_AGREEMENT_SIGN_METHOD,
  INVESTMENT_REVERTED_STATUS,
  CM_TABLE_STATUS,
} from 'constants/index';

import { agreementText } from 'components/client_management/cell_renderers/agreement/helpers';

/* Investment Status */
const reverted = INVESTMENT_APPROVAL_STATUS_OVERRIDES.REVERTED;
const preApproval = INVESTMENT_APPROVAL_STATUS_OVERRIDES.PRE_APPROVAL;
const preApprovalSecondary = INVESTMENT_APPROVAL_STATUS_OVERRIDES.PRE_APPROVAL_SECONDARY;
const pendingReview = INVESTMENT_APPROVAL_STATUS_OVERRIDES.PENDING_REVIEW;
const externalReview = INVESTMENT_APPROVAL_STATUS_OVERRIDES.EXTERNAL_REVIEW;
const externalSecondaryReview = INVESTMENT_APPROVAL_STATUS_OVERRIDES.EXTERNAL_SECONDARY_REVIEW;
const irReview = INVESTMENT_APPROVAL_STATUS_OVERRIDES.IR_REVIEW;
const complianceReview = INVESTMENT_APPROVAL_STATUS_OVERRIDES.COMPLIANCE_REVIEW;
const approved = INVESTMENT_APPROVAL_STATUS_OVERRIDES.APPROVED;
const accepted = INVESTMENT_APPROVAL_STATUS_OVERRIDES.ACCEPTED;
const autoReverted = INVESTMENT_APPROVAL_STATUS_OVERRIDES.INVESTMENT_AUTO_REVERTED;
const transfers = CM_TABLE_STATUS.TRANSFERS;
const lettersOfAuthorization = CM_TABLE_STATUS.LETTERS_OF_AUTHORIZATION;
const redemptions = CM_TABLE_STATUS.REDEMPTIONS;

/* Reverted Statuses */
const revInProgress = INVESTMENT_REVERTED_STATUS.IN_PROGRESS;
const revResubmit = INVESTMENT_REVERTED_STATUS.RESUBMIT;
const revNew = INVESTMENT_REVERTED_STATUS.NEW;

export const investmentStatus = {
  reverted,
  preApproval,
  preApprovalSecondary,
  pendingReview,
  externalReview,
  externalSecondaryReview,
  irReview,
  complianceReview,
  approved,
  accepted,
  transfers,
  autoReverted,
  redemptions,
};

export const isPreApprovalStatus = (status) => status === preApproval;

export const isPreApprovalSecondaryStatus = (status) => status === preApprovalSecondary;

export const isRevertedStatus = (status) => status === reverted;

export const isPendingDocumentStatus = (status) => status === pendingReview;

export const isExternalReviewStatus = (status) => status === externalReview;

export const isExternalSecondaryReviewStatus = (status) => status === externalSecondaryReview;

export const isIRReviewStatus = (status) => status === irReview;

export const isComplianceReviewStatus = (status) => status === complianceReview;

export const isApprovedStatus = (status) => status === approved;

export const isAcceptedStatus = (status) => status === accepted;

export const isTransferStatus = (status) => status === transfers;

const reviewStatuses = [
  isExternalReviewStatus,
  isExternalSecondaryReviewStatus,
  isComplianceReviewStatus,
  isIRReviewStatus,
];
export const isReviewStatus = (status) => reviewStatuses.some((isStatus) => isStatus(status));

// isPendingReview is obtained from Investment/TransferInvestment serializer, through the pending_review flag,
// which is true if a Transfer's "review_status" is set to "pending"
export const isReviewStatusPendingTransfer = (status, isPendingReviewTransfer) =>
  isTransferStatus(status) && isPendingReviewTransfer;

export const isAutoRevertedStatus = (status) => status === autoReverted;

export const isAttentionRequiredStatus = (status) => isRevertedStatus(status) || isAutoRevertedStatus(status);

export const isLOAStatus = (status) => status === lettersOfAuthorization;

export const isRedemptionsStatus = (status) => status === redemptions;

export const isInRegularStatus = (status) =>
  !(
    isRevertedStatus(status) ||
    isAutoRevertedStatus(status) ||
    isPreApprovalStatus(status) ||
    isPreApprovalSecondaryStatus(status)
  );

/* Agreement Signature Statuses */
export const isAgreementSignedStatus = (investment, user) =>
  agreementText(investment, user) === GET_AGREEMENT_STATUS_LABELS().SIGNED;
export const isAgreementPendingStatus = (investment, user) =>
  agreementText(investment, user) === GET_AGREEMENT_STATUS_LABELS().PENDING_SIGNATURE;
export const isAgreementUploadStatus = (investment, user) =>
  agreementText(investment, user) === GET_AGREEMENT_STATUS_LABELS().UPLOAD_SUBSCRIPTION;
export const isAgreementToSend = (investment, user) =>
  [
    GET_AGREEMENT_STATUS_LABELS().NOT_SENT,
    GET_AGREEMENT_STATUS_LABELS().SEND_SUBSCRIPTION,
    GET_AGREEMENT_STATUS_LABELS().SEND_DOCUMENT,
  ].includes(agreementText(investment, user));
export const isAgreementNotGenerated = (investment, user) =>
  [
    GET_AGREEMENT_STATUS_LABELS().NOT_SENT,
    GET_AGREEMENT_STATUS_LABELS().SEND_SUBSCRIPTION,
    GET_AGREEMENT_STATUS_LABELS().SEND_DOCUMENT,
    GET_AGREEMENT_STATUS_LABELS().GENERATING_AGREEMENT,
  ].includes(agreementText(investment, user));

export const isTransferAgreementSignedStatus = (investment, user) =>
  agreementText(investment, user, true) === GET_AGREEMENT_STATUS_LABELS().SIGNED;
export const isTransferAgreementPendingStatus = (investment, user) =>
  agreementText(investment, user, true) === GET_AGREEMENT_STATUS_LABELS().PENDING_SIGNATURE;
export const isTransferAgreementUploadStatus = (investment, user) =>
  agreementText(investment, user, true) === GET_AGREEMENT_STATUS_LABELS().UPLOAD_TRANSFER_DOCUMENT;
export const isTransferAgreementToSend = (investment, user) => {
  return [GET_AGREEMENT_STATUS_LABELS().NOT_SENT, GET_AGREEMENT_STATUS_LABELS().SEND_DOCUMENT].includes(
    agreementText(investment, user, true)
  );
};
export const isTransferAgreementNotGenerated = (investment, user) =>
  [
    GET_AGREEMENT_STATUS_LABELS().NOT_SENT,
    GET_AGREEMENT_STATUS_LABELS().SEND_DOCUMENT,
    GET_AGREEMENT_STATUS_LABELS().GENERATING_AGREEMENT,
  ].includes(agreementText(investment, user, true));
export const isTransferAgreementPendingCompletionStatus = (investment, user) =>
  agreementText(investment, user, true) === GET_AGREEMENT_STATUS_LABELS().PENDING_COMPLETION;
export const isTransferAgreementToUpload = (investment, user) =>
  ![
    GET_AGREEMENT_STATUS_LABELS().NOT_SENT,
    GET_AGREEMENT_STATUS_LABELS().SEND_DOCUMENT,
    GET_AGREEMENT_STATUS_LABELS().GENERATING_AGREEMENT,
  ].includes(agreementText(investment, user, true));

/* Agreement Signature Methods */
export const isElectronicSignMethod = (signMethod) => signMethod === INVESTMENT_AGREEMENT_SIGN_METHOD.ELECTRONIC;
export const isManualSignMethod = (signMethod) => signMethod === INVESTMENT_AGREEMENT_SIGN_METHOD.MANUAL;

/* Investment Reverted Status */
export const isRevertedNewStatus = (revertedStatus) => revertedStatus === revNew;
export const isRevertedInProgressStatus = (revertedStatus) => revertedStatus === revInProgress;
export const isRevertedResubmitStatus = (revertedStatus) => revertedStatus === revResubmit;
