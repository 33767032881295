import update from 'immutability-helper';
import {
  ED_SET_LOG_DATA,
  ED_SET_LOG_CURRENT_PAGE,
  ED_SET_LOG_IS_LOADING_FIRST_PAGE,
  ED_SET_LOG_REQUEST_MADE,
  ED_SET_LOG_SORT,
  ED_SET_LOG_SEARCH_TERMS,
  ED_SET_LOG_PAGE_ACTIONS,
  ED_SET_LOG_SELECTED_IDS,
} from '../actions/constants';
import initialState from './initialState';

export default (state = initialState, action) => {
  switch (action.type) {
    case ED_SET_LOG_DATA: {
      return update(state, {
        logData: {
          currentPage: { $set: action.payload.current_page },
          data: { $set: action.payload.data },
          filterOptions: { $set: action.payload.wlp_filter_options },
          filterSelectedValues: { $set: action.payload.filters_selected_values },
          filterValues: { $set: action.payload.filters },
          hasNextPage: { $set: action.payload.has_next_page },
          infoMessage: { $set: action.payload.info_message },
          isLoadingFirstPage: { $set: false },
          requestMade: { $set: false },
          resultsPerPage: { $set: action.payload.results_per_page },
          totalCount: { $set: action.payload.total_count },
        },
      });
    }

    case ED_SET_LOG_PAGE_ACTIONS: {
      return update(state, { logData: { pageActions: { $merge: action.payload } } });
    }

    case ED_SET_LOG_CURRENT_PAGE: {
      return update(state, { logData: { currentPage: { $set: action.payload } } });
    }

    case ED_SET_LOG_IS_LOADING_FIRST_PAGE: {
      return update(state, { logData: { isLoadingFirstPage: { $set: action.payload } } });
    }

    case ED_SET_LOG_REQUEST_MADE: {
      return update(state, { logData: { requestMade: { $set: action.payload } } });
    }

    case ED_SET_LOG_SORT: {
      return update(state, { logData: { pageActions: { sort: { $set: action.payload } } } });
    }

    case ED_SET_LOG_SEARCH_TERMS: {
      return update(state, { logData: { pageActions: { searchTerms: { $set: action.payload } } } });
    }

    case ED_SET_LOG_SELECTED_IDS: {
      return update(state, { logData: { pageActions: { filters: { ids: { $set: action.payload } } } } });
    }

    default:
      return state;
  }
};
