import { actionFactory } from 'services/actions';
import { patchRequest } from 'actions/utils';
import { RGA_SAVE_FILE_NAME } from 'actions/actionsConstants';

const BASE_URL = 'report_generator/configs';
const reportGeneratorConfigsSaveFileNameSuccess =
  actionFactory(RGA_SAVE_FILE_NAME);

function reportGeneratorConfigsSaveFileName(updateConfig, isFilename = false) {
  return (dispatch, getState) => {
    const storeConfig = getState().reportGeneratorConfigs.current_configs;
    return dispatch(
      patchRequest({
        url: `/${BASE_URL}/${storeConfig.id}`,
        data: {
          report_generator_config: updateConfig,
        },
        onSuccess: () =>
          reportGeneratorConfigsSaveFileNameSuccess(updateConfig),
        successMessage: {
          message: `Report ${isFilename ? 'file' : ''}name saved successfully`,
        },
        errorMessage: {
          title: 'Error',
          message: `Failed to save report ${isFilename ? 'file' : ''}name`,
        },
      }),
    );
  };
}

export default {
  reportGeneratorConfigsSaveFileName,
};
