import { RGA_LOAD_CONFIGS_SUCCESS } from 'actions/actionsConstants';

import update from 'immutability-helper';

import initialState from 'reducers/report_generator_reducers/initialState';

export default (state = initialState, action) => {
  switch (action.type) {
    case RGA_LOAD_CONFIGS_SUCCESS:
      return update(state, {
        configs: { $set: action.payload },
        reportExecutedQuery: { $set: '' },
      });
    default:
      return state;
  }
};
