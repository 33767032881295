import { actionFactory } from 'services/actions';
import { getRequest } from 'actions/utils';
import {
  RGA_SET_REPORT_DATA,
  RGA_RESET_REPORT_DATA,
  RGA_SET_DATA_FETCH_IN_PROGRESS,
} from 'actions/actionsConstants';
import { getCurrentConfigId } from 'reducers/report_generator_reducers/selectors';

import {
  getWorkerStatus,
  reportGeneratorConfigsWorkerFinished,
  reportGeneratorConfigsWorkerStarted,
  updateReportGeneratorConfigsWorkerStatus,
} from './workerActions';
import notificationService from '../../services/notifications';

const BASE_URL = 'report_generator/views';
const rgaSetReportGeneratorViewData = actionFactory(RGA_SET_REPORT_DATA);
const rgaResetReportGeneratorViewData = actionFactory(RGA_RESET_REPORT_DATA);
const rgaSetDataFetchInProgress = actionFactory(RGA_SET_DATA_FETCH_IN_PROGRESS);

function _processViewDataResponse(data) {
  return (dispatch) => {
    if (data.response_type === 'success') {
      dispatch(rgaSetReportGeneratorViewData(data));
    } else if (data.response_type === 'error') {
      dispatch(rgaSetDataFetchInProgress(false));
      notificationService.notifyError('An error has occurred');
    } else {
      setTimeout(() => dispatch(getReportGeneratorViewData()), 1000);
    }
  };
}

function getReportGeneratorViewData(superUser = false) {
  return (dispatch, getState) => {
    const configId = getCurrentConfigId(getState());
    return dispatch(
      getRequest({
        url: `${BASE_URL}/${configId}`,
        params: {
          super_user: superUser || undefined,
        },
        onSuccess: _processViewDataResponse,
        onFailure: rgaResetReportGeneratorViewData,
        showSpinner: false,
      }),
    );
  };
}

function fetchViewData(superUser = false) {
  return (dispatch, _) => {
    dispatch(rgaResetReportGeneratorViewData());
    dispatch(rgaSetDataFetchInProgress(true));
    return dispatch(getReportGeneratorViewData(superUser));
  };
}

function downloadReportData(configId = null, superUser = false) {
  return (dispatch, getState) => {
    let currentConfigId = configId;
    if (!currentConfigId) {
      currentConfigId = getCurrentConfigId(getState());
    }

    return dispatch(
      getRequest({
        url: `${BASE_URL}/${currentConfigId}/download`,
        params: { super_user: superUser || undefined },
        onSuccess: reportGeneratorConfigsWorkerStarted,
        showSpinner: false,
      }),
    );
  };
}

export default {
  fetchViewData,
  downloadReportData,
};
