import {
  AS_LOAD_REPRESENTATIONS_SUCCESS,
  AS_LOAD_REPRESENTATIONS_COUNT,
  AS_REMOVE_REPRESENTATION,
} from 'actions/actionsConstants';

import update from 'immutability-helper';

import reducers from './reducers';
import initialState from './initialState';

const loadingReducers = (state = initialState, action) => {
  switch (action.type) {
    case AS_LOAD_REPRESENTATIONS_SUCCESS:
      return update(state, { representations: { $set: action.payload } });
    case AS_LOAD_REPRESENTATIONS_COUNT:
      return update(state, { representations_count: { $set: action.payload } });
    case AS_REMOVE_REPRESENTATION:
      if (state.representations.length !== 1 || state.current_page <= initialState.current_page) {
        return state;
      }
      return update(state, {
        current_page: { $set: state.current_page - 1 },
      });
    default:
      return state;
  }
};

const fn = [
  reducers.filterReducer,
  reducers.whiteLabelPartnersReducer,
  reducers.firmsReducer,
  reducers.fundsReducer,
  reducers.modalReducer,
  loadingReducers,
];

const reducer = (state = initialState, action) => (
  fn.reduce((stateAccumulator, fnc) => fnc(stateAccumulator, action), state)
);

export default reducer;
