import {
    DC_SET_RESULTS_PER_PAGE,
} from 'actions/actionsConstants'

import update from 'immutability-helper';

const initialState = {
    results_per_page: 25,
};

export default (state = initialState, action) => {
    switch (action.type) {
    case DC_SET_RESULTS_PER_PAGE:
        return update(state, {
            results_per_page: {
                $set: action.payload
            }
        });

    default:
        return state;
    }
};
