import notificationService from 'services/notifications';

import {
  RG_SCHEDULE_LOGS_RESET_FILTERS,
  RG_SCHEDULE_LOGS_SET_CURRENT_PAGE,
  RG_SCHEDULE_LOGS_SET_FILTERS,
  RG_SCHEDULE_LOGS_SET_RESULTS_PER_PAGE,
  RG_SCHEDULE_LOGS_SET_SORTING,
  RG_SET_SCHEDULE_EXECUTION_LOGS,
  RG_SET_SCHEDULE_EXECUTION_LOGS_COUNT,
  RG_UPDATE_SCHEDULE_EXECUTION_LOG,
} from 'actions/actionsConstants';
import { getRequest, putRequest } from 'actions/utils';
import { actionFactory } from 'services/actions';

const BASE_URL = 'report_generator/schedule_execution_logs';
const rgSetScheduleExecutionLogs = actionFactory(
  RG_SET_SCHEDULE_EXECUTION_LOGS,
);
const rgSetScheduleExecutionLogsCount = actionFactory(
  RG_SET_SCHEDULE_EXECUTION_LOGS_COUNT,
);

const rgUpdateScheduleExecutionLog = actionFactory(
  RG_UPDATE_SCHEDULE_EXECUTION_LOG,
);
const onReportScheduleLogsSuccess = (data) => (dispatch) => {
  dispatch(rgSetScheduleExecutionLogs(data.schedule_execution_logs));
  dispatch(rgSetScheduleExecutionLogsCount(data.total));
};

const onStopScheduleExecutionSuccess = (data) => (dispatch) =>
  dispatch(rgUpdateScheduleExecutionLog(data));

const rgStopScheduleExecution = (id) => (dispatch) => {
  return dispatch(
    putRequest({
      url: `${BASE_URL}/${id}`,
      data: { event_type: 'schedule_execution_stop' },
      onSuccess: onStopScheduleExecutionSuccess,
      successMessage: {
        message:
          'Successfully stopped execution of current report delivery job.',
      },
      onFailure: ({ data }) => notificationService.notifyError(data?.message),
      showSpinner: true,
    }),
  );
};

const rgFetchScheduleExecutionLogs = () => (dispatch, getState) => {
  const state = getState().reportScheduleLogs;
  const { currentPage, resultsPerPage, sortingColumn, sortingOrder, filters } =
    state;

  return dispatch(
    getRequest({
      url: BASE_URL,
      params: {
        locked_config_id: filters.lockedConfigId,
        open_config_id: filters.openConfigId,
        status: filters.status,
        scheduled_at: filters.scheduledAt,
        page: currentPage,
        per_page: resultsPerPage,
        sorting_column: sortingColumn,
        sorting_order: sortingOrder,
      },
      onSuccess: onReportScheduleLogsSuccess,
    }),
  );
};

const setScheduleExecutionLogPerPage = (resultsPerPage) => (dispatch) => {
  dispatch({
    type: RG_SCHEDULE_LOGS_SET_RESULTS_PER_PAGE,
    payload: resultsPerPage,
  });
  return dispatch(rgFetchScheduleExecutionLogs());
};
const setScheduleExecutionLogCurrentPage = (currentPage) => (dispatch) => {
  dispatch({
    type: RG_SCHEDULE_LOGS_SET_CURRENT_PAGE,
    payload: currentPage,
  });
  return dispatch(rgFetchScheduleExecutionLogs());
};
const setScheduleExecutionLogSorting =
  (sortingColumn, sortingOrder) => (dispatch) => {
    dispatch({
      type: RG_SCHEDULE_LOGS_SET_SORTING,
      payload: { sortingColumn, sortingOrder },
    });
    return dispatch(rgFetchScheduleExecutionLogs());
  };
const rgSetScheduleExecutionLogFilters = (filters) => (dispatch) => {
  dispatch({
    type: RG_SCHEDULE_LOGS_SET_FILTERS,
    payload: filters,
  });
  return dispatch(rgFetchScheduleExecutionLogs());
};
const rgResetScheduleExecutionLogFilters = () => (dispatch) =>
  dispatch({
    type: RG_SCHEDULE_LOGS_RESET_FILTERS,
    payload: {},
  });

export default {
  rgFetchScheduleExecutionLogs,
  setScheduleExecutionLogPerPage,
  setScheduleExecutionLogCurrentPage,
  setScheduleExecutionLogSorting,
  rgSetScheduleExecutionLogFilters,
  rgResetScheduleExecutionLogFilters,
  rgStopScheduleExecution,
};
