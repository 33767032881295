import { actionFactory } from 'services/actions';
import {
  RGA_SAVE_FILTER_BOOLEAN,
  RGA_CHANGE_BOOLEAN_FILTER,
  RGA_CLOSE_FIELD_FILTER,
  RG_RESET_BOOLEAN_FILTER,
} from 'actions/actionsConstants';
import configFieldActions from '../configFieldActions';

const saveFilter = actionFactory(RGA_SAVE_FILTER_BOOLEAN);
const closeFilter = actionFactory(RGA_CLOSE_FIELD_FILTER);

export const resetBooleanFilter = actionFactory(RG_RESET_BOOLEAN_FILTER);

export const reportGeneratorConfigsChangeBooleanFilter = actionFactory(
  RGA_CHANGE_BOOLEAN_FILTER,
);

export const reportGeneratorConfigsSaveFilterBoolean =
  (filter, fieldId) => (dispatch) => {
    dispatch(saveFilter(filter));
    dispatch(configFieldActions.handleSaveFilter(filter.filter, fieldId));
    dispatch(closeFilter(filter));
  };
