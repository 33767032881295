import axios from 'axios';
import {
  sortKeepAllObjBeginning,
  removeDuplicates,
} from 'services/document_manager';

export const fetchPrivateAccessFundsAutocomplete = (query) => {
  const url = 'private_access_funds/autocomplete';
  return axios.get(url, {
    withCredentials: true,
    params: {
      query,
    },
  }).then(
    response => Promise.resolve(response)
  ).catch(
    error => Promise.reject(error)
  );
};

export const getPrivateAccessFundsNameList = (query) => {
  const url = 'private_access_funds/name_list';
  return axios.get(url, {
    withCredentials: true,
    params: {
      query,
    },
  }).then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error));
};

export const getPrivateAccessFunds = (query) => {
  const url = 'private_access_funds';
  return axios.get(url, {
    withCredentials: true,
    params: {
      query,
    },
  }).then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error));
};

export const sortPrivateAccessFunds = (data = []) => {
  let funds = [...data];
  funds = removeDuplicates(funds);
  return funds.sort(sortKeepAllObjBeginning);
};
