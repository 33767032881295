export const documentDimension = [
  'Actual Size',
  'Fit page',
  'Fit Page Width',
  '50%',
  '75%',
  '100%',
  '125%',
  '150%',
  '175%',
  '200%',
  '225%',
  '250%',
];

export const MIN_ZOOM = 0.5;
export const MAX_ZOOM = 2.5;
export const SCALE_INCREMENT = 0.25;
export const INITIAL_SCALE = 1.5;
export const MIN_SCALE = 0.5;

export const DOCUMENT_BEING_PROCESSED_MESSAGE = 'The document is being processed. Please wait a moment and refresh the page.';
export const TOKEN_ERROR_FETCH_DOCUMENT_MESSAGE = 'An error occurred while trying to fetch the document with the given token.';
export const PASSWORD_ERROR_FETCH_DOCUMENT_MESSAGE = 'An error occurred while trying to fetch the document with the given password.';
export const ALERT_MESSAGE = 'Downloading and printing of this document have been disabled by the manager.  You can use the above controls to navigate the document.';

// COLORS
export const defaultIconColor = '#ffffff';

export const width = 500;
export const height = width * 1.5;

export const SCROLL_BAR_WIDTH = 15;
