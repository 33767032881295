const formatNumber = (number, decimalCases, printDecimalCases, locale) => {
  let valueAbs = Math.abs(number);
  const options = { maximumFractionDigits: decimalCases };
  if (printDecimalCases) options.minimumFractionDigits = decimalCases;

  valueAbs = valueAbs.toLocaleString(locale, options);

  return number < 0 ? `(${valueAbs})` : valueAbs;
};

const currencyFormatter = (
  params,
  needDollar = false,
  decimalCases = 0,
  printDecimalCases = false,
  keepZero = false,
  locale = undefined
) => {
  const value = params.value;
  if ((typeof value !== 'number' || value === 0 || Number.isNaN(value)) && !keepZero) { return '\u2014'; }

  return `${needDollar ? '$' : ''}${formatNumber(value, decimalCases, printDecimalCases, locale)}`;
};

export default currencyFormatter;
