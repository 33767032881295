import update from 'immutability-helper';
import _ from 'lodash';

import {
  RGA_CHANGE_BOOLEAN_FILTER,
  RGA_SAVE_FILTER_BOOLEAN,
  RG_RESET_BOOLEAN_FILTER,
} from 'actions/actionsConstants';

import initialState from 'reducers/report_generator_reducers/initialState';
import { retrieveDBKey } from 'reducers/report_generator_reducers/utils';

export default (state = initialState, action) => {
  switch (action.type) {
    case RGA_CHANGE_BOOLEAN_FILTER: {
      const { filter, value, nullOption } = action.payload;

      if (!_.isNil(nullOption)) {
        return {
          ...state,
          nullOptions: update(state.nullOptions, {
            [state.configurableId]: { [filter]: { $set: nullOption } },
          }),
        };
      }

      const filter_selected_values =
        state.options[state.configurableId][filter]?.value || [];
      const toggle_values = filter_selected_values.includes(value)
        ? filter_selected_values.filter((option) => option != value)
        : [...filter_selected_values, value];

      return {
        ...state,
        options: update(state.options, {
          [state.configurableId]: {
            [filter]: { $set: { value: toggle_values } },
          },
        }),
      };
    }

    case RGA_SAVE_FILTER_BOOLEAN: {
      const { filter, value } = action.payload;
      const configurableId = state.configurableId;
      const nullOption = state.nullOptions[configurableId][filter] || false;
      const saveObject = {
        data_type: 'BOOLEAN',
        null_option: nullOption,
        value,
      };

      const dbMethod = retrieveDBKey(
        filter,
        state.available_fields[configurableId],
      );
      const totalsArray = [...value];
      if (nullOption) {
        totalsArray.push('NULL');
      }

      return {
        ...state,
        options: update(state.options, {
          [configurableId]: { [filter]: { $set: { value } } },
        }),
        initialOptions: update(state.initialOptions, {
          [configurableId]: { [filter]: { $set: { value } } },
        }),
        stored_queries: update(state.stored_queries, {
          [configurableId]: {
            [filter]: { $set: { ...saveObject, ...dbMethod } },
          },
        }),
        totals: update(state.totals, {
          [configurableId]: { [filter]: { $set: totalsArray.join() } },
        }),
      };
    }

    case RG_RESET_BOOLEAN_FILTER: {
      const configurableId = state.configurableId;
      const { filter } = action.payload;

      return {
        ...state,
        options: update(state.options, {
          [configurableId]: { [filter]: { $set: { value: [] } } },
        }),
        nullOptions: update(state.nullOptions, {
          [configurableId]: { [filter]: { $set: false } },
        }),
      };
    }

    default:
      return state;
  }
};
