import update from 'immutability-helper';

import { COM_SET_FUNDS } from 'actions/actionsConstants';

import initialState from '../initialState';

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case COM_SET_FUNDS:
      return update(state, { funds: { $set: payload } });
    default:
      return state;
  }
};
