import { parseUrl, stringify } from 'query-string';

/**
 * Update query params in an URL:
 *
 * @param url string
 * @param queryParamsToOverWrite object
 * @returns string
 */
export const updateQueryParams = (url, queryParamsToOverWrite = {}) => {
  const { url: pathname, query: queryParams } = parseUrl(url);
  const searchQuery = stringify({ ...queryParams, ...queryParamsToOverWrite });
  return searchQuery ? pathname.concat(`?${searchQuery}`) : pathname;
};
