import {
  RGA_WORKER_STARTED,
  RGA_WORKER_FINISHED,
  RGA_UPDATE_WORKER_PROGRESS,
} from 'actions/actionsConstants';

import initialState from 'reducers/report_generator_reducers/initialState';

export default (state = initialState, action) => {
  switch (action.type) {
    case RGA_WORKER_STARTED: {
      const { worker_id, status } = action.payload;
      return {
        ...state,
        workerId: worker_id,
        workerStatus: status,
      };
    }
    case RGA_WORKER_FINISHED: {
      const reportExecutedQuery = action.payload?.reportExecutedQuery;

      return {
        ...state,
        workerId: null,
        workerStatus: null,
        total: 0,
        at: 0,
        reportExecutedQuery,
      };
    }

    case RGA_UPDATE_WORKER_PROGRESS: {
      const { total, at, status } = action.payload;
      return {
        ...state,
        workerStatus: status,
        total,
        at,
      };
    }

    default:
      return state;
  }
};
