import fundsReducer from './fundsReducer';
import filtersReducer from './filtersReducer';
import pageActionsReducer from './pageActionsReducer';
import initialState from './initialState';

const fn = [fundsReducer, filtersReducer, pageActionsReducer];

export const reducer = (state = initialState, action) =>
  fn.reduce((stateAccumulator, fnc) => fnc(stateAccumulator, action), state);

export default reducer;
