import indexReducers from './reportScheduleRequestsReducer';
import initialState from './initialState';

const fn = [
  indexReducers.loadingReducer,
  indexReducers.filterReducer,
  indexReducers.configsReducer,
];

const reducer = (state = initialState, action) =>
  fn.reduce((stateAccumulator, fnc) => fnc(stateAccumulator, action), state);

export default reducer;
