import {
  DD_LOAD_DATA_SYNC_FILE_REPORT,
} from 'actions/actionsConstants';

import initialState from '../initialState';
import { updateState } from '../helpers';

const REQUEST_OBJECT = 'data_sync_file_report';

export default (state = initialState, action) => {
  switch (action.type) {
    case DD_LOAD_DATA_SYNC_FILE_REPORT:
      return updateState(state, action.payload, (data) => ({
        request_object: REQUEST_OBJECT,
        request_type: 'set_data_sync_file_reports_list',
        message: 'Fetched Data Sync File Reports.',
        dataSyncFileReport: {
          ...state.dataSyncFileReport,
          records: data.records,
          pagination: data.meta.pagination,
        },
      }));
    default:
      return state;
  }
};
