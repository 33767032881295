import axios from 'axios';

export const getGeneralLedgerAccounts = (query) => {
  const url = 'flexible_feeds/general_ledger_accounts';
  return axios.get(url, {
    withCredentials: true,
    params: {
      query,
    },
  }).then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error));
};
