import { noValue } from 'services/investor_profile';
import {
  custodianValidations,
  profileValidations,
  interestedPartyValidations,
  signersValidations,
} from 'actions/investor_profile_actions/helpers/errors/validations/index';
import validators from 'actions/investor_profile_actions/helpers/errors/validators';

const fieldApplicabilityCheck = (baseObj, fieldsObj) => {
  let applicable = false;

  Object.entries(fieldsObj).forEach((field) => {
    const [fieldName, applicableValues] = field;

    if (applicableValues.includes(baseObj[fieldName])) {
      applicable = true;
    }
  });

  return applicable;
};

const applicabilityChecks = {
  profileField: (profile, fieldsObj) => fieldApplicabilityCheck(profile, fieldsObj),

  custodian: (profile, fieldsObj, custodian) => fieldApplicabilityCheck(custodian, fieldsObj),

  whiteLabelPartners: (profile, applicableWlps, wlp) => (
    !applicableWlps || applicableWlps.includes(wlp.id)
  ),
};

const doesNotApply = (limitations, profile, whiteLabelPartner, currentCustodian) => {
  if (!limitations) { return false; }

  let applicable = true;

  Object.entries(limitations).forEach((limitation) => {
    const [limitationName, limitationDefinitions] = limitation;

    if (!applicabilityChecks[limitationName](profile, limitationDefinitions, currentCustodian)) {
      applicable = false;
    }
  });

  return !applicable;
};

const validateUpdate = (validation, value, profile, wlp, currentCustodian) => {
  const [validationName, rulesObj] = validation;
  const { limitations } = rulesObj;

  if (limitations && doesNotApply(limitations, profile, wlp, currentCustodian)) { return null; }

  return validators[validationName] && validators[validationName](value, rulesObj, profile);
};

const checkValidations = (
  updateObj, profile, whiteLabelPartner, validationsObj, currentCustodian
) => {
  const errors = {};
  const errorless = [];

  Object.entries(updateObj).forEach(([field, value]) => {
    const fieldValidations = validationsObj[field];

    // exit if no validations exist for field
    if (!fieldValidations) { return; }

    // no error when field has no value
    if (noValue(value)) {
      errorless.push(field);
      return;
    }

    Object.entries(fieldValidations).forEach((validation) => {
      const result = validateUpdate(
        validation, value, profile, whiteLabelPartner, currentCustodian
      );

      if (result && !result.valid) {
        errors[field] = errors[field] || [];
        errors[field].push(result.errorMsg);
      }
    });

    if (!errors[field]) {
      errorless.push(field);
    }
  });

  return { errors, errorless };
};

export const checkSignersValidations = (updateObj, profile, whiteLabelPartner) => (
  checkValidations(updateObj, profile, whiteLabelPartner, signersValidations)
);

export const checkCustodianValidations = (
  updateObj, profile, whiteLabelPartner, currentCustodian
) => (
  checkValidations(updateObj, profile, whiteLabelPartner, custodianValidations, currentCustodian)
);

export const checkInterestedPartiesValidations = (updateObj, profile, whiteLabelPartner) => (
  checkValidations(updateObj, profile, whiteLabelPartner, interestedPartyValidations)
);

export const checkProfileValidations = (updateObj, profile, whiteLabelPartner) => (
  checkValidations(updateObj, profile, whiteLabelPartner, profileValidations)
);
