/* eslint-disable max-lines-per-function */
/* eslint-disable max-statements */
import React, { useEffect } from 'react';
import { useMenu } from '@icapitalnetwork/supernova-hooks';
import {
  Button,
  Menu,
  MenuItem,
  Typography,
} from '@icapitalnetwork/supernova-core';
import {
  bool,
  string,
  arrayOf,
  shape,
  func,
} from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import LanguageIcon from '@mui/icons-material/Language';
import CheckIcon from '@mui/icons-material/Check';
import mainActions from 'actions';
import { polyglotFetchAvailableLanguagesData } from 'modules/polyglot/state/actions';
import {
  getAvailableLanguages,
  getMultilingualSupportEnabled,
  getMultilingualSupportPermissionEnabled,
  getCurrentLanguage,
} from 'modules/polyglot/state/selectors';
import styles from './LanguageMenu.module.scss';

export function LanguageMenu({
  loadAvailableLanguages,
  updateCurrentLanguage,
  multilingualSupportEnabled,
  multilingualSupportPermissionEnabled,
  availableLanguages,
  currentLanguage,
  t,
}) {
  useEffect(() => { loadAvailableLanguages(); }, [loadAvailableLanguages]);

  const {
    handleClick,
    open,
    anchorEl,
    handleClose,
  } = useMenu();

  const handleLanguageSelection = (language) => {
    updateCurrentLanguage(language);
    handleClose();
  };

  return (multilingualSupportEnabled || multilingualSupportPermissionEnabled) && (
    <>
      <Button
        variant="text"
        color="text"
        id="language-selection-button"
        className="icn_wlp_header_link_tag"
        aria-controls={open ? 'language-selection-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <div className="header__actions-language icn_wlp_nav_letter">
          <LanguageIcon />
          <span>{currentLanguage.toUpperCase()}</span>
        </div>
      </Button>
      <Menu
        id="language-selection-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'language-selection-menu',
        }}
      >
        <Typography variant="columnHeader" className={styles.menu_title}>
          {t('polyglot:menus.language_selection.title', 'Select your default language')}
        </Typography>
        {availableLanguages.map((language) => (
          <MenuItem
            key={language.code}
            value={language.code}
            sx={{ justifyContent: 'space-between' }}
            onClick={() => handleLanguageSelection(language.code)}
          >
            {language.name}
            {language.code === currentLanguage && (
              <CheckIcon sx={{ width: '20px', height: '20px' }} />
            )}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

LanguageMenu.propTypes = {
  loadAvailableLanguages: func.isRequired,
  updateCurrentLanguage: func.isRequired,
  availableLanguages: arrayOf(shape({})),
  multilingualSupportEnabled: bool,
  multilingualSupportPermissionEnabled: bool,
  currentLanguage: string,
  t: func.isRequired,
};

LanguageMenu.defaultProps = {
  availableLanguages: [],
  multilingualSupportEnabled: false,
  multilingualSupportPermissionEnabled: false,
  currentLanguage: 'en',
};

const mapStateToProps = (state) => ({
  availableLanguages: getAvailableLanguages(state),
  multilingualSupportEnabled: getMultilingualSupportEnabled(state),
  multilingualSupportPermissionEnabled: getMultilingualSupportPermissionEnabled(state),
  currentLanguage: getCurrentLanguage(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  loadAvailableLanguages: polyglotFetchAvailableLanguagesData,
  updateCurrentLanguage: mainActions.updateCurrentLanguage,
}, dispatch);

export default withTranslation('polyglot')(connect(mapStateToProps, mapDispatchToProps)(LanguageMenu));
