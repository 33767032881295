import React from 'react';
import cx from 'classnames';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import {
  node, string, bool, arrayOf, oneOfType,
} from 'prop-types';
import styles from './IcnTooltipOverlayTrigger.module.scss';

const IcnTooltipOverlayTrigger = ({
  placement,
  trigger,
  rootClose,
  children,
  popOverContent,
  popOverAccessibilityId,
  popOverStyle,
  popOverType,
}) => (
  <OverlayTrigger
    placement={placement}
    trigger={trigger}
    rootClose={rootClose}
    overlay={(
      <Popover
        id={popOverAccessibilityId}
        className={cx(popOverStyle, popOverType)}
      >
        {popOverContent}
      </Popover>
      )}
  >
    {children}
  </OverlayTrigger>
);

IcnTooltipOverlayTrigger.propTypes = {
  popOverContent: node.isRequired,
  children: node.isRequired,
  popOverAccessibilityId: string.isRequired,
  placement: string,
  trigger: oneOfType([string, arrayOf(string)]),
  rootClose: bool,
  popOverStyle: oneOfType([string, arrayOf(string)]),
  popOverType: string,
};

IcnTooltipOverlayTrigger.defaultProps = {
  placement: 'bottom',
  trigger: 'click',
  rootClose: true,
  popOverStyle: '',
  popOverType: styles.default_tooltip,
};

export default IcnTooltipOverlayTrigger;
