import update from 'immutability-helper';
import {
  ACTIVITY_LOG_GP_ENGAGEMENT_SCORE_SET_INFO,
  ACTIVITY_LOG_ENGAGEMENT_SCORE_SET_USER_FUND_INFO,
  ACTIVITY_LOG_ENGAGEMENT_SCORE_SET_SCORE_INFO_DIALOG,
} from 'modules/activity_log/engagement_score/actions/constants';
import initialState from 'modules/activity_log/reducers/initialState';

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIVITY_LOG_GP_ENGAGEMENT_SCORE_SET_INFO: {
      return update(state, {
        engagementScoreData: {
          categories: { $set: action.payload.categories },
          activity_weights: { $set: action.payload.activity_weights },
        },
      });
    }

    case ACTIVITY_LOG_ENGAGEMENT_SCORE_SET_USER_FUND_INFO: {
      return update(state, {
        engagementScoreData: {
          user_fund_info: { $set: action.payload.user_fund_info },
        },
      });
    }

    case ACTIVITY_LOG_ENGAGEMENT_SCORE_SET_SCORE_INFO_DIALOG: {
      return update(state, {
        engagementScoreData: {
          is_score_info_dialog_open: { $set: action.payload },
        },
      });
    }

    default:
      return state;
  }
};
