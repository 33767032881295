import axios from 'axios';

export const getIntegrationPartners = () => {
  const url = 'integration_partners';

  return axios.get(url, {
    withCredentials: true,
  }).then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error));
};
