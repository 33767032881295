import axios from 'axios';
import {
  DC_SET_FORM_INFO,
  DC_TOGGLE_DOWNLOAD_ERROR,
} from 'actions/actionsConstants';
import {
  beginApiRequest,
  endApiRequest,
} from 'actions/spinner_actions/spinnerActions';

const API_BASE_URL = 'doc_center/uploader';

function dcSetFormInfo(json) {
  return {
    type: DC_SET_FORM_INFO,
    payload: json,
  };
}

function toggleDownloadError(payload) {
  return {
    type: DC_TOGGLE_DOWNLOAD_ERROR,
    payload,
  };
}

function dcGetFormInfo() {
  return (dispatch, getState) => {
    const state = getState();
    const info = state.dcUploader.info;
    if (!info) {
      dispatch(beginApiRequest());
      return axios({
        url: `${API_BASE_URL}/info`,
        method: 'get',
        withCredentials: true,
      })
        .then((response) => {
          dispatch(dcSetFormInfo({
            funds: response.data.funds.map((e) => ({ id: e[0], name: e[1] })),
            externalDocumentTypes: response.data.external_document_types.map((e) => ({
              id: e[0],
              name: e[1],
              type: e[2],
            })),
          }));
          dispatch(endApiRequest());
        })
        .catch(() => {
          dispatch(endApiRequest());
        });
    }
    return undefined;
  };
}

function dcDocumentUpload(file, info, callback) {
  return (dispatch) => {
    dispatch(beginApiRequest());
    const data = new FormData();
    data.append('file', file);
    data.append('description', info.description);
    if (info.documentType.type !== 'multi_fund') data.append('fund_id', info.fund.id);
    data.append('external_document_type_id', info.documentType.id);
    data.append('effective_date', info.effectiveDate);

    return axios({
      url: `${API_BASE_URL}`,
      method: 'post',
      withCredentials: true,
      data,
    })
      .then((response) => {
        if (response.status === 200) {
          dispatch(
            toggleDownloadError({
              downloadError: {
                title: 'Operations Document Upload',
                text: 'Document was successfully uploaded.',
              },
            })
          );
        } else {
          dispatch(
            toggleDownloadError({
              downloadError: {
                title: 'Operations Document Upload',
                text: 'There was an error uploading the document. Please contact a Document Center administrator.',
              },
            })
          );
        }
        callback();
        dispatch(endApiRequest());
      })
      .catch(() => {
        dispatch(endApiRequest());
      });
  };
}

export default {
  dcGetFormInfo,
  dcDocumentUpload,
};
