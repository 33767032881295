import PropTypes from 'prop-types';

const useNotificationService = ({
  message,
  variant,
  onActionClick,
  actionButtonLabel,
  enqueueSnackbar,
  closeSnackbar,
  autoHideDuration,
  persist,
  anchorOrigin,
}) => enqueueSnackbar(message, {
  variant,
  persist: persist || false,
  anchorOrigin: anchorOrigin || { vertical: 'top', horizontal: 'right' },
  actionButtonLabel: actionButtonLabel || undefined,
  onActionClick: () => onActionClick() || undefined,
  onCloseAction: (id) => closeSnackbar(id),
  autoHideDuration: autoHideDuration || 5000,
});

export default useNotificationService;

useNotificationService.propTypes = {
  message: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
  actionButtonLabel: PropTypes.string,
  onActionClick: PropTypes.func,
  onCloseAction: PropTypes.func,
  autoHideDuration: PropTypes.number,
  persist: PropTypes.boolean,
  anchorOrigin: PropTypes.shape({
    vertical: PropTypes.string,
    horizontal: PropTypes.string,
  }),
};
