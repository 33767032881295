import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { i18nResources, i18nNamespaces } from 'i18n/resources';

i18n
  // bind react-i18next to the instance
  .use(initReactI18next)
  .use(new LanguageDetector(null, { lookupLocalStorage: 'lng' }))
  // Transform resources to an i18next backend.
  .use(resourcesToBackend((language, namespace, callback) => {
    import(`./i18n/${namespace}/${language}.json`)
      .then((resources) => {
        callback(null, resources);
      })
      .catch((error) => {
        callback(error, null);
      });
  }))
  .init({
    partialBundledLanguages: true,
    debug: false,
    lng: 'en',
    fallbackLng: 'en',
    nsSeparator: ':',
    // Separate the translations per module,
    // to prevent having a single large file with all the translations.
    // The common namespace will have strings that are used everywhere.
    ns: i18nNamespaces,
    defaultNS: 'common',
    resources: i18nResources,
  });

/**
 * The best aproach is to lazy load the translations.
 * This way only the translations needed are loaded.
 *
 * PROBLEM:
 * The problem with the lazy loading is that it breaks the feature tests.
 *
 * REASON:
 * The lazy load breaks the tests (cucumber, jest) because we can't guarantee that the translations
 * are loaded before the tests start, and we are using, validations against the text
 * on the component. Another reason why I believe that checking againt the text is not a good aproach
 * is that if for some reason we decided to change the style of the text (capitalize, uncapitalize,
 * lowercase, uppercase, etc), we'll also need to change the tests, otherwise they'll break because
 * the validation is being done in a case-sensitive way.
 *
 * SOLUTION:
 * In my opinion, the best aproach for tests would be no to validate against the text on the
 * component by against the translation key or a component id. This way we can lazy load
 * all the translations because the validation is not done against the text on the component.
 *
 * WORKAROUND:
 * Currently, it was implemented a workaround using the "partialBundledLanguages".
 * The main language "en" is loaded on init and the rest of languages are lazy loaded.
 * This way we can keep the tests how they are. We only need to make sure that the
 * text that's being tested exists on the "en" translation files.
 */

export default i18n;
