import CryptoJS from 'crypto-js';

const key = CryptoJS.enc.Utf8.parse('SecretKey123');
const fixedIV = CryptoJS.enc.Utf8.parse('FixedIV1234567890');


function encrypt(text) {
  const encrypted = CryptoJS.AES.encrypt(JSON.stringify(text), key, {
    mode: CryptoJS.mode.ECB,
    iv: fixedIV,
  });
  return encrypted.toString();
}

function decrypt(encrypted) {
  const decrypted = CryptoJS.AES.decrypt(encrypted, key, {
    mode: CryptoJS.mode.ECB,
    iv: fixedIV,
  });
  return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
}

function getDuration(milli){
  let minutes = Math.floor(milli / 60000);
  let hours = Math.round(minutes / 60);
  let days = Math.round(hours / 24);

  return days
};

function getDate(timestamp){
  var date = new Date(timestamp);
  date = date.getFullYear()+"-"+(date.getMonth()+1)+"-"+date.getDate();

  return date
};

/* globals window, document, FS, _fs_host */
export default function appCues(user, wlp, env, domain, region, roles, groups, permissions, enable) {
  window.cryptoJs = CryptoJS
  const anon_user_id = encrypt([
    user.id,
    env,
    domain || '',
    region,
  ])

  const anon_wlp_id = encrypt([
    wlp?.id || 0,
    env,
    domain || '',
    region,
  ])

  var timestamp_created_at = Date.parse(user.created_at)
  var timestamp_last_login = Date.parse(user.last_login)

  var date_created_at = getDate(timestamp_created_at);
  var date_last_login = getDate(timestamp_last_login);

  var num_days_created_at = getDuration(timestamp_created_at);
  var num_days_last_login = getDuration(timestamp_last_login);

  if (enable) {
    if (user) {
      window.Appcues?.identify(
        anon_user_id,
        {
          white_label_partner_id: anon_wlp_id,
          date_created_at: date_created_at,
          date_last_login: date_last_login,
          days_created_at: num_days_created_at,
          days_last_login: num_days_last_login,
          accredited: user.accredited,
          roles: roles.join(','),
          groups: groups.join(','),
          permissions: permissions.join(','),
        }
      );
    }
  }
}
