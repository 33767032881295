import update from 'immutability-helper';
import { defaultPeriodFilter } from 'constants/synthesis';

import {
  FUND_FINANCES_SYNTHESIS_SET_INDEX_MASTERS_LIST,
  FUND_FINANCES_SYNTHESIS_SET_INDEX_MASTERS,
  FUND_FINANCES_SYNTHESIS_SET_YEARS_PERIOD,
  FUND_FINANCES_SYNTHESIS_CHANGE_PERIOD,
  FUND_FINANCES_SYNTHESIS_SET_FUND_GROUPS_LIST,
  FUND_FINANCES_SYNTHESIS_SET_FUND_GROUPS,
  FUND_FINANCES_SYNTHESIS_SET_FUND_LIST,
  FUND_FINANCES_SYNTHESIS_SET_FUNDS,
  FUND_FINANCES_SYNTHESIS_SET_SHARE_CLASS_LIST,
  FUND_FINANCES_SYNTHESIS_SET_SHARE_CLASSES,
  FUND_FINANCES_SYNTHESIS_SET_RESULTS_TOTAL,
  FUND_FINANCES_SYNTHESIS_SET_GRID_STATE,
  FUND_FINANCES_SYNTHESIS_SET_LOADED_GRID_STATE,
  FUND_FINANCES_SYNTHESIS_SET_ALL_FILTERS,
  FUND_FINANCES_SYNTHESIS_SET_RETURN_TYPES,
} from 'actions/actionsConstants';

export const initialState = {
  indexMastersList: [],
  indexMasters: [],
  yearsPeriod: [],
  period: defaultPeriodFilter(),
  fundGroupsList: [],
  fundGroups: [],
  fundsList: [],
  funds: [],
  shareClassesList: [],
  shareClasses: [],
  resultsTotals: 0,
  gridState: undefined,
  loadedGridState: undefined,
  returnTypes: [],
};

export default function fundFinancesSynthesisReducer(state = initialState, action) {
  switch (action.type) {
    case FUND_FINANCES_SYNTHESIS_SET_INDEX_MASTERS_LIST:
      return update(state, {
        indexMastersList: { $set: action.payload },
      });
    case FUND_FINANCES_SYNTHESIS_SET_INDEX_MASTERS:
      return update(state, {
        indexMasters: { $set: action.payload },
      });
    case FUND_FINANCES_SYNTHESIS_SET_YEARS_PERIOD:
      return update(state, {
        yearsPeriod: { $set: action.payload },
      });
    case FUND_FINANCES_SYNTHESIS_CHANGE_PERIOD:
      return update(state, {
        period: { $set: action.payload },
      });
    case FUND_FINANCES_SYNTHESIS_SET_FUND_GROUPS_LIST:
      return update(state, {
        fundGroupsList: { $set: action.payload },
      });
    case FUND_FINANCES_SYNTHESIS_SET_FUND_GROUPS:
      return update(state, {
        fundGroups: { $set: action.payload },
      });
    case FUND_FINANCES_SYNTHESIS_SET_FUND_LIST:
      return update(state, {
        fundsList: { $set: action.payload },
      });
    case FUND_FINANCES_SYNTHESIS_SET_FUNDS:
      return update(state, {
        funds: { $set: action.payload },
      });
    case FUND_FINANCES_SYNTHESIS_SET_SHARE_CLASS_LIST:
      return update(state, {
        shareClassesList: { $set: action.payload },
      });
    case FUND_FINANCES_SYNTHESIS_SET_SHARE_CLASSES:
      return update(state, {
        shareClasses: { $set: action.payload },
      });
    case FUND_FINANCES_SYNTHESIS_SET_RETURN_TYPES:
      return update(state, {
        returnTypes: { $set: action.payload },
      });
    case FUND_FINANCES_SYNTHESIS_SET_RESULTS_TOTAL:
      return update(state, {
        resultsTotals: { $set: action.payload },
      });
    case FUND_FINANCES_SYNTHESIS_SET_GRID_STATE:
      return update(state, {
        gridState: { $set: action.payload },
      });
    case FUND_FINANCES_SYNTHESIS_SET_LOADED_GRID_STATE:
      return update(state, {
        loadedGridState: { $set: action.payload },
      });
    case FUND_FINANCES_SYNTHESIS_SET_ALL_FILTERS:
      return update(state, {
        fundGroups: { $set: action.payload.fundGroups },
        funds: { $set: action.payload.funds },
        period: { $set: action.payload.period },
        indexMasters: { $set: action.payload.indexMasters },
        shareClasses: { $set: action.payload.shareClasses },
        returnTypes: { $set: action.payload.returnTypes },
        loadedGridState: { $set: action.payload.gridState },
      });
    default:
      return state;
  }
}
