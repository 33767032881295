/* eslint-disable max-statements */
/* eslint-disable max-lines */
import isEqual from 'lodash/isEqual';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import intersectionWith from 'lodash/intersectionWith';
import { createAction } from 'redux-actions';
import { getRequest, postRequest } from 'actions/utils';

import {
  FF_SET_ACTIVE_SAVED_VIEW,
  FF_SET_REPRESENTATIVES_FOR_FILTER,
  FF_SET_OPTIONS_FOR_FILTER,
  FF_SET_FUNDS_FOR_FILTER,
  FF_SELECTED_FUNDS,
  FF_SELECTED_PERIOD,
  FF_SELECTED_SINGLE_FUND,
  FF_CURRENT_PERIOD_TYPE,
  FF_DASHBOARD_TOTALS,
  FF_SET_CURRENT_PAGE,
  FF_SET_RESULTS_PER_PAGE,
  FF_RESET_DASHBOARD_TOTALS,
  FF_SELECTED_PERIOD_PICKER_TYPE,
  FF_SET_IS_LOADING_FUND_DATA_FOR_FILTER,
  FF_SET_IS_LOADING_REP_DATA_FOR_FILTER,
  FF_SET_IS_LOADING_FUNDS_FOR_FILTER,
  FF_SET_IS_LOADING_DEFAULT_VIEW,
  FF_SET_FEE_TYPE_FILTERS,
} from 'actions/actionsConstants';

const ffSetActiveSavedView = createAction(FF_SET_ACTIVE_SAVED_VIEW);
const ffSetRepresentativesForFilter = createAction(FF_SET_REPRESENTATIVES_FOR_FILTER);
const ffSetOptionsForFilter = createAction(FF_SET_OPTIONS_FOR_FILTER);
const ffSetFundsForFilter = createAction(FF_SET_FUNDS_FOR_FILTER);
const ffSetSelectedFunds = createAction(FF_SELECTED_FUNDS);
const ffSetSelectedPeriod = createAction(FF_SELECTED_PERIOD);
const ffSetSelectedSingleFund = createAction(FF_SELECTED_SINGLE_FUND);
const ffSetCurrentPeriodType = createAction(FF_CURRENT_PERIOD_TYPE);
const ffSetDashboardTotals = createAction(FF_DASHBOARD_TOTALS);
const ffSetCurrentPage = createAction(FF_SET_CURRENT_PAGE);
const ffSetResultsPerPage = createAction(FF_SET_RESULTS_PER_PAGE);
const ffResetDashboardTotals = createAction(FF_RESET_DASHBOARD_TOTALS);
const ffSetSelectedPeriodPickerType = createAction(FF_SELECTED_PERIOD_PICKER_TYPE);
const ffSetIsLoadingFundDataForFilter = createAction(FF_SET_IS_LOADING_FUND_DATA_FOR_FILTER);
const ffSetIsLoadingRepDataForFilter = createAction(FF_SET_IS_LOADING_REP_DATA_FOR_FILTER);
const ffSetIsLoadingFundsForFilter = createAction(FF_SET_IS_LOADING_FUNDS_FOR_FILTER);
const ffSetIsLoadingDefaultView = createAction(FF_SET_IS_LOADING_DEFAULT_VIEW);
const ffSetFeeTypeFilters = createAction(FF_SET_FEE_TYPE_FILTERS);

function ffLoadRepresentativeForFilter() {
  return (dispatch) => {
    dispatch(ffSetIsLoadingRepDataForFilter(true));

    return dispatch(
      getRequest({
        url: 'fund_master/fund_representative_relationship',
        onSuccess: ffSetRepresentativesForFilter,
        showSpinner: false,
      })
    ).finally(() => dispatch(ffSetIsLoadingRepDataForFilter(false)));
  };
}

function ffLoadOptionsForFilter() {
  return (dispatch) => {
    dispatch(ffSetIsLoadingFundDataForFilter(true));

    return dispatch(
      getRequest({
        url: 'fund_master/funds',
        onSuccess: ffSetOptionsForFilter,
        showSpinner: false,
      })
    ).finally(() => dispatch(ffSetIsLoadingFundDataForFilter(false)));
  };
}

function ffLoadFundsForFilter(options = {}) {
  return (dispatch, getState) => {
    dispatch(ffSetIsLoadingFundsForFilter(true));

    return dispatch(
      getRequest({
        url: 'private_access_funds/for_fund_fees',
        params: {
          profit_and_loss: options.profitAndLoss,
          asset_class: options.assetClass,
          representative_type: options.representativeType,
          representative: options.representative,
          fees_are_live: options.feesAreLive,
        },
        showSpinner: false,
        onSuccess: (data) => {
          dispatch(ffSetFundsForFilter(data));

          const oldSelectedFunds = getState().fundFees.selected_funds;
          const newFunds = data.length && data.map((item) => item.id);
          const updatedSelectedFunds = intersectionWith(oldSelectedFunds, newFunds, isEqual);

          if (!isEqual(oldSelectedFunds, updatedSelectedFunds)) {
            return ffSetSelectedFunds(updatedSelectedFunds);
          }

          return ffSetIsLoadingFundsForFilter(false);
        },
      })
    ).finally(() => {
      if (getState().fundFees.is_loading_funds_for_filter !== false) {
        dispatch(ffSetIsLoadingFundsForFilter(false));
      }
    });
  };
}

function ffFilterByFunds(newSelectedFunds) {
  return (dispatch, getState) => {
    const oldSelectedFunds = getState().fundFees.selected_funds;

    if (!isEqual(newSelectedFunds, oldSelectedFunds)) {
      dispatch(ffSetSelectedFunds(newSelectedFunds));
      return dispatch(ffSetActiveSavedView());
    }

    return Promise.resolve();
  };
}

function ffFilterByPeriod(newSelectedPeriod) {
  return (dispatch, getState) => {
    const oldSelectedPeriod = getState().fundFees.selected_period;
    if (!isEqual(newSelectedPeriod, oldSelectedPeriod)) {
      dispatch(ffSetSelectedPeriod(newSelectedPeriod));
      return dispatch(ffSetActiveSavedView());
    }
    return Promise.resolve();
  };
}

function ffFilterBySingleFund(newSelectedSingleFund) {
  return (dispatch, getState) => {
    const oldSelectedSingleFund = getState().fundFees.selected_single_fund;
    if (!isEqual(newSelectedSingleFund, oldSelectedSingleFund)) {
      dispatch(ffSetSelectedSingleFund(newSelectedSingleFund));
      return dispatch(ffSetActiveSavedView());
    }
    return Promise.resolve();
  };
}

function ffFilterByCurrentPeriodType(newCurrentPeriodType) {
  return (dispatch, getState) => {
    const oldCurrentPeriodType = getState().fundFees.current_period_type;
    if (!isEqual(newCurrentPeriodType, oldCurrentPeriodType)) {
      dispatch(ffSetCurrentPeriodType(newCurrentPeriodType));
      return dispatch(ffSetActiveSavedView());
    }
    return Promise.resolve();
  };
}

function ffFilterByPeriodPickerType(newSelectedPeriodPickerType) {
  return (dispatch, getState) => {
    const oldSelectedPeriodPickerType = getState().fundFees.selected_period_picker_type;
    if (!isEqual(newSelectedPeriodPickerType, oldSelectedPeriodPickerType)) {
      dispatch(ffSetSelectedPeriodPickerType(newSelectedPeriodPickerType));
      return dispatch(ffSetActiveSavedView());
    }
    return Promise.resolve();
  };
}

function ffLoadSavedView(data) {
  return (dispatch) => {
    const filters = data.filters.drop_down_filters;
    const gFilters = groupBy(filters, 'filter_type');

    dispatch(ffSetSelectedSingleFund(''));

    dispatch(ffSetSelectedFunds(
      gFilters.funds && gFilters.funds[0].filters.length > 0
        ? map(gFilters.funds[0].filters, 'typed_filter_id')
        : []
    ));

    dispatch(ffSetSelectedPeriod(
      gFilters.period && gFilters.period[0].filters.length > 0
        ? gFilters.period[0].filters[0].typed_filter_id
        : ''
    ));

    dispatch(ffSetCurrentPeriodType(
      gFilters.periodType && gFilters.periodType[0].filters.length > 0
        ? gFilters.periodType[0].filters[0].typed_filter_id
        : 'accruals'
    ));

    dispatch(ffSetSelectedPeriodPickerType(
      gFilters.periodPickerType && gFilters.periodPickerType[0].filters.length > 0
        ? gFilters.periodPickerType[0].filters[0].typed_filter_id
        : 'accrual'
    ));

    dispatch(ffSetActiveSavedView(data));

    return Promise.resolve();
  };
}

function ffLoadDefaultView() {
  return (dispatch) => {
    dispatch(ffSetIsLoadingDefaultView(true));

    return dispatch(
      getRequest({
        url: 'user_saved_views/default_view',
        params: { page_slug: 'fund_fees/dashboard' },
        onSuccess: ffLoadSavedView,
        showSpinner: false,
      })
    ).catch((err) => {
      if (err.status !== 404) throw err;
    }).finally(() => dispatch(ffSetIsLoadingDefaultView(false)));
  };
}

function ffLoadDashboardTotals(
  selectedFunds,
  periodStart,
  periodEnd,
  currentPeriodType,
  accrualPeriodStart,
  accrualPeriodEnd,
  filterClause
) {
  return (dispatch) => dispatch(
    postRequest({
      url: '/fund_fee_distributor/dashboard/totals',
      data: {
        private_access_fund_ids: selectedFunds,
        period_start: periodStart,
        period_end: periodEnd,
        period_type: currentPeriodType,
        accrual_period_start: accrualPeriodStart,
        accrual_period_end: accrualPeriodEnd,
        filter_clause: filterClause,
      },
      onSuccess: (data) => ffSetDashboardTotals(data[0]),
      onFailure: ffResetDashboardTotals,
      showSpinner: false,
    })
  );
}

function ffExportFundFeesDashboard(
  pafIds,
  periodStart,
  periodEnd,
  accrualPeriodStart,
  accrualPeriodEnd,
  currentPeriodType,
  filterClause,
  groupFields,
  additionalFields
) {
  return (dispatch) => (
    dispatch(
      postRequest({
        url: '/fund_fee_distributor/dashboard/export',
        data: {
          private_access_fund_ids: pafIds,
          period_start: periodStart,
          period_end: periodEnd,
          period_type: currentPeriodType,
          accrual_period_start: accrualPeriodStart,
          accrual_period_end: accrualPeriodEnd,
          filter_clause: filterClause,
          group_fields: groupFields.length > 0 ? groupFields : null,
          additional_fields: additionalFields,
        },
      })
    )
  );
}

function ffLoadFeeDetailsForFilter(
  selectedFunds,
  periodStart,
  periodEnd,
  currentPeriodType,
  accrualPeriodStart,
  accrualPeriodEnd,
  filterClause
) {
  return (dispatch) => dispatch(
    postRequest({
      url: '/fund_fee_distributor/dashboard',
      data: {
        private_access_fund_ids: selectedFunds,
        period_start: periodStart,
        period_end: periodEnd,
        period_type: currentPeriodType,
        accrual_period_start: accrualPeriodStart,
        accrual_period_end: accrualPeriodEnd,
        filter_clause: filterClause,
        fields: ['fee_type'],
        distinct: true,
      },
      onSuccess: (data) => ffSetFeeTypeFilters(data.map((f) => f.fee_type)),
      showSpinner: false,
    })
  );
}

export default {
  ffLoadRepresentativeForFilter,
  ffLoadOptionsForFilter,
  ffLoadDefaultView,
  ffLoadSavedView,
  ffFilterByFunds,
  ffLoadFundsForFilter,
  ffFilterByPeriod,
  ffFilterBySingleFund,
  ffFilterByCurrentPeriodType,
  ffLoadDashboardTotals,
  ffExportFundFeesDashboard,
  ffSetCurrentPage,
  ffSetResultsPerPage,
  ffFilterByPeriodPickerType,
  ffSetIsLoadingFundDataForFilter,
  ffSetIsLoadingRepDataForFilter,
  ffSetIsLoadingFundsForFilter,
  ffSetIsLoadingDefaultView,
  ffLoadFeeDetailsForFilter,
};
