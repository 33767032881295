import axios from 'axios';
import i18n from 'i18n.js';
import notificationService from 'services/notifications';
import { getRequest } from 'actions/utils';
import ExportStarted from 'components/shared/notifications/exportStarted';
import { actionFactory } from 'services/actions';
import { beginApiRequest, endApiRequest } from '../spinner_actions/spinnerActions';

import {
  SR_SET_SALES_REPORTS,
} from '../actionsConstants';

const srSetSalesReports = actionFactory(SR_SET_SALES_REPORTS);

function srRequest(endpoint, params = {}) {
  const url = endpoint;
  return (dispatch) => {
    dispatch(beginApiRequest());
    return axios.get(url, { withCredentials: true, params }).then(() => {
      dispatch(endApiRequest());
      notificationService.notifyInfo('', ExportStarted());
    }).catch((error) => {
      dispatch(endApiRequest());
      let message = i18n.t(
        'common:errors.unexpected_error',
        'An unexpected error occurred. Please try again.'
      );

      if (error.data && error.data.message) {
        message = error.data.message;
      }
      notificationService.notifyError(
        i18n.t(
          'common:errors.count_not_submit_report_request',
          'Could not submit report request'
        ),
        message,
      );
    });
  };
}

function srRequestInvestmentReport(params) {
  return srRequest('fund_raising_activity/investment_report', params);
}

function srRequestAdvisorDetailReport(params) {
  return srRequest('fund_raising_activity/advisor_detail_report', params);
}

function srRequestSalesReports(params, showSpinner = true) {
  return (dispatch) => dispatch(getRequest({
    url: 'fund_raising_activity/sales_reports',
    params,
    showSpinner,
    onSuccess: srSetSalesReports,
  }));
}

export default {
  srRequestInvestmentReport,
  srRequestAdvisorDetailReport,
  srRequestSalesReports,
};
