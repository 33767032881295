import { SR_LOAD_ASSIGNMENTS_REVIEWERS_SUCCESS } from 'actions/actionsConstants';

import update from 'immutability-helper';

import { sortArrayAlphabetically } from '../utils';
import initialState from '../initialState';

export default (state = initialState, action) => {
  let assignmentsReviewers = null;

  switch (action.type) {
    case SR_LOAD_ASSIGNMENTS_REVIEWERS_SUCCESS:
      assignmentsReviewers = action.payload.map((el) => {
        const obj = el;
        obj.content = `${el.name} (${el.email})`;
        return obj;
      });
      assignmentsReviewers = sortArrayAlphabetically(assignmentsReviewers, 'content');
      return update(state, {
        assignments_reviewers: {
          $set: assignmentsReviewers,
        },
      });
    default:
      return state;
  }
};
