import { combineReducers } from 'redux';
import formTemplateVersionsShow from './show/reducer';
import formTemplateVersionsUpdate from './update/reducer';
import formTemplateVersionsCreate from './create/reducer';

export default combineReducers({
  show: formTemplateVersionsShow,
  update: formTemplateVersionsUpdate,
  create: formTemplateVersionsCreate,
});
