/* eslint-disable max-statements */
import store from 'icnStore';
import actions from 'actions';
import { routeCodes } from 'router';
import { clientManagementExportFinished } from 'containers/client_management/actions';
import { currentPafId } from 'containers/client_management/selectors';

function onExportFinished() {
  const state = store.getState();
  const fundDashboardSelectedFunds = state.fundDashboard.selected_funds;
  const salesDetailSelectedFunds = state.salesDetail.selected_funds;
  let pafIds = [];
  if (fundDashboardSelectedFunds.length) {
    pafIds = fundDashboardSelectedFunds.map((fund) => fund.id);
  } else if (salesDetailSelectedFunds.length) {
    pafIds = salesDetailSelectedFunds.map((fund) => fund.id);
  }

  if (currentPafId(state)) {
    store.dispatch(clientManagementExportFinished());
  }

  if (pafIds.length) {
    store.dispatch(actions.srRequestSalesReports({ pafIds }, false));
  }

  const isCapEventDashboard = routeCodes.FUND_ADMIN_OPERATIONS.includes(location?.pathname);

  if (isCapEventDashboard) {
    store.dispatch(actions.odSetHasNewExportedFile(true));
  }
}

function onWatermarkDocumentReady(documentType, documentId, status) {
  const state = store.getState();
  if (documentType === 'FundDocument') {
    const documents = state.documentManager.documents;
    if (documents.length) {
      store.dispatch(actions.dmSetDocumentsStatus({ document_id: documentId, status }));
    }
  }
}

function refreshOnEventComplete() {
  store.dispatch(actions.fundAdminUploaderRefreshPage());
}

export default {
  onExportFinished,
  onWatermarkDocumentReady,
  refreshOnEventComplete,
};
