import {
  DC_UPLOAD_DOCUMENT,
  DC_SET_FORM_INFO,
} from 'actions/actionsConstants';

import update from 'immutability-helper';

const initialState = {
  info: undefined,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case DC_UPLOAD_DOCUMENT:
      return update(state);
    case DC_SET_FORM_INFO:
      return update(state, {
        info: { $set: action.payload },
      });
    default:
      return state;
  }
};

export default reducer;
