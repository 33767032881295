import { getRequest } from 'actions/utils';
import { actionFactory } from 'services/actions';

export const SET_POLYGLOT_AVAILABLE_LANGUAGES_DATA = 'SET_POLYGLOT_AVAILABLE_LANGUAGES_DATA';

const polyglotGetAvailableLanguagesData = actionFactory(SET_POLYGLOT_AVAILABLE_LANGUAGES_DATA);

const onFetchAvailableLanguagesDataSuccess = (payload) => (dispatch) => {
  dispatch(polyglotGetAvailableLanguagesData(payload));
};

// Fetch the data from the server
export const polyglotFetchAvailableLanguagesData = () => getRequest({
  url: 'polyglot/locales',
  onSuccess: (payload) => onFetchAvailableLanguagesDataSuccess(payload),
});
