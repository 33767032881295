import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { func } from 'prop-types';
import userShape from 'prop_types/shared/user';

import * as icnBootstrapSelectors from 'selectors/icnBootstrapSelectors';

// A wrapper for <Route> that redirects unless there is a user
export const PrivateRoute = ({
  component: Component,
  user,
  ...rest
}) => (
  <Route
    {...rest}
    render={(routeProps) => (
      user ? (
        <Component
          {...rest}
          {...routeProps}
        />
      ) : (
        <Redirect
          to={{
            pathname: '/icn_react/static/login',
            state: { from: rest.location },
          }}
        />
      )
    )}
  />
);

function mapStateToProps(state) {
  const user = icnBootstrapSelectors.getUser(state);
  return {
    user,
  };
}

PrivateRoute.defaultProps = {
  user: null,
};

PrivateRoute.propTypes = {
  user: userShape,
  component: func.isRequired,
};

export default connect(mapStateToProps, null)(PrivateRoute);
