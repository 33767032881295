export function searchTextFormatter(text) {
  if (text == null) { return ''; }
  return text.toLowerCase();
}

export function wordsToHighlight(text) {
  if (text == null) { return []; }
  return text.split(' ');
}

export default {
  searchTextFormatter,
  wordsToHighlight,
};
