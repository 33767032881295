export const publicPath = '/icn_react/static/';
export const baseRoute = `${publicPath}product_profile_builder/`;

export const routeCodes = {
  PPB_EDIT_PROFILE: `${baseRoute}fund_profile/profiles/:profile_id`,
  PPB_EDIT_TEMPLATE: `${baseRoute}fund_profile/templates/:template_id`,
  PPB_FUND_PROFILE: `${baseRoute}fund_profile/:fund_profile_id`,
  PPB_FUND_PROFILES_INDEX: `${baseRoute}fund_profile/profiles`,
  PPB_FUND_PROFILE_FUND: `${baseRoute}funds/:fund_id`,
  PPB_FUND_PROFILE_FUND_PREVIEW: `${baseRoute}fund_profile/:fund_profile_id/preview`,
  PPB_INDEX_TEMPLATES: `${baseRoute}fund_profile/templates`,
  PPB_INDEX_TEMPLATES_TAB: `${baseRoute}fund_profile/templates?tab=:tab`,
  PPB_INDEX_PROFILES_TAB: `${baseRoute}fund_profile/profiles?tab=:tab`,
  PPB_FUND_PROFILE_OLD: `${publicPath}funds/:fund_id`,
  PPB_RESEARCH_PROFILE: `${publicPath}research_funds/:fund_id`,
};
