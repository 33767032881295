/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
import React, { useEffect } from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import { routeCodes as integrationHubRouteCodes } from '@ihub/router';
import { routeCodes as validatrRouteCodes } from '@validatr/router';
import { useSnackbar } from '@icapitalnetwork/supernova-hooks';
import { func, shape } from 'prop-types';
import icnReactBootstrapShape from 'prop_types/shared/icn_react_bootstrap';
import Loadable from 'react-loadable';
import { connect } from 'react-redux';
import classNames from 'classnames';
import _, { isEmpty } from 'lodash';
import { bindActionCreators } from 'redux';
import IcnSpinner from 'components/shared/icn_spinner/IcnSpinner';
import Loading from 'components/shared/loading/Loading';
import NotFoundEntry from 'containers/not_found/entry';
import LayoutGlobal from 'components/shared/layout_global/LayoutGlobal';
import PrivateRoute from 'containers/private_route/PrivateRoute';
import * as icnBootstrapSelectors from 'selectors/icnBootstrapSelectors';
import { initializeBootstrap } from 'services/bootstrapServices';
import { getPassportCandidates } from 'modules/passport/selectors';
import { getUser } from 'selectors/icnBootstrapSelectors';
import { passFetchCandidates } from 'modules/passport/actions';
import { routeCodes as educationRouteCodes } from 'modules/education/router';
import { routeCodes as activityLogRouteCodes } from 'modules/activity_log/router';
import { routeCodes as investmentOpportunityRouteCodes } from 'modules/investment_opportunity/router';
import { routeCodes as landingPageRouteCodes } from 'modules/landing_page/router';
import { routeCodes as configurationMasterRouteCodes } from 'modules/configuration_master/router';
import { routeCodes as profileRouteCodes } from 'modules/profile/router';
import { routeCodes as settingsRouteCodes } from 'modules/settings/router';
import { routeCodes as impersonationRouteCodes } from 'modules/impersonation/router';
import { routeCodes as releaseNotesRouteCodes } from 'modules/release_notes/router';
import { BASE_ROUTE as advisorDashboardRoute } from 'modules/advisor_dashboard/router';
import { routeCodes as bulkInvestmentRouteCodes } from 'modules/bulk_investment/router';
import { routeCodes as LetterOfAuthorizationRouteCodes } from 'modules/letter_of_authorization/router';
import { routeCodes as initiateTransferRouteCodes } from 'modules/initiate_transfer/router';
import { routeCodes as transferReviewRouteCodes } from 'modules/transfers/review/router';
import { routeCodes as ManageNomineeAllocationsRouteCodes } from 'modules/nominees/manage_allocations/router';
import { baseRoute as productProfileBuilderBaseRoute } from 'modules/product_profile_builder/router';
import {
  baseRoute as compareProductsBaseRouter,
  hideFooterRoutes as compareProductsNoFooterRoutes,
} from 'modules/compare_products/constants';
import OrganizationManagementMFE from 'modules/organization_management/containers/organization_management_mfe/OrganizationManagementMFE';
import SalesEnablementMFE from 'modules/sales_enablement/containers/sales_enablement_mfe/SalesEnablementMFE';
import {
  baseRoute as ProductAccessBaseRoute,
  hideFooterRoutes as productAccessNoFooterRoutes,
} from 'modules/product_access/constants/router';
import { routeCodes as passportRouteCodes } from 'modules/passport/router';
import useHeartBeat from './hooks/useHeartBeat';
import history from './createHistory';
import { routeCodes } from './router';
import Shell from './Shell';
import useRouteListeners from './hooks/useRouteListeners';
import LandingPageApp from './modules/landing_page/App';

const FOOTER_BLACKLIST = {
  [routeCodes.FUND_PROFILE]: '/icn_react/static/funds/[0-9]+',
  ...compareProductsNoFooterRoutes,
  ...productAccessNoFooterRoutes,
  login: routeCodes.LOGIN_MFE,
};

const Compliance = Loadable({
  loader: () => import(/* webpackChunkName: 'compliance' */ './containers/compliance'),
  loading: Loading,
});

const LoadableDocCenterEntry = Loadable({
  loader: () => import(/* webpackChunkName: "DocCenterEntry" */ './containers/doc_center/entry/entry.jsx'),
  loading: Loading,
});

const LoadableDocumentCenterAdminEntry = Loadable({
  loader: () =>
    import(/* webpackChunkName: "DocumentCenterAdminEntry" */ './containers/document_center_admin/entry/Entry.jsx'),
  loading: Loading,
});

const LoadableFundAdminStatements = Loadable({
  loader: () => import(/* webpackChunkName: "FundAdminStatements" */ './containers/fund_admin/statements.jsx'),
  loading: Loading,
});

const LoadableFundAdminReconciliation = Loadable({
  loader: () =>
    import(/* webpackChunkName: "FundAdminReconciliation" */ './containers/fund_admin/ReconciliationEntry.jsx'),
  loading: Loading,
});

const LoadableFundAdminCashFunding = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "ManageFundingStatus" */ './containers/fund_admin/capital_events/cash_funding/CashFunding.jsx'
    ),
  loading: Loading,
});

const LoadableFundAdminCapitalEvent = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "FundAdminCapitalEventsEntry" */ './containers/fund_admin/capital_events/CapitalEventsEntry'
    ),
  loading: Loading,
});

const LoadableFundAdminOperationsEntry = Loadable({
  loader: () => import('./containers/fund_admin/operations/operations.jsx'),
  loading: Loading,
});

const LoadableFundAdminFundFees = Loadable({
  loader: () => import(/* webpackChunkName: "FundAdminFundFees" */ './containers/fund_admin/fund_fees/FundFees.jsx'),
  loading: Loading,
});

const LoadableFundAdminGeneralLedgers = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "FundAdminGeneralLedgers" */ './containers/fund_admin/general_ledgers/GeneralLedgers.jsx'
    ),
  loading: Loading,
});

const LoadableDocumentManager = Loadable({
  loader: () =>
    import(/* webpackChunkName: "DocumentManager" */ './containers/document_manager/entry/documentManagerEntry'),
  loading: Loading,
});

const LoadableSubscriptionWorkflowStatus = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "SubscriptionWorkflowStatus" */ './containers/subscription_workflow_status/entry/SubscriptionWorkflowEntry.jsx'
    ),
  loading: Loading,
});

const LoadableFundDashboard = Loadable({
  loader: () => import(/* webpackChunkName: "FundDashboard" */ './containers/fund_dashboard/entry/entry.jsx'),
  loading: Loading,
});

const LoadableSalesDetail = Loadable({
  loader: () => import(/* webpackChunkName: "SalesDetail" */ './containers/sales_detail/entry/SalesDetailEntry'),
  loading: Loading,
});

const LoadableInvestorProfile = Loadable({
  loader: () => import(/* webpackChunkName: "InvestorProfile" */ './containers/investor_profile/entry/entry'),
  loading: Loading,
});

const LoadableNewInvestorProfile = Loadable({
  loader: () => import(/* webpackChunkName: "InvestorProfile" */ './containers/new_investor_profile'),
  loading: Loading,
});

const LoadableReporting = Loadable({
  loader: () => import(/* webpackChunkName: "Reporting" */ './containers/reporting/entry/entry'),
  loading: Loading,
});

const LoadableDelegation = Loadable({
  loader: () => import(/* webpackChunkName: "Delegation" */ './containers/delegation/entry/entry.jsx'),
  loading: Loading,
});

const LoadableInvestmentDashboard = Loadable({
  loader: () => import(/* webpackChunkName: "InvestmentDashboard" */ './containers/investment_dashboard/entry/Entry'),
  loading: Loading,
});

const LoadableExportHistory = Loadable({
  loader: () => import('./modules/export_history/ExportHistoryIndex'),
  loading: Loading,
});

const LoadableInvestmentDashboardExportHistory = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "InvestmentDashboard" */ './containers/investment_dashboard/export_history/ExportHistory'
    ),
  loading: Loading,
});

const LoadableNotificationEventsIndex = Loadable({
  loader: () => import('./containers/notification_events/index/NotificationEventsIndex'),
  loading: Loading,
});

const LoadableNotificationEventEdit = Loadable({
  loader: () => import('./containers/notification_events/edit/NotificationEventEdit'),
  loading: Loading,
});

const LoadableNotificationEventNew = Loadable({
  loader: () => import('./containers/notification_events/new/NotificationEventNew'),
  loading: Loading,
});

const LoadableAuthorizationGroupsIndex = Loadable({
  loader: () => import('./containers/authorization_groups/AuthorizationGroupsIndex.jsx'),
  loading: Loading,
});

const LoadableSubscriptionReviewer = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "SubscriptionReviewer" */ './containers/subscription_reviewer/entry/subscriptionReviewerEntry'
    ),
  loading: Loading,
});

const LoadableSubscriptionsDashboard = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "SubscriptionsDashboard" */ './containers/subscriptions_dashboard/entry/SubscriptionsDashboardEntry'
    ),
  loading: Loading,
});

const LoadableTransferReview = Loadable({
  loader: () => import(/* webpackChunkName: "TransferReviewEntry" */ './modules/transfers/review/TransferReviewPage'),
  loading: Loading,
});

const LoadableManageFundingStatus = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "ManageFundingStatus" */ './containers/manage_funding_status/entry/ManageFundingStatusEntry'
    ),
  loading: Loading,
});

const LoadableReportGeneratorAdminEdit = Loadable({
  loader: () => import('./containers/report_generator/view/ReportGeneratorAdminEdit'),
  loading: Loading,
});

const LoadableReportGeneratorAdmin = Loadable({
  loader: () => import('./containers/report_generator/entry/ReportGeneratorAdminEntry'),
  loading: Loading,
});

const LoadableReportingHub = Loadable({
  loader: () => import('./containers/reporting_hub/entry/ReportingHubEntry'),
  loading: Loading,
});

const LoadableReportingHubView = Loadable({
  loader: () => import('./containers/reporting_hub/ReportContainer/ReportContainer'),
  loading: Loading,
});

const LoadableReportGeneratorSuperAdmin = Loadable({
  loader: () =>
    import('./containers/report_schedule_requests/entry/ReportScheduleRequestsEntry/ReportScheduleRequestsEntry'),
  loading: Loading,
});

const LoadableReportGeneratorSuperAdminView = Loadable({
  loader: () =>
    import('./containers/report_schedule_requests/view/ReportScheduleRequestsView/ReportScheduleRequestsView'),
  loading: Loading,
});

const LoadableReportGeneratorScheduleLogs = Loadable({
  loader: () => import('./containers/report_schedule_logs/ReportScheduleLogsEntry/ReportScheduleLogsEntry'),
  loading: Loading,
});

const LoadableReportGeneratorGlossary = Loadable({
  loader: () => import('./containers/report_generator/GlossaryEntry/GlossaryEntry'),
  loading: Loading,
});

const LoadableSubscriptionDocuments = Loadable({
  loader: () => import(/* webpackChunkName: "SubscriptionDocuments" */ './containers/document_definitions/entry'),
  loading: Loading,
});

const LoadableDataDashboardIndex = Loadable({
  loader: () => import(/* webpackChunkName: "DataDashboardIndex" */ './containers/data_dashboard/DataDashboard'),
  loading: Loading,
});

const LoadableRakeTasksDashboardIndex = Loadable({
  loader: () => import(/* webpackChunkName: "RakeTasksDashboard" */ './containers/rake_tasks_dashboard/Entry'),
  loading: Loading,
});

const LoadableSubscriptionReview = Loadable({
  loader: () => import(/* webpackChunkName: "SubscriptionReview" */ './containers/subscription_review/entry/entry'),
  loading: Loading,
});

const LoadableApiLogFinder = Loadable({
  loader: () => import('./containers/api_log_finder/ApiLogFinder'),
  loading: Loading,
});

const LoadableSubDocExclusions = Loadable({
  loader: () => import('./containers/subdoc_exclusions/entry/subDocExclusionsEntry'),
  loading: Loading,
});

const LoadableIntegrationPartnerDashboard = Loadable({
  loader: () => import('./containers/integration_partners_dashboard/entry/entry'),
  loading: Loading,
});

const LoadableAmlSuitabilityReps = Loadable({
  loader: () => import('./containers/aml_suitability_reps/entry/amlSuitabilityRepsEntry'),
  loading: Loading,
});

const LoadableSupplementalForm = Loadable({
  loader: () => import('./containers/supplemental_form/entry/SupplementalFormEntry'),
  loading: Loading,
});

const LoadableRedemptionSupplementalForm = Loadable({
  loader: () => import('./containers/redemption_supplemental_form/entry/RedemptionSupplementalFormEntry'),
  loading: Loading,
});

const LoadableClientManagement = Loadable({
  loader: () =>
    import(/* webpackChunkName: "ClientManagement" */ './containers/client_management/entry/ClientManagement'),
  loading: Loading,
});

const LoadableFormBuilder = Loadable({
  loader: () => import('./containers/form_builder/entry/FormBuilderEntry'),
  loading: Loading,
});

const LoadableFormTemplates = Loadable({
  loader: () => import(/* webpackChunkName: "FormTemplates" */ './containers/form_templates/entry/FormTemplates'),
  loading: Loading,
});

const LoadableInvestorProfileConfiguration = Loadable({
  loader: () =>
    import(/* webpackChunkName: "InvestorProfileConfiguration" */ './containers/investor_profile_configuration'),
  loading: Loading,
});

const LoadableLogin = Loadable({
  loader: () => import('./containers/login/Login'),
  loading: Loading,
});

const LoadablePublishedDocumentExport = Loadable({
  loader: () => import('./containers/published_document_export/index.jsx'),
  loading: Loading,
});

const LoadableLevenshteinMatcher = Loadable({
  loader: () => import('./containers/levenshtein_matcher/LevenshteinMatcher.jsx'),
  loading: Loading,
});

const LoadableSecureDocumentTransfer = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "SecureDocumentTransfer" */ './containers/secure_document_transfer/entry/SecureDocumentTransfer'
    ),
  loading: Loading,
});

const LoadableAdminSearch = Loadable({
  loader: () => import(/* webpackChunkName: "InvestmentDashboard" */ './containers/admin_search/as_full_results/Entry'),
  loading: Loading,
});

const LoadableIndexMasters = Loadable({
  loader: () => import('./containers/fund_finances/index_masters/IndexMastersEntry'),
  loading: Loading,
});

const LoadableSynthesis = Loadable({
  loader: () => import('./containers/fund_finances/performance_statistics/FundFinanceSynthesisEntry'),
  loading: Loading,
});

const LoadableDocumentViewInfo = Loadable({
  loader: () => import(/* webpackChunkName: "DocumentViewInfo" */ './containers/document_view_info/entry/Entry'),
  loading: Loading,
});

const LoadableInvestorRepresentative = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "InvestorRepresentatives" */ './containers/investor_representative/entry/InvestorRepresentativeEntry'
    ),
  loading: Loading,
});

const LoadableTeamManagement = Loadable({
  loader: () => import('./containers/team_management/entry/TeamManagement'),
  loading: Loading,
});

const LoadableInvestorQuestionnaire = Loadable({
  loader: () => import('./containers/investor_representative/questionnaire/IRQuestionnaire'),
  loading: Loading,
});

const LoadableDataReconciliationIndex = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "DataReconciliationIndex" */ './containers/data_reconciliation/DataReconciliationIndex'
    ),
  loading: Loading,
});

const LoadableRequiredDocuments = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "RequiredDocuments" */ './modules/investments/required_documents/RequiredDocumentsIndex'
    ),
  loading: Loading,
});

const LoadableRevertedDocuments = Loadable({
  loader: () =>
    import(/* webpackChunkName: "RevertedDocuments" */ './modules/investments/reversion/RevertedDocumentsIndex'),
  loading: Loading,
});

const LoadableViewDocuments = Loadable({
  loader: () =>
    import(/* webpackChunkName: "ViewDocuments" */ './modules/investments/view_documents/ViewDocumentsIndex'),
  loading: Loading,
});

const LoadableCloseSchedule = Loadable({
  loader: () => import(/* webpackChunkName: "CloseSchedule" */ './modules/manage_close/CloseScheduleIndex'),
  loading: Loading,
});

const LoadableManageClose = Loadable({
  loader: () => import(/* webpackChunkName: "CloseSchedule" */ './modules/manage_close/ManageClosePage'),
  loading: Loading,
});

const LoadableRegistrationAccountType = Loadable({
  loader: () =>
    import(/* webpackChunkName: "RegistrationAccountTypes" */ './containers/registration/account_type/AccountType'),
  loading: Loading,
});

const LoadableRegistrationCreateAccount = Loadable({
  loader: () => import(/* webpackChunkName: "RegistrationCreateAccount" */ './containers/registration/Registration'),
  loading: Loading,
});

const LoadableMultifactorAuthenticationRegistration = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "MfaRegistration" */ './containers/login/multifactor_authentication/MfaRegistrationEntry'
    ),
  loading: Loading,
});

const LoadableMultifactorAuthenticationVerification = Loadable({
  loader: () =>
    import(/* webpackChunkName: "MfaVerification" */ './containers/login/multifactor_authentication/MfaVerification'),
  loading: Loading,
});

const LoadableRegistrationReviewAccount = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "RegistrationReviewAccount" */ './containers/registration/review_account/ReviewAccount'
    ),
  loading: Loading,
});

const LoadableImpersonationApp = Loadable({
  loader: () => import(/* webpackChunkName: 'ImpersonationApp' */ './modules/impersonation/App'),
  loading: Loading,
});

const LoadableProductProfileBuilderApp = Loadable({
  loader: () => import(/* webpackChunkName: 'ProductProfileBuilderApp' */ './modules/product_profile_builder/App'),
  loading: Loading,
});

const LoadableCompareProductsApp = Loadable({
  loader: () => import(/* webpackChunkName: 'CompareProductsApp' */ './modules/compare_products/App'),
  loading: Loading,
});

const LoadableProductPassportApp = Loadable({
  loader: () => import(/* webpackChunkName: 'PassportApp' */ './modules/passport/App'),
  loading: Loading,
});

const LoadableInvestmentBlocksManager = Loadable({
  loader: () => import('./containers/investment_blocks_manager/entry/InvestmentBlocksEntry'),
  loading: Loading,
});

const LoadableSetPassword = Loadable({
  loader: () => import('./containers/login/set_password/SetPassword'),
  loading: Loading,
});

const LoadablePrivacyPolicy = Loadable({
  loader: () => import('./containers/privacy_policy/PrivacyPolicy'),
  loading: Loading,
});

const LoadableTermsOfUse = Loadable({
  loader: () => import('./containers/terms_of_use/TermsOfUse'),
  loading: Loading,
});

const LoadableDisclosures = Loadable({
  loader: () => import('./containers/disclosures/Disclosures'),
  loading: Loading,
});

const LoadableThirdPartyVendors = Loadable({
  loader: () => import('./containers/third_party_vendors/ThirdPartyVendors'),
  loading: Loading,
});

const LoadablePreApprovalQuestionnaire = Loadable({
  loader: () => import('./containers/pre_approval_questionnaire/entry/PreApprovalQuestionnaireEntry'),
  loading: Loading,
});

const LoadableDownloadDataQualityReport = Loadable({
  loader: () => import('./containers/data_quality_report/DownloadReport'),
  loading: Loading,
});

const LoadableFundAdminTradeExecutions = Loadable({
  loader: () => import('./containers/fund_admin/trade_executions/TradeExecutions.jsx'),
  loading: Loading,
});

const LoadableFundAdminUploader = Loadable({
  loader: () => import(/* webpackChunkName: "FundAdminUploader" */ './containers/fund_admin/uploader/Uploader.jsx'),
  loading: Loading,
});

const LoadableHFARDetail = Loadable({
  loader: () => import('./containers/fund_admin/uploader/HFARDetail'),
  loading: Loading,
});

const LoadableManageDocumentDefinition = Loadable({
  loader: () => import('./containers/document_definitions/manage/Entry'),
  loading: Loading,
});

const LoadableAgreementSigning = Loadable({
  loader: () => import('./containers/agreement_signing/AgreementSigningEntry'),
  loading: Loading,
});

const LoadableRegistrationCompletionPage = Loadable({
  loader: () => import('./containers/sso_registration/RegistrationCompletionPage'),
  loading: Loading,
});

const LoadableFundProfile = Loadable({
  loader: () => import(/* webpackChunkName: 'FundProfile' */ './modules/fund_profile'),
  loading: Loading,
});

const LoadableProfile = Loadable({
  loader: () => import(/* webpackChunkName: 'ProfileApp' */ './modules/profile/App'),
  loading: Loading,
});

const LoadableSettings = Loadable({
  loader: () => import(/* webpackChunkName: 'SettingsApp' */ './modules/settings/App'),
  loading: Loading,
});

const LoadableDlt = Loadable({
  loader: () => import(/* webpackChunkName: 'DltApp' */ './modules/dlt/App'),
  loading: Loading,
});

const LoadableReleaseNotes = Loadable({
  loader: () => import(/* webpackChunkName: 'ReleaseNotesApp' */ './modules/release_notes/App'),
  loading: Loading,
});

const LoadableFundDocumentEmails = Loadable({
  loader: () =>
    import(/* webpackChunkName: "FundDocumentEmails" */ './containers/fund_document_emails/entry/FundDocumentEmails'),
  loading: Loading,
});

const LoadableWlpAdmin = Loadable({
  loader: () => import('modules/wlp_admin/WlpAdmin'),
  loading: Loading,
});

const LoadablePdfViewer = Loadable({
  loader: () => import('./modules/pdf_viewer/PdfViewerEntry'),
  loading: Loading,
});

const LoadableMassMailer = Loadable({
  loader: () => import(/* webpackChunkName: "MassMailer" */ './containers/mass_mailer/entry/MassMailer'),
  loading: Loading,
});

const LoadableEsignHub = Loadable({
  loader: () => import(/* webpackChunkName: "EsignHub" */ './containers/esign_hub/entry/EsignHub'),
  loading: Loading,
});

const LoadableEsignHubAuth = Loadable({
  loader: () => import(/* webpackChunkName: "EsignHubAuth" */ './containers/esign_hub/auth/Auth'),
  loading: Loading,
});

const LoadableLibraryTemplates = Loadable({
  loader: () => import(/* webpackChunkName: "LibraryTemplates" */ './modules/library_templates/LibraryTemplatesIndex'),
  loading: Loading,
});

const LoadableNomineeReview = Loadable({
  loader: () => import(/* webpackChunkName: "NomineeReview" */ './containers/nominee_review/entry/NREntry.jsx'),
  loading: Loading,
});

const LoadableEducationApp = Loadable({
  loader: () => import(/* webpackChunkName: "EducationApp" */ './modules/education/App'),
  loading: Loading,
});

const LoadableActivityLogApp = Loadable({
  loader: () => import(/* webpackChunkName: "ActivityLogApp" */ './modules/activity_log/App'),
  loading: Loading,
});

const LoadableInvestmentOpportunityApp = Loadable({
  loader: () => import(/* webpackChunkName: "ActivityLogApp" */ './modules/investment_opportunity/App'),
  loading: Loading,
});

const LoadableConfigurationMasterApp = Loadable({
  loader: () => import(/* webpackChunkName: "ConfigurationMasterApp" */ './modules/configuration_master/App'),
  loading: Loading,
});

const LoadableIntegrationHubApp = Loadable({
  loader: () => import(/* webpackChunkName: "IHubApp" */ '@ihub'),
  loading: Loading,
});

const LoadableValidatrApp = Loadable({
  loader: () => import(/* webpackChunkName: "ValidatrApp" */ '@validatr'),
  loading: Loading,
});

const LoadableSendGPCertificate = Loadable({
  loader: () => import('./containers/send_gp_certificate/SendGPCertificate'),
  loading: Loading,
});

const LoadableArchitectSaml = Loadable({
  loader: () => import(/* webpackChunkName: "ArchitectSaml" */ './containers/architect_saml/ArchitectSaml'),
  loading: Loading,
});

const LoadableAdvisorDashboard = Loadable({
  loader: () => import(/* webpackChunkName: "AdvisorDashboard" */ './modules/advisor_dashboard/AdvisorDashboardEntry'),
  loading: Loading,
});

const LoadableBulkInvestment = Loadable({
  loader: () => import(/* webpackChunkName: "BulkInvestment" */ './modules/bulk_investment'),
  loading: Loading,
});

const LoadableBulkInvestmentModal = Loadable({
  loader: () => import(/* webpackChunkName: "BulkInvestmentModal" */ './modules/bulk_investment/modal'),
  loading: Loading,
});

const LoadableConsentForm = Loadable({
  loader: () => import(/* webpackChunkName: "ConsentForm" */ './containers/consent_form/entry/ConsentForm'),
  loading: Loading,
});

const LoadableLetterOfAuthorization = Loadable({
  loader: () => import(/* webpackChunkName: "LetterOfAuthorization" */ './modules/letter_of_authorization'),
  loading: Loading,
});

const LoadableLetterOfAuthorizationModal = Loadable({
  loader: () => import(/* webpackChunkName: "LetterOfAuthorizationModal" */ './modules/letter_of_authorization/modal'),
  loading: Loading,
});

const LoadableInitiateTransferModal = Loadable({
  loader: () => import(/* webpackChunkName: "InitiateTransferModal" */ './modules/initiate_transfer/modal'),
  loading: Loading,
});

const LoadableManageNomineeAllocations = Loadable({
  loader: () => import(/* webpackChunkName: "ManageNomineeAllocations" */ './modules/nominees/manage_allocations'),
  loading: Loading,
});

const LoadableProductAccessApp = Loadable({
  loader: () => import(/* webpackChunkName: 'ProductAccessApp' */ './modules/product_access/App'),
  loading: Loading,
});

const SiAnnuitiesMfe = () => <div id="single-spa-application:@simon/simonmarkets-web" />;

// eslint-disable-next-line max-statements
export function App(props) {
  const { icnReactBootstrap, dispatch, displaySpinner, enqueueSnackbarProp, closeSnackbarProp, loadUsers, user } =
    props;
  const location = useLocation();
  const { pathname } = history.location;
  const hasBootstrap = !isEmpty(icnReactBootstrap);
  // Change to correct multi asset permission when created
  const isMultiAsset = hasBootstrap ? icnReactBootstrap.user?.permissions?.includes('see_unified_homepage') : false;

  const checkFooterBlacklist = () => Object.values(FOOTER_BLACKLIST).some((exp) => new RegExp(exp).test(pathname));

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    initializeBootstrap(dispatch, enqueueSnackbarProp ?? enqueueSnackbar, closeSnackbarProp ?? closeSnackbar);
  }, [dispatch]);

  useEffect(() => {
    if (
      (user && icnReactBootstrap?.white_label_partner?.allow_passport_enrollment) ||
      user?.passport_linked_users.includes(user.id)
    ) {
      loadUsers();
    }
  }, [loadUsers, user]);

  useHeartBeat(icnReactBootstrap);
  useRouteListeners();

  const findDeepestLocation = (loc) => {
    if (loc.state?.background) {
      return findDeepestLocation(loc.state.background);
    }
    return loc;
  };

  const resolvedLocation = findDeepestLocation(location);

  return (
    <Shell>
      <div
        className={classNames({
          App: true,
          staging: icnReactBootstrap && icnReactBootstrap.hasOwnProperty('env') && icnReactBootstrap.env === 'staging',
          disable_animations:
            icnReactBootstrap &&
            icnReactBootstrap.hasOwnProperty('disable_animations_flag') &&
            icnReactBootstrap.disable_animations_flag,
        })}
      >
        {displaySpinner === false || isMultiAsset ? '' : <IcnSpinner />}
        {hasBootstrap && (
          <>
            <Switch location={resolvedLocation}>
              <Route exact path={routeCodes.ESIGN_HUB_AUTH} component={LoadableEsignHubAuth} />
              <Route path={routeCodes.ESIGN_HUB} component={LoadableEsignHub} />
              <Route exact path={routeCodes.SECURE_DOCUMENT_TRANSFER} component={LoadableSecureDocumentTransfer} />
              <Route exact path={routeCodes.SET_PASSWORD} component={LoadableSetPassword} />

              <Route exact path={routeCodes.PDF_VIEWER} component={LoadablePdfViewer} />

              <Route exact path={routeCodes.CONSENT_FORM} component={LoadableConsentForm} />

              <PrivateRoute
                path={passportRouteCodes.BASE}
                key={passportRouteCodes.BASE}
                component={LoadableProductPassportApp}
              />

              <LayoutGlobal hideFooter={checkFooterBlacklist()} hideBanners={checkFooterBlacklist()}>
                <Switch location={resolvedLocation}>
                  <Route exact path={routeCodes.LOGIN} component={LoadableLogin} />
                  <Route exact path={routeCodes.TERMS_OF_USE} component={LoadableTermsOfUse} />
                  <Route exact path={routeCodes.PRIVACY_POLICY} component={LoadablePrivacyPolicy} />
                  <Route exact path={routeCodes.DISCLOSURES} component={LoadableDisclosures} />
                  <PrivateRoute exact path={routeCodes.THIRD_PARTY_VENDORS} component={LoadableThirdPartyVendors} />
                  <PrivateRoute path={routeCodes.COMPLIANCE} component={Compliance} />
                  <PrivateRoute exact path={routeCodes.DOC_CENTER} component={LoadableDocCenterEntry} />
                  <PrivateRoute exact path={routeCodes.DOC_CENTER_ADMIN} component={LoadableDocumentCenterAdminEntry} />
                  <PrivateRoute
                    key={`${routeCodes.INV_PROF}/:profileId/:stepPath?/:step?`}
                    path={`${routeCodes.INV_PROF}/:profileId/:stepPath?/:step?`}
                    component={LoadableInvestorProfile}
                  />
                  <PrivateRoute
                    key={routeCodes.NEW_INVESTOR_PROFILE}
                    path={routeCodes.NEW_INVESTOR_PROFILE}
                    component={LoadableNewInvestorProfile}
                  />
                  <PrivateRoute
                    exact
                    path={`${routeCodes.FUND_ADMIN_STATEMENTS}/:id?/:statementsPath?/:reportId?`}
                    component={LoadableFundAdminStatements}
                  />
                  <PrivateRoute
                    exact
                    path={routeCodes.FUND_ADMIN_OPERATIONS}
                    component={LoadableFundAdminOperationsEntry}
                  />
                  <PrivateRoute
                    exact
                    path={`${routeCodes.FUND_ADMIN_FUND_FEES}/:tab?`}
                    component={LoadableFundAdminFundFees}
                  />
                  <PrivateRoute
                    exact
                    path={`${routeCodes.FUND_ADMIN_GENERAL_LEDGERS}/:tab?`}
                    component={LoadableFundAdminGeneralLedgers}
                  />
                  <PrivateRoute
                    exact
                    path={`${routeCodes.FUND_ADMIN_RECONCILIATION}/:reconciliationPath?`}
                    component={LoadableFundAdminReconciliation}
                  />
                  <PrivateRoute
                    exact
                    path={routeCodes.FUND_ADMIN_TRADE_EXECUTIONS}
                    component={LoadableFundAdminTradeExecutions}
                  />
                  <PrivateRoute
                    exact
                    path={`${routeCodes.FUND_ADMIN_FUNDING_STATUS}/:fundingTab?`}
                    component={LoadableFundAdminCashFunding}
                  />
                  <PrivateRoute
                    exact
                    path={routeCodes.FUND_ADMIN_CAPITAL_EVENTS}
                    component={LoadableFundAdminCapitalEvent}
                  />
                  <PrivateRoute
                    exact
                    path={routeCodes.FUND_ADMIN_UNDERLYING_CAPITAL_EVENTS}
                    component={LoadableFundAdminCapitalEvent}
                  />
                  <PrivateRoute exact path={routeCodes.FUND_ADMIN_UPLOADER} component={LoadableFundAdminUploader} />
                  <PrivateRoute exact path={routeCodes.FUND_ADMIN_HFAR_DETAIL} component={LoadableHFARDetail} />
                  <PrivateRoute
                    exact
                    key={routeCodes.REPORT_GENERATOR}
                    path={routeCodes.REPORT_GENERATOR}
                    component={LoadableReportGeneratorAdmin}
                  />
                  <PrivateRoute
                    exact
                    key={routeCodes.REPORTING_HUB}
                    path={routeCodes.REPORTING_HUB}
                    component={LoadableReportingHub}
                  />
                  <PrivateRoute
                    exact
                    key={routeCodes.REPORTING_HUB_CTX}
                    path={routeCodes.REPORTING_HUB_CTX}
                    component={LoadableReportingHub}
                  />
                  <PrivateRoute
                    exact
                    key={routeCodes.REPORTING_HUB_VIEW}
                    path={routeCodes.REPORTING_HUB_VIEW}
                    component={LoadableReportingHubView}
                  />
                  <PrivateRoute
                    exact
                    key={routeCodes.REPORTING_HUB_VIEW_CTX}
                    path={routeCodes.REPORTING_HUB_VIEW_CTX}
                    component={LoadableReportingHubView}
                  />
                  <PrivateRoute
                    exact
                    key={routeCodes.REPORT_SCHEDULE_REQUESTS}
                    path={routeCodes.REPORT_SCHEDULE_REQUESTS}
                    component={LoadableReportGeneratorSuperAdmin}
                  />
                  <PrivateRoute
                    exact
                    key={routeCodes.VIEW_REPORT_SCHEDULE_REQUESTS}
                    path={routeCodes.VIEW_REPORT_SCHEDULE_REQUESTS}
                    component={LoadableReportGeneratorSuperAdminView}
                  />
                  <PrivateRoute
                    exact
                    key={routeCodes.EDIT_REPORT_GENERATOR}
                    path={routeCodes.EDIT_REPORT_GENERATOR}
                    component={LoadableReportGeneratorAdminEdit}
                  />
                  <PrivateRoute
                    exact
                    key={routeCodes.REPORT_SCHEDULE_LOGS}
                    path={routeCodes.REPORT_SCHEDULE_LOGS}
                    component={LoadableReportGeneratorScheduleLogs}
                  />
                  <PrivateRoute
                    exact
                    key={routeCodes.REPORT_GENERATOR_GLOSSARY}
                    path={routeCodes.REPORT_GENERATOR_GLOSSARY}
                    component={LoadableReportGeneratorGlossary}
                  />
                  <PrivateRoute
                    exact
                    key={routeCodes.DOC_MANAGER}
                    path={routeCodes.DOC_MANAGER}
                    component={LoadableDocumentManager}
                  />
                  <PrivateRoute
                    exact
                    key={routeCodes.SUBSCRIPTION_WORKFLOW_STATUS}
                    path={routeCodes.SUBSCRIPTION_WORKFLOW_STATUS}
                    component={LoadableSubscriptionWorkflowStatus}
                  />
                  <PrivateRoute
                    exact
                    key={routeCodes.FUND_DASH}
                    path={routeCodes.FUND_DASH}
                    component={LoadableFundDashboard}
                  />
                  <PrivateRoute
                    exact
                    key={routeCodes.INVITE_HISTORY}
                    path={routeCodes.INVITE_HISTORY}
                    component={LoadableSalesDetail}
                  />
                  <PrivateRoute
                    exact
                    key={routeCodes.SALES_DETAIL}
                    path={routeCodes.SALES_DETAIL}
                    component={LoadableSalesDetail}
                  />

                  <PrivateRoute key={routeCodes.REPORTING} path={routeCodes.REPORTING} component={LoadableReporting} />

                  <PrivateRoute
                    exact
                    key={routeCodes.DELEGATION}
                    path={routeCodes.DELEGATION}
                    component={LoadableDelegation}
                  />

                  <PrivateRoute
                    key={routeCodes.SUBSCRIPTION_REVIEWER}
                    path={routeCodes.SUBSCRIPTION_REVIEWER}
                    component={LoadableSubscriptionReviewer}
                  />

                  <PrivateRoute
                    exact
                    key={routeCodes.SUBSCRIPTION_DOCUMENTS}
                    path={routeCodes.SUBSCRIPTION_DOCUMENTS}
                    component={LoadableSubscriptionDocuments}
                  />

                  <PrivateRoute
                    key={routeCodes.DATA_DASHBOARD}
                    path={routeCodes.DATA_DASHBOARD}
                    component={LoadableDataDashboardIndex}
                  />

                  <PrivateRoute
                    key={routeCodes.RAKE_TASKS_DASHBOARD}
                    path={routeCodes.RAKE_TASKS_DASHBOARD}
                    component={LoadableRakeTasksDashboardIndex}
                  />

                  <PrivateRoute
                    key={routeCodes.DATA_RECONCILIATION}
                    path={routeCodes.DATA_RECONCILIATION}
                    component={LoadableDataReconciliationIndex}
                  />

                  <PrivateRoute exact path={routeCodes.REQUIRED_DOCUMENTS} component={LoadableRequiredDocuments} />

                  <PrivateRoute
                    exact
                    key={routeCodes.INVESTMENT_DASHBOARD}
                    path={routeCodes.INVESTMENT_DASHBOARD}
                    component={LoadableInvestmentDashboard}
                  />

                  <PrivateRoute path={routeCodes.REVERTED_DOCUMENTS} component={LoadableRevertedDocuments} />

                  <PrivateRoute path={routeCodes.VIEW_DOCUMENTS} component={LoadableViewDocuments} />

                  <PrivateRoute path={routeCodes.CLOSE_SCHEDULE} component={LoadableCloseSchedule} />

                  <PrivateRoute path={routeCodes.MANAGE_CLOSE} component={LoadableManageClose} />

                  <PrivateRoute
                    exact
                    key={routeCodes.EXPORT_HISTORY}
                    path={routeCodes.EXPORT_HISTORY}
                    component={LoadableInvestmentDashboardExportHistory}
                  />

                  <PrivateRoute
                    exact
                    key={routeCodes.CM_EXPORT_HISTORY}
                    path={routeCodes.CM_EXPORT_HISTORY}
                    component={LoadableExportHistory}
                  />

                  <PrivateRoute
                    exact
                    key={routeCodes.NOTIFICATION_EVENTS}
                    path={routeCodes.NOTIFICATION_EVENTS}
                    component={LoadableNotificationEventsIndex}
                  />

                  <PrivateRoute
                    exact
                    key={`${routeCodes.NOTIFICATION_EVENTS}/new`}
                    path={`${routeCodes.NOTIFICATION_EVENTS}/new`}
                    component={LoadableNotificationEventNew}
                  />

                  <PrivateRoute
                    exact
                    key={`${routeCodes.NOTIFICATION_EVENTS}/:eventId`}
                    path={`${routeCodes.NOTIFICATION_EVENTS}/:eventId`}
                    component={LoadableNotificationEventEdit}
                  />

                  <PrivateRoute
                    exact
                    key={routeCodes.AUTHORIZATION_GROUPS}
                    path={routeCodes.AUTHORIZATION_GROUPS}
                    component={LoadableAuthorizationGroupsIndex}
                  />

                  <PrivateRoute
                    exact
                    key={routeCodes.SUBSCRIPTIONS_DASHBOARD}
                    path={routeCodes.SUBSCRIPTIONS_DASHBOARD}
                    component={LoadableSubscriptionsDashboard}
                  />

                  <PrivateRoute path={transferReviewRouteCodes.BASE} component={LoadableTransferReview} />

                  <PrivateRoute
                    key={routeCodes.SEND_GP_CERTIFICATE}
                    path={routeCodes.SEND_GP_CERTIFICATE}
                    component={LoadableSendGPCertificate}
                  />

                  <PrivateRoute
                    key={routeCodes.NOMINEE_REVIEW}
                    path={routeCodes.NOMINEE_REVIEW}
                    component={LoadableNomineeReview}
                  />

                  <PrivateRoute
                    key={`${routeCodes.SUBSCRIPTION_REVIEW}/:pafId?`}
                    path={`${routeCodes.SUBSCRIPTION_REVIEW}/:pafId?`}
                    component={LoadableSubscriptionReview}
                  />

                  <PrivateRoute
                    key={routeCodes.INTEGRATION_PARTNERS_DASHBOARD}
                    path={routeCodes.INTEGRATION_PARTNERS_DASHBOARD}
                    component={LoadableIntegrationPartnerDashboard}
                  />

                  <PrivateRoute
                    exact
                    key={routeCodes.MANAGE_FUNDING_STATUS}
                    path={routeCodes.MANAGE_FUNDING_STATUS}
                    component={LoadableManageFundingStatus}
                  />

                  <PrivateRoute
                    exact
                    key={routeCodes.API_LOG_FINDER}
                    path={routeCodes.API_LOG_FINDER}
                    component={LoadableApiLogFinder}
                  />

                  <PrivateRoute
                    key={routeCodes.SUBDOC_EXCLUSIONS}
                    path={routeCodes.SUBDOC_EXCLUSIONS}
                    component={LoadableSubDocExclusions}
                  />

                  <PrivateRoute
                    key={routeCodes.SUPPLEMENTAL_FORM}
                    path={routeCodes.SUPPLEMENTAL_FORM}
                    component={LoadableSupplementalForm}
                  />

                  <PrivateRoute
                    key={routeCodes.SUPPLEMENTAL_FORM_PRE}
                    path={routeCodes.SUPPLEMENTAL_FORM_PRE}
                    component={LoadableSupplementalForm}
                  />

                  <PrivateRoute
                    key={routeCodes.REDEMPTION_SUPPLEMENTAL_FORM}
                    path={routeCodes.REDEMPTION_SUPPLEMENTAL_FORM}
                    component={LoadableRedemptionSupplementalForm}
                  />

                  <PrivateRoute
                    key={routeCodes.RELEASE_NOTES}
                    path={routeCodes.RELEASE_NOTES}
                    component={LoadableReleaseNotes}
                  />

                  <PrivateRoute
                    key={routeCodes.PUBLISHED_DOCUMENT_EXPORT}
                    path={routeCodes.PUBLISHED_DOCUMENT_EXPORT}
                    component={LoadablePublishedDocumentExport}
                  />

                  <PrivateRoute
                    key={routeCodes.CLIENT_MANAGEMENT}
                    path={routeCodes.CLIENT_MANAGEMENT}
                    component={LoadableClientManagement}
                  />

                  <PrivateRoute
                    key={routeCodes.FORM_BUILDER}
                    path={routeCodes.FORM_BUILDER}
                    component={LoadableFormBuilder}
                  />

                  <PrivateRoute
                    key={routeCodes.FORM_TEMPLATES}
                    path={routeCodes.FORM_TEMPLATES}
                    component={LoadableFormTemplates}
                  />

                  <PrivateRoute
                    key={routeCodes.INVESTOR_PROFILE_CONFIGURATION}
                    path={routeCodes.INVESTOR_PROFILE_CONFIGURATION}
                    component={LoadableInvestorProfileConfiguration}
                  />

                  <PrivateRoute
                    key={routeCodes.INDEXMASTERS}
                    path={routeCodes.INDEXMASTERS}
                    component={LoadableIndexMasters}
                  />

                  <PrivateRoute
                    key={`${routeCodes.INDEXRETURNS}/:fundFinanceModel?/:performanceModel?`}
                    path={`${routeCodes.INDEXRETURNS}/:fundFinanceModel?/:performanceModel?`}
                    component={LoadableSynthesis}
                  />

                  <PrivateRoute
                    key={routeCodes.LEVENSHTEIN_MATCHER}
                    path={routeCodes.LEVENSHTEIN_MATCHER}
                    component={LoadableLevenshteinMatcher}
                  />

                  <PrivateRoute
                    key={routeCodes.AML_SUITABILITY_REPS}
                    path={routeCodes.AML_SUITABILITY_REPS}
                    component={LoadableAmlSuitabilityReps}
                  />

                  <PrivateRoute
                    key={routeCodes.INVESTOR_REPRESENTATIVE}
                    path={routeCodes.INVESTOR_REPRESENTATIVE}
                    component={LoadableInvestorRepresentative}
                  />

                  <PrivateRoute
                    key={`${routeCodes.INVESTOR_QUESTIONNAIRE}/:stepPath?/:step?/:investorId?`}
                    path={`${routeCodes.INVESTOR_QUESTIONNAIRE}/:stepPath?/:step?/:investorId?`}
                    component={LoadableInvestorQuestionnaire}
                  />

                  <PrivateRoute
                    key={routeCodes.DOCUMENT_VIEW_INFO}
                    path={routeCodes.DOCUMENT_VIEW_INFO}
                    component={LoadableDocumentViewInfo}
                  />

                  <PrivateRoute
                    exact
                    key={routeCodes.ADMIN_SEARCH}
                    path={routeCodes.ADMIN_SEARCH}
                    component={LoadableAdminSearch}
                  />

                  <PrivateRoute
                    key={routeCodes.TEAM_MANAGEMENT}
                    path={routeCodes.TEAM_MANAGEMENT}
                    component={LoadableTeamManagement}
                  />

                  <PrivateRoute
                    key={routeCodes.INVESTMENT_BLOCKS_MANAGER}
                    path={routeCodes.INVESTMENT_BLOCKS_MANAGER}
                    component={LoadableInvestmentBlocksManager}
                  />

                  <PrivateRoute path={Object.values(impersonationRouteCodes)[0]} component={LoadableImpersonationApp} />

                  <PrivateRoute
                    path={productProfileBuilderBaseRoute}
                    key={productProfileBuilderBaseRoute}
                    component={LoadableProductProfileBuilderApp}
                  />

                  <PrivateRoute
                    path={compareProductsBaseRouter}
                    key={compareProductsBaseRouter}
                    component={LoadableCompareProductsApp}
                  />

                  <PrivateRoute
                    key={routeCodes.PRE_APPROVAL_QUESTIONNAIRE}
                    path={routeCodes.PRE_APPROVAL_QUESTIONNAIRE}
                    component={LoadablePreApprovalQuestionnaire}
                  />

                  <PrivateRoute
                    key={routeCodes.DATA_QUALITY_REPORT_DOWNLOAD}
                    path={routeCodes.DATA_QUALITY_REPORT_DOWNLOAD}
                    component={LoadableDownloadDataQualityReport}
                  />

                  <PrivateRoute
                    key={routeCodes.DEFINITION_CREATE}
                    path={routeCodes.DEFINITION_CREATE}
                    component={() => <LoadableManageDocumentDefinition isCreate />}
                  />

                  <PrivateRoute path={`${routeCodes.DEFINITION_EDIT}`} component={LoadableManageDocumentDefinition} />

                  <PrivateRoute
                    key={routeCodes.AGREEMENT_SIGNING}
                    path={routeCodes.AGREEMENT_SIGNING}
                    component={LoadableAgreementSigning}
                  />

                  <PrivateRoute
                    key={routeCodes.FUND_DOC_EMAILS}
                    path={routeCodes.FUND_DOC_EMAILS}
                    component={LoadableFundDocumentEmails}
                  />

                  <PrivateRoute key={routeCodes.WLP_ADMIN} path={routeCodes.WLP_ADMIN} component={LoadableWlpAdmin} />

                  <PrivateRoute exact path={routeCodes.LIBRARY_TEMPLATES} component={LoadableLibraryTemplates} />

                  <Route
                    key={routeCodes.REGISTRATION_REVIEW_ACCOUNT}
                    path={routeCodes.REGISTRATION_REVIEW_ACCOUNT}
                    render={(routeProps) =>
                      icnReactBootstrap.hasOwnProperty('feature_flags') &&
                      icnReactBootstrap.feature_flags.enable_registration_refresh ? (
                        <LoadableRegistrationReviewAccount {...routeProps} />
                      ) : (
                        <Redirect to={routeCodes.LOGIN} />
                      )
                    }
                  />

                  <Route
                    key={routeCodes.REGISTRATION_ACCOUNT_TYPE}
                    path={routeCodes.REGISTRATION_ACCOUNT_TYPE}
                    render={(routeProps) =>
                      icnReactBootstrap?.feature_flags?.enable_registration_refresh ? (
                        <LoadableRegistrationAccountType {...routeProps} />
                      ) : (
                        <Redirect to={routeCodes.LOGIN} />
                      )
                    }
                  />

                  <Route
                    key={routeCodes.REGISTRATION_PROCESS}
                    path={routeCodes.REGISTRATION_PROCESS}
                    render={(routeProps) =>
                      icnReactBootstrap.hasOwnProperty('feature_flags') &&
                      icnReactBootstrap.feature_flags.enable_registration_refresh ? (
                        <LoadableRegistrationCreateAccount {...routeProps} />
                      ) : (
                        <Redirect to={routeCodes.LOGIN} />
                      )
                    }
                  />

                  <Route
                    exact
                    path={routeCodes.SSO_REGISTRATION_COMPLETION}
                    component={LoadableRegistrationCompletionPage}
                  />

                  <PrivateRoute
                    key={routeCodes.MULTIFACTOR_AUTHENTICATION_REGISTER}
                    path={routeCodes.MULTIFACTOR_AUTHENTICATION_REGISTER}
                    component={LoadableMultifactorAuthenticationRegistration}
                  />

                  <Route
                    key={routeCodes.MULTIFACTOR_AUTHENTICATION_VERIFY}
                    path={routeCodes.MULTIFACTOR_AUTHENTICATION_VERIFY}
                    component={LoadableMultifactorAuthenticationVerification}
                  />

                  <PrivateRoute
                    key={routeCodes.FUND_PROFILE}
                    path={routeCodes.FUND_PROFILE}
                    component={
                      icnReactBootstrap?.white_label_partner?.use_ppb_fund_profiles
                        ? LoadableProductProfileBuilderApp
                        : LoadableFundProfile
                    }
                  />

                  <PrivateRoute
                    key={routeCodes.RESEARCH_FUND_PROFILE}
                    path={routeCodes.RESEARCH_FUND_PROFILE}
                    component={LoadableProductProfileBuilderApp}
                  />

                  <PrivateRoute
                    path={Object.entries(educationRouteCodes).map((route) => `${route[1]}`)}
                    component={LoadableEducationApp}
                  />

                  <PrivateRoute
                    key={routeCodes.ORGANIZATION_MANAGEMENT}
                    path={routeCodes.ORGANIZATION_MANAGEMENT}
                    component={OrganizationManagementMFE}
                  />

                  <PrivateRoute
                    key={routeCodes.SALES_ENABLEMENT}
                    path={routeCodes.SALES_ENABLEMENT}
                    component={SalesEnablementMFE}
                  />

                  <PrivateRoute
                    path={Object.entries(activityLogRouteCodes).map((route) => `${route[1]}`)}
                    component={LoadableActivityLogApp}
                  />

                  <PrivateRoute
                    path={Object.entries(investmentOpportunityRouteCodes).map((route) => `${route[1]}`)}
                    component={LoadableInvestmentOpportunityApp}
                  />

                  <PrivateRoute
                    path={Object.entries(landingPageRouteCodes).map((route) => `${route[1]}`)}
                    component={LandingPageApp}
                  />

                  <PrivateRoute
                    path={Object.entries(configurationMasterRouteCodes).map((route) => `${route[1]}`)}
                    component={LoadableConfigurationMasterApp}
                  />

                  <PrivateRoute path={Object.values(profileRouteCodes)} component={LoadableProfile} />

                  <PrivateRoute path={Object.values(settingsRouteCodes)} component={LoadableSettings} />

                  <PrivateRoute path={Object.values(releaseNotesRouteCodes)} component={LoadableReleaseNotes} />

                  <PrivateRoute exact path={routeCodes.ARCHITECT} component={LoadableArchitectSaml} />

                  <PrivateRoute exact path={routeCodes.DLT} component={LoadableDlt} />

                  <PrivateRoute path={advisorDashboardRoute} component={LoadableAdvisorDashboard} />

                  <PrivateRoute path={Object.values(integrationHubRouteCodes)} component={LoadableIntegrationHubApp} />

                  <PrivateRoute path={bulkInvestmentRouteCodes.BASE} component={LoadableBulkInvestment} />

                  <PrivateRoute
                    key={`${routeCodes.MASS_MAILER_DOCUMENTS}/:tab?`}
                    path={`${routeCodes.MASS_MAILER_DOCUMENTS}/:tab?`}
                    component={LoadableMassMailer}
                  />

                  <PrivateRoute path={Object.values(validatrRouteCodes)} component={LoadableValidatrApp} />

                  <PrivateRoute path={LetterOfAuthorizationRouteCodes.BASE} component={LoadableLetterOfAuthorization} />

                  <PrivateRoute
                    path={ManageNomineeAllocationsRouteCodes.BASE}
                    component={LoadableManageNomineeAllocations}
                  />

                  <PrivateRoute
                    path={ProductAccessBaseRoute}
                    key={ProductAccessBaseRoute}
                    component={LoadableProductAccessApp}
                  />

                  {/* Match MFE routes so that they don't render NotFoundEntry */}
                  <Route
                    path={routeCodes.LOGIN_MFE}
                    render={() => <div id="single-spa-application:@icapitalnetwork/login" />}
                  />
                  <PrivateRoute path={routeCodes.SI_MFE} component={SiAnnuitiesMfe} />

                  <PrivateRoute path="*" component={NotFoundEntry} />
                </Switch>
                {location.state?.background && (
                  <Switch>
                    <PrivateRoute path={bulkInvestmentRouteCodes.BASE} component={LoadableBulkInvestmentModal} />
                    <PrivateRoute
                      path={LetterOfAuthorizationRouteCodes.BASE}
                      component={LoadableLetterOfAuthorizationModal}
                    />
                    <PrivateRoute path={initiateTransferRouteCodes.BASE} component={LoadableInitiateTransferModal} />
                  </Switch>
                )}
              </LayoutGlobal>
            </Switch>
            {location.state?.background && (
              <Switch>
                <Route path={routeCodes.ESIGN_HUB} component={LoadableEsignHub} />
              </Switch>
            )}
          </>
        )}
      </div>
    </Shell>
  );
}

function mapStateToProps(state) {
  const icnReactBootstrap = icnBootstrapSelectors.getIcnReactBootstrap(state);
  return {
    icnReactBootstrap,
    displaySpinner: state.spinner.display,
    users: getPassportCandidates(state),
    user: getUser(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        loadUsers: passFetchCandidates,
      },
      dispatch
    ),
  };
}

App.defaultProps = {
  icnReactBootstrap: null,
  enqueueSnackbarProp: null,
  closeSnackbarProp: null,
  loadUsers: null,
  user: null,
};

App.propTypes = {
  icnReactBootstrap: icnReactBootstrapShape,
  dispatch: func.isRequired,
  enqueueSnackbarProp: func,
  closeSnackbarProp: func,
  loadUsers: func,
  user: shape({}),
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
