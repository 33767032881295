import update from 'immutability-helper';
import { FORM_TEMPLATES_VERSIONS_CREATE } from './actions';

export const initialState = {
  isLoading: true,
  formTemplate: null,
};

export const formTemplateVersionCreateReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FORM_TEMPLATES_VERSIONS_CREATE.READ.REQUEST:
      return update(state, {
        isLoading: { $set: true },
      });
    case FORM_TEMPLATES_VERSIONS_CREATE.READ.SUCCESS:
      return update(state, {
        isLoading: { $set: false },
        formTemplate: { $set: payload.response },
      });
    case FORM_TEMPLATES_VERSIONS_CREATE.READ.FAILURE:
      return update(state, {
        isLoading: { $set: false },
      });
    case FORM_TEMPLATES_VERSIONS_CREATE.UNMOUNT:
      return initialState;
    default:
      return state;
  }
};

export default formTemplateVersionCreateReducer;
