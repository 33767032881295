import i18n from 'i18n';

export const EXPORT_BASE_OPTIONS = {
  investment_export: 'Investments',
  subscription_document_export: 'Documents',
};

export const EXPORT_OPTION_RESPONSES = [
  { label: 'All', value: 'all' },
  { label: 'Accepted', value: 'accepted' },
  { label: 'Pending', value: 'pending' },
];

export const FALLBACK_ERROR_MESSAGE = {
  message: i18n.t('common:messages.general_error', 'Sorry, something went wrong.'),
};

export const SUCCESS_MESSAGE = {
  message: i18n.t('client_management:upload_subdoc_modal.success_message', 'File uploaded successfully.'),
};

export const INVESTMENT_MANAGEMENT_ENTRY = Object.freeze({
  id: 'investment_management',
  label: i18n.t('client_management:header.investment_management_title'),
});

export const CLIENT_MANAGEMENT_ENTRY = Object.freeze({
  id: 'client_management',
  label: i18n.t('client_management:header.client_management_title'),
});

export const CLIENT_MANAGEMENT_PAGE_ENTRY = [INVESTMENT_MANAGEMENT_ENTRY, CLIENT_MANAGEMENT_ENTRY]
  .map((entry) => entry.id)
  .join('|');

export const ACCOUNT_NAME_SEARCH = Object.freeze({
  id: 'account_name',
  i18nPlaceholderKey: 'client_management:search_labels.account_name',
  i18nLabelKey: 'client_management:search_types.account_name',
});

export const ACCOUNT_NUMBER_SEARCH = Object.freeze({
  id: 'account_number',
  i18nPlaceholderKey: 'client_management:search_labels.account_number',
  i18nLabelKey: 'client_management:search_types.account_number',
});

export const CLIENT = Object.freeze({
  id: 'client',
  i18nPlaceholderKey: 'client_management:search_labels.client',
  i18nLabelKey: 'client_management:search_types.client',
});

export const INVESTMENT = Object.freeze({
  id: 'investment',
  i18nPlaceholderKey: 'client_management:search_labels.investment',
  i18nLabelKey: 'client_management:search_types.investment',
});

export const INVESTMENT_EXTERNAL_ID_SEARCH = Object.freeze({
  id: 'investment_external_id',
  i18nPlaceholderKey: 'client_management:search_labels.investment_external_id',
  i18nLabelKey: 'client_management:search_types.investment_external_id',
});

export const SEARCH_TYPES = Object.freeze([
  ACCOUNT_NAME_SEARCH,
  ACCOUNT_NUMBER_SEARCH,
  CLIENT,
  INVESTMENT,
  INVESTMENT_EXTERNAL_ID_SEARCH,
]);
