export default {
  // Index page states
  configs: [],
  configs_count: 0,
  results_per_page: 25,
  current_page: 1,
  sorting_column: 'created_at',
  sorting_order: 'desc',
  active_modal: null,
  selected_row: null,
  checked_rows: [],
  configFields: [],
  error: '',
  configLoaded: false,
  nameFilter: '',
  baseQueryFilter: '',
  deliveryFilter: '',

  // Edit page states
  current_configs: {
    currency_formatting: false,
    batch_processing_enabled: true,
  },
  available_fields: {},
  frequently_used_fields: {},
  options: {},
  initialOptions: {},
  requests: {},
  queries: {},
  query_totals: {},
  toggle_tracker: {},
  nullOptions: {},
  filteredOptions: {},
  filteredRequests: {},
  filteredQueries: {},
  filteredOptionsPicked: {},
  filteredTracker: {},
  appliedRanges: {},
  appliedFilterType: {},
  filteredTotals: {},
  excludedRanges: {},
  stored_queries: {},
  totals: {},
  storedExcludedQueries: {},
  filteredSubTotals: {},
  config_shared_users: [],
  selectedFields: {},
  selectedFieldMetadata: {},

  // Report visualization page states
  reportData: [],
  reportDataTableColumnMetadata: {},
  reportDataTableResponseType: null,
  reportDataFetchInProgress: false,
  reportDataThresholdLimit: 0,
  reportExecutedQuery: '',

  // Worker states
  workerId: null,
  workerStatus: null,
  total: 0,
  at: 0,

  report_generator_base_queries: [],

  // Scheduling states
  schedule: {
    type: 'Daily',
    interval: 1,
    days_of_month: [1],
    dates_of_year: [{ month: 1, day: 1 }],
    days: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
    time: ['07:30 AM'],
    email_recipients: [],
    email_subject: '',
    email_content: '',
    expiry_duration: 7,
    enabled: false,
    approval_status: 'initialized',
    rejection_reason: '',
    approval_status_text: 'Not Scheduled',
    sftpAccountId: null,
    sftpUploadPath: '/',
    snowflakeClientId: null,
    snowflakeTableName: null,
  },
  schedulingTypes: {
    email: false,
    sftp: false,
    snowflake: false,
  },
  sftpAccounts: [],
  disableSave: {},

  // additional settings
  failedScheduleNotificationRecipients: {
    recipients: [],
    defaultRecipients: [],
  },

  // custom query
  reportGeneratorCustomQueries: [],
  createCustomQuery: false,

  configurableId: null,

  fileAttributes: {
    file_format: 'csv',
    file_compression: false,
    column_headers: true,
    pgp_encryption: false,
    custom_delimiter: false,
    skip_empty_report_generation: false,
  },
  snowflakeClients: [],
  snowflakeConfigs: [],
};
