import { combineReducers } from 'redux';
import clientManagementReducer from 'containers/client_management/reducer';
import investmentBlocksManagerReducer from 'containers/investment_blocks_manager/reducer';
import formTemplatesReducer from 'containers/form_templates/reducer';
import fundProfileReducer from 'modules/fund_profile/FundProfile.reducer';
import whiteLabelPartnerReducer from 'modules/wlp_admin/WlpAdmin.reducer';
import educationReducer from 'modules/education/reducers';
import polyglotReducer from 'modules/polyglot/state/reducer';
import investmentOpportunitiesReducer from 'modules/investment_opportunity/reducers';
import activityLogReducer from 'modules/activity_log/reducers';
import pdfViewerReducer from 'modules/pdf_viewer/state/reducer';
import listOpportunitiesReducer from 'modules/investment_opportunity/reducers/list';
import impersonateReducer from 'modules/impersonation/reducers/ImpersonationReducers';
import productProfileBuilderReducer from 'modules/product_profile_builder/reducers/Reducers';
import passportReducer from 'modules/passport/reducers';
import IcnBootstrapReducer from './icnBootstrapReducer';
import DcFilterReducer from './doc_center_reducers/dcFilterReducer';
import DcHotButtonsReducer from './doc_center_reducers/dcHotButtonsReducer';
import DcDatePickerDateTypeReducer from './doc_center_reducers/dcDatePickerDateTypeReducer';
import dcFilterListReducer from './doc_center_reducers/dcFilterListReducer';
import dcResultsReducer from './doc_center_reducers/dcResultsReducer';
import dcResultsPerPageReducer from './doc_center_reducers/dcResultsPerPageReducer';
import dcDownloadsReducer from './doc_center_reducers/dcDownloadsReducer';
import dcSavedViewsReducer from './doc_center_reducers/dcSavedViewsReducer';
import dcNotesReducer from './doc_center_reducers/dcNotesReducer';
import dcUploaderReduced from './doc_center_reducers/dcUploaderReducer';
import investorProfileReducer from './investor_profile_reducers/investorProfileReducer';
import custodiansReducer from './custodian_reducers/custodianReducer';
import spinnerReducer from './spinnerReducer';
import capitalEventReducer from './fund_admin_reducers/capitalEventReducer';
import secureDocumentTransferReducer from './secure_document_transfer_reducers/secureDocumentTransferReducer';
import fundAdminReducer from './fund_admin_reducers/fundAdminReducer';
import pcapReducer from './fund_admin_reducers/pcapReducer';
import notificationReducer from './notificationReducer';
import authorizationGroupsReducer from './authorization_groups_reducer/authorizationGroupsReducer';
import reportGeneratorConfigsReducer from './report_generator_reducers';
import documentManagerReducer from './document_manager/documentManagerReducer';
import fundDashboardReducer from './fund_dashboard_reducers/fundDashboardReducer';
import salesDetailReducer from './sales_detail_reducers/salesDetailReducer';
import salesReportReducer from './sales_report_reducers/salesReportReducer';
import investmentDashboardReducer from './investment_dashboard_reducers/investmentDashboardReducer';
import inviteClientsReducer from './sales_invite_clients/inviteClientsReducer';
import subscriptionWorkflowStatusReducer from './subscription_workflow_status_reducers/subscriptionWorkflowStatusReducer';
import subscriptionReviewerReducer from './subscription_reviewer_reducers/subscriptionReviewerReducer';
import subscriptionDocumentsReducer from './document_definitions_reducers/subscriptionDocumentsReducer';
import oneTimePopupReducer from './one_time_popup_reducers/oneTimePopupReducer';
import operationsReportsReducer from './reporting/operationsReportsReducer';
import subDocExclusionsReducer from './subdoc_exclusions_reducers/subDocExclusionsReducer';
import ppmWatermarkingReducer from './reporting/ppmWatermarkingReducer';
import amlSuitabilityRepsReducer from './aml_suitability_reps_reducers/amlSuitabilityRepsReducer';
import dataDashboardReducer from './data_dashboard_reducers/setReducers';
import subscriptionsDashboardReducer from './subscriptions_dashboard/subscriptionsDashboardReducer';
import navReducer from './fund_admin_reducers/navReducer';
import statementsReducer from './fund_admin_reducers/statementsReducer';
import reconciliationReducer from './fund_admin_reducers/reconciliationReducer';
import subscriptionReviewReducer from './subscription_review_reducers/subscriptionReviewReducer';
import manageFundingStatusReducer from './manage_funding_status_reducers/manageFundingStatusReducer';
import operationsReducer from './fund_admin_reducers/operations_dashboard/operationsReducer';
import manageNotificationEventReducer from './notification_event_reducer/notificationEventReducer';
import integrationPartnersDashboardReducer from './integration_partners_dashboard_reducers/integrationPartnersDashboardReducer';
import integrationPartnerTemplatesReducer from './integration_partner_templates_reducers/integrationPartnerTemplatesReducer';
import fundFeesReducer from './fund_fees_reducers/fundFeesReducer';
import supplementalFormReducer from './supplemental_form/supplementalFormReducer';
import fundFinancesSynthesisReducer from './fund_finances_reducers/fundFinancesSynthesisReducer';
import synthesisExportModalReducer from './fund_finances_reducers/synthesisExportModalReducer';
import levenshteinMatcherReducer from './levenshtein_matcher_reducer/levenshteinMatcherReducer';
import adminSearchReducer from './admin_search_reducers/adminSearchReducers';
import generalLedgersReducer from './general_ledgers_reducers/generalLedgersReducer';
import documentViewInfoReducer from './document_view_info_reducers/documentViewInfoReducer';
import publishedDocumentExportReducer from './published_document_export/publishedDocumentExportReducer';
import investorRepresentativeReducer from './investor_representative_reducers/investorRepresentativeReducer';
import termsOfUsePopupReducer from './terms_of_use_popup_reducers/termsOfUsePopupReducer';
import teamManagementReducer from './team_management_reducers/teamManagementReducer';
import dataReconciliationReducer from '././data_reconciliation_reducers/setReducers';
import landingPageReducer from '../modules/landing_page/reducers';
import complianceReducer from './compliance_reducers/complianceReducer';
import registrationAccountTypeReducer from './registration_account_type_reducers/registrationAccountTypeReducer';
import registrationReducer from './registration_reducers/registrationReducer';
import preApprovalQuestionnaireReducer from './pre_approval_questionnaire/preApprovalQuestionnaireReducer';
import uploaderReducer from './fund_admin_reducers/uploaderReducer';
import ceCashFundingReducer from './fund_admin_reducers/capital_events/cashFundingReducer';
import icnGeoCountriesReducer from './icn_geo_countries_reducers/icnGeoCountriesReducer';
import fundDocumentEmailsReducer from './fund_document_emails_reducers/fundDocumentEmailsReducer';
import reportScheduleRequestsReducer from './report_schedule_requests_reducers';
import accessAgreementsReducer from './access_agreements_reducers/reducer';
import nomineeReviewReducer from './nominee_review_reducers/nomineeReviewReducer';
import reportScheduleLogReducers from './report_schedule_log_reducers';
import ihubReducer from '@ihub/reducer';
import validatrReducer from '@validatr/reducer';
import qualificationPopupReducer from './qualification_popup_reducers/qualificationPopupReducer';
import massMailerReducer from 'containers/mass_mailer/reducer';
import flexibleFeedDashboardReducers from './flexible_feed_dashboard_reducers/setReducers';
import consentFormReducer from './consent_form_reducers/consentFormReducer';

const rootReducer = combineReducers({
  icnReactBootstrap: IcnBootstrapReducer,
  dcNotes: dcNotesReducer,
  dcUploader: dcUploaderReduced,
  dcDatePickerDateType: DcDatePickerDateTypeReducer,
  dcFilters: DcFilterReducer,
  dcHotButtons: DcHotButtonsReducer,
  dcFilterLists: dcFilterListReducer,
  dcResults: dcResultsReducer,
  dcResultsPerPage: dcResultsPerPageReducer,
  dcDownloads: dcDownloadsReducer,
  dcSavedViews: dcSavedViewsReducer,
  investorProfile: investorProfileReducer,
  custodians: custodiansReducer,
  passport: passportReducer,
  spinner: spinnerReducer,
  capitalEvents: capitalEventReducer,
  secureDocumentTransfer: secureDocumentTransferReducer,
  fundAdmin: fundAdminReducer,
  pcaps: pcapReducer,
  operations: operationsReducer,
  notification: notificationReducer,
  authorizationGroups: authorizationGroupsReducer,
  reportGeneratorConfigs: reportGeneratorConfigsReducer,
  documentManager: documentManagerReducer,
  fundDashboard: fundDashboardReducer,
  salesDetail: salesDetailReducer,
  salesReport: salesReportReducer,
  investmentDashboard: investmentDashboardReducer,
  inviteClients: inviteClientsReducer,
  subscriptionWorkflowStatus: subscriptionWorkflowStatusReducer,
  subscriptionReviewer: subscriptionReviewerReducer,
  subscriptionDocuments: subscriptionDocumentsReducer,
  oneTimePopup: oneTimePopupReducer,
  operationsReports: operationsReportsReducer,
  subDocExclusions: subDocExclusionsReducer,
  ppmWatermarking: ppmWatermarkingReducer,
  amlSuitabilityReps: amlSuitabilityRepsReducer,
  dataDashboard: dataDashboardReducer,
  subscriptionsDashboard: subscriptionsDashboardReducer,
  navs: navReducer,
  statements: statementsReducer,
  reconciliation: reconciliationReducer,
  subscriptionReview: subscriptionReviewReducer,
  manageFundingStatus: manageFundingStatusReducer,
  manageNotificationEvent: manageNotificationEventReducer,
  integrationPartnersDashboard: integrationPartnersDashboardReducer,
  integrationPartnerTemplates: integrationPartnerTemplatesReducer,
  fundFees: fundFeesReducer,
  supplementalForm: supplementalFormReducer,
  clientManagement: clientManagementReducer,
  formTemplates: formTemplatesReducer,
  synthesisExportModal: synthesisExportModalReducer,
  fundFinancesSynthesis: fundFinancesSynthesisReducer,
  levenshteinMatcher: levenshteinMatcherReducer,
  adminSearch: adminSearchReducer,
  generalLedgers: generalLedgersReducer,
  investmentOpportunities: investmentOpportunitiesReducer,
  documentViewInfo: documentViewInfoReducer,
  publishedDocumentExport: publishedDocumentExportReducer,
  investorRepresentative: investorRepresentativeReducer,
  termsOfUsePopup: termsOfUsePopupReducer,
  teamManagement: teamManagementReducer,
  dataReconciliation: dataReconciliationReducer,
  landingPage: landingPageReducer,
  investmentBlocksManager: investmentBlocksManagerReducer,
  compliance: complianceReducer,
  education: educationReducer,
  registrationAccountType: registrationAccountTypeReducer,
  registration: registrationReducer,
  preApprovalQuestionnaire: preApprovalQuestionnaireReducer,
  fundAdminUploader: uploaderReducer,
  ceCashFunding: ceCashFundingReducer,
  listOpportunities: listOpportunitiesReducer,
  icnGeoCountries: icnGeoCountriesReducer,
  fundProfile: fundProfileReducer,
  fundDocumentEmails: fundDocumentEmailsReducer,
  whiteLabelPartner: whiteLabelPartnerReducer,
  reportScheduleRequests: reportScheduleRequestsReducer,
  pdfViewer: pdfViewerReducer,
  accessAgreements: accessAgreementsReducer,
  nomineeReview: nomineeReviewReducer,
  activityLog: activityLogReducer,
  polyglot: polyglotReducer,
  reportScheduleLogs: reportScheduleLogReducers,
  ihub: ihubReducer,
  impersonate: impersonateReducer,
  qualificationPopup: qualificationPopupReducer,
  productProfileBuilder: productProfileBuilderReducer,
  validatr: validatrReducer,
  massMailer: massMailerReducer,
  flexibleFeedDashboard: flexibleFeedDashboardReducers,
  consentForm: consentFormReducer,
});

export default rootReducer;
