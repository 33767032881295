import React, { useState } from 'react';
import { func, string, instanceOf } from 'prop-types';
import { RowNode } from 'ag-grid-community';
import classNames from 'classnames/bind';
import styles from './DeleteEditButtonCellRenderer.module.scss';
import popOverStyles from '../../icn_tooltip_overlay_trigger/IcnTooltipOverlayTrigger.module.scss';
import IcnSimpleChoiceModal from '../../modals/IcnSimpleChoiceModal/IcnSimpleChoiceModal';
import IcnTooltipOverlayTrigger from '../../icn_tooltip_overlay_trigger/IcnTooltipOverlayTrigger';

const cx = classNames.bind(styles);

const DeleteEditButtonCellRenderer = ({
  warningBodyText,
  warningTitle,
  onDeleteRowClicked,
  onEditRowClicked,
  node,
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const onDeleteClicked = () => {
    setShowDeleteModal(true);
  };

  const onCloseModal = () => {
    setShowDeleteModal(false);
  };

  const onConfirmDelete = () => {
    setShowDeleteModal(false);
    onDeleteRowClicked(node.data);
  };

  const generateBodyText = () => {
    const regex = /(\${\w+})/g;
    const match = warningBodyText.replace(regex, (matched) => {
      const key = matched.slice(2, -1);
      const value = node.data[key];
      return value;
    });

    return match;
  };

  return (
    <div className="text-center">
      <IcnTooltipOverlayTrigger
        trigger={['hover', 'focus']}
        popOverAccessibilityId="Edit Hover"
        popOverType={popOverStyles.default_tooltip}
        popOverContent={(
          <div>
            Edit
          </div>
        )}
      >
        <a href="#" onClick={() => onEditRowClicked(node.data)}>
          <div className={cx(styles.edit_button)} />
        </a>
      </IcnTooltipOverlayTrigger>
      <div className={styles.spacer} />
      <IcnTooltipOverlayTrigger
        trigger={['hover', 'focus']}
        popOverAccessibilityId="Delete Hover"
        popOverType={popOverStyles.default_tooltip}
        popOverContent={(
          <div>
            Delete
          </div>
        )}
      >
        <a href="#" onClick={onDeleteClicked}>
          <div className={styles.delete_button} />
        </a>
      </IcnTooltipOverlayTrigger>
      <IcnSimpleChoiceModal
        isOpen={showDeleteModal}
        title={warningTitle}
        bodyText={generateBodyText()}
        onConfirm={onConfirmDelete}
        confirmButtonText="OK"
        onCancel={() => onCloseModal(node.data)}
      />
    </div>
  );
};

DeleteEditButtonCellRenderer.defaultProps = {
  node: null,
};

DeleteEditButtonCellRenderer.propTypes = {
  warningBodyText: string.isRequired,
  warningTitle: string.isRequired,
  onDeleteRowClicked: func.isRequired,
  onEditRowClicked: func.isRequired,
  node: instanceOf(RowNode),
};

export default DeleteEditButtonCellRenderer;
