import { LP_SET_ADVISOR_MARKETING_FIELDS } from 'modules/landing_page/actions/constants';

import update from 'immutability-helper';

import initialState from './initialState';

export default (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case LP_SET_ADVISOR_MARKETING_FIELDS:
      return update(state, {
        advisorMarketingFields: {
          id: { $set: payload.id || '' },
          announcement: { $set: payload.banner_text || '' },
          announcementUrl: { $set: payload.banner_link || '' },
          fundName: { $set: payload.banner_subject || '' },
        },
      });
    default:
      return state;
  }
};
