import {
  DD_LOAD_DATA_SYNC_SCHEMA,
  DD_LOAD_DATA_SYNC_IDENTIFIERS,
  DD_LOAD_DATA_SYNC_STATUSES,
  DD_LOAD_DATA_SYNC_USERS,
  DD_LOAD_DATA_SYNC_TIME_RANGES,
} from 'actions/actionsConstants';

import initialState from '../initialState';
import { updateState } from '../helpers';

const REQUEST_OBJECT = 'data_sync_schema';

export default (state = initialState, action) => {
  switch (action.type) {
    case DD_LOAD_DATA_SYNC_SCHEMA:
      return updateState(state, action.payload, (data) => ({
        request_object: REQUEST_OBJECT,
        request_type: 'set_data_sync_schema_structure',
        message: 'Fetched Data Sync Schema Structure.',
        dataSyncSchema: {
          schema: {
            ...state.dataSyncSchema.schema,
            ...data,
          },
          responseId: Date.now(),
        },
      }));
    case DD_LOAD_DATA_SYNC_IDENTIFIERS:
      return updateState(state, action.payload, (data) => ({
        request_object: REQUEST_OBJECT,
        request_type: 'set_data_sync_schema_identifiers',
        message: 'Fetched Data Sync Schema Identifiers.',
        dataSyncSchema: {
          ...state.dataSyncSchema,
          identifiers: data,
        },
      }));
    case DD_LOAD_DATA_SYNC_STATUSES:
      return updateState(state, action.payload, (data) => ({
        request_object: REQUEST_OBJECT,
        request_type: 'set_data_sync_schema_statuses',
        message: 'Fetched Data Sync Schema Statuses.',
        dataSyncSchema: {
          ...state.dataSyncSchema,
          statuses: data,
        },
      }));
    case DD_LOAD_DATA_SYNC_USERS:
      return updateState(state, action.payload, (data) => ({
        request_object: REQUEST_OBJECT,
        request_type: 'set_data_sync_schema_users',
        message: 'Fetched Data Sync Schema Users.',
        dataSyncSchema: {
          ...state.dataSyncSchema,
          users: data,
        },
      }));
    case DD_LOAD_DATA_SYNC_TIME_RANGES:
      return updateState(state, action.payload, (data) => ({
        request_object: REQUEST_OBJECT,
        request_type: 'set_data_sync_schema_time_ranges',
        message: 'Fetched Data Sync Schema Time Ranges.',
        dataSyncSchema: {
          ...state.dataSyncSchema,
          time_ranges: data,
        },
      }));
    default:
      return state;
  }
};
