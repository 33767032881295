import i18n from 'i18n.js';

export const IDDDisclosureText = () =>
  i18n.t(
    'disclaimer:diligence',
    `iCapital diligence does not constitute a personal recommendation or take into account
  the particular investment objectives, financial situations, or needs of the Receiving Party. Additionally, a fund that
  has received iCapital diligence does not constitute a recommendation over a fund that has not received diligence.
  iCapital, in providing diligence, is not providing investment advice and does not recommend or endorse any private
  capital fund, private capital manager, private capital segment, or any investment opportunity.`
  );

export default IDDDisclosureText;
