export default {
  assignments: [],
  assignments_reviewers: [],
  rias_firms: [],
  assignable_rias_firms: [],
  funds: [],
  assignable_funds: [],
  assignments_count: 0,
  results_per_page: 25,
  current_page: 1,
  selected_funds: [],
  selected_rias_firms: [],
  selected_contacts: [],
  show_no_reviewers: false,
  sorting_column: 'assignable_fund_id',
  sorting_order: 'desc',
  active_modal: null,
  selected_row: null,
};
