import emptyFormatter from './emptyFormatter';
import floatFormatter from './floatFormatter';

export default function percentageFormatter(params, fixedDecimal = 2, optionalDecimal = false) {
  const value = params.value;

  if (value === undefined || value === null || Number.isNaN(Number(value))) {
    return emptyFormatter(params);
  }

  let valueAbs = Math.abs(value);
  const floatVal = floatFormatter(valueAbs * 100, fixedDecimal, optionalDecimal);

  valueAbs = `${floatVal}%`;

  return value < 0 ? `(${valueAbs})` : valueAbs;
}
