/* eslint-disable max-lines-per-function */
/* eslint-disable max-statements */
import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  DialogActions,
  Typography,
} from '@icapitalnetwork/supernova-core';
import { shape, string, func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import {
  registeredAsBDOrIA,
  iaWithSeries65,
  totalAssets,
  isOtherTypeInstitution,
} from 'containers/registration/complete_profile/profileData';
import IndividualCrdNumber from 'containers/registration/complete_profile/sections/IndividualCrdNumber';
import RegisteredAsBdOrRia from 'containers/registration/complete_profile/sections/RegisteredAsBdOrRia';
import TotalAssets from 'containers/registration/complete_profile/sections/TotalAssets';
import { isTextFilled } from 'containers/registration/complete_profile/CompleteProfileValidations';
import { calculateSfoCanSubmit } from './../helpers/validations';
import YesNoQuestion from './YesNoQuestion';
import StyledDialogContent from './StyledDialogContent';
import { FINANCIAL_PROFESSIONAL } from '../constants';

function SfoUserIdentificationPageOne({
  user,
  submit: submitUserAcknowledgments,
  updateUser,
}) {
  const [information, setInformation] = useState({});
  const [canSubmit, setCanSubmit] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const theme = useTheme();
  const { t } = useTranslation(['common']);

  useEffect(() => {
    setCanSubmit(calculateSfoCanSubmit(information));
  }, [information]);

  const saveInformation = (newInfo) => {
    setInformation((i) => ({ ...i, ...newInfo }));
  };

  const saveSeries65 = (newInfo) => {
    const value = Object.values(newInfo)[0];
    setInformation((i) => {
      const newI = { ...i };
      if (value === true) {
        delete newI[isOtherTypeInstitution.question_key];
        delete newI[totalAssets.question_key];
      }
      return ({
        ...newI,
        [iaWithSeries65.question_key]: value,
      });
    });
  };

  const selectRegistrationStatus = ({ target: { value } }) => {
    setInformation((i) => {
      const newI = { ...i };
      if (isTextFilled(value) && value !== 'none') {
        delete newI[isOtherTypeInstitution.question_key];
        delete newI[totalAssets.question_key];
      }
      return ({
        ...newI,
        [registeredAsBDOrIA.question_key]: value,
      });
    });
  };

  const saveIsOtherType = (newInfo) => {
    const value = Object.values(newInfo)[0];
    setInformation((i) => {
      const newI = { ...i };
      if (value === false) {
        delete newI[totalAssets.question_key];
      }
      return ({
        ...newI,
        ...newInfo,
      });
    });
  };

  const submit = () => {
    setIsSubmitting(true);

    submitUserAcknowledgments({
      answers: information,
      acknowledgement_type: FINANCIAL_PROFESSIONAL,
      from_identification_user_modal: true,
    })
      .then(() => {
        // Upon success, update user
        updateUser({ identification_user_modal: false });
        setIsSubmitting(false);
      })
      .catch(() => window.location.assign('/logout'));
  };

  return (
    <>
      <StyledDialogContent>
        <Typography variant="extraLargeParagraph" sx={{ lineHeight: '30px' }}>
          {`Hello ${user.first_name},`}
        </Typography>
        <Typography variant="extraLargeParagraph" sx={{ lineHeight: '30px' }}>
          To properly identify who our users are on the iCapital platform, we ask that you please answer the
          following questions.
        </Typography>

        <Box sx={{ marginTop: theme.spacing(2) }}>
          <RegisteredAsBdOrRia
            selectRegistrationStatus={selectRegistrationStatus}
            selected={information[registeredAsBDOrIA.question_key] || ''}
            label={registeredAsBDOrIA.text}
            hasHeadlineText
          />
        </Box>

        {/* Series 65 */}
        <Box sx={{ marginTop: theme.spacing(1.5) }}>
          <YesNoQuestion
            informationKey={iaWithSeries65.question_key}
            questionText={iaWithSeries65.text}
            information={information}
            saveInformation={saveSeries65}
          />
        </Box>

        <Box sx={{ marginTop: theme.spacing(1) }}>
          <IndividualCrdNumber
            profileData={information}
            updateStore={saveInformation}
            hasHeadlineText
            isPopup
          />
        </Box>

        {information[iaWithSeries65.question_key] === false &&
        information[registeredAsBDOrIA.question_key] === 'none' && (
          <Box sx={{ marginTop: theme.spacing(1) }}>
            <Typography variant="paragraph">
              Are you acting on behalf of an entity (not a natural person) with total assets of at least $75 million?
            </Typography>
            <YesNoQuestion
              informationKey={isOtherTypeInstitution.question_key}
              information={information}
              saveInformation={saveIsOtherType}
            />
            {information[isOtherTypeInstitution.question_key] && (
              <TotalAssets
                profileData={information}
                updateStore={saveInformation}
              />
            )}
          </Box>
        )}
      </StyledDialogContent>
      <DialogActions>
        <Button autoFocus onClick={submit} variant="contained" disabled={!canSubmit || isSubmitting}>
          {t('common:confirm', 'Confirm')}
        </Button>
      </DialogActions>
    </>
  );
}

SfoUserIdentificationPageOne.propTypes = {
  user: shape({
    first_name: string.isRequired,
  }).isRequired,
  submit: func.isRequired,
  updateUser: func.isRequired,
};

export default SfoUserIdentificationPageOne;
