import { getRequest, postRequest } from 'actions/utils';
import axios from 'axios';
import { flow, filter, map } from 'lodash/fp';
import { pick } from 'lodash';
import { rootUrl } from 'services/axios/setBaseUrl';
import dcDownloadActions from 'actions/doc_center_actions/doc_center_download_actions/dcDownloadActions';
import delimiter from 'components/shared/document_center_widget/fund_doc_watermarking_response_delimiter/responseDelimiter';
import { beginApiRequest, endApiRequest, setSpinnerText } from '../spinner_actions/spinnerActions';

const dcwGetTableResults = (payload, cancelInstance) => (dispatch) => dispatch(
  postRequest({
    url: '/doc_center',
    data: payload,
    cancelInstance,
  })
);

const dcwFundDocWatermarkCombo = (info, positionDateWatermarkEnabled) => {
  if (positionDateWatermarkEnabled) {
    return `${info.fund_id}${delimiter}${info.effective_date}${delimiter}${info.external_document_id}`;
  }
  return `${info.fund_id}${delimiter}${info.external_document_id}`;
};

const getAvailableAccountsForFunds = (fundId, externalDocumentId, effectiveDate) => {
  const data = {
    docs: [
      {
        fund_id: fundId,
        external_document_id: externalDocumentId,
        effective_date: effectiveDate,
      },
    ],
  };

  return axios({
    url: 'document_downloads/available_accounts_for_funds',
    method: 'post',
    withCredentials: true,
    data,
  });
};

const dcwOnSingleDocument = (fundDocWatermarkingEnabled, positionDateEnabled, event) => {
  const {
    docSourceId,
    docSourceClassName,
    externalDocumentType,
    externalDocumentId,
    fundId,
    investorProfileId,
    effectiveDate,
  } = event.currentTarget.dataset;
  const isFundLevelDocument = !event.currentTarget.dataset.investorProfileId;

  const docData = {
    doc_source_id: docSourceId,
    doc_source_class_name: docSourceClassName,
    external_document_type: externalDocumentType,
    fund_id: fundId,
    investor_profile_id: investorProfileId,
    effective_date: effectiveDate,
  };
  return (dispatch) => {
    const data = {
      response: [],
      doc_data: docData,
    };
    if (fundDocWatermarkingEnabled && isFundLevelDocument) {
      dispatch(beginApiRequest());
      return new Promise((resolve, reject) => {
        getAvailableAccountsForFunds(fundId, externalDocumentId, effectiveDate).then((response) => {
          const key = positionDateEnabled ? `${fundId}${delimiter}${effectiveDate}${delimiter}${externalDocumentId}` : fundId;
          // TODO make sure webpack handles "Optional_chaining"
          //  "response?.data[key]?.accounts_info"
          if (
            response &&
            response.data &&
            response.data[key] &&
            response.data[key].accounts_info
          ) {
            data.response = response.data[key].accounts_info;
          }
          resolve({ data });
        }).catch((error) => {
          dcDownloadActions.onDownloadError(
            dispatch,
            'Preview or Download Error',
            error.data.message
          );
          reject(error);
        }).finally(() => {
          dispatch(endApiRequest());
        });
      });
    }
    return Promise.resolve({ data });
  };
};

function getDownloadBaseUrl() {
  return `${rootUrl()}document_downloads`;
}

/* @function dcwDownloadSingleDocument
   @desc Handles two cases.
   1) User selects a single item to download, and clicks 'Download'.
   2) User clicks inline icon for downloading an item.
   Both cases, no spinner is displayed while waiting for server response.
   For the inline download icon, allow clearSelection=false
   to opt out of clearing selection after download.
   @param {Object} item An object with props for server to query for a document
   @param {Boolean} clearSelection Flag if selection UI should be cleared after download
 */
const dcwDownloadSingleDocument = (
  downloadStates,
  downloadStatesDispatch,
  filterParameters,
  item = {},
  type,
  clearSelection = true,
  selectedAccounts = null,
  callbackSnackbar = undefined
) => (dispatch) => {
  let formObj = item;

  if (clearSelection === true) {
    downloadStatesDispatch({ type: 'toggleSelectAll', payload: { downloadInProgress: true } });
  }

  if (selectedAccounts) {
    formObj = {
      docs: [item],
      selected_accounts: selectedAccounts,
    };

    dcDownloadActions.bulkDownload(dispatch, formObj, callbackSnackbar);
  } else {
    dcDownloadActions.singleDocument(dispatch, formObj, type);
  }
};

const dcwDownloadSelectedMiddleware = (callback, getAvailableAccountsForSelectedDocs, downloadStates, totalCount, filterParameters) => (dispatch, getState) => {
  const state = getState();
  const allSelected = !!downloadStates.allSelected;
  const didSelectAll = !!downloadStates.didSelectAll;
  const params = assembleRequestParams(state, allSelected, didSelectAll, downloadStates, filterParameters);

  const isValid = validNumberOfDocsSelected(
    downloadStates.maxFiles,
    totalCount,
    allSelected,
    didSelectAll,
    downloadStates
  );

  if (!isValid) {
    const errorMessage = `The number of documents you are attempting to download exceeds the allowable limit of ${downloadStates.maxFiles}.  Please modify your selections.`;
    dcDownloadActions.onDownloadError(
      dispatch,
      'Download size exceeds limit',
      errorMessage
    );

    return new Promise((resolve, reject) => {
      reject(errorMessage);
    });
  }

  dispatch(beginApiRequest());
  return new Promise((resolve, reject) => {
    dcDownloadActions.getAvailableAccountsForSelectedDocs(params).then((response) => {
      const userSelectedAccountForFunds = {};
      const dropdownOptionsForUserToSelect = [];

      Object.keys(response.data).forEach((key) => {
        const accountsInfo = response.data[key].accounts_info;
        const fundName = response.data[key].fund_name;
        const externalDocumentType = response.data[key].external_document_type;
        const fundIdDocTypeId = key.split(delimiter);
        const fundId = fundIdDocTypeId[0];
        const docTypeId = fundIdDocTypeId[fundIdDocTypeId.length - 1];
        let effectiveDate = null;
        if (fundIdDocTypeId.length === 3) {
          effectiveDate = fundIdDocTypeId[1];
        }
        dropdownOptionsForUserToSelect.push({
          fund_id: fundId,
          fund_name: fundName,
          external_document_id: docTypeId,
          external_document_type: externalDocumentType,
          effective_date: effectiveDate,
          options: accountsInfo,
        });
      });

      dropdownOptionsForUserToSelect.sort((a, b) => {
        if (a.fund_name === b.fund_name) {
          return 0;
        }
        return a.fund_name < b.fund_name ? -1 : 1;
      });

      callback(userSelectedAccountForFunds);
      resolve(dropdownOptionsForUserToSelect);
    }).catch((error) => {
      reject(error);
    }).finally(() => {
      dispatch(endApiRequest());
    });
  });
};

const findDocuments = (collection = {}, selectionState) => {
  // find isSelected = true
  if (Object.keys(collection).length === 0) {
    return [];
  }
  return flow(
    filter((item) => item[selectionState] === true),
    map((item) => pick(item, 'doc_source_id', 'doc_source_class_name', 'fund_id', 'external_document_type', 'external_document_id', 'investor_profile_id', 'effective_date'))
  )(collection);
};

// generate success function for preDownload call
// success function will be called if preDownload call is succeed, and will make a download call afterwards
const preDownloadSuccessGenerator = (onDownloadSuccess, onTimeout, endpointUrl, formObj) => () => {
  const iframeDownload = new IframeDownload({
    onDownloadSuccess,
    onDownloadTimeout: onTimeout,
    endpointUrl,
    formObj,
  });
  iframeDownload.render();
};

const documentsForDownload = (downloadStates, didSelectAll) => (didSelectAll ?
  findDocuments(downloadStates.unselectedDocuments, 'isUnselected') :
  findDocuments(downloadStates.selectedDocuments, 'isSelected'));

const validNumberOfDocsSelected = (maxFiles, totalDocs, allSelected, didSelectAll, downloadStates) => {
  const docs = documentsForDownload(downloadStates, didSelectAll);

  if (allSelected && totalDocs > maxFiles) {
    return false;
  }
  if (didSelectAll) {
    if ((totalDocs - docs.length) > maxFiles) {
      return false;
    }
  } else if (docs.length > maxFiles) {
    return false;
  }
  return true;
};

function onCookieLoaded(cookieVal, cookieName, dispatch, clearSelection, downloadStatesDispatch) {
  const status = parseInt(cookieVal, 10);

  switch (status) {
    case 0:
      // set error in dcDownloadsReducer, trigger modal/component.
      // TODO - Server should specify error message.
      dcDownloadActions.onDownloadError(dispatch);
      break;

    case 1:
      if (clearSelection === true) {
        downloadStatesDispatch({ type: 'clearAllSelectedDocuments' });
        downloadStatesDispatch({
          type: 'toggleSelectAll',
          payload: {
            allSelected: false,
            didSelectAll: false,
            showBanner: false,
            downloadInProgress: false,
          },
        });
      }
      break;
    case 2:
      dcDownloadActions.onDownloadError(dispatch, '', 'You don\'t have access to some of the documents you selected.');
      break;
    case 3:
      dcDownloadActions.onDownloadError(dispatch, '', 'You have exceeded the maximum number of documents that can be bulk downloaded.');
      break;
    default:
      break;
  }
}

// allSelected  ---  all docs (not only per page shows) are selected
// didSelectAll  ---  all docs (not only per page shows) have been selected,
//                    and then some docs have been unselected on the page afterwards
const assembleRequestParams = (state, allSelected, didSelectAll, downloadStates, filterParameters, selectedAccounts = {}) => {
  const formObj = {};
  const icnReactBootstrap = state.icnReactBootstrap;
  formObj.authenticity_token = icnReactBootstrap.icn_react_bootstrap && icnReactBootstrap.icn_react_bootstrap.csrf_token;
  formObj.selected_accounts = selectedAccounts;

  if (allSelected) {
    formObj.all_selected = true;
    formObj.filters = filterParameters;
  } else {
    formObj.docs = documentsForDownload(downloadStates, didSelectAll);

    if (didSelectAll) {
      formObj.all_selected_with_exclusions = true;
      formObj.filters = filterParameters;
    }
  }
  return formObj;
};

/* @function dcwDownloadSelectedDocuments
   @desc This is triggered by the 'Download' button above the list of Document results.
   Query in Redux for what Documents have been persisted. If only 1 document,
   return early and call downloadSingleDocument. Show spinner with text.
   Send server IDs of documents to download.
   Poll for a cookie back from server to check if server responded, which means download has begun.
*/

// TODO may need to change this to a function creator using closure
const dcwDownloadSelectedDocuments = (
  downloadStates,
  downloadStatesDispatch,
  totalCount,
  filterParameters,
  fundDocWatermarkingSelected = {},
  callbackSnackbar = undefined,
) => {
  const selectedAccounts = fundDocWatermarkingSelected;
  return (dispatch, getState) => new Promise((resolve) => {
    const state = getState();
    const allSelected = !!downloadStates.allSelected;
    const didSelectAll = !!downloadStates.didSelectAll;
    const isValid = validNumberOfDocsSelected(
      downloadStates.maxFiles,
      totalCount,
      allSelected,
      didSelectAll,
      downloadStates
    );

    if (!isValid) {
      return dcDownloadActions.onDownloadError(
        dispatch,
        'Download size exceeds limit',
        `The number of documents you are attempting to download exceeds the allowable limit of ${downloadStates.maxFiles}. Please modify your selections.`
      );
    }

    // when only 1 document selected, download without spinner
    if (!allSelected && !didSelectAll) {
      const documents = findDocuments(downloadStates.selectedDocuments, 'isSelected');
      if (documents.length === 1) {
        return dispatch(
          dcDownloadActions.downloadSingleDocument(documents[0], 'download', true, JSON.stringify(selectedAccounts))
        );
      }
    }

    const formObj = assembleRequestParams(state, allSelected, didSelectAll, downloadStates, filterParameters, selectedAccounts);

    downloadStatesDispatch({ type: 'toggleSelectAll', payload: { downloadInProgress: true } });
    dispatch(setSpinnerText({ spinnerText: 'Please wait while your documents are being prepared' }));
    dcDownloadActions.bulkDownload(dispatch, formObj, callbackSnackbar);
  });
};

export default {
  dcwGetTableResults,
  dcwFundDocWatermarkCombo,
  dcwOnSingleDocument,
  dcwDownloadSingleDocument,
  dcwDownloadSelectedMiddleware,
  dcwDownloadSelectedDocuments,
};
