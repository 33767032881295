import {
  ED_SET_CUSTOM_COURSES_DATA,
  ED_SET_CUSTOM_COURSES_CURRENT_PAGE,
  ED_SET_CUSTOM_COURSES_IS_LOADING_FIRST_PAGE,
  ED_SET_CUSTOM_COURSES_REQUEST_MADE,
  ED_SET_CUSTOM_COURSE,
  ED_REMOVE_CUSTOM_COURSE,
} from '../actions/constants';
import update from 'immutability-helper';
import initialState from './initialState';

export default (state = initialState, action) => {
  switch (action.type) {
    case ED_SET_CUSTOM_COURSES_DATA: {
      return update(state, {
        customCoursesData: {
          data: { $set: action.payload.data },
          currentPage: { $set: action.payload.current_page },
          resultsPerPage: { $set: action.payload.results_per_page },
          hasNextPage: { $set: action.payload.has_next_page },
          filterValues: { $set: action.payload.filters },
          filterSelectedValues: { $set: action.payload.filters_selected_values },
          isLoadingFirstPage: { $set: false },
          requestMade: { $set: false },
          totalCount: { $set: action.payload.total_count },
        },
      });
    }

    case ED_SET_CUSTOM_COURSE:
      return update(state, {
        customCoursesData: {
          data: {
            $set: state.customCoursesData.data.map((course) => {
              if (course.id === action.payload.id) {
                return {
                  ...course,
                  status: action.payload.status,
                };
              }
              return course;
            }),
          },
        },
      });

    case ED_SET_CUSTOM_COURSES_CURRENT_PAGE: {
      return update(state, { customCoursesData: { currentPage: { $set: action.payload } } });
    }

    case ED_SET_CUSTOM_COURSES_REQUEST_MADE: {
      return update(state, { customCoursesData: { requestMade: { $set: action.payload } } });
    }

    case ED_SET_CUSTOM_COURSES_IS_LOADING_FIRST_PAGE: {
      return update(state, { customCoursesData: { isLoadingFirstPage: { $set: action.payload } } });
    }

    case ED_REMOVE_CUSTOM_COURSE:
      return update(state, {
        customCoursesData: {
          data: {
            $set: state.customCoursesData.data.filter((course) => course.id !== action.payload.id),
          },
        },
      });

    default:
      return state;
  }
};
