import { FALLBACK_ERROR_MESSAGE } from 'containers/client_management/constants';
import {
  patchRequest,
} from 'actions/utils';

export const patchPlacementFee = (
  investment,
  placementFee,
  requestArgs = {}
) => patchRequest({
  errorMessage: FALLBACK_ERROR_MESSAGE,
  url: `client_management/investments/${investment.id}/set_placement_fee`,
  data: {
    placement_fee: placementFee,
  },
  ...requestArgs,
});
