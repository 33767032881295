/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
/* eslint-disable max-statements */
import update from 'immutability-helper';

import {
  TM_SET_ACCOUNT_PROFILES,
  TM_SET_AP_REFERRAL_CODES_FILTER,
  TM_SET_AP_ACCOUNT_PROFILES_FILTER,
  TM_SET_AP_BRANCH_CODES_FILTER,
  TM_SET_AP_SELECTED_ACCOUNTS_FILTER,
  TM_SET_AP_SELECTED_REF_CODES_FILTER,
  TM_SET_AP_SELECTED_BRANCH_CODES_FILTER,
  TM_SET_AP_SELECTED_ACCOUNTS_OPTIONS_FILTER,
  TM_SET_AP_SELECTED_REF_CODES_OPTIONS_FILTER,
  TM_SET_AP_SELECTED_BRANCH_CODES_OPTIONS_FILTER,
  TM_SET_RC_ASSIGNABLE_USERS,
  TM_RESET_RC_ASSIGNABLE_USERS,
  TM_SET_RC_REFERRAL_CODE_USERS,
  TM_RESET_RC_REFERRAL_CODE_USERS,
  TM_RESET_RC_USER_REFERRAL_CODES,
  TM_SET_TEAMS_REFERRAL_CODES,
  TM_SET_TEAMS_REFERRAL_CODES_FILTER,
  TM_SET_TEAMS_USERS_FILTER,
  TM_SET_TEAMS_BRANCH_CODES_FILTER,
  TM_SET_TEAMS_SELECTED_REFERRAL_CODES_FILTER,
  TM_SET_TEAMS_SELECTED_USERS_FILTER,
  TM_SET_TEAMS_SELECTED_BRANCH_CODES_FILTER,
  TM_SET_TEAMS_SELECTED_REFERRAL_CODES_OPTIONS_FILTER,
  TM_SET_TEAMS_SELECTED_USERS_OPTIONS_FILTER,
  TM_SET_TEAMS_SELECTED_BRANCH_CODES_OPTIONS_FILTER,
  TM_RESET_SELECTED_TEAMS_FILTERS,
  TM_SET_ASSIGNABLE_REFERRAL_CODES_COUNT,
  TM_SET_BC_ASSIGNABLE_BRANCH_CODES,
  TM_SET_TEAM_INVITES,
  TM_SET_TEAM_INVITES_FILTER,
  TM_SET_SELECTED_TEAM_INVITES_FILTER,
  TM_RESET_BC_BRANCH_REFERRAL_CODES,
  TM_SET_RC_ASSIGNABLE_REFERRAL_CODES,
  TM_TOGGLE_EDIT_TEAM_MODAL_OPEN,
  TM_TOGGLE_DELETE_TEAM_MODAL_OPEN,
} from 'actions/actionsConstants';
import {
  TM_RC_ASSIGNABLE_REFERRAL_CODES,
  TM_RC_USER_REFERRAL_CODES,
  TM_BC_BRANCH_REFERRAL_CODES,
} from 'actions/team_management/teamManagementActions';
import initialState from './initialState';

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case TM_SET_ACCOUNT_PROFILES: {
      return update(state, {
        accounts: { accountProfiles: { $set: payload } },
      });
    }
    case TM_SET_AP_REFERRAL_CODES_FILTER: {
      let refCodesFilter = payload;
      if (payload.show_null_option) {
        refCodesFilter = update(payload, {
          referral_codes: { $unshift: [{ id: 0, code: 'Unassigned Team Code' }] },
        });
      }

      return update(state, {
        accounts: { apReferralCodesFilter: { $set: refCodesFilter } },
      });
    }
    case TM_SET_AP_ACCOUNT_PROFILES_FILTER: {
      return update(state, {
        accounts: { apAccountProfilesFilter: { $set: payload } },
      });
    }
    case TM_SET_AP_BRANCH_CODES_FILTER: {
      return update(state, {
        accounts: { branchCodesFilter: { $set: payload } },
      });
    }
    case TM_SET_AP_SELECTED_ACCOUNTS_FILTER: {
      return update(state, {
        accounts: { apSelectedAccountsFilter: { $set: payload } },
      });
    }
    case TM_SET_AP_SELECTED_REF_CODES_FILTER: {
      return update(state, {
        accounts: { apSelectedRefCodesFilter: { $set: payload } },
      });
    }
    case TM_SET_AP_SELECTED_BRANCH_CODES_FILTER: {
      return update(state, {
        accounts: { selectedBranchCodesFilter: { $set: payload } },
      });
    }
    case TM_SET_AP_SELECTED_ACCOUNTS_OPTIONS_FILTER: {
      return update(state, {
        accounts: { apSelectedAccountsOptionsFilter: { $set: payload } },
      });
    }
    case TM_SET_AP_SELECTED_REF_CODES_OPTIONS_FILTER: {
      return update(state, {
        accounts: { apSelectedRefCodesOptionsFilter: { $set: payload } },
      });
    }
    case TM_SET_AP_SELECTED_BRANCH_CODES_OPTIONS_FILTER: {
      return update(state, {
        accounts: { selectedBranchCodesOptionsFilter: { $set: payload } },
      });
    }
    case TM_RC_ASSIGNABLE_REFERRAL_CODES.REQUEST: {
      return update(state, {
        teams: {
          rcAssignableReferralCodes: { loaded: { $set: false } },
        },
      });
    }
    case TM_RC_ASSIGNABLE_REFERRAL_CODES.SUCCESS: {
      return update(state, {
        teams: {
          rcAssignableReferralCodes: { $set: { ...payload.response, loaded: true } },
        },
      });
    }
    case TM_SET_RC_ASSIGNABLE_REFERRAL_CODES: {
      return update(state, {
        teams: {
          rcAssignableReferralCodes: {
            referral_codes: { $set: payload },
            total: { $set: payload.length },
          },
        },
      });
    }
    case TM_SET_RC_ASSIGNABLE_USERS: {
      return update(state, {
        teams: {
          rcAssignableUsers: { $set: { ...payload, loaded: true } },
        },
      });
    }
    case TM_RESET_RC_ASSIGNABLE_USERS: {
      return update(state, {
        teams: {
          rcAssignableUsers: { $set: initialState.teams.rcAssignableUsers },
        },
      });
    }
    case TM_SET_RC_REFERRAL_CODE_USERS: {
      return update(state, {
        teams: {
          rcReferralCodeUsers: { $set: payload },
        },
      });
    }
    case TM_RESET_RC_REFERRAL_CODE_USERS: {
      return update(state, {
        teams: {
          rcReferralCodeUsers: { $set: initialState.teams.rcReferralCodeUsers },
        },
      });
    }
    case TM_RC_USER_REFERRAL_CODES.REQUEST: {
      return update(state, {
        teams: {
          rcUserReferralCodes: { loaded: { $set: false } },
        },
      });
    }
    case TM_RC_USER_REFERRAL_CODES.SUCCESS: {
      return update(state, {
        teams: {
          rcUserReferralCodes: { $set: { ...payload.response, loaded: true } },
        },
      });
    }
    case TM_RESET_RC_USER_REFERRAL_CODES: {
      return update(state, {
        teams: {
          rcUserReferralCodes: { $set: initialState.teams.rcUserReferralCodes },
        },
      });
    }
    case TM_SET_TEAMS_REFERRAL_CODES: {
      return update(state, {
        teams: { referralCodes: { $set: { ...payload, timestamp: Date.now() } } },
      });
    }
    case TM_SET_TEAMS_REFERRAL_CODES_FILTER: {
      return update(state, {
        teams: { referralCodesFilter: { $set: payload } },
      });
    }
    case TM_SET_TEAMS_USERS_FILTER: {
      return update(state, {
        teams: { usersFilter: { $set: payload } },
      });
    }
    case TM_SET_TEAMS_BRANCH_CODES_FILTER: {
      return update(state, {
        teams: { branchCodesFilter: { $set: payload } },
      });
    }
    case TM_SET_TEAMS_SELECTED_REFERRAL_CODES_FILTER: {
      return update(state, {
        teams: { selectedReferralCodesFilter: { $set: payload } },
      });
    }
    case TM_SET_TEAMS_SELECTED_USERS_FILTER: {
      return update(state, {
        teams: { selectedUsersFilter: { $set: payload } },
      });
    }
    case TM_SET_TEAMS_SELECTED_BRANCH_CODES_FILTER: {
      return update(state, {
        teams: { selectedBranchCodesFilter: { $set: payload } },
      });
    }
    case TM_SET_TEAMS_SELECTED_REFERRAL_CODES_OPTIONS_FILTER: {
      return update(state, {
        teams: { selectedReferralCodesOptionsFilter: { $set: payload } },
      });
    }
    case TM_SET_TEAMS_SELECTED_USERS_OPTIONS_FILTER: {
      return update(state, {
        teams: { selectedUsersOptionsFilter: { $set: payload } },
      });
    }
    case TM_SET_TEAMS_SELECTED_BRANCH_CODES_OPTIONS_FILTER: {
      return update(state, {
        teams: { selectedBranchCodesOptionsFilter: { $set: payload } },
      });
    }
    case TM_RESET_SELECTED_TEAMS_FILTERS: {
      return update(state, {
        teams: {
          selectedReferralCodesFilter: { $set: initialState.teams.selectedReferralCodesFilter },
          selectedUsersFilter: { $set: initialState.teams.selectedUsersFilter },
          selectedBranchCodesFilter: { $set: initialState.teams.selectedBranchCodesFilter },
          selectedReferralCodesOptionsFilter: { $set: initialState.teams.selectedReferralCodesOptionsFilter },
          selectedUsersOptionsFilter: { $set: initialState.teams.selectedUsersOptionsFilter },
          selectedBranchCodesOptionsFilter: { $set: initialState.teams.selectedBranchCodesOptionsFilter },
        },
      });
    }
    case TM_SET_ASSIGNABLE_REFERRAL_CODES_COUNT: {
      return update(state, {
        teams: { rcAssignableReferralCodesCount: { $set: payload?.total } },
      });
    }
    case TM_SET_BC_ASSIGNABLE_BRANCH_CODES: {
      return update(state, {
        branches: { assignableBranchCodes: { $set: payload } },
      });
    }
    case TM_SET_TEAM_INVITES: {
      return update(state, {
        teamInvites: { invites: { $set: payload } },
      });
    }
    case TM_SET_TEAM_INVITES_FILTER: {
      return update(state, {
        teamInvites: { invitesFilter: { $set: payload } },
      });
    }
    case TM_SET_SELECTED_TEAM_INVITES_FILTER: {
      return update(state, {
        teamInvites: { selectedInvitesFilter: { $set: payload } },
      });
    }
    case TM_BC_BRANCH_REFERRAL_CODES.REQUEST: {
      return update(state, {
        branches: {
          branchReferralCodes: { loaded: { $set: false } },
        },
      });
    }
    case TM_BC_BRANCH_REFERRAL_CODES.SUCCESS: {
      return update(state, {
        branches: {
          branchReferralCodes: { $set: { ...payload.response, loaded: true } },
        },
      });
    }
    case TM_RESET_BC_BRANCH_REFERRAL_CODES: {
      return update(state, {
        branches: {
          branchReferralCodes: { $set: initialState.branches.branchReferralCodes },
        },
      });
    }
    case TM_TOGGLE_EDIT_TEAM_MODAL_OPEN: {
      return update(state, {
        modals: {
          editTeamModal: { $set: {
            isOpen: payload.isOpen,
            rowData: payload.row || {},
          } },
        },
      });
    }
    case TM_TOGGLE_DELETE_TEAM_MODAL_OPEN: {
      return update(state, {
        modals: {
          deleteTeamModal: { $set: {
            isOpen: payload.isOpen,
            rowData: payload.row || {},
          } },
        },
      });
    }
    default:
      return state;
  }
};
