import reducers from './reducers';
import initialState from './initialState';

const fn = [
  reducers.capitalEventsReducer,
];

const reducer = (state = initialState, action) => (
  fn.reduce((stateAccumulator, fnc) => fnc(stateAccumulator, action), state)
);

export default reducer;
