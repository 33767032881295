export const publicPath = '/icn_react/static/';

export const routeCodes = {
  ACTIVITY_LOG: `${publicPath}activity_log`,
  USER_ACTIVITY_LOG: `${publicPath}activity_log/user/:id/logs`,
  GP_ENGAGEMENT: `${publicPath}engagement`,
  GP_USER_ENGAGEMENT: `${publicPath}engagement/user/:userId/fund/:fundId/logs`,
  GP_USER_ENGAGEMENT_MFE: `${publicPath}sales_enablement/engagement/user/:userId/fund/:fundId/logs`,
  ADVISOR_ACTIVITY_LOG: `${publicPath}activity_log/advisor`,
  LOG_EXPORT_HISTORY: `${publicPath}activity_log/export_history`,
};
