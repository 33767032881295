/* eslint-disable max-lines */
/* eslint-disable max-statements */
import { isEqual, groupBy, map } from 'lodash';
import { createAction } from 'redux-actions';
import { getRequest, postRequest } from 'actions/utils';
import fundsSummaryCalculator from 'services/subscriptions_dashboard/fundsSummaryCalculator';
import { gtmHelper } from 'components/subscriptions_dashboard/SDHeader/gtmHelper';
import { InvestmentProduct, SuperFeatureSet, Phase, Activity, Action, Feature } from 'constants/gtm';
import gtmPush from 'services/google_tag_manager/index';

import {
  SDB_SET_FUNDS,
  SDB_SET_FUNDS_SUMMARY,
  SDB_SET_FUNDS_FIRMS,
  SDB_SET_ADVISORY_FIRMS_FOR_FILTER,
  SDB_SET_FUNDS_FOR_FILTER,
  SDB_SET_WORKFLOW_STATUSES_FOR_FILTER,
  SDB_SET_STATUS_DISPLAY_LABELS,
  SDB_SELECTED_ADVISORY_FIRMS,
  SDB_SELECTED_FUNDS,
  SDB_SELECTED_STATUSES,
  SDB_SET_FILTERED_FUNDS,
  SDB_SET_ACTIVE_SAVED_VIEW,
  SDB_SELECTED_FUND_STATUS,
  SDB_SET_SEARCH_TERM,
  SDB_SET_SEARCH_TYPE,
  SDB_SELECTED_ACTIVITY,
  SBD_SET_BRANCH_CODES_FOR_FILTER,
  SDB_SELECTED_BRANCH_CODES,
} from '../../reducers/subscriptions_dashboard/actionsConstants';

const sdbSetFunds = createAction(SDB_SET_FUNDS);
const sdbSetFundsSummary = createAction(SDB_SET_FUNDS_SUMMARY);
const sdbSetFundsFirms = createAction(SDB_SET_FUNDS_FIRMS);
const sdbSetAdvisoryFirmsForFilter = createAction(SDB_SET_ADVISORY_FIRMS_FOR_FILTER);
const sdbSetFundsForFilter = createAction(SDB_SET_FUNDS_FOR_FILTER);
const sdbSetWorkflowStatusesForFilter = createAction(SDB_SET_WORKFLOW_STATUSES_FOR_FILTER);
const sdbSetStatusDisplayLabels = createAction(SDB_SET_STATUS_DISPLAY_LABELS);
const sdbSetSelectedStatuses = createAction(SDB_SELECTED_STATUSES);
const setFilteredFunds = createAction(SDB_SET_FILTERED_FUNDS);
const sdbSetSelectedAdvisoryFirms = createAction(SDB_SELECTED_ADVISORY_FIRMS);
const sdbSetSelectedFunds = createAction(SDB_SELECTED_FUNDS);
const sdbSetActiveSavedView = createAction(SDB_SET_ACTIVE_SAVED_VIEW);
const sdbSetSelectedFundStatus = createAction(SDB_SELECTED_FUND_STATUS);
const sdbUpdateSearchType = createAction(SDB_SET_SEARCH_TYPE);
const sdbUpdateSearchTerm = createAction(SDB_SET_SEARCH_TERM);
const sdbSetSelectedActivityForFilter = createAction(SDB_SELECTED_ACTIVITY);
const sdbSetBranchCodesForFilter = createAction(SBD_SET_BRANCH_CODES_FOR_FILTER);
const sdbSetSelectedBranchCodes = createAction(SDB_SELECTED_BRANCH_CODES);

function transformActivity(activity) {
  if (activity === -1) {
    return null;
  }
  return activity;
}

function loadFundsSummary(funds) {
  return (dispatch) => {
    const summary = fundsSummaryCalculator(funds);
    dispatch(sdbSetFundsSummary(summary));
  };
}

function setFilteredFundsAndSummary() {
  return (dispatch, getState) => {
    const state = getState().subscriptionsDashboard;
    dispatch(setFilteredFunds(state.funds));
    dispatch(loadFundsSummary(state.funds));
  };
}

function setFundsAndSummary(data) {
  return (dispatch, getState) => {
    const state = getState().subscriptionsDashboard;
    dispatch(sdbSetFunds(data.funds));
    dispatch(sdbSetWorkflowStatusesForFilter(data.workflow_statuses));
    dispatch(sdbSetFundsFirms(data.investment_firms));
    dispatch(sdbSetBranchCodesForFilter(data.branch_codes));
    dispatch(setFilteredFundsAndSummary());
    const { dateRange, filters } = gtmHelper(state);

    gtmPush({
      event: 'icn_subscriptionDashboardFilter',
      eventDisplayName: 'Filter Subscription Dashboard',
      categories: {
        investmentProduct: [InvestmentProduct.ALTS],
        feature: Feature.SUBSCRIPTION_DASHBOARD,
        superFeatureSet: SuperFeatureSet.ADMIN,
        phase: Phase.POST_TRADE,
        activity: Activity.FEATURE_USAGE,
        action: Action.FILTER,
      },
      properties: {
        filterProperties: {
          dateRange,
          filters,
        },
      },
    });
  };
}

function loadFunds(params = {}, hideSpinner = false) {
  return (dispatch, getState) => {
    const state = getState().subscriptionsDashboard;
    return dispatch(
      getRequest({
        url: 'subscriptions_dashboard/metrics',
        params: {
          ...params,
          search_type: state.search_type,
          search_term: state.search_term,
          paf_ids: state.selected_funds,
          firm_ids: state.selected_advisory_firms,
          status: state.selected_statuses,
          fund_status: state.selected_fund_status,
          activity: transformActivity(state.selected_activity),
          branch_codes: state.selected_branch_codes,
        },
        onSuccess: setFundsAndSummary,
        showSpinner: !hideSpinner,
      })
    );
  };
}

function setFilters(data) {
  return (dispatch) => {
    dispatch(sdbSetFundsForFilter(data.funds));
    // Since we might have saved views that contain particular fund ids that don't belong to the user's Admin Group
    dispatch(sdbSetSelectedFunds(data.funds.map((f) => f.id)));
    dispatch(sdbSetAdvisoryFirmsForFilter(data.firms));
    // Since we might have saved views that contain particular firm ids that no longer make sense for the selected funds
    dispatch(sdbSetSelectedAdvisoryFirms(data.firms.map((f) => f.id)));
    dispatch(sdbSetSelectedBranchCodes(data.branch_codes.map((f) => f.id)));
    dispatch(sdbSetBranchCodesForFilter(data.branch_codes));
  };
}

function sdbLoadFiltersData(hideSpinner = false) {
  return (dispatch, getState) => {
    const state = getState().subscriptionsDashboard;
    return dispatch(
      postRequest({
        url: 'subscriptions_dashboard/filters/saved_filter_data',
        data: {
          paf_ids: state.selected_funds,
          firm_ids: state.selected_advisory_firms,
          branch_codes: state.selected_branch_codes,
        },
        onSuccess: setFilters,
        showSpinner: !hideSpinner,
      })
    );
  };
}

function sdbLoadStatusDisplayLabels() {
  return (dispatch) =>
    dispatch(
      getRequest({
        url: 'subscriptions_dashboard/metrics/status_display_labels',
        onSuccess: sdbSetStatusDisplayLabels,
        showSpinner: false,
      })
    );
}

function sdbFilterByAdvisoryFirms(newSelectedFirms) {
  return (dispatch, getState) => {
    const oldSelectedFirms = getState().subscriptionsDashboard.selected_advisory_firms;
    if (!isEqual(newSelectedFirms, oldSelectedFirms)) {
      dispatch(sdbSetSelectedAdvisoryFirms(newSelectedFirms));
      dispatch(sdbSetActiveSavedView());
      return dispatch(loadFunds());
    }
    return Promise.resolve();
  };
}

function sdbFilterByFundStatus(newSelectedFundStatuses) {
  return (dispatch, getState) => {
    const oldSelectedFundStatuses = getState().subscriptionsDashboard.selected_fund_status;
    if (!isEqual(newSelectedFundStatuses, oldSelectedFundStatuses)) {
      dispatch(sdbSetSelectedFundStatus(newSelectedFundStatuses));
      dispatch(sdbSetActiveSavedView());
      return dispatch(loadFunds());
    }
    return Promise.resolve();
  };
}

function sdbFilterByFunds(newSelectedFunds) {
  return (dispatch, getState) => {
    const oldSelectedFunds = getState().subscriptionsDashboard.selected_funds;
    if (!isEqual(newSelectedFunds, oldSelectedFunds)) {
      dispatch(sdbSetSelectedFunds(newSelectedFunds));
      dispatch(sdbSetActiveSavedView());
      return dispatch(loadFunds());
    }
    return Promise.resolve();
  };
}

function sdbFilterByStatuses(newSelectedStatuses) {
  return (dispatch, getState) => {
    const oldSelectedStatuses = getState().subscriptionsDashboard.selected_statuses;
    if (!isEqual(newSelectedStatuses, oldSelectedStatuses)) {
      dispatch(sdbSetSelectedStatuses(newSelectedStatuses));
      dispatch(sdbSetActiveSavedView());
      return dispatch(loadFunds());
    }
    return Promise.resolve();
  };
}

function sdbFilterByActivity(newSelectedActivity) {
  return (dispatch, getState) => {
    const oldSelectedDateRange = getState().subscriptionsDashboard.selected_activity;
    if (newSelectedActivity !== oldSelectedDateRange) {
      dispatch(sdbSetSelectedActivityForFilter(newSelectedActivity));
      return dispatch(loadFunds());
    }
    return Promise.resolve();
  };
}

function sdbFilterByBranchCodes(newSelectedBranchCodes) {
  return (dispatch, getState) => {
    const oldSelectedBranchCodes = getState().subscriptionsDashboard.selected_branch_codes;
    if (!isEqual(newSelectedBranchCodes, oldSelectedBranchCodes)) {
      dispatch(sdbSetSelectedBranchCodes(newSelectedBranchCodes));
      return dispatch(loadFunds());
    }
    return Promise.resolve();
  };
}

function removeAllFilters() {
  return (dispatch) => {
    dispatch(sdbSetSelectedAdvisoryFirms([]));
    dispatch(sdbSetSelectedFunds([]));
    dispatch(sdbFilterByFundStatus([]));
    dispatch(sdbSetSelectedStatuses([]));
    dispatch(sdbSetSelectedBranchCodes([]));
    dispatch(sdbSetActiveSavedView());
  };
}

function sdbResetSelectedFilters() {
  return (dispatch) => {
    dispatch(removeAllFilters());
    dispatch(loadFunds());
  };
}

function sdbLoadQueryParamsData({
  selectedAdvisoryFirms,
  selectedFunds,
  selectedStatuses,
  selectedActivity,
  selectedFundStatuses,
  selectedSearchType,
  selectedSearchTerm,
  selectedBranchCodes,
}) {
  const parseToNumber = (value) => parseInt(value, 10);
  const toArr = (value) => (Array.isArray(value) ? value : [value]);

  return (dispatch) => {
    dispatch(removeAllFilters());

    if (selectedAdvisoryFirms && selectedAdvisoryFirms.length > 0) {
      dispatch(sdbSetSelectedAdvisoryFirms(toArr(selectedAdvisoryFirms).map(parseToNumber)));
    }

    if (selectedFunds && selectedFunds.length > 0) {
      dispatch(sdbSetSelectedFunds(toArr(selectedFunds).map(parseToNumber)));
    }

    if (selectedStatuses && selectedStatuses.length > 0) {
      dispatch(sdbSetSelectedStatuses(toArr(selectedStatuses)));
    }

    if (selectedFundStatuses && selectedFundStatuses.length > 0) {
      dispatch(sdbSetSelectedFundStatus(toArr(selectedFundStatuses)));
    }

    if (selectedActivity) {
      dispatch(sdbSetSelectedActivityForFilter(parseToNumber(selectedActivity)));
    }

    if (selectedSearchTerm) {
      dispatch(sdbUpdateSearchTerm(selectedSearchTerm));
    }

    if (selectedSearchType) {
      dispatch(sdbUpdateSearchType(selectedSearchType));
    }

    if (selectedBranchCodes && selectedBranchCodes.length > 0) {
      dispatch(sdbSetSelectedBranchCodes(toArr(selectedBranchCodes)));
    }

    return dispatch(loadFunds(undefined, true));
  };
}

function sdbLoadSavedView(data) {
  return (dispatch) => {
    const filters = data.filters.drop_down_filters;
    const groupedFilters = groupBy(filters, 'filter_type');

    dispatch(removeAllFilters());

    if (groupedFilters.firms && groupedFilters.firms[0].filters.length > 0) {
      dispatch(sdbSetSelectedAdvisoryFirms(map(groupedFilters.firms[0].filters, 'typed_filter_id')));
    }

    if (groupedFilters.funds && groupedFilters.funds[0].filters.length > 0) {
      dispatch(sdbSetSelectedFunds(map(groupedFilters.funds[0].filters, 'typed_filter_id')));
    }

    if (groupedFilters.statuses && groupedFilters.statuses[0].filters.length > 0) {
      dispatch(sdbSetSelectedStatuses(map(groupedFilters.statuses[0].filters, 'typed_filter_id')));
    }

    if (groupedFilters.fund_statuses && groupedFilters.fund_statuses[0].filters.length > 0) {
      dispatch(sdbSetSelectedFundStatus(groupedFilters.fund_statuses[0].filters[0].typed_filter_id));
    }

    if (groupedFilters.branch_codes && groupedFilters.branch_codes[0].filters.length > 0) {
      dispatch(sdbSetSelectedBranchCodes(map(groupedFilters.branch_codes[0].filters, 'typed_filter_id')));
    }

    dispatch(sdbSetActiveSavedView(data));
  };
}

function sdbLoadDefaultView() {
  return getRequest({
    url: 'user_saved_views/default_view',
    params: { page_slug: 'subscriptions_dashboard' },
    onSuccess: sdbLoadSavedView,
    showSpinner: false,
  });
}

export default {
  sdbLoadDefaultView,
  sdbLoadFunds: loadFunds,
  sdbFilterByAdvisoryFirms,
  sdbFilterByFunds,
  sdbFilterByStatuses,
  sdbLoadFiltersData,
  sdbLoadStatusDisplayLabels,
  sdbLoadSavedView,
  sdbSetActiveSavedView,
  sdbFilterByFundStatus,
  sdbFilterByBranchCodes,
  sdbFilterByActivity,
  sdbResetSelectedFilters,
  sdbUpdateSearchType,
  sdbUpdateSearchTerm,
  sdbLoadQueryParamsData,
};
