import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';

import './IcnGenericModal.scss';

const customStyles = {
  overlay: {
    position: 'fixed',
    top: '75px',
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(46, 66, 80, 0.2)',
    zIndex: 1250,
    overflow: 'auto',
  },
  content: {
    boxShadow: '0 5px 15px rgba(0,0,0,0.5)',
    padding: '50px 60px 50px 60px',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    overflow: 'initial',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    outline: 0,
    backgroundClip: 'padding-box',
    border: '1px solid rgba(0,0,0,0.2)',
    maxHeight: '80vh',
    overflowY: 'scroll',
  },
};

export default class IcnGenericModal extends PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onRequestClose: PropTypes.func.isRequired,
    className: PropTypes.string
  };

  static defaultProps = {
    className: ''
  };

  componentDidMount() {
    ReactModal.setAppElement('#root');
  }

  render() {
    if (!this.props.isOpen) {
      return null;
    }

    return (
      <ReactModal
        className={''}
        overlayClassName={''}
        isOpen={this.props.isOpen}
        onRequestClose={this.props.onRequestClose}
        style={customStyles}
      >
        <div className={''}>
          {this.props.children}
        </div>
      </ReactModal>
    );
  }
}
