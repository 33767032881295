import beneficialOwnerSideEffects from './beneficialOwner';
import investorDetailsSideEffects from './investorDetails';
import retirementEmployeeBenefitPlanSideEffects from './retirementEmployeeBenefitPlan';
import signerTypeSideEffects from './signerType';
import professionalCertificationsSideEffects from './professionalCertifications';

function sideEffects(updateObj, profile, hasCustodian, profileFieldVisibility, isMainSitePafAdmin) {
  let modifiedObj = { ...updateObj };

  modifiedObj = signerTypeSideEffects(modifiedObj, profile);
  modifiedObj = beneficialOwnerSideEffects(modifiedObj, profile);
  modifiedObj = investorDetailsSideEffects(modifiedObj, profile, hasCustodian);
  modifiedObj = retirementEmployeeBenefitPlanSideEffects(modifiedObj, profile);
  modifiedObj = professionalCertificationsSideEffects(
    modifiedObj,
    profile,
    profileFieldVisibility,
    isMainSitePafAdmin
  );

  return modifiedObj;
}

export default sideEffects;
