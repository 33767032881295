import {
  ADMS_SET_SEARCH_RESULTS_LOADED,
  ADMS_SET_SEARCH_KEYWORD,
  ADMS_SET_CURRENT_RESULTS,
  ADMS_SET_IS_ADMIN_SEARCH_OPEN,
  ADMS_SET_TABS,
  ADMS_SET_SELECTED_TAB,
} from 'actions/actionsConstants';

import { searchTextFormatter } from 'containers/admin_search/services/textFormatter';
import update from 'immutability-helper';
import _ from 'lodash';

import initialState from '../initialState';

export default (state = initialState, action) => {
  switch (action.type) {
    case ADMS_SET_CURRENT_RESULTS: {
      const previousSearchKeywords = _.keys(state.previous_search_results);
      const includesSearchResults = _.includes(
        previousSearchKeywords,
        searchTextFormatter(state.search_keyword)
      );

      if (includesSearchResults) {
        const tabs = state.tabs;
        const index = _.findIndex(tabs, { id: state.selected_tab });
        tabs.splice(index, 1, {
          ...tabs[index],
          number_of_results: action.payload[0]?.number_of_results,
        });

        return update(state, {
          current_search_results: { $set: action.payload },
          tabs: { $set: [...tabs] },
        });
      }

      const allResults = {
        ...state.previous_search_results,
        [state.search_keyword]: action.payload,
      };
      return update(state, {
        previous_search_results: {
          $set: allResults,
        },
        current_search_results: { $set: action.payload },
      });
    }
    case ADMS_SET_SEARCH_RESULTS_LOADED: {
      return update(state, {
        search_results_loaded: { $set: action.payload },
      });
    }
    case ADMS_SET_SEARCH_KEYWORD: {
      // React Router Dom only decodes part of the URL, so we need to decode the rest but that can cause errors since it decodes % signs.
      // See https://github.com/remix-run/react-router/issues/11179
      // The updgrade to React Router V6 should hopefully mean this is unnecessary.
      let searchKeyword = '';
      try {
        searchKeyword = decodeURIComponent(action.payload);
      } catch (e) {
        searchKeyword = action.payload;
        console.error(e);
      }
      return update(state, {
        search_keyword: { $set: searchKeyword },
      });
    }
    case ADMS_SET_IS_ADMIN_SEARCH_OPEN: {
      return update(state, {
        is_admin_search_open: { $set: action.payload },
      });
    }
    case ADMS_SET_TABS: {
      return update(state, {
        tabs: { $set: action.payload },
      });
    }
    case ADMS_SET_SELECTED_TAB: {
      return update(state, {
        selected_tab: { $set: action.payload },
      });
    }
    default:
      return state;
  }
};
