import update from 'immutability-helper';

import {
  FP_SET_FUND_DATA,
  FP_SET_USER_HAS_INVESTOR_PROFILE,
  FP_SET_IS_INVESTOR_PROFILE_COMPLETED,
  FP_SET_IS_CONTACT_INFO_OPEN,
} from '../actionsConstants';

import initialState from '../initialState';

function setData(state, {
  minimum_investment: minimumInvestment,
  minimum_addon_investment: minimumAddOnInvestment,
  subscription_minimum_detail: subscriptionMinimumDetail,
  investor_qualification: investorQualification,
  currency_code: currencyCode,
  profile_subtitle: profileSubtitle,
  cooled_off_override: cooledOffOverride,
  ...data
} = {}) {
  return update(state, {
    fund: {
      $set: {
        ...state.fund,
        ...data,
        minimumInvestment,
        minimumAddOnInvestment,
        subscriptionMinimumDetail,
        investorQualification,
        currencyCode,
        profileSubtitle,
        cooledOffOverride,
      },
    },
  });
}

function setCurrentUserHasProfile(state, payload) {
  return update(state, {
    currentUserHasProfile: { $set: payload.has_profile },
  });
}

function setIsContactInfoOpen(state, payload) {
  return update(state, {
    isContactInfoOpen: { $set: payload },
  });
}

function setInvestorProfileCompleted(state, payload) {
  return update(state, {
    isInvestorProfileCompleted: { $set: payload.is_completed },
    investorProfileId: { $set: payload.investor_profile_id },
  });
}

export default (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case FP_SET_FUND_DATA:
      return setData(state, payload);
    case FP_SET_USER_HAS_INVESTOR_PROFILE:
      return setCurrentUserHasProfile(state, payload);
    case FP_SET_IS_CONTACT_INFO_OPEN:
      return setIsContactInfoOpen(state, payload);
    case FP_SET_IS_INVESTOR_PROFILE_COMPLETED:
      return setInvestorProfileCompleted(state, payload);
    default:
      return state;
  }
};
