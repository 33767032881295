var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from 'axios';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import i18n from 'i18n.js';
import { actionFactory } from '../../services/actions';
import { OKTA_AUTH_SET_TOKEN } from '../actionsConstants';
import { beginApiRequest, endApiRequest, } from '../spinner_actions/spinnerActions';
const oktaAuthenticationSetToken = actionFactory(OKTA_AUTH_SET_TOKEN);
// Method that transforms the okta response into the format the Okta JS SDK expects.
export const formatOktaTokenResponse = (oktaResponse) => {
    var _a, _b, _c;
    // Decode claims from JWT.
    // https://github.com/okta/okta-auth-js/blob/64a548b9f0037887d1bf8908a76bf9f3848ee0d6/lib/oidc/decodeToken.ts#L17
    let claims;
    try {
        claims = JSON.parse(atob((_a = oktaResponse.access_token) === null || _a === void 0 ? void 0 : _a.split('.')[1]));
    }
    catch (e) {
        claims = {};
    }
    return {
        accessToken: oktaResponse.access_token,
        expiresAt: Math.floor(Date.now() / 1000) + Number(oktaResponse.expires_in),
        tokenType: oktaResponse.token_type,
        scopes: (_c = (_b = oktaResponse.scope) === null || _b === void 0 ? void 0 : _b.split(' ')) !== null && _c !== void 0 ? _c : [],
        claims,
        // not currently on Okta's response but complying with the specified interface.
        userinfoUrl: (oktaResponse === null || oktaResponse === void 0 ? void 0 : oktaResponse.userinfo_url) || '',
        authorizeUrl: (oktaResponse === null || oktaResponse === void 0 ? void 0 : oktaResponse.authorize_url) || '',
    };
};
// Promise to invalidate the current Okta session.
export const postSimonOktaLogout = () => axios.get('/simon/auth/logout', {
    withCredentials: true,
    maxRedirects: 0,
    baseURL: '/',
});
// Promise to fetch the Okta token.
export const getSimonOktaToken = () => axios.get('/sso/saml/idp/token?partner=simon', {
    withCredentials: true,
});
export const oktaAuthenticationFetchToken = (enqueueSnackbar, closeSnackbar, onSuccess
// redirectUrl = '/simon/index.html',
// newTab = false
) => 
// eslint-disable-next-line max-statements
(dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch(beginApiRequest());
    try {
        yield postSimonOktaLogout();
    }
    catch (error) {
        // we are going to ignore the attempt to logout from okta, and simply fire the request.
    }
    try {
        const oktaTokenResponse = yield getSimonOktaToken();
        if (oktaTokenResponse.data.access_token === undefined) {
            dispatch(endApiRequest());
            const errorMessage = i18n.t('common:errors.unexpected_error', 'An unexpected error occurred. Please try again.');
            return enqueueSnackbar(errorMessage, {
                variant: 'error',
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                onCloseAction: (id) => closeSnackbar(id),
            });
        }
        sessionStorage.setItem('okta-token-storage', JSON.stringify({
            accessToken: formatOktaTokenResponse(oktaTokenResponse.data),
        }));
        dispatch(oktaAuthenticationSetToken());
        // if (newTab) {
        //   window.open(redirectUrl, '_blank');
        // } else {
        //   window.location.href = redirectUrl;
        // }
        return onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess();
    }
    catch (error) {
        dispatch(endApiRequest());
        return enqueueSnackbar(error, {
            variant: 'error',
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            onCloseAction: (id) => closeSnackbar(id),
        });
    }
});
