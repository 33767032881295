import { getRequest, postRequest } from 'actions/utils';
import { actionFactory } from 'services/actions';
import { createAction } from 'redux-actions';
import { createRequestTypes } from 'actions/helpers';

import {
  FD_SELECT_FUNDS,
  FD_SET_FUNDS,
  FD_RUN_FUND_METRICS,
  FD_SET_FUND_INFO,
  FD_SET_VIEW,
  FD_SET_VIEW_MODE,
  FD_SET_CURRENT_STATUS,
  FD_SET_SITE_ENGAGEMENT,
  FD_SET_SUBSCRIPTION_STATUS,
} from '../actionsConstants';

const fdSelectFunds = createAction(FD_SELECT_FUNDS);
const fdSetFunds = createAction(FD_SET_FUNDS);
const fdRunFundMetrics = actionFactory(FD_RUN_FUND_METRICS);
const fdSetView = createAction(FD_SET_VIEW);
const fdSetViewMode = createAction(FD_SET_VIEW_MODE);

export const FD_FUND_INFO = createRequestTypes(FD_SET_FUND_INFO);
export const FD_CURRENT_STATUS = createRequestTypes(FD_SET_CURRENT_STATUS);
export const FD_SITE_ENGAGEMENT = createRequestTypes(FD_SET_SITE_ENGAGEMENT);
export const FD_SUBSCRIPTION_STATUS = createRequestTypes(FD_SET_SUBSCRIPTION_STATUS);

const fdFetchFunds = (showSpinner = true) => (
  getRequest({
    showSpinner,
    url: 'private_access_funds/for_sales_reporting',
    onSuccess: fdSetFunds,
  })
);

const fdFetchFundInfo = (params, showSpinner = true) => (
  getRequest({
    showSpinner,
    url: 'fund_dashboard/fund_info',
    params,
    actions: FD_FUND_INFO,
  })
);

const fdFetchCurrentStatus = (params, showSpinner = true) => (
  getRequest({
    showSpinner,
    url: 'fund_dashboard/current_status',
    params,
    actions: FD_CURRENT_STATUS,
  })
);

const fdFetchSiteEngagement = (params, showSpinner = true) => (
  getRequest({
    showSpinner,
    url: 'fund_dashboard/site_engagement',
    params,
    actions: FD_SITE_ENGAGEMENT,
  })
);

const fdFetchSubscriptionStatus = (params, showSpinner = true) => (
  getRequest({
    showSpinner,
    url: 'fund_dashboard/subscription_status',
    params,
    actions: FD_SUBSCRIPTION_STATUS,
  })
);

const fdFetchMetrics = (params) => (
  getRequest({
    showSpinner: false,
    url: 'fund_dashboard/metrics',
    params,
  })
);

const fdFetchRunFundMetrics = (params, showSpinner = true) => (
  postRequest({
    showSpinner,
    url: 'private_access_funds/run_fund_metrics',
    params,
    onSuccess: fdRunFundMetrics,
  })
);

export default {
  fdSelectFunds,
  fdSetFunds,
  fdFetchFunds,
  fdFetchMetrics,
  fdFetchFundInfo,
  fdFetchCurrentStatus,
  fdFetchSiteEngagement,
  fdFetchSubscriptionStatus,
  fdFetchRunFundMetrics,
  fdSetView,
  fdSetViewMode,
};
