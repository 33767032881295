import {
  DD_LOAD_WHITE_LABEL_PARTNERS,
} from 'actions/actionsConstants';

import initialState from '../initialState';
import { updateState } from '../helpers';

const REQUEST_OBJECT = 'white_label_partner';

export default (state = initialState, action) => {
  switch (action.type) {
    case DD_LOAD_WHITE_LABEL_PARTNERS:
      return updateState(state, action.payload, (data) => ({
        request_object: REQUEST_OBJECT,
        request_type: 'set_white_label_partners',
        message: 'Fetched White Label Partners.',
        white_label_partners: data,
      }));
    default:
      return state;
  }
};
