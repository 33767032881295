import {
  ONE_TIME_POPUP_SET,
  ONE_TIME_POPUP_READ,
} from "actions/actionsConstants";
import axios from "axios";
import { findActivePopup } from "services/notifications/oneTimePopup";

const baseUrl = "/one_time_popups/";
const mostRecentUrl = "most_recent";
const markAsReadUrl = "mark_as_read";

function setOneTimePopup(payload) {
  return {
    type: ONE_TIME_POPUP_SET,
    payload,
  };
}

function markAsRead() {
  return {
    type: ONE_TIME_POPUP_READ,
  };
}

function checkOneTimePopup() {
  return function(dispatch) {
    return axios({
      url: baseUrl + mostRecentUrl,
      method: "get",
      withCredentials: true
    }).then(response => {
      const popups = response.data.one_time_popups;
      if (popups.length) {
        dispatch(setOneTimePopup(popups));
      }
    });
  };
}

function markOneTimePopupAsRead() {
  return function(dispatch, getState) {
    const state = getState();
    const activePopup = findActivePopup(state);
    return axios({
      url: baseUrl + activePopup.id + "/" + markAsReadUrl,
      method: "patch",
      withCredentials: true
    }).then(response => {
      dispatch(markAsRead());
    });
  };
}

export default {
  markOneTimePopupAsRead,
  checkOneTimePopup,
};
