import {
  ED_SET_WLP_SETTINGS_FORM_DATA,
} from 'modules/education/actions/constants';

import update from 'immutability-helper';
import initialState from './initialState';

export default (state = initialState, action) => {
  switch (action.type) {
    case ED_SET_WLP_SETTINGS_FORM_DATA: {
      return update(state, {
        wlpSettingsFormData: {
          data: { $set: action.payload },
        },
      });
    }
    default:
      return state;
  }
};
