import { isEmpty } from 'lodash';
import IcnIdentity from 'services/icn_identity/icnIdentity';
import showExceptionFlagsForWlpInvState from 'services/subscription_review/exceptionFlags';

const showMainSiteExceptionFlags = (icnBootstrap) => (
  icnBootstrap.white_label_partner.show_main_site_exception_flags_in_sub_review
);
const showWlpExceptionFlags = (icnBootstrap) => (
  IcnIdentity.isWhiteLabel(icnBootstrap) && !showMainSiteExceptionFlags(icnBootstrap)
);
const exceptionFlags = (flaggedFields) => (!isEmpty(flaggedFields) && flaggedFields) || null;

const calculateFlaggedFields = (investment, icnBootstrap) => {
  const flaggedFields = investment.flagged_fields;

  if (!flaggedFields) { return null; }

  if (showWlpExceptionFlags(icnBootstrap)) {
    const slug = icnBootstrap.white_label_partner.slug;

    return (showExceptionFlagsForWlpInvState(slug, investment.approval_status) &&
      exceptionFlags(flaggedFields.wlps[slug]));
  }

  return exceptionFlags(flaggedFields.main_site);
};

export default calculateFlaggedFields;
