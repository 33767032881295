import {
  deleteRequest,
  getRequest,
  putRequest,
  postRequest,
  patchRequest,
} from 'actions/utils';
import { createCRUD, createRequestTypes } from 'actions/helpers';

export const BASE_URL = 'mass_mailer/mass_mailer_documents';
export const BASE_NAME = 'massMailerDocument';

export const MASS_MAILER_DOCUMENTS = createCRUD(BASE_NAME, {
  LIST: createRequestTypes(`${BASE_NAME}_LIST`),
});

export const getMassMailerDocuments = (query) => getRequest({
  params: query,
  showSpinner: false,
  url: BASE_URL,
  actions: MASS_MAILER_DOCUMENTS.LIST,
});

export const createMassMailerDocument = ({ massMailerDocument }) => postRequest({
  url: BASE_URL,
  actions: MASS_MAILER_DOCUMENTS.CREATE,
  data: {
    mass_mailer_document: massMailerDocument,
  },
});

export const updateMassMailerDocument = ({ id, massMailerDocument }) => putRequest({
  url: `${BASE_URL}/${id}`,
  actions: MASS_MAILER_DOCUMENTS.UPDATE,
  data: {
    mass_mailer_document: massMailerDocument,
  },
  requestParams: {
    id,
  },
});

export const createMassMailerDocumentEmails = (id) => patchRequest({
  url: `${BASE_URL}/${id}/create_contacts`,
  actions: MASS_MAILER_DOCUMENTS.UPDATE,
  requestParams: {
    id,
  },
});

export const approveMassMailerDocumentEmails = (id) => patchRequest({
  url: `${BASE_URL}/${id}/approve`,
  actions: MASS_MAILER_DOCUMENTS.UPDATE,
  requestParams: {
    id,
  },
});

export const rejectMassMailerDocumentEmails = (id) => patchRequest({
  url: `${BASE_URL}/${id}/reject`,
  actions: MASS_MAILER_DOCUMENTS.UPDATE,
  requestParams: {
    id,
  },
});

export const deleteMassMailerDocument = ({ id }) => deleteRequest({
  url: `${BASE_URL}/${id}`,
  actions: MASS_MAILER_DOCUMENTS.DELETE,
  requestParams: {
    id,
  },
});

export const showMassMailerDocument = ({ id }, showSpinner = true) => getRequest({
  url: `${BASE_URL}/${id}`,
  actions: MASS_MAILER_DOCUMENTS.READ,
  showSpinner,
  requestParams: {
    id,
  },
});

export default {
  getMassMailerDocuments,
  createMassMailerDocument,
  updateMassMailerDocument,
  deleteMassMailerDocument,
  showMassMailerDocument,
};
