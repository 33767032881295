import { PRE_APPROVAL_QUESTIONNAIRE } from 'actions/pre_approval_questionnaire_actions/preApprovalQuestionnaireActions';

import update from 'immutability-helper';
import initialState from './initialState';

export default (state = initialState, action) => {
  switch (action.type) {
    case PRE_APPROVAL_QUESTIONNAIRE.LOAD.REQUEST:
      return update(state, {
        isLoading: { $set: true },
      });
    case PRE_APPROVAL_QUESTIONNAIRE.LOAD.SUCCESS:
      return update(state, {
        isLoading: { $set: false },
        schema: { $set: action.payload.response.schema },
        uiSchema: { $set: action.payload.response.ui_schema },
        formData: { $set: action.payload.response.previous_responses },
        accountName: { $set: action.payload.response.account_name },
        accountNum: { $set: action.payload.response.account_num },
        signerType: { $set: { 0: 'individual', 1: 'entity' }[action.payload.response.signer_type] },
      });
    case PRE_APPROVAL_QUESTIONNAIRE.LOAD.FAILURE:
      return update(state, {
        isLoading: { $set: false },
      });
    default:
      return state;
  }
};
