import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  arrayOf,
  func,
  string,
} from 'prop-types';
import { routeCodes } from 'router';
import { searchResult, tab } from 'prop_types/admin_search/index';
import * as asSelectors from 'reducers/admin_search_reducers/selectors';
import { bindActionCreators } from 'redux';
import actions from 'actions';
import { useHistory, useLocation } from 'react-router-dom';
import { generatePath } from 'react-router';

import ASPreviewResultsList from 'containers/admin_search/as_preview_results/ASPreviewResultsList';
import IcnTabs from 'components/shared/icn_tabs/IcnTabs';
import IcnTab from 'components/shared/icn_tabs/IcnTab';

import styles from './ASPreviewResults.module.scss';

export const ASPreviewResults = ({
  currentSearchResults,
  searchKeyword,
  tabs,
  setPreviousPageUrl,
}) => {
  const [currentTab, setCurrentTab] = useState(0);

  const changeTab = (tabId) => {
    setCurrentTab(tabId);
  };

  const adminSearchURL = searchKeyword ?
    generatePath(routeCodes.ADMIN_SEARCH, { searchText: searchKeyword }) :
    generatePath(routeCodes.ADMIN_SEARCH);

  const renderSelectedTabContent = () => currentSearchResults?.map((searchItem) => (
    <ASPreviewResultsList
      displayTitle={currentTab === -1}
      key={searchItem.type}
      searchResults={searchItem}
      searchKeyword={searchKeyword}
    />
  ));

  const { pathname } = useLocation();
  const { push } = useHistory();

  const onClickSeeAllResults = () => {
    setPreviousPageUrl(pathname);
    push(adminSearchURL);
  };

  return (
    <div>
      <IcnTabs>
        {tabs?.map((tabItem) => (
          <IcnTab
            key={tabItem.id}
            onClick={() => changeTab(tabItem.id)}
            isSelected={tabItem.id === currentTab}
            className={styles.tab_label}
          >
            {tabItem.label}
          </IcnTab>
        ))}
      </IcnTabs>
      <div className={styles.selected_tab_content}>
        {renderSelectedTabContent()}
      </div>
      {currentSearchResults.length > 0 && (
        <button
          type="button"
          onClick={onClickSeeAllResults}
          className={styles.see_all_results_link}
        >
          See All Search Results
        </button>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    currentSearchResults: asSelectors.currentSearchResults(state),
    searchKeyword: asSelectors.searchKeyword(state),
    tabs: asSelectors.tabs(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    setPreviousPageUrl: actions.admsSetPreviousPageUrl,
  }, dispatch);
}

ASPreviewResults.defaultProps = {
  currentSearchResults: null,
  searchKeyword: null,
  tabs: null,
};

ASPreviewResults.propTypes = {
  currentSearchResults: arrayOf(searchResult),
  searchKeyword: string,
  tabs: arrayOf(tab),
  setPreviousPageUrl: func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ASPreviewResults);
