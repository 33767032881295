import update from 'immutability-helper';

import { RG_SET_SNOWFLAKE_CONFIGS } from 'actions/actionsConstants';
import initialState from 'reducers/report_generator_reducers/initialState';

export default (state = initialState, action) => {
  switch (action.type) {
    case RG_SET_SNOWFLAKE_CONFIGS:
      return update(state, {
        snowflakeConfigs: {
          $set: action.payload.rg_snowflake_configs,
        },
      });
    default:
      return state;
  }
};
