import {
  SR_SET_SALES_REPORTS,
} from 'actions/actionsConstants';

import update from 'immutability-helper';

const initialState = {
  sales_reports: [],
  total: 0,
  page: 1,
  per_page: 25,
  sort_field: 'export_date',
  sort_direction: 'desc'
};

export { initialState };

export default (state = initialState, action) => {
  switch (action.type) {
    case SR_SET_SALES_REPORTS:
      return update(state, {
        sales_reports: { $set: action.payload.sales_reports },
        total: { $set: action.payload.total },
        page: { $set: action.payload.page },
        per_page: { $set: action.payload.per_page },
        sort_field: { $set: action.payload.sort_field },
        sort_direction: { $set: action.payload.sort_direction },
      });
    default:
      return state;
  }
};
