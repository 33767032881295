import {
  FLEXIBLE_FEED_DASHBOARD_SET_PRIVATE_ACCESS_FUNDS,
} from 'actions/actionsConstants';

import { updateState } from '../helpers';
import initialState from '../initialState';

export default (state = initialState, action) => {
  switch (action.type) {
    case FLEXIBLE_FEED_DASHBOARD_SET_PRIVATE_ACCESS_FUNDS:
      return updateState(state, action.payload, (data) => ({
        privateAccessFunds: data,
      }));
    default:
      return state;
  }
};
