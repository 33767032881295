/* eslint-disable max-lines */
/* eslint-disable max-statements */
import { getRequest, postRequest } from 'actions/utils';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import indexOf from 'lodash/indexOf';
import isEqual from 'lodash/isEqual';
import { filterProps } from 'hooks/investment_dasboard/useTransactionsFilter';
import {
  beginApiRequest,
  endApiRequest,
} from 'actions/spinner_actions/spinnerActions';
import {
  investmentDashboardAnonymizedFields,
  isInterestedParty,
  canHaveInvestments,
  canSeeDocumentCenter,
} from 'selectors/icnBootstrapSelectors';
import {
  getSelectedFund,
  getSelectedAccounts,
  getSelectedInvestor,
  getSelectedAdvisor,
  getInvestmentIds,
  getExternalCommitmentsIds,
  getDataSetsLoaded,
  canSeeInvestmentDashboardTransactions,
  ubsLayout,
  getAggregationLevel,
  getMostRecentStatementInSelectedCurrency,
  canViewHedgeFunds,
  getSelectedAssetClass,
  hasHedgeFundBehavior,
  getAssetClasses,
  getAccounts,
  getFilteredFunds,
  getFilteredAccounts,
  getFunds,
  isAggregatedAccountsSelected,
  getActiveTab,
  canViewAllTab,
  canViewPcapCalcsForPrivateCapitalFunds,
  isFundLevel,
} from 'reducers/investment_dashboard_reducers/selectors';
import {
  assetClassWithPrivateCapitalBehavior,
  isAllAssetClass,
} from 'services/investment_dashboard/generalBehaviour';
import notificationService from 'services/notifications';

import {
  idSetAdvisors,
  idSelectAdvisor,
  idSetInvestors,
  idSelectInvestor,
  idSetAccounts,
  idSelectAccounts,
  idResetAdvisor,
  idResetInvestor,
  idResetAccount,
  idSetFunds,
  idSelectFund,
  idSetFundData,
  idResetFundData,
  idSetInvestmentData,
  idResetInvestmentData,
  idSetGeneralLedgerTransactionsData,
  idSelectActiveTab,
  idSetTabs,
  idUpdateDataSetsLoaded,
  idSetFundDataMetrics,
  idFetchingAdvisorsOnGoing,
  idFetchingInvestorsOnGoing,
  idFetchingInvestorProfileQueryStringOnGoing,
  idFetchingAccountsOnGoing,
  idFetchingFundsOnGoing,
  idSetAllTransactions,
  idSetAllExternalCommitmentTransactions,
  idSetInvestorProfileQueryString,
  idSetExports,
  idSetExportCsvData,
  idSetExportFlyoverStatus,
  idSetDocCenterWidgetCache,
  idSetHideAdvisorDropdown,
  idSetAggregationLevel,
  idSetDisclaimers,
  idSetConfig,
  idSetAssetClass,
  idSetAssetClasses,
  idSetFilteredAccounts,
  idResetFund,
  idResetAssetClass,
  idSetSelectedCurrency,
} from './helpers/constants';

import { idFetchGeneralLedgerTransactionsList, idLoadGeneralLedgerTransactionsSet } from './generalLedgerTransactionsActions';
import { idGetCapitalStatements } from './capitalStatementsActions';

const idGetGeneralLedgerTransactionsData = (endpoint, params) => (
  (dispatch, getState) => (
    dispatch(getRequest({
      url: endpoint,
      params: { ...params, selected_currency: getState().investmentDashboard.selectedCurrencyData.currencyCode },
    })).then((json) => {
      dispatch(
        idSetGeneralLedgerTransactionsData(json)
      );
    }).finally(() => {
      dispatch(endApiRequest());
    })
  )
);

// To be deleted after the removal of the transactions' old endpoints
const idFetchGeneralLedgerTransactionsDataLegacy = (investmentsIds, statementDate) => {
  const endpoint = 'investment_dashboard/general_ledger_transactions_legacy';
  const params = {
    investments_ids: investmentsIds,
    pcap_date: statementDate,
  };
  return idGetGeneralLedgerTransactionsData(endpoint, params);
};

// To be deleted after the removal of the transactions' old endpoints
const idFetchExternalCommitmentGeneralLedgerTransactionsDataLegacy = (externalCommitmentsIds, statementDate) => {
  const endpoint = 'investment_dashboard/general_ledger_transaction_external_commitments_legacy';
  const params = {
    external_commitments_ids: externalCommitmentsIds,
    pcap_date: statementDate,
  };
  return idGetGeneralLedgerTransactionsData(endpoint, params);
};

// To be deleted after the removal of the transactions' old endpoints
const idFetchAllGeneralLedgerTransactionsDataLegacy = (params) => (
  getRequest({
    url: 'investment_dashboard/general_ledger_transactions_legacy/list_transactions',
    params,
    onSuccess: idSetAllTransactions,
  })
);

// To be deleted after the removal of the transactions' old endpoints
const idFetchAllGeneralLedgerExternalCommitmentTransactionsDataLegacy = (params) => (
  getRequest({
    url: 'investment_dashboard/general_ledger_transaction_external_commitments_legacy/list_transactions',
    params,
    onSuccess: idSetAllExternalCommitmentTransactions,
  })
);

// To be deleted after the removal of the transactions' old endpoints
const idFetchAllGeneralLedgerTransactionsData = (params) => (
  (dispatch, getState) => {
    if (canViewPcapCalcsForPrivateCapitalFunds(getState())) {
      return dispatch(idFetchGeneralLedgerTransactionsList({
        ...params,
        investment_ids: params.investments_ids,
        external_commitment_ids: params.external_commitments_ids,
      }));
    }

    if (params.investments_ids?.length) {
      return dispatch(idFetchAllGeneralLedgerTransactionsDataLegacy(params));
    }

    if (params.external_commitments_ids?.length) {
      return dispatch(idFetchAllGeneralLedgerExternalCommitmentTransactionsDataLegacy(params));
    }

    return null;
  }
);

const idFetchExternalCommitmentData = (externalCommitmentsIds) => (
  (dispatch, getState) => dispatch(getRequest({
    url: 'investment_dashboard/external_commitments_legacy',
    params: {
      ids: externalCommitmentsIds,
      selected_currency: getState().investmentDashboard.selectedCurrencyData.currencyCode,
      aggregation_level: getAggregationLevel(getState()),
    },
  })));

const idFetchExports = () => (
  getRequest({
    url: 'investment_dashboard/export_history',
    onSuccess: idSetExports,
  })
);

const getInvestorProfileQueryString = (accountIds) => {
  if (isNull(accountIds) || isUndefined(accountIds)) {
    return (dispatch) => {
      dispatch(idSetInvestorProfileQueryString(''));
    };
  }
  const endpoint = 'investment_dashboard/investor_profiles/investor_profile_query_string';
  return (dispatch) => {
    dispatch(idSetInvestorProfileQueryString(''));
    dispatch(getRequest({
      url: endpoint,
      params: { account_ids: accountIds },
      onSuccess: idSetInvestorProfileQueryString,
    })).finally(() => dispatch(idFetchingInvestorProfileQueryStringOnGoing(false)));
  };
};

const idGenerateExport = (exportType, period = 'All') => (
  (dispatch, getState) => {
    const state = getState();

    const investmentsIds = getInvestmentIds(state);
    const externalCommitmentsIds = getExternalCommitmentsIds(state);
    const selectedFund = getSelectedFund(state);
    const selectedAccounts = getSelectedAccounts(state);

    return dispatch(postRequest({
      url: 'investment_dashboard/export_history/generate_export',
      showSpinner: false,
      params: {
        investment_id: investmentsIds,
        external_commitment_id: externalCommitmentsIds,
        current_fund_id: selectedFund.fund_id,
        investor_name: selectedAccounts.name,
        export_type: exportType,
        period,
      },
    })).then((response) => {
      const csvData = {
        link: encodeURI(response.csvContent),
        name: `export_${exportType}_${(new Date()).toISOString()}.csv`,
      };
      dispatch(idSetExportCsvData(csvData));
      dispatch(idSetExportFlyoverStatus('ready'));
      // eslint-disable-next-line max-lines
      dispatch(idFetchExports());
    });
  }
);

const idFetchFundData = (fund) => (
  (dispatch, getState) => {
    const state = getState();

    const selectedAccounts = getSelectedAccounts(state);

    return dispatch(getRequest({
      url: 'investment_dashboard/private_access_funds/funds_data',
      params: {
        account_ids: selectedAccounts.ids,
        fund_ids: fund.id,
      },
      onSuccess: idSetFundData,
    }));
  }
);

const idFetchInvestmentsData = (investmentsIds) => (dispatch, getState) => dispatch(getRequest({
  url: 'investment_dashboard/investments_legacy',
  params: {
    investments_ids: investmentsIds,
    selected_currency: getState().investmentDashboard.selectedCurrencyData.currencyCode,
    aggregation_level: getAggregationLevel(getState()),
  },
}));

const idFetchConfig = () => (
  getRequest({
    url: 'investment_dashboard/investment_dashboard_configs',
    onSuccess: idSetConfig,
  })
);

const idFetchFundDataMetrics = () => (
  (dispatch, getState) => {
    const state = getState();
    const selectedFund = getSelectedFund(state);

    return dispatch(getRequest({
      url: 'investment_dashboard/private_access_funds/fund_data_metrics',
      params: { paf_id: selectedFund.id },
      onSuccess: idSetFundDataMetrics,
    }));
  }
);

const isAccountLevelAggregation = (fund) => Array.isArray(fund.id);

const idFetchDisclaimers = (fund) => (
  getRequest({
    url: 'investment_dashboard/investment_reporting_disclaimers',
    params: { paf_ids: fund.id },
    onSuccess: idSetDisclaimers,
  })
);

const idSelectingTabs = (assetClass) => (
  (dispatch, getState) => {
    const newTabs = [{ id: 0, key: 'investment_overview', label: 'overview' }];
    if (assetClass && assetClassWithPrivateCapitalBehavior(assetClass)) {
      newTabs.push({ id: newTabs.length, key: 'cash_flow', label: 'cash_flow' });
    }
    const canSeeDocCenter = canSeeDocumentCenter(getState());
    if (canSeeDocCenter) {
      newTabs.push({ id: newTabs.length, key: 'document_center', label: 'document_other' });
    }

    const selectedTab = newTabs.find((tab) => tab.key === getActiveTab(getState()).key) || { id: 0, key: 'investment_overview', label: 'overview' };
    dispatch(idSelectActiveTab(selectedTab));

    dispatch(idSetTabs(newTabs));
  }
);

const selectingAggregationLevel = (fund) => (
  (dispatch, getState) => {
    if (!canViewHedgeFunds(getState())) {
      if (isAggregatedAccountsSelected(getState())) {
        dispatch(idSetAggregationLevel('investor_level'));
      } else if (isAccountLevelAggregation(fund)) {
        dispatch(idSetAggregationLevel('account_level'));
      } else {
        dispatch(idSetAggregationLevel('fund_level'));
        return dispatch(idFetchDisclaimers(fund));
      }
    } else {
      const filteredAccounts = getFilteredAccounts(getState());
      const filteredFunds = getFilteredFunds(getState());

      if (filteredAccounts.length > 1) {
        dispatch(idSetAggregationLevel('investor_level'));
      } else if (filteredFunds.length > 1) {
        dispatch(idSetAggregationLevel('account_level'));
      } else {
        dispatch(idSetAggregationLevel('fund_level'));
        return dispatch(idFetchDisclaimers(fund));
      }
    }
  }
);

const handleFundSelection = (fund) => (
  (dispatch) => {
    dispatch(idSelectFund(fund));

    dispatch(idFetchFundData(fund));
    return dispatch(selectingAggregationLevel(fund));
  });

const filterFunds = (currentFund, filteredFunds) => {
  currentFund.id = filteredFunds.map((f) => f.id);
  currentFund.fund_id = filteredFunds.map((f) => f.fund_id);
  return currentFund;
};

const selectFilteredAccounts = (filteredAccountsIds, accounts) => (
  (dispatch) => {
    const filteredAccounts = accounts
      .filter((account) => account.ids.some((id) => filteredAccountsIds.includes(id)) && account.name !== 'aggregated_accounts');
    dispatch(idSetFilteredAccounts(filteredAccounts));
  }
);

const idSelectingCurrency = (currency) => (
  (dispatch, getState) => {
    dispatch(idResetInvestmentData());
    dispatch(idSetSelectedCurrency(currency));
  }
);

const idSelectingAssetClass = (assetClass, fund) => (
  (dispatch, getState) => {
    if (!canViewHedgeFunds(getState())) return;

    let currentFund = fund || getSelectedFund(getState());

    dispatch(idResetInvestmentData());
    dispatch(idResetFundData());
    dispatch(idResetAssetClass());

    dispatch(idSetAssetClass(assetClass));
    dispatch(idSelectingTabs(assetClass));

    let filteredAccountsIds = currentFund.account_ids;

    if (currentFund.name === 'aggregated_holdings') {
      // if different filter
      let filteredFunds = getFunds(getState()).filter((f) => f.name !== 'aggregated_holdings');
      filteredFunds = isAllAssetClass(assetClass) ? filteredFunds
        : filteredFunds
          .filter((f) => f.asset_class === assetClass);

      currentFund = filterFunds(currentFund, filteredFunds);
      filteredAccountsIds = filteredFunds
        .reduce((acc, f) => { acc.push(...f.account_ids); return acc; }, []);
    }
    dispatch(selectFilteredAccounts(filteredAccountsIds, getAccounts(getState())));

    return dispatch(handleFundSelection(currentFund));
  }
);

const idSelectingFund = (fund) => (
  (dispatch, getState) => {
    dispatch(idUpdateDataSetsLoaded({ docCenterWidgetCacheDataSet: false }));
    dispatch(idResetInvestmentData());
    dispatch(idResetFundData());
    dispatch(idResetFund());

    const state = getState();
    if (canViewHedgeFunds(state)) {
      const prevAssetClass = getAssetClasses(state).find((ac) => isEqual(ac, getSelectedAssetClass(state)));
      const assetClass = fund?.asset_class || prevAssetClass || getAssetClasses(getState())[0];
      return dispatch(idSelectingAssetClass(assetClass, fund));
    }
    return dispatch(handleFundSelection(fund));
  }
);

const idFetchFunds = (selectedFundId = null) => (
  (dispatch, getState) => {
    const state = getState();
    const selectedAccounts = getSelectedAccounts(state);

    dispatch(idFetchingFundsOnGoing(true));

    return dispatch(getRequest({
      url: 'investment_dashboard/private_access_funds',
      params: {
        account_ids: selectedAccounts.ids,
        is_investor_level: isAggregatedAccountsSelected(state),
      },
      onSuccess: idSetFunds,
    })).then((funds) => {
      if (canViewHedgeFunds(state)) {
        const uniqAssetClasses = funds.reduce((assetClasses, fund) => {
          if (fund.asset_class && !assetClasses.includes(fund.asset_class)) {
            assetClasses.push(fund.asset_class);
          }
          return assetClasses;
        }, []);

        if (uniqAssetClasses.length > 1 && canViewAllTab(state)) {
          uniqAssetClasses.push('all');
        }

        const order = ['all', 'private_capital_close_ended', 'private_capital_open_ended', 'hedge_fund_open_ended'];
        dispatch(idSetAssetClasses(sortBy(uniqAssetClasses, (obj) => indexOf(order, obj))));
      }

      if (!selectedFundId) {
        const hasAggregateHoldingsOption = funds.length > 0 && Array.isArray(funds[0].id);

        if (funds.length === 1 || hasAggregateHoldingsOption) {
          dispatch(idSelectingFund(funds[0]));
        }
      } else {
        const fund = funds.find((f) => f.fund_id === parseInt(selectedFundId, 10));
        if (fund) {
          dispatch(idSelectingFund(fund));
        }
      }
    }).finally(() => {
      dispatch(idFetchingFundsOnGoing(false));
    });
  }
);

const idSelectingAccounts = (accounts, selectedFundId = null) => (
  (dispatch) => {
    dispatch(idSetInvestorProfileQueryString(''));
    dispatch(idFetchingInvestorProfileQueryStringOnGoing(true));
    dispatch(idUpdateDataSetsLoaded({ docCenterWidgetCacheDataSet: false }));
    dispatch(beginApiRequest());

    dispatch(idResetInvestmentData());
    dispatch(idResetFundData());
    dispatch(idResetAccount());

    dispatch(idSelectAccounts(accounts));
    dispatch(getInvestorProfileQueryString(accounts.ids));
    return dispatch(idFetchFunds(selectedFundId))
      .finally(() => {
        dispatch(endApiRequest());
      });
  }
);

const idFetchAccounts = () => (
  (dispatch, getState) => {
    const state = getState();
    const selectedAdvisor = getSelectedAdvisor(state);
    const selectedInvestor = getSelectedInvestor(state);

    dispatch(idFetchingAccountsOnGoing(true));
    return dispatch(getRequest({
      url: 'investment_dashboard/filters/accounts',
      params: {
        advisor_id: selectedAdvisor?.id,
        investor_contact_card_id: selectedInvestor.id,
      },
      onSuccess: idSetAccounts,
    })).then((accounts) => {
      const hasAggregateAccountsOption = accounts.length > 0 && accounts[0].name === 'aggregated_accounts';

      if (accounts.length === 1 || hasAggregateAccountsOption) {
        dispatch(idSelectingAccounts(accounts[0]));
      }
    }).finally(() => {
      dispatch(idFetchingAccountsOnGoing(false));
    });
  }
);

const idSelectingInvestor = (investor) => (
  (dispatch) => {
    dispatch(idSetInvestorProfileQueryString(''));
    dispatch(idUpdateDataSetsLoaded({ docCenterWidgetCacheDataSet: false }));
    dispatch(beginApiRequest());

    dispatch(idResetInvestmentData());
    dispatch(idResetFundData());
    dispatch(idResetInvestor());

    dispatch(idSelectInvestor(investor));
    return dispatch(idFetchAccounts())
      .finally(() => {
        dispatch(endApiRequest());
      });
  }
);

const idFetchInvestors = (query = '') => (
  (dispatch, getState) => {
    const state = getState();
    const selectedAdvisor = getSelectedAdvisor(state);

    dispatch(idFetchingInvestorsOnGoing(true));
    return dispatch(getRequest({
      url: 'investment_dashboard/filters/investors',
      params: {
        advisor_id: selectedAdvisor?.id,
        query,
      },
      showSpinner: investmentDashboardAnonymizedFields(state),
      onSuccess: idSetInvestors,
    })).then((investors) => {
      if (investors.length === 1 && query === '') {
        dispatch(idSelectingInvestor(investors[0]));
      }
    }).finally(() => {
      dispatch(idFetchingInvestorsOnGoing(false));
    });
  }
);

const idSelectingAdvisor = (advisor) => (
  (dispatch) => {
    dispatch(idUpdateDataSetsLoaded({ docCenterWidgetCacheDataSet: false }));
    dispatch(idSetInvestorProfileQueryString(''));
    dispatch(beginApiRequest());

    dispatch(idResetInvestmentData());
    dispatch(idResetFundData());
    dispatch(idResetAdvisor());

    dispatch(idSelectAdvisor(advisor));
    return dispatch(idFetchInvestors())
      .finally(() => {
        dispatch(endApiRequest());
      });
  }
);

const idFetchAdvisors = (query = '') => (
  (dispatch, getState) => {
    const state = getState();

    dispatch(beginApiRequest());
    dispatch(idFetchingAdvisorsOnGoing(true));
    return dispatch(getRequest({
      url: 'investment_dashboard/filters/advisors',
      params: { query },
      showSpinner: investmentDashboardAnonymizedFields(state),
      onSuccess: idSetAdvisors,
    })).then((advisors) => {
      if (query === '') {
        if (advisors.length === 0) {
          dispatch(idSetHideAdvisorDropdown(true));
          dispatch(idFetchInvestors());
        } else if (advisors.length === 1) {
          dispatch(idSelectingAdvisor(advisors[0]));
        }
      }
    }).finally(() => {
      dispatch(idFetchingAdvisorsOnGoing(false));
      dispatch(endApiRequest());
    });
  }
);

const loadFirstDropdown = () => (
  (dispatch, getState) => {
    const state = getState();
    const individualInvestor = canHaveInvestments(state);
    const interestedParty = isInterestedParty(state);

    if (individualInvestor || interestedParty) {
      return dispatch(idFetchInvestors());
    }
    return dispatch(idFetchAdvisors());
  }
);

const idFetchClientContext = (accountId) => (
  (dispatch) => (
    dispatch(getRequest({
      url: 'investment_dashboard/filters/url_context',
      params: {
        account: accountId,
      },
      onFailure: (payload) => notificationService.notifyError('', map(payload.data.errors, (error) => error)),
    }))
  ).then((clientContextData) => {
    dispatch(idSetAdvisors(clientContextData.available_advisors));
    dispatch(idSelectAdvisor(clientContextData.selected_advisor[0]));
    dispatch(idSetInvestors(clientContextData.available_investors));
    dispatch(idSelectInvestor(clientContextData.selected_investor[0]));
    dispatch(idSetAccounts(clientContextData.available_accounts));
    dispatch(idSelectAccounts(clientContextData.selected_account[0]));
    dispatch(getInvestorProfileQueryString(clientContextData.selected_account[0].ids));
    dispatch(idFetchFunds());
  })
);

const fetchInitialData = () => (
  (dispatch) => {
    const clientContext = window.location.search;

    if (clientContext.indexOf('account=') !== -1) {
      const accountId = clientContext.substr(clientContext.indexOf('account=') + 8, clientContext.length - 1);
      if (accountId === -1) {
        dispatch(notificationService.notifyError('', 'The selected account is not available.'));
        return dispatch(loadFirstDropdown());
      }

      return dispatch(idFetchClientContext(accountId)).catch(() => {
        dispatch(loadFirstDropdown());
      });
    }
    return dispatch(loadFirstDropdown());
  }
);

const idLoadInvestmentsDataSetLegacy = (investmentsIds, externalCommitmentsIds) => (
  (dispatch, getState) => {
    const state = getState();
    const dataSetsLoaded = getDataSetsLoaded(state);

    let promise = Promise.resolve();

    if (dataSetsLoaded.investmentsDataSet) return promise;

    if (investmentsIds) {
      promise = dispatch(idFetchInvestmentsData(investmentsIds))
        .then((investmentData) => {
          dispatch(idSetInvestmentData(
            {
              investmentInSelectedCurrency: investmentData.selected_currency_data,
              investmentInFundCurrency: investmentData.fund_currency_data,
            }
          ));
        });
    } else if (externalCommitmentsIds) {
      promise = dispatch(idFetchExternalCommitmentData(externalCommitmentsIds))
        .then((externalCommitmentData) => {
          if (externalCommitmentData) {
            dispatch(idSetInvestmentData(
              {
                investmentInSelectedCurrency: externalCommitmentData.selected_currency_data,
                investmentInFundCurrency: externalCommitmentData.fund_currency_data,
              }
            ));
          }
        });
    }
    dispatch(idUpdateDataSetsLoaded({ investmentsDataSet: true }));
    return promise;
  }
);

const idLoadExportHistoryDataSet = () => (
  (dispatch, getState) => {
    const state = getState();
    const dataSetsLoaded = getDataSetsLoaded(state);

    if (dataSetsLoaded.exportHistoryDataSet) return;

    dispatch(idFetchExports());
    dispatch(idUpdateDataSetsLoaded({ exportHistoryDataSet: true }));
  }
);

const idLoadTransactionsDataSetLegacy = (investmentsIds, externalCommitmentsIds) => (
  (dispatch, getState) => {
    const state = getState();
    const dataSetsLoaded = getDataSetsLoaded(state);

    if (dataSetsLoaded.transactionsDataSet) return;

    const canSeeIDTransactions = canSeeInvestmentDashboardTransactions(state);

    if (investmentsIds) {
      dispatch(
        idFetchGeneralLedgerTransactionsDataLegacy(
          investmentsIds,
          getMostRecentStatementInSelectedCurrency(state).quarter_end_date
        )
      );

      if (canSeeIDTransactions) {
        dispatch(
          idFetchAllGeneralLedgerTransactionsDataLegacy({
            investments_ids: investmentsIds,
            ...filterProps(),
          })
        );
      }
    } else if (externalCommitmentsIds) {
      dispatch(idFetchExternalCommitmentGeneralLedgerTransactionsDataLegacy(
        externalCommitmentsIds,
        getMostRecentStatementInSelectedCurrency(state).quarter_end_date
      ));

      if (canSeeIDTransactions) {
        dispatch(
          idFetchAllGeneralLedgerExternalCommitmentTransactionsDataLegacy({
            external_commitments_ids: externalCommitmentsIds,
            ...filterProps(),
          })
        );
      }
    }
    dispatch(idUpdateDataSetsLoaded({ transactionsDataSet: true }));
  }
);

const idLoadFundDataMetricsDataSet = () => (
  (dispatch, getState) => {
    const state = getState();
    const dataSetsLoaded = getDataSetsLoaded(state);

    if (dataSetsLoaded.fundDataMetricsDataSet) return;

    dispatch(idFetchFundDataMetrics());
    dispatch(idUpdateDataSetsLoaded({ fundDataMetricsDataSet: true }));
  }
);

const idLoadCashFlowTabData = () => (
  async (dispatch, getState) => {
    const state = getState();

    const investmentsIds = getInvestmentIds(state);
    const externalCommitmentsIds = getExternalCommitmentsIds(state);

    dispatch(idLoadExportHistoryDataSet());

    if (canViewPcapCalcsForPrivateCapitalFunds(state)) {
      await dispatch(idGetCapitalStatements());
    } else { // To be deleted after the removal of the transactions' old endpoints
      await dispatch(idLoadInvestmentsDataSetLegacy(investmentsIds, externalCommitmentsIds));
    }

    if (isFundLevel(state)) {
      if (canViewPcapCalcsForPrivateCapitalFunds(state)) {
        dispatch(idLoadGeneralLedgerTransactionsSet({
          investment_ids: investmentsIds,
          external_commitment_ids: externalCommitmentsIds,
        }));
      } else { // To be deleted after the removal of the transactions' old endpoints
        dispatch(idLoadTransactionsDataSetLegacy(investmentsIds, externalCommitmentsIds));
      }
    }
  }
);

const idLoadInvestmentOverviewTabData = () => (
  (dispatch, getState) => {
    const state = getState();

    const investmentsIds = getInvestmentIds(state);
    const externalCommitmentsIds = getExternalCommitmentsIds(state);
    const isUbsLayout = ubsLayout(state);

    dispatch(beginApiRequest());

    if (canViewPcapCalcsForPrivateCapitalFunds(state)) {
      dispatch(idGetCapitalStatements());
    } else {
      // To be deleted after the removal of the transactions' old endpoints
      dispatch(idLoadInvestmentsDataSetLegacy(investmentsIds, externalCommitmentsIds));
    }

    if (isUbsLayout || hasHedgeFundBehavior(state)) {
      dispatch(idLoadFundDataMetricsDataSet());
    }

    dispatch(endApiRequest());
  }
);

export default {
  idFetchAdvisors,
  idFetchInvestors,
  idFetchAccounts,
  idFetchFunds,
  idSelectingAdvisor,
  idSelectingInvestor,
  idSelectingAccounts,
  idSelectingFund,
  idSelectingCurrency,
  idSelectAccounts,
  idSelectInvestor,
  idSelectFund,
  idResetFundData,
  idFetchExternalCommitmentData,
  idResetInvestmentData,
  idFetchGeneralLedgerTransactionsDataLegacy,
  idFetchExternalCommitmentGeneralLedgerTransactionsDataLegacy,
  idFetchAllGeneralLedgerTransactionsData,
  idFetchAllGeneralLedgerExternalCommitmentTransactionsDataLegacy,
  getInvestorProfileQueryString,
  idSelectActiveTab,
  idUpdateDataSetsLoaded,
  idLoadCashFlowTabData,
  idLoadInvestmentOverviewTabData,
  idFetchFundDataMetrics,
  idFetchingInvestorProfileQueryStringOnGoing,
  idSetInvestorProfileQueryString,
  idFetchExports,
  idSetExportFlyoverStatus,
  idGenerateExport,
  idLoadInvestmentsDataSetLegacy,
  idSetDocCenterWidgetCache,
  idFetchClientContext,
  fetchInitialData,
  idFetchDisclaimers,
  idFetchConfig,
  idSelectingAssetClass,
};
