import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
} from 'actions/utils';
import { createRequestTypes } from 'actions/helpers';

const BASE_URL = 'investment_block/rules';
const BASE_NAME = 'investmentBlocksManager';

export const INVESTMENT_BLOCKS_MANAGER = {
  INVESTMENT_BLOCK_RULES_LIST: createRequestTypes(`${BASE_NAME}/INVESTMENT_BLOCK_RULES_LIST`),
  INVESTMENT_BLOCK_FILTERS: createRequestTypes(`${BASE_NAME}/INVESTMENT_BLOCK_FILTERS`),
  BLOCKS_LIST: createRequestTypes(`${BASE_NAME}/BLOCKS_LIST`),
  SET_SELECTED_WLPS: `${BASE_NAME}/SET_SELECTED_WLPS`,
  SET_SELECTED_FIRMS: `${BASE_NAME}/SET_SELECTED_FIRMS`,
  SET_SELECTED_FUNDS: `${BASE_NAME}/SET_SELECTED_FUNDS`,
  SET_SELECTED_BLOCKS: `${BASE_NAME}/SET_SELECTED_BLOCKS`,
  SET_SELECTED_CONSTRAINT_TYPE: `${BASE_NAME}/SET_SELECTED_CONSTRAINT_TYPE`,
};

export const getInvestmentBlockRulesList = (params = {}, cancelInstance) => (
  (dispatch, getState) => {
    const state = getState().investmentBlocksManager;
    return dispatch(getRequest({
      url: `${BASE_URL}`,
      params: {
        block_types: state.selectedBlocks,
        constraint_type: state.selectedConstraintType,
        fund_ids: state.selectedFunds,
        firm_ids: state.selectedFirms,
        white_label_partner_ids: state.selectedWlps,
        page: state.investmentBlockRulesList.page,
        per_page: state.investmentBlockRulesList.per_page,
        sort_by: state.investmentBlockRulesList.sort_by,
        ...params,
      },
      actions: INVESTMENT_BLOCKS_MANAGER.INVESTMENT_BLOCK_RULES_LIST,
      cancelInstance,
    }));
  }
);

export const setSelectedBlocks = (ids, constraintType) => (dispatch) => Promise.all([
  dispatch({ type: INVESTMENT_BLOCKS_MANAGER.SET_SELECTED_BLOCKS, payload: ids }),
  dispatch({ type: INVESTMENT_BLOCKS_MANAGER.SET_SELECTED_CONSTRAINT_TYPE, payload: constraintType }),
  dispatch(getInvestmentBlockRulesList({ page: 1 })),
]);

const createSelectedAction = (type) => (payload) => (dispatch) => Promise.all([
  dispatch({ type, payload }),
  dispatch(getInvestmentBlockRulesList({ page: 1 })),
]);

export const setSelectedWlps = createSelectedAction(INVESTMENT_BLOCKS_MANAGER.SET_SELECTED_WLPS);
export const setSelectedFirms = createSelectedAction(INVESTMENT_BLOCKS_MANAGER.SET_SELECTED_FIRMS);
export const setSelectedFunds = createSelectedAction(INVESTMENT_BLOCKS_MANAGER.SET_SELECTED_FUNDS);

export const getInvestmentBlockFilters = () => (
  getRequest({
    url: `${BASE_URL}/filters`,
    actions: INVESTMENT_BLOCKS_MANAGER.INVESTMENT_BLOCK_FILTERS,
  })
);

export const getBlocksList = () => (
  getRequest({
    url: 'investment_block/blocks',
    actions: INVESTMENT_BLOCKS_MANAGER.BLOCKS_LIST,
  })
);

const needsToGoToPreviousPage = (ruleWasRemoved, rulesList) => ruleWasRemoved &&
  rulesList.page > 1 && rulesList.total_count - 1 <= (rulesList.page - 1) * rulesList.per_page;

const loadRulesAndFilters = (ruleWasRemoved) => (dispatch, getState) => {
  const params = {};
  const rulesList = getState().investmentBlocksManager.investmentBlockRulesList;

  // if a rule was removed and was the only rule in that page, request the previous page
  if (needsToGoToPreviousPage(ruleWasRemoved, rulesList)) {
    params.page = rulesList.page - 1;
  }

  return Promise.all([
    dispatch(getInvestmentBlockRulesList(params)),
    dispatch(getInvestmentBlockFilters()),
  ]);
};

export const createInvestmentBlockRule = (rule) => (
  postRequest({
    url: BASE_URL,
    data: { investment_block_rule: rule },
    onSuccess: () => loadRulesAndFilters(false),
    successMessage: { message: 'Investment Block Rule Created' },
  })
);

export const updateInvestmentBlockRule = (rule) => (
  putRequest({
    url: `${BASE_URL}/${rule.id}`,
    data: { investment_block_rule: rule },
    onSuccess: () => loadRulesAndFilters(false),
    successMessage: { message: 'Investment Block Rule Updated' },
  })
);

export const deleteInvestmentBlockRule = (rule) => (
  deleteRequest({
    url: `${BASE_URL}/${rule.id}`,
    onSuccess: () => loadRulesAndFilters(true),
    successMessage: { message: 'Investment Block Rule Deleted' },
  })
);

export default INVESTMENT_BLOCKS_MANAGER;
