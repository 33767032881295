import { map } from 'lodash';
import { createSelector } from 'reselect';
import { isMainSitePafAdmin } from 'selectors/icnBootstrapSelectors';
import { stringComparator } from 'services/customComparators';
import { isIndividual, needsInvestorCertification, isIndividualDisregardedEntity } from 'services/investor_profile';

export const getInvestorProfile = (state) => state.investorProfile.profile;
export const getIcnReactBootstrapUser = (state) => state.icnReactBootstrap.icn_react_bootstrap.user;
export const getCountries = (state) => state.investorProfile.countries;
export const getHasCustodian = (state) => state.investorProfile.has_custodian;
export const getCurrentCustodian = (state) => state.investorProfile.current_custodian;
export const getProfileErrors = (state) => state.investorProfile.errors.profile_errors;
export const getIndividualSignersTypes = (state) => (
  state.investorProfile.individual_signers_types
);
export const getEntitySignerTypes = (state) => state.investorProfile.entity_signer_types;
export const getEntityTaxClassification = (state) => (
  state.investorProfile.entity_tax_classification
);
export const getProfileFieldVisibility = (state) => state.investorProfile.visibility;
// Disregarded Entity types for Individual and Entities
export const getIndividualDisregardedEntityTypes = (state) => (
  state.investorProfile.individual_disregarded_entity_types
);
export const getEntityDisregardedEntityTypes = (state) => (
  state.investorProfile.entity_disregarded_entity_types
);
export const getEntityDisregardedEntityTaxClassification = (state) => (
  state.investorProfile.entity_disregarded_entity_tax_classification
);

export const getIsIndividualProfile = createSelector(getInvestorProfile, isIndividual);
export const getIsIndividualDisregardedEntity = createSelector(
  getInvestorProfile,
  isIndividualDisregardedEntity
);

const sortTaxClassifications = (a, b) => {
  if (a.value === 'Other') return 1;
  if (b.value === 'Other') return -1;
  return stringComparator(a.value, b.value);
};

export const getAccountTypeData = createSelector(
  getIndividualSignersTypes,
  getEntitySignerTypes,
  getIsIndividualProfile,
  (individualTypes, entityTypes, isIndividualProfile) => ({
    label: isIndividualProfile ? 'Type of Account' : 'Type of Entity',
    responses: map(isIndividualProfile ? individualTypes : entityTypes, (type) => ({
      value: type,
    })),
  })
);

export const getEntityTaxClassificationData = createSelector(
  getEntityTaxClassification,
  (taxClassifications) => ({
    label: 'Tax Classification',
    responses: map(taxClassifications, (type) => ({
      value: type,
    })).sort(sortTaxClassifications),
  })
);

export const getDisregardedEntityTypeData = createSelector(
  getIndividualDisregardedEntityTypes,
  getEntityDisregardedEntityTypes,
  getIsIndividualDisregardedEntity,
  (individualTypes, entityTypes, isIndividualDE) => ({
    label: 'Account Type',
    responses: map(isIndividualDE ? individualTypes : entityTypes, (type) => ({
      value: type,
    })),
  })
);

export const getDisregardedEntityTaxClassificationData = createSelector(
  getEntityDisregardedEntityTaxClassification,
  (taxClassifications) => ({
    label: 'Beneficial Owner Tax Classification',
    responses: map(taxClassifications, (type) => ({
      value: type,
    })).sort(sortTaxClassifications),
  })
);

export const profileFieldVisibilitySelector = createSelector(
  [getProfileFieldVisibility],
  (profileFieldVisibility) => profileFieldVisibility
);

export const getShowProfessionalCertifications = createSelector(
  getInvestorProfile,
  profileFieldVisibilitySelector,
  isMainSitePafAdmin,
  needsInvestorCertification
);
