import React from 'react';
import { connect } from 'react-redux';
// Currently IcnSpinner is widely used without default export , We are taking below route to re export.
//  In future , we will notify team to  use IcnSpinner directly from RCL library.
import { IcnSpinner as IcnSpinnerFromRCL } from '@icapitalnetwork/react-component-library';
import { shape, bool, string } from 'prop-types';
import styles from './IcnSpinner.module.scss';

export const IcnSpinner = IcnSpinnerFromRCL;

IcnSpinner.displayName = 'IcnSpinner';

const ICNSpinnerContainer = ({isFetching, spinnerText, whiteLabelPartner}) => { 
  const enableSiteNavMFE = whiteLabelPartner?.enable_sitenav_mfe;
return <IcnSpinner isFetching={isFetching} spinnerText={spinnerText} className={enableSiteNavMFE ? styles.icn_react_spinner_bg_MFE : ''}  /> 

}

ICNSpinnerContainer.propTypes = {
  isFetching: bool,
  spinnerText: string,
  whiteLabelPartner: shape({
    enable_sitenav_mfe: bool,
  }),
};

ICNSpinnerContainer.defaultProps = {
  isFetching: 0,
  spinnerText: '',
  whiteLabelPartner: {}
}

function mapStateToProps(state) {
  return {
    isFetching: state.spinner.isFetching > 0,
    spinnerText: state.spinner.spinnerText,
    whiteLabelPartner: state.icnReactBootstrap.icn_react_bootstrap.white_label_partner,
  };
}

export default connect(mapStateToProps, null)(ICNSpinnerContainer);
