import {
  AA_CLOSE_AGREEMENTS_MODAL,
  AA_OPEN_AGREEMENTS_MODAL,
  AA_ACCEPT_AGREEMENT,
  AA_ACCEPT_LEGACY_AGREEMENT,
  AA_SET_REQUEST_FAILED,
} from 'actions/actionsConstants';
import update from 'immutability-helper';
import initialState from '../initialState';

export default (state = initialState, action) => {
  switch (action.type) {
    case AA_OPEN_AGREEMENTS_MODAL: {
      const { fund, agreements, onAgreementsCleared } = action.payload;
      if (agreements?.length > 0) {
        return update(state, {
          fund: { $set: fund },
          agreements: { $set: agreements },
          isAgreementsModalOpen: { $set: true },
          onAgreementsCleared: { $set: onAgreementsCleared },
        });
      }
      onAgreementsCleared();
      return state;
    }
    case AA_CLOSE_AGREEMENTS_MODAL: {
      return update(state, {
        fund: { $set: initialState.fund },
        agreements: { $set: initialState.agreements },
        isAgreementsModalOpen: { $set: initialState.isAgreementsModalOpen },
        onAgreementsCleared: { $set: initialState.onAgreementsCleared },
      });
    }
    case AA_ACCEPT_AGREEMENT: {
      let agreements = [];
      const { agreementId } = action.payload;
      agreements = state.agreements.filter(
        (a) => a.agreementId !== agreementId
      );
      if (agreements?.length > 0) {
        return update(state, {
          agreements: { $set: agreements },
        });
      }

      const { onAgreementsCleared } = state;
      onAgreementsCleared();
      return update(state, {
        fund: { $set: initialState.fund },
        agreements: { $set: initialState.agreements },
        isAgreementsModalOpen: { $set: initialState.isAgreementsModalOpen },
        onAgreementsCleared: { $set: initialState.onAgreementsCleared },
      });
    }
    case AA_ACCEPT_LEGACY_AGREEMENT: {
      let agreements = [];
      const { type } = action.payload;
      agreements = state.agreements.filter((a) => a.type !== type);
      const { fund_id: fundId } = state.fund;
      const fundAcceptedLegacyAgreements = state.acceptedLegacyAgreements[fundId]
        ? [...state.acceptedLegacyAgreements[fundId], type]
        : [type];
      if (agreements?.length > 0) {
        return update(state, {
          agreements: { $set: agreements },
          acceptedLegacyAgreements: {
            [fundId]: { $set: fundAcceptedLegacyAgreements },
          },
        });
      }

      const { onAgreementsCleared } = state;
      onAgreementsCleared();
      return update(state, {
        fund: { $set: initialState.fund },
        agreements: { $set: initialState.agreements },
        isAgreementsModalOpen: { $set: initialState.isAgreementsModalOpen },
        onAgreementsCleared: { $set: initialState.onAgreementsCleared },
        acceptedLegacyAgreements: {
          [fundId]: { $set: fundAcceptedLegacyAgreements },
        },
      });
    }
    case AA_SET_REQUEST_FAILED: {
      const status = action.payload;
      return update(state, {
        requestFailed: { $set: status },
      });
    }
    default:
      return state;
  }
};
