/* eslint-disable max-lines */
import { createAction } from 'redux-actions';
import {
  getRequest,
  putRequest,
  postRequest,
  deleteRequest,
} from 'actions/utils';
import { createRequestTypes } from 'actions/helpers';
import _ from 'lodash';

import {
  TM_SET_ACCOUNT_PROFILES,
  TM_SET_AP_ACCOUNT_PROFILES_FILTER,
  TM_SET_AP_REFERRAL_CODES_FILTER,
  TM_SET_AP_BRANCH_CODES_FILTER,
  TM_SET_AP_SELECTED_ACCOUNTS_FILTER,
  TM_SET_AP_SELECTED_REF_CODES_FILTER,
  TM_SET_AP_SELECTED_BRANCH_CODES_FILTER,
  TM_SET_AP_SELECTED_ACCOUNTS_OPTIONS_FILTER,
  TM_SET_AP_SELECTED_REF_CODES_OPTIONS_FILTER,
  TM_SET_AP_SELECTED_BRANCH_CODES_OPTIONS_FILTER,
  TM_SET_RC_ASSIGNABLE_REFERRAL_CODES,
  TM_SET_RC_ASSIGNABLE_USERS,
  TM_SET_RC_REFERRAL_CODE_USERS,
  TM_RESET_RC_REFERRAL_CODE_USERS,
  TM_SET_RC_USER_REFERRAL_CODES,
  TM_RESET_RC_USER_REFERRAL_CODES,
  TM_SET_TEAMS_REFERRAL_CODES,
  TM_SET_TEAMS_REFERRAL_CODES_FILTER,
  TM_SET_TEAMS_USERS_FILTER,
  TM_SET_TEAMS_BRANCH_CODES_FILTER,
  TM_SET_TEAMS_SELECTED_REFERRAL_CODES_FILTER,
  TM_SET_TEAMS_SELECTED_USERS_FILTER,
  TM_SET_TEAMS_SELECTED_BRANCH_CODES_FILTER,
  TM_SET_TEAMS_SELECTED_REFERRAL_CODES_OPTIONS_FILTER,
  TM_SET_TEAMS_SELECTED_USERS_OPTIONS_FILTER,
  TM_SET_TEAMS_SELECTED_BRANCH_CODES_OPTIONS_FILTER,
  TM_RESET_SELECTED_TEAMS_FILTERS,
  TM_SET_ASSIGNABLE_REFERRAL_CODES_COUNT,
  TM_SET_BC_ASSIGNABLE_BRANCH_CODES,
  TM_SET_TEAM_INVITES,
  TM_SET_TEAM_INVITES_FILTER,
  TM_SET_SELECTED_TEAM_INVITES_FILTER,
  TM_SET_BC_BRANCH_REFERRAL_CODES,
  TM_RESET_BC_BRANCH_REFERRAL_CODES,
  TM_TOGGLE_EDIT_TEAM_MODAL_OPEN,
  TM_TOGGLE_DELETE_TEAM_MODAL_OPEN,
  TM_RESET_RC_ASSIGNABLE_USERS,
} from 'actions/actionsConstants';

import { teamInvites } from 'reducers/team_management_reducers/selectors';

const BASE_URL = 'team_management';
const ACCOUNT_PROFILES_URL = `${BASE_URL}/account_profiles`;
const REFERRAL_CODES_URL = `${BASE_URL}/referral_codes`;
const INVITES_URL = `${BASE_URL}/invites`;
const BRANCH_CODES_URL = `${BASE_URL}/branch_codes`;

const setAccountProfiles = createAction(TM_SET_ACCOUNT_PROFILES);
const setAPReferralCodesFilter = createAction(TM_SET_AP_REFERRAL_CODES_FILTER);
const setAPAccountProfilesFilter = createAction(TM_SET_AP_ACCOUNT_PROFILES_FILTER);
const setAPBranchCodesFilter = createAction(TM_SET_AP_BRANCH_CODES_FILTER);
const setAPSelectedAccountsFilter = createAction(TM_SET_AP_SELECTED_ACCOUNTS_FILTER);
const setAPSelectedRefCodesFilter = createAction(TM_SET_AP_SELECTED_REF_CODES_FILTER);
const setAPSelectedBranchCodesFilter = createAction(TM_SET_AP_SELECTED_BRANCH_CODES_FILTER);
const setAPSelectedAccountsOptionsFilter = createAction(TM_SET_AP_SELECTED_ACCOUNTS_OPTIONS_FILTER);
const setAPSelectedRefCodesOptionsFilter = createAction(TM_SET_AP_SELECTED_REF_CODES_OPTIONS_FILTER);
const setAPSelectedBranchCodesOptionsFilter = createAction(TM_SET_AP_SELECTED_BRANCH_CODES_OPTIONS_FILTER);
const setRCAssignableUsers = createAction(TM_SET_RC_ASSIGNABLE_USERS);
const setRCReferralCodeUsers = createAction(TM_SET_RC_REFERRAL_CODE_USERS);
const resetRCAssignableUsers = createAction(TM_RESET_RC_ASSIGNABLE_USERS);
const resetRCReferralCodeUsers = createAction(TM_RESET_RC_REFERRAL_CODE_USERS);
const resetRCUserReferralCodes = createAction(TM_RESET_RC_USER_REFERRAL_CODES);
const setTeamsReferralCodes = createAction(TM_SET_TEAMS_REFERRAL_CODES);
const setTeamsReferralCodesFilter = createAction(TM_SET_TEAMS_REFERRAL_CODES_FILTER);
const setTeamsUsersFilter = createAction(TM_SET_TEAMS_USERS_FILTER);
const setTeamsBranchCodesFilter = createAction(TM_SET_TEAMS_BRANCH_CODES_FILTER);
const setTeamsSelectedReferralCodesFilter =
  createAction(TM_SET_TEAMS_SELECTED_REFERRAL_CODES_FILTER);
const setTeamsSelectedUsersFilter = createAction(TM_SET_TEAMS_SELECTED_USERS_FILTER);
const setTeamsSelectedBranchCodesFilter = createAction(TM_SET_TEAMS_SELECTED_BRANCH_CODES_FILTER);
const setTeamsSelectedReferralCodesOptionsFilter =
  createAction(TM_SET_TEAMS_SELECTED_REFERRAL_CODES_OPTIONS_FILTER);
const setTeamsSelectedUsersOptionsFilter = createAction(TM_SET_TEAMS_SELECTED_USERS_OPTIONS_FILTER);
const setTeamsSelectedBranchCodesOptionsFilter = createAction(TM_SET_TEAMS_SELECTED_BRANCH_CODES_OPTIONS_FILTER);
const resetSelectedTeamsFilters = createAction(TM_RESET_SELECTED_TEAMS_FILTERS);
const setAssignableReferralCodesCount = createAction(TM_SET_ASSIGNABLE_REFERRAL_CODES_COUNT);
const setBCAssignableBranchCodes = createAction(TM_SET_BC_ASSIGNABLE_BRANCH_CODES);
const resetBCBranchReferralCodes = createAction(TM_RESET_BC_BRANCH_REFERRAL_CODES);
const toggleEditTeamModalOpen = createAction(TM_TOGGLE_EDIT_TEAM_MODAL_OPEN);
const toggleDeleteTeamModalOpen = createAction(TM_TOGGLE_DELETE_TEAM_MODAL_OPEN);

export const TM_RC_ASSIGNABLE_REFERRAL_CODES = createRequestTypes(TM_SET_RC_ASSIGNABLE_REFERRAL_CODES);
export const TM_RC_USER_REFERRAL_CODES = createRequestTypes(TM_SET_RC_USER_REFERRAL_CODES);
export const TM_BC_BRANCH_REFERRAL_CODES = createRequestTypes(TM_SET_BC_BRANCH_REFERRAL_CODES);

const setTeamInvites = createAction(TM_SET_TEAM_INVITES);
const setTeamInvitesFilter = createAction(TM_SET_TEAM_INVITES_FILTER);
const setSelectedTeamInvitesFilter = createAction(TM_SET_SELECTED_TEAM_INVITES_FILTER);
const setRCAssignableReferralCodes = createAction(TM_SET_RC_ASSIGNABLE_REFERRAL_CODES);

const loadAccountProfiles = (params) => (
  getRequest({
    url: ACCOUNT_PROFILES_URL,
    params,
    onSuccess: setAccountProfiles,
  })
);

const loadAPReferralCodesFilter = (params, showSpinner = true) => (
  getRequest({
    showSpinner,
    url: `${ACCOUNT_PROFILES_URL}/referral_codes_filter`,
    params,
    onSuccess: setAPReferralCodesFilter,
  })
);

const loadAPAccountProfilesFilter = (params, showSpinner = true) => (
  getRequest({
    showSpinner,
    url: `${ACCOUNT_PROFILES_URL}/account_profiles_filter`,
    params,
    onSuccess: setAPAccountProfilesFilter,
  })
);

const loadAPBranchCodesFilter = (params, showSpinner = true) => (
  getRequest({
    showSpinner,
    url: `${ACCOUNT_PROFILES_URL}/branch_codes_filter`,
    params,
    onSuccess: setAPBranchCodesFilter,
  })
);

const updateAPAccountProfiles = (id, data) => (
  putRequest({
    url: `${ACCOUNT_PROFILES_URL}/${id}`,
    data,
  })
);

const loadRCAssignableReferralCodes = (params, showSpinner = true, ignoreOnSuccessCallback = false) => (
  getRequest({
    showSpinner,
    url: `${REFERRAL_CODES_URL}/assignable_referral_codes`,
    params,
    actions: ignoreOnSuccessCallback ? null : TM_RC_ASSIGNABLE_REFERRAL_CODES,
  })
);

const loadRCAssignableReferralCodesCount = (showSpinner = true) => {
  const params = { per_page: 0, exceed_per_page_only_count: true };
  return getRequest({
    showSpinner,
    url: `${REFERRAL_CODES_URL}/assignable_referral_codes`,
    params,
    onSuccess: setAssignableReferralCodesCount,
  });
};

const loadRCAssignableUsers = (params, showSpinner = true, ignoreOnSuccessCallback = false) => (
  getRequest({
    showSpinner,
    url: `${REFERRAL_CODES_URL}/assignable_users`,
    params,
    onSuccess: ignoreOnSuccessCallback ? null : setRCAssignableUsers,
  })
);

const loadRCReferralCodeUsers = (params, showSpinner = true, onSuccess = setRCReferralCodeUsers) => (
  getRequest({
    showSpinner,
    url: `${REFERRAL_CODES_URL}/referral_code_users`,
    params,
    onSuccess,
  })
);

const validateCreateReferralCode = (data) => (
  postRequest({
    showSpinner: false,
    url: `${REFERRAL_CODES_URL}/validate`,
    data,
  })
);

const createReferralCode = (data) => (
  postRequest({
    url: REFERRAL_CODES_URL,
    data,
  })
);

const validateUpdateReferralCode = (id, data) => (
  postRequest({
    showSpinner: false,
    url: `${REFERRAL_CODES_URL}/validate/${id}`,
    data,
  })
);

const updateReferralCode = (id, data) => (
  putRequest({
    url: `${REFERRAL_CODES_URL}/${id}`,
    data,
  })
);

const loadRCUserReferralCodes = (params, showSpinner = true) => (
  getRequest({
    showSpinner,
    url: `${REFERRAL_CODES_URL}/user_referral_codes`,
    params,
    actions: TM_RC_USER_REFERRAL_CODES,
  })
);

const validateUpdateUserReferralCodes = (id, data) => (
  postRequest({
    showSpinner: false,
    url: `${REFERRAL_CODES_URL}/validate_user_referral_codes/${id}`,
    data,
  })
);

const updateUserReferralCodes = (id, data) => (
  postRequest({
    url: `${REFERRAL_CODES_URL}/user_referral_codes/${id}`,
    data,
  })
);

const loadTeamsReferralCodes = (params) => (
  getRequest({
    url: REFERRAL_CODES_URL,
    params,
    onSuccess: setTeamsReferralCodes,
  })
);

const loadTeamsReferralCodesFilter = (params, showSpinner = true) => (
  getRequest({
    showSpinner,
    url: `${REFERRAL_CODES_URL}/referral_codes_filter`,
    params,
    onSuccess: setTeamsReferralCodesFilter,
  })
);

const loadTeamsUsersFilter = (params, showSpinner = true) => (
  getRequest({
    showSpinner,
    url: `${REFERRAL_CODES_URL}/users_filter`,
    params,
    onSuccess: setTeamsUsersFilter,
  })
);

const loadTeamsBranchCodesFilter = (params, showSpinner = true) => (
  getRequest({
    showSpinner,
    url: `${REFERRAL_CODES_URL}/branch_codes_filter`,
    params,
    onSuccess: setTeamsBranchCodesFilter,
  })
);

const deleteTeam = (id) => (
  deleteRequest({
    url: `${REFERRAL_CODES_URL}/${id}`,
  })
);

const loadBCAssignableBranchCodes = (params, showSpinner = true, ignoreOnSuccessCallback = false) => (
  getRequest({
    showSpinner,
    url: `${BRANCH_CODES_URL}/assignable_branch_codes`,
    params,
    onSuccess: ignoreOnSuccessCallback ? null : setBCAssignableBranchCodes,
  })
);

const validateCreateBranchCode = (data) => (
  postRequest({
    showSpinner: false,
    url: `${BRANCH_CODES_URL}/validate`,
    data,
  })
);

const createBranchCode = (data) => (
  postRequest({
    url: BRANCH_CODES_URL,
    data,
  })
);

const validateUpdateBranchReferralCodes = (id, data) => (
  postRequest({
    showSpinner: false,
    url: `${BRANCH_CODES_URL}/validate_branch_referral_codes/${id}`,
    data,
  })
);

const updateBranchReferralCodes = (id, data) => (
  postRequest({
    url: `${BRANCH_CODES_URL}/branch_referral_codes/${id}`,
    data,
  })
);

const loadBCBranchReferralCodes = (params, showSpinner = true) => (
  getRequest({
    showSpinner,
    url: `${BRANCH_CODES_URL}/branch_referral_codes`,
    params,
    actions: TM_BC_BRANCH_REFERRAL_CODES,
  })
);

const reloadTeams = () => (
  (dispatch, getState) => {
    const {
      selectedUsersFilter,
      selectedReferralCodesFilter,
      selectedBranchCodesFilter,
    } = getState().teamManagement.teams;

    dispatch(resetSelectedTeamsFilters());

    // if there is ref codes or users selected we loadTeamsReferralCodes automatically, otherwise we need to load.
    if (_.isEmpty(selectedUsersFilter) &&
        _.isEmpty(selectedReferralCodesFilter) &&
        _.isEmpty(selectedBranchCodesFilter)) {
      dispatch(loadTeamsReferralCodes());
    }
  }
);

const loadTeamInvites = (params) => (
  getRequest({
    url: INVITES_URL,
    params,
    onSuccess: setTeamInvites,
  })
);

const loadTeamInvitesFilter = (params, showSpinner = true) => (
  getRequest({
    showSpinner,
    url: `${INVITES_URL}/invites_filter`,
    params,
    onSuccess: setTeamInvitesFilter,
  })
);

const createUserTeamInvite = (data) => (
  postRequest({
    url: INVITES_URL,
    data,
  })
);

const resendUserTeamInvite = (id) => (dispatch, getState) => dispatch(postRequest({
  url: `${INVITES_URL}/resend_invite/${id}`,
  onSuccess: () => {
    const invites = { ...teamInvites(getState()) };
    const selectedInvite = invites?.invites.find(
      (invite) => invite.id === id
    );
    if (selectedInvite) {
      selectedInvite.expired = false;
      return dispatch(setTeamInvites(invites));
    }
    return null;
  },
}));

export default {
  setAccountProfiles,
  setAPReferralCodesFilter,
  setAPAccountProfilesFilter,
  setAPBranchCodesFilter,
  setAPSelectedAccountsFilter,
  setAPSelectedRefCodesFilter,
  setAPSelectedBranchCodesFilter,
  setAPSelectedAccountsOptionsFilter,
  setAPSelectedRefCodesOptionsFilter,
  setAPSelectedBranchCodesOptionsFilter,
  setRCAssignableUsers,
  setRCReferralCodeUsers,
  resetRCAssignableUsers,
  resetRCReferralCodeUsers,
  resetRCUserReferralCodes,
  loadAccountProfiles,
  loadAPReferralCodesFilter,
  loadAPAccountProfilesFilter,
  loadAPBranchCodesFilter,
  updateAPAccountProfiles,
  loadRCAssignableReferralCodes,
  loadRCAssignableUsers,
  loadRCReferralCodeUsers,
  validateCreateReferralCode,
  createReferralCode,
  validateUpdateReferralCode,
  updateReferralCode,
  loadRCUserReferralCodes,
  validateUpdateUserReferralCodes,
  updateUserReferralCodes,
  loadTeamsReferralCodes,
  loadTeamsReferralCodesFilter,
  loadTeamsUsersFilter,
  loadTeamsBranchCodesFilter,
  setTeamsReferralCodesFilter,
  setTeamsUsersFilter,
  setTeamsBranchCodesFilter,
  setTeamsSelectedReferralCodesFilter,
  setTeamsSelectedUsersFilter,
  setTeamsSelectedBranchCodesFilter,
  setTeamsSelectedReferralCodesOptionsFilter,
  setTeamsSelectedUsersOptionsFilter,
  setTeamsSelectedBranchCodesOptionsFilter,
  resetSelectedTeamsFilters,
  reloadTeams,
  deleteTeam,
  loadRCAssignableReferralCodesCount,
  setAssignableReferralCodesCount,
  loadBCAssignableBranchCodes,
  setTeamInvites,
  loadTeamInvites,
  setTeamInvitesFilter,
  loadTeamInvitesFilter,
  setSelectedTeamInvitesFilter,
  createUserTeamInvite,
  resendUserTeamInvite,
  validateCreateBranchCode,
  createBranchCode,
  validateUpdateBranchReferralCodes,
  updateBranchReferralCodes,
  loadBCBranchReferralCodes,
  resetBCBranchReferralCodes,
  setRCAssignableReferralCodes,
  toggleEditTeamModalOpen,
  toggleDeleteTeamModalOpen,
};
