import { arrToObj, withPrefix } from 'services/investor_profile';

const noResponses = obj => Object.keys(obj).every(field => !obj[field]);

const updateCompleteness = (obj, prefix, completenessObj) => {
  const completenessUpdates = {};

  Object.entries(obj).forEach(([field, val]) => {
    completenessUpdates[`${prefix}_${field}`] = val ? 'complete' : 'not_required';
  });

  return { ...completenessObj, ...completenessUpdates };
};

const newIssuesProfileCompleteness = (newIssuesProfile, signerType) => {
  if (!newIssuesProfile) { return {}; }

  const { data, choose_to_participate } = newIssuesProfile;
  const { question1, question2 } = data;

  let completeness = {
    ...arrToObj(withPrefix(Object.keys(question1), 'new_issues_question1'), 'not_required'),
    ...arrToObj(withPrefix(Object.keys(question2), 'new_issues_question2'), 'not_required'),
  };

  completeness.new_issues_choose_to_participate = (
    [true, false].includes(choose_to_participate) ?
      'complete' :
      'incomplete'
  );

  if (!choose_to_participate) { return completeness; }

  completeness = {
    ...completeness,
    ...arrToObj(withPrefix(Object.keys(question1), 'new_issues_question1'), 'incomplete'),
  };

  if (noResponses(question1)) { return completeness; }

  completeness = updateCompleteness(question1, 'new_issues_question1', completeness);

  if (signerType === '0' || question1.none_applies) { return completeness; }

  completeness = {
    ...completeness,
    ...arrToObj(withPrefix(Object.keys(question2), 'new_issues_question2'), 'incomplete'),
  };

  if (noResponses(question2)) { return completeness; }

  completeness = updateCompleteness(question2, 'new_issues_question2', completeness);

  return completeness;
};

export default newIssuesProfileCompleteness;
