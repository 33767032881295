export const signersValidations = {
  first_name: {
    allowedCharacters: {},
  },
  last_name: {
    allowedCharacters: {},
  },
  email: {
    email: {},
  },
  birthdate: {
    date: {
      format: 'MM/DD/YYYY',
    },
  },
};

export const signersUniquenessValidations = {
  email: {
    compareFields: [
      'signers.0.email',
      'signers.1.email',
      'signers.2.email',
    ],
    errorMsg: 'Each signer must have a unique email address.',
  },
};
