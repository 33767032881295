import {
  DD_LOAD_STORAGED_FILES,
  DD_INITIATE_UPLOAD_FILE,
  DD_DELETE_STORAGED_FILE,
} from 'actions/actionsConstants';

import initialState from '../initialState';
import { updateState } from '../helpers';

const REQUEST_OBJECT = 'file';

export default (state = initialState, action) => {
  switch (action.type) {
    case DD_LOAD_STORAGED_FILES:
      return updateState(state, action.payload, (data) => ({
        request_object: REQUEST_OBJECT,
        request_type: 'set_storaged_files_list',
        message: 'Fetched Storaged Files List.',
        files: {
          list: data.files,
          white_label_partner_id: data.white_label_partner_id,
        },
      }));
    case DD_INITIATE_UPLOAD_FILE:
      return updateState(state, action.payload, (data) => ({
        error: !data,
        request_object: REQUEST_OBJECT,
        request_type: 'upload_file',
        message: `${data ? 'Succeeded' : 'Failed'} on uploading a file`,
        response_file_upload: {
          file_key: data.file_key,
          uploaded: data.uploaded,
        },
      }));
    case DD_DELETE_STORAGED_FILE:
      return updateState(state, action.payload, (data) => ({
        error: !data.deleted,
        request_object: REQUEST_OBJECT,
        request_type: 'delete_file',
        message: `${data.deleted ? 'Succeeded' : 'Failed'} on deleting a file`,
        response_file_deletion: {
          file_key: data.file_key,
          deleted: data.deleted,
        },
      }));
    default:
      return state;
  }
};
