import {
  IO_SET_IS_LOADING,
  IO_SET_LOADING_FIRST_PAGE,
  IO_SET_PAFS,
  IO_SET_SEARCH_TERMS,
} from 'modules/investment_opportunity/actions/constants';

import update from 'immutability-helper';
import initialState from './initialState';

export default (state = initialState, action) => {
  switch (action.type) {
    case IO_SET_PAFS: {
      return update(state, {
        pageActions: { pafs: { $set: action.payload } },
      });
    }
    case IO_SET_SEARCH_TERMS: {
      return update(state, {
        pageActions: { searchTerms: { $set: action.payload } },
      });
    }
    case IO_SET_IS_LOADING:
      return update(state, { isLoading: { $set: action.payload } });
    case IO_SET_LOADING_FIRST_PAGE:
      return update(state, { loadingFirstPage: { $set: action.payload } });
    default:
      return state;
  }
};
