import update from 'immutability-helper';

import { RG_SET_FAILED_SCHEDULE_NOTIFICATION_RECIPIENTS } from 'actions/actionsConstants';

import initialState from 'reducers/report_generator_reducers/initialState';

export default (state = initialState, action) => {
  switch (action.type) {
    case RG_SET_FAILED_SCHEDULE_NOTIFICATION_RECIPIENTS: {
      const { recipients, default_recipients: defaultRecipients } =
        action.payload;
      return update(state, {
        failedScheduleNotificationRecipients: {
          $set: { recipients, defaultRecipients },
        },
      });
    }
    default:
      return state;
  }
};
