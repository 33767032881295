/* eslint-disable max-statements */
/* eslint-disable max-lines-per-function */
import moment from 'moment';
import update from 'immutability-helper';
import {
  ACTIVITY_LOG_ADVISOR_ACTIVITIES_SET_DATA,
  ACTIVITY_LOG_ADVISOR_ACTIVITIES_SET_CURRENT_PAGE,
  ACTIVITY_LOG_ADVISOR_ACTIVITIES_SET_RESULTS_PER_PAGE,
  ACTIVITY_LOG_ADVISOR_ACTIVITIES_SET_SORT,
  ACTIVITY_LOG_ADVISOR_ACTIVITIES_SET_PAGE_ACTIONS,
  ACTIVITY_LOG_ADVISOR_ACTIVITIES_REMOVE_ALL_FILTERS,
  ACTIVITY_LOG_ADVISOR_ACTIVITIES_REMOVE_FILTER_EVENT,
  ACTIVITY_LOG_ADVISOR_ACTIVITIES_REMOVE_FILTER_PRODUCT,
  ACTIVITY_LOG_ADVISOR_ACTIVITIES_REMOVE_FILTER_CATEGORY,
  ACTIVITY_LOG_ADVISOR_ACTIVITIES_REMOVE_FILTER_STRATEGY,
  ACTIVITY_LOG_ADVISOR_ACTIVITIES_REMOVE_FILTER_COURSE_TYPE,
  ACTIVITY_LOG_ADVISOR_ACTIVITIES_SET_FILTERS,
  ACTIVITY_LOG_ADVISOR_ACTIVITIES_REMOVE_DATE_RANGE_FILTER,
  ACTIVITY_LOG_ADVISOR_ACTIVITIES_SET_USER_SAVED_VIEW,
} from '../../actions/constants';
import initialState from '../initialState';

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIVITY_LOG_ADVISOR_ACTIVITIES_SET_DATA: {
      return update(state, {
        advisorActivitiesData: {
          data: { $set: action.payload.data },
          currentPage: { $set: action.payload.current_page },
          resultsPerPage: { $set: action.payload.results_per_page },
          hasNextPage: { $set: action.payload.has_next_page },
          totalCount: { $set: action.payload.total_count },
        },
      });
    }

    case ACTIVITY_LOG_ADVISOR_ACTIVITIES_SET_FILTERS: {
      return update(state, {
        advisorActivitiesData: {
          filterValues: { $set: action.payload.filters },
        },
      });
    }

    case ACTIVITY_LOG_ADVISOR_ACTIVITIES_SET_CURRENT_PAGE: {
      return update(state, {
        advisorActivitiesData: {
          currentPage: { $set: action.payload },
        },
      });
    }

    case ACTIVITY_LOG_ADVISOR_ACTIVITIES_SET_USER_SAVED_VIEW: {
      const savedview = action.payload.filters.sequence;
      if (savedview.startDate !== null) {
        savedview.startDate = moment(savedview.startDate);
      }
      if (savedview.endDate !== null) {
        savedview.endDate = moment(savedview.endDate);
      }

      return update(state, {
        advisorActivitiesData: {
          currentPage: { $set: action.payload.per_page },
          sort: { $set: action.payload.sort_field },
          pageActions: {
            filters: { $set: savedview },
          },
        },
      });
    }

    case ACTIVITY_LOG_ADVISOR_ACTIVITIES_SET_RESULTS_PER_PAGE: {
      return update(state, {
        advisorActivitiesData: {
          resultsPerPage: { $set: action.payload },
          currentPage: { $set: 1 },
        },
      });
    }

    case ACTIVITY_LOG_ADVISOR_ACTIVITIES_SET_SORT: {
      return update(state, {
        advisorActivitiesData:
        {
          sort: { $set: action.payload },
        },
      });
    }

    case ACTIVITY_LOG_ADVISOR_ACTIVITIES_SET_PAGE_ACTIONS: {
      return update(state, {
        advisorActivitiesData: {
          pageActions: { $merge: action.payload },
          currentPage: { $set: 1 },
        },
      });
    }

    case ACTIVITY_LOG_ADVISOR_ACTIVITIES_REMOVE_ALL_FILTERS:
      return update(state, {
        advisorActivitiesData: {
          pageActions: {
            filters: { $set: initialState.advisorActivitiesData.pageActions.filters },
          },
        },
      });

    case ACTIVITY_LOG_ADVISOR_ACTIVITIES_REMOVE_FILTER_EVENT:
      return update(state, {
        advisorActivitiesData: {
          pageActions: {
            filters: {
              events: {
                $set: state.advisorActivitiesData.pageActions.filters.events.filter(
                  (elem) => elem.id !== action.payload.id
                ),
              },
            },
          },
        },
      });

    case ACTIVITY_LOG_ADVISOR_ACTIVITIES_REMOVE_FILTER_PRODUCT:
      return update(state, {
        advisorActivitiesData: {
          pageActions: {
            filters: {
              products: {
                $set: state.advisorActivitiesData.pageActions.filters.products.filter(
                  (elem) => elem.id !== action.payload.id
                ),
              },
            },
          },
        },
      });

    case ACTIVITY_LOG_ADVISOR_ACTIVITIES_REMOVE_FILTER_CATEGORY:
      return update(state, {
        advisorActivitiesData: {
          pageActions: {
            filters: {
              categories: {
                $set: state.advisorActivitiesData.pageActions.filters.categories.filter(
                  (elem) => elem.id !== action.payload.id
                ),
              },
            },
          },
        },
      });

    case ACTIVITY_LOG_ADVISOR_ACTIVITIES_REMOVE_FILTER_STRATEGY:
      return update(state, {
        advisorActivitiesData: {
          pageActions: {
            filters: {
              strategies: {
                $set: state.advisorActivitiesData.pageActions.filters.strategies.filter(
                  (elem) => elem.id !== action.payload.id
                ),
              },
            },
          },
        },
      });

    case ACTIVITY_LOG_ADVISOR_ACTIVITIES_REMOVE_FILTER_COURSE_TYPE:
      return update(state, {
        advisorActivitiesData: {
          pageActions: {
            filters: {
              courseTypes: {
                $set: state.advisorActivitiesData.pageActions.filters.courseTypes.filter(
                  (elem) => elem.id !== action.payload.id
                ),
              },
            },
          },
        },
      });

    case ACTIVITY_LOG_ADVISOR_ACTIVITIES_REMOVE_DATE_RANGE_FILTER:
      return update(state, {
        advisorActivitiesData: {
          pageActions: {
            filters: {
              dateOption: { $set: 'all-time' },
              endDate: { $set: null },
              startDate: { $set: null },
            },
          },
        },
      });

    default:
      return state;
  }
};
